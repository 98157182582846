import styled from "@emotion/styled/macro";
import { Accordion, AccordionSummary, Box } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledWhiteCardContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.white,
  boxShadow: "0px 1.2824229001998901px 6.412113666534424px 0px #0000001A",
  borderRadius: "4px",
  position: "relative",
}));

export const StyledWhiteAccordion = styled(Accordion, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  // "&.Mui-expanded": {
  //   margin: 0,
  // },
  "&.MuiAccordion-root:before": {
    height: 0,
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent",
  },
  background: theme.customColors.white,
  boxShadow: "0px 1.2824229001998901px 6.412113666534424px 0px #0000001A",
  borderRadius: "4px",
  padding: 0,
  margin: "20px 0",
  maxWidth: "100%",
  position: "relative",
}));

export const StyledWhiteAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: 0,
  paddingRight: "20px",
  margin: 0,
  display: "flex",
  alignItems: "center",
  "&.Mui-expanded": {
    marginTop: "20px !important",
  },
  "& > div": {
    margin: 0,
  },
}));

export const StyledContainerTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  padding: "20px",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
}));

export const StyledInputContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  padding: "20px",
  fontSize: "14px",
}));

export const StyledObjectiveTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: "#555",
  marginBottom: "8px",
}));

export const StyledObjectiveDescription = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  color: "#000",
}));

export const StyledAttributionTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  color: theme.customColors.grey[700],
  marginBottom: "8px",
}));

export const StyledAttributionSubtitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: theme.customColors.grey[600],
  marginBottom: "20px",
}));

export const StyledLinkInput = styled.input(
  {
    shouldForwardProp: isStyledPropsValid,
  },
  ({ theme }) => ({
    border: 0,
    fontFamily: "Inter",
    width: "100%",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.75,
    "&:focus": {
      outline: "none",
    },
  })
);
export const StyledGrid = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, grid = 4 }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid}, 1fr)`,
  gap: "16px 20px",
  margin: "16px 0",
}));

export const StyledGridItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, checked }) => ({
  flex: 1,
  padding: "8px",
  borderRadius: "4px",
  border: `1px solid ${
    checked ? theme.customColors.purple[500] : theme.customColors.grey[300]
  }`,
}));

export const StyledCollapseTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => ({
  "& > i": {
    rotate: open ? "180deg" : "0deg",
    transition: "all 0.3s ease-in-out",
    marginLeft: "4px",
  },
  cursor: "pointer",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
}));

export const StyledEngagementTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  margin: "2px 0",
  color: theme.customColors.grey[700],
}));

export const StyledQuote = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  padding: "12px",
  paddingLeft: "20px",
  color: theme.customColors.purple[500],
  backgroundColor: theme.customColors.purple[300],
  borderLeft: `4px solid ${theme.customColors.purple[500]}`,
  marginTop: "16px",
  marginBottom: "12px",

  "& div": {
    color: "#000",
  },
}));

export const StyledChannelOption = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, active, disabled }) => {
  return {
    position: "relative",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "10px 8px",
    borderRadius: "3px",
    minHeight: "80px",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.5 : 1,
    margin: "8px 0",
    borderColor: active
      ? theme.customColors.purple[500]
      : theme.customColors.grey[300],
  };
});

export const StyledIntegrationOption = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "center",
  position: "absolute",
  left: 0,
  top: 0,
  backgroundColor: "rgba(248, 248, 248, 0.7)",
  color: theme.customColors.purple[700],
  fontSize: 16,
  fontWeight: 700,
  width: "100%",
  height: "100%",
  zIndex: 2,
  rowGap: 16,
}));

export const StyledDetectURLContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",

  "& > div": {
    width: "100%",
  },
}));

export const StyledAudienceSelectBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isSelected }) => ({
  border: isSelected
    ? `1px solid ${theme.customColors.purple[500]}`
    : `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: 4,
  paddingLeft: 8,
  flexBasis: "33.33%",
  cursor: "pointer",
  margin: "8px 0",
}));

export const StyledOptionPreviewWrapper = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "relative",
  padding: "8px 18px",
  height: "100%",
  zIndex: 1,
}));

export const PreviewOptionItemHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: theme.fontWeight.BOLD,
  fontSize: theme.fontSize.LARGE,
  color: theme.customColors.grey[700],
}));

export const PreviewHolderWrapper = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  gap: 4,
  flexWrap: "wrap",
}));

export const PreviewAddressCount = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontWeight: 700,
  fontSize: 24,
  color: theme.customColors.purple[500],
}));

export const PreviewPremiumArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 100,
  color: theme.customColors.purple[500],
  textAlign: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
}));

import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch, FormControlLabel } from "@material-ui/core";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 14,
    padding: 0,
    overflow: "visible",
    // display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(18px)",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#e5dfff",
        borderColor: "#e5dfff",
      },
    },
  },
  colorSecondary: {
    padding: 0,
    backgroundColor: "#F9F9F9",
    border: "3px solid #7B61FF",
    marginTop: "-4px",
    boxShadow: "0px 0px 3.21333px rgba(39, 39, 39, 0.35)",
    "&$checked": {
      color: "#7B61FF",
      border: "3px solid #F9F9F9",
      backgroundColor: "#7B61FF",
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: "#CCCCCC",
  },
  checked: {},
}))(Switch);
export default function CustomSwitch({ label, ...props }) {
  return (
    <FormControlLabel
      style={{ margin: 0 }}
      control={<AntSwitch {...props}></AntSwitch>}
      label={
        <div style={{ fontSize: "12px", fontWeight: "500", marginLeft: 8 }}>
          {label}
        </div>
      }
    />
  );
}

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Box from "@mui/material/Box";
import Linkify from "linkify-react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { useOutletContext } from "react-router-dom";

import Collapse from "@mui/material/Collapse";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import moment from "moment";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useIntl } from "react-intl";

import {
  StyledQuestionBoxContainer,
  StyledQuiz,
  StyledQuestionLine,
  StyledQuestionText,
  StyledQuestionButtons,
  StyledExpandButton,
  StyledShowMoreButton,
  StyledQuestionExpandContentContainer,
  StyledRelatedQuestionsContainer,
  StyledRelatedQuestionsQ,
  StyledRelatedQuestionsList,
  StyledRelatedQuestions,
  StyledDividingLine,
  StyledQuestionExpandContent,
  StyledQuestionExpandTextContainer,
  StyledQuestionExpandText,
  StyledAppliedChannels,
  StyledQuestionExpandImgContainer,
  StyledImage,
  StyledTagList,
  StyledQuestionExpandEditor,
  StyledMenu,
  StyledIcon,
} from "./QuestionBlock.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import MenuItem from "@mui/material/MenuItem";
import { get, isEmpty, find, filter, includes } from "lodash-es";
import { deleteMacroAsyncAction } from "features/metadesk/redux/macro/macro.action";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import useGetPagePermission from "hooks/useGetPagePermission";

const QuestionBlock = ({
  onLinkifyOptions,
  macro,
  onOpenEditMacroModal,
  tabCategoryId,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const [entityName, isCustomDomain] = useOutletContext();

  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // const { tags } = useFetchTags();
  const {
    _id: macroId,
    answer,
    category,
    channels,
    lastEditor,
    questions,
    tags,
    updated,
  } = macro;
  const images = get(answer, "image", []);

  const icon = getUserAvatarIcon(get(lastEditor, "_id"), theme);

  const mainQuestion = find(questions, { isMain: true });
  const relatedQuestion = filter(questions, { isMain: false });

  const renderTags = () => {
    return tags.map((tag) => (
      <MetaCRMTag
        key={tag._id}
        option={{ color: tag.color, label: tag.value }}
      />
    ));
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleOpenEditModal = () => {
    onOpenEditMacroModal({ ...macro, nowTabCategory: tabCategoryId });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDelete = (event) => {
    confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content: formatMessage({
        id: "popup.delete.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        dispatch(
          deleteMacroAsyncAction({ entityName: entityName, id: macroId })
        );
        handleClose(event);
      })
      .catch(() => {});
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("macros");

  return (
    <StyledQuestionBoxContainer>
      {!isEmpty(relatedQuestion) && (
        <StyledQuiz>
          {formatMessage({ id: "macrosSetting.macros.quiz" })}
        </StyledQuiz>
      )}

      <StyledQuestionLine expanded={expanded}>
        <StyledQuestionText>{get(mainQuestion, "text")}</StyledQuestionText>
        <StyledQuestionButtons>
          <StyledExpandButton
            expanded={expanded}
            onClick={handleExpandClick}
            color={theme.customColors.purple[500]}
          >
            <ExpandMoreIcon />
          </StyledExpandButton>
          {canEdit && (
            <>
              <StyledShowMoreButton
                ref={anchorRef}
                // onClick={handleClick}
                onClick={handleToggle}
                color={theme.customColors.purple[500]}
              >
                <MoreHorizIcon />
              </StyledShowMoreButton>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <StyledMenu>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleOpenEditModal} disableRipple>
                            <i className="meta-crm-icon-ic_edit-1 font-size-16" />
                            {formatMessage({ id: "global.btn.edit" })}
                          </MenuItem>
                          <MenuItem onClick={handleDelete} disableRipple>
                            <i className="meta-crm-icon-ic_trash font-size-16" />
                            {formatMessage({ id: "global.btn.delete" })}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </StyledMenu>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </StyledQuestionButtons>
      </StyledQuestionLine>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <StyledQuestionExpandContentContainer>
          {!isEmpty(relatedQuestion) && (
            <StyledRelatedQuestionsContainer>
              <StyledRelatedQuestionsQ>Q</StyledRelatedQuestionsQ>

              <StyledRelatedQuestionsList>
                {relatedQuestion.map((question) => (
                  <StyledRelatedQuestions key={question._id}>
                    {question.text}
                  </StyledRelatedQuestions>
                ))}
              </StyledRelatedQuestionsList>
            </StyledRelatedQuestionsContainer>
          )}

          <StyledDividingLine />

          <StyledQuestionExpandContent>
            <StyledQuestionExpandTextContainer>
              <StyledQuestionExpandText>
                <Linkify options={onLinkifyOptions}>
                  {get(answer, "text")}
                </Linkify>
              </StyledQuestionExpandText>
              <StyledAppliedChannels>
                {includes(channels, "supportCenter") && (
                  <div className="iconBackground">
                    <i
                      className="meta-crm-icon-ic_supportCenter font-size-10"
                      style={{ color: "white" }}
                    />
                  </div>
                )}

                {includes(channels, "discord") && (
                  <div className="iconBackground">
                    <i
                      className="meta-crm-icon-ic_discord font-size-16"
                      style={{ color: "white" }}
                    />
                  </div>
                )}

                {includes(channels, "widget") && (
                  <div className="iconBackground">
                    <i
                      className="meta-crm-icon-ic_widgetSetting font-size-14"
                      style={{ color: "white" }}
                    />
                  </div>
                )}

                {includes(channels, "") && (
                  <>
                    <div className="iconBackground">
                      <i
                        className="meta-crm-icon-ic_supportCenter font-size-10"
                        style={{ color: "white" }}
                      />
                    </div>
                    <div className="iconBackground">
                      <i
                        className="meta-crm-icon-ic_discord font-size-16"
                        style={{ color: "white" }}
                      />
                    </div>
                    <div className="iconBackground">
                      <i
                        className="meta-crm-icon-ic_widgetSetting font-size-14"
                        style={{ color: "white" }}
                      />
                    </div>
                  </>
                )}

                {/* <StyledIcon>
                  <WidgetIcon />
                </StyledIcon> */}
              </StyledAppliedChannels>
            </StyledQuestionExpandTextContainer>
            {!isEmpty(images) && (
              <StyledQuestionExpandImgContainer>
                {images.map((image) => (
                  <StyledImage key={image} src={image}></StyledImage>
                ))}
              </StyledQuestionExpandImgContainer>
            )}

            <StyledTagList>{renderTags()}</StyledTagList>
            <StyledQuestionExpandEditor>
              <span className="editor-title">
                {formatMessage({ id: "macrosSetting.macros.editor" })}:
              </span>
              <img src={require(`assets/img/${icon}`)} className="avatar-img" />
              {get(lastEditor, "name")},{moment(updated).format("YYYY/MM/DD")}
            </StyledQuestionExpandEditor>
          </StyledQuestionExpandContent>
        </StyledQuestionExpandContentContainer>
      </Collapse>
    </StyledQuestionBoxContainer>
  );
};

export default QuestionBlock;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";

import Box from "@mui/material/Box";
import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import {
  StyledTextHeader,
  StyledSettingIcon,
  StyledCard,
  StyledDemoTemplateBody,
  StyledDemoTemplateInfoArea,
  StyledTicketList,
  StyledTicketItem,
  StyledTicketItemStatus,
  StyledDemoTemplateChatArea,
  StyledDemoTemplateChatAreaHeaderContainer,
  StyledAdminMessageItemMsg,
  StyledAdminMessageItemMsgTitle,
  StyledBottomNavigation,
  StyledBottomNavigationContentContainer,
  StyledBottomNavigationIcon,
  StyledFixBottomContainer,
  StyledFixBottomText,
} from "./WidgetDemoTemplate.styles";
import { getShortAddress } from "features/helpers/utils";
import { useIntl } from "react-intl";

const WidgetDemoTemplate = ({ colorArray }) => {
  const { formatMessage } = useIntl();

  const convertColorArrayToObject = (array) => {
    let obj = {};
    for (let item of array) {
      obj[item.name] = item.color;
    }
    return obj;
  };

  const colorSetting = convertColorArrayToObject(colorArray);
  console.log("colorSetting: ", colorSetting);

  const {
    primaryColor,
    hoverColor,
    backgroundColor,
    textHoverColor,
    cardColor,
    cardHoverColor,
  } = colorSetting;

  const widgetRoutes = [
    {
      name: "global.function.home",
      icon: <i className="meta-crm-icon-ic_home_outline font-size-24" />,
      isActive: true,
      unRead: false,
    },
    {
      name: "global.function.ticket",
      icon: <i className="meta-crm-icon-ic_file_outline font-size-24" />,
      isActive: false,
      unRead: false,
    },
    {
      name: "global.function.notifications",
      icon: (
        <i className="meta-crm-icon-ic_bell_regular_outline font-size-24" />
      ),
      isActive: false,
      unRead: true,
    },
  ];

  const getBrightness = (rgb) => {
    if (!rgb) return;
    // 將hex顏色轉換為rgb
    const [r, g, b] = _.map(rgb.match(/\w\w/g), (x) => parseInt(x, 16));
    // 計算亮度
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const textWithDynamicColor = (bgColor) => {
    // 計算背景顏色的亮度
    const brightness = getBrightness(bgColor);
    // 根據亮度選擇文字顏色
    return brightness > 128 ? "black" : "white";
  };
  return (
    <div style={{ maxWidth: 700, margin: "0 auto" }}>
      <StyledDemoTemplateBody>
        <StyledDemoTemplateInfoArea background={backgroundColor}>
          <Box padding="20px 16px">
            <div className="betweenRow">
              <IconLogoCRM height={32} width={32} />
              <StyledSettingIcon color={primaryColor}>
                {/* <i className="meta-crm-icon-ic_settingLine font-size-24" /> */}
                <Box
                  component="i"
                  className="meta-crm-icon-ic_wallet font-size-16"
                  mr={"5px"}
                />
                <Box>
                  {getShortAddress("000000000000000000000000000000000")}
                </Box>
              </StyledSettingIcon>
            </div>
            <StyledTextHeader
              style={{ textAlign: "left" }}
              color={textWithDynamicColor(backgroundColor)}
            >
              Hello 👋 <br />
              How can we help you?
            </StyledTextHeader>
            <Box display={"flex"} justifyContent={"space-between"}>
              <StyledAdminMessageItemMsgTitle
                textColor={primaryColor}
                height={12}
                width={99}
              />

              <StyledAdminMessageItemMsgTitle
                textColor={primaryColor}
                height={12}
                width={30}
                sx={{ opacity: 0.6 }}
              />
            </Box>
            <StyledCard color={cardColor}>
              <StyledAdminMessageItemMsgTitle
                textColor={hoverColor}
                height={12}
                width={99}
              />
              <StyledAdminMessageItemMsg
                textColor={hoverColor}
                height={8}
                width={249}
                mb={4}
              />
              <StyledAdminMessageItemMsg
                textColor={hoverColor}
                height={8}
                width={185}
                mb={24}
              />
              <StyledAdminMessageItemMsg
                textColor={hoverColor}
                height={8}
                width={121}
                mb={0}
              />
            </StyledCard>
            <StyledAdminMessageItemMsgTitle
              textColor={primaryColor}
              height={12}
              width={99}
              mt={29}
            />
            <StyledCard
              cardHoverColor={cardHoverColor}
              textHoverColor={textHoverColor}
            >
              <StyledAdminMessageItemMsgTitle
                textColor={hoverColor}
                textHoverColor={textHoverColor}
                height={12}
                width={218}
                mt={4}
                mb={4}
              />
              <Box
                component="img"
                src={require(`assets/img/cursor.png`)}
                sx={{
                  width: "30px",
                  position: "absolute",
                  right: "5px",
                }}
              ></Box>
            </StyledCard>
          </Box>
          <StyledBottomNavigation>
            {widgetRoutes.map((route, index) => (
              <StyledBottomNavigationContentContainer
                color={route.isActive ? primaryColor : "#A5A5A5"}
                key={index}
                unRead={route.unRead}
              >
                <StyledBottomNavigationIcon unRead={route.unRead}>
                  {route.icon}
                </StyledBottomNavigationIcon>
                <Box>
                  {formatMessage({
                    id: route.name,
                  })}
                </Box>
              </StyledBottomNavigationContentContainer>
            ))}
          </StyledBottomNavigation>
        </StyledDemoTemplateInfoArea>
        <StyledDemoTemplateChatArea>
          <StyledDemoTemplateChatAreaHeaderContainer
            primaryColor={primaryColor}
          >
            <StyledAdminMessageItemMsgTitle
              textColor={textWithDynamicColor(primaryColor)}
              height={12}
              width={99}
              mb={0}
            />
          </StyledDemoTemplateChatAreaHeaderContainer>
          <StyledTicketList>
            {[...Array(3)].map((_, index) => {
              const isHover = index == 0;
              return (
                <StyledTicketItem key={index}>
                  {isHover && (
                    <Box
                      component="img"
                      src={require(`assets/img/cursor.png`)}
                      sx={{
                        width: "30px",
                        position: "absolute",
                        right: "88px",
                        bottom: "5px",
                      }}
                    ></Box>
                  )}
                  <StyledTicketItemStatus
                    background={isHover ? primaryColor : "#F1F6FF"}
                  ></StyledTicketItemStatus>
                  <div>
                    <StyledAdminMessageItemMsgTitle
                      textColor={isHover ? textHoverColor : hoverColor}
                      height={12}
                      width={99}
                    />
                    <StyledAdminMessageItemMsg
                      textColor={isHover ? textHoverColor : hoverColor}
                      height={8}
                      width={188}
                      mb={4}
                    />
                    <StyledAdminMessageItemMsg
                      textColor={isHover ? textHoverColor : hoverColor}
                      height={8}
                      width={138}
                      mb={0}
                    />
                  </div>
                  <i
                    className="meta-crm-icon-ic_arrowLineRight font-size-24"
                    style={{ color: primaryColor }}
                  />
                </StyledTicketItem>
              );
            })}
          </StyledTicketList>

          <StyledFixBottomContainer primaryColor={primaryColor}>
            <StyledFixBottomText
              textColor={textWithDynamicColor(primaryColor)}
            ></StyledFixBottomText>
          </StyledFixBottomContainer>
        </StyledDemoTemplateChatArea>
      </StyledDemoTemplateBody>
    </div>
  );
};

export default WidgetDemoTemplate;

import { Box, Grid } from "@mui/material";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { useContext, useMemo, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import BigNumber from "bignumber.js";
import _ from "lodash";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import ReferrerCountModal from "./component/ReferrerCountModal";
import { useIntl } from "react-intl";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import TextWithTooltip from "components/Text/TextWithTooltip";
export default function ReferrerTable({
  referrers,
  total,
  isShowObjective,
  objectiveData,
}) {
  const isDemoSite = useSelector(selectDemoSite);
  const PAGE_COUNT = 5;
  const [countModal, setCountModal] = useState({ isOpen: false, data: [] });
  const { page, setPage } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();
  const tableData = useMemo(() => {
    if (isShowObjective) {
      return [
        {
          title: (
            <Box className="startRow">
              <Box className="mgr4">
                {formatMessage({
                  id: "websiteTraffic.columnName.trafficSource",
                })}
              </Box>
              <IconTooltip
                content={formatMessage({
                  id: "websiteTraffic.columnName.trafficSource.toolTip",
                })}
              />
            </Box>
          ),
          size: 4,
        },
        { title: "Total Users", size: 2 },
        { title: "Wallet Connected", size: 2 },
        { title: "Objective Achieved", size: 2 },
        { title: "Conversion Rate", size: 2 },
      ];
    } else {
      return [
        {
          title: (
            <Box className="startRow">
              <Box className="mgr4">
                {formatMessage({
                  id: "websiteTraffic.columnName.trafficSource",
                })}
              </Box>
              <IconTooltip
                content={formatMessage({
                  id: "websiteTraffic.columnName.trafficSource.toolTip",
                })}
              />
            </Box>
          ),
          size: 3,
        },
        { title: "Total Users", size: 3 },
        { title: "Wallet Connected", size: 3 },
        { title: "Drop Rate", size: 3 },
      ];
    }
  }, [isShowObjective]);
  const calculateConversion = (objectiveAchieved = 0, total) => {
    if (!total) return "-%";
    return `${parseFloat((objectiveAchieved * 100) / total).toFixed(1)}%`;
  };
  const calculateDrop = (walletsConnected, total) => {
    if (!total) return "-%";
    return `${parseFloat(((total - walletsConnected) * 100) / total).toFixed(
      1
    )}%`;
  };

  const renderCell = (index, report, i) => {
    if (index === 0) {
      const data = isDemoSite
        ? `https://test-demo${(page - 1) * PAGE_COUNT + i + 1}.metacrm.inc`
        : report.referer;
      return (
        <>
          <Box sx={{ mr: "12px", whiteSpace: "nowrap" }}>
            {(page - 1) * PAGE_COUNT + i + 1}.
          </Box>{" "}
          <TextWithTooltip data={data} />
        </>
      );
    } else if (index === 1) {
      return report.totalUser;
    } else if (index === 2) {
      return report.walletsConnected;
    } else if (index === 3 && isShowObjective) {
      return renderObjectiveAchieved(report);
    } else if (isShowObjective) {
      return calculateConversion(report.objectiveAchieved, report.totalUser);
    } else {
      return calculateDrop(report.walletsConnected, report.totalUser);
    }
  };

  const renderObjectiveAchieved = (report) => {
    if (report.objectiveAchieved) {
      return (
        <span
          className="linkCount"
          onClick={(e) => {
            e.stopPropagation();
            setCountModal({
              isOpen: true,
              data: {
                achievedWallets: report.achievedWallets,
                totalWallet: total?.wallets,
              },
            });
          }}
        >
          {report.objectiveAchieved}
        </span>
      );
    }
    if (!_.isEmpty(objectiveData)) return 0;
    return (
      <IconTooltip
        content={"Please set up an objective to display value."}
        icon={"-"}
      />
    );
  };

  const renderContents = (report, i) => {
    return (
      <Grid container alignItems={"center"}>
        {tableData.map((column, index) => (
          <Grid
            item
            key={index}
            xs={column.size}
            sx={{
              p: "20px",
              borderRight: "1px solid #F8F8F8",
              display: "flex",
              wordBreak: "break-all",
              flexWrap: "nowrap",
              alignItems: "center",
              fontSize: 12,
              color: "#555",
            }}
          >
            {renderCell(index, report, i)}
          </Grid>
        ))}
      </Grid>
    );
  };
  const renderInfo = (title, value, size = 2) => {
    return (
      <Grid
        xs={12}
        md={size}
        sx={{ height: 94, p: "20px", borderRight: "1px solid #F8F8F8" }}
      >
        <Box sx={{ fontWeight: 500, fontSize: 14, color: "#777" }}>{title}</Box>
        <Box
          sx={{ fontWeight: 700, fontSize: 24, color: "#383538", mt: "12px" }}
        >
          {value}
        </Box>
      </Grid>
    );
  };
  return (
    <Box>
      <Box
        sx={{
          background: "white",
          borderRadius: "4px",
          mt: "7px",
          mb: "4px",
        }}
      >
        <Grid container>
          {tableData.map((column, index) => {
            let value;

            if (index === 0) {
              value = "";
            } else if (index === 1) {
              value = total?.users
                ? new BigNumber(total.users).toFormat(0)
                : "-";
            } else if (index === 2) {
              value = total?.wallets
                ? new BigNumber(total.wallets).toFormat(0)
                : "-";
            } else if (index === 3 && isShowObjective) {
              value = total?.objectiveAchieved
                ? new BigNumber(total.objectiveAchieved).toFormat(0)
                : "-";
            } else {
              value = isShowObjective
                ? calculateConversion(total?.objectiveAchieved, total?.users)
                : calculateDrop(total?.wallets, total?.users);
            }

            return renderInfo(column.title, value, column.size);
          })}
        </Grid>
      </Box>
      {_.map(referrers.data, (row, i) => {
        return (
          <Box index={i} sx={{ background: "white", mb: "4px" }}>
            {renderContents(row, i)}
          </Box>
        );
      })}
      <ReferrerCountModal
        open={countModal.isOpen}
        onClose={() => setCountModal({ isOpen: false, data: [] })}
        data={countModal.data}
      />
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={referrers.total}
        page={page}
        setPage={(v) => setPage(v)}
      />
    </Box>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { upperCase } from "lodash";
import moment from "moment";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import {
  getShortAddress,
  formatDiscordUsername,
  isVersionMoreThan,
} from "features/helpers/utils";
import Linkify from "linkify-react";
import { enqueueSnackbar } from "features/common/redux/actions";
import IconIcDiscord2 from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscord2";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import {
  StyledAdminMessage,
  StyledAdminMessageTime,
  StyledMacroButton,
  StyledMessageRow,
  StyledMsgAddress,
  StyledMsgField,
  StyledMsgValue,
  StyledUserMessage,
  StyledUserMessageTime,
  StyledMoreButton,
  StyledRecallContainer,
  StyledFileContainer,
  StyledFileBox,
} from "./Chat.styles";
import { PopperMenu } from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useIntl } from "react-intl";
import { selectWidgetModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import TicketTypeLabel from "./components/TicketTypeLabel";
import { PreviewFile } from "@metacrm/metacrm-material-ui/dist/PreviewFile";

const ChatItem = ({
  data,
  index,
  lastOne,
  lastData,
  setOpenImg,
  discordId,
  selectedTicket,
  entityName,
  onRecallMsg,
}) => {
  const options = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };

  const dispatch = useDispatch();
  const address = _.get(selectedTicket, "userWallet.address", "");
  const status = _.get(data, "status", "");
  const isRecalled = _.get(data, "isRecalled", false);
  const team = _.get(data, "team", null);

  const [openMore, setOpenMore] = useState(false);
  const anchorRef = useRef(null);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const widgetModule = useSelector(selectWidgetModule);

  const widgetConnected = _.get(widgetModule, "connected", false);
  const widgetConnecting = _.get(widgetModule, "connecting", false);

  const widgetVersion = _.get(widgetModule, "version", "");

  const renderTime = () => {
    if (!data.created) return <div />;
    return (
      <>
        {moment(data.created).format("YYYY/MM/DD")}
        <br /> {moment(data.created).format("HH:mm")}
      </>
    );
  };

  const renderUserInitTicketMessage = () => {
    return (
      <div
        style={{
          fontSize: 13,
          padding: 0,
          lineHeight: "22px",
          color: "383538",
        }}
      >
        <div
          style={{
            background: "#FFFFFF",
            padding: 18,
            fontSize: 12,
            borderRadius: "0px 10px 0px 0px",
          }}
        >
          <div style={{ marginBottom: 5 }}>
            <span style={{ fontSize: 10 }}>From: </span>
            <span style={{ color: "#3CA1FF", fontWeight: 700 }}>
              {getAddress()}
            </span>
          </div>
          <div className="startRow">
            <StyledMsgField>
              Create:{" "}
              {moment(_.get(selectedTicket, "created")).format(
                "YYYY/MM/DD HH:mm"
              )}
            </StyledMsgField>
            <StyledMsgField style={{ padding: "2px 6px" }}>
              <TicketTypeLabel ticketType={_.get(selectedTicket, "type")} />
            </StyledMsgField>
          </div>
        </div>
        <div style={{ padding: 16, borderTop: "1px solid #A5A5A5" }}>
          <div style={{ fontSize: 18, fontWeight: 700, marginBottom: 10 }}>
            {_.get(selectedTicket, "title")}
          </div>
          <Box>
            <StyledMsgValue>
              <Linkify options={options}>
                {_.get(selectedTicket, "description")}
              </Linkify>
            </StyledMsgValue>
            <PreviewFile
              files={data.imagesUrl}
              bigImg={true}
              onImgClick={(image) => setOpenImg(image)}
              style={{ lineHeight: "1.2" }}
              videoProps={{ width: 375, height: 200 }}
            />
          </Box>
        </div>
      </div>
    );
  };
  const renderStatusDisplay = () => {
    if (status == "sending") return "Sending...";
    if (status == "failed")
      return (
        <i
          className="meta-crm-icon-ic_info_fill font-size-16"
          style={{ color: "#FC5555" }}
        />
      );
    return renderTime();
  };

  const handleMoreAction = () => {
    setOpenMore(true);
  };

  const handleCloseMoreAction = () => {
    setOpenMore(false);
  };

  const handleRecall = async () => {
    try {
      const { data: resData } = await axios.post(
        `${apiUrl}/api/messages/manage/${entityName}/recall`,
        {
          messageId: _.get(data, "_id"),
          ticketId: _.get(selectedTicket, "_id"),
        }
      );

      onRecallMsg(resData);

      // handleBack();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(
            error,
            "response.data.error",
            _.get(error, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleRecallConfirm = () => {
    handleCloseMoreAction();
    confirm({
      title: formatMessage({
        id: "popup.recallConfirmation.title",
      }),
      content: formatMessage({
        id: "popup.recallConfirmation.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(() => {
        handleRecall();
      })
      .catch(() => {});
  };

  const itemList = [
    {
      children: (
        <>
          <i className="meta-crm-icon-ic_redo font-size-16" />
          Recall
        </>
      ),
      func: (e) => {
        handleRecallConfirm(e);
      },
    },
  ];

  const renderMoreBtn = () => {
    return (
      <>
        <Box mr="5px">
          <StyledMoreButton
            onClick={(event) => handleMoreAction(event)}
            size={16}
            ref={anchorRef}
          >
            <i className="meta-crm-icon-ic_dots font-size-16" />
          </StyledMoreButton>

          <PopperMenu
            open={openMore}
            onClose={handleCloseMoreAction}
            anchorEl={anchorRef.current}
            placement="bottom"
            itemList={itemList}
          />
        </Box>
      </>
    );
  };

  const renderMoreBtnCondition = () => {
    // 不是已經被Recall的訊息 && 不是傳送失敗的訊息 && 不是傳送中的訊息 && 訊息是portal admin回覆的
    if (!isRecalled && status !== "failed" && status !== "sending" && team) {
      // 客戶有安裝widget && widget版本 > 1.1.1 (1.1.2版本才支援recall)
      if (widgetConnected || widgetConnecting) {
        if (isVersionMoreThan(widgetVersion, "1.1.1")) {
          return renderMoreBtn();
        }
      } else {
        // 客戶沒有安裝widget
        return renderMoreBtn();
      }
    }
  };

  const renderAdminChatBox = () => {
    return (
      <div key={index}>
        {getAdminName()}
        <Box mb="18px">
          <StyledMessageRow status={status} isRecalled={isRecalled}>
            <StyledAdminMessageTime>
              {renderStatusDisplay()}
            </StyledAdminMessageTime>

            {renderMoreBtnCondition()}

            <StyledAdminMessage sending={status == "sending"}>
              <Linkify options={options}>{data.message}</Linkify>
              <PreviewFile
                files={data.imagesUrl}
                bigImg={true}
                onImgClick={(image) => setOpenImg(image)}
                white
                videoProps={{ width: 375, height: 200 }}
              />
            </StyledAdminMessage>
          </StyledMessageRow>
          {isRecalled && (
            <StyledRecallContainer>
              {formatMessage({ id: "chatroom.messageState.recall" })}{" "}
              {_.get(data, "recallBy.name", "admin")}
            </StyledRecallContainer>
          )}
        </Box>
      </div>
    );
  };

  const renderUserChatBox = () => {
    return (
      <div key={index}>
        {lastOne !== "user" && (
          <div style={{ margin: "10px 0 7px 0" }}>{getAddress()}</div>
        )}
        <Box mb="18px">
          <StyledMessageRow
            style={{ justifyContent: "flex-start" }}
            isRecalled={isRecalled}
          >
            <StyledUserMessage index={index}>
              {index == 0 ? (
                renderUserInitTicketMessage()
              ) : (
                <div style={{ padding: 10 }}>
                  {isRecalled ? (
                    <div>
                      {formatMessage({
                        id: "chatroom.messageState.recall",
                      })}{" "}
                      user
                    </div>
                  ) : (
                    <div>
                      {/* {isDiscordGifMessage(data.message) ? (
                        <><PreviewFile files={data.imagesUrl} bigImg={true}  onImgClick={(image) => setOpenImg(image)} /></PreviewFile>
                      ) : (
                        <> */}
                      <Linkify options={options}>{data.message}</Linkify>
                      <PreviewFile
                        files={data.imagesUrl}
                        bigImg={true}
                        onImgClick={(image) => setOpenImg(image)}
                        videoProps={{ width: 375, height: 200 }}
                      />
                    </div>
                  )}
                </div>
              )}
            </StyledUserMessage>
            <StyledUserMessageTime>
              {data.edited && <div>Edited</div>}
              {renderTime()}
            </StyledUserMessageTime>
          </StyledMessageRow>
        </Box>
      </div>
    );
  };
  const getAdminName = () => {
    const lastAdminName =
      formatDiscordUsername(_.get(lastData, "discordUsername")) ||
      _.get(lastData, "team.name");
    const adminName =
      formatDiscordUsername(_.get(data, "discordUsername")) ||
      _.get(data, "team.name");
    const adminFromDiscord = !!_.get(data, "discordUsername");
    if (lastAdminName == adminName) return;
    return (
      <div style={{ marginBottom: 5 }} className="endRow">
        {adminFromDiscord ? (
          <>
            <IconIcDiscord2 width={20} height={20} className="mgr4" />
            <span
              style={{
                color: "#555555",
                fontWeight: 500,
                fontSize: 12,
                textDecorationLine: "underline",
              }}
            >
              {adminName}
            </span>
          </>
        ) : (
          <>
            <img
              src={require("assets/img/ic_alpacaBlacBigFace.svg").default}
              style={{ width: 18, height: 18, marginRight: 5 }}
            />
            <span
              style={{
                color: "#555555",
                fontWeight: 500,
                fontSize: 12,
                textDecorationLine: "underline",
              }}
            >
              {adminName}
            </span>
          </>
        )}
      </div>
    );
  };
  const getAddress = () => {
    return (
      <StyledMsgAddress className={"startRow"}>
        {address &&
          getShortAddress(_.get(selectedTicket, "userWallet.address", ""))}
        {discordId && (
          <div
            className="startRow"
            style={{
              color: "#555555",
              fontWeight: 500,
              marginLeft: address ? 15 : 0,
            }}
          >
            <IconIcDiscord2 width={20} height={20} className="mgr4" />
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: "#777777",
              }}
            >
              ID: {formatDiscordUsername(discordId)}
            </span>
          </div>
        )}
      </StyledMsgAddress>
    );
  };

  if (data.isAdminResponse) return renderAdminChatBox();
  return renderUserChatBox();
};

export default ChatItem;

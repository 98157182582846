import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import worldData from "./world.geo.json";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import _ from "lodash";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FF9BCD",
  },
}));

const SecondaryLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#BA8DFB",
  },
}));

const MapChart = ({ country, isShowObjective }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const titleStyle = {
    color: "#777",
    fontSize: 14,
    fontWeight: 500,
    marginBottom: "30px",
  };

  const colorScale = scaleLinear()
    .domain([0, 100])
    .range(["#F5F5F5", "#5643CC"]);

  const handleMouseEnter = (event, data) => {
    const { clientX, clientY } = event; // 获取鼠标位置

    setTooltipContent(data);
    setTooltipPosition({
      x: clientX + 10, // 调整以显示 tooltip
      y: clientY - 10,
    });
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setTooltipPosition({ x: 0, y: 0 });
  };

  return (
    <Box
      sx={{
        minHeight: "365px",
        p: 2,
        bgcolor: "#fff",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: (theme) => theme.customColors.grey[700],
        }}
      >
        Active User by country
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            width: "500px",
            mt: "14px",
            overflow: "hidden",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <ComposableMap
            height={320}
            width={620}
            projection="geoMercator"
            projectionConfig={{
              rotate: [0, 0, 0],
              scale: 96,
              center: [0, 30],
            }}
          >
            <Geographies geography={worldData} enableBackground={"#fff"}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const d = country.find((c) => c.country === geo.id);

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={d ? colorScale(d.totalUser) : "#F5F5F5"}
                      stroke={"#dddddd"}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                      onMouseEnter={(event) => handleMouseEnter(event, d)}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          {tooltipContent && (
            <Box
              sx={{
                position: "fixed",
                width: "200px",
                zIndex: 10000,
                top: `${tooltipPosition.y}px`,
                left: `${tooltipPosition.x}px`,
                backgroundColor: "white",
                padding: "12px",
                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
                color: "#555",
                fontWeight: 500,
                borderRadius: "5px",
                pointerEvents: "none",
              }}
            >
              <Box sx={{ color: "#777", mb: "8px", fontWeight: 400 }}>
                {tooltipContent?.name}
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  Users
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right", mb: "8px" }}>
                  {tooltipContent?.totalUser}
                </Grid>
                <Grid item xs={6}>
                  Wallets
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right", mb: "8px" }}>
                  {tooltipContent?.walletsConnected}
                </Grid>
                {isShowObjective && (
                  <>
                    <Grid item xs={6}>
                      Objective Achieved
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      {tooltipContent?.objectiveAchieved}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            height: "100%",
            overflow: "hidden",
            p: 2,
          }}
        >
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={isShowObjective ? 3 : 4} sx={titleStyle}>
              Country
            </Grid>
            {isShowObjective && (
              <Grid item xs={3} sx={titleStyle}>
                Objective Achieved
              </Grid>
            )}
            <Grid item xs={isShowObjective ? 3 : 4} sx={titleStyle}>
              Wallets
            </Grid>
            <Grid item xs={isShowObjective ? 3 : 4} sx={titleStyle}>
              Users
            </Grid>
          </Grid>
          {_.map(_.slice(country, 0, 8), (c) => {
            return (
              <Grid
                container
                sx={{ mb: "12px", fontWeight: 500, fontSize: 12 }}
                key={c.name}
              >
                <Grid item xs={3} sx={{ mb: "2px" }}>
                  {c.name}
                </Grid>
                {isShowObjective && (
                  <Grid item xs={isShowObjective ? 3 : 4}>
                    {c.objectiveAchieved}
                  </Grid>
                )}
                <Grid item xs={isShowObjective ? 3 : 4}>
                  {c.walletsConnected}
                </Grid>
                <Grid item xs={isShowObjective ? 3 : 4}>
                  {c.totalUser}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ position: "relative" }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={_.min([
                        (c.walletsConnected * 100) / c.totalUser,
                        100,
                      ])}
                    />
                    {isShowObjective && (
                      <SecondaryLinearProgress
                        variant="determinate"
                        value={_.min([
                          (c.objectiveAchieved * 100) / c.totalUser,
                          100,
                        ])}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MapChart;

import React, { useState, createContext } from "react";
import { Loading } from "features/common";
import { useSelector } from "react-redux";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import moment from "moment";
const defaultDays = 1;
export const PerformanceContext = createContext();

export const PerformanceProvider = ({ children }) => {
  const isDemoSite = useSelector(selectDemoSite);

  const getInitDateRange = () => {
    if (isDemoSite) {
      return {
        from: new Date(2024, 4, 1),
        to: new Date(2024, 6, 31),
        range: "custom",
      };
    }
    const today = moment().endOf("day").toDate();
    const lastWeek = moment()
      .subtract(defaultDays, "days")
      .startOf("day")
      .toDate();
    return { from: lastWeek, to: today, range: defaultDays };
  };
  const initDateData = getInitDateRange();
  const [range, setRange] = useState(initDateData.range);
  const [startDate, setStartDate] = useState(initDateData.from);
  const [endDate, setEndDate] = useState(initDateData.to);
  const [isLoading, setIsLoading] = useState({
    chart: false,
    table: false,
  });
  const [page, setPage] = useState(1);

  return (
    <PerformanceContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        isLoading,
        setIsLoading,
        page,
        setPage,
        range,
        setRange,
      }}
      style={{ height: "100%", position: "relative" }}
    >
      <Loading
        open={isLoading.chart || isLoading.table}
        fullScreen={false}
        longWait
      />

      {children}
    </PerformanceContext.Provider>
  );
};

import React, { useEffect, useState, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";

import { Modal, Grid, useMediaQuery } from "@material-ui/core";
import {
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useConnectWallet } from "features/home/redux/hooks";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import styles from "./styles";
import { SiweMessage } from "siwe";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from "wagmi";
import _ from "lodash";
import { getShortAddress, parseCustomDomainUrl } from "features/helpers/utils";
import { generateKey, encryptToken } from "features/helpers/encrypt";
import InterceptorContext from "features/home/InterceptorContext";
import { StyledWelcomeText } from "./styles";
import Box from "@mui/material/Box";
import { getUserAvatarIcon } from "features/metadesk/utils";
import {
  StyledTabLabel,
  StyledTabLabelImg,
  StyledBuildWith,
} from "./ConnectWallet.styles";
import {
  MartianWalletName,
  AptosWalletName,
  RiseWalletName,
  PontemWalletName,
  useWallet,
} from "@manahippo/aptos-wallet-adapter";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import { SolflareWalletName } from "@solana/wallet-adapter-solflare";
import config from "../../config";
import { useOpenState } from "contexts/stepContext/Open.context";
axios.defaults.withCredentials = true;
const useStyles = makeStyles(styles);

export function useDebounceEffect(fn, waitTime, deps, preAction) {
  useEffect(() => {
    preAction && preAction();
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

const ConnectWallet = ({
  isCustomDomain,
  entityName,
  hidden = false,
  defaultOpen = false,
  isTrial = false,
}) => {
  const [shortAddress, setShortAddress] = useState("");
  const { chain } = useNetwork();
  const { openState, setOpen } = useOpenState();
  const {
    authCheck,
    auth,
    userData,
    authCheckPending,
    authCheckDone,
    signout,
    logoutLoading,
    getAuthenticatedInfo,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
  } = useConnectWallet();

  const theme = useTheme();
  const { formatMessage } = useIntl();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useStyles();

  const { disconnect } = useDisconnect();
  const {
    address,
    aptosConnect,
    solanaSelect,
    solanaConnect,
    isConnected,
    isConnecting,
    aptosDisconnect,
    solanaDisconnect,
    isEcoSystemLoading,
    isInterceptorSetup,
    aptosSignMessage,
    solanaSignMessage,
    ecoSystemData,
    handleGetEntityEcosystem,
    requestAuthPending,
    setRequestAuthPending,
    requestAuthDone,
    setRequestAuthDone,
  } = useContext(InterceptorContext);

  const { connect, connectors } = useConnect({
    onError(error) {
      // console.log("Error", error);
      setRequestAuthPending(false);
      setRequestAuthDone(false);
    },
  });

  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();
  const domain = window.location.host;
  const origin = window.location.origin;
  const [currentConnectors, setCurrentConnectors] = useState([]);
  const [onlyWalletConnect, setOnlyWalletConnect] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false);
  const intervalId = useRef(null);
  const [tabChainValue, setTabChainValue] = useState(0);
  const aptosWallets = [
    PontemWalletName,
    MartianWalletName,
    RiseWalletName,
    AptosWalletName,
  ];

  const solanaWallets = [PhantomWalletName, SolflareWalletName];

  // const solanaWallets = [PhantomWalletName, SolflareWalletName];

  const handleChangeTabChain = (event, newValue) => {
    setTabChainValue(newValue);
  };

  const connectorsImg = {
    metaMask: require("assets/img/MetaMask.png"),
    injected: require("assets/img/BrowserWallet.png"),
    walletConnect: require("assets/img/WalletConnect.svg").default,
  };

  const aptosConnectorImg = {
    [MartianWalletName]: require("assets/img/martian.png"),
    [RiseWalletName]: require("assets/img/rise.png"),
    [AptosWalletName]: require("assets/img/petra.png"),
    [PontemWalletName]: require("assets/img/pontem.svg").default,
  };

  const solanaConnectorImg = {
    [PhantomWalletName]: require("assets/img/phantom.png"),
    [SolflareWalletName]: require("assets/img/solflare.png"),
  };

  const renderEvmWalletLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {currentConnectors.map((connector, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <div
                className={`${classes.button} `}
                disabled={!connector.ready}
                key={connector.id}
                onClick={async () => {
                  connect({ connector });
                }}
              >
                <div>
                  <img
                    src={connectorsImg[connector.id]}
                    style={{ width: 57, height: 57 }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector.name}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <StyledBuildWith>
        {formatMessage({ id: "login.wallet.builtWith" })}
        <StyledTabLabelImg
          component="img"
          src={require("assets/img/ETH_grey_bg.png")}
          sx={{ marginLeft: "6px" }}
        ></StyledTabLabelImg>{" "}
        {formatMessage({ id: "global.blockchain.evm" })}
      </StyledBuildWith>
    </Box>
  );

  const renderAptosWalletLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {aptosWallets.map((connector, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <div
                className={`${classes.button} `}
                key={connector.id}
                onClick={async () => {
                  try {
                    await aptosConnect(connector);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <div>
                  <img
                    src={aptosConnectorImg[connector]}
                    style={{ width: 57, height: 57 }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <StyledBuildWith>
        {formatMessage({ id: "login.wallet.builtWith" })}
        <StyledTabLabelImg
          component="img"
          src={require("assets/img/aptos.png")}
          sx={{ marginLeft: "6px" }}
        ></StyledTabLabelImg>{" "}
        {formatMessage({ id: "global.blockchain.aptos" })}
      </StyledBuildWith>
    </Box>
  );

  const renderSolanaLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {solanaWallets.map((connector, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <div
                className={`${classes.button} `}
                key={connector.id}
                onClick={async () => {
                  try {
                    await solanaSelect(connector);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <div>
                  <img
                    src={solanaConnectorImg[connector]}
                    style={{ width: 57, height: 57 }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <StyledBuildWith>
        {formatMessage({ id: "login.wallet.builtWith" })}
        <StyledTabLabelImg
          component="img"
          src={require("assets/img/solana.png")}
          sx={{ marginLeft: "6px" }}
        ></StyledTabLabelImg>{" "}
        {formatMessage({ id: "global.blockchain.solana" })}
      </StyledBuildWith>
    </Box>
  );

  const renderWalletOnlyWalletConnectStatus = () => {
    if (ecoSystemData === "aptos") {
      return renderAptosWalletLoginWays();
    } else {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Button
              color={COLOR.SECONDARY}
              disabled={!currentConnectors[0].ready}
              key={currentConnectors[0].name}
              onClick={async () => {
                let connector = currentConnectors[0];

                connect({ connector });
              }}
            >
              Click to connect
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const renderWalletLoginWays = () => {
    if (ecoSystemData === "evm" || isTrial) {
      return renderEvmWalletLoginWays();
    } else if (ecoSystemData === "aptos") {
      return renderAptosWalletLoginWays();
    } else if (ecoSystemData === "solana") {
      return renderSolanaLoginWays();
    }
  };

  const tabsArray = [
    {
      label: (
        <StyledTabLabel>
          <StyledTabLabelImg
            component="img"
            src={require("assets/img/ETH_grey_bg.png")}
          ></StyledTabLabelImg>{" "}
          {formatMessage({ id: "global.blockchain.evm" })}
        </StyledTabLabel>
      ),
      content: renderEvmWalletLoginWays(),
      _id: 1,
    },
    {
      label: (
        <StyledTabLabel>
          <StyledTabLabelImg
            component="img"
            src={require("assets/img/aptos.png")}
          ></StyledTabLabelImg>{" "}
          {formatMessage({ id: "global.blockchain.aptos" })}
        </StyledTabLabel>
      ),
      content: renderAptosWalletLoginWays(),
      _id: 1,
    },
  ];

  useDebounceEffect(
    () => {
      if (!isInterceptorSetup) return;

      if (isTrial) {
        if (!isConnected && defaultOpen) {
          return setOpen({ wallet: true });
        }
      } else {
        if (!auth && defaultOpen) {
          return setOpen({ wallet: true });
        }
        if (!auth) {
          navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
        }

        if (!auth) setAnchorMenu(null);
      }
    },
    500,
    [auth, isInterceptorSetup, isConnected]
  );

  useEffect(() => {
    if (!authCheckPending && authCheckDone && requestAuthDone && !auth) {
      setUnauthorized(true);
    }
  }, [requestAuthDone, auth, authCheckPending, authCheckDone]);

  const team = _.get(userData, "team", {});

  useEffect(async () => {
    if (!getAuthenticatedInfoPending && getAuthenticatedInfoDone && userData) {
      const addrs = _.get(userData, "address", "");
      setShortAddress(team.name);
    }
  }, [userData]);

  useEffect(() => {
    if (_.get(openState, "wallet") && auth) {
      setOpen({ wallet: false });
      setRequestAuthPending(false);
      setRequestAuthDone(false);
    }
    if (isTrial && _.get(openState, "wallet") && isConnected) {
      setOpen({ wallet: false });
      setRequestAuthPending(false);
      setRequestAuthDone(false);
    }
  }, [address, auth]);

  useEffect(() => {
    let filteredConnectors = connectors;

    if (!computer) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name !== "MetaMask";
      });
      //mobile version (only show injected metamask)
    }
    if (!window?.ethereum) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name == "WalletConnect";
      });
      setOnlyWalletConnect(true);
    }
    setCurrentConnectors(filteredConnectors);
  }, [computer, connectors, window]);

  const signIn = async () => {
    try {
      setRequestAuthPending(true);
      setRequestAuthDone(false);

      const nonceRes = await axios.get(`${apiUrl}/api/session/nonce`);
      const nonce = nonceRes.data;

      let res;

      if (ecoSystemData === "aptos") {
        const resultFromAptosMsg = await aptosSignMessage({
          address: address,
          message: `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 hr.`,
          nonce,
        });

        res = await axios.post(
          `${apiUrl}/api/session/sign-in/address`,
          { address: address, entityName },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (ecoSystemData === "solana") {
        const message = new TextEncoder().encode(
          `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 hr.`
        );
        await solanaSignMessage(message);

        res = await axios.post(
          `${apiUrl}/api/session/sign-in/address`,
          { address: address, entityName },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (ecoSystemData === "evm") {
        const message = await createSiweMessage(
          address,
          `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 hr.`,
          nonce
        );

        const signature = await signMessageAsync({ message });

        res = await axios.post(
          `${apiUrl}/api/session/sign-in`,
          JSON.stringify({ message, signature, entityName, nonce }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (res.data && res.status == 200) {
        let sessionId = res.data.sessionId;
        localStorage.setItem("session_id", sessionId);

        let key = generateKey(sessionId);
        let accessToken = res.data.accessToken;
        let encryptedAccessToken = encryptToken(accessToken, key);
        localStorage.setItem("access_token", encryptedAccessToken);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        authCheck(entityName);
        setRequestAuthPending(false);
        setRequestAuthDone(true);
      }
    } catch (err) {
      console.log(err);

      setRequestAuthPending(false);
      setRequestAuthDone(false);
    }
  };

  const createSiweMessage = async (address, statement, nonce) => {
    const numOfHours = config.sessionNumOfHours;
    var expirationTime = new Date();
    expirationTime.setTime(
      expirationTime.getTime() + numOfHours * 60 * 60 * 1000
    );

    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: chain.id,
      nonce: nonce,
      expirationTime: expirationTime.toISOString(),
    });
    return message.prepareMessage();
  };

  const renderSignIn = () => {
    if (auth) {
      return "";
    } else {
      if (isTrial) {
        return "";
      }
      return (
        <>
          {renderConnectedAddress()}
          <div
            style={{
              margin: "14px 0",
              fontWeight: 400,
              fontSize: 17,
              marginTop: 38,
            }}
          >
            Please sign in to continue
          </div>
          <Button
            color={COLOR.SECONDARY}
            size={SIZE.XL}
            fullWidth
            onClick={async () => {
              await signIn();
            }}
          >
            SIGN IN
            <img
              src={require("assets/img/loginW.svg").default}
              style={{ marginLeft: 10, width: 15, height: 15 }}
            />
          </Button>
          <div>
            <Button
              variant={VARIANT.TEXT}
              color={COLOR.INFO}
              style={{ marginTop: 10 }}
              onClick={async () => {
                setRequestAuthPending(false);
                setRequestAuthDone(false);
                disconnect();
                aptosDisconnect();
                solanaDisconnect();
              }}
            >
              Try other wallet
            </Button>
          </div>
        </>
      );
    }
  };

  const renderConnecting = () => {
    return (
      <>
        <StyledWelcomeText>
          Welcome to
          <IconIcMetaCRM width={130} height={19} />
        </StyledWelcomeText>

        <div style={{ marginTop: 40 }}>
          <img src={require("assets/img/loading.gif")} className="lgIcon" />
          <div style={{ fontWeight: 700, fontSize: 12 }}>{"Connecting..."}</div>
        </div>
      </>
    );
  };
  const renderConnectedAddress = () => {
    return (
      <div style={{ fontWeight: 400, fontSize: 14, marginTop: 14 }}>
        Connected Address :{" "}
        <span style={{ fontWeight: 600, fontSize: 23 }}>
          {getShortAddress(address)}
        </span>
      </div>
    );
  };
  const renderLoginPage = () => {
    let content;

    if (requestAuthPending || authCheckPending) {
      content = (
        <div>
          {renderConnectedAddress()}
          <div
            style={{
              marginTop: 33,
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            <img
              style={{ marginTop: "10px" }}
              src={require("assets/img/loading.gif")}
              className="lgIcon"
            />
            <div>Signing in...</div>
          </div>
        </div>
      );
    } else {
      if (unauthorized) {
        content = (
          <div
            style={{
              background: "white",
              maxWidth: 357,
              margin: "0 auto",
              borderRadius: 10,
            }}
          >
            <img
              src={require("assets/img/alertLg.svg").default}
              className="lgIcon"
            />
            <div
              style={{
                color: "#FF3296",
                fontWeight: 500,
                margin: "10px 0px",
                marginBottom: 4,
                fontSize: 14,
              }}
            >
              No authorization, please try other wallet.
            </div>
            <div
              style={{
                border: "1px solid #FF3296",
                padding: "6px 16px",
                fontSize: 12,
                marginBottom: 36,
              }}
            >
              <span style={{ color: "#5C5C5C", fontWeight: 500 }}>
                Connected Address:
              </span>
              <span> </span>
              <span style={{ fontWeight: 700, fontSize: 17 }}>
                {getShortAddress(address)}
              </span>
            </div>
            <Button
              variant={VARIANT.TEXT}
              color={COLOR.INFO}
              onClick={() => {
                const disconnectCallback = () => {
                  disconnect();
                  aptosDisconnect();
                };
                signout(disconnectCallback);
                setUnauthorized(false);
                setRequestAuthDone(false);
                setRequestAuthPending(false);
              }}
            >
              TRY OTHER WALLET
              <img
                src={require("assets/img/loginW.svg").default}
                style={{ marginLeft: 10, width: 15, height: 15 }}
              />
            </Button>
          </div>
        );
      } else {
        content = renderSignIn();
      }
    }

    return (
      <div style={{ textAlign: "center", width: "100%" }}>
        <div className="row">
          <div style={{ fontWeight: 700, fontSize: 15 }}>
            <StyledWelcomeText>
              Welcome to
              <IconIcMetaCRM width={130} height={19} />
            </StyledWelcomeText>
          </div>
        </div>
        {content}
      </div>
    );
  };

  const renderModalContent = () => {
    if (isConnected) {
      // 第二步驟 連結錢包後
      return renderLoginPage();
    } else if (isEcoSystemLoading || isConnecting) {
      //  在拿EcoSystem的資料 || 選定某個錢包後 跳出視窗 還沒按連結的等待畫面
      return renderConnecting();
    } else {
      return <div>{renderChooseWallet()}</div>;
    }
  };
  const renderChooseWallet = () => {
    let walletContent;

    if (onlyWalletConnect) {
      walletContent = renderWalletOnlyWalletConnectStatus();
    } else {
      walletContent = renderWalletLoginWays();

      // TODO Tab切換鏈先留著 以後應該會開啟
      // walletContent = (
      //   <>
      //     <StyledChainTabs
      //       value={tabChainValue}
      //       onChange={handleChangeTabChain}
      //       variant="fullWidth"
      //     >
      //       {tabsArray.map((tab, i) => (
      //         <StyledChainTab
      //           label={tab.label}
      //           key={i}
      //           {...a11yProps(i)}
      //         ></StyledChainTab>
      //       ))}
      //     </StyledChainTabs>

      //     {tabsArray.map((tab, index) => (
      //       <Box
      //         role="tabpanel"
      //         hidden={tabChainValue !== index}
      //         id={`simple-tabpanel-${index}`}
      //         aria-labelledby={`simple-tab-${index}`}
      //         key={`tabContent-${index}`}
      //       >
      //         {tab.content}
      //       </Box>
      //     ))}
      //   </>
      // );
    }

    return (
      <>
        <Box
          className={classes.title}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          Welcome to{" "}
          <IconIcMetaCRM
            style={{ marginLeft: "5px" }}
            width={140}
            height={25}
          ></IconIcMetaCRM>
        </Box>

        <Box
          className={classes.subtitle}
          style={{ margin: "18px auto", maxWidth: 308 }}
          sx={{ color: theme.customColors.grey[600] }}
        >
          {formatMessage({ id: "login.wallet.description" })}
        </Box>
        <div>
          {walletContent}
          {window?.ethereum && (
            <Box
              sx={{
                textAlign: "center",
                color: theme.customColors.grey[600],
                fontWeight: 400,
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              {formatMessage({
                id: "global.popup.loginnotification.description",
              })}
            </Box>
          )}
        </div>
      </>
    );
  };

  const renderModal = (canClose = true) => {
    return (
      <Modal
        style={{ zIndex: 6000 }}
        open={_.get(openState, "wallet")}
        onClose={() => {
          if (canClose) {
            setRequestAuthPending(false);
            setRequestAuthDone(false);
            setOpen({ wallet: false });
          }
        }}
        className={classes.modal}
      >
        <div className={classes.modalPaper}>{renderModalContent()}</div>
      </Modal>
    );
  };

  const renderHeader = () => {
    if (auth) {
      return (
        <div>
          <Button
            variant={VARIANT.TEXT}
            color={COLOR.INFO}
            sx={{ paddingLeft: "15px" }}
            onClick={(e) => setAnchorMenu(e.currentTarget)}
          >
            <div className="betweenRow">
              <div className="startRow formTitle">
                <img
                  src={require(`assets/img/${getUserAvatarIcon(
                    team?.role?._id
                  )}`)}
                  alt="roleImage"
                  className={classes.icon}
                />
                {shortAddress}
              </div>

              <i
                className="meta-crm-icon-ic_arrowLineDown font-size-18"
                style={{ marginLeft: 20 }}
              />
            </div>
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          variant={VARIANT.TEXT}
          color={COLOR.INFO}
          onClick={() => {
            chooseUserLogin();
          }}
        >
          <span className="formTitle">Sign in | Connect</span>
        </Button>
      );
    }
  };
  const chooseUserLogin = async () => {
    if (!auth) {
      disconnect();
      setOpen({ wallet: true });
    }
  };
  if (hidden) {
    return <>{renderModal(true)}</>;
  }
  return (
    <div style={{}}>
      <Modal
        style={{ zIndex: 3000 }}
        open={Boolean(logoutLoading)}
        className={classes.modal}
      >
        <div
          style={{
            background: "#FFFFFF",
            padding: 48,
            borderRadius: 10,
            textAlign: "center",
          }}
        >
          <img src={require("assets/img/loading.gif")} className="lgIcon" />
          <div style={{ fontWeight: 500, fontSize: 16 }}>{"Signing out"}</div>
        </div>
      </Modal>
      {renderModal()}

      {!isTrial && renderHeader()}
    </div>
  );
};

export default ConnectWallet;

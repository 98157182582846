import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  TICKETLIST_FETCH_BEGIN,
  TICKETLIST_FETCH_SUCCESS,
  TICKETLIST_FETCH_FAILURE,
  USER_TICKET_LIST_FETCH_BEGIN,
  USER_TICKET_LIST_FETCH_SUCCESS,
  USER_TICKET_LIST_FETCH_FAILURE,
  TICKET_COUNT_FETCH_SUCCESS,
  TICKET_COUNT_FETCH_ERROR,
  UPDATE_TICKET_LIST_FILTER,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import * as Sentry from "@sentry/react";

export const TICKET_FILTER_INITIAL_STATE = {
  ticketListFilter: {
    ticketStatus: "new",
    page: 1,
    orderData: { sort: "message.created", order: -1 },
    assigneeOrCollaborator: [],
    channel: [],
    issue: [],
    addressStatus: "",
    searchTags: [],
    searchText: "",
  },
};

export function fetchTicketList({ entityName, filter, status }) {
  return (dispatch, getState) => {
    const ticketStatus = status || _.get(filter, "ticketStatus", "");
    if (!ticketStatus) return; //prevent init fetch
    dispatch({
      type: TICKETLIST_FETCH_BEGIN,
    });
    let data = {
      sources: _.get(filter, "channel", []),
      status: _.upperFirst(ticketStatus),
      entityName: entityName,
      page: _.get(filter, "page", 1),
      hasAddress: _.get(filter, "addressStatus", "All"),
      issue: _.get(filter, "issue", ""),
      assigneeOrCollaborator: _.get(filter, "assigneeOrCollaborator", ""),
      searchTags: _.get(filter, "searchTags", ""),
      searchText: _.get(filter, "searchText", ""),
      sort: _.get(filter, "orderData.sort"),
      order: _.get(filter, "orderData.order"),
    };
    const promise = new Promise(async (resolve, reject) => {
      try {
        let ticketList = await axios.get(`${apiUrl}/api/ticket/ticketList`, {
          params: data,
        });
        dispatch({
          type: TICKETLIST_FETCH_SUCCESS,
          data: ticketList.data,

          ticketCounts: ticketList.headers["x-total-count"],
        });

        resolve();
      } catch (err) {
        dispatch({
          type: TICKETLIST_FETCH_FAILURE,
        });
        Sentry.captureException(err);
        // console.log(err)
      }
    });

    return promise;
  };
}
export function updateTicketListFilter(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_TICKET_LIST_FILTER,
      data,
    });
  };
}

export function getStatsCounts({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let stats = await axios.get(
          `${apiUrl}/api/manage/count?entityName=` + entityName
        );

        dispatch({
          type: TICKET_COUNT_FETCH_SUCCESS,
          statsCounts: stats.data,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: TICKET_COUNT_FETCH_ERROR,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function fetchUserTicketList({ entityName, userId }) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_TICKET_LIST_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let userTickets = await axios.get(
          `${apiUrl}/api/ticket/user?userId=${userId}&entityName=` + entityName
        );
        dispatch({
          type: USER_TICKET_LIST_FETCH_SUCCESS,
          data: userTickets.data,
          userId,
        });
      } catch (err) {
        Sentry.captureException(err);
        dispatch({
          type: USER_TICKET_LIST_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function useFetchTicketList() {
  const dispatch = useDispatch();

  const {
    ticketList,
    fetchTicketListPending,
    fetchTicketListDone,
    statsCounts,
    userTicketList,
    ticketCounts,
    fetchUserTicketListPending,
    ticketListFilter,
  } = useSelector(
    (state) => ({
      ticketCounts: state.metadesk.ticketCounts,
      ticketList: state.metadesk.ticketList,
      statsCounts: state.metadesk.statsCounts,
      fetchTicketListPending: state.metadesk.fetchTicketListPending,
      fetchTicketListDone: state.metadesk.fetchTicketListDone,
      userTicketList: state.metadesk.userTicketList,
      fetchUserTicketListPending: state.metadesk.fetchUserTicketListPending,
      ticketListFilter: state.metadesk.ticketListFilter,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTicketList(data));
    },
    [dispatch]
  );
  const boundCountAction = useCallback(
    (data) => {
      return dispatch(getStatsCounts(data));
    },
    [dispatch]
  );

  const fetchUserTicketListAction = useCallback(
    (data) => {
      return dispatch(fetchUserTicketList(data));
    },
    [dispatch]
  );

  const updateTicketListFilterAction = useCallback(
    (data) => {
      return dispatch(updateTicketListFilter(data));
    },
    [dispatch]
  );

  return {
    ticketList,
    statsCounts,
    getStatsCounts: boundCountAction,
    fetchTicketList: boundAction,
    updateTicketListFilter: updateTicketListFilterAction,
    fetchTicketListDone,
    fetchTicketListPending,
    userTicketList,
    ticketCounts,
    ticketListFilter,
    fetchUserTicketList: fetchUserTicketListAction,
    fetchUserTicketListPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TICKETLIST_FETCH_BEGIN:
      return {
        ...state,
        fetchTicketListPending: true,
      };

    case TICKET_COUNT_FETCH_SUCCESS:
      return {
        ...state,
        statsCounts: action.statsCounts,
      };
    case TICKET_COUNT_FETCH_ERROR:
      return {
        ...state,
        statsCounts: "",
      };

    case TICKETLIST_FETCH_SUCCESS:
      return {
        ...state,
        ticketList: action.data,
        fetchTicketListDone: true,
        ticketCounts: action.ticketCounts,
        fetchTicketListPending: false,
      };

    case TICKETLIST_FETCH_FAILURE:
      return {
        ...state,
        fetchTicketListPending: false,
      };

    case USER_TICKET_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchUserTicketListPending: true,
      };

    case USER_TICKET_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userTicketList: {
          ...state.userTicketList,
          [action.userId]: {
            ...action.data,
            userId: action.userId,
            tickets: action.data.tickets,
          },
        },
        fetchUserTicketListPending: false,
      };

    case USER_TICKET_LIST_FETCH_FAILURE:
      return {
        ...state,
        fetchUserTicketListPending: false,
      };

    case UPDATE_TICKET_LIST_FILTER:
      return {
        ...state,
        ticketListFilter: { ...state.ticketListFilter, ...action.data },
      };

    default:
      return state;
  }
}

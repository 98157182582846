import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";

const TextWithTooltip = ({ data, style = {} }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  
  const checkOverflow = () => {
    if (textRef.current) {
      setIsOverflow(textRef.current.scrollHeight > textRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    checkOverflow(); // Initial check on mount

    const handleResize = () => {
      checkOverflow(); // Re-check on window resize
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  const textStyle = {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 12,
    maxHeight: "40px",
    color: "#555",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    ...style,
  };

  return (
    <>
      {isOverflow ? (
        <IconTooltip
          content={data}
          icon={
            <Box ref={textRef} style={{ ...textStyle, cursor: "pointer" }}>
              {data}
            </Box>
          }
        />
      ) : (
        <Box ref={textRef} style={textStyle}>
          {data}
        </Box>
      )}
    </>
  );
};

export default TextWithTooltip;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { get, isEmpty } from "lodash-es";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import { useForm, useFormState } from "react-hook-form";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { apiUrl } from "features/configure";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { selectWidgetModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import Back from "features/metadesk/components/Back";
import { v4 as uuidv4 } from "uuid";
import CustomServiceWidgetContent from "./widget/CustomServiceWidgetContent";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { parseCustomDomainUrl, getBaseUrl } from "features/helpers/utils";
import { useConfirm } from "features/home/ConfirmDialogProvider";

const formDataInit = {
  domainName: "",
};

const IntegrationWidgetSettingContainer = () => {
  const { formatMessage } = useIntl();
  const [options, setOptions] = useState(null);
  const [entityName, isCustomDomain] = useOutletContext();
  const [integrityInfo, setIntegrityInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [widgetInfoIsMounted, setWidgetInfoIsMounted] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isDirtyNow, setIsDirtyNow] = useState(false);
  const confirm = useConfirm();

  const [newTabs, setNewTabs] = useState([]);
  const widgetModule = useSelector(selectWidgetModule);

  let [searchParams] = useSearchParams();

  const fromFromUrl = searchParams.get("from");

  //widgetConfig 是 redux 的state.metadesk.widgetConfig 是一些widget的配置 ex:colorTheme, displacement, url ,新版的會包含 apiKey apiKeyCreated
  const { fetchWidgetConfig, widgetConfig, fetchWidgetConfigPending } =
    useFetchWidgetConfig();

  const [widgetsInfo, setWidgetsInfo] = useState(null);

  const isMultiDomain = get(widgetModule, `multiDomain`);

  const existNameList = useMemo(
    () => widgetConfig?.map((item) => item.name),
    [widgetConfig]
  );

  useEffect(() => {
    if (fromFromUrl === "customization" && widgetInfoIsMounted) {
      handleAddTab();
    }
  }, [fromFromUrl, widgetInfoIsMounted]);

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  const handleSetDirty = (value) => {
    setIsDirtyNow(value);
  };

  const handleFieldChange = (newWidgetItem, index) => {
    const tempWidgetInfo = [...widgetsInfo];
    tempWidgetInfo[index] = newWidgetItem;
    setWidgetsInfo(tempWidgetInfo);
  };

  const handleTabChange = (event, newValue) => {
    if (isDirtyNow) {
      confirm({
        title: formatMessage({
          id: "popup.saveChange.title",
        }),
        content: formatMessage({
          id: "popup.changeNotSaved.description",
        }),
        confirmText: formatMessage({
          id: "global.btn.confirm",
        }),
        cancelText: formatMessage({
          id: "Cancel",
        }),
      })
        .then(() => {
          setTabValue(newValue);
        })
        .catch(() => {});
    } else {
      setTabValue(newValue);
    }
  };

  useEffect(() => {
    if (!isEmpty(widgetConfig)) {
      const tempWidgetInfo = widgetConfig?.map((widgetItem) => {
        return {
          ...widgetItem,
          apiKey: widgetItem.apiKey,
          created: widgetItem.created,
          domainName: widgetItem.url,
          _id: widgetItem._id,
          name: widgetItem.name,
          connected: widgetItem.connected,
        };
      });
      setWidgetsInfo(tempWidgetInfo);
    }
    if (widgetConfig?.length === 0) {
      setWidgetsInfo([]);
    }
  }, [widgetConfig?.length]);

  useEffect(() => {
    if (widgetsInfo && !isEmpty(integrityInfo)) {
      const tabsArray = widgetsInfo?.map((widgetItem, index) => {
        const newExistNameList = existNameList.filter(
          (item, existItemIndex) => index !== existItemIndex
        );
        return {
          _id: widgetItem._id,
          label: widgetItem.name,
          content: (
            <CustomServiceWidgetContent
              widgetItemInfo={widgetItem}
              onFieldChange={handleFieldChange}
              index={index}
              integrityInfo={integrityInfo}
              setOptions={setOptions}
              onDelete={handleDeleteWidget}
              existNameList={newExistNameList}
              onSetDirty={handleSetDirty}
            />
          ),
        };
      });
      setNewTabs(tabsArray);
      setWidgetInfoIsMounted(true);
    }
  }, [widgetsInfo, integrityInfo]);

  const handleAddTabBlock = () => {
    if (isDirtyNow) {
      confirm({
        title: formatMessage({
          id: "popup.saveChange.title",
        }),
        content: formatMessage({
          id: "popup.changeNotSaved.description",
        }),
        confirmText: formatMessage({
          id: "global.btn.confirm",
        }),
        cancelText: formatMessage({
          id: "Cancel",
        }),
      })
        .then(() => {
          handleAddTab();
        })
        .catch(() => {});
    } else {
      handleAddTab();
    }
  };

  const handleAddTab = (event) => {
    const newWidgetItemInfo = {
      apiKey: "",
      created: new Date(),
      domainName: "",
      _id: uuidv4(),
      name: "New Widget",
      isNewAdd: true,
      connected: false,
    };
    if (widgetsInfo) {
      setWidgetsInfo((prev) => [...prev, newWidgetItemInfo]);
    } else {
      setWidgetsInfo([newWidgetItemInfo]);
    }
    setTabValue(newTabs.length);
  };

  const handleDeleteTab = ({ widgetId }) => {
    const tempWidgetsInfo = [...widgetsInfo];
    const result = tempWidgetsInfo.filter((item) => item._id !== widgetId);
    setWidgetsInfo(result);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDeleteWidget = async ({ widgetId, isNewAdd }) => {
    setIsLoading(true);
    try {
      if (!isNewAdd) {
        const { data } = await axios.delete(
          `${apiUrl}/api/manage/widgetConfig/${entityName}/${widgetId}`
        );
      }
      handleDeleteTab({ widgetId });
      fetchWidgetConfig({ entityName });

      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration"
        )
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIntegrity = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/integrity/widget`);
      setIntegrityInfo(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchWidgetConfig({ entityName });
    fetchIntegrity();
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = (e) => {
    setOptions(null);
  };

  const canAdd = useCallback(() => {
    if (isMultiDomain) {
      return true;
    } else {
      if (widgetsInfo?.length === 0 || !widgetsInfo) {
        return true;
      }
      return false;
    }
  }, [isMultiDomain, widgetsInfo?.length]);

  return (
    <>
      <Box sx={{ minHeight: "calc(100vh - 110px)" }}>
        <Back />
        <div className="lgTitle" style={{ marginTop: 18 }}>
          {formatMessage({ id: "integration.widgetInstall.title" })}
        </div>
        <div className="description betweenRow" style={{ lineHeight: "20px" }}>
          {formatMessage({
            id: "integration.widgetInstall.description",
          })}{" "}
          {/* <div>{topActions}</div> */}
        </div>
        <Box marginTop={"15px"}>
          {fetchWidgetConfigPending ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              minHeight={"50vh"}
            >
              <LoadingComponent />
            </Box>
          ) : (
            <Tabs
              tabs={newTabs}
              color={COLOR.SECONDARY}
              canAdd={canAdd()}
              onAdd={handleAddTabBlock}
              addLabel={formatMessage({
                id: "widgetSettings.tab.addNewWidget",
              })}
              variant="scrollable"
              allowScrollButtonsMobile
              value={tabValue}
              onChange={handleTabChange}
            />
          )}
        </Box>
      </Box>

      <BasicDialog
        open={Boolean(options)}
        onClose={handleClose}
        onHandleConfirm={options && options.handleConfirm}
        dialogTitle={options && options.title ? options.title : ""}
        dialogContent={options && options.content ? options.content : ""}
        confirmText={options && options.confirmText ? options.confirmText : ""}
        cancelText={options && options.cancelText ? options.cancelText : ""}
      />
    </>
  );
};

export default IntegrationWidgetSettingContainer;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@material-ui/core";
import { useAccount } from "wagmi";
import { useIntl } from "react-intl";

import { apiUrl } from "features/configure";

import Title from "../components/Title";
import EntityList from "./EntityList";
import WorkspaceWrapper from "../components/WorkspaceWrapper";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import CreateNewWorkspace from "../components/CreateNewWorkspace";

import { StyledMainContainer } from "./WorkspaceView.styled";

const WorkspaceView = () => {
  const { formatMessage } = useIntl();
  const PageTitle = {
    welcome: {
      text: formatMessage({ id: "workspace.title.found" }),
      emoji: "👋",
    },
    notFount: {
      text: formatMessage({ id: "workspaceList.title.notFound" }),
      emoji: "🤷‍♀️",
    },
  };
  const { address, isConnected } = useAccount({});
  const [entityList, setEntityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(PageTitle.welcome);

  useEffect(() => {
    if (isConnected) {
      getEntityList();
    } else {
      window.location.href = "/workspace/login";
    }
  }, [address]);

  async function getEntityList() {
    setIsLoading(true);
    const result = await axios.get(`${apiUrl}/api/workspace`, {
      params: { address },
      responseType: "json",
    });
    if (result.data.success) {
      const entities = result.data.entities;
      setEntityList(entities);
      if (!entities.length) {
        setTitle(PageTitle.notFount);
      }
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <Box style={{ height: "100vh" }}>
        <LoadingComponent width={80} height={80} withText={false} />
      </Box>
    );
  }

  return (
    <>
      <WorkspaceWrapper isShowBrandIcon={false}>
        <StyledMainContainer>
          <Title title={title} />
          <EntityList memberAddress={address} entityList={entityList} />
          <CreateNewWorkspace />
        </StyledMainContainer>
      </WorkspaceWrapper>
    </>
  );
};

export default WorkspaceView;

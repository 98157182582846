import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import CustomTabs from "components/CustomTabs/Tabs";
import { useFetchCurveDetails } from "./redux/fetchCurveDetails";
import { useFetchCurveOnchainDetails } from "./redux/fetchCurveOnchainDetails";
import { useFetchPrice } from "features/price/fetchPrice";
import axios from "axios";
import { useFetchCurveTxs } from "./redux/fetchCurveTxs";
import { formatTvl } from "../../helpers/format";
import {
  convertAmountFromRawNumber,
  formatValue,
} from "features/helpers/bignumber";
import { mapPoolToken, mapPoolName } from "./utils";
import UserTitle from "../components/UserTitle";
import UserDetail from "../components/UserDetail";
import TicketTable from "../components/TicketTable";
import BigNumber from "bignumber.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import { Loading } from "features/common";
import Transaction from "../components/Transaction";
import { useFetchUserWalletInfo } from "../redux/fetchUserWalletInfo";
import { images, defaultImage } from "features/configure";
import { useIntl } from "react-intl";
const useStyles = makeStyles((theme) => ({
  input: {
    background: "#FFFFFF",
    padding: "2px 10px",
    margin: "5px 0",
    border: "1px solid #7B61FF",
    borderRadius: 2,
  },
  row: {
    background: "white",
    margin: "5px 0",
    padding: "10px",
    fontSize: 12,
    fontWeight: 700,
    borderBottom: "1px solid #d6d6d6",
  },
  title: { color: "#777777", fontSize: 12, fontWeight: 400 },

  value: { fontWeight: 700, fontSize: 14, marginLeft: 4, color: "#383538" },
  coinImagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(20px * 2)", // Width is calculated based on 2 images (100px each) + 4*8px (margin for each image)
    marginRight: 10,
  },
  coinImageWrapper: {
    margin: theme.spacing(0),
    boxSizing: "border-box",
    flex: "0 0 calc(50% - 8px * 2)",
  },
  coinImage: {
    width: 20,
    height: 20,
    display: "block",
    margin: "0 auto",
  },
}));

const CurveUserInfo = ({
  ticketListData,
  selectedUserId,
  selectedUserAddress,
  entityName,
  isFetching,
  fetchUserObjs,
}) => {
  const classes = useStyles();
  const [curveNetWorth, setCurveNetWorth] = useState(0);
  const [veCRVBalances, setVeCRVBalances] = useState(0);
  const [distributeType, setDistributeType] = useState(0);
  const { userWalletInfos, fetchUserWalletInfo } = fetchUserObjs();
  const userInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, {});
  const { curveDetails, fetchCurveDetails, fetchCurveDetailsPending } =
    useFetchCurveDetails();
  const { formatMessage } = useIntl();
  const { curveOnchainDetails, fetchCurveOnchainDetails } =
    useFetchCurveOnchainDetails();
  const { curveTxs, fetchCurveTxs, fetchCurveTxsPending } = useFetchCurveTxs();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPool, setSelectedPool] = useState();
  const [allPools, setAllPools] = useState({});
  const { fetchPrice, priceData } = useFetchPrice();

  useEffect(() => {
    fetchPrice();
    getCurveTVLData();
    const id = setInterval(() => {
      fetchPrice();
    }, 1000000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (selectedUserAddress) {
      fetchCurveDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchCurveOnchainDetails({ selectedUserAddress: selectedUserAddress });
      fetchCurveTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
    }
  }, [selectedUserAddress]);

  useEffect(() => {
    let netWorth = 0;
    if (
      userInfo &&
      _.find(userInfo.onChainList, (obj) => {
        return obj.name == "Curve";
      })
    ) {
      let curveDataDebank = _.find(userInfo.onChainList, (obj) => {
        return obj.name == "Curve";
      });

      netWorth = curveDataDebank.net_usd_value;
    }
    setCurveNetWorth(netWorth);
  }, [userInfo]);

  useEffect(() => {
    const allProtocolsDataFetched = _.every(userInfo.updatedList, {
      protocolDataFetching: false,
    });

    if (allProtocolsDataFetched && selectedUserAddress) {
      fetchCurveDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchCurveOnchainDetails({ selectedUserAddress: selectedUserAddress });
      fetchCurveTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
    }
  }, [userInfo.updatedList]);

  useEffect(() => {
    let veCRVBalances = 0;
    if (
      curveOnchainDetails &&
      curveOnchainDetails["pools"] &&
      curveOnchainDetails["pools"].length > 0
    ) {
      const veCRV = _.find(curveOnchainDetails["pools"], { name: "veCRV" });
      veCRVBalances = veCRV ? veCRV.balances : 0;
    }
    setVeCRVBalances(veCRVBalances);
  }, [curveOnchainDetails]);

  const getCurveTVLData = async () => {
    try {
      await axios.get(`${apiUrl}/api/proxy/curvepools`).then((res) => {
        setAllPools(res.data);
      });
    } catch (error) {}
  };

  const renderTxs = (data, loading) => {
    const renderTxsContentWithData = () => (
      <Transaction
        data={data}
        renderTxContentBasedOnABI={renderTxContentBasedOnABI}
      />
    );
    return (
      <div>
        <CustomDataFetching
          loading={loading}
          hasData={data && data.length > 0}
          contents={renderTxsContentWithData}
        ></CustomDataFetching>
      </div>
    );
  };

  const renderToken = (tokenData, value) => {
    let coinAddress = tokenData.address && tokenData.address.toLowerCase();
    return (
      <div className="startRow">
        <img
          style={{ width: 27, height: 27, marginRight: 5 }}
          src={`https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${coinAddress}.png`}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = require(`assets/img/empty-token.png`);
          }}
        />

        <div>
          <div style={{ color: "#555555" }}>{_.get(tokenData, "name")}</div>
          {value > 0 && (
            <div style={{ color: "#555555", fontWeight: 700 }}>
              {new BigNumber(
                convertAmountFromRawNumber(
                  value,
                  _.get(tokenData, "decimals", 18)
                )
              ).toFormat(3)}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCoinImages = (poolAddress) => {
    if (!poolAddress) {
      return;
    }

    let coinAddresses = [];
    if (
      allPools &&
      allPools[poolAddress.toUpperCase()] &&
      allPools[poolAddress.toUpperCase()]["coins"]
    ) {
      coinAddresses = _.map(
        allPools[poolAddress.toUpperCase()]["coins"],
        (coin) => {
          return coin.address.toLowerCase();
        }
      );
    }

    return (
      <div className={classes.coinImagesContainer}>
        {coinAddresses.map((coinAddress, index) => (
          <div key={index} className={classes.coinImageWrapper}>
            <img
              src={`https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${coinAddress}.png`}
              alt={""}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = require(`assets/img/empty-token.png`);
              }}
              className={classes.coinImage}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderExchangeTxContent = (row) => {
    const { inputToken, outputToken, inputValue, outputValue } =
      row.argsMapping;
    return (
      <div className="row">
        {renderToken(mapPoolToken(row.pool.address, inputToken), inputValue)}
        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(mapPoolToken(row.pool.address, outputToken), outputValue)}
      </div>
    );
  };

  const renderExchangeUnderlyingTxContent = (row) => {
    const { inputToken, outputToken, inputValue, outputValue } =
      row.argsMapping;

    return (
      <div className="row">
        {renderToken(mapPoolToken(row.pool.address, inputToken), inputValue)}
        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(mapPoolToken(row.pool.address, outputToken), outputValue)}
      </div>
    );
  };

  const renderAddLiquidityTxContent = (row) => {
    const { inputValues, outputValue } = row.argsMapping;

    return (
      <div className="row">
        <div>
          {_.map(inputValues, (value, i) => (
            <div style={{ marginBottom: 5 }}>
              {renderToken(mapPoolToken(row.pool.address, i), value)}
            </div>
          ))}
        </div>

        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(
          { name: mapPoolName(row.pool.address) + " LP", decimals: 18 },
          outputValue
        )}
      </div>
    );
  };

  const renderRemoveLiquidityTxContent = (row) => {
    const { inputValue, outputValues } = row.argsMapping;

    return (
      <div className="row">
        {renderToken(
          { name: mapPoolName(row.pool.address) + " LP", decimals: 18 },
          inputValue
        )}

        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        <div>
          {_.map(
            outputValues,
            (value, i) =>
              value !== "0" && (
                <div style={{ marginBottom: 5 }}>
                  {renderToken(mapPoolToken(row.pool.address, i), value)}
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  const renderRemoveLiquidityOneCoinTxContent = (row) => {
    const { inputValue, outputToken, outputValue } = row.argsMapping;
    return (
      <div className="row">
        {renderToken(
          { name: mapPoolName(row.pool.address) + " LP", decimals: 18 },
          inputValue
        )}
        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(mapPoolToken(row.pool.address, outputToken), outputValue)}
      </div>
    );
  };

  const renderRemoveLiquidityImbalanceTxContent = (row) => {
    const { inputValue, outputValues } = row.argsMapping;

    return (
      <div className="row">
        {renderToken(
          { name: mapPoolName(row.pool.address) + " LP", decimals: 18 },
          inputValue
        )}

        <img
          style={{ width: 23, height: 23, margin: 10 }}
          src={require("assets/img/swap.svg").default}
        />
        <div>
          {_.map(
            outputValues,
            (value, i) =>
              value !== "0" && (
                <div style={{ marginBottom: 5 }}>
                  {renderToken(mapPoolToken(row.pool.address, i), value)}
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  const renderTxContentBasedOnABI = (row) => {
    switch (row.method) {
      case "exchange":
        return renderExchangeTxContent(row);
      case "exchange_underlying":
        return renderExchangeUnderlyingTxContent(row);
      case "add_liquidity":
        return renderAddLiquidityTxContent(row);
      case "remove_liquidity":
        return renderRemoveLiquidityTxContent(row);
      case "remove_liquidity_one_coin":
        return renderRemoveLiquidityOneCoinTxContent(row);
      case "remove_liquidity_imbalance":
        return renderRemoveLiquidityImbalanceTxContent(row);
      default:
        console.log(`Not yet supported txContentBasedOnABI`);
    }
  };

  const renderTokenLabel = (label, value, icon, extra) => {
    return (
      <div className="startRow" style={{ width: 115, margin: 5 }}>
        <img
          src={
            _.startsWith(icon, "https://")
              ? icon
              : require(`assets/img/${icon}`)
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = require(`assets/img/empty-token.png`);
          }}
          className="smIcon"
        />
        <div>
          <div style={{ fontSize: 11 }}>{label}</div>
          <div style={{ fontSize: 12 }}>
            {value}
            <span
              style={{
                fontSize: 10,
                color: "#7B61FF",
                fontWeight: 400,
                marginLeft: 3,
              }}
            >
              {extra}
            </span>
          </div>
        </div>
      </div>
    );
  };

  let tabs = [
    {
      label: "Overview",
      content: <UserDetail userInfo={userInfo} />,
    },
    {
      label: "Distribution",
      content: (
        <div>
          <div className={`infoHeader startRow`}>
            {formatMessage({
              id: "userInfoContent.curveUserInfo.distribution",
            })}
          </div>
          <CustomDataFetching
            loading={false}
            hasData={curveDetails && curveDetails.length > 0}
            contents={() => {
              return (
                <>
                  <div
                    className="startRow"
                    style={{
                      color: "#7B61FF",
                      fontWeight: 500,
                      padding: 5,
                      width: "calc(100% - 30px)",
                    }}
                  >
                    <div className="w40">
                      {formatMessage({ id: "userInfoContent.userInfo.pools" })}
                    </div>
                    <div className="w20">
                      {formatMessage({
                        id: "userInfoContent.curveUserInfo.type",
                      })}
                    </div>
                    <div className="w20">
                      {formatMessage({
                        id: "userInfoContent.curveUserInfo.tvl",
                      })}
                    </div>
                    <div className="w20">
                      {formatMessage({
                        id: "userInfoContent.userDetail.value",
                      })}
                    </div>
                  </div>
                  {curveDetails.map((row, i) => {
                    return (
                      <>
                        <Card
                          title={
                            <CardTitle
                              data={[
                                {
                                  title: (
                                    <div className="startRow">
                                      {renderCoinImages(row.address)}
                                      {_.truncate(_.get(row, "name", ""))}
                                    </div>
                                  ),
                                  className: "w40",
                                },
                                {
                                  title: <span>{_.get(row, "type", "")}</span>,
                                  className: "w20",
                                },
                                {
                                  title: (
                                    <div>
                                      {_.keys(allPools).length > 0
                                        ? formatTvl(
                                            _.get(
                                              allPools,
                                              row.address.toUpperCase()
                                            )["usdTotal"],
                                            1
                                          )
                                        : ""}
                                    </div>
                                  ),
                                  className: "w20",
                                },
                                {
                                  title: (
                                    <div style={{ fontWeight: 900 }}>
                                      $
                                      {formatValue(
                                        _.get(row, "totalValue", "-")
                                      )}
                                    </div>
                                  ),
                                  className: "w20",
                                },
                              ]}
                            />
                          }
                          content={
                            <div
                              style={{
                                width: "100%",
                                maxHeight: 400,
                                overflow: "auto",
                                color: "#555555",
                                fontWeight: 700,
                              }}
                            >
                              <CustomTabs
                                sm="true"
                                tabIndex={distributeType}
                                handleChange={(e, newValue) =>
                                  setDistributeType(newValue)
                                }
                                tabs={[
                                  {
                                    label: "Swap",
                                    content: renderTxs(
                                      _.filter(curveTxs, (tx) => {
                                        return (
                                          (tx.method == "exchange" ||
                                            tx.method ==
                                              "exchange_underlying") &&
                                          tx.pool.address == selectedPool
                                        );
                                      }),
                                      fetchCurveTxsPending
                                    ),
                                  },
                                  {
                                    label: "Deposit LP",
                                    content: (
                                      <div>
                                        <div
                                          style={{
                                            padding: 10,
                                            fontSize: 12,
                                          }}
                                        >
                                          <Grid container>
                                            <Grid item xs={5}>
                                              <div>LP Tokens Holding</div>
                                              <div
                                                style={{
                                                  fontWeight: 900,
                                                  fontSize: 18,
                                                }}
                                              >
                                                {formatValue(
                                                  _.get(row, "balances", "-")
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                              <div
                                                style={{
                                                  borderLeft:
                                                    "1.5px solid #A5A5A5",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {allPools[
                                                  row.address.toUpperCase()
                                                ] &&
                                                allPools[
                                                  row.address.toUpperCase()
                                                ]["coins"]
                                                  ? _.map(
                                                      allPools[
                                                        row.address.toUpperCase()
                                                      ]["coins"],
                                                      (coin) => {
                                                        return renderTokenLabel(
                                                          coin.symbol,
                                                          `$${formatValue(
                                                            (parseFloat(
                                                              row.totalValue
                                                            ) *
                                                              parseFloat(
                                                                convertAmountFromRawNumber(
                                                                  coin.poolBalance,
                                                                  parseFloat(
                                                                    coin.decimals
                                                                  )
                                                                ).toString()
                                                              )) /
                                                              _.reduce(
                                                                allPools[
                                                                  row.address.toUpperCase()
                                                                ]["coins"],
                                                                (sum, c) => {
                                                                  return (
                                                                    parseFloat(
                                                                      convertAmountFromRawNumber(
                                                                        c.poolBalance,
                                                                        parseFloat(
                                                                          c.decimals
                                                                        )
                                                                      ).toString()
                                                                    ) + sum
                                                                  );
                                                                },
                                                                0
                                                              )
                                                          )}`,
                                                          `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${coin.address.toLowerCase()}.png`,
                                                          `${(
                                                            (100 *
                                                              parseFloat(
                                                                convertAmountFromRawNumber(
                                                                  coin.poolBalance,
                                                                  parseFloat(
                                                                    coin.decimals
                                                                  )
                                                                ).toString()
                                                              )) /
                                                            _.reduce(
                                                              allPools[
                                                                row.address.toUpperCase()
                                                              ]["coins"],
                                                              (sum, c) => {
                                                                return (
                                                                  parseFloat(
                                                                    convertAmountFromRawNumber(
                                                                      c.poolBalance,
                                                                      parseFloat(
                                                                        c.decimals
                                                                      )
                                                                    ).toString()
                                                                  ) + sum
                                                                );
                                                              },
                                                              0
                                                            )
                                                          ).toFixed(0)}%`
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </div>
                                            </Grid>
                                          </Grid>

                                          <div>
                                            {formatMessage({
                                              id: "global.demographic.transaction",
                                            })}
                                          </div>
                                        </div>
                                        {renderTxs(
                                          _.filter(curveTxs, (tx) => {
                                            return (
                                              tx.method == "add_liquidity" &&
                                              tx.pool.address == selectedPool
                                            );
                                          }),
                                          fetchCurveTxsPending
                                        )}
                                      </div>
                                    ),
                                  },
                                  {
                                    label: "Withdraw LP",
                                    content: (
                                      <div>
                                        <div
                                          style={{
                                            padding: 10,
                                            fontSize: 12,
                                          }}
                                        >
                                          <Grid container>
                                            <Grid item xs={5}>
                                              <div>LP Tokens Holding</div>
                                              <div
                                                style={{
                                                  fontWeight: 900,
                                                  fontSize: 18,
                                                }}
                                              >
                                                {row.balances
                                                  ? _.get(
                                                      row,
                                                      "balances",
                                                      "-"
                                                    ).toFixed(2)
                                                  : "-"}
                                              </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                              <div
                                                style={{
                                                  borderLeft:
                                                    "1.5px solid #A5A5A5",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {allPools[
                                                  row.address.toUpperCase()
                                                ] &&
                                                allPools[
                                                  row.address.toUpperCase()
                                                ]["coins"]
                                                  ? _.map(
                                                      allPools[
                                                        row.address.toUpperCase()
                                                      ]["coins"],
                                                      (coin) => {
                                                        return renderTokenLabel(
                                                          coin.symbol,
                                                          `$${formatValue(
                                                            (parseFloat(
                                                              row.totalValue
                                                            ) *
                                                              parseFloat(
                                                                convertAmountFromRawNumber(
                                                                  coin.poolBalance,
                                                                  parseFloat(
                                                                    coin.decimals
                                                                  )
                                                                ).toString()
                                                              )) /
                                                              _.reduce(
                                                                allPools[
                                                                  row.address.toUpperCase()
                                                                ]["coins"],
                                                                (sum, c) => {
                                                                  return (
                                                                    parseFloat(
                                                                      convertAmountFromRawNumber(
                                                                        c.poolBalance,
                                                                        parseFloat(
                                                                          c.decimals
                                                                        )
                                                                      ).toString()
                                                                    ) + sum
                                                                  );
                                                                },
                                                                0
                                                              )
                                                          )}`,
                                                          `https://cdn.jsdelivr.net/gh/curvefi/curve-assets/images/assets/${coin.address.toLowerCase()}.png`,
                                                          `${(
                                                            (100 *
                                                              parseFloat(
                                                                convertAmountFromRawNumber(
                                                                  coin.poolBalance,
                                                                  parseFloat(
                                                                    coin.decimals
                                                                  )
                                                                ).toString()
                                                              )) /
                                                            _.reduce(
                                                              allPools[
                                                                row.address.toUpperCase()
                                                              ]["coins"],
                                                              (sum, c) => {
                                                                return (
                                                                  parseFloat(
                                                                    convertAmountFromRawNumber(
                                                                      c.poolBalance,
                                                                      parseFloat(
                                                                        c.decimals
                                                                      )
                                                                    ).toString()
                                                                  ) + sum
                                                                );
                                                              },
                                                              0
                                                            )
                                                          ).toFixed(0)}%`
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </div>
                                            </Grid>
                                          </Grid>

                                          <div>Transactions</div>
                                        </div>
                                        {renderTxs(
                                          _.filter(curveTxs, (tx) => {
                                            return (
                                              (tx.method ==
                                                "remove_liquidity_one_coin" ||
                                                tx.method ==
                                                  "remove_liquidity" ||
                                                tx.method ==
                                                  "remove_liquidity_imbalance") &&
                                              tx.pool.address == selectedPool
                                            );
                                          }),
                                          fetchCurveTxsPending
                                        )}
                                      </div>
                                    ),
                                  },
                                  {
                                    label: "Claim",
                                    content: (
                                      <div>
                                        <div
                                          style={{
                                            padding: 10,
                                            fontSize: 12,
                                          }}
                                        >
                                          <div style={{ marginTop: 20 }}>
                                            {formatMessage({
                                              id: "global.demographic.transaction",
                                            })}
                                          </div>
                                        </div>
                                        {renderTxs(
                                          _.filter(curveTxs, (tx) => {
                                            return (
                                              ["mint", "mint_many"].includes(
                                                tx.method
                                              ) &&
                                              tx.pool.address == selectedPool
                                            );
                                          }),
                                          fetchCurveTxsPending
                                        )}
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          }
                          switchable={true}
                          handleExpand={{
                            currentPool: selectedPool,
                            thisPool: _.get(row, "address", ""),
                          }}
                          handleExpandAction={(event, expanded) => {
                            expanded
                              ? setSelectedPool(_.get(row, "address", ""))
                              : setSelectedPool();
                          }}
                        />
                      </>
                    );
                  })}
                </>
              );
            }}
          ></CustomDataFetching>
        </div>
      ),
    },
    {
      label: "Transactions",
      content: (
        <div>
          <div className={`infoHeader startRow`}>
            {formatMessage({ id: "global.demographic.transaction" })}
          </div>
          {renderTxs(curveTxs, fetchCurveTxsPending)}
        </div>
      ),
    },
    {
      label: "Atomic Swap",
      content: (
        <div>
          <div className={`infoHeader startRow`}>
            {formatMessage({ id: "userInfoContent.curveUserInfo.atomicSwap" })}
          </div>
          <CustomDataFetching
            loading={false}
            hasData={
              curveOnchainDetails &&
              curveOnchainDetails["curveSynthSwapNFTList"] &&
              curveOnchainDetails["curveSynthSwapNFTList"].length > 0
            }
            contents={() => {
              return (
                <CustomTable
                  style={{ fontSize: 12 }}
                  headers={["NFT Address", "TokenID"]}
                  contents={curveOnchainDetails["curveSynthSwapNFTList"].map(
                    (row, i) => {
                      return [
                        <div className="startRow">
                          {_.get(row, "contractAddress", "")}
                        </div>,
                        <div>
                          <span> {_.get(row, "tokenId", "")}</span>
                        </div>,
                      ];
                    }
                  )}
                />
              );
            }}
          ></CustomDataFetching>
        </div>
      ),
    },
    {
      label: "Boost Source",
      content: (
        <div>
          <div className="startRow infoHeader">
            {formatMessage({ id: "userInfoContent.curveUserInfo.boostSource" })}
          </div>
          <CustomDataFetching
            loading={false}
            hasData={curveDetails && curveDetails.length > 0}
            contents={() => {
              return (
                <>
                  <div
                    className="startRow"
                    style={{
                      color: "#7B61FF",
                      fontSize: 10,
                      fontWeight: 700,
                      padding: 5,
                      width: "calc(100% - 30px)",
                    }}
                  >
                    <div className="w40">
                      {formatMessage({ id: "userInfoContent.userInfo.pools" })}
                    </div>
                    <div className="w20">
                      {formatMessage({
                        id: "userInfoContent.curveUserInfo.type",
                      })}
                    </div>
                    <div className="w20">
                      {formatMessage({
                        id: "userInfoContent.userDetail.value",
                      })}
                    </div>
                  </div>
                  {curveDetails.map((row, i) => {
                    return (
                      <>
                        <Card
                          title={
                            <CardTitle
                              data={[
                                {
                                  title: (
                                    <div className="startRow">
                                      {renderCoinImages(row.address)}
                                      {_.truncate(_.get(row, "name", ""))}
                                    </div>
                                  ),
                                  className: "w40",
                                },
                                {
                                  title: <span>{_.get(row, "type", "")}</span>,
                                  className: "w20",
                                },
                                {
                                  title: (
                                    <div style={{ fontWeight: 900 }}>
                                      $
                                      {formatValue(
                                        _.get(row, "totalValue", 0),
                                        0
                                      )}
                                    </div>
                                  ),
                                  className: "w20",
                                },
                              ]}
                            />
                          }
                          content={
                            <div
                              style={{
                                width: "100%",
                                maxHeight: 400,
                                overflow: "auto",
                                color: "#555555",
                                fontWeight: 700,
                              }}
                            >
                              <div className={`${classes.row} startRow`}>
                                <div style={{ width: "57%" }}>
                                  {formatMessage({
                                    id: "ticketDetail.chatItem.from",
                                  })}
                                  <img
                                    src={require(`assets/img/crv.svg`).default}
                                    className="smIcon"
                                  />
                                  CURVE
                                </div>
                                <div>
                                  ${formatValue(_.get(row, "curveValue", 0), 0)}
                                </div>
                              </div>
                              <div className={`${classes.row} startRow`}>
                                <div style={{ width: "57%" }}>
                                  {formatMessage({
                                    id: "ticketDetail.chatItem.from",
                                  })}{" "}
                                  <img
                                    src={
                                      require(`assets/img/convex.svg`).default
                                    }
                                    className="smIcon"
                                  />
                                  CONVEX
                                </div>
                                <div>
                                  $
                                  {formatValue(_.get(row, "convexValue", 0), 0)}
                                </div>
                              </div>
                            </div>
                          }
                          switchable={true}
                          handleExpand={{
                            currentPool: selectedPool,
                            thisPool: _.get(row, "address", ""),
                          }}
                          handleExpandAction={(event, expanded) => {
                            expanded
                              ? setSelectedPool(_.get(row, "address", ""))
                              : setSelectedPool();
                          }}
                        />
                      </>
                    );
                  })}
                </>
              );
            }}
          ></CustomDataFetching>
        </div>
      ),
    },
  ];
  if (ticketListData) {
    tabs = [
      {
        label: `Tickets (${_.get(ticketListData, "tickets.length", 0)})`,
        content: (
          <div style={{ padding: "0 12px" }}>
            {ticketListData.tickets && (
              <TicketTable
                containerStyle={{ height: "54vh" }}
                userId={selectedUserId}
                ticketList={ticketListData.tickets}
                entityName={entityName}
              />
            )}
          </div>
        ),
      },
      ...tabs,
    ];
  }

  const renderContent = () => {
    return (
      <div>
        <CustomTabs
          sm="true"
          variant={ticketListData ? "" : "scrollable"}
          scrollButtons="on"
          type={"metaform"}
          tabIndex={selectedTab}
          handleChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={tabs}
        />
      </div>
    );
  };

  return (
    <div>
      <UserTitle
        selectedUserId={selectedUserId}
        paddingLeft={ticketListData}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        fetchUserObjs={fetchUserObjs}
        extra={
          <div>
            <div className="startRow" style={{ marginBottom: 10 }}>
              <img
                src={require("assets/img/crv.svg").default}
                className="smIcon"
                style={{ marginRight: 8 }}
              />
              <div
                style={{
                  minWidth: 200,
                }}
              >
                <div className={classes.title}>
                  {formatMessage({
                    id: "userInfoContent.curveUserInfo.totalBalances",
                  })}{" "}
                  <span className={classes.value}>
                    ${new BigNumber(curveNetWorth).toFormat(2)}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="startRow">
                <img
                  src={require("assets/img/veCRV.svg").default}
                  className="smIcon"
                  style={{ marginRight: 8 }}
                />
                <div
                  className={classes.title}
                  style={{ padding: "10px 0", borderTop: "1px solid #d6d6d6" }}
                >
                  veCRV{" "}
                  {formatMessage({ id: "userInfoContent.userDetail.balances" })}
                  <span className={classes.value}>
                    ${new BigNumber(veCRVBalances).toFormat(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <div
        style={{
          overflowX: "auto",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Loading
          open={fetchCurveDetailsPending || fetchCurveTxsPending || isFetching}
          text={isFetching ? "Data Fetching..." : "Loading..."}
          fullScreen={false}
          topPos="50vh"
        />
        {renderContent()}
      </div>
    </div>
  );
};

export default CurveUserInfo;

import React, { useEffect, useState, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: 10,

    backgroundColor: "transparent",
    border: "0px",
    boxShadow: "none",
    "&.Mui-expanded": {
      marginTop: 0,
    },
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  switchableTitle: {
    borderBottom: "1px solid #d6d6d6",
    "&.Mui-expanded": {
      borderBottom: "1px solid #d6d6d6",
    },
  },
  dark: {
    backgroundColor: theme.palette.background.paper,
    color: "black",
    fontWeight: "bold",
    fontSize: 18,
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  bright: {
    backgroundColor: theme.palette.background.secondary,

    color: "black",
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  accordionContent: {
    margin: "8px 0",
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  paper: {
    backgroundColor: "#E6E6E6",
    color: "black",
    padding: 0,
    minHeight: 80,
  },
}));

const Card = ({
  title,
  content,
  switchable = false,
  dark = true,
  handleExpand,
  handleExpandAction,
}) => {
  const classes = useStyles();
  if (!handleExpand) {
    return (
      <Accordion
        defaultExpanded={!switchable}
        disabled={!switchable}
        classes={{
          root: classes.accordion,
        }}
      >
        <AccordionSummary
          classes={{
            root: `${dark ? classes.dark : classes.bright} ${
              classes.switchableTitle
            }`,
            content: classes.accordionContent,
          }}
          expandIcon={
            switchable ? (
              <ExpandMoreIcon
                className={dark ? classes.dark : classes.bright}
              />
            ) : (
              ""
            )
          }
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={classes.paper}>{content}</AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <Accordion
        defaultExpanded={!switchable}
        disabled={!switchable}
        classes={{
          root: classes.accordion,
        }}
        expanded={handleExpand.currentPool === handleExpand.thisPool}
        onChange={(event, expanded) => {
          handleExpandAction(event, expanded);
        }}
      >
        <AccordionSummary
          classes={{
            root: `${dark ? classes.dark : classes.bright} ${
              classes.switchableTitle
            }`,
            content: classes.accordionContent,
          }}
          expandIcon={
            switchable ? (
              <ExpandMoreIcon
                className={dark ? classes.dark : classes.bright}
              />
            ) : (
              ""
            )
          }
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={classes.paper}>{content}</AccordionDetails>
      </Accordion>
    );
  }
};

export default memo(Card);

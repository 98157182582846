import React, { useState, useEffect, useRef, useMemo } from "react";
import { get, isEmpty } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import { Grid } from "@mui/material";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import { useForm, useFormState, Controller } from "react-hook-form";
import {
  formatCalendarDateAndTime,
  isVersionLessThan,
  validateURL,
} from "features/helpers/utils";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import { parseCustomDomainUrl, getBaseUrl } from "features/helpers/utils";
import { apiUrl } from "features/configure";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { widgetJsUrl } from "features/configure";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useZoomIn } from "features/home/ZoomInDialogProvider";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import {
  selectServiceModule,
  selectWidgetVersionCanUpdate,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import Back from "features/metadesk/components/Back";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import {
  StyledFormTitle,
  StyledApiKeyCreatedTime,
  StyledGoToCustomize,
  StyledPreviewImageContainer,
  StyledHoverShowIcon,
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepContainer,
  StyledSettingWidgetStepNumber,
  StyledSettingWidgetStepTitle,
  StyledFormTitleContent,
  StyledSettingWidgetStepDescribe,
  StyledGreyBlock,
} from "features/metadesk/settings/integrations/IntegrationWidgetSetting.styles";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import CodePannel from "components/CodePannel/CodePannel";
import { generateWidgetScript } from "features/configure/appCode";
import SriCodeTab from "features/metadesk/appUpdate/SriCodeTab";

const formDataInit = {
  name: "",
  domainName: "",
  apiKey: "",
};

const removeHttpsPrefix = (url) => {
  if (url?.startsWith("https://")) {
    return url?.replace(/https:\/\//g, "");
  }
  return url;
};

const CustomServiceWidgetContent = ({
  widgetItemInfo,
  onFieldChange,
  integrityInfo,
  index,
  setOptions,
  onDelete,
  existNameList,
  onSetDirty,
}) => {
  const {
    apiKey,
    created: apiKeyCreated,
    domainName,
    _id,
    name,
    isNewAdd,
    connected,
    fixedApiKey,
  } = widgetItemInfo;

  const { formatMessage } = useIntl();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [entityName, isCustomDomain] = useOutletContext();
  const [formData, setFormData] = useState(formDataInit);
  const domainNameForShow = removeHttpsPrefix(formData.domainName);

  const [SRIEnable, setSRIEnable] = useState(true);
  const [testValue, setTestValue] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  // https://stackoverflow.com/questions/76366010/react-input-caret-jumps-to-end-if-value-gets-modified
  const nameInputRef = useRef(null);
  const domainNameInputRef = useRef(null);
  const [activeRef, setActiveRef] = useState(null);

  const confirm = useConfirm();
  const zoomIn = useZoomIn();
  const serviceModule = useSelector(selectServiceModule);
  const ecosystemName = useSelector(selectEcosystemName);

  // const domainNameForShow = removeHttpsPrefix(domainName);

  // widgetObject 只返回 apiKey apiKeyCreated 兩個東西 被包含在 entityConfig 的 widget 裡面
  // const { apiKey, created: apiKeyCreated } = widgetObject;

  const { fetchWidgetConfig, widgetConfig, updateWidgetConfig } =
    useFetchWidgetConfig();

  // 'Undetected' 'Waiting' 'Enabled' 'Failed'
  const widgetIntegrationStatus = connected ? "Enabled" : "Waiting";

  const isGenerateApiKeyButtonShow = useMemo(
    () => !isNewAdd && !fixedApiKey,
    [entityName, isNewAdd]
  );

  const { latestVersion } = useSelector(selectWidgetVersionCanUpdate);
  const canUpdate = isVersionLessThan(
    widgetItemInfo.jsVersion || latestVersion,
    latestVersion
  );

  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid, isDirty },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  useEffect(() => {
    onSetDirty(isDirty);
  }, [isDirty]);

  const isSaveDisabled = () => {
    if (isNewAdd) {
      return !isValid;
    }
    return !isValid || !isDirty;
  };

  const handleCheckRepeat = (value) => {
    return existNameList?.some((item) => item === value.trim())
      ? formatMessage({ id: "global.inputCheck.repeatedName" })
      : true;
  };

  const renderWidgetIntegrationStatus = () => {
    // 新add還未儲存不用顯示
    if (isNewAdd) {
      return null;
    }
    if (widgetIntegrationStatus === "Waiting") {
      return (
        <StatusLabel color={COLOR.WARNING}>
          {formatMessage({
            id: "global.status.waiting",
          })}
        </StatusLabel>
      );
    }
    if (widgetIntegrationStatus === "Enabled") {
      return (
        <StatusLabel color={COLOR.SECONDARY}>
          {formatMessage({
            id: "global.status.enabled",
          })}
        </StatusLabel>
      );
    }
  };

  const trackingAddressCode = `import { useAccount } from "wagmi";
// if your app is using wagmi, you can use useAccount hook
// your app might not use wagmi, and you might need some other way to grab wallet address

// ...
const Component = () => {
  const { address } = useAccount();

  useEffect(() => {
    if (window?.MetaCRMWidget?.manualConnectWallet) {
      window.MetaCRMWidget.manualConnectWallet(address);
    }
    
    const handleConnectWidget = () => {
      window.MetaCRMWidget.manualConnectWallet(address);
    };
    document.addEventListener('MetaCRMLoaded', handleConnectWidget);

    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
    };
  }, [address]);
  // ...
};
  `;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };

  const handleClose = (e) => {
    setOptions(null);
  };

  const handleRegenerateAPI = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/apiKey/${entityName}/widget`,
        {
          widgetId: isNewAdd ? null : widgetItemInfo?._id,
        }
      );
      handleChange({
        event: { target: { value: data.apiKey, name: "apiKey" } },
      });

      const newWidgetItem = { ...widgetItemInfo, apiKey: data.apiKey };
      onFieldChange(newWidgetItem, index);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
  // 要是是新加入的widget 並且沒有apiKey
  useEffect(() => {
    if (!apiKey && isNewAdd && formData.name) {
      handleRegenerateAPI();
    }
  }, [apiKey, isNewAdd, formData.name]);

  const handleRegenerate = () => {
    confirm({
      title: formatMessage({
        id: "popup.regenerateApiKey.title",
      }),
      content: formatMessage({
        id: "popup.regenerateApiKey.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        handleRegenerateAPI();
      })
      .catch(() => {});
  };

  const handleCheckSuccess = () => {
    setOptions({
      title: (
        <Box textAlign={"center"}>
          {formatMessage({
            id: "widgetSettings.headCode.statusDetected",
          })}
        </Box>
      ),
      confirmText: "OK",
      handleConfirm: () => {
        handleClose();
      },
    });
  };

  const handleCheckWaiting = () => {
    setOptions({
      title: (
        <Box textAlign={"center"}>
          {formatMessage({
            id: "popup.statusReturn.title",
          })}
        </Box>
      ),
      content: (
        <Box textAlign={"center"}>
          {formatMessage({
            id: "widgetSettings.headCode.statusWaiting",
          })}
        </Box>
      ),
      confirmText: "OK",
      handleConfirm: () => {
        handleClose();
      },
    });
  };

  const handleCheckInstallWidget = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/widgetConfig/${entityName}`
      );

      const connectStatus = data.find(
        (widgetConfigItem) => widgetConfigItem._id === widgetItemInfo?._id
      )?.connected;

      if (connectStatus) {
        handleCheckSuccess();
        const newWidgetItem = { ...widgetItemInfo, connected: true };
        onFieldChange(newWidgetItem, index);
      } else {
        handleCheckWaiting();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleGoToCustomize = (e) => {
    e.preventDefault();
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/customization/widgetSetting/${_id}`
      )
    );
  };

  useEffect(() => {
    if (!activeRef?.current) return;
    activeRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [widgetItemInfo]);

  useEffect(() => {
    if (!isEmpty(widgetItemInfo)) {
      reset({
        ...formData,
        name: widgetItemInfo.name,
        domainName: widgetItemInfo.domainName,
      });
      setFormData({
        name: widgetItemInfo.name,
        domainName: widgetItemInfo.domainName,
        apiKey: widgetItemInfo.apiKey,
      });
    }
  }, []);

  const handleChange = ({ event, fieldName }) => {
    const { name, value } = event.target;

    if (fieldName === "name") {
      setActiveRef(nameInputRef);
    } else if (fieldName === "domainName") {
      setActiveRef(domainNameInputRef);
    }

    const selectionStart = event.target.selectionStart;
    setCursorPosition(selectionStart);
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formData?.domainName) {
      setValue(
        "domainName",
        formData?.domainName?.startsWith("https://")
          ? formData.domainName
          : `https://${formData.domainName}`,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
  }, [formData.domainName]);

  const SDKsteps = [
    {
      title: formatMessage({
        id: "widgetSettings.apiKey.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.apiKey.description",
      }),
      content: (
        <>
          <StyledFormTitle center item xs={12} md={4}>
            {formatMessage({
              id: "widgetSettings.apiKey.input",
            })}
          </StyledFormTitle>
          <Grid
            position={"relative"}
            item
            xs={12}
            md={isGenerateApiKeyButtonShow ? 6 : 8}
          >
            <InputField
              disabled
              value={formData.apiKey}
              endAdornment={
                <IconButton onClick={() => copyToClipboard(formData.apiKey)}>
                  <i className="meta-crm-icon-ic_copy font-size-24" />
                </IconButton>
              }
            ></InputField>
            <StyledApiKeyCreatedTime>
              <Box
                color={(theme) => theme.customColors.grey[600]}
                size="12px"
                mr="2.5px"
              >
                {formatMessage({
                  id: "plans&billing.usage.recountTime",
                })}{" "}
                :
              </Box>
              <Box
                color={(theme) => theme.customColors.purple[500]}
                size="12px"
              >
                {formatCalendarDateAndTime(apiKeyCreated)}
              </Box>
            </StyledApiKeyCreatedTime>
          </Grid>
          {isGenerateApiKeyButtonShow && (
            <Grid item xs={12} md={2}>
              <Button
                size={SIZE.XL}
                sx={{ width: "100%" }}
                onClick={handleRegenerate}
              >
                {formatMessage({
                  id: "global.btn.regenerate",
                })}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} mb={"16px"}></Grid>
        </>
      ),
    },
    {
      stepNumber: 1,
      title: formatMessage({
        id: "widgetSettings.widgetName.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.widgetName.description",
      }),
      content: (
        <>
          <StyledFormTitle center item xs={12} md={4}>
            <StyledFormTitleContent isRequired>
              {formatMessage({
                id: "global.filterCriteria.name",
              })}
            </StyledFormTitleContent>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            <InputField
              value={formData.name}
              maxLength={15}
              name={`name`}
              placeholder={formatMessage({
                id: "global.placeholder.name",
              })}
              onChange={(event) => {
                // setTestValue(event.target.value);
                handleChange({
                  event,
                });
              }}
              rules={{
                required: {
                  value: true,
                  message: `name ${formatMessage({
                    id: "global.error.required",
                  })}`,
                },
                validate: handleCheckRepeat,
              }}
              control={control}
              errors={get(errorsInfo, "name.message")}
              ref={nameInputRef}
            ></InputField>
          </Grid>
          <Grid item xs={12} mb={"16px"}></Grid>
        </>
      ),
    },
    {
      stepNumber: 2,
      title: formatMessage({
        id: "widgetSettings.domain.title",
      }),
      describe: formatMessage({
        id: "widgetSettings.domain.description",
      }),
      content: (
        <>
          {" "}
          <StyledFormTitle center item xs={12} md={4}>
            {formatMessage({
              id: "widgetSettings.domain.input",
            })}
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            <InputField
              ref={domainNameInputRef}
              placeholder={formatMessage({
                id: "widgetSettings.domain.placeholder",
              })}
              onChange={(event) => {
                handleChange({
                  event,
                });
              }}
              name={`domainName`}
              control={control}
              errors={get(errorsInfo, "domainName.message")}
              value={domainNameForShow}
              prefixWord={"https://"}
              endAdornment={
                <IconButton
                  onClick={() =>
                    copyToClipboard(`https://${domainNameForShow}`)
                  }
                >
                  <i className="meta-crm-icon-ic_copy font-size-24" />
                </IconButton>
              }
              rules={{
                validate: (value) =>
                  validateURL(value) ||
                  formatMessage({
                    id: "global.inputCheck.invalidUrl",
                  }),
              }}
            ></InputField>
          </Grid>
        </>
      ),
    },
    {
      stepNumber: 3,
      title: formatMessage({
        id: "widgetSettings.headCode.title",
      }),
      status: renderWidgetIntegrationStatus,

      titleButton: isNewAdd ? null : (
        <Button
          variant={VARIANT.OUTLINED}
          sx={{ marginLeft: "auto" }}
          disabled={widgetIntegrationStatus === "Enabled"}
          onClick={handleCheckInstallWidget}
        >
          {formatMessage({
            id: "global.btn.checkConnection",
          })}
        </Button>
      ),
      describe: formatMessage(
        {
          id: "widgetSettings.headCode.description",
        },
        {
          br: <br />,
        }
      ),

      content: (
        <>
          <StyledFormTitle item xs={12} md={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              height={"24px"}
              color={canUpdate ? "#FF9D2A" : null}
            >
              {formatMessage({
                id: "snippet.widget.integration",
              })}
              {canUpdate && (
                <Box
                  bgcolor="#FF9D2A"
                  p="2px 6px"
                  fontSize="12px"
                  borderRadius="10px"
                  color="white"
                  ml="8px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      parseCustomDomainUrl(
                        isCustomDomain,
                        entityName,
                        "/settings/general/app/update-list?updateType=widget"
                      )
                    )
                  }
                >
                  {formatMessage({ id: "integration.newVersion.title" })} :
                  {" " + latestVersion}
                </Box>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {/* <Box>
                <CustomSwitch
                  checked={SRIEnable}
                  onClick={() => setSRIEnable(!SRIEnable)}
                />
              </Box> */}
            </Box>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8} mb={2}>
            {!isEmpty(integrityInfo) && apiKey && (
              <SriCodeTab
                sriCode={generateWidgetScript({ integrityInfo, apiKey })}
                nonSriCode={generateWidgetScript({ apiKey })}
              />
            )}
            {!apiKey && (
              <Box position={"relative"}>
                <Box
                  height="100%"
                  width="100%"
                  backgroundColor={(theme) => theme.customColors.grey[300]}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={(theme) => theme.customColors.purple[500]}
                  fontSize={"24px"}
                  fontWeight={"700"}
                  position={"absolute"}
                  zIndex={2}
                  sx={{ opacity: 0.8 }}
                >
                  {formatMessage({
                    id: "widgetSettings.apiKey.generate.hint",
                  })}
                </Box>
                <InputField
                  multiline={true}
                  InputProps={{
                    rows: 9,
                    multiline: true,
                    inputComponent: "textarea",
                  }}
                  disabled
                />
              </Box>
            )}
            <StyledGreyBlock mt={"12px"}>
              <Box
                component="i"
                className="meta-crm-icon-ic_info font-size-16"
                mr="10px"
              />

              <Box>
                {formatMessage({
                  id: "widgetSettings.headCode.instruction",
                })}
              </Box>
            </StyledGreyBlock>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className="description">
              {formatMessage({
                id: "widgetSettings.headCode.preview",
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box style={{ textAlign: "center" }}>
              {SRIEnable ? (
                <StyledPreviewImageContainer
                  onClick={() =>
                    zoomIn({
                      src: require("assets/img/widgetScriptDemo.png"),
                    }).catch(() => {})
                  }
                >
                  <Box
                    component="img"
                    src={require("assets/img/widgetScriptDemo.png")}
                    sx={{ width: "100%", cursor: "pointer" }}
                  />
                  <StyledHoverShowIcon>
                    <i className="meta-crm-icon-ic_bigger font-size-24" />
                  </StyledHoverShowIcon>
                </StyledPreviewImageContainer>
              ) : (
                <StyledPreviewImageContainer
                  onClick={() =>
                    zoomIn({
                      src: require("assets/img/widgetScriptDemo_SRI_false.png"),
                    }).catch(() => {})
                  }
                >
                  <Box
                    component="img"
                    src={require("assets/img/widgetScriptDemo_SRI_false.png")}
                    sx={{ width: "100%" }}
                  />
                  <StyledHoverShowIcon>
                    <i className="meta-crm-icon-ic_bigger font-size-24" />
                  </StyledHoverShowIcon>
                </StyledPreviewImageContainer>
              )}
            </Box>
          </Grid>
        </>
      ),
    },
    {
      stepNumber: 4,
      title: formatMessage({
        id: "widgetSettings.addressIdentification.title",
      }),
      titleHint: `(${formatMessage({
        id: "global.annotation.recommended",
      })})`,
      describe: (
        <>
          <Box>
            {formatMessage(
              {
                id: "widgetSettings.addressIdentification.description",
              },
              {
                br: <br />,
              }
            )}
          </Box>
          <Box
            color={(theme) => theme.customColors.purple[500]}
            sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <Box mr="4px" className="meta-crm-icon-ic_info font-size-16" />
            {formatMessage({
              id: "widgetSettings.addressIdentification.explanation",
            })}
          </Box>
        </>
      ),

      content: (
        <>
          <StyledFormTitle item xs={12} md={4}>
            <Box display={"flex"} alignItems={"center"} height={"24px"}>
              {formatMessage({
                id: "snippet.widget.addressIdentification",
              })}
            </Box>
            <Box className="description">
              {ecosystemName === "Solana" && (
                <Box>
                  To ensure that you can track{" "}
                  <Box
                    sx={{ display: "inline" }}
                    color={(theme) => theme.customColors.purple[500]}
                  >
                    Solflare wallet,
                  </Box>{" "}
                  you must install this method.
                </Box>
              )}
            </Box>
          </StyledFormTitle>
          <Grid position={"relative"} item xs={12} md={8}>
            {!isEmpty(trackingAddressCode) && (
              <CodePannel code={trackingAddressCode} />
            )}
            <StyledGreyBlock mt={"12px"}>
              <Box marginRight="5px">
                <i className="meta-crm-icon-ic_info font-size-16" />
              </Box>

              <Box component="span">
                {formatMessage({
                  id: "metaCRMSDK.walletConnects.addressIdentification.instruction",
                })}
              </Box>
            </StyledGreyBlock>
          </Grid>
        </>
      ),
    },
    ...(!isNewAdd
      ? [
          {
            stepNumber: 5,
            title: formatMessage({
              id: "widgetSettings.goToCustomization.title",
            }),
            describe: formatMessage({
              id: "widgetSettings.goToCustomization.description",
            }),
            content: (
              <Box
                component={Grid}
                item
                xs={12}
                color={(theme) => theme.customColors.purple[500]}
              >
                <StyledGoToCustomize
                  variant={VARIANT.TEXT}
                  color={COLOR.SECONDARY}
                  onClick={handleGoToCustomize}
                  sx={{ textDecoration: "underline" }}
                >
                  {formatMessage({
                    id: "widgetSettings.goToCustomization.hyperlink",
                  })}
                </StyledGoToCustomize>
              </Box>
            ),
          },
        ]
      : []),
  ];

  const handleDelete = ({ widgetId }) => {
    confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content: (
        <>
          <Box width={"340px"} sx={{ margin: "0 auto" }}>
            {formatMessage({
              id: "popup.delete.description",
            })}
          </Box>
          <Box color={(theme) => theme.customColors.purple[500]} mt={1}>
            <Box
              component={"i"}
              className="meta-crm-icon-ic_info font-size-14"
              sx={{ display: "inline-block", transform: "translateY(2px)" }}
            />
            {formatMessage({ id: "widgetSettings.delete.explanatory" })}
          </Box>
        </>
      ),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "global.btn.cancel",
      }),
    })
      .then(() => {
        onDelete({ widgetId: _id, isNewAdd });
      })
      .catch(() => {});
  };

  const handleSave = async () => {
    const newDomainName = formData.domainName.trim();
    const sendData = {
      apiKey: formData.apiKey,
      name: formData.name,
      url: newDomainName !== "" ? `https://${formData.domainName}` : "",
      widgetId: widgetItemInfo.isNewAdd ? null : widgetItemInfo._id,
    };

    try {
      await updateWidgetConfig({
        entityName,
        config: sendData,
        type: "integration",
      });
      fetchEntityConfig({ entityName });
      fetchWidgetConfig({ entityName });

      // reset({ ...formData, domainName: formData.domainName });
      dispatch(
        enqueueSnackbar({
          message: formatMessage({ id: "widgetSettings.setting.saveSuccess" }),
          options: {
            variant: "success",
          },
        })
      );

      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/settings/general/integration"
        )
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  return (
    <StyledWhiteContainer mt="20px" mb="70px">
      <Box>
        <Box>
          {SDKsteps.map((settingStep, index) => (
            <StyledSettingWidgetStepContainerOutSide
              key={`${settingStep.title}${_id}`}
            >
              <StyledSettingWidgetStepContainer
                container
                spacing={1}
                isIsolateComponent={false}
              >
                <Grid item xs={12} display={"flex"} alignItems={"center"}>
                  {settingStep.stepNumber && (
                    <StyledSettingWidgetStepNumber>
                      {settingStep.stepNumber}
                    </StyledSettingWidgetStepNumber>
                  )}

                  <StyledSettingWidgetStepTitle color={settingStep.titleColor}>
                    {settingStep.title}
                  </StyledSettingWidgetStepTitle>
                  {settingStep.titleHint && (
                    <Box
                      display={"inline-block"}
                      fontSize={"16px"}
                      color={(theme) => theme.customColors.grey[500]}
                      mr={1}
                    >
                      {settingStep.titleHint}
                    </Box>
                  )}
                  {settingStep.status && settingStep.status()}
                  {settingStep.titleContent}
                  {settingStep.titleButton && settingStep.titleButton}
                </Grid>
                <Grid item xs={12}>
                  <StyledSettingWidgetStepDescribe>
                    {settingStep.describe}
                  </StyledSettingWidgetStepDescribe>
                </Grid>
                {settingStep.content}
              </StyledSettingWidgetStepContainer>
            </StyledSettingWidgetStepContainerOutSide>
          ))}
        </Box>

        <Box display={"flex"}>
          {!isNewAdd && (
            <Button
              color={COLOR.ERROR}
              size={SIZE.XL}
              sx={{ width: 120, marginLeft: "auto", display: "block" }}
              onClick={() => handleDelete({ widgetId: _id, isNewAdd })}
              variant={VARIANT.OUTLINED}
            >
              {formatMessage({
                id: "global.btn.delete",
              })}
            </Button>
          )}

          {/* 新建立的Widget不用在乎是不是有改過欄位 都可以存 */}
          <Button
            disabled={isSaveDisabled()}
            color={COLOR.SECONDARY}
            size={SIZE.XL}
            sx={{
              width: 120,
              display: "block",
              marginLeft: isNewAdd ? "auto" : "12px",
            }}
            onClick={handleSave}
          >
            {formatMessage({
              id: "global.btn.save",
            })}
          </Button>
        </Box>
      </Box>
    </StyledWhiteContainer>
  );
};

export default CustomServiceWidgetContent;

import Button, { COLOR, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { BillingBtn, BillingContainer } from "./Billing.styles";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { Link } from "react-router-dom";
import ContactInformationModal from "./ContactInformationModal";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

export default function Billing() {
  const { formatMessage } = useIntl();
  const confirm = useConfirm();

  async function handleCancelSubscription() {
    try {
      await confirm({
        title: formatMessage({ id: "cancelSubscription.popup.title" }),
        content: `${formatMessage({
          id: "cancelSubscription.popup.description.font",
        })} ${moment().add(2, "months").format("MMM DD, YYYY")}${formatMessage({
          id: "cancelSubscription.popup.description.back",
        })}`,
        confirmText: "Yes",
        cancelText: "No",
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function handleViewInvoice() {
    try {
      await confirm({
        title: formatMessage({ id: "invoiceRequest.popup.title" }),
        content: formatMessage({ id: "invoiceRequest.popup.description" }),
        confirmText: formatMessage({ id: "global.btn.iSee" }),
        color: "secondary",
      });
    } catch (e) {
      console.log(e);
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: "SIAO-MING,WANG",
    email: "AsVE34sdfs45@gmail.com",
  });

  return (
    <div>
      <div className="title">
        {formatMessage({ id: "global.function.billing" })}
      </div>
      <div className="description">
        {formatMessage({ id: "billing.setting.description" })}
      </div>

      <BillingContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              color: "#555",
              fontSize: "16px",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexShrink: 0,
            }}
          >
            <i
              className="meta-crm-icon-ic_moon3 font-size-24"
              style={{ color: "#FFC814" }}
            />
            <Box mx="4px">MetaCRM for Service</Box>
            <Box sx={{ color: "#7B61FF" }}> Pro Plan</Box>
          </Box>
          <Box sx={{ color: "#555", fontSize: "20px", fontWeight: 900 }}>
            $599 / month
          </Box>
        </Box>

        <Box
          sx={{ my: "16px", color: "#777", fontSize: "14px", fontWeight: 500 }}
        >
          Includes up 2,500 of Tickets per month.
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              mt: "16px",
              color: "#777",
              fontSize: "14px",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ flexShrink: 0 }}>
              {formatMessage({ id: "billing.planManagement.nextBilling" })}
            </Box>
            <Box sx={{ color: "#7B61FF", mx: "4px", flexShrink: 0 }}>
              {moment().format("MMM DD, YYYY")}
            </Box>
            {/* <BillingBtn onClick={handleCancelSubscription}>
              {formatMessage({ id: "billing.planManagement.cancel.btn" })}
            </BillingBtn> */}
          </Box>
          <Link to="manage-plan">
            <Button color={COLOR.SECONDARY} variant="outlined">
              {formatMessage({ id: "billing.planManagement.btn" })}
            </Button>
          </Link>
        </Box>
      </BillingContainer>
      <BillingContainer sx={{ marginBottom: "40px" }}>
        <Box
          sx={{
            color: "#A5A5A5",
            fontSize: "16px",
            fontWeight: 700,
            mb: "16px",
          }}
        >
          {formatMessage({ id: "billing.contact.subtitle" })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "#555",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              {formatMessage({ id: "global.commonWord.name" })} :{" "}
              {contactInfo.name}
            </Grid>
            <Grid item xs={12} sm={6}>
              {formatMessage({ id: "global.commonWord.e-mail" })} :{" "}
              {contactInfo.email}
            </Grid>
          </Grid>
        </Box>
        <BillingBtn
          sx={{
            position: "absolute",
            right: "12px",
            top: "12px",
          }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          edit
        </BillingBtn>
      </BillingContainer>

      <div className="title">
        {formatMessage({ id: "billing.history.subtitle" })}
      </div>
      <Box
        className="description"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {formatMessage({ id: "billing.history.description" })}
        <Box
          sx={{
            color: "#7B61FF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleViewInvoice}
        >
          {formatMessage({ id: "billingHistory.invoiceRequest.btn" })}
        </Box>
      </Box>
      <ContactInformationModal
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
      />
    </div>
  );
}

const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    fontSize: "14px",
    fontFamily: "Inter",
    textTransform: "none",
    marginRight: 3,
    height: 36,
    borderRadius: "3px",
    position: "relative",
    padding: "10px 30px",
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
    letterSpacing: "0",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    cursor: "pointer",
    "& .MuiButton-label": {
      fontWeight: 500,
    },

    "&:hover": {
      "& .MuiButton-label": {
        color: "white",
      },
    },
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.green}`,
    color: `${theme.palette.text.green}`,
    "& .MuiButton-label": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
  blue: {
    boxShadow: "5px 5px 5px 1px rgba(0, 0, 0, 0.5)",
    minWidth: 180,
  },

  primary: {
    backgroundColor: "#7B61FF",
    fontSize: "13px",
    marginRight: 10,
    height: 34,
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    padding: "9px 32px",
    borderRadius: "3px",
    "& .MuiButton-label": {
      fontWeight: 500,
      color: "#ffffff",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      backgroundColor: "#7B61FF",
    },
  },
  secondary: {
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    marginRight: 3,
    height: 36,
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
    padding: "9px 32px",
    borderRadius: "3px",
    border: "1px solid #7B61FF",
    "& .MuiButton-label": {
      fontWeight: 500,
      color: "#7B61FF",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#7B61FF",
      },
      backgroundColor: "#FFFFFF",
    },
  },

  remove: {
    backgroundColor: "#FFDED7",
    fontSize: "14px",
    height: 36,
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
    padding: "9px 32px",
    borderRadius: "3px",
    "& .MuiButton-label": {
      fontWeight: 500,
      color: "#FC5555",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#FC5555",
      },
      backgroundColor: "#FFDED7",
    },
  },

  dark: {
    background: "#383538",
    margin: 5,
    minWidth: 92,
    padding: 10,
    fontSize: "16px",
    fontWeight: 900,
    borderRadius: "5px",
    height: "40px",
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "#383538",
    },
  },
  connect: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    padding: "6px 15px",
    "& .MuiButton-label": {
      color: "#383538",
      fontWeight: 500,
      fontSize: 14,
    },
    height: 32,
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
      },
      background: "#FFFFFF",
    },
  },
  socialDark: {
    background: "transparent",
    minWidth: 0,
    margin: "5px 5px",
    borderRadius: 40,
    padding: 7,
    boxShadow: "0px 0px 0px",
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "& .MuiButton-label": {
      color: `#141314`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      background: "#7B61FF",
    },
  },

  social: {
    background: "transparent",
    minWidth: 0,
    margin: "5px 5px",
    borderRadius: 40,
    padding: 7,
    boxShadow: "0px 0px 0px",
    "& svg": {
      width: "22px",
      height: "22px",
    },
    "& .MuiButton-label": {
      color: `#ffffff`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#7B61FF",
      },
      background: "#ffffff",
    },
  },

  login: {
    background: "#7B61FF",
    boxShadow: "2.21296px 2.21296px 0.737654px rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
    padding: "12px 25px",
    "& .MuiButton-label": {
      color: "#FFFFFF",
      fontWeight: 700,
      fontSize: 12,
    },
    height: 38,
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#FFFFFF",
      },
      background: "#7B61FF",
    },
  },
  form: {
    background: "white",
    minWidth: 40,
    border: "1px solid #D6D6D6",
    boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: 3,
    padding: "9px 12px",
    height: "42px",
    "& .MuiButton-label": {
      color: `#383538`,
      fontSize: "14px",
      fontWeight: 500,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
      },
      background: "#F7F1FE",
    },
  },

  formPrimary: {
    background: "#7B61FF",
    margin: 5,
    minWidth: 40,
    boxShadow: "3px 3px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    padding: "3px 5px",
    height: "29px",
    "& .MuiButton-label": {
      color: `#FFFFFF`,
      fontSize: "12px",
      fontWeight: 600,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#FFFFFF",
        fontWeight: 600,
      },
      background: "#7B61FF",
    },
  },
  linear: {
    background: "linear-gradient(89.34deg, #D16FFF -13.43%, #459BFF 98.87%)",
    margin: 5,
    minWidth: 140,
    borderRadius: 3,
    padding: 10,
    fontSize: "14px",
    height: "29px",
    "& .MuiButton-label": {
      color: `white`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "white",
      },
      background: "linear-gradient(89.34deg, #D16FFF -13.43%, #459BFF 98.87%)",
    },
  },

  text: {
    backgroundColor: "transparent",
    fontSize: "14px",
    height: 36,
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)",
    padding: "9px 32px",
    borderRadius: "3px",
    "& .MuiButton-label": {
      fontWeight: 500,
      color: "#383538",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#383538",
      },
      backgroundColor: "#transparent",
    },
  },
  grey: {
    backgroundColor: "#E6E6E6",
    fontSize: "14px",
    height: 36,
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.1)",
    padding: "6px",
    borderRadius: "3px",
    "& .MuiButton-label": {
      fontWeight: 500,
      color: "#A5a5a5",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#A5a5a5",
      },
      backgroundColor: "#E6E6E6",
    },
  },

  textWhite: {
    backgroundColor: "transparent",
    padding: 0,
    height: 20,
    borderRadius: 0,
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.2)",
    "& .MuiButton-label": {
      fontWeight: 700,
      fontSize: 11,
      color: `#ffffff`,
      textAlign: "left",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      backgroundColor: "transparent",
    },
  },
  blueBackground: {
    backgroundColor: "#7B61FF",
    padding: 8,
    borderRadius: 5,
    "& .MuiButton-label": {
      fontWeight: 700,
      fontSize: 17,
      color: `#ffffff`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
      backgroundColor: "#7B61FF",
    },
  },
  blueText: {
    backgroundColor: "transparent",
    height: 34,
    border: "0.5px solid #7B61FF",
    borderRadius: 3,
    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
    "& .MuiButton-label": {
      fontWeight: 500,
      fontSize: 13,
      color: `#7B61FF`,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#7B61FF",
      },
      backgroundColor: "transparent",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    background: "transparent",
    padding: 0,
    minWidth: 0,
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.55",
    textDecoration: "none",
    // "& .MuiButton-label": {
    //   color: theme.palette.text.primary,
    // },
    border: "none",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "56px",
    borderRadius: 8,
    margin: 7,
    padding: "1.125rem 2.25rem",
    "& .MuiButton-label": {
      fontSize: "20px",
    },

    lineHeight: "1.333333",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    padding: "0.3rem 0.5rem",
    height: 30,
    margin: 0,
    fontSize: "0.9rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;

import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { get } from "lodash";
import { useSelector } from "react-redux";

export default function useMarketModule() {
  const marketingModule = useSelector(selectMarketingModule);
  const attributionUrl = get(marketingModule, "attribution.url");
  const attributionConnect = get(marketingModule, `attribution.connected`);
  const attributionConnecting = get(marketingModule, `attribution.connecting`);
  const referralEnabled = get(marketingModule, `attribution.referral.enabled`);
  const referralConnected = get(
    marketingModule,
    `attribution.referral.connected`
  );
  const attributionIntegrationStatus = attributionConnect
    ? "Enabled"
    : attributionConnecting
    ? "Waiting"
    : "Undetected";

  return {
    marketingModule,
    attributionIntegrationStatus,
    attributionConnect,
    attributionConnecting,
    attributionUrl,
    referralEnabled,
    referralConnected,
  };
}

import React from "react";
import _ from "lodash";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import Linkify from "linkify-react";
import moment from "moment";
import Box from "@mui/material/Box";
import {
  StyledAdminMessage,
  StyledAdminMessageTime,
  StyledMessageRow,
  SurveyScoreGroup,
  SurveyScoreItem,
} from "./Chat.styles";
import {
  IC_STAR,
  MAX_SURVEY_SCORE,
  emojiList,
} from "./settings/Customization/SurveyConstants";

function SurveyMsg({ selectedTicket, surveyConfig }) {
  const options = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const surveyScore = _.get(selectedTicket, "surveyScore", null);
  const surveySendingTime = _.get(selectedTicket, "surveySendingTime", null);
  const surveyBtnStyle = _.get(surveyConfig, "surveyBtnStyle");
  const surveyMsg = _.get(surveyConfig, "surveyMsg");

  function renderStars(count) {
    const stars = [];
    for (let i = 0; i < count; i++) {
      const ele = (
        <span key={i} style={{ fontSize: computer ? 16 : 14 }}>
          {IC_STAR}
        </span>
      );
      stars.push(ele);
    }
    return stars;
  }

  function renderEmoji(idx) {
    const emojiDetail = emojiList[idx];
    return (
      <>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <div style={{ fontSize: computer ? 24 : 20 }}>
            {emojiDetail.emoji}
          </div>
        </span>
      </>
    );
  }

  const renderSurveyMsg = () => {
    if (surveyScore) {
      return (
        <Linkify options={options}>
          {"🎉 Thank you for your rate! See you next time!"}
        </Linkify>
      );
    }

    if (surveyBtnStyle === "star") {
      const starCounts = Array.from(
        { length: MAX_SURVEY_SCORE },
        (_, index) => index + 1
      );
      return (
        <div>
          <Linkify options={options}>{surveyMsg}</Linkify>
          <SurveyScoreGroup>
            {starCounts.map((count, index) => (
              <SurveyScoreItem key={index}>
                {renderStars(count)}
              </SurveyScoreItem>
            ))}
          </SurveyScoreGroup>
        </div>
      );
    }

    if (surveyBtnStyle === "emoji") {
      return (
        <div>
          <div style={{ maxWidth: 304 }}>
            <Linkify options={options}>{surveyMsg}</Linkify>
          </div>
          <SurveyScoreGroup>
            {emojiList.map((_, index) => (
              <SurveyScoreItem style={{ height: 48, width: 48 }} key={index}>
                {renderEmoji(index)}
              </SurveyScoreItem>
            ))}
          </SurveyScoreGroup>
        </div>
      );
    }
  };

  const renderTime = () => {
    if (!surveySendingTime) return false;
    return (
      <>
        {moment(surveySendingTime).format("YYYY/MM/DD")}
        <br /> {moment(surveySendingTime).format("HH:mm")}
      </>
    );
  };

  return (
    <div>
      <Box mb="18px">
        <StyledMessageRow status={"success"}>
          <StyledAdminMessageTime>{renderTime()}</StyledAdminMessageTime>
          <StyledAdminMessage style={{ maxWidth: "none" }} sending={false}>
            {renderSurveyMsg()}
          </StyledAdminMessage>
        </StyledMessageRow>
      </Box>
    </div>
  );
}

SurveyMsg.shouldRender = ({ selectedTicket, surveyConfig }) => {
  const isSurveyEnabled = _.get(surveyConfig, "isSurveyEnabled", false);
  const isSurveySent = _.get(selectedTicket, "isSurveySent", false);
  return isSurveyEnabled && isSurveySent;
};

export default SurveyMsg;

import React, { useContext, useState, useMemo, useEffect } from "react";
import WebPush from "./WebPush";
import _, { set } from "lodash";
import {
  StyledChannelOption,
  StyledContainerTitle,
  StyledEngagementTitle,
  StyledGrid,
  StyledInputContainer,
  StyledIntegrationOption,
  StyledQuote,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
  StyledAudienceSelectBox,
  StyledOptionPreviewWrapper,
  PreviewOptionItemHeader,
  PreviewHolderWrapper,
  PreviewAddressCount,
  PreviewPremiumArea,
} from "./CampaignCreation.styles";
import { AccordionDetails, Box, Grid } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Radio } from "@metacrm/metacrm-material-ui/dist/Radio";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import Button, {
  IconButton,
  AddButton,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import { enqueueSnackbar } from "features/common/redux/actions";
import { get, filter, map, debounce } from "lodash-es";
import { Link, useOutletContext } from "react-router-dom";
import axios from "axios";
import { adamUrl, apiUrl } from "features/configure";
import {
  useFetchTags,
  useFetchGroups,
  useFetchEntityConfig,
} from "features/metadesk/redux/hooks";
import {
  getInitEngagementDistributionSetting,
  makeCustomTrackingLink,
} from "../utils/index";
import { useIntl } from "react-intl";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getShortAddress, validateURL } from "features/helpers/utils";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useSelector } from "react-redux";
import AudienceType from "./AudienceType";
import { Loading } from "features/common";
import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import useNavigateEntity from "hooks/useNavigateEntity";
import useCampaignSave from "../hook/useCampaignSave";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useDebounceEffect } from "components/ConnectWallet/ConnectWallet";
import IntergrationCover from "./IntergrationCover";

const aiEnhancedUserHolderMap = {
  GameFi: [
    require("assets/img/holder_gamefi_immutable.webp"),
    require("assets/img/holder_gamefi_notcoin.webp"),
    require("assets/img/holder_gamefi_floki.webp"),
    require("assets/img/holder_gamefi_axie_infinity.webp"),
    require("assets/img/holder_gamefi_gala.webp"),
    require("assets/img/holder_gamefi_beam.webp"),
    require("assets/img/holder_gamefi_sandbox.webp"),
    require("assets/img/holder_gamefi_ronin.webp"),
    require("assets/img/holder_gamefi_apecoin.jpg"),
    require("assets/img/holder_gamefi_illuvium.webp"),
  ],
  DAOVoting: [
    require("assets/img/holder_dao_aave.webp"),
    require("assets/img/holder_dao_ens.webp"),
    require("assets/img/holder_dao_apecoin.webp"),
    require("assets/img/holder_dao_balancer.webp"),
    require("assets/img/holder_dao_lido-snapshot.webp"),
    require("assets/img/holder_dao_starknet.webp"),
    require("assets/img/holder_dao_safe.webp"),
    require("assets/img/holder_dao_maviavote.webp"),
    require("assets/img/holder_dao_shutterdao0x36.webp"),
    require("assets/img/holder_dao_mixin-autonomous-organization.webp"),
    require("assets/img/holder_dao_swarmfoundation.webp"),
    require("assets/img/holder_dao_stgdao.webp"),
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
    require("assets/img/holder_dao_opcollective.webp"),
    require("assets/img/holder_dao_cakevote.webp"),
  ],
  AirdropHunter: [
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
    require("assets/img/holder_airdrop_eigenLayer.jpg"),
    require("assets/img/holder_airdrop_layerzero.png"),
    require("assets/img/holder_airdrop_ethena.png"),
    require("assets/img/holder_airdrop_renzo.jpg"),
    require("assets/img/holder_airdrop_eigenLayer.jpg"),
    require("assets/img/holder_airdrop_friendTech.jpg"),
    require("assets/img/holder_airdrop_celestia.jpg"),
  ],
  premium: [
    require("assets/img/holder_dao_apecoin.webp"),
    require("assets/img/holder_dao_balancer.webp"),
    require("assets/img/holder_dao_lido-snapshot.webp"),
    require("assets/img/holder_dao_starknet.webp"),
    require("assets/img/holder_dao_safe.webp"),
    require("assets/img/holder_dao_maviavote.webp"),
    require("assets/img/holder_dao_shutterdao0x36.webp"),
    require("assets/img/holder_dao_mixin-autonomous-organization.webp"),
    require("assets/img/holder_dao_swarmfoundation.webp"),
    require("assets/img/holder_dao_stgdao.webp"),
    require("assets/img/holder_dao_arbitrumfoundation.webp"),
  ],
};

export default function EngagementCampaign({ step, tabIndex }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const {
    stepData,
    setStepValues,
    isXmtpEnabled,
    isPushEnabled,
    isXmtpConnected,
    isPushConnected,
    isWidgetActive,
    isTrackingActive,
  } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;
  const [entityName] = useOutletContext();
  const dispatch = useDispatch();
  const { tags, fetchTags } = useFetchTags();
  const { formatMessage } = useIntl();
  const marketingModule = useSelector(selectMarketingModule);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const { navigateEntity } = useNavigateEntity();
  const { groups, fetchGroups } = useFetchGroups();
  const { save } = useCampaignSave(step);
  const [aiEnhancedUserGroupInfo, setAiEnhancedUserGroupInfo] = useState([]);
  const { entityConfig } = useFetchEntityConfig();
  const confirm = useConfirm();

  const [customSuffixWithSettingIndex, setCustomSuffixWithSettingIndex] =
    useState("");
  const [customSuffixApiError, setCustomSuffixApiError] = useState("");

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo },
    watch,
    register,
  } = useFormContext();

  const { append, replace } = useFieldArray({
    control: control,
    name: "campaignContentSets",
  });

  const adamEnabled = get(entityConfig, "marketingModule.adam.enabled", false);

  const watchCampaignContentSets = watch("campaignContentSets");

  const goal = _.get(campaignContentSets, `${tabIndex}.goal`);
  const type = _.get(campaignContentSets, `${tabIndex}.type`);

  const disabledChannels = ["debankHi"];
  const onlyCustomChannels = ["debankHi", "xmtp", "push"];
  const CUSTOM_TYPE = {
    empty: "",
    aiEnhancedUser: "aiEnhancedUser",
    segment: "segment",
    tag: "tag",
    adam: "adam",
  };
  const AUDIENCE_TYPE = {
    all: "all",
    custom: "custom",
  };

  const marketChannels = [
    {
      label: formatMessage({ id: "integration.push.name" }),
      value: "push",
      auth: isPushConnected,
      enable: isPushEnabled,
      integration: "/settings/general/integration/update/push",
      image: require("assets/img/source/push.svg").default,
      intro: formatMessage({ id: "marketingChannel.push.intro" }),
    },
    {
      label: formatMessage({ id: "integration.xmtp.name" }),
      enable: isXmtpEnabled,
      value: "xmtp",
      auth: isXmtpConnected,
      integration: "/settings/general/integration/update/xmtp",
      image: require("assets/img/source/xmtp.svg").default,
      intro: formatMessage({ id: "marketingChannel.xmtp.intro" }),
    },
  ];

  useEffect(async () => {
    await fetchAiEnhancedUserInfo();
    fetchTags({ entityName });
    fetchGroups({ entityName });
  }, []);

  useEffect(() => {
    const channel = getActiveChannel();

    if (
      type !== "widget" &&
      channel &&
      !get(
        campaignContentSets,
        `${tabIndex}.engagementDistributionSettings[0].channel`
      )
    ) {
      const temp = [...campaignContentSets];
      temp[tabIndex].engagementDistributionSettings[0].channel = channel;
      if (onlyCustomChannels.includes(channel)) {
        temp[tabIndex].engagementDistributionSettings[0].audienceType =
          "custom";
      }
      setStepValues({ campaignContentSets: temp });
    }
  }, []);

  useEffect(() => {
    const temp = [...campaignContentSets];
    const settings = temp[tabIndex].engagementDistributionSettings;
    for (let settingIndex = 0; settingIndex < settings.length; settingIndex++) {
      const s = settings[settingIndex];
      if (s.aiEnhancedUserGroup) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.aiEnhancedUser
        );
      }
      if (s.userGroup) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.segment
        );
      }
      if (s.tag) {
        handleChangeDistributionSettings(
          settingIndex,
          "customType",
          CUSTOM_TYPE.tag
        );
      }
    }
  }, []);

  const tagOptions = useMemo(
    () => [
      ...get(tags, entityName, []).map((item) => {
        return { ...item, label: item.value, value: item._id };
      }),
    ],
    [tags]
  );

  const segmentOptions = useMemo(
    () =>
      groups?.map((item) => {
        return { ...item, label: item.name, value: item._id };
      }),
    [groups]
  );

  const aiEnhancedUserOptions = useMemo(() => {
    if (!aiEnhancedUserGroupInfo.length) return [];
    return aiEnhancedUserGroupInfo.map((item) => {
      const o = {
        ...item,
        label: item.name,
        labelComponent: !item.isPremium ? (
          <div>{item.name}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
            <div style={{ color: theme.customColors.grey[500] }}>
              {item.name}
            </div>
            <div
              style={{
                background: "#FFE7CA",
                color: "#FF9D2A",
                padding: "2px 6px",
                borderRadius: 16,
                width: "fit-content",
                fontSize: theme.fontSize.TINY,
              }}
            >
              {"Premium"}
            </div>
          </div>
        ),
        value: item._id,
        optionPreview: renderOptionPreview(item),
        readonly: item.isPremium,
      };

      return o;
    });
  }, [aiEnhancedUserGroupInfo]);

  useDebounceEffect(
    async () => {
      if (!customSuffixWithSettingIndex) return;
      const parts = customSuffixWithSettingIndex.split("-");
      const settingIndex = Number(parts[0]);
      const customSuffix = parts[1];
      const { error } = await checkCustomSuffixAvailability(
        customSuffix.trim()
      );
      setCustomSuffixApiError(error);
      set(
        errorsInfo,
        `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink.message`,
        error
      );
    },
    1000,
    [customSuffixWithSettingIndex]
  );

  function handleChangeDistributionSettings(
    settingIndex,
    key,
    value,
    callback
  ) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex][key] = value;
    setStepValues({ campaignContentSets: temp });
    !_.isEmpty(value) && callback && callback();
  }

  function handleChangeDistributionSettingsData(settingIndex, data, callback) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex] = {
      ...temp[tabIndex].engagementDistributionSettings[settingIndex],
      ...data,
    };
    setStepValues({ campaignContentSets: temp });
    callback && callback();
  }

  const countTagAddresses = (tag) =>
    axios.post(`${apiUrl}/api/report/${entityName}/countAddresses`, {
      rules: {
        tags: [tag],
      },
    });

  const getReachableUsersCnt = async ({ channel, users }) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/campaign/${entityName}/reachable`,
        { channel, users }
      );
      return data.reachableUsersCnt;
    } catch (error) {
      return null;
    }
  };

  const fetchAiEnhancedUserInfo = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/aiEnhancedUserGroups/`);
      setAiEnhancedUserGroupInfo(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const checkCustomSuffixAvailability = async (suffix) => {
    if (!suffix) return { error: "" };
    try {
      await axios.post(`${apiUrl}/api/campaign/${entityName}/suffixavailable`, {
        customSuffix: suffix,
      });
      return { error: "" };
    } catch (error) {
      return {
        error: get(
          error,
          "response.data.error",
          get(error, "response.data.message", "error")
        ),
      };
    }
  };

  const handleSetAiEnhancedUserEstimatedAmount = async ({
    tabIndex,
    settingIndex,
    channel,
  }) => {
    try {
      if (estimateLoading) return;
      setEstimateLoading(true);
      const temp = [...campaignContentSets];

      const aiEnhancedUserGroupId =
        temp[tabIndex].engagementDistributionSettings[settingIndex][
          "aiEnhancedUserGroup"
        ];

      const targetGrp = aiEnhancedUserGroupInfo.find(
        (item) => item._id === aiEnhancedUserGroupId
      );

      const totalUserCount = _.get(targetGrp, "totalAddressCount", 0);

      let reachableUsersCount = totalUserCount;

      if (channel === "xmtp") {
        reachableUsersCount = _.get(targetGrp, "reachableAddressCount", 0);
      }

      temp[tabIndex].engagementDistributionSettings[settingIndex][
        "estimatedAmount"
      ] = totalUserCount;

      temp[tabIndex].engagementDistributionSettings[settingIndex][
        "reachableUsers"
      ] = reachableUsersCount;

      setStepValues({ campaignContentSets: temp });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setEstimateLoading(false);
    }
  };

  const handleSetSegmentEstimatedAmount = async ({
    value,
    tabIndex,
    settingIndex,
    channel,
  }) => {
    if (estimateLoading) return;
    setEstimateLoading(true);
    const reachableUsersCount = await getReachableUsersCnt({
      channel,
      users: value?.users || [],
    });

    const totalUserCount = value?.users?.length;
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex][
      "estimatedAmount"
    ] = totalUserCount;

    temp[tabIndex].engagementDistributionSettings[settingIndex][
      "reachableUsers"
    ] = reachableUsersCount;

    setStepValues({ campaignContentSets: temp });
    setEstimateLoading(false);
  };

  const handleCountAddresses = async ({
    tag,
    tabIndex,
    settingIndex,
    channel,
  }) => {
    try {
      if (estimateLoading) return;
      setEstimateLoading(true);
      const { data } = await countTagAddresses(tag);
      const { totalUserCount, users } = data;

      const reachableUsersCount = await getReachableUsersCnt({
        channel,
        users: users || [],
      });

      const temp = [...campaignContentSets];
      temp[tabIndex].engagementDistributionSettings[settingIndex][
        "estimatedAmount"
      ] = totalUserCount;

      temp[tabIndex].engagementDistributionSettings[settingIndex][
        "reachableUsers"
      ] = reachableUsersCount;

      setStepValues({ campaignContentSets: temp });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setEstimateLoading(false);
    }
  };

  const handleAdamCountAddresses = async ({ audienceId, settingIndex }) => {
    try {
      const { data } = await axios.get(
        `${adamUrl}/api/adam/audience/${audienceId}/count`
      );
      const temp = [...campaignContentSets];
      temp[tabIndex].engagementDistributionSettings[settingIndex][
        "estimatedAmount"
      ] = data;
      setStepValues({ campaignContentSets: temp });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setEstimateLoading(false);
    }
  };

  function handleReset(settingIndex) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings[settingIndex] =
      getInitEngagementDistributionSetting();

    setStepValues({ campaignContentSets: temp });
  }

  function addDistributionSetting() {
    const firstAvailableChannel = getActiveChannel();
    if (!firstAvailableChannel) return;

    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings.push({
      ...getInitEngagementDistributionSetting({
        useOnchain: goal === "onchain",
      }),
      channel: firstAvailableChannel,
      audienceType: onlyCustomChannels.includes(firstAvailableChannel)
        ? AUDIENCE_TYPE.custom
        : AUDIENCE_TYPE.all,
    });
    setStepValues({ campaignContentSets: temp });
  }

  function isDisabled(settingIndex, channelValue) {
    // 如果目前這個功能還在測試階段，則此channel選項會被禁用
    if (disabledChannels.includes(channelValue)) return true;

    const channelValueIndex = campaignContentSets[
      tabIndex
    ].engagementDistributionSettings.findIndex(
      (item) => item.channel === channelValue
    );

    const channelExists = channelValueIndex !== -1;

    // 如果有相同的channelValue，且不是同一個settingIndex，則不允許新增
    if (channelExists && channelValueIndex !== settingIndex) return true;
    return false;
  }

  function getActiveChannel() {
    const availableChannels = new Set(
      marketChannels
        .filter((item) => !disabledChannels.includes(item.value) && item.auth)
        .map((item) => item.value)
    );

    const settingsChannels = new Set(
      campaignContentSets[tabIndex].engagementDistributionSettings.map(
        (item) => item.channel
      )
    );

    for (const channel of availableChannels) {
      if (!settingsChannels.has(channel)) {
        return channel; // If any available channel is not in the settings, return true
      }
    }

    return false; // If all available channels are in the settings, return false
  }

  function selectedSegment(settingIndex) {
    const segment = segmentOptions.find(
      (option) =>
        campaignContentSets[tabIndex]?.engagementDistributionSettings[
          settingIndex
        ]["userGroup"] === option.value
    );
    const channel =
      campaignContentSets[tabIndex].engagementDistributionSettings[settingIndex]
        .channel;

    if (
      typeof campaignContentSets[tabIndex].engagementDistributionSettings[
        settingIndex
      ]["reachableUsers"] === "undefined" &&
      segment &&
      !estimateLoading
    ) {
      setEstimateLoading(true);
      getReachableUsersCnt({
        channel,
        users: segment.users || [],
      })
        .then((reachableUsersCnt) => {
          const temp = [...campaignContentSets];
          temp[tabIndex].engagementDistributionSettings[settingIndex][
            "reachableUsers"
          ] = reachableUsersCnt;
          setStepValues({ campaignContentSets: temp });
        })
        .catch((err) => console.log(err))
        .finally(() => setEstimateLoading(false));
    }

    return segment;
  }

  function selectedTag(settingIndex) {
    const tag = tagOptions.find(
      (option) =>
        campaignContentSets[tabIndex]?.engagementDistributionSettings[
          settingIndex
        ]["tag"] === option.value
    );

    const channel =
      campaignContentSets[tabIndex].engagementDistributionSettings[settingIndex]
        .channel;

    if (
      typeof campaignContentSets[tabIndex].engagementDistributionSettings[
        settingIndex
      ]["reachableUsers"] === "undefined" &&
      tag &&
      !estimateLoading
    ) {
      setEstimateLoading(true);
      countTagAddresses(tag)
        .then(({ data }) => {
          const { users } = data;
          getReachableUsersCnt({
            channel,
            users: users || [],
          })
            .then((reachableUsersCnt) => {
              const temp = [...campaignContentSets];
              temp[tabIndex].engagementDistributionSettings[settingIndex][
                "reachableUsers"
              ] = reachableUsersCnt;
              setStepValues({ campaignContentSets: temp });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err))
        .finally(() => setEstimateLoading(false));
    }

    return tag;
  }

  function selectedAiEnhancedUserGrp(settingIndex) {
    const selectedGrp = aiEnhancedUserOptions.find(
      (option) =>
        campaignContentSets[tabIndex]?.engagementDistributionSettings[
          settingIndex
        ]["aiEnhancedUserGroup"] === option.value
    );
    return selectedGrp;
  }

  function deleteDistributionSetting(index) {
    const temp = [...campaignContentSets];
    temp[tabIndex].engagementDistributionSettings.splice(index, 1);
    setStepValues({ campaignContentSets: temp });
  }

  const handleSetAudienceType = (settingIndex, type) => {
    if (type === AUDIENCE_TYPE.all) {
      handleChangeDistributionSettings(
        settingIndex,
        "audienceType",
        AUDIENCE_TYPE.all
      );
      handleChangeDistributionSettings(
        settingIndex,
        "customType",
        CUSTOM_TYPE.empty
      );
    } else {
      handleChangeDistributionSettings(settingIndex, "audienceType", "custom");
      handleChangeDistributionSettings(
        settingIndex,
        "customType",
        CUSTOM_TYPE[type]
      );
    }
    handleChangeDistributionSettingsData(settingIndex, {
      tag: "",
      userGroup: "",
      aiEnhancedUserGroup: "",
      estimatedAmount: 0,
      reachableUsers: [],
    });
  };

  function renderChangeWallet(type) {
    if (!_.get(marketingModule, `[${type}].address`)) return;
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        style={{ fontSize: "12px" }}
        marginBottom={"8px"}
      >
        <p style={{ marginRight: "1rem" }}>
          Currently used to send messages ：
          {getShortAddress(marketingModule[type].address)}
        </p>
        <Button
          variant={VARIANT.OUTLINED}
          size={SIZE.XS}
          onClick={() =>
            window.open(
              `/${entityName}/settings/general/integration/update/${type}`,
              "_blank",
              "rel=noopener noreferrer"
            )
          }
        >
          Change Wallet
        </Button>{" "}
      </Box>
    );
  }

  const helperText = {
    xmtp: renderChangeWallet("xmtp"),
    push: renderChangeWallet("push"),
  };

  function renderOptionPreview(item) {
    return (
      <StyledOptionPreviewWrapper>
        <div
          style={{
            ...(item.isPremium && {
              position: "relative",
              filter: "blur(5px)",
            }),
          }}
        >
          <PreviewOptionItemHeader>{item.name}</PreviewOptionItemHeader>
          <section style={{ margin: "16px 0" }}>
            <p
              style={{
                color: theme.customColors.grey[600],
                marginBottom: 4,
              }}
            >
              {formatMessage({
                id: "global.aiEnhancedUser.description.holding",
              })}
            </p>
            <PreviewHolderWrapper>
              {(!item.isPremium
                ? aiEnhancedUserHolderMap[item.name]
                : aiEnhancedUserHolderMap["premium"]
              )?.map((holderImg) => {
                return (
                  <img
                    src={holderImg}
                    alt="item.name"
                    style={{ width: 24, height: 24 }}
                  />
                );
              })}
            </PreviewHolderWrapper>
          </section>
          <section>
            <p
              style={{
                color: theme.customColors.grey[600],
                marginBottom: 4,
              }}
            >
              {formatMessage({
                id: "global.aiEnhancedUser.description.address",
              })}
            </p>
            <PreviewAddressCount>
              {!item.isPremium ? item.totalAddressCount : 34562}
            </PreviewAddressCount>
          </section>
        </div>
        {item.isPremium && (
          <PreviewPremiumArea>
            <p style={{ fontSize: 16 }}>
              {formatMessage({
                id: "aiEnhancedUser.premium.disable.description",
              })}
            </p>
            <Button
              style={{
                fontSize: theme.fontSize.LARGE,
                fontWeight: theme.fontWeight.BOLD,
              }}
              variant={VARIANT.TEXT}
              size={SIZE.LARGE}
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = "mailto:bd@metacrm.inc";
              }}
            >
              {"Contact us"} <ArrowRightAltIcon />
            </Button>
          </PreviewPremiumArea>
        )}
      </StyledOptionPreviewWrapper>
    );
  }
  const handleDropdownChange = (
    type,
    newValue,
    tabIndex,
    settingIndex,
    channel
  ) => {
    if (type === CUSTOM_TYPE.aiEnhancedUser) {
      handleSetAudienceType(settingIndex, CUSTOM_TYPE.aiEnhancedUser);
      handleChangeDistributionSettings(
        settingIndex,
        "aiEnhancedUserGroup",
        newValue.value,
        () =>
          handleSetAiEnhancedUserEstimatedAmount({
            tabIndex,
            settingIndex,
            channel,
          })
      );
    }
    if (type === CUSTOM_TYPE.segment) {
      handleSetAudienceType(settingIndex, CUSTOM_TYPE.segment);
      handleChangeDistributionSettings(
        settingIndex,
        "userGroup",
        newValue.value,
        () =>
          handleSetSegmentEstimatedAmount({
            value: selectedSegment(settingIndex),
            tabIndex,
            settingIndex,
            channel,
          })
      );
    } else if (type === CUSTOM_TYPE.tag) {
      handleSetAudienceType(settingIndex, CUSTOM_TYPE.tag);
      handleChangeDistributionSettings(
        settingIndex,
        "tag",
        newValue.value,
        () =>
          handleCountAddresses({
            tag: campaignContentSets[tabIndex]?.engagementDistributionSettings[
              settingIndex
            ]["tag"],
            tabIndex,
            settingIndex,
            channel,
          })
      );
    }
  };

  function handleSaveAndNavigate({ url }) {
    confirm({
      title: "Save as draft?",
      content:
        "You will be leaving this page soon, do you need to save a draft of what you have filled in?",
      confirmText: "Save",
      cancelText: formatMessage({
        id: "Cancel",
      }),
      color: "secondary",
    })
      .then(async () => {
        await save(true, { customUrl: url });
      })
      .catch((err) => {
        console.log("cancel", err);
        navigateEntity(url);
      });
  }

  return (
    <div>
      <WebPush tabIndex={tabIndex} />

      <Box position="relative">
        <IntergrationCover
          show={
            (type === "widget" && !isWidgetActive) ||
            (type === "flyer" && !(isPushConnected || isXmtpConnected))
          }
          type={type}
        />
        {campaignContentSets[tabIndex].engagementDistributionSettings?.map(
          (distributionSetting, settingIndex) => {
            const estimatedAmount = _.get(
              campaignContentSets,
              `[${tabIndex}].engagementDistributionSettings[${settingIndex}].estimatedAmount`,
              0
            );
            const reachableUsersAmount = _.get(
              campaignContentSets,
              `[${tabIndex}].engagementDistributionSettings[${settingIndex}].reachableUsers`,
              0
            );

            return (
              <StyledWhiteAccordion
                key={settingIndex}
                defaultExpanded
                disableGutters={true}
                style={{ marginTop: "20px" }}
              >
                <StyledWhiteAccordionSummary
                  expandIcon={
                    <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
                  }
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ padding: 0, paddingRight: "20px", margin: 0 }}
                >
                  <StyledContainerTitle>
                    {formatMessage({
                      id: "engagementCampaign.distribtutionSetting.title",
                    })}
                    {campaignContentSets[tabIndex]
                      .engagementDistributionSettings.length > 1 && (
                      <IconButton
                        className="list-item-btn"
                        size={14}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteDistributionSetting(settingIndex);
                        }}
                        color={"#A5A5A5"}
                        sx={{ marginLeft: "4px" }}
                      >
                        <i className="meta-crm-icon-ic_trash font-size-18" />
                      </IconButton>
                    )}
                  </StyledContainerTitle>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "A5A5A5",
                      marginRight: "13px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReset(settingIndex);
                    }}
                  >
                    <i
                      className="meta-crm-icon-ic_refresh font-size-20"
                      style={{ marginRight: "4px", rotate: "-90deg" }}
                    />
                    <p style={{ color: "#A5A5A5" }}>
                      {formatMessage({ id: "global.btn.reset" })}
                    </p>
                  </div>
                </StyledWhiteAccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <div className="mhr" style={{ margin: 0 }}></div>
                  <StyledInputContainer>
                    {/* Marketing Channel */}
                    {type !== "widget" && (
                      <>
                        <StyledEngagementTitle>
                          {formatMessage({
                            id: "engagementCampaign.channelSetting.subtitle",
                          })}{" "}
                          <span className="required" style={{ marginLeft: 2 }}>
                            *
                          </span>
                        </StyledEngagementTitle>
                        <Grid container spacing={1}>
                          {marketChannels.map((channel) => {
                            if (!channel.enable) return;
                            return (
                              <Grid item xs={12} md={4}>
                                <StyledChannelOption
                                  onClick={() => {
                                    if (isDisabled(settingIndex, channel.value))
                                      return;
                                    if (!channel.auth) {
                                      return handleSaveAndNavigate({
                                        url: channel.integration,
                                      });
                                    }
                                    const isCustomChannel =
                                      onlyCustomChannels.includes(
                                        channel.value
                                      );

                                    const audienceType = isCustomChannel
                                      ? AUDIENCE_TYPE.custom
                                      : AUDIENCE_TYPE.all;

                                    const customType = isCustomChannel
                                      ? CUSTOM_TYPE.aiEnhancedUser
                                      : CUSTOM_TYPE.empty;

                                    handleChangeDistributionSettingsData(
                                      settingIndex,
                                      {
                                        channel: channel.value,
                                        audienceType: audienceType,
                                        customType: customType,
                                        tag: "",
                                        userGroup: "",
                                        aiEnhancedUserGroup: "",
                                        estimatedAmount: 0,
                                        reachableUsers: [],
                                      }
                                    );
                                  }}
                                  disabled={isDisabled(
                                    settingIndex,
                                    channel.value
                                  )}
                                  active={
                                    distributionSetting.channel ===
                                    channel.value
                                  }
                                >
                                  {!isDisabled(settingIndex, channel.value) &&
                                    !channel.auth && (
                                      <StyledIntegrationOption>
                                        {`Go to Integration -->`}
                                      </StyledIntegrationOption>
                                    )}
                                  <Box display="flex" alignItems="flex-start">
                                    <img
                                      style={{ marginRight: "8px" }}
                                      src={channel.image}
                                    />
                                    <div>
                                      <StyledEngagementTitle>
                                        {channel.label}
                                      </StyledEngagementTitle>
                                      <p>{channel.intro}</p>
                                    </div>
                                  </Box>
                                </StyledChannelOption>
                                {channel.value === "xmtp" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      gap: 2,
                                      px: 1,
                                      mt: 1,
                                      fontWeight: 600,
                                      color: (theme) =>
                                        theme.customColors.purple[500],
                                    }}
                                  >
                                    <span>Dapp:</span>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: 2,
                                      }}
                                    >
                                      {[
                                        {
                                          image: require("assets/img/xmtp_dapp1.png"),
                                          name: "Coinbase",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp2.png"),
                                          name: "Hey",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp3.png"),
                                          name: "",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp4.png"),
                                          name: "Paragraph",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp5.png"),
                                          name: "Phaver",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp6.png"),
                                          name: "Yup",
                                        },
                                        {
                                          image: require("assets/img/xmtp_dapp7.png"),
                                          name: "Converse",
                                        },
                                      ].map((dapp) => (
                                        <img src={dapp.image} alt={dapp.name} />
                                      ))}
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    {
                      <>
                        <StyledEngagementTitle>
                          {formatMessage({
                            id: "engagementCampaign.distributionSetting.audience.all",
                          })}{" "}
                          <span className="required" style={{ marginLeft: 2 }}>
                            *
                          </span>
                        </StyledEngagementTitle>
                        <StyledGrid style={{ display: "flex", width: "100%" }}>
                          <>
                            {distributionSetting.audienceType === "custom" &&
                            distributionSetting.channel !== "widget" ? (
                              <StyledAudienceSelectBox
                                id="btn_audienceByAiEnhanced"
                                isSelected={
                                  distributionSetting.customType ===
                                  CUSTOM_TYPE.aiEnhancedUser
                                }
                                onClick={() =>
                                  handleSetAudienceType(
                                    settingIndex,
                                    CUSTOM_TYPE.aiEnhancedUser
                                  )
                                }
                              >
                                <Radio
                                  checked={
                                    distributionSetting.customType ===
                                    CUSTOM_TYPE.aiEnhancedUser
                                  }
                                  label={
                                    <StyledEngagementTitle
                                      style={{ margin: "16px 0" }}
                                    >
                                      {formatMessage({
                                        id: "global.property.aiEnhancedUser",
                                      })}
                                    </StyledEngagementTitle>
                                  }
                                />
                              </StyledAudienceSelectBox>
                            ) : (
                              <StyledAudienceSelectBox
                                id="btn_audienceByAll"
                                isSelected={
                                  distributionSetting.audienceType ===
                                    AUDIENCE_TYPE.all &&
                                  distributionSetting.customType ===
                                    CUSTOM_TYPE.empty
                                }
                                onClick={() =>
                                  handleSetAudienceType(
                                    settingIndex,
                                    AUDIENCE_TYPE.all
                                  )
                                }
                              >
                                <Radio
                                  checked={
                                    distributionSetting.audienceType ===
                                      AUDIENCE_TYPE.all &&
                                    distributionSetting.customType ===
                                      CUSTOM_TYPE.empty
                                  }
                                  label={
                                    <StyledEngagementTitle
                                      style={{ margin: "16px 0" }}
                                    >
                                      {formatMessage({
                                        id: "engagementCampaign.distributionSetting.allUser",
                                      })}
                                    </StyledEngagementTitle>
                                  }
                                />
                              </StyledAudienceSelectBox>
                            )}
                          </>
                          <StyledAudienceSelectBox
                            id="btn_audienceBySegment"
                            isSelected={
                              distributionSetting.customType ===
                              CUSTOM_TYPE.segment
                            }
                            onClick={() => {
                              handleSetAudienceType(settingIndex, "segment");
                            }}
                          >
                            <Radio
                              checked={
                                distributionSetting.customType ===
                                CUSTOM_TYPE.segment
                              }
                              label={
                                <StyledEngagementTitle
                                  style={{ margin: "16px 0" }}
                                >
                                  {formatMessage({
                                    id: "ticketDetail.chat.segment",
                                  })}
                                </StyledEngagementTitle>
                              }
                            />
                          </StyledAudienceSelectBox>
                          <StyledAudienceSelectBox
                            id="btn_audienceByUserTag"
                            isSelected={
                              distributionSetting.customType === CUSTOM_TYPE.tag
                            }
                            onClick={() => {
                              handleSetAudienceType(settingIndex, "tag");
                            }}
                          >
                            <Radio
                              checked={
                                distributionSetting.customType ===
                                CUSTOM_TYPE.tag
                              }
                              label={
                                <StyledEngagementTitle
                                  style={{
                                    margin: "16px 0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {formatMessage({
                                    id: "global.property.userTag",
                                  })}
                                </StyledEngagementTitle>
                              }
                            />
                          </StyledAudienceSelectBox>
                          {adamEnabled && distributionSetting.adamAudience && (
                            <StyledAudienceSelectBox
                              id="btn_audienceByUserTag"
                              isSelected={
                                distributionSetting.customType ===
                                CUSTOM_TYPE.adam
                              }
                              onClick={async () => {
                                handleSetAudienceType(settingIndex, "adam");
                                await handleAdamCountAddresses({
                                  audienceId: distributionSetting.adamAudience,
                                  settingIndex,
                                });
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <Radio
                                  checked={
                                    distributionSetting.customType ===
                                    CUSTOM_TYPE.adam
                                  }
                                  label={
                                    <StyledEngagementTitle
                                      style={{
                                        margin: "16px 0",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {"Adam Audience"}
                                    </StyledEngagementTitle>
                                  }
                                />
                              </Box>
                            </StyledAudienceSelectBox>
                          )}
                        </StyledGrid>
                      </>
                    }
                    {distributionSetting.audienceType === "custom" && (
                      <>
                        <AudienceType
                          campaignContentSets={campaignContentSets}
                          tabIndex={tabIndex}
                          distributionSetting={distributionSetting}
                          handleSetAudienceType={handleSetAudienceType}
                          settingIndex={settingIndex}
                          segmentOptions={segmentOptions}
                          aiEnhancedUserOptions={aiEnhancedUserOptions}
                          selectedSegment={selectedSegment}
                          selectedAiEnhancedUserGrp={selectedAiEnhancedUserGrp}
                          selectedTag={selectedTag}
                          handleDropdownChange={handleDropdownChange}
                          tagOptions={tagOptions}
                          handleAdamCountAddresses={handleAdamCountAddresses}
                          customType={distributionSetting.customType}
                        />
                      </>
                    )}
                    <StyledQuote>
                      <p>
                        {formatMessage({ id: "campaign.type.estimatedReach" })}:{" "}
                        {distributionSetting.audienceType === AUDIENCE_TYPE.all
                          ? "Unlimited"
                          : distributionSetting.channel == "xmtp" &&
                            estimatedAmount - reachableUsersAmount > 0
                          ? [reachableUsersAmount, estimatedAmount].join("/")
                          : estimatedAmount}
                      </p>
                      {distributionSetting.audienceType == "custom" &&
                      distributionSetting.channel == "xmtp" &&
                      estimatedAmount - reachableUsersAmount > 0 ? (
                        <div>{`{${
                          estimatedAmount - reachableUsersAmount
                        }} wallets aren't using ${distributionSetting.channel.toUpperCase()}, preventing message sending.`}</div>
                      ) : null}
                      <Loading open={estimateLoading} fullScreen={false} sm />
                    </StyledQuote>
                    {helperText[distributionSetting.channel]}
                    {/* Performance tracking link */}
                    {goal === "onchain" && (
                      <Box position={"relative"}>
                        <IntergrationCover
                          zIndex={2}
                          show={!isTrackingActive}
                          type="tracker"
                        />
                        <StyledEngagementTitle
                          style={{ margin: "16px 0", marginTop: "36px" }}
                        >
                          {"Call to action"}
                        </StyledEngagementTitle>
                        <div className="mhr" />
                        <Box>
                          <StyledEngagementTitle style={{ margin: "16px 0" }}>
                            {formatMessage({
                              id: "campaignCreate.custom.destination",
                            })}
                          </StyledEngagementTitle>
                          <InputField
                            title={formatMessage({
                              id: "marketingSettings.domain.title",
                            })}
                            isRequired
                            prefixWord="https://"
                            control={control}
                            placeholder="example.com"
                            name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].websiteUrl`}
                            rules={{
                              ...(watchCampaignContentSets[tabIndex]
                                ?.engagementDistributionSettings[settingIndex]
                                ?.useOnchain && {
                                required: {
                                  value: true,
                                  message: `URL ${formatMessage({
                                    id: "global.error.required",
                                  })}`,
                                },
                                validate: (value) =>
                                  validateURL(value) ||
                                  formatMessage({
                                    id: "global.inputCheck.invalidUrl",
                                  }),
                              }),
                            }}
                            errors={get(
                              errorsInfo,
                              `campaignContentSets[${[
                                tabIndex,
                              ]}].engagementDistributionSettings[${settingIndex}].websiteUrl.message`
                            )}
                            value={distributionSetting.websiteUrl}
                            onChange={(e) =>
                              handleChangeDistributionSettings(
                                settingIndex,
                                "websiteUrl",
                                e.target.value
                              )
                            }
                          />
                          <p style={{ margin: "16px 0 4px" }}>
                            {formatMessage({
                              id: "engagementCampaign.trackSetting.linkWithParameter",
                            })}
                            <IconTooltip
                              style={{ marginLeft: "4px" }}
                              content={formatMessage({
                                id: "engagementCampaign.trackSetting.linkWithParameter.tooltip",
                              })}
                            />
                          </p>
                          <DetectURL
                            distributionSetting={distributionSetting}
                            tabIndex={tabIndex}
                          />
                          <div
                            style={{
                              display: "flex",
                              columnGap: 10,
                              margin: "32px 0 0px",
                            }}
                          >
                            <StyledEngagementTitle>
                              {"Link Format Settings"}
                            </StyledEngagementTitle>
                            <Switch
                              label={[]}
                              labelPlacement="start"
                              checked={distributionSetting.useLinkFormatSetting}
                              onChange={() => {
                                handleChangeDistributionSettingsData(
                                  settingIndex,
                                  {
                                    useLinkFormatSetting:
                                      !distributionSetting.useLinkFormatSetting,
                                    linkSetting: {},
                                  }
                                );
                                set(
                                  errorsInfo,
                                  `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting`,
                                  {}
                                );
                                setCustomSuffixApiError("");
                              }}
                            />
                          </div>
                          {distributionSetting.useLinkFormatSetting && (
                            <Box>
                              {type === "widget" && (
                                <Box display="flex" alignItems="center">
                                  <div style={{ width: "25%" }}>
                                    <Radio
                                      checked={
                                        distributionSetting?.linkSetting
                                          ?.type === "namingLink"
                                      }
                                      onChange={() =>
                                        handleChangeDistributionSettingsData(
                                          settingIndex,
                                          {
                                            linkSetting: {
                                              type: "namingLink",
                                            },
                                          }
                                        )
                                      }
                                      label={
                                        <StyledEngagementTitle
                                          style={{ margin: "16px 0" }}
                                        >
                                          {"Naming Link"}
                                        </StyledEngagementTitle>
                                      }
                                    />
                                  </div>
                                  <div style={{ width: "75%" }}>
                                    <InputField
                                      disabled={
                                        distributionSetting?.linkSetting
                                          ?.type !== "namingLink"
                                      }
                                      control={control}
                                      placeholder="You can customize the name of the link, e.g. Let's deposit"
                                      name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.linkTitle`}
                                      rules={{
                                        required: {
                                          value:
                                            watchCampaignContentSets[tabIndex]
                                              ?.engagementDistributionSettings[
                                              settingIndex
                                            ]?.useLinkFormatSetting &&
                                            watchCampaignContentSets[tabIndex]
                                              ?.engagementDistributionSettings[
                                              settingIndex
                                            ]?.linkSetting?.type ===
                                              "namingLink",
                                          message: `Link title ${formatMessage({
                                            id: "global.error.required",
                                          })}`,
                                        },
                                        validate: (value) =>
                                          value?.trim() !== "" ||
                                          formatMessage({
                                            id: "global.inputCheck.empty",
                                          }),
                                      }}
                                      errors={get(
                                        errorsInfo,
                                        `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.linkTitle.message`
                                      )}
                                      value={
                                        distributionSetting?.linkSetting
                                          ?.linkTitle || ""
                                      }
                                      onChange={(e) =>
                                        handleChangeDistributionSettingsData(
                                          settingIndex,
                                          {
                                            linkSetting: {
                                              type: "namingLink",
                                              linkTitle: e.target.value,
                                            },
                                          }
                                        )
                                      }
                                    />
                                  </div>
                                </Box>
                              )}
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={"space-between"}
                              >
                                <div style={{ width: "25%" }}>
                                  <Radio
                                    checked={
                                      distributionSetting?.linkSetting?.type ===
                                      "customSuffix"
                                    }
                                    onChange={() =>
                                      handleChangeDistributionSettingsData(
                                        settingIndex,
                                        {
                                          linkSetting: {
                                            type: "customSuffix",
                                          },
                                        }
                                      )
                                    }
                                    label={
                                      <StyledEngagementTitle
                                        style={{ margin: "16px 0" }}
                                      >
                                        {"Custom suffix"}
                                      </StyledEngagementTitle>
                                    }
                                  />
                                </div>
                                <div style={{ width: "75%" }}>
                                  <InputField
                                    disabled={
                                      distributionSetting?.linkSetting?.type !==
                                      "customSuffix"
                                    }
                                    prefixWord="https://bit.ly/"
                                    control={control}
                                    placeholder="demo"
                                    name={`campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink`}
                                    rules={{
                                      required: {
                                        value:
                                          watchCampaignContentSets[tabIndex]
                                            ?.engagementDistributionSettings[
                                            settingIndex
                                          ]?.useLinkFormatSetting &&
                                          watchCampaignContentSets[tabIndex]
                                            ?.engagementDistributionSettings[
                                            settingIndex
                                          ]?.linkSetting?.type ===
                                            "customSuffix",
                                        message: `Short Link ${formatMessage({
                                          id: "global.error.required",
                                        })}`,
                                      },
                                      validate: (value) =>
                                        value?.trim() !== "" ||
                                        formatMessage({
                                          id: "global.inputCheck.invalidUrl",
                                        }),
                                    }}
                                    errors={
                                      get(
                                        errorsInfo,
                                        `campaignContentSets[${tabIndex}].engagementDistributionSettings[${settingIndex}].linkSetting.shortLink.message`
                                      ) || customSuffixApiError
                                    }
                                    value={
                                      distributionSetting?.linkSetting
                                        ?.shortLink || ""
                                    }
                                    onChange={(e) => {
                                      let suffix = e.target.value;
                                      handleChangeDistributionSettingsData(
                                        settingIndex,
                                        {
                                          linkSetting: {
                                            type: "customSuffix",
                                            shortLink: e.target.value,
                                          },
                                        }
                                      );
                                      setCustomSuffixWithSettingIndex(
                                        `${settingIndex}-${suffix}`
                                      );
                                    }}
                                  />
                                </div>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </StyledInputContainer>
                </AccordionDetails>
              </StyledWhiteAccordion>
            );
          }
        )}
        {type !== "widget" && getActiveChannel() && (
          <AddButton
            style={{
              marginTop: "20px",
              background: "white",
              padding: 20,
              border: `1px dashed ${theme.customColors.purple[500]}`,
              height: "52px",
              fontWeight: theme.fontWeight.BOLD,
            }}
            color="#7B61FF"
            onClick={addDistributionSetting}
          >
            Add Distribution Setting
          </AddButton>
        )}
      </Box>
    </div>
  );
}

const DetectURL = ({ distributionSetting, tabIndex }) => {
  const { stepData } = useContext(CampaignCreationContext);
  const { name } = stepData;
  const trackingUrl = makeCustomTrackingLink(
    distributionSetting,
    name,
    tabIndex
  );
  const copyFn = useCopyToClipboard(trackingUrl);

  return (
    <InputField
      disabled={true}
      value={trackingUrl}
      endAdornment={
        <i
          className="meta-crm-icon-ic_copy font-size-16"
          style={{ color: "#A5A5A5", cursor: "pointer" }}
          onClick={copyFn}
        />
      }
    />
  );
};

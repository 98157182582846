import styled from "@emotion/styled/macro";

export const TitleText = styled("h1")(({ theme }) => ({
  fontSize: 38,
  fontWeight: theme.fontWeight.BOLD,

  span: {
    background:
      "linear-gradient(100.06deg, #f960ae 1.29%, #564bab 51.16%, #40748f 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    color: "transparent",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: 30,
  },
}));

import React, { useState, useEffect } from "react";
import _ from "lodash";
import Back from "features/metadesk/components/Back";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import FixedButton from "features/metadesk/settings/common/FixedButton";

const IntegrationSettings = ({
  controlHeaderView = false,
  headerContent,
  title,
  description,
  content,
  actions,
  footer,
  topActions,
  isFixedBarShow = true,
  isIsolateComponent,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ minHeight: "calc(100vh - 110px)" }}>
      {!isIsolateComponent && <Back />}
      {controlHeaderView ? (
        headerContent
      ) : (
        <>
          <div className="lgTitle" style={{ marginTop: 18 }}>
            {title}
          </div>
          <div className="description" style={{ lineHeight: "20px" }}>
            {description} <div>{topActions}</div>
          </div>
        </>
      )}
      <Box mt="32px" pb="60px">
        {/* {!isIsolateComponent && <div className="mhr" />} */}
        {content}
        {footer && !isIsolateComponent && <div className="mhr" />}
        {footer}
        {!isIsolateComponent && actions && (
          <FixedButton isFixedBarShow={isFixedBarShow}>
            <div style={{ marginRight: "0" }}>{actions}</div>
          </FixedButton>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationSettings;

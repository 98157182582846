import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slide,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { apiUrl } from "features/configure";
import _, { set } from "lodash";
import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import Dropzone from "react-dropzone";
import IconIcContract from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcContract";
import Papa from "papaparse";
import {
  StyledImportPopoverContainer,
  StyledImportPopoverTitle,
  StyledImportPopoverUpload,
  StyledImportPopoverLine,
  StyledImportPopoverNameArea,
  StyledImportPopoverFileName,
  StyledPreview,
  StyledImportPopoverErrorText,
  StyledNavBar,
  StyledImportWayBtn,
} from "./ImportDataPopover.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconIcCsv from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcCsv";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import { useDispatch } from "react-redux";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { Loading } from "features/common";
import { formatSizeUnits } from "features/helpers/format";
import {
  alertServerError,
  centerTruncate,
  exportCSV,
  formatDate,
} from "features/helpers/utils";
import { formatCSV } from "features/helpers/utils";
import ImportContractSelect from "./ImportContractSelect";
import UserTag from "../UserTag";
import { useIntl } from "react-intl";
import ImportDataHistoryBox from "./ImportDataHistoryBox";

const ImportDataPopover = ({
  onClose,
  open,
  entityName,
  fileSizeLimit,
  supportExtension,
  onFetchUserSource,
}) => {
  const { formatMessage } = useIntl();
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [importType, setImportType] = useState("");
  const [tags, setTags] = useState([]);
  const [file, setFile] = useState("");
  const [uploadData, setUploadData] = useState({});
  const [exportDataSource, setExportDataSource] = useState("");

  const [progress, setProgress] = useState(0);
  const [exportWay, setExportWay] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
  }, [importType]);

  useEffect(() => {
    let interval;
    if (isUploading) {
      interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 2 : 100
        );
      }, 1000);
    }
    return () => {
      if (interval) {
        setProgress(0);
        clearInterval(interval);
      }
    };
  }, [isUploading]);

  const validateFile = (file) => {
    if (!supportExtension.some((extension) => file.name.endsWith(extension))) {
      setError("Not supported file type!");
      return false;
    }
    if (file.size > fileSizeLimit) {
      setError("Over file limit 30MB!");
      return false;
    }
    return true;
  };

  const checkFile = async (f) => {
    if (!validateFile(f)) {
      setFile("");
      setUploadData({});
      return;
    }
    setError("");
    Papa.parse(f, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        try {
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          const addressArray = _.map(
            _.filter(results.data, (r) => r.address),
            (r) => r.address.toLowerCase()
          );

          const idArray = _.map(
            _.filter(results.data, (r) => r._id),
            (r) => r._id
          );

          const duplicateAddress =
            addressArray.length !== _.uniq(addressArray).length;
          const duplicateId = idArray.length !== _.uniq(idArray).length;

          if (duplicateAddress) {
            setError(
              "Please check the file again. There are duplicate addresses present."
            );
            setFile("");
            setUploadData({});
          } else if (duplicateId) {
            setError(
              "Please check the file again. There are duplicate ids present."
            );
            setFile("");
            setUploadData({});
          } else {
            setFile(f);
            await uploadToServer(f);
          }
        } catch (err) {
          setError(
            _.get(
              err,
              "response.data.error",
              "Please check the file again. There are some errors."
            )
          );
          setUploadData({});
        } finally {
          setIsUploading(false);
        }
      },
    });
  };

  const handleClear = () => {
    onClose();
    setFile("");
    setError("");
    setTags([]);
    setUploadData({});
    setExportDataSource("");
    setImportType("");
  };

  const handleChooseFile = async (f) => {
    setIsUploading(true);
    checkFile(f);
  };

  const disableConfirm = () => {
    if (importType == "contract") {
      if (!_.get(uploadData, "contract.name")) return true;
    }
    if (importType == "csv") {
      if (!file) return true;
    }

    if (isUploading) {
      return true;
    }
    if (error) {
      return true;
    }
    return false;
  };

  const uploadToServer = async (f) => {
    if (!f) return;
    setIsUploading(true);
    let postFormData = new FormData();
    postFormData.append("csv", f);
    postFormData.append("entityName", entityName);
    const uploadResult = await axios.post(
      `${apiUrl}/api/gcp/uploadCSV?entityName=` + entityName,
      postFormData
    );
    let csv = _.get(uploadResult, "data.csv[0]");
    const previewResult = await axios.post(
      `${apiUrl}/api/userSource/${entityName}/csv/preview`,
      { csvFileUrl: csv }
    );

    setUploadData({ csv, ...previewResult.data, csvName: formatFileName(f) });
    setError("");

    setIsUploading(false);
  };

  const exportUsers = async (userSourceId) => {
    try {
      setIsExporting(true);

      let data = {};
      if (userSourceId == "all") {
        data = {
          from: "all",
        };
      } else {
        data = {
          userSourceId: userSourceId,
        };
      }

      const exportResult = await axios.post(
        `${apiUrl}/api/userSource/${entityName}/export`,
        data
      );
      exportCSV(formatCSV(exportResult.data));
    } catch (e) {
      console.log("e: ", e);
      dispatch(alertServerError(e));
    } finally {
      setIsExporting(false);
    }
  };

  const handleImport = async () => {
    try {
      setIsLoading(true);
      let res;
      let data = {};

      if (tags.length > 0) {
        data["tagIds"] = tags;
      }

      if (importType == "contract") {
        data = {
          ...data,
          address: _.get(uploadData, "contract.address"),
          chainId: _.get(uploadData, "contract.chainId"),
          type: _.get(uploadData, "contract.type"),
          timeRange: _.get(uploadData, "contract.range"),
        };

        res = await axios.post(
          apiUrl + `/api/userSource/${entityName}/contract/import`,
          data
        );
      }
      if (importType == "csv") {
        if (!uploadData.csv) return;
        data = {
          ...data,
          csvFileName: uploadData.csvName,
          csvFileUrl: uploadData.csv,
          entityName,
        };

        res = await axios.post(
          `${apiUrl}/api/userSource/${entityName}/csv/import`,
          data
        );
      }

      onFetchUserSource();
      handleClear();
    } catch (e) {
      // dispatch(alertServerError(e));
      setError(_.get(e, "response.data.error", "Error"));
    } finally {
      setIsLoading(false);
    }
  };
  const renderNav = () => {
    if (!importType) return;
    return (
      <StyledNavBar>
        {importType == "contract" && (
          <div
            style={{
              textAlign: "center",
              padding: 16,
            }}
          >
            How to find address of smart contract?{" "}
            <a
              href="https://support.metamask.io/hc/en-us/articles/360059683451-How-to-find-a-token-contract-address"
              target="_blank"
              style={{ textDecoration: "underline", color: "#7B61FF" }}
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
        )}
        <div
          className="startRow"
          style={{
            borderTop: "1px solid #d6d6d6",
            margin: "0px 40px",
            paddingTop: 27,
          }}
        >
          <Button
            color={COLOR.SECONDARY}
            style={{ width: 120 }}
            variant={VARIANT.TEXT}
            onClick={() => {
              setImportType("");
              setTags([]);
            }}
          >
            Back
          </Button>
          <Button
            color={COLOR.SECONDARY}
            disabled={disableConfirm()}
            onClick={() => {
              handleImport();
            }}
            style={{ marginLeft: 8, width: "calc(100% - 130px)" }}
          >
            {"Confirm to Import"}
          </Button>
        </div>
      </StyledNavBar>
    );
  };

  const formatFileName = (file) => {
    return centerTruncate(_.get(file, "name"));
  };

  const renderCSVImport = () => {
    return (
      <div>
        <StyledImportPopoverTitle>CSV Import</StyledImportPopoverTitle>
        <Accordion
          disableGutters={true}
          sx={{
            "&:before": {
              display: "none",
            },
            borderRadius: "3px",
            border: "1px solid #e6e6e6",
            boxShadow: "0px 0px 0px",
            marginBottom: "8px",
            "& .Mui-expanded": {
              borderRadius: "3px 3px 0 0",
              borderBottom: 0,
            },
            "& .MuiAccordion-region": {
              borderRadius: "0 0 3px 3px",
              borderTop: 0,
            },
            "&:hover": {},
          }}
        >
          <AccordionSummary
            sx={{
              "& .Mui-expanded": { border: 0, marginBottom: 0 },
              maxWidth: "100%",
            }}
            expandIcon={<ExpandMoreIcon style={{ color: "#7B61FF" }} />}
          >
            <div className="betweenRow">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#7B61FF",
                  width: 250,
                }}
              >
                Use Template
              </div>
              <div style={{ fontWeight: 300, fontSize: 12 }}>
                Export the template and fill in the required fields before
                uploading it
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              borderTop: "1px solid #e6e6e6",
              marginTop: 10,
              padding: "22px 24px",
            }}
          >
            <div>
              <CustomRadioGroup
                alignItems="flex-start"
                value={exportWay}
                onChange={(e) => setExportWay(e.target.value)}
                data={[
                  {
                    label: (
                      <div style={{ padding: "6px 0" }}>
                        CSV Template
                        <div className="memo">
                          You can download the attached example and use them as
                          a starting point for your user list.
                        </div>
                      </div>
                    ),
                    value: "template",
                  },
                ]}
              />
              <div style={{ textAlign: "center" }}>
                <Button
                  color={COLOR.SECONDARY}
                  style={{ width: 130 }}
                  disabled={
                    !exportWay || (exportWay == "data" && !exportDataSource)
                  }
                  onClick={() => {
                    if (exportWay == "template") {
                      window.open(
                        "https://storage.googleapis.com/metadesk-dev/MetaCRM_CSV_template_v3.csv",
                        "_blank"
                      );
                    } else {
                      exportUsers(exportDataSource);
                    }
                  }}
                >
                  Download
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Dropzone
          onDrop={(acceptedFiles) => handleChooseFile(acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <StyledImportPopoverUpload {...getRootProps()}>
              Drop a .CSV file here <span>or</span>
              <input
                type="file"
                {...getInputProps()}
                accept=".csv"
                placeholder="Select a file"
              />
              <Button color={COLOR.SECONDARY}>Select a file</Button>
            </StyledImportPopoverUpload>
          )}
        </Dropzone>

        {file && (
          <StyledImportPopoverFileName>
            <div className="betweenRow">
              <div className="startRow">
                <IconIcCsv width={24} height={24} style={{ marginRight: 8 }} />
                <div>
                  <div className="formTitle">{formatFileName(file)} </div>
                  <div className="startRow memo">
                    {formatSizeUnits(_.get(file, "size", 0))}
                    {uploadData.csv && " - Preview completed"}
                    <i
                      className="meta-crm-icon-ic_check font-size-18"
                      style={{ fontWeight: 700, marginLeft: 4 }}
                    />
                  </div>
                </div>
              </div>
              {!isUploading && (
                <IconButton
                  onClick={() => {
                    setFile("");
                    setUploadData({});
                    setError("");
                  }}
                  size={16}
                >
                  <i className="meta-crm-icon-ic_trash font-size-12" />
                </IconButton>
              )}
            </div>
            {isUploading && (
              <div style={{ marginTop: "10px" }}>
                <LinearProgress color="info" />
              </div>
            )}
          </StyledImportPopoverFileName>
        )}
        {uploadData.csv && (
          <StyledPreview>
            <div className="inputTitle">{"Import Preview:"} </div>
            <div style={{ marginTop: 5 }}>
              <ul style={{ paddingLeft: 20 }}>
                <li className="dotList">{`${uploadData.totalCount}/ 2000 (Only the first 2000 rows will be uploaded)`}</li>
                <li className="dotList">{`${uploadData.createdCount} users will be created. `}</li>
                <li className="dotList">{`${uploadData.updatedCount} existing users will be updated. `}</li>
                <li className="dotList">{`${uploadData.validCols} valid fields `}</li>
              </ul>
            </div>
          </StyledPreview>
        )}

        {error && (
          <StyledImportPopoverErrorText>
            {file && (
              <div style={{ fontWeight: 500, marginBottom: 5 }}>
                {formatFileName(file)}
              </div>
            )}
            {error}
          </StyledImportPopoverErrorText>
        )}
        {renderAssignTag()}
      </div>
    );
  };
  const renderAssignTag = () => {
    return (
      <div>
        <StyledImportPopoverLine />
        <StyledImportPopoverNameArea className="startRow">
          <div style={{ marginBottom: 11, paddingLeft: 5 }}>
            Assign tags to all the imported users
          </div>
          <UserTag
            entityName={entityName}
            width="100%"
            embed={true}
            initTags={tags}
            tagListHeight={100}
            setInitTags={setTags}
          />
        </StyledImportPopoverNameArea>
      </div>
    );
  };

  const renderContractImport = () => {
    return (
      <div style={{ position: "relative" }}>
        <StyledImportPopoverTitle>
          Import by Smart Contract
        </StyledImportPopoverTitle>
        <ImportContractSelect
          entityName={entityName}
          editable={true}
          setError={setError}
          setIsUploading={setIsUploading}
          isUploading={isUploading}
          uploadData={uploadData}
          setUploadData={setUploadData}
          onSelect={async (contractData) => {
            const result = await axios.post(
              apiUrl + `/api/userSource/${entityName}/contract/preview`,
              {
                address: contractData.address,
                chainId: contractData.chainId,
                type: contractData.type,
                range: contractData.range,
              }
            );
            const data = result.data;
            setUploadData({
              contract: contractData,
              total: data.total,
              overLimit: data.overLimit,
              existing: data.existing,
              limit: data.limit,
            });
          }}
        />
        {uploadData.contract && (
          <StyledPreview>
            <Loading
              fullScreen={false}
              open={isUploading}
              sm={true}
              white={true}
              text={"Fetching..."}
            />
            <p>
              <b>{formatMessage({ id: "importByContract.preview.title" })}</b>
            </p>
            <div style={{ marginTop: 5 }}>
              <p className="inputTitle">
                {formatMessage({
                  id: "importByContract.preview.subtitle.input",
                })}
              </p>
              <ul style={{ paddingLeft: 20 }}>
                <li className="dotList">
                  Contract:{" "}
                  {uploadData.contract?.name ||
                    uploadData.contract?.address ||
                    null}
                </li>
                <li className="dotList">
                  Import type:{" "}
                  {
                    {
                      "nft-holder": "NFT Holders",
                      "token-holder": "Token Holders",
                      "contract-interactor": "Contract Interactors",
                    }[uploadData.contract?.type]
                  }
                </li>
                {uploadData.contract?.range?.length > 0 && (
                  <li className="dotList">
                    Time Range:{" "}
                    {uploadData.contract?.range
                      .split(";")
                      .map((d) => formatDate(d, true))
                      .join("~")}
                  </li>
                )}
              </ul>
            </div>
            <div style={{ marginTop: 5 }}>
              <p className="inputTitle">
                {formatMessage({
                  id: "importByContract.preview.subtitle.output",
                })}
              </p>
              <ul style={{ paddingLeft: 20 }}>
                <li className="dotList">
                  {`${
                    uploadData.overLimit ? uploadData.limit : uploadData.total
                  } ${
                    uploadData.contract?.type === "contract-interactor"
                      ? "interactors"
                      : "holders"
                  } will be created as customers.`}
                </li>
                <li className="dotList">{`${uploadData.existing} existing customers will be updated.`}</li>
              </ul>
            </div>
          </StyledPreview>
        )}
        {isUploading && (
          <LinearProgress color="info" variant="determinate" value={progress} />
        )}
        {error && (
          <StyledImportPopoverErrorText>{error}</StyledImportPopoverErrorText>
        )}

        {renderAssignTag()}
      </div>
    );
  };

  const [histories, setHistories] = useState([]);
  useEffect(() => {
    async function fetchHistory() {
      const { data } = await axios.get(
        apiUrl + `/api/userSource/${entityName}/importHistory`
      );
      setHistories(data);
    }
    fetchHistory();
  }, []);

  const renderContent = () => {
    if (importType == "csv") {
      return renderCSVImport();
    }
    if (importType == "contract") {
      return renderContractImport();
    }
    return (
      <div>
        <StyledImportPopoverTitle>
          Import Your Customer Data to Get Started
        </StyledImportPopoverTitle>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledImportWayBtn
              fullWidth
              onClick={() => setImportType("csv")}
              variant={VARIANT.SHADOW}
            >
              <div
                className="betweenRow"
                style={{ width: "100%", fontWeight: 500 }}
              >
                <div className="startRow">
                  <IconIcCsv
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  CSV
                </div>{" "}
                <i className="meta-crm-icon-ic_add font-size-18" />
              </div>
            </StyledImportWayBtn>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledImportWayBtn
              fullWidth
              onClick={() => setImportType("contract")}
              variant={VARIANT.SHADOW}
            >
              <div
                className="betweenRow"
                style={{ width: "100%", fontWeight: 500 }}
              >
                <div className="startRow">
                  <IconIcContract
                    width={24}
                    height={24}
                    style={{ marginRight: 8 }}
                  />
                  Smart Contract
                </div>{" "}
                <i className="meta-crm-icon-ic_add font-size-18" />
              </div>
            </StyledImportWayBtn>
          </Grid>
        </Grid>
        <Box
          sx={{
            borderBottom: "1px solid #E6E6E6",
            padding: "16px 0",
            m: "24px 0 16px",
            color: "#777",
          }}
        >
          {formatMessage({ id: "import.history.subtitle" })}
        </Box>
        {histories.map((history) => (
          <ImportDataHistoryBox history={history} />
        ))}
      </div>
    );
  };
  return (
    <Slide direction="left" in={open}>
      <StyledImportPopoverContainer>
        <div
          style={{
            position: "relative",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: 18, right: 18 }}
            onClick={() => {
              handleClear();
            }}
          >
            <i className="meta-crm-icon-ic_cancel font-size-18" />
          </IconButton>
          <div style={{ padding: "48px 40px" }}>{renderContent()}</div>
        </div>
        {renderNav()}
        <Loading
          open={isLoading || isExporting}
          fullScreen={false}
          text={isExporting ? "Data Exporting" : "Data Importing"}
        />
      </StyledImportPopoverContainer>
    </Slide>
  );
};

ImportDataPopover.propTypes = {
  onClose: () => {},
  open: PropTypes.bool,
  anchorEl: PropTypes.shape({}),
  supportExtension: PropTypes.arrayOf(PropTypes.string),
  fileSizeLimit: PropTypes.number,
  onFetchUserSource: () => {},
};

ImportDataPopover.defaultProps = {
  onClose: null,
  open: false,
  anchorEl: null,
  supportExtension: [".csv"],
  fileSizeLimit: 30 * 1024 * 1024,
  onFetchUserSource: null,
};

export default ImportDataPopover;

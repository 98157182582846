import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const CreateNewWorkspaceContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  height: 80,
  display: "flex",
  background: "#ffffff80",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 5,
  marginTop: 32,
}));

import React, { useContext, useState, useEffect, useMemo } from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import {
  Button,
  VARIANT,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useOutletContext } from "react-router";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import Box from "@mui/material/Box";
import { StyledDropdownContainer } from "./component/PerformanceFilter.styles";
import { useFetchTags, useFetchUserList } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import { SinglePerformanceContext } from "./context/SinglePerformanceProvider";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { get, isEmpty } from "lodash-es";
import { useTheme } from "@mui/styles";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const userTypeOptions = [
  // { label: "All", value: "" },
  { label: "Returning", value: "returning" },
  { label: "New", value: "new" },
];

const walletBalanceOptions = [
  // { label: "All", value: "" },
  { label: "Over $10k", value: "over10k" },
  { label: "Under $10k", value: "under10k" },
];

const walletAgeOptions = [
  // { label: "All", value: "" },
  { label: "Over 365 days", value: "over1y" },
  { label: "Under 365 days", value: "under1y" },
];

const objectiveAchievedOptions = [
  // { label: "All", value: "" },
  { label: "Achieved", value: "true" },
  { label: "Not Achieved", value: "false" },
];

const filterMap = {
  dateRange: true,
  userType: true,
  dateRange: true,
  walletBalance: true,
  walletAge: true,
  objectiveAchieved: true,
  tags: true,
};

export default function SinglePerformanceFilter({ campaign }) {
  const { id } = useParams();
  const dispatchSnackbar = useDispatchSnackbar();
  const theme = useTheme();
  const { tags } = useFetchTags();
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  const [utmIsLoading, setUtmIsLoading] = useState(false);
  const [utmData, setUtmData] = useState(null);
  const { filterDetail, setFilterDetail, filterData, setFilterData } =
    useContext(SinglePerformanceContext);

  const [searchParams] = useSearchParams();
  const utmLinkFormUrl = searchParams.get("utmLink");

  const utmDataOptions = useMemo(() => {
    if (utmData) {
      return [
        {
          label: "All",
          value: "",
        },
        ...utmData.map((item) => {
          return { label: item.originalLink, value: item._id };
        }),
      ];
    }
    return null;
  }, [utmData]);

  // const [filterData, setFilterData] = useState({
  //   userType: "",
  // });

  // const tagOptions = useMemo(
  //   () => [
  //     {
  //       label: "All",
  //       value: "",
  //     },
  //     ...get(tags, entityName, []).map((item) => {
  //       return { ...item, label: item.value, value: item._id };
  //     }),
  //   ],
  //   [tags]
  // );

  const tagOptions = useMemo(
    () => [
      ...get(tags, entityName, []).map((item) => {
        return { ...item, label: item.value, value: item._id };
      }),
    ],
    [tags]
  );

  const getPerformanceData = async (e) => {
    try {
      setUtmIsLoading(true);
      const { data, headers } = await axios.get(
        `${apiUrl}/api/campaignPerformance/${entityName}/detail/${id}/utms`
      );
      setUtmData(data);
    } catch (error) {
      dispatchSnackbar({
        message: error,
      });
    } finally {
      setUtmIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!isEmpty(campaign)) {
  //     console.log("campaign: ", campaign);
  //     setFilterData((prevFilter) => ({
  //       ...prevFilter,
  //       periodStart: campaign.startFrom,
  //       periodEnd: campaign.endAt,
  //     }));
  //   }
  // }, []);

  useEffect(() => {
    if (utmLinkFormUrl) {
      getPerformanceData();
      setFilterData((prevFilter) => ({
        ...prevFilter,
        utmId: utmLinkFormUrl,
      }));
    }
  }, [utmLinkFormUrl]);

  const handleChange = (event, value) => {
    // setFilterDetail((prevFilter) => ({
    //   ...prevFilter,
    //   [event.target.name]: value.value,
    // }));
    setFilterData((prevFilter) => ({
      ...prevFilter,
      [event?.target?.name]: value.value,
    }));
  };

  const handleChangeDate = (value) => {
    // setFilterDetail((prevFilter) => ({
    //   ...prevFilter,
    //   periodStart: value[0].startDate,
    //   periodEnd: value[0].endDate,
    // }));
    setFilterData((prevFilter) => ({
      ...prevFilter,
      periodStart: value[0].startDate,
      periodEnd: value[0].endDate,
    }));
  };

  const handleClear = () => {
    // setFilterDetail((prevFilter) => ({
    //   ...prevFilter,
    //   userType: "",
    //   walletBalance: "",
    //   walletAge: "",
    //   objectiveAchieved: "",
    //   periodStart: null,
    //   periodEnd: null,
    //   tag: "",
    // }));
    setFilterData((prevFilter) => ({
      ...prevFilter,
      userType: "",
      walletBalance: "",
      walletAge: "",
      objectiveAchieved: "",
      periodStart: null,
      periodEnd: null,
      tag: "",
    }));
  };

  const handleChangeFilter = (e, filterType) => {
    e.keepOpen = true;
    setFilterData({ ...filterData, [filterType]: e.value });
  };

  const userTypeFilterNumber = useMemo(
    () => (filterData.userType ? 1 : 0),
    [filterData]
  );

  const walletBalanceFilterNumber = useMemo(
    () => (filterData.walletBalance ? 1 : 0),
    [filterData]
  );

  const walletAgeFilterNumber = useMemo(
    () => (filterData.walletAge ? 1 : 0),
    [filterData]
  );

  const objectiveAchievedFilterNumber = useMemo(
    () => (filterData.objectiveAchieved ? 1 : 0),
    [filterData]
  );

  const tagFilterNumber = useMemo(() => (filterData.tag ? 1 : 0), [filterData]);

  const topFilterNumber = useMemo(
    () =>
      userTypeFilterNumber +
      walletBalanceFilterNumber +
      walletAgeFilterNumber +
      objectiveAchievedFilterNumber +
      tagFilterNumber,
    [
      userTypeFilterNumber,
      walletBalanceFilterNumber,
      walletAgeFilterNumber,
      objectiveAchievedFilterNumber,
      tagFilterNumber,
    ]
  );

  const itemList = [
    {
      children: <>New/Returning</>,
      type: "radioGroup",
      value: filterData.userType,
      onRadioChange: (e) => handleChangeFilter(e, "userType"),
      filterNumber: userTypeFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "radio",
          checked: filterData.userType === "",
        },
        ...userTypeOptions.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "radio",
          checked: filterData.userType === item.value,
        })),
      ],
    },
    {
      children: <>Wallet Age</>,
      type: "radioGroup",
      value: filterData.walletAge,
      onRadioChange: (e) => handleChangeFilter(e, "walletAge"),
      filterNumber: walletAgeFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "radio",
          checked: filterData.walletAge === "",
        },
        ...walletAgeOptions.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "radio",
          checked: filterData.walletAge === item.value,
        })),
      ],
    },
    {
      children: <>Wallet Balance</>,
      type: "radioGroup",
      value: filterData.walletBalance,
      onRadioChange: (e) => handleChangeFilter(e, "walletBalance"),
      filterNumber: walletBalanceFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "radio",
          checked: filterData.walletBalance === "",
        },
        ...walletBalanceOptions.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "radio",
          checked: filterData.walletBalance === item.value,
        })),
      ],
    },
    {
      children: <>Objective Achieved</>,
      type: "radioGroup",
      value: filterData.objectiveAchieved,
      onRadioChange: (e) => handleChangeFilter(e, "objectiveAchieved"),
      filterNumber: objectiveAchievedFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "radio",
          checked: filterData.objectiveAchieved === "",
        },
        ...objectiveAchievedOptions.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "radio",
          checked: filterData.objectiveAchieved === item.value,
        })),
      ],
    },

    {
      children: <>Tag</>,
      type: "radioGroup",
      value: filterData.tag,
      onRadioChange: (e) => handleChangeFilter(e, "tag"),
      filterNumber: tagFilterNumber,
      subMenu: [
        {
          children: <>All</>,
          value: "",
          type: "radio",
          checked: filterData.tag === "",
        },
        ...tagOptions.map((item) => ({
          children: <>{item.label}</>,
          value: item.value,
          type: "radio",
          checked: filterData.tag === item.value,
          backgroundColor: item.color,
        })),
      ],
    },
  ];

  return (
    <Box
      display="flex"
      mt={2}
      flexWrap="nowrap"
      sx={{
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "4px 4px 0 0",
      }}
    >
      {utmDataOptions && (
        <Box
          sx={{
            width: "65%",
            border: `1px solid ${theme.customColors.grey[300]}`,
            borderRadius: "4px",
            mr: "5px",
          }}
        >
          <AutoCompleteDropdown
            value={utmDataOptions?.find(
              (option) => option.value === filterData.utmId
            )}
            options={utmDataOptions}
            onChange={handleChange}
            size={SIZE.MEDIUM}
            name="utmId"
            prefix={`${formatMessage({
              id: "websiteTraffic.searchBar.from",
            })}:`}
            noBorder
          />
        </Box>
      )}

      <Box
        mr="5px"
        sx={{
          border: `1px solid ${theme.customColors.grey[300]}`,
          borderRadius: "4px",
          ml: "auto",
        }}
      >
        <Menu
          overflow="auto"
          position="initial"
          itemList={itemList}
          topFilterNumber={topFilterNumber}
          isFilter
        />
      </Box>

      <Box
        sx={{
          border: `1px solid ${theme.customColors.grey[300]}`,
          borderRadius: "4px",
        }}
      >
        <DateRangeSelect
          dropdown
          shownDate={new Date(campaign.startFrom || null)}
          maxDate={new Date(campaign.endAt || null)}
          minDate={new Date(campaign.startFrom || null)}
          defaultInputRanges={null}
          prefix={
            <Box display="flex" alignItems="center">
              {formatMessage({
                id: "campaignDetail.filterCriteria.trackingPeriod",
              })}
              &nbsp;:&nbsp;
            </Box>
          }
          value={[
            {
              startDate:
                filterData.periodStart || new Date(campaign.startFrom || null),
              endDate: filterData.periodEnd || new Date(campaign.endAt || null),
              key: "selection",
            },
          ]}
          onChange={handleChangeDate}
        />
      </Box>

      {/* 
      <Button
        variant={VARIANT.TEXT}
        style={{ padding: 0, marginLeft: 4 }}
        size={SIZE.SMALL}
        onClick={handleClear}
      >
        {formatMessage({ id: "global.filter.clean" })}
      </Button> */}
    </Box>
  );
}

import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { getShortAddress } from "features/helpers/utils";
import React from "react";
import { useAccount } from "wagmi";
import { useOnboardingState } from "../OnboardingProvider";
import { useNavigate } from "react-router";

export default function HasWorkspace({ workSpace }) {
  const theme = useTheme();
  const { address: evmAddress } = useAccount({});
  const navigate = useNavigate();
  const { nextStep } = useOnboardingState();

  return (
    <Box position="relative">
      <Box
        sx={{
          height: "60vh",
          bgcolor: "#FAFAFD",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        p="28px 0 16px"
      >
        <Box
          sx={{
            height: "fit-content",
            textAlign: "center",
            px: "62px",
          }}
        >
          <img
            src={require("assets/img/onboardingLogo.png")}
            style={{ width: 79, height: 79 }}
          />
          <Box fontSize="24px" fontWeight={700} mt="8px" mb="4px">
            Enter Existing Workspace
          </Box>
          <Box
            color="#777"
            fontSize="14px"
            m="4px auto"
            mb="16px"
            p="0 5px"
            maxWidth={"363px"}
          >
            Your account (wallet){" "}
            <span style={{ color: "#7B61FF" }}>
              {getShortAddress(evmAddress)}
            </span>{" "}
            has been added to the following workspace. Click to enter!
          </Box>
          <Box
            sx={{
              height: "calc(50vh - 200px)",
              overflowY: "auto",
              px: "62px",
            }}
          >
            {workSpace.map((workspace, i) => (
              <Box
                key={i}
                onClick={() => {
                  navigate(`/${workspace.name}`);
                  window.location.reload();
                }}
                sx={{
                  border: "1px solid #E6E6E6",
                  background: "white",
                  borderRadius: "8px",
                  p: "10px 12px",
                  mb: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  cursor: "pointer",
                  "&:hover": {
                    border: `1px solid ${theme.customColors.purple[500]}`,
                  },
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={5} className="startRow">
                    <img
                      src={
                        workspace?.company?.logo ||
                        require("assets/img/header.svg").default
                      }
                      style={{
                        width: 28,
                        height: 28,
                        flexShrink: 0,
                        objectFit: "contain",
                      }}
                    />
                    <Box
                      ml="12px"
                      fontSize="16px"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "4px",
                      }}
                    >
                      {workspace.name}
                    </Box>
                  </Grid>
                  <Grid item xs={8} md={5} className="startRow">
                    <Box
                      sx={{
                        width: Math.min(workspace.memberCount, 5) * 22 - 20,
                      }}
                      position="relative"
                      height="22px"
                    >
                      {Array.from({
                        length: Math.min(workspace.memberCount, 5),
                      }).map((_, i) => (
                        <img
                          key={`image-${i + 1}`}
                          src={require(`assets/img/workspace_ic/ic_alpaca-${
                            i + 1
                          }.png`)}
                          width={22}
                          height={22}
                          style={{
                            position: "absolute",
                            left: `${i * 15}px`,
                          }}
                        />
                      ))}
                    </Box>
                    {workspace.memberCount > 5 && (
                      <Box fontSize="11px" color="#A5A5A5">
                        ...and other {workspace.memberCount - 5} people
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4} md={2} className="endRow">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        color: "#7B61FF",
                        cursor: "pointer",
                      }}
                    >
                      Enter
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4175_17331)">
                          <path
                            d="M4.16659 10.8333H13.4749L9.40825 14.8999C9.08325 15.2249 9.08325 15.7583 9.40825 16.0833C9.73325 16.4083 10.2583 16.4083 10.5833 16.0833L16.0749 10.5916C16.3999 10.2666 16.3999 9.7416 16.0749 9.4166L10.5916 3.9166C10.2666 3.5916 9.74159 3.5916 9.41659 3.9166C9.09159 4.2416 9.09159 4.7666 9.41659 5.0916L13.4749 9.1666H4.16659C3.70825 9.1666 3.33325 9.5416 3.33325 9.99993C3.33325 10.4583 3.70825 10.8333 4.16659 10.8333Z"
                            fill="#7B61FF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4175_17331">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        top="60%"
        left={"50%"}
        sx={{ transform: "translate(-50%, -50%)" }}
        color="#777"
        fontSize="14px"
      >
        or
      </Box>
      <Box
        sx={{
          height: "40vh",
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        pt="116px"
      >
        <Box sx={{ width: "400px", textAlign: "center" }}>
          <Box fontSize="20px" fontWeight={700} mb="4px">
            Want to create your own workspace?
          </Box>
          <Box color="#777" fontSize="14px" mb="32px">
            If you don’t want to join any existing workspace, you can choose to
            create a new one for your team!
          </Box>
          <Button onClick={nextStep}>Create Workspace</Button>
        </Box>
      </Box>
    </Box>
  );
}

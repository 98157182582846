import React, { useState, useEffect } from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import _ from "lodash";
import moment from "moment";
import {
  radiantGovernaceToken,
  tokenMapping,
  radiantAccountingTokens,
  radiantLPToken,
} from "./configure";
import { radiantAccountingTokensV1 } from "./configure/radiantV1Contracts";
import { makeStyles } from "@material-ui/core/styles";
import CustomTabs from "components/CustomTabs/Tabs";
import { useFetchRadiantDetails } from "./redux/fetchRadiantDetails";
import { useFetchRadiantV1Details } from "./redux/fetchRadiantV1Details";
import { useFetchRadiantOnchainDetails } from "./redux/fetchRadiantOnchainDetails";
import { useFetchRadiantUserDetails } from "./redux/fetchRadiantUserDetails";
import { useFetchRadiantTxs } from "./redux/fetchRadiantTxs";
import { useFetchTransactionType } from "../redux/fetchTransactionType";
import {
  convertAmountFromRawNumber,
  formatValue,
  convertHexToString,
} from "features/helpers/bignumber";
import { convertToCountdown } from "./utils";
import BigNumber from "bignumber.js";
import UserTitle from "../components/UserTitle";
import UserDetail from "../components/UserDetail";
import TicketTable from "../components/TicketTable";
import Rows from "components/Rows/Rows";
import { useFetchPrice } from "features/price/fetchPrice";
import CustomDataFetching from "components/CustomDataFetching/CustomDataFetching";
import { Loading } from "features/common";
import SelectChain from "../components/SelectChain";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import Transaction from "../components/Transaction";
import { Grid } from "@material-ui/core";
import { useFetchUserWalletInfo } from "../redux/fetchUserWalletInfo";
import { images, defaultImage } from "features/configure";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#777777",
    fontWeight: 700,
    fontSize: 12,
    marginRight: 6,
  },

  subTitle: {
    fontWeight: 700,
    fontSize: 12,
    color: "#777777",
    marginBottom: 5,
  },
  value: { fontWeight: 700, fontSize: 14 },
  smValue: { fontWeight: 700, fontSize: 15 },

  desc: { color: "#A5A5A5", fontSize: 12, marginLeft: 3, marginTop: 1 },
  smIcon: { marginLeft: 3, width: 16, height: 16 },
  icon: { marginRight: 3, width: 20, height: 20 },

  swap: {
    width: 23,
    height: 23,
    margin: 10,
  },
  rowHeader: {
    color: "#7B61FF",
    fontSize: 10,
    fontWeight: 700,
  },
  smIcon: { marginRight: 5, width: 15, height: 15 },
  leftBorder: {
    borderLeft: "0.5px solid #A5A5A5",
    paddingLeft: 17,
    marginLeft: 17,
  },
  selectMethod: {
    paddingLeft: 13,
    color: "#555555",
    fontWeight: 400,
    fontSize: 12,
  },
}));

const RadiantUserInfo = ({
  ticketListData,
  selectedUserId,
  selectedUserAddress,
  entityName,
  isFetching,
  fetchUserObjs,
}) => {
  const classes = useStyles();
  const [radiantDepositsPoolsDetails, setRadiantDepositsPoolsDetails] =
    useState([]);
  const [radiantBorrowsPoolsDetails, setRadiantBorrowsPoolsDetails] = useState(
    []
  );

  const { entityConfig } = useFetchEntityConfig();
  const { formatMessage } = useIntl();
  const chainList = useSelector(selectEntityChainList);

  const [stakeDetails, setStakeDetails] = useState([]);
  const [relockList, setReLockList] = useState([]);
  const [earningsInfo, setEarningsInfo] = useState({});
  const [autoRelockDisabled, setAutoRelockDisabled] = useState(true);

  const [radiantV1PoolsDetails, setRadiantV1PoolsDetails] = useState([]);

  const [selectedChain, setSelectedChain] = useState({
    deposits: 0,
    borrows: 0,
    transaction: 0,
    stake: 0,
    defaultLock: 0,
    vest: 0,
  });
  const [radiantNetWorth, setRadiantNetWorth] = useState({});
  const [radiantHealthFactor, setRadiantHealthFactor] = useState({});

  const { userWalletInfos } = fetchUserObjs();
  const userInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, {}) || {};

  const { radiantDetails, fetchRadiantDetails, fetchRadiantDetailsPending } =
    useFetchRadiantDetails();
  const {
    radiantV1Details,
    fetchRadiantV1Details,
    fetchRadiantV1DetailsPending,
  } = useFetchRadiantV1Details();

  const {
    radiantOnchainDetails,
    fetchRadiantOnchainDetails,
    fetchRadiantOnchainDetailsPending,
  } = useFetchRadiantOnchainDetails();

  const { transactionTypeList, fetchTransactionType } =
    useFetchTransactionType();

  const [radiantV1Balances, setRadiantV1Balances] = useState(0);
  const [radiantV1VestingBalances, setRadiantV1VestingBalances] = useState(0);

  const { fetchPrice, priceData } = useFetchPrice();

  const relockTypeIndexMapping = {
    0: 2592000,
    1: 7776000,
    2: 15552000,
    3: 31104000,
  };
  const supportChains = [
    { name: "All", id: 0 },
    ..._.filter(chainList, function (o) {
      return _.includes(entityConfig.chainIds, o.id);
    }),
  ];
  const {
    radiantUserDetails,
    fetchRadiantUserDetails,
    fetchRadiantUserDetailsPending,
  } = useFetchRadiantUserDetails();
  const { radiantTxs, fetchRadiantTxs, fetchRadiantTxsPending } =
    useFetchRadiantTxs();
  const [radiantTransactions, setRadiantTransactions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchMethod, setSearchMethod] = useState([]);

  const setSelectedChainByTab = (tabName, selectedChainId) => {
    const updatedSelectedChain = {
      ...selectedChain,
      [tabName]: selectedChainId,
    };
    setSelectedChain(updatedSelectedChain);
  };

  useEffect(() => {
    fetchPrice(
      [
        ..._.uniq(_.map(radiantAccountingTokensV1, "coingeckoId")),
        radiantGovernaceToken[42161].coingeckoId,
      ].join(",")
    );
    const id = setInterval(() => {
      fetchPrice(
        [
          ..._.uniq(_.map(radiantAccountingTokensV1, "coingeckoId")),
          radiantGovernaceToken[42161].coingeckoId,
        ].join(",")
      );
    }, 1000000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    setSelectedTab(0);
    setSearchMethod([]);
    setSelectedChain({
      deposits: 0,
      borrows: 0,
      transaction: 0,
      stake: 0,
      defaultLock: 0,
      vest: 0,
    });
    if (selectedUserAddress) {
      fetchRadiantDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchRadiantV1Details(selectedUserAddress);
      fetchRadiantUserDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchRadiantTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchTransactionType({
        entityName: entityName,
      });
    }
  }, [selectedUserAddress]);

  useEffect(() => {
    if (selectedUserAddress) {
      if (selectedChain.vest == 0) {
        fetchRadiantOnchainDetails({
          entityName: entityName,
          chainId: 42161,
          entityName,
          selectedUserAddress: selectedUserAddress,
        });
        fetchRadiantOnchainDetails({
          entityName: entityName,
          chainId: 56,
          entityName,
          selectedUserAddress: selectedUserAddress,
        });
        fetchRadiantOnchainDetails({
          entityName: entityName,
          chainId: 1,
          entityName,
          selectedUserAddress: selectedUserAddress,
        });
      } else {
        fetchRadiantOnchainDetails({
          entityName: entityName,
          chainId: selectedChain.vest,
          entityName,
          selectedUserAddress: selectedUserAddress,
        });
      }
    }
  }, [selectedUserAddress, selectedChain.vest]);

  useEffect(() => {
    const allProtocolsDataFetched = _.every(userInfo.updatedList, {
      protocolDataFetching: false,
    });

    if (allProtocolsDataFetched && selectedUserAddress) {
      fetchRadiantDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchRadiantV1Details(selectedUserAddress);
      fetchRadiantUserDetails({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
      fetchRadiantTxs({
        entityName: entityName,
        selectedUserAddress: selectedUserAddress,
      });
    }
  }, [userInfo.updatedList, selectedUserAddress]);
  const getNetInitMap = () => {
    return { 0: 0, 1: 0, 56: 0, 42161: 0 };
  };

  useEffect(() => {
    let poolsDetails = [];
    let netWorth = 0;

    if (radiantV1Details) {
      if (radiantV1Details["pools"] && radiantV1Details["pools"].length > 0) {
        _.forEach(radiantV1Details["pools"], function (detail) {
          detail["totalValue"] =
            detail["balances"] *
            _.get(priceData, `[${detail["coingeckoId"]}].usd`, 0);
          detail.debt
            ? (netWorth -= detail["totalValue"])
            : (netWorth += detail["totalValue"]);

          poolsDetails.push(detail);
        });
        const balances =
          _.find(radiantV1Details["pools"], { name: "RDNT" })?.balances || 0;
        setRadiantV1Balances(balances);

        const vestingBalances = radiantV1Details["vestingRdntBalances"] || 0;
        setRadiantV1VestingBalances(vestingBalances);
      }
    }

    const withDebtTrue = poolsDetails.filter((entry) => entry.debt === true);
    const withDebtFalse = poolsDetails.filter((entry) => entry.debt === false);
    let filteredPoolsDetails = [...withDebtTrue, ...withDebtFalse];

    setRadiantV1PoolsDetails(filteredPoolsDetails);
  }, [radiantV1Details]);

  useEffect(() => {
    let poolsDetails = [];
    let netWorth = getNetInitMap();
    let healthFactor = {};

    if (radiantDetails && radiantDetails.length > 0) {
      _.map(supportChains, function (chain) {
        const condition = chain.id == 0 ? {} : { chainId: chain.id };
        healthFactor[chain.id] =
          _.filter(radiantDetails, { debt: false, ...condition }).reduce(
            (sum, collateral) => {
              return (
                sum +
                collateral["balances"] *
                  collateral["price"] *
                  collateral["liquidationThreshold"]
              );
            },
            0
          ) /
          _.filter(radiantDetails, { debt: true, ...condition }).reduce(
            (sum, debt) => {
              return sum + debt["balances"] * debt["price"];
            },
            0
          );
      });

      _.forEach(radiantDetails, function (detail) {
        if (!detail.chainId) return;
        detail.debt
          ? (netWorth[detail.chainId] -= detail["totalValue"])
          : (netWorth[detail.chainId] += detail["totalValue"]);

        poolsDetails.push(detail);
      });
      netWorth[0] = _.sum(_.values(netWorth));
    }

    let filteredDepositsPoolsDetails = poolsDetails.filter(
      (entry) => entry.debt === false
    );
    if (selectedChain.deposits != 0) {
      filteredDepositsPoolsDetails = filteredDepositsPoolsDetails.filter(
        (entry) => entry.chainId == selectedChain.deposits
      );
    }
    filteredDepositsPoolsDetails = _.sortBy(filteredDepositsPoolsDetails, [
      (pool) => -pool.totalValue,
    ]);
    setRadiantDepositsPoolsDetails(filteredDepositsPoolsDetails);

    let filteredBorrowsPoolsDetails = poolsDetails.filter(
      (entry) => entry.debt === true
    );
    if (selectedChain.borrows != 0) {
      filteredBorrowsPoolsDetails = filteredBorrowsPoolsDetails.filter(
        (entry) => entry.chainId == selectedChain.borrows
      );
    }
    filteredBorrowsPoolsDetails = _.sortBy(filteredBorrowsPoolsDetails, [
      (pool) => -pool.totalValue,
    ]);

    setRadiantBorrowsPoolsDetails(filteredBorrowsPoolsDetails);
    setRadiantNetWorth(netWorth);
    setRadiantHealthFactor(healthFactor);
  }, [radiantDetails, selectedChain.deposits, selectedChain.borrows]);

  useEffect(() => {
    let filteredTxs = radiantTxs;
    if (selectedChain.transaction != 0) {
      filteredTxs = filteredTxs.filter(
        (entry) => entry.chainId == selectedChain.transaction
      );
    }
    if (searchMethod && searchMethod.length != 0) {
      filteredTxs = filteredTxs.filter((entry) =>
        _.includes(searchMethod, entry.method)
      );
    }
    setRadiantTransactions(filteredTxs);
  }, [radiantTxs, selectedChain.transaction, searchMethod]);

  useEffect(() => {
    let filterLockInfos = _.reduce(
      radiantUserDetails,
      (result, n) => {
        let data = _.reject(_.get(n, "dlpInfo.lockData", []), { amount: 0 });
        _.map(data, (d) => {
          d.chainId = n.chainId;
          return d;
        });
        if (selectedChain.stake != 0) {
          if (n.chainId == selectedChain.stake) {
            result = _.concat(result, data);
          }
        } else {
          result = _.concat(result, data);
        }
        return result;
      },
      []
    );
    if (selectedChain.stake == 0) {
      filterLockInfos = _.sortBy(filterLockInfos, ["unlockTime"]);
    }

    setStakeDetails(filterLockInfos);
  }, [radiantUserDetails, selectedChain.stake]);

  useEffect(() => {
    let filteredTxs = radiantTxs;
    if (selectedChain.defaultLock != 0) {
      filteredTxs = filteredTxs.filter(
        (entry) => entry.chainId == selectedChain.defaultLock
      );
    }
    let list = _.filter(filteredTxs, (row) => {
      const method = _.get(row.method.split("("), `[0]`, "");
      return method == "setDefaultRelockTypeIndex";
    });

    setReLockList(list);
  }, [radiantTxs, selectedChain.defaultLock]);

  useEffect(() => {
    let result = true;
    if (selectedChain.defaultLock != 0) {
      const data = _.find(radiantUserDetails, {
        chainId: selectedChain.defaultLock,
      });
      if (data) result = _.get(data, "lockSettings.autoRelockDisabled");
    }

    setAutoRelockDisabled(result);
  }, [radiantUserDetails, selectedChain.defaultLock]);

  useEffect(() => {
    let infos = _.reduce(
      radiantUserDetails,
      (result, n) => {
        let data = _.get(n, "earningsInfo", {});
        let vestHistory = _.map(data.history, (d) => {
          d.chainId = n.chainId;
          return d;
        });
        if (selectedChain.vest == 0 || n.chainId == selectedChain.vest) {
          result.history = _.concat(result.history, vestHistory);
        }
        return result;
      },
      { currentlyVesting: 0, readyToVest: 0, history: [] }
    );
    if (selectedChain.vest == 0) {
      infos.history = _.sortBy(infos.history, ["unlockTime"]);
    }
    if (selectedChain.vest == 0) {
      infos.currentlyVesting = _.sumBy(
        _.values(radiantOnchainDetails),
        (detail) => parseFloat(detail.currentlyVesting)
      );

      infos.readyToVest = _.sumBy(_.values(radiantOnchainDetails), (detail) =>
        parseFloat(detail.readyToVest)
      );
    } else {
      infos.currentlyVesting = _.get(
        radiantOnchainDetails,
        `[${selectedChain.vest}].currentlyVesting`
      );
      infos.readyToVest = _.get(
        radiantOnchainDetails,
        `[${selectedChain.vest}].readyToVest`
      );
    }
    setEarningsInfo(infos);
  }, [
    radiantUserDetails,
    radiantOnchainDetails,
    selectedChain.stake,
    selectedChain.vest,
  ]);

  const renderLendingTable = (data, chars) => {
    return (
      <Rows
        headers={["Chain", "Pool", "Balances", "USD Value"]}
        widths={[1, 3, 4, 4]}
        contents={_.map(data, (row, i) => {
          const token = _.find(tokenMapping[row.chainId], {
            address: _.toLower(row.underlyingToken),
          });
          return [
            <div>
              <img
                src={
                  _.find(supportChains, { id: _.get(row, "chainId", 42161) })
                    ?.logo_url
                }
                className={classes.smIcon}
              />
            </div>,
            <div className="startRow">
              <img
                src={token?.img_url || images[defaultImage]}
                className="smIcon"
              />
              {_.trimStart(_.get(row, "name", ""), chars)}
            </div>,
            <div>
              <span>
                {_.get(row, "balances")
                  ? formatValue(_.get(row, "balances", 0))
                  : "N/A"}
              </span>
              <span> {_.truncate(_.get(row, "name", ""))}</span>
            </div>,
            <div>
              $
              {_.get(row, "totalValue")
                ? row.debt
                  ? "-" + formatValue(_.get(row, "totalValue", 0))
                  : formatValue(_.get(row, "totalValue", 0))
                : "N/A"}
            </div>,
          ];
        })}
        loading={fetchRadiantDetailsPending || fetchRadiantUserDetailsPending}
        fetching={isFetching}
      />
    );
  };

  const renderSelectChain = (tabName) => {
    return (
      <SelectChain
        setSelectedChainByTab={setSelectedChainByTab}
        selectedChain={selectedChain}
        chains={supportChains}
        tabName={tabName}
      />
    );
  };

  const renderSelectMethod = () => {
    if (transactionTypeList && transactionTypeList.length != 0) {
      return (
        <div className={`${classes.selectMethod} startRow`}>
          <div style={{ marginRight: 5 }}>Method:</div>
          <CustomDropdown
            border
            sm
            data={[{ values: [], label: "All" }, ...transactionTypeList]}
            fullWidth
            placeholder={"All"}
            value={searchMethod}
            onSelect={(e) => setSearchMethod(e.target.value)}
            style={{
              width: "15vw",
            }}
            multipleValues={true}
          />
        </div>
      );
    }
  };

  const renderTxs = (data, loading) => {
    const renderTxContentWithData = () => (
      <Transaction
        data={data}
        chains={supportChains}
        renderTxContentBasedOnABI={renderTxContentBasedOnABI}
      />
    );
    return (
      <CustomDataFetching
        loading={loading}
        hasData={data && data.length > 0}
        contents={renderTxContentWithData}
      ></CustomDataFetching>
    );
  };

  const renderToken = (tokenData, value) => {
    if (!tokenData) return;

    let formatValue = new BigNumber(
      convertAmountFromRawNumber(
        typeof value === "object"
          ? convertHexToString(_.get(value, "_hex", 0))
          : value,
        _.get(tokenData, "decimals", 18)
      )
    ).toFormat(3);
    if (
      typeof value === "object" &&
      _.get(value, "_hex") ==
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    ) {
      formatValue = "All";
    } else if (
      value >=
      115000000000000000000000000000000000000000000000000000000000000000000000000000
    ) {
      formatValue = "All";
    }
    return (
      <div className="startRow">
        <img
          style={{ width: 20, height: 20, marginRight: 5 }}
          src={tokenData.img_url || require(`assets/img/empty-token.png`)}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = require(`assets/img/empty-token.png`);
          }}
        />

        <div>
          <div style={{ color: "#555555", fontSize: 11 }}>
            {_.get(tokenData, "name")}
          </div>
          {value && (
            <div style={{ color: "#555555", fontSize: 12, fontWeight: 600 }}>
              {formatValue}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAddLiquidityTxContent = (row) => {
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[1].value");
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        <div>{renderToken(token, amount)}</div>

        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken({ ...token, name: "r" + token.name }, amount)}
      </div>
    );
  };
  const renderExchangeTxContent = (row) => {
    const amount = _.get(row, "arguments[0].value");
    const token = radiantGovernaceToken[row.chainId];
    return (
      <div className="row">
        <div>{renderToken({ ...token, name: token.name + "v1" }, amount)}</div>

        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(token, amount)}
      </div>
    );
  };

  const renderRepayTxContent = (row) => {
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[1].value");
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        <div>{renderToken({ ...token, name: "r" + token.name }, amount)}</div>

        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(token, amount)}
      </div>
    );
  };

  const renderLoopTxContent = (row) => {
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[1].value");
    const count = convertHexToString(_.get(row, "arguments[4].value._hex", 0));
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        <div>{renderToken(token, amount)}</div>{" "}
        <div style={{ margin: 5 }}>{` x  `}</div>
        <div>{count}</div>
      </div>
    );
  };

  const renderBorrowTxContent = (row) => {
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[1].value");
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return <div className="row">{renderToken(token, amount)}</div>;
  };

  const renderZapTxContent = (row) => {
    const argLength = row.arguments.length;
    let wethAmount = 0;
    let rdntAmount = 0;
    let token = _.find(tokenMapping[row.chainId], { name: "WETH" });
    if (argLength === 4) {
      wethAmount = _.get(row, "arguments[1].value", 0);
      rdntAmount = _.get(row, "arguments[2].value", 0);
    }
    if (argLength > 4) {
      wethAmount = _.get(row, "arguments[2].value", 0);
      rdntAmount = _.get(row, "arguments[3].value", 0);
      token = _.find(tokenMapping[row.chainId], {
        address: _.toLower(_.get(row, "arguments[1].value", "")),
      });
    }

    const value = _.get(row, "value", 0);

    return (
      <div className="row">
        {wethAmount > 0 && (
          <div style={{ margin: "0 25px" }}>
            {renderToken(token, wethAmount)}{" "}
          </div>
        )}
        {value > 0 && (
          <div style={{ margin: "0 25px" }}>{renderToken(token, value)} </div>
        )}

        {rdntAmount > 0 && (
          <div style={{ margin: "0 25px" }}>
            {renderToken(radiantGovernaceToken[row.chainId], rdntAmount)}
          </div>
        )}
      </div>
    );
  };

  const renderSetDefaultRelockTypeIndexTxContent = (row) => {
    const relockTypeIndex = parseInt(
      _.get(row, "arguments[0].value._hex", 0),
      16
    );

    let duration = relockTypeIndexMapping[relockTypeIndex];
    return <div className="row">{duration / 2592000 + " Months"}</div>;
  };
  const renderSetAutoCompoundTxContent = (row) => {
    const autoCompound = _.get(row, "arguments[0].value") ? "true" : "false";

    return <div className="row">{autoCompound}</div>;
  };
  const renderWithdrawTxContent = (row) => {
    const argsLength = row.arguments.length;
    if (argsLength == 1) {
      const amount = _.get(row, "arguments[0].value");
      const to = selectedUserAddress;
      const token = {
        name: "RDNT",
        img_url: require("assets/img/RDNT.svg"),
        decimals: 18,
      };
      return (
        <div className="row">
          {renderToken(token, amount)}
          <img
            className={classes.swap}
            src={require("assets/img/swap.svg").default}
          />
          {to && `${to.slice(0, 6)}....${to.slice(-4)}`}
        </div>
      );
    }
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[1].value");
    const to = _.get(row, "arguments[2].value");
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        {renderToken(token, amount)}
        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {to && `${to.slice(0, 6)}....${to.slice(-4)}`}
      </div>
    );
  };
  const renderGetRewardTxContent = (row) => {
    const tokens = _.get(row, "arguments[0].value", []);
    return (
      <div className="row">
        <div>
          {_.map(tokens, (token, i) => {
            return (
              <div key={i} style={{ marginBottom: 2 }}>
                {renderToken(
                  _.find(radiantAccountingTokens[row.chainId], {
                    address: _.toLower(token),
                  })
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderStakeTxContent = (row) => {
    const amount = _.get(row, "arguments[0].value");

    return (
      <div className="row">
        {renderToken(radiantLPToken[row.chainId], amount)}
      </div>
    );
  };
  const renderLiquidationCallTxContent = (row) => {
    const asset = _.get(row, "arguments[0].value");
    const amount = _.get(row, "arguments[4].value");
    const liquidator = _.get(row, "arguments[5].value");
    const token = _.find(tokenMapping[row.chainId], {
      address: _.toLower(asset),
    }) || {
      name: asset,
      address: asset,
      decimals: 18,
    };
    return (
      <div className="row">
        {liquidator && `${liquidator.slice(0, 6)}....${liquidator.slice(-4)}`}
        <img
          className={classes.swap}
          src={require("assets/img/swap.svg").default}
        />
        {renderToken(token, amount)}
      </div>
    );
  };
  const renderTxContentBasedOnABI = (row, method) => {
    switch (method) {
      case "loop":
        return renderLoopTxContent(row);
      case "getReward":
        return renderGetRewardTxContent(row);
      case "stake":
        return renderStakeTxContent(row);
      case "exchange":
        return renderExchangeTxContent(row);
      case "borrow":
        return renderBorrowTxContent(row);
      case "withdraw":
        return renderWithdrawTxContent(row);
      case "zap":
        return renderZapTxContent(row);
      case "zapFromVesting":
        return renderZapTxContent(row);
      case "deposit":
        return renderAddLiquidityTxContent(row);
      case "setDefaultRelockTypeIndex":
        return renderSetDefaultRelockTypeIndexTxContent(row);
      case "setAutocompound":
      case "setRelock":
        return renderSetAutoCompoundTxContent(row);
      case "repay":
        return renderRepayTxContent(row);
      case "liquidationCall":
        return renderLiquidationCallTxContent(row);

      default:
        return <div />;
    }
  };
  const renderMultiChainValue = (
    values,
    subValues,
    symbol = "$",
    defaultValue = "-",
    row = true
  ) => {
    return (
      <div className={row ? "startRow" : ""} style={{ marginTop: row ? 7 : 0 }}>
        {_.map(supportChains, (o, i) => {
          if (o.id == 0 || !subValues[o.id]) return;
          return (
            <div key={i} style={{ marginRight: 12 }}>
              <div
                className="startRow"
                style={{ marginBottom: subValues ? 0 : 5 }}
              >
                <img
                  src={_.find(supportChains, { id: o.id })?.logo_url}
                  className={classes.smIcon}
                />
                {symbol}
                {formatValue(values[o.id], defaultValue)}
              </div>

              {subValues && (
                <div
                  className={classes.desc}
                  style={{ marginBottom: 5 }}
                >{`($${formatValue(subValues[o.id], defaultValue)})`}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  let tabs = [
    {
      label: "Overview",
      content: <UserDetail userInfo={userInfo} />,
    },
    {
      label: "Lending",
      content: (
        <div>
          <div className="userInfoContainer" style={{ paddingBottom: 10 }}>
            <Grid container alignItems="flex-start">
              <Grid xs={7} item>
                <div>
                  <span className={`${classes.subTitle}`}>
                    {formatMessage({
                      id: "userInfoContent.radiantUserInfo.cross-chainNetValue",
                    })}
                  </span>
                  <span className={classes.value} style={{ marginLeft: 5 }}>
                    ${formatValue(radiantNetWorth[0])}
                  </span>
                </div>
              </Grid>
              <Grid xs={5} item>
                {renderMultiChainValue(radiantNetWorth, "", "", "0.00", false)}
              </Grid>
              <Grid
                xs={12}
                item
                style={{
                  borderBottom: "1px solid #D6D6D6",
                  paddingBottom: 13,
                  marginBottom: 13,
                }}
              ></Grid>
              <Grid xs={7} item>
                <div>
                  <span className={`${classes.subTitle}`}>
                    {formatMessage({
                      id: "userInfoContent.radiantUserInfo.healthFactor",
                    })}{" "}
                  </span>
                  <span className={classes.value} style={{ marginLeft: 5 }}>
                    {formatValue(radiantHealthFactor[0])}
                  </span>
                </div>
              </Grid>
              <Grid xs={5} item>
                {renderMultiChainValue(radiantHealthFactor, "", "", "-", false)}
              </Grid>
              <Grid
                xs={12}
                item
                style={{
                  borderBottom: "1px solid #D6D6D6",
                  paddingBottom: 13,
                  marginBottom: 13,
                }}
              ></Grid>
            </Grid>
          </div>
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.action.deposits" })}
          </div>
          {renderSelectChain("deposits")}
          {renderLendingTable(radiantDepositsPoolsDetails, "r")}

          <div className={"infoHeader"}>
            {formatMessage({ id: "global.action.borrows" })}{" "}
          </div>
          {renderSelectChain("borrows")}
          {renderLendingTable(radiantBorrowsPoolsDetails, "vd")}
        </div>
      ),
    },
    {
      label: "Transaction",
      content: (
        <div>
          {renderSelectChain("transaction")}
          {renderSelectMethod()}
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.demographic.transaction" })}
          </div>
          {renderTxs(radiantTransactions, fetchRadiantTxsPending)}
        </div>
      ),
    },
    {
      label: "Stake",
      content: (
        <div>
          {renderSelectChain("stake")}
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.action.staking" })}(dLP Balance Sheet)
          </div>

          <Rows
            style={{ height: 49 }}
            headers={[
              "Chain",
              "Date Staked",
              "dLP Amount",
              "Multiple",
              "Unlockable In",
            ]}
            widths={[1, 3, 3, 2, 3]}
            contents={_.map(stakeDetails, (row) => {
              return [
                <div>
                  <img
                    src={
                      _.find(supportChains, {
                        id: _.get(row, "chainId", 42161),
                      })?.logo_url
                    }
                    style={{ marginLeft: 5, width: 20, height: 20 }}
                  />
                </div>,
                <div style={{ color: "#7B61FF", fontWeight: 400 }}>
                  {" "}
                  {moment(row.unlockTime * 1000 - row.duration * 1000).format(
                    "MMM DD,YYYY hh:mm"
                  )}
                </div>,
                <div className="startRow">
                  <img
                    src={require("assets/img/dLP.svg").default}
                    className={classes.icon}
                  />
                  {formatValue(row.amount)}
                </div>,
                row.multiplier + "x",
                convertToCountdown(row.unlockTime * 1000),
              ];
            })}
            loading={
              fetchRadiantDetailsPending || fetchRadiantUserDetailsPending
            }
          />
        </div>
      ),
    },
    {
      label: "Default Lock Length",
      content: (
        <div>
          {renderSelectChain("defaultLock")}
          <div className={"infoHeader"}>
            {formatMessage({
              id: "userInfoContent.radiantUserInfo.defaultLockLength",
            })}
          </div>
          {selectedChain.defaultLock !== 0 && (
            <div
              className="startRow"
              style={{ margin: "15px 0", fontSize: 14, fontWeight: 600 }}
            >
              {formatMessage({
                id: "userInfoContent.radiantUserInfo.autoRelock",
              })}
              :{" "}
              <span
                style={{
                  color: "#A5A5A5",
                  fontWeight: 600,
                  fontSize: 14,
                  textDecorationLine: "underline",
                  marginLeft: 10,
                  marginRight: 15,
                }}
              >
                {autoRelockDisabled ? "Disabled" : "Enabled"}
              </span>
            </div>
          )}

          <Rows
            headers={[
              "Chain",
              "Date Adjusted",
              "Previous Duration",
              "",
              "Latest Duration",
            ]}
            widths={[1, 4, 3, 1, 3]}
            contents={_.map(relockList, (row, i) => {
              const relockTypeIndex = convertHexToString(
                _.get(row, "arguments[0].value._hex", 0)
              );
              let duration = relockTypeIndexMapping[relockTypeIndex];
              let previous = "3 Months";
              let previousIndex = i;
              while (previousIndex + 1 < relockList.length) {
                let previousData = relockList[i + 1];
                if (previousData.chainId !== row.chainId) {
                  previousIndex++;
                  continue;
                }
                const previousRelockTypeIndex = convertHexToString(
                  _.get(previousData, `arguments[0].value._hex`, 0)
                );
                previous =
                  relockTypeIndexMapping[previousRelockTypeIndex] / 2592000 +
                  " Months";
                break;
              }
              return [
                <div>
                  <img
                    src={
                      _.find(supportChains, {
                        id: _.get(row, "chainId", 42161),
                      })?.logo_url
                    }
                    style={{ marginLeft: 5, width: 20, height: 20 }}
                  />
                </div>,
                <div style={{ color: "#7B61FF", fontWeight: 400 }}>
                  {moment(row.timestamp * 1000).format("MMM DD, YYYY hh:mm")}
                </div>,

                previous,
                <img
                  style={{ width: 23, height: 23, margin: "10 0" }}
                  src={require("assets/img/swap.svg").default}
                />,
                duration / 2592000 + " Months",
              ];
            })}
            loading={fetchRadiantTxsPending || fetchRadiantUserDetailsPending}
          />
        </div>
      ),
    },
    {
      label: "Vest",
      content: (
        <div>
          {renderSelectChain("vest")}
          <div className={"infoHeader"}>
            {formatMessage({
              id: "userInfoContent.radiantUserInfo.vestingDetails",
            })}
          </div>
          <div
            className="startRow userInfoContainer"
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <div>
              <div className={classes.subTitle}>
                {formatMessage({
                  id: "userInfoContent.radiantUserInfo.readyToVest",
                })}
                :
              </div>
              <div className={classes.value}>
                ${formatValue(earningsInfo.readyToVest)}
              </div>
            </div>
            <div style={{ marginLeft: 50 }}>
              <div className={classes.subTitle}>
                {formatMessage({
                  id: "userInfoContent.radiantUserInfo.currentlyVesting",
                })}
                :
              </div>
              <div className={classes.value}>
                ${formatValue(earningsInfo.currentlyVesting)}
              </div>
            </div>
          </div>
          <div className={"infoHeader"}>
            {formatMessage({ id: "userInfoContent.radiantUserInfo.history" })}
          </div>
          <Rows
            headers={[
              "Chain",
              "Date Vested",
              "Amount",
              "Penalty",
              "Unlockable In",
            ]}
            widths={[1, 3, 3, 3, 2]}
            contents={_.map(earningsInfo.history, (row, i) => {
              return [
                <div>
                  <img
                    src={
                      _.find(supportChains, {
                        id: _.get(row, "chainId", 42161),
                      })?.logo_url
                    }
                    style={{ marginLeft: 5, width: 20, height: 20 }}
                  />
                </div>,
                <div style={{ color: "#7B61FF", fontWeight: 400 }}>
                  {moment(row.unlockTime * 1000).format("MMM DD, YYYY hh:mm")}
                </div>,
                <div className="startRow">
                  <img
                    src={require("assets/img/RDNT.svg").default}
                    className={classes.icon}
                  />
                  {formatValue(row.amount)}
                </div>,
                <span
                  style={{ color: "#FF3296", fontSize: 12, fontWeight: 600 }}
                >
                  {formatValue(row.penalty)}{" "}
                </span>,
                convertToCountdown(row.unlockTime * 1000),
              ];
            })}
            loading={fetchRadiantTxsPending || fetchRadiantUserDetailsPending}
          />
        </div>
      ),
    },
    {
      label: "RDNT V1",
      content: (
        <div>
          <div className="userInfoContainer">
            <div className="startRow">
              <div>
                <div className={`${classes.subTitle}`}>Balance of RDNT v1</div>
                <div className={classes.value}>
                  <div>{formatValue(radiantV1Balances)}</div>
                </div>
              </div>
              <div className="borderLeft" />
              <div>
                <div className={`${classes.subTitle}`}>Vesting RDNT v1</div>
                <div className={classes.value}>
                  <div>{formatValue(radiantV1VestingBalances)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={"infoHeader"}>
            {formatMessage({ id: "global.action.deposits" })}
          </div>
          {renderLendingTable(
            _.filter(radiantV1PoolsDetails, {
              debt: false,
            }),
            "r"
          )}

          <div className={"infoHeader"}>
            {formatMessage({ id: "global.action.borrows" })}{" "}
          </div>
          {renderLendingTable(
            _.filter(radiantV1PoolsDetails, {
              debt: true,
            }),
            "vd"
          )}
        </div>
      ),
    },
  ];

  if (ticketListData) {
    tabs = [
      {
        label: `Tickets (${_.get(ticketListData, "tickets.length", "-")})`,
        content: (
          <div style={{ padding: "0 12px" }}>
            {ticketListData.tickets && (
              <TicketTable
                containerStyle={{ height: "54vh" }}
                userId={selectedUserId}
                ticketList={ticketListData.tickets}
                entityName={entityName}
              />
            )}
          </div>
        ),
      },
      ...tabs,
    ];
  }

  const renderContent = () => {
    return (
      <div>
        <CustomTabs
          centered={!ticketListData}
          sm="true"
          variant={ticketListData ? "" : "scrollable"}
          scrollButtons="on"
          type={"metaform"}
          tabIndex={selectedTab}
          handleChange={(e, newValue) => setSelectedTab(newValue)}
          tabs={tabs}
        />
      </div>
    );
  };

  const rdntMap = _.reduce(
    radiantUserDetails,
    (result, item) => {
      result["rdntBalances"][item.chainId] = _.get(item, "rdntBalances", 0);
      result["rdntValue"][item.chainId] =
        _.get(priceData, "radiant-capital.usd", 0) *
        _.get(item, "rdntBalances", 0);
      result["dlpBalances"][item.chainId] = _.get(item, "dlpInfo.locked", 0);
      result["dlpValue"][item.chainId] =
        _.get(item, "dlpInfo.locked", 0) * _.get(item, "dlpInfo.price", 0);
      return result;
    },
    { rdntBalances: {}, rdntValue: {}, dlpBalances: {}, dlpValue: {} }
  );
  return (
    <div>
      <UserTitle
        selectedUserId={selectedUserId}
        paddingLeft={ticketListData}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        fetchUserObjs={fetchUserObjs}
        extra={
          <div>
            <div className="startRow">
              <img
                src={require("assets/img/RDNT.svg").default}
                className={"smIcon"}
              />
              <span className={classes.title}>
                RDNT{" "}
                {formatMessage({
                  id: "userInfoContent.userDetail.balance",
                })}
              </span>
              <span className={classes.value}>
                {formatValue(_.sumBy(radiantUserDetails, "rdntBalances"))}
              </span>
              <span className={classes.desc}>
                ($
                {formatValue(
                  _.get(priceData, "radiant-capital.usd", 0) *
                    _.sumBy(radiantUserDetails, "rdntBalances")
                )}
                )
              </span>
            </div>
            <div
              className="startRow"
              style={{
                marginLeft: 20,
              }}
            >
              {renderMultiChainValue(
                rdntMap.rdntBalances,
                rdntMap.rdntValue,
                "",
                "0.00"
              )}
            </div>
            <div className="startRow">
              <img
                src={require("assets/img/dLP.svg").default}
                className={"smIcon"}
              />
              <span className={classes.title}>
                dLP{" "}
                {formatMessage({
                  id: "userInfoContent.userDetail.balance",
                })}
              </span>
              <span className={classes.value}>
                {formatValue(_.sumBy(radiantUserDetails, "dlpInfo.locked"))}
              </span>
              <span className={classes.desc}>
                ($
                {formatValue(
                  _.sumBy(
                    radiantUserDetails,
                    (details) => details.dlpInfo.locked * details.dlpInfo.price
                  )
                )}
                )
              </span>
            </div>
            <div
              style={{
                marginLeft: 20,
              }}
            >
              {renderMultiChainValue(
                rdntMap.dlpBalances,
                rdntMap.dlpValue,
                "",
                "0.00"
              )}
            </div>
          </div>
        }
      />

      <div style={{ position: "relative", zIndex: 1 }}>
        <Loading
          open={
            fetchRadiantDetailsPending ||
            fetchRadiantTxsPending ||
            fetchRadiantV1DetailsPending ||
            fetchRadiantUserDetailsPending ||
            isFetching
          }
          text={isFetching ? "Data Fetching..." : "Loading..."}
          fullScreen={false}
          topPos="50vh"
        />
        {renderContent()}
      </div>
    </div>
  );
};

export default RadiantUserInfo;

import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { StyledModalTableRow } from "features/metadesk/campaign/campaignList/component/TrackingModal.styles";
import moment from "moment";
import _ from "lodash";
import { getShortAddress, getTransactionUrl } from "features/helpers/utils";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useTheme } from "@mui/styles";
import { useOpenState } from "contexts/stepContext/Open.context";
import { StyledSelectCampaignCardCover } from "../../createCampaign/component/SelectCampaignModal.styles";
const mockData = [
  {
    walletAddress: "0x123456890",
    txs: [
      { txHash: "1234123123123213", chainId: 1, timestamp: new Date() },
      { txHash: "2234123123123214", chainId: 1, timestamp: new Date() },
      { txHash: "3234123123123215", chainId: 1, timestamp: new Date() },
    ],
  },
  {
    walletAddress: "0x123456890",
    txs: [{ txHash: "1234123123123213", chainId: 1, timestamp: new Date() }],
  },
];

const ReferrerCountModal = ({ open, onClose, data }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const ecosystemName = useSelector(selectEcosystemName);
  const { setOpen } = useOpenState();
  const [expandedState, setExpandedState] = useState({});

  const handleChange = (walletAddress) => (event, isExpanded) => {
    setExpandedState((prev) => ({
      ...prev,
      [walletAddress]: isExpanded,
    }));
  };

  const renderRow = (item, index) => {
    const { txs, timestamp, value, walletAddress } = item;
    const hasMultipleTxs = txs.length > 1;
    return (
      <Accordion
        key={walletAddress}
        expanded={
          hasMultipleTxs ? expandedState[walletAddress] || false : false
        }
        onChange={hasMultipleTxs ? handleChange(walletAddress) : null}
        sx={{
          boxShadow: "none",
          "&:before": { display: "none" },
          borderBottom: "1px solid #F8F8F8",
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            hasMultipleTxs ? (
              <ExpandMoreIcon />
            ) : (
              <Box sx={{ width: "20px" }}></Box>
            )
          }
          sx={{
            padding: 0,
            fontSize: 12,
            color: "#777",
            minHeight: "31px",
            "&.Mui-expanded": {
              minHeight: "24px",
            },
            "& .MuiAccordionSummary-content": {
              margin: "0",
              alignItems: "center",
              "&.Mui-expanded": {
                margin: "8px 0",
              },
            },
          }}
        >
          <Box sx={{ width: "30%" }}>{getShortAddress(walletAddress)}</Box>
          <Box sx={{ width: "70%" }}>
            {renderTransaction(txs[0], ecosystemName)}
          </Box>
        </AccordionSummary>
        {hasMultipleTxs && (
          <AccordionDetails sx={{ padding: "0", pb: "8px" }}>
            {_.map(txs.slice(1), (tx, i) => (
              <Box
                display="flex"
                alignItems="center"
                sx={{ pr: "21px" }}
                mt={i > 0 ? "8px" : "0px"}
              >
                <Box sx={{ width: "30%" }}></Box>
                <Box sx={{ width: "70%" }}>
                  {renderTransaction(tx, ecosystemName, i + 1)}
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  const renderTransaction = (tx, ecosystemName, index = 0) => {
    if (!tx) return null;
    return (
      <Box display="flex" alignItems="center" key={index} sx={{ fontSize: 12 }}>
        <Box sx={{ width: "50%" }}>
          <a
            href={getTransactionUrl(ecosystemName, tx.chainId, tx.txHash)}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#7B61FF",
              textDecoration: "underline",
            }}
          >
            {_.truncate(tx.txHash, { length: 11 })}
          </a>
        </Box>
        <Box sx={{ width: "50%", color: "#777" }}>
          {moment(tx.timestamp).format("MMM DD,YYYY HH:mmA")}
        </Box>
      </Box>
    );
  };

  const renderTable = (rowData) => {
    return (
      <Box sx={{ width: "100%", overflowY: "auto" }}>
        <StyledModalTableRow style={{ paddingRight: "21px" }}>
          <div style={{ width: "30%", textAlign: "left", fontWeight: 500 }}>
            Address
          </div>

          <div style={{ width: "35%", textAlign: "left", fontWeight: 500 }}>
            Transaction Hashes
          </div>
          <div style={{ width: "35%", textAlign: "left", fontWeight: 500 }}>
            Timestamp
          </div>
        </StyledModalTableRow>
        <Box sx={{ overflowY: "auto" }}>
          {_.map(rowData, (item, index) => renderRow(item, index))}
        </Box>
      </Box>
    );
  };

  const renderAchievedContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          height: "275px",
          padding: "12px 17px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="betweenRow">
          <Box>
            {formatMessage({
              id: "websiteTraffic.popup.title.achievedWallet",
            })}
            <Box color={"#7B61FF"} fontWeight={700} mt="8px">
              {data?.achievedWallets?.length}
            </Box>
          </Box>
          <Box>
            <Button
              variant={VARIANT.OUTLINED}
              style={{ marginRight: "8px" }}
              id="btn_exportAchievedWalletToCustomerList"
              onClick={() => {
                setOpen({ upgrade: true });
              }}
            >
              {formatMessage({
                id: "global.btn.exportCustomerList",
              })}
            </Button>
            <Button
              variant={VARIANT.OUTLINED}
              id="btn_csvDownloadAchievedWallet"
              onClick={() => {
                setOpen({ upgrade: true });
              }}
            >
              {formatMessage({
                id: "global.btn.downloadAsCsv",
              })}
            </Button>
          </Box>
        </Box>
        <Box className="smhr"></Box>
        {renderTable(data?.achievedWallets)}
      </Box>
    );
  };

  const renderUnAchievedDialogContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          height: "275px",
          padding: "12px 17px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          marginTop: "24px",
        }}
      >
        <Box className="betweenRow">
          <Box>
            {formatMessage({
              id: "websiteTraffic.popup.title.unAchievedWallet",
            })}
            <Box fontWeight={700} mt="8px">
              {data?.totalWallet - (data?.achievedWallets?.length || 0)}
            </Box>
          </Box>
          <Box>
            <Button
              id="btn_csvDownloadUnachievedWallet"
              variant={VARIANT.OUTLINED}
              onClick={() => {
                setOpen({ upgrade: true });
              }}
            >
              {formatMessage({
                id: "global.btn.downloadAsCsv",
              })}
            </Button>
          </Box>
        </Box>
        <Box className="smhr"></Box>
        <Box position="relative">
          <StyledSelectCampaignCardCover
            style={{
              background: "rgba(255,255,255,0.5)",
              zIndex: 1000,
              borderRadius: 0,
            }}
          />
          {renderTable(mockData)}
        </Box>
      </Box>
    );
  };

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      dialogRootStyle={{ maxWidth: "700px", overflow: "hidden", width: "100%" }}
    >
      <Box
        padding={"24px 40px 48px"}
        flexDirection={"column"}
        sx={{ overflow: "hidden", flex: 1, display: "flex" }}
      >
        <Box fontSize="18px" fontWeight={700} mb={"18px"}>
          {formatMessage({
            id: "websiteTraffic.popup.objectiveAchieved.header",
          })}
          <span style={{ color: theme.customColors.purple[500] }}>
            {data?.achievedWallets?.length}
          </span>
        </Box>
        {renderAchievedContent()}
        {renderUnAchievedDialogContent()}
      </Box>
    </BasicDialog>
  );
};

export default ReferrerCountModal;

import React from "react";
import { StyledSettingWidgetStepDescribe } from "../IntegrationWidgetSetting.styles";
import { useIntl } from "react-intl";

const ReferralRules = ({}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <StyledSettingWidgetStepDescribe>
        {formatMessage({
          id: "marketingSettings.introductionTab.referralProgramStep2.description",
        })}
      </StyledSettingWidgetStepDescribe>
      <ul style={{ marginTop: "-4px" }}>
        <li className="dotList inputTitle">
          Website URL: Your destination links{" "}
        </li>
        <li className="dotList inputTitle">Source: metacrmReferral </li>
        <li className="dotList inputTitle">
          Content: {`{referer wallet address}`}{" "}
        </li>
      </ul>
      <StyledSettingWidgetStepDescribe
        style={{ marginBottom: "0px", marginTop: "-8px" }}
      >
        Example:{" "}
        {`https://demo.example.com/?utm_source=metacrmReferral&utm_content=address`}{" "}
      </StyledSettingWidgetStepDescribe>
    </>
  );
};

export default ReferralRules;

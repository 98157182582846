import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { alpha } from "@mui/material/styles";

export const StyledWhiteContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  background: theme.customColors.white,
  marginTop: "20px",
  boxShadow: "0px 1.2824229001998901px 6.412113666534424px 0px #0000001A",
}));

export const StyledContainerTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#555",
  fontSize: "14px",
  fontWeight: 700,
  padding: "20px",
}));

export const StyledColumnTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#555",
  fontSize: "14px",
  fontWeight: 500,
  marginRight: "4px",
  paddingTop: "1px",
}));

export const StyledContentContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "20px",
  borderTop: "1px solid #E6E6E6",
}));

export const StyledSubTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#777",
  fontSize: "12px",
  fontWeight: 500,
  paddingBottom: "10px",
}));

export const StyledReferralValue = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#000000",
  fontSize: "16px",
  fontWeight: 500,
}));

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { get, isEmpty, has } from "lodash-es";

import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useTheme } from "@mui/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";

import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";

import axios from "axios";
import { apiUrl } from "features/configure";

import {
  StyledWidgetButtonContainer,
  StyledAddNewWidgetButton,
} from "./CustomServiceWidget.styles";
import { StyledSettingTitle } from "../SettingCommon.styles";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

import WidgetButton from "./WidgetButton";
import { selectWidgetModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
import { StyledWhiteContainer } from "../../common.styles";

const CustomServiceWidgetNew = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const widgetModule = useSelector(selectWidgetModule);

  const multiDomain = widgetModule?.multiDomain;
  const { formatMessage } = useIntl();

  const { fetchWidgetConfig, widgetConfig, fetchWidgetConfigPending } =
    useFetchWidgetConfig();

  const [entityName, isCustomDomain] = useOutletContext();

  useEffect(() => {
    fetchWidgetConfig({ entityName });
  }, []);

  const handleToAdd = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/general/integration/update/widget?from=customization"
      )
    );
  };

  const pagePermission = useGetPagePermission();
  const integrationPermission = pagePermission("integration");
  return (
    <>
      {fetchWidgetConfigPending ? (
        <Box height={"40vh"} width={"100%"}>
          <LoadingComponent />
        </Box>
      ) : (
        <Box>
          <Box mb="20px">
            <Box className="title">
              {formatMessage({ id: "global.serviceWidget" })}
            </Box>
            <Box className="description">
              Integrate MetaCRM widget to your website for a better customer
              support.
            </Box>
          </Box>
          <StyledWhiteContainer style={{ minHeight: "calc(100vh - 170px)" }}>
            {widgetConfig?.length > 0 ? (
              <Grid container spacing={2.5}>
                {widgetConfig?.map((widgetItem, index) => (
                  <Grid item xs={6} md={4} lg={3}>
                    <WidgetButton widgetItemInfo={widgetItem} />
                  </Grid>
                ))}
                {multiDomain && (
                  <Grid item xs={6} md={4} lg={3}>
                    <StyledAddNewWidgetButton onClick={handleToAdd}>
                      +{" "}
                      {formatMessage({ id: "widgetSettings.tab.addNewWidget" })}
                    </StyledAddNewWidgetButton>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "5%",
                }}
              >
                <Box
                  component="img"
                  alt="logo"
                  src={require("assets/img/img_emptyWidget.svg").default}
                  className="lgIcon"
                  width={"432px"}
                  height={"240px"}
                  marginBottom={"24px"}
                />
                <Box
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                    color: theme.customColors.grey[800],
                    width: "460px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {formatMessage({
                    id: "widgetCustomization.emptyState.tltle",
                  })}
                </Box>
                <Box
                  sx={{
                    width: "440px",
                    textAlign: "center",
                    color: theme.customColors.grey[600],
                    fontWeight: 400,
                    marginBottom: "24px",
                    lineHeight: "1.3",
                  }}
                >
                  {formatMessage({
                    id: "widgetCustomization.emptyState.description",
                  })}
                </Box>
                {integrationPermission.canEdit && (
                  <Box>
                    <Button onClick={handleToAdd}>
                      {formatMessage({ id: "global.btn.getStarted" })}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </StyledWhiteContainer>
        </Box>
      )}
    </>
  );
};

export default CustomServiceWidgetNew;

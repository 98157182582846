import React, { useEffect, useState } from "react";
import _, { set } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import { formatDateAndTime } from "features/helpers/utils";
import { useNavigate } from "react-router-dom";
import { useFetchTeamMember, useFetchTicketList } from "../redux/hooks";
import Channel from "./Channel";
import TicketStatus from "./TicketStatus";
import { getUserAvatarIcon } from "../utils";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { getShortAddress } from "features/helpers/utils";
import { useConnectWallet } from "features/home/redux/connectWallet";
import Comment from "./Comment";
import TicketTypeLabel from "./TicketTypeLabel";

const useStyles = makeStyles((theme) => ({
  timestampLg: {
    fontSize: 12,
    color: "#555555",
  },
}));

const TicketTable = ({
  ticketList,
  entityName,
  containerStyle = {},
  noDataComponent,
  orderData = { order: -1, sort: "created" },
  setOrderData = () => {},
}) => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const { socket, userData } = useConnectWallet();
  const { memberList } = useFetchTeamMember();
  const [commentMapping, setCommentMapping] = useState({});
  const kMapping = {
    "Created Time": "created",
    "Last Updated": "message.created",
    "Last Message": "message.message",
    Assignee: "team.name",
    Status: "status",
    Issue: "type",
    "Ticket Title": "title",
    Channel: "from",
    Address: "user.address",
    Comment: "comment",
  };
  const isNewMessage = (ticket) => {
    let hasNewMessage = _.get(ticket, "hasNewMessage", false);
    if (hasNewMessage) {
      return true;
    }
    return false;
  };

  const handleReadMessage = (ticket) => {
    if (
      socket &&
      _.get(userData, "address") == _.get(ticket, "team.address", "N/A")
    ) {
      socket.emit("adminReadTicket", {
        adminAddress: _.get(userData, "address"),
        ticketId: ticket._id,
        entityName,
      });
    }
  };

  const handleSort = (header, direction) => {
    const by = header || orderData.sort;
    const dir =
      orderData.sort != by ? orderData.order : direction || orderData.order;

    setOrderData({ sort: by, order: dir });
  };

  const getRowValue = {
    Status: (ticket) => (
      <div style={{ padding: "15px 9px" }}>
        <TicketStatus status={ticket.status} />
      </div>
    ),
    Channel: (ticket) => (
      <div>
        <Channel ticket={ticket} />
      </div>
    ),
    "Issue Tag": (ticket) => <TicketTypeLabel ticketType={ticket.type} />,
    "Ticket Title": (ticket) => (
      <div
        style={{
          minWidth: 100,
          fontWeight: 500,
          fontSize: 15,
          color: "#555555",
        }}
        className="startRow"
      >
        {_.truncate(_.get(ticket, "title", ""))}
      </div>
    ),
    Address: (ticket) => {
      const userId = _.isObject(ticket.user) ? ticket.user.id : ticket.user;
      const didName = _.get(ticket, "userWallet.didNames[0].name", "");
      const icon = getUserAvatarIcon(userId, theme, didName);
      return (
        <div
          style={{
            fontWeight: 500,
            fontSize: 15,
            color: "#383538",
            width: 150,
          }}
          className="startRow"
        >
          {_.get(ticket, "userWallet.address", "") && (
            <img
              src={require(`assets/img/${icon}`)}
              className="smIcon"
              style={{ borderRadius: 20 }}
            />
          )}

          <span>
            {getShortAddress(_.get(ticket, "userWallet.address", ""))}
          </span>
        </div>
      );
    },
    "Last Message": (ticket) => {
      const isNew = isNewMessage(ticket);
      return (
        <div style={{ position: "relative" }}>
          {isNew && (
            <span
              style={{
                position: "absolute",
                right: 0,
                top: "-5px",
                width: "8px",
                height: "8px",
                background: "#FF3296",
                borderRadius: 40,
              }}
            />
          )}
          <div
            style={{
              color: isNew ? "#383538" : "#555555",
              minWidth: 120,
              fontSize: 14,
              maxHeight: 20,
              overflow: "hidden",
              fontWeight: isNew ? 500 : 400,
            }}
            className="startRow"
          >
            {_.truncate(_.get(ticket, "message.message", ""))}
          </div>
        </div>
      );
    },
    "Created Time": (ticket) => (
      <div
        className={classes.timestampLg}
        style={{ minWidth: 110, fontWeight: 400, fontSize: 12 }}
      >
        {formatDateAndTime(_.get(ticket, "created", ""), true)}
      </div>
    ),
    "Last Updated": (ticket) => (
      <div
        className={classes.timestampLg}
        style={{ minWidth: 110, fontWeight: 400, fontSize: 12 }}
      >
        {formatDateAndTime(_.get(ticket, "message.created", ""), true)}{" "}
      </div>
    ),
    Assignee: (ticket) => (
      <div style={{ color: "#A2BFD3", textDecorationLine: "underline" }}>
        {_.get(ticket, "team.name", "N/A")}
      </div>
    ),
    Collaborators: (ticket) => {
      return (
        <div style={{ color: "#A2BFD3", textDecorationLine: "underline" }}>
          {ticket.tagTeam && ticket.tagTeam.length > 0
            ? _.map(ticket.tagTeam, (team, i) => {
                return (
                  <span style={{ marginRight: 5 }} key={i}>
                    {_.get(
                      _.find(memberList, {
                        _id: team,
                      }),
                      "name",
                      "N/A"
                    )}
                  </span>
                );
              })
            : "N/A"}
        </div>
      );
    },
    Comment: (ticket) => {
      if (!ticket.comment && !commentMapping[ticket._id])
        return (
          <Comment
            entityName={entityName}
            selectedTicket={ticket}
            row={true}
            comment={commentMapping[ticket._id]}
            setComment={(v) =>
              setCommentMapping({
                ...commentMapping,
                [ticket._id]: v,
              })
            }
          />
        );
      return (
        <div>{_.truncate(commentMapping[ticket._id] || ticket.comment)}</div>
      );
    },
  };

  let headers = [
    "Status",
    "Channel",
    "Issue Tag",
    "Ticket Title",
    "Last Message",
    "Created Time",
    "Last Updated",
    "Address",
    "Assignee",
    "Collaborators",
    "Comment",
  ];

  return (
    <CustomTable
      centerText={{
        0: true,
        1: true,
      }}
      containerStyle={containerStyle}
      disabledSort={{ 1: true }}
      headers={headers}
      sortDataFunction={handleSort}
      sortMapping={kMapping}
      sortBy={orderData.sort}
      order={orderData.order}
      noDataComponent={noDataComponent}
      noDataTitle="No Ticket"
      rowSelected={(index) => {
        const ticket = _.get(ticketList, `[${index}]`);
        handleReadMessage(ticket);
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            `/service/ticket/detail/${ticket._id}`
          )
        );
      }}
      contents={_.map(ticketList, (ticket) => {
        return _.map(headers, (header) => getRowValue[header](ticket));
      })}
    />
  );
};

export default TicketTable;

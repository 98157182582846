import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, IconButton, Hidden } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import {
  StyledMainCategoryTitle,
  StyledNavItem,
  StyledNavItemTitle,
  StyledNavItemInfo,
} from "./CampaignSideMenu.styles";
import { CampaignCreationContext } from "features/metadesk/campaign/createCampaign/context/CampaignCreationProvider";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const useStyles = (props) =>
  makeStyles((theme) => {
    return {
      toolbar: theme.mixins.toolbar,
    };
  });
export default function CampaignSideMenu({
  content,
  anchor = "left",
  drawerWidth = 200,
}) {
  const { stepData } = useContext(CampaignCreationContext);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [leftOpen, setLeftOpen] = React.useState(false);
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  const leftToggle = () => {
    setLeftOpen(!leftOpen);
  };
  const classes = useStyles({ drawerWidth, computer })();

  const drawerPaper = {
    top: 0,
    width: drawerWidth,
    borderRight: "0.5px solid #D8D8D8",
    fontSize: 13,
  };

  const renderContent = () => (
    <Box>
      <StyledMainCategoryTitle>
        <i className="meta-crm-icon-ic_campaign font-size-16 mr4" />
        {formatMessage({ id: "campaignBuild.info.title" })}
      </StyledMainCategoryTitle>
      <Box padding="8px 10px">
        <StyledNavItem>
          <StyledNavItemTitle>
            {formatMessage({ id: "campaignBuild.info.campaignName" })}
          </StyledNavItemTitle>
          <StyledNavItemInfo>{stepData.name}</StyledNavItemInfo>
        </StyledNavItem>

        <StyledNavItem>
          <StyledNavItemTitle>
            {formatMessage({ id: "global.column.description" })}
          </StyledNavItemTitle>
          <StyledNavItemInfo>{stepData.description}</StyledNavItemInfo>
        </StyledNavItem>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box component="nav" aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <IconButton
          onClick={leftToggle}
          style={{
            position: "fixed",
            zIndex: 1000,
            [anchor]: "0",
            top: "50%",
            color: "white",
            background: "#383538",
          }}
        >
          <MenuOpenIcon />
        </IconButton>
        <Drawer
          container={container}
          variant="temporary"
          anchor={anchor}
          open={leftOpen}
          onClose={leftToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ zIndex: 1300, "& .MuiPaper-root": { ...drawerPaper } }}
        >
          {renderContent()}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={anchor}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
          sx={{ "& .MuiPaper-root": { ...drawerPaper } }}
        >
          {renderContent()}
        </Drawer>
      </Hidden>
    </Box>
  );
}

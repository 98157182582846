import React, { memo, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, useTheme } from "@mui/material";
import Back from "features/metadesk/components/Back";
import {
  StyledContainerTitle,
  StyledWhiteCardContainer,
} from "../createCampaign/component/CampaignCreation.styles";
import _ from "lodash";
import { StyledContentContainer } from "../referral/Referral.styles";
import Objective from "../createCampaign/component/Objective";
import { useForm, FormProvider } from "react-hook-form";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import {
  Button,
  VARIANT,
  AddButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useDispatch } from "react-redux";
import { alertInfo, alertServerError } from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useNavigate, useOutletContext } from "react-router";
import useNavigateEntity from "hooks/useNavigateEntity";
import { Loading } from "features/common";
import { useOpenState } from "contexts/stepContext/Open.context";

const defaultValues = {
  objectives: [{ type: "contract" }],
};

function TrafficReportObjective() {
  const theme = useTheme();
  const [entityName] = useOutletContext();
  const [objectiveData, setObjectiveData] = useState();
  const [multiObjectiveData, setMultiObjectiveData] = useState();
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors: errorsInfo, isValid },
  } = methods;
  const [formData, setFormData] = useState(defaultValues);
  const { setOpen } = useOpenState();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();
  const objectives = _.get(formData, "objectives");
  const objective = _.get(objectives, "[0]");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(async () => {
    try {
      setLoading(true);
      const result = await axios.get(
        apiUrl + `/api/trackingObjective/${entityName}`
      );
      const data = _.get(result, "data", []);
      if (data.length > 0) {
        setObjectiveData(data[0]);
        if (data.length > 1) setMultiObjectiveData(_.slice(data, 1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const convertObjective = (data) => {
    return {
      chainId: data.chainId,
      smartContractAddress: data.contractAddress,
      method: data.eventName,
      smartContractABI: data.eventABI,
      type: "contract",
    };
  };

  useEffect(async () => {
    if (!objectiveData) return;
    setFormData({
      objectives: [convertObjective(objectiveData)],
    });
  }, [objectiveData]);

  const handleCreate = async () => {
    try {
      await axios.post(apiUrl + `/api/trackingObjective/${entityName}`, {
        objectives: [
          {
            chainId: objective.chainId,
            contractAddress: objective.smartContractAddress,
            eventName: objective.method,
            eventABI: objective.smartContractABI,
          },
        ],
      });
      dispatch(alertInfo("Create Objective Success"));
      navigateEntity("/analytics/website");
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  return (
    <Box position={"relative"}>
      <Loading open={loading} fullScreen={true} />
      <FormProvider {...methods}>
        <Back />
        <Box className="title" mb="20px">
          Create your objective
        </Box>
        <StyledWhiteCardContainer>
          <StyledContainerTitle>
            Please select your objective
          </StyledContainerTitle>
          <StyledContentContainer>
            <Objective
              objectiveIndex={0}
              readonly={objectiveData}
              formData={formData}
              isTargetEvent={true}
              setFormData={(values) => {
                setFormData({
                  ...formData,
                  ...values,
                });
              }}
            ></Objective>
            {!objectiveData && (
              <Box sx={{ borderTop: "1px dashed #d6d6d6", mt: 2, pt: 2 }}>
                <AddButton
                  onClick={() => {
                    setOpen({ upgrade: true });
                  }}
                >
                  {formatMessage({
                    id: "campaignBuild.thirdStep.addObjective.btn",
                  })}
                </AddButton>
              </Box>
            )}
            {multiObjectiveData &&
              _.map(multiObjectiveData, (multiData, i) => (
                <Objective
                  objectiveIndex={0}
                  readonly={true}
                  formData={{ objectives: [convertObjective(multiData)] }}
                  isTargetEvent={true}
                ></Objective>
              ))}
          </StyledContentContainer>
        </StyledWhiteCardContainer>
        {!objectiveData && (
          <FixedButton>
            <Button
              variant={VARIANT.TEXT}
              style={{ width: 144 }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit(handleCreate())}
              style={{ width: 144 }}
              disabled={!isValid}
            >
              Create
            </Button>
          </FixedButton>
        )}
      </FormProvider>
    </Box>
  );
}

export default memo(TrafficReportObjective);

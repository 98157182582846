import CustomPagination from "components/CustomPagination/CustomPagination";
import { Loading } from "features/common";
import React, { useState } from "react";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import useGetCampaignTable from "./hook/useGetCampaignTable";
import { Box } from "@mui/material";
import { map, upperFirst } from "lodash-es";
import ActionDropDown from "./component/ActionDropDown";
import StatusLabel, {
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import TrackingModal from "./component/TrackingModal";
import moment from "moment";
import { useIntl } from "react-intl";
import useNavigateEntity from "hooks/useNavigateEntity";
import useGetPagePermission from "hooks/useGetPagePermission";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";

export default function CampaignTable() {
  const PAGE_COUNT = 20;
  const [trackingLinksModal, setTrackingLinksModal] = useState({
    isOpen: false,
    data: [],
    campaignName: "",
  });
  const { formatMessage } = useIntl();
  const {
    loading,
    page,
    setPage,
    rowData,
    headCells,
    totalCount,
    handleRowClick,
    handleRequestSort,
    handleDelete,
    order,
    orderBy,
  } = useGetCampaignTable();

  const renderStatus = (status) => {
    let color;
    if (status === "draft") {
      color = COLOR.SECONDARY;
    } else if (status === "scheduled") {
      color = COLOR.PRIMARY;
    } else if (status === "ongoing") {
      color = COLOR.SUCCESS;
    } else {
      color = COLOR.INFO;
    }
    return (
      <StatusLabel color={color} size={"small"}>
        {upperFirst(status)}
      </StatusLabel>
    );
  };

  const renderContents = (performance) => {
    const contents = [
      <Box slot="status">{renderStatus(performance.status)}</Box>,
      <Box slot="campaignName" sx={{ maxWidth: "260px" }} className="wordBreak">
        {performance.name}
      </Box>,
      <Box
        slot="trackingLink"
        style={{
          cursor: "default",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {performance.status !== "draft" ? (
          <p
            onClick={(e) => {
              if (performance.status === "draft") return;
              setTrackingLinksModal({
                isOpen: true,
                data: performance.trackingLinks,
                campaignName: performance.name,
                promotype: performance.promotype,
              });
            }}
            style={{
              color: "#7B61FF",
              width: "fit-content",
              paddingBottom: "1px",
              lineHeight: "1",
              borderBottom: "1px solid #7B61FF",
              fontWeight: 700,
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            {performance?.trackingLinks?.length}
          </p>
        ) : (
          "--"
        )}
      </Box>,
      <Box slot="promotype">
        <IconTooltip
          icon={
            <Box sx={{ fontSize: "12px", cursor: "pointer" }}>
              {upperFirst(performance.promotype) || "--"}
            </Box>
          }
          content="Go to the Dashboard page to view the performance of this campaign"
          customColor="#555555"
        />
      </Box>,
      <Box slot="clicks">{performance.linkClicked || "--"}</Box>,
      <Box slot="startDate">
        {performance.startFrom
          ? moment(performance.startFrom).format("DD MMM YYYY , HH:mm")
          : "--"}
      </Box>,
      <Box slot="lastUpdated">
        {moment(performance.updated).format("DD MMM YYYY , HH:mm")}
      </Box>,
      <Box slot="assignee">{performance.assignee || "--"}</Box>,
      <Box slot="action">
        <ActionDropDown
          campaignName={performance.name}
          id={performance._id}
          handleDelete={handleDelete}
          status={performance.status}
          promotype={performance.promotype}
        />
      </Box>,
    ];
    return contents;
  };

  const { navigateEntity } = useNavigateEntity();
  const pagePermission = useGetPagePermission();
  const { canEdit: canGoToIntegration } = pagePermission("integration");

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 330px)",
          overflow: "auto",
        }}
      >
        <Loading open={loading} fullScreen={false} white />
        <CRMTable
          rowData={rowData}
          headers={headCells}
          contents={map(rowData, (user, i) => renderContents(user))}
          noDataComponent={
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="6%"
            >
              <img src={require("assets/img/campaignNoData.png")} />
              <p style={{ color: "#555555", width: "250px" }}>
                {formatMessage({ id: "campaigns.emptyState.description" })}
              </p>
            </Box>
          }
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
      </div>
      <TrackingModal
        open={trackingLinksModal.isOpen}
        onClose={() =>
          setTrackingLinksModal({ isOpen: false, data: [], campaignName: "" })
        }
        data={trackingLinksModal.data}
        campaignName={trackingLinksModal.campaignName}
        promotype={trackingLinksModal.promotype}
      />
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={totalCount}
        page={page}
        setPage={(v) => setPage(v)}
      />
    </>
  );
}

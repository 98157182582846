import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "@mui/material";
import moment from "moment";

const LineChart = ({ performance, isShowObjective }) => {
  const option = useMemo(() => {
    const legendData = ["Wallets Connected", "Total User"];
    const series = [
      {
        name: "Wallets Connected",
        type: "line",
        smooth: true,
        data: performance.map((x) => x.walletsConnected),
        itemStyle: { color: "#72DFD0" },
        lineStyle: { color: "#72DFD0" },
        showSymbol: false,
      },
      {
        name: "Total User",
        type: "line",
        smooth: true,
        data: performance.map((x) => x.totalUser),
        itemStyle: { color: "#F7ABC7" },
        lineStyle: { color: "#F7ABC7" },
        showSymbol: false,
      },
    ];

    if (isShowObjective) {
      legendData.push("Objective Achieved");
      series.push({
        name: "Objective Achieved",
        type: "line",
        smooth: true,
        data: performance.map((x) => x.objectiveAchieved),
        itemStyle: { color: "#BA8DFB" },
        lineStyle: { color: "#BA8DFB" },
        showSymbol: false,
      });
    }

    return {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        orient: "horizontal",
        left: "left",
        data: legendData,
      },
      xAxis: {
        type: "category",
        data: performance.map((x) => x.date),
        axisLabel: {
          formatter: (value) => moment(value).format("DD MMM"),
        },
      },
      yAxis: {
        type: "value",
      },
      series: series,
    };
  }, [performance, isShowObjective]);

  return (
    <Box
      sx={{
        height: "308px",
        flex: 1,
        bgcolor: "#fff",
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: (theme) => theme.customColors.grey[700],
        }}
      >
        Number of users and wallets connected over time
      </Box>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", padding: "12px" }}
      />
    </Box>
  );
};

export default LineChart;

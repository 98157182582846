import React, { useContext } from "react";
import PerformanceTable from "./PerformanceTable";
import PerformanceLeftChart from "./PerformanceLeftChart";
import PerformanceHeader from "./PerformanceHeader";
import { Box } from "@material-ui/core";
import PerformanceRightChart from "./PerformanceRightChart";
import PerformanceSearch from "./PerformanceSearch";
import {
  PerformanceContext,
  PerformanceProvider,
} from "./context/PerformanceProvider";
import useGetPerformanceChart from "./hook/useGetPerformanceChart";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { Loading } from "features/common";

export default function CampaignPerformance() {
  return (
    <PerformanceProvider>
      <PerformanceHeader />
      <PerformanceContent />
    </PerformanceProvider>
  );
}

function PerformanceContent() {
  const { isLoading } = useContext(PerformanceContext);

  return (
    <Box position={"relative"}>
      <Loading
        fullScreen={false}
        white
        open={isLoading.switchTab || isLoading.chart || isLoading.table}
        topPos={"50vh"}
      />
      <PerformanceSearch />

      <PerformanceCharts />
      <PerformanceTable />
    </Box>
  );
}

function PerformanceCharts() {
  const { timeRangeClicked, totalClicked } = useGetPerformanceChart();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "between",
        alignItems: "center",
      }}
    >
      <PerformanceLeftChart timeRangeClicked={timeRangeClicked} />
      <PerformanceRightChart totalClicked={totalClicked} />
    </Box>
  );
}

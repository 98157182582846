import { Box } from "@mui/material";
import ReactECharts from "echarts-for-react";

export default function BarChart({ title, isShowObjective, data }) {
  const series = [
    {
      name: "Users",
      type: "bar",
      data: data.map((d) => d.totalUser),
      itemStyle: { color: "#72DFD0" },
      label: { show: true, fontSize: 10 },
    },
    {
      name: "Wallets",
      type: "bar",
      data: data.map((d) => d.walletsConnected),
      itemStyle: { color: "#F7ABC7" },
      label: { show: true, fontSize: 10 },
    },
  ];

  if (isShowObjective) {
    series.push({
      name: "Objective Achieved",
      type: "bar",
      data: data.map((d) => d.objectiveAchieved),
      itemStyle: { color: "#BA8DFB" },
      label: { show: true, fontSize: 10 },
    });
  }

  const option = {
    tooltip: {},
    legend: {
      data: series.map((s) => s.name),
      left: "center",
      bottom: 0,
    },
    xAxis: {
      type: "category",
      data: data.map((d) => d.browser),
    },
    yAxis: {
      type: "value",
    },
    series,
  };

  return (
    <Box
      sx={{
        p: 3,
        height: "365px",
        bgcolor: "#fff",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: (theme) => theme.customColors.grey[700],
        }}
      >
        {title}
      </Box>
      <ReactECharts option={option} style={{ flex: 1, width: "100%" }} />
    </Box>
  );
}

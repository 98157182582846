import { combineReducers } from "redux";
import { reducer as fetchMagpieDetailsReducer } from "../magpie/redux/fetchMagpieDetails";
import { reducer as fetchMagpieTxsReducer } from "../magpie/redux/fetchMagpieTxs";
import { reducer as fetchCurveDetailsReducer } from "../curve/redux/fetchCurveDetails";
import { reducer as fetchCurveOnchainDetailsReducer } from "../curve/redux/fetchCurveOnchainDetails";
import { reducer as fetchCurveTxsReducer } from "../curve/redux/fetchCurveTxs";
import { reducer as fetchTeamMemberReducer } from "./fetchTeamMember";
import {
  reducer as fetchTicketListReducer,
  TICKET_FILTER_INITIAL_STATE,
} from "./fetchTicketList";
import {
  reducer as fetchUserListReducer,
  USER_FILTER_INITIAL_STATE,
} from "./fetchUserList";
import { reducer as fetchUserInfoReducer } from "./fetchUserInfo";
import { reducer as fetchUserWalletInfoReducer } from "./fetchUserWalletInfo";
import { reducer as fetchRadiantDetailsReducer } from "../radiant/redux/fetchRadiantDetails";
import { reducer as fetchRadiantOnchainDetailsReducer } from "../radiant/redux/fetchRadiantOnchainDetails";
import { reducer as fetchRadiantV1DetailsReducer } from "../radiant/redux/fetchRadiantV1Details";
import { reducer as fetchRadiantUserDetailsReducer } from "../radiant/redux/fetchRadiantUserDetails";
import { reducer as fetchRadiantTxsReducer } from "../radiant/redux/fetchRadiantTxs";
import { reducer as fetchTagsReducer } from "./fetchTags";
import { reducer as fetchEntityConfigReducer } from "./entityConfig/fetchEntityConfig";
import { reducer as fetchWidgetConfigReducer } from "./entityConfig/fetchWidgetConfig";
import { reducer as fetchTransactionTypeReducer } from "./fetchTransactionType";
import { reducer as fetchTxsReducer } from "./fetchTxs";
import { reducer as fetchNotificationsReducer } from "./fetchNotifications";
import {
  reducer as fetchGroupsReducer,
  GROUP_USER_FILTER_INITIAL_STATE,
} from "./fetchGroups";
import { reducer as fetchGithubReducer } from "./fetchGithub";
import { reducer as fetchRoles } from "./roles";

import {
  reducer as fetchAttributionsReducer,
  ATTRIBUTION_FILTER_INITIAL_STATE,
} from "./fetchAttributions";
import {
  reducer as macroReducer,
  MACRO_INITIAL_STATE,
} from "./macro/macro.reducer";
import {
  reducer as trialReducer,
  TRIAL_INITIAL_STATE,
} from "./trial/trial.reducer";
import {
  reducer as globalStateReducer,
  GLOBAL_STATE_INITIAL_STATE,
} from "./globalState/globalState.reducer";
import { reducer as fetchAiSupportReducer } from "./aiSupport/fetchAiSupport";

import moment from "moment";
const reducers = [
  fetchMagpieDetailsReducer,
  fetchMagpieTxsReducer,
  fetchTeamMemberReducer,
  fetchTicketListReducer,
  fetchUserListReducer,
  fetchUserInfoReducer,
  fetchUserWalletInfoReducer,
  fetchCurveDetailsReducer,
  fetchCurveOnchainDetailsReducer,
  fetchCurveTxsReducer,
  fetchRadiantDetailsReducer,
  fetchRadiantOnchainDetailsReducer,
  fetchRadiantV1DetailsReducer,
  fetchRadiantUserDetailsReducer,
  fetchRadiantTxsReducer,
  fetchTagsReducer,
  fetchEntityConfigReducer,
  fetchGroupsReducer,
  fetchTransactionTypeReducer,
  fetchTxsReducer,
  fetchNotificationsReducer,
  fetchGithubReducer,
  macroReducer,
  fetchAttributionsReducer,
  fetchWidgetConfigReducer,
  trialReducer,
  fetchRoles,
  fetchAiSupportReducer,
  globalStateReducer,
];
const initialState = {
  radiantDetails: [],
  ticketTypeList: [],
  radiantTxs: [],
  soldRDNTTxs: [],
  magpieDetails: [],
  savedHeaderItems: {},
  txs: [],
  statsCounts: {
    ticket: {
      New: 0,
      Done: 0,
      Ongoing: 0,
      All: 0,
    },
    user: {
      metaDesk: 0,
      import: 0,
      smartContract: 0,
      all: 0,
    },
    group: 0,
    team: 0,
  },
  queryUsers: [],
  performances: {},
  fetchPerformancesPending: {},
  DIDInfo: {},
  DIDCount: 0,
  queryUsersCount: 0,
  userListCount: 0,
  groupsCount: 0,
  groupUserCount: 0,
  ticketCounts: 0,
  draftsCount: 0,
  notificationsCount: 0,
  magpieTxs: [],
  groupUsers: [],
  curveDetails: [],
  curveOnchainDetails: [],
  subscribeInfo: {},
  selectedGroup: {},
  curveTxs: [],
  soldMGPTxs: [],
  memberList: [],
  ticketList: [],
  notificationData: {},
  filter: { status: "NEW" },
  notification: {},
  filterTicketList: [],
  userList: [],
  tags: [],
  groups: [],
  userWalletInfos: {},
  searchUserWalletInfos: {},
  userInfos: {},
  fetchSearchUserWalletInfoPending: false,
  userTicketList: {},
  drafts: [],
  notifications: [],
  githubIssues: [],
  githubRepos: [],
  userSource: [],
  csvHeaders: [],
  userSourceCount: 0,
  fetchUserSourcePending: false,
  userAdminList: [],
  userGuideInfo: {},
  attributionsFilterData: {
    page: 1,
    dateRange: [
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ],
    searchName: "",
    sort: "created",
    order: -1,
  },
  fetchUserAdminListPending: false,
  ticketTypeList: [],
  fetchUserGuideInfoDone: {},
  entityConfig: {
    init: true,
    ticketTypes: [],
    signatureSignInDisabled: false,
  },
  singleAttributionFilter: {},
  roles: [],
  ...MACRO_INITIAL_STATE,
  ...TICKET_FILTER_INITIAL_STATE,
  ...USER_FILTER_INITIAL_STATE,
  ...ATTRIBUTION_FILTER_INITIAL_STATE,
  ...TRIAL_INITIAL_STATE,
  ...GROUP_USER_FILTER_INITIAL_STATE,
  ...GLOBAL_STATE_INITIAL_STATE,
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}

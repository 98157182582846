import styled from "@emotion/styled";

export const EntityListContainer = styled.section`
  width: 100%;
  height: auto;
`;

export const UserAddress = styled("p")(({ theme }) => ({
  color: theme.customColors.purple[500],
  fontWeight: theme.fontWeight.NORMAL,
  fontSize: 14,
  marginBottom: 8,
  paddingLeft: 20,
}));

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { get } from "lodash-es";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";

import { enqueueSnackbar } from "features/common/redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import {
  Button,
  COLOR,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import {
  selectEntityId,
  selectTrackingObject,
  selectWidgetVersionCanUpdate,
  selectAttributionVersionCanUpdate,
  selectWidgetConnected,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { apiUrl } from "features/configure";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import useMarketModule from "hooks/useMarketModule";
import Back from "../components/Back";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledContainerTitle,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
} from "../campaign/createCampaign/component/CampaignCreation.styles";
import IntegrationVersionInfo from "../settings/integrations/IntegrationVersionInfo";
import CodePannel from "components/CodePannel/CodePannel";
import { isVersionLessThan } from "features/helpers/utils";
import { Loading } from "features/common";
import {
  generateTrackingScript,
  generateWidgetScript,
} from "features/configure/appCode";
import SriCodeTab from "./SriCodeTab";

const AppUpdateList = () => {
  const { formatMessage } = useIntl();
  const [entityName] = useOutletContext();
  let [searchParams] = useSearchParams();
  const updateType = searchParams.get("updateType");
  const { attributionConnect } = useMarketModule();
  const widgetConnected = useSelector(selectWidgetConnected);
  const ecosystemName = useSelector(selectEcosystemName);
  const entityId = useSelector(selectEntityId);
  const [trackingIntegrityInfo, setTrackingIntegrityInfo] = useState({});
  const [widgetIntegrityInfo, setWidgetIntegrityInfo] = useState({});

  const trackingObject = useSelector(selectTrackingObject);
  const { apiKey } = trackingObject;
  const { fetchWidgetConfig, widgetConfig } = useFetchWidgetConfig();
  const { fetchEntityConfig } = useFetchEntityConfig();
  const [appType, setAppType] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [failedDialog, setFailedDialog] = useState(false);
  const [loading, setLoading] = useState({
    widget: false,
    tracking: false,
    integrity: false,
  });
  const trackingVersionData = useSelector(selectAttributionVersionCanUpdate);
  const widgetVersionData = useSelector(selectWidgetVersionCanUpdate);
  const showEmpty =
    !trackingVersionData?.canUpdate && !widgetVersionData?.canUpdate;
  const fetchIntegrity = async () => {
    try {
      setLoading({ ...loading, integrity: true });
      if (attributionConnect) {
        const trackingData = await axios.get(
          `${apiUrl}/api/integrity/tracking`
        );
        setTrackingIntegrityInfo(trackingData?.data);
      }
      if (widgetConnected) {
        await fetchWidgetConfig({ entityName });
        const widgetData = await axios.get(`${apiUrl}/api/integrity/widget`);
        setWidgetIntegrityInfo(widgetData?.data);
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading({ ...loading, integrity: false });
    }
  };

  useEffect(async () => {
    fetchIntegrity();
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCheckInstallAttribution = async (e) => {
    try {
      e.stopPropagation();
      setLoading({ ...loading, tracking: true });
      await fetchEntityConfig({ entityName });

      if (!trackingVersionData?.canUpdate) {
        setSuccessDialog(true);
      } else {
        setFailedDialog(true);
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading({ ...loading, tracking: false });
    }
  };

  const handleCheckInstallWidget = async (e, widgetId) => {
    try {
      e.stopPropagation();
      setLoading({ ...loading, widget: true });
      await fetchEntityConfig({ entityName });
      const widgetData = await fetchWidgetConfig({ entityName });

      const jsVersion = widgetData.find(
        (widgetConfigItem) => widgetConfigItem._id === widgetId
      )?.jsVersion;
      const version = jsVersion || widgetVersionData.version;

      if (
        !version ||
        isVersionLessThan(version, widgetVersionData.latestVersion)
      ) {
        setFailedDialog(true);
      } else {
        setSuccessDialog(true);
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading({ ...loading, widget: false });
    }
  };

  const renderUpdateApp = ({
    key,
    title,
    sriCode,
    nonSriCode,
    checkStatus,
    open = true,
    versionData,
    type,
    i18nKey,
  }) => {
    const isUpToDate =
      versionData.version && versionData.version == versionData.latestVersion;
    if (isUpToDate) return;
    return (
      <StyledWhiteAccordion
        key={key}
        disableGutters={true}
        sx={{ mt: "15px" }}
        defaultExpanded={open}
      >
        <StyledWhiteAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <StyledContainerTitle
            className="betweenRow"
            style={{ padding: "12px 20px" }}
          >
            <Box>
              {title}
              <StatusLabel
                color={isUpToDate ? COLOR.SECONDARY : COLOR.INFO}
                style={{ marginLeft: "8px" }}
              >
                {formatMessage({
                  id: isUpToDate
                    ? "global.status.upToDate"
                    : "global.status.notUpToDate",
                })}
              </StatusLabel>
            </Box>
            <Button
              variant={VARIANT.OUTLINED}
              disabled={isUpToDate}
              style={{ width: 140 }}
              size={SIZE.MEDIUM}
              isLoading={loading[type]}
              onClick={checkStatus}
            >
              {formatMessage({
                id: "global.btn.checkStatus",
              })}
            </Button>
          </StyledContainerTitle>
        </StyledWhiteAccordionSummary>
        <AccordionDetails sx={{ p: "0px" }}>
          <Box className="mhr" sx={{ m: "0px" }}></Box>
          <Box className="description" sx={{ p: "10px 20px" }}>
            {formatMessage({
              id: `updateList.${i18nKey}.description`,
            })}
          </Box>
          <Box sx={{ p: "20px" }}>
            <Grid container sx={{ fontWeight: 500, fontSize: "14px" }}>
              <Grid item xs={12} md={4}>
                <Box sx={{ fontWeight: 700, fontSize: "14px", mb: "15px" }}>
                  {formatMessage({
                    id: `updateList.instruction.subtitle`,
                  })}
                </Box>
                <Box>
                  <Box
                    sx={{
                      color: theme.customColors.purple[500],
                      mb: "3px",
                    }}
                  >
                    Step 1:
                  </Box>
                  <Box color={theme.customColors.grey[600]}>
                    {formatMessage({
                      id: `${i18nKey}Update.instruction.step1`,
                    })}
                  </Box>
                  <Box
                    sx={{
                      color: theme.customColors.purple[500],
                      mt: "15px",
                      mb: "3px",
                    }}
                  >
                    Step 2:
                  </Box>
                  <Box color={theme.customColors.grey[600]}>
                    {formatMessage({
                      id: `${i18nKey}Update.instruction.step2`,
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{ color: theme.customColors.grey[500] }}
                  className="startRow"
                  mb="10px"
                >
                  {formatMessage({
                    id: "global.integration.latestVersion.description",
                  })}
                  :
                  <Box
                    ml="7px"
                    color={"#7B61FF"}
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAppType(type);
                    }}
                  >
                    {versionData.latestVersion}
                  </Box>
                </Box>
                <SriCodeTab sriCode={sriCode} nonSriCode={nonSriCode} />

                <Box className="codeContainer startRow">
                  <i className="meta-crm-icon-ic_info font-size-16 mgr4" />{" "}
                  {formatMessage({
                    id: `widgetUpdate.codeblock.explanation`,
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </StyledWhiteAccordion>
    );
  };

  const renderContent = () => {
    if (showEmpty) {
      return (
        <Box
          alignItems={"center"}
          minHeight={"60vh"}
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
          color={theme.customColors.grey[700]}
          fontSize={"16px"}
          fontWeight={500}
          maxWidth={"350px"}
          margin={"0 auto"}
          lineHeight={"20px"}
        >
          <Box>
            <img src={require("assets/img/allUpgraded.png")} />
            <Box>
              {formatMessage(
                { id: "update.updateList.emptyState.description" },
                { br: <br /> }
              )}
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <>
        {attributionConnect &&
          renderUpdateApp({
            title: formatMessage({
              id: `updateList.trackingTag.title`,
            }),
            checkStatus: handleCheckInstallAttribution,
            versionData: trackingVersionData,
            open: updateType ? updateType == "tracking" : true,
            type: "tracking",
            sriCode: generateTrackingScript({
              integrityInfo: trackingIntegrityInfo,
              apiKey,
              entityId,
              ecosystemName,
            }),
            nonSriCode: generateTrackingScript({
              apiKey,
              entityId,
              ecosystemName,
            }),
            i18nKey: "trackingTag",
          })}
        {widgetConnected &&
          _.map(widgetConfig, (widgetItem) => {
            if (!widgetItem.connected) return;
            return renderUpdateApp({
              key: widgetItem?._id,
              title:
                formatMessage({
                  id: `updateList.widget.title`,
                }) + ` - ${widgetItem?.name}`,
              open: updateType ? updateType == "widget" : true,
              checkStatus: (e) => handleCheckInstallWidget(e, widgetItem?._id),
              versionData: {
                ...widgetVersionData,
                version: widgetItem.jsVersion || widgetVersionData.version,
              },
              type: "widget",
              sriCode: generateWidgetScript({
                apiKey: widgetItem.apiKey,
                integrityInfo: widgetIntegrityInfo,
              }),
              nonSriCode: generateWidgetScript({
                apiKey: widgetItem.apiKey,
              }),
              i18nKey: "widget",
            });
          })}
      </>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: 1200,
        margin: "20px auto",
        padding: "10px",
        paddingTop: "60px",
      }}
    >
      <Loading open={loading.integrity} />
      <Back />
      <Box className="lgTitle" style={{ marginTop: 18 }}>
        {formatMessage({ id: "update.updateList.title" })}
      </Box>
      <Box className="description" style={{ lineHeight: "20px" }}>
        {formatMessage({ id: "update.updateList.description" })}
      </Box>
      {renderContent()}
      {appType && (
        <IntegrationVersionInfo
          handleClose={() => setAppType("")}
          type={appType}
        />
      )}

      <BasicDialog
        dialogTitle={formatMessage({ id: "popup.statusReturn.upTpDate.title" })}
        dialogContent={formatMessage({
          id: "popup.statusReturn.upTpDate.description",
        })}
        open={successDialog}
        onClose={() => setSuccessDialog(false)}
        dialogBottom={
          <Box textAlign={"center"} sx={{ maxWidth: "250px", mt: "20px" }}>
            <Button
              sx={{ width: "120px" }}
              onClick={() => setSuccessDialog(false)}
              size={SIZE.LARGE}
            >
              OK
            </Button>
            <Box
              color={theme.customColors.grey[500]}
              fontSize={"12px"}
              mt="20px"
            >
              {formatMessage({ id: "popup.statusReturn.upTpDate.tooltip" })}
            </Box>
          </Box>
        }
      />
      <BasicDialog
        dialogTitle={formatMessage({
          id: "popup.statusReturn.needUpdate.title",
        })}
        dialogContent={formatMessage({
          id: "popup.statusReturn.needUpdate.description",
        })}
        open={failedDialog}
        onClose={() => setFailedDialog(false)}
        dialogBottom={
          <Box textAlign={"center"} sx={{ maxWidth: "250px", mt: "20px" }}>
            <Button
              sx={{ width: "120px" }}
              onClick={() => setFailedDialog(false)}
              size={SIZE.LARGE}
            >
              OK
            </Button>
            <Box>
              <a target="_blank" href="mailto:bd@metacrm.inc">
                <Button
                  sx={{ width: "140px", color: "#383538" }}
                  size={SIZE.LARGE}
                  variant={VARIANT.TEXT}
                >
                  {formatMessage({ id: "global.btn.letsSee" })}
                </Button>
              </a>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default AppUpdateList;

import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Landing from "features/home/Landing";
import { Trial, TrialMarketing, TrialService } from "features/home/pages";
import NotFound from "features/metadesk/components/NotFound";
import { RouterProvider } from "react-router-dom";
import _ from "lodash";
import {
  MainMenu,
  TicketDetail,
  TicketList,
  GroupList,
  GroupDetail,
  SingleUserTicketList,
  Organization,
  OrganizationCreation,
  Security,
  Integration,
  IntegrationDiscord,
  PermissionControl,
  AiManagement,
  Macros,
  IntegrationGithub,
  ServerCallback,
  SettingSupportCenter,
  Profile,
  TicketType,
  IntegrationMailchimp,
  IntegrationAttributionLink,
  IntegrationWidgetSettingContainer,
  WidgetSetting,
  IntegrationDiscordWebhook,
  IntegrationSlack,
  CampaignPerformance,
  CampaignUtmLinksAnalysis,
  CampaignAnalysis,
  CampaignSinglePerformance,
  CampaignList,
  CreateCampaign,
  CampaignEdit,
  IntegrationConnectDiscord,
  IntegrationConnectSlack,
  ServiceReportPage,
  WorkspaceLogin,
  WorkspaceView,
  IntegrationXmtp,
  IntegrationPush,
  CustomerBehavior,
  ImportList,
  Referral,
  CreateReferral,
  ReferralDetail,
  AppUpdateList,
  OnChainDataSource,
  CompanyProfile,
  CustomServiceWidgetNew,
  DiscordBot,
  InterfaceTheme,
  TrafficReportObjective,
} from "features/metadesk";
import AppContainer from "features/home/AppContainer";
import { FormattedMessage } from "react-intl";
import UnderMaintenance from "features/metadesk/components/UnderMaintenance";
import CreateGroup from "features/metadesk/group/CreateGroup/CreateGroup";
import Billing from "features/metadesk/settings/Billing/Billing";
import ManagePlan from "features/metadesk/settings/Billing/ManagePlan";
import envConfig from "./config";
import Onboarding from "features/metadesk/Onboarding/Onboarding";
const envEntity = process.env.REACT_APP_ENTITY;
const isCustomDomain = Boolean(envEntity);

const transformConfig = (config) => {
  const transform = (route, parentUpgrade = {}, parentAllowed = {}) => {
    const {
      path,
      element,
      children,
      feature,
      handle,
      upgrade,
      disabled,
      index,
      ecosystems,
      needEditPermission,
      allowed,
      allowedEntities,
    } = route;

    const mergedAllowed = { ...parentAllowed, ...allowed };
    const mergedUpgrade = { ...parentUpgrade, ...upgrade };
    const wrappedElement = (
      <PermissionControl
        upgrade={mergedUpgrade} //check config to upgrade
        feature={feature} // check permission to hide and redirect to 404
        allowed={mergedAllowed} //check config to hide and redirect to 404
        ecosystems={ecosystems}
        disabled={disabled}
        allowedEntities={allowedEntities}
        needEditPermission={needEditPermission}
      >
        {element}
      </PermissionControl>
    );
    const transformedChildren = children
      ? children.map((child) => transform(child, mergedUpgrade, mergedAllowed))
      : undefined;

    return {
      path,
      element: element ? wrappedElement : null,
      children: transformedChildren,
      index,
      handle,
    };
  };
  return transform(config);
};

const serviceConfig = {
  path: "service",
  title: "Services",
  icon: <i className="meta-crm-icon-ic_services font-size-16" />,
  element: <MainMenu />,
  upgrade: { "modules.service": true },
  children: [
    {
      path: "ticket/detail/:ticketId",
      feature: "tickets", //for permission
      handle: { hideMenu: true },
      element: <TicketDetail />,
    },

    {
      path: "ticket",
      feature: "tickets",
      isMenu: true,
      title: "Tickets",
      category: "ticket", // for stats count
      children: [
        {
          title: "New",
          path: "new",
          element: <TicketList ticketStatus="new" />,
        },
        {
          title: "Ongoing",
          path: "ongoing",
          element: <TicketList ticketStatus="ongoing" />,
        },
        {
          title: "Done",
          path: "done",
          element: <TicketList ticketStatus="done" />,
        },
        {
          title: "All",
          path: "all",
          element: <TicketList ticketStatus="all" />,
        },
      ],
    },

    {
      path: "dashboard",
      title: "Ticket Distribution",
      feature: "servicesReport",
      element: <ServiceReportPage />,
    },
  ],
};

const marketingConfig = {
  path: "marketing",
  title: "Marketing",
  icon: <i className="meta-crm-icon-ic_marketing font-size-16" />,
  element: <MainMenu />,
  upgrade: { "modules.marketing": true },
  children: [
    {
      path: "campaign/edit/:campaignId",
      feature: "campaigns",
      handle: { hideMenu: true },
      element: <CampaignEdit />,
    },
    {
      path: "campaign/create",
      needEditPermission: true,
      feature: "campaigns",
      handle: { hideMenu: true },
      element: <CreateCampaign />,
    },
    {
      path: "campaign/create/:campaignId",
      needEditPermission: true,
      feature: "campaigns",
      handle: { hideMenu: true },
      element: <CreateCampaign />,
    },

    {
      path: "campaign",
      feature: "campaigns",
      isMenu: true,
      title: "Campaigns",
      category: "campaign", // for stats count
      children: [
        {
          index: true,
          title: "Draft",
          path: "Draft",
          feature: "campaigns",
          element: <CampaignList campaignStatus={"draft"} />,
        },
        {
          title: "Scheduled",
          path: "scheduled",
          feature: "campaigns",
          element: <CampaignList campaignStatus={"scheduled"} />,
        },
        {
          title: "Ongoing",
          path: "ongoing",
          feature: "campaigns",
          element: <CampaignList campaignStatus={"ongoing"} />,
        },
        {
          title: "Done",
          path: "done",
          feature: "campaigns",
          element: <CampaignList campaignStatus={"done"} />,
        },
        {
          title: "All",
          path: "all",
          feature: "campaigns",
          element: <CampaignList campaignStatus={"all"} />,
        },
      ],
    },
    {
      path: "referral",
      feature: "campaigns",
      title: "Referral",
      isMenu: true,
      upgrade: { "marketingModule.attribution.referral.enabled": true },
      children: [
        {
          index: true,
          path: "",
          element: <Referral />,
          title: "Program",
          feature: "campaigns",
        },
        {
          path: "create",
          handle: { hideMenu: true },
          feature: "campaigns",
          upgrade: { "marketingModule.attribution.referral.enabled": true },
          element: <CreateReferral />,
        },
      ],
    },
    {
      path: "dashboard",
      title: "Performance",
      isMenu: true,
      feature: "campaignPerformance",
      children: [
        {
          path: "campaign",
          title: "Campaign Performance",
          feature: "campaignPerformance",
          element: <CampaignPerformance />,
        },
        {
          path: "referral",
          title: "Referral Performance",
          feature: "campaignPerformance",
          upgrade: { "marketingModule.attribution.referral.enabled": true },
          element: <ReferralDetail />,
        },
        {
          path: "campaign/utmLinks/:id",
          feature: "campaignPerformance",
          element: <CampaignUtmLinksAnalysis />,
        },
        {
          path: "campaign/:id",
          feature: "campaignPerformance",
          element: <CampaignSinglePerformance />,
        },
      ],
    },
    // {
    //   path: "legacy",
    //   allowed: { hasLegacy: true },
    //   title: "Legacy Performance",
    //   isMenu: true,
    //   feature: "campaignPerformance",
    //   children: [
    //     {
    //       path: "broadcast",
    //       title: "Broadcast",
    //       element: <NotificationsPerformance type="broadcast" />,
    //     },
    //     {
    //       path: "segmented",
    //       title: "Segmented Message",
    //       element: <NotificationsPerformance type="segmented" />,
    //     },

    //     {
    //       path: "attribution",
    //       title: "Attribution Link",
    //       element: <AttributionLink />,
    //     },
    //     {
    //       path: "attribution/analysis/:campaignId",
    //       element: <AttributionDetail />,
    //     },
    //     {
    //       path: "attribution/analysis",
    //       title: "Attribution Analysis",
    //       element: <AttributionPerformance />,
    //     },
    //     {
    //       path: ":type/:notificationId",
    //       element: <PerformanceDetail />,
    //     },
    //   ],
    // },
  ],
};

const analyticsConfig = {
  path: "analytics",
  title: "Analytics",

  icon: <i className="meta-crm-icon-ic_analytics font-size-16" />,
  element: <MainMenu />,
  upgrade: { "modules.marketing": true },
  children: [
    {
      path: "website",
      title: "Website Traffic",
      feature: "campaignPerformance",
      children: [
        {
          index: true,
          path: "",
          feature: "campaignPerformance",
          element: <CampaignAnalysis />,
        },
        {
          path: "objective",
          feature: "campaignPerformance",
          ecosystems: ["EVM"],
          element: <TrafficReportObjective />,
        },
      ],
    },
  ],
};

const advertiseConfig = {
  path: "advertise",
  title: "Ads",
  upgrade: { "advertise.enabled": true },
  icon: <i className="meta-crm-icon-ic_advertise font-size-20" />,
  element: <MainMenu />,
  children: [{}],
};

const customersConfig = {
  path: "customers",
  title: "Customers",
  icon: <i className="meta-crm-icon-ic_customers font-size-16" />,
  element: <MainMenu />,
  children: [
    {
      path: "list",
      title: "Customer List",
      feature: "customerList",
      element: <ImportList />,
    },
    {
      path: "user/:userId",
      feature: "customerList",
      handle: { hideMenu: true },
      element: <SingleUserTicketList />,
    },
    {
      path: "behavior",
      feature: "customerList",
      title: "Customer Analytics",
      allowed: { demoSite: true },
      element: <CustomerBehavior />,
    },
    {
      path: "segment/create",
      feature: "segmentation",
      needEditPermission: true,
      element: <CreateGroup />,
    },
    {
      path: "segment/:groupId",
      feature: "segmentation",
      element: <GroupDetail />,
    },
    {
      path: "segment",
      title: "Segmentation",
      feature: "segmentation",
      ecosystems: ["EVM"],
      element: <GroupList />,
    },
    // { remove temporary
    //   path: "report",
    //   title: "Customer Report",
    //   ecosystems: ["EVM"],
    //   feature: "customerReport",
    //   children: [
    //     {
    //       index: true,
    //       path: "",
    //       ecosystems: ["EVM"],
    //       element: <CustomerReport />,
    //     },
    //     {
    //       path: ":reportId",
    //       ecosystems: ["EVM"],
    //       feature: "customerReport",
    //       element: <CustomerReportDetail />,
    //     },
    //     {
    //       path: "general-report",
    //       ecosystems: ["EVM"],
    //       feature: "customerReport",
    //       element: <GeneralReport />,
    //     },
    //     {
    //       path: "general-report/:reportId",
    //       ecosystems: ["EVM"],
    //       feature: "customerReport",
    //       element: <GeneralReport />,
    //     },
    //   ],
    // },
  ],
};
const settingsConfig = {
  path: "settings",
  title: "Settings",
  icon: <i className="meta-crm-icon-ic_settingOutline font-size-16" />,
  element: <MainMenu />,
  children: [
    {
      path: "service-module",
      title: "Service",
      isMenu: true,
      upgrade: {
        "modules.service": true,
      },
      children: [
        {
          title: "Ticket",
          icon: <i className="meta-crm-icon-ic_ticket font-size-16" />,
          path: "ticket",
          feature: "customization",
          element: <TicketType />,
        },
        {
          path: "ai-management",
          title: "AI Management",
          icon: <i className="meta-crm-icon-ic_aiRound font-size-16" />,
          feature: "aiSupport",
          upgrade: {
            "serviceModule.aiSupport.enabled": true,
          },
          element: <AiManagement />,
        },
        {
          path: "macros",
          title: <FormattedMessage id="globol.module.macros" />,
          icon: <i className="meta-crm-icon-ic_macro font-size-16" />,
          feature: "macros",
          element: <Macros />,
        },
        {
          path: "related",
          title: "Related Components",
          isMenu: true,
          feature: "customization",
          icon: (
            <i className="meta-crm-icon-ic_relatedComponents font-size-16" />
          ),
          children: [
            {
              index: true,
              path: "widget",
              title: "MetaCRM Widget",
              icon: (
                <i className="meta-crm-icon-ic_widgetSetting font-size-16" />
              ),
              element: <CustomServiceWidgetNew />,
            },

            {
              path: "support-center",
              title: "Support Center",
              icon: (
                <i className="meta-crm-icon-ic_supportCenter font-size-12" />
              ),
              element: <InterfaceTheme />,
            },
            {
              path: "discord",
              element: <DiscordBot />,

              title: "Discord Bot",
              icon: <i className="meta-crm-icon-ic_discord font-size-16" />,
            },
          ],
        },
      ],
    },
    {
      path: "marketing-module",
      title: "Marketing",
      isMenu: true,
      upgrade: {
        "modules.marketing": true,
      },
      children: [
        //for route
        {
          path: "related",
          isMenu: true,
          title: "Related Components",
          icon: (
            <i className="meta-crm-icon-ic_relatedComponents font-size-16" />
          ),
          feature: "customization",
          children: [
            {
              title: "MetaCRM Widget",
              path: "widget",
              icon: (
                <i className="meta-crm-icon-ic_widgetSetting font-size-16" />
              ),
              path: "widget",
              feature: "customization",
              element: <CustomServiceWidgetNew />,
            },
          ],
        },
        {
          path: "widgetSetting/:widgetId",
          feature: "customization",
          element: <WidgetSetting />,
        },
      ],
    },
    {
      path: "general",
      title: "General Settings",
      isMenu: true,
      children: [
        //for route
        {
          path: "app/update-list",
          feature: "integration",
          handle: { hideMenu: true },
          element: <AppUpdateList />,
        },
        {
          title: "Company Profile",
          icon: <i className="meta-crm-icon-ic_people font-size-16" />,
          path: "company/profile",
          feature: "profileCompany",
          element: <CompanyProfile />,
        },

        {
          path: "integration",
          feature: "integration",
          title: <FormattedMessage id="integrationSetting.integration.title" />,
          icon: <i className="meta-crm-icon-ic_integration font-size-16" />,
          children: [
            {
              path: "",
              index: true,
              feature: "integration",
              needEditPermission: true,
              element: <Integration />,
            },
            {
              path: "discordWebhook",
              feature: "integration",
              element: <IntegrationDiscordWebhook />,
            },

            {
              path: "discord",
              feature: "integration",
              handle: { hideMenu: true },
              element: <IntegrationDiscord />,
            },
            {
              path: "slack",
              feature: "integration",
              handle: { hideMenu: true },
              upgrade: { "modules.service": true },
              element: <IntegrationSlack />,
            },
            {
              path: "mailchimp",
              handle: { hideMenu: true },
              feature: "integration",
              element: <IntegrationMailchimp />,
            },

            {
              path: "github",
              feature: "integration",
              element: <IntegrationGithub />,
            },
            {
              path: "update",
              children: [
                {
                  path: "mailchimp",
                  feature: "integration",
                  element: <IntegrationMailchimp isSetting={true} />,
                },
                {
                  path: "xmtp",
                  feature: "integration",
                  upgrade: {
                    "modules.marketing": true,
                    "marketingModule.xmtp.enabled": true,
                  },
                  element: <IntegrationXmtp />,
                },
                {
                  path: "push",
                  feature: "integration",
                  upgrade: {
                    "modules.marketing": true,
                    "marketingModule.push.enabled": true,
                  },
                  element: <IntegrationPush />,
                },
                {
                  path: "discordWebhook",
                  feature: "integration",
                  upgrade: {
                    "modules.service": true,
                  },
                  element: <IntegrationDiscordWebhook />,
                },
                {
                  path: "discord",
                  feature: "integration",
                  upgrade: {
                    "modules.service": true,
                  },
                  element: <IntegrationDiscord isSetting={true} />,
                },
                {
                  path: "slack",
                  feature: "integration",
                  upgrade: {
                    "modules.service": true,
                  },
                  element: <IntegrationSlack isSetting={true} />,
                },
                {
                  path: "attribution",
                  feature: "integration",
                  upgrade: {
                    "modules.marketing": true,
                    "marketingModule.attribution.enabled": true,
                  },
                  element: <IntegrationAttributionLink isSetting={true} />,
                },
                {
                  path: "widget",
                  feature: "integration",
                  element: (
                    <IntegrationWidgetSettingContainer isSetting={true} />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: "On-Chain Data Source",
          ecosystems: ["EVM", "Manta"],
          icon: <i className="meta-crm-icon-ic_data font-size-16" />,
          path: "onchain-data",
          feature: "customization",
          element: <OnChainDataSource />,
        },
        {
          path: "organization",
          title: (
            <FormattedMessage id="organizationSetting.organization.title" />
          ),
          feature: "organization",
          icon: <i className="meta-crm-icon-ic_organization font-size-16" />,
          children: [
            {
              index: true,
              path: "",
              feature: "organization",
              element: <Organization />,
            },
            {
              path: "create",
              feature: "organization",
              needEditPermission: true,
              element: <OrganizationCreation />,
            },
            {
              path: "create/:id",
              feature: "organization",
              needEditPermission: true,
              element: <OrganizationCreation />,
            },
          ],
        },
        {
          title: <FormattedMessage id="billingSetting.billing.title" />,
          icon: <i className="meta-crm-icon-ic_wallet font-size-16" />,
          feature: "integration",
          allowedEntities: envConfig.allowedEntities.billing,
          path: "billing",
          children: [
            {
              index: true,
              path: "",
              feature: "integration",
              element: <Billing />,
            },
            {
              path: "manage-plan",
              feature: "integration",
              element: <ManagePlan />,
            },
          ],
        },
        {
          path: "security",
          title: <FormattedMessage id="securitySetting.security.title" />,
          type: "security",
          icon: <i className="meta-crm-icon-ic_safe font-size-16" />,
          feature: "security",
          element: <Security />,
        },
      ],
    },
    {
      path: "customization",
      children: [
        {
          path: "support-center",
          feature: "customization",
          handle: { hideMenu: true },
          element: <SettingSupportCenter />,
        },
        {
          path: "widgetSetting/:widgetId",
          feature: "customization",
          handle: { hideMenu: true },
          element: <WidgetSetting />,
        },
      ],
    },
  ],
};

const accountConfig = {
  path: "account",
  title: "Account",
  icon: (
    <img
      src={require("assets/img/roles_ic/ic_alpaca-1.svg").default}
      style={{ width: 16 }}
    />
  ),
  element: <MainMenu />,
  children: [
    {
      path: "profile",
      title: "Profile",
      icon: <i className="meta-crm-icon-ic_profile font-size-16" />,
      element: <Profile />,
    },
  ],
};
export const routeConfig = {
  service: serviceConfig,
  marketing: marketingConfig,
  analytics: analyticsConfig,
  advertise: advertiseConfig,
  divider: { children: [{}] },
  customers: customersConfig,
  settings: settingsConfig,
  account: accountConfig,
};

export const createRouteConfig = () => {
  const appChildren = [
    {
      path: "error",
      element: <NotFound />,
    },
    {
      path: "",
      element: <MainMenu />,
      children: [
        {
          index: true,
          element: <PermissionControl defaultPage></PermissionControl>,
        },
      ],
    },
  ];
  for (let module of _.values(routeConfig)) {
    appChildren.push(transformConfig(module));
  }

  const routes = [
    {
      path: "callback",
      element: <ServerCallback />,
    },
    {
      path: isCustomDomain ? "/" : "/:entity",
      element: <AppContainer />,
      errorElement: <NotFound />,
      children: appChildren,
    },
    {
      path: "/maintenance ",
      element: <UnderMaintenance />,
    },
    {
      path: "/",
      errorElement: <NotFound />,
      element: <Landing />,
    },

    {
      path: "/trial",
      element: <Trial />,
      children: [
        {
          path: "marketing",
          element: <TrialMarketing />,
        },
        {
          path: "service",
          element: <TrialService />,
        },
      ],
    },
    {
      path: "/connectDiscord",
      element: <IntegrationConnectDiscord />,
    },
    {
      path: "/connectSlack",
      element: <IntegrationConnectSlack />,
    },
    {
      path: "/onboarding",
      element: <Onboarding />,
    },
    {
      path: "/workspace",
      children: [
        {
          path: "",
          element: <WorkspaceView />,
        },
        {
          path: "login",
          element: <WorkspaceLogin />,
        },
      ],
    },
  ];

  return routes;
};
const RouteComponent = () => {
  const router = createBrowserRouter(createRouteConfig());

  return <RouterProvider router={router} />;
};

export default React.memo(RouteComponent);

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Tooltip from "@mui/material/Tooltip";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
export const StyledTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})((props) => {
  return {
    color: props.color || "#555",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "20px",
  };
});

export const StyledMenuItem = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})((props) => {
  return {
    color: props.color || "#555",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "20px",
  };
});

export const StyledLabel = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})((props) => {
  return {
    padding: "2px 4px",
    fontSize: "12px",
    fontWeight: 400,
    borderRadius: "34px",
    marginLeft: "10px",
    color: props.color || "#7B61FF",
    backgroundColor: props.backgroundColor || "#F7F1FE",
  };
});

export const StyledCard = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})((props) => {
  return {
    padding: "32px",
    boxShadow: "0px 0px 0px",
    border: 0,
    background: "#FFFFFF",
    margin: "10px 0",
    borderRadius: 7,
  };
});

export const StyledSubtitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    fontSize: "16px",
    fontWeight: 500,
  };
});

export const StyledIconToolTips = styled(IconTooltip, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    marginLeft: 4,
    color: "#A5A5A5",
  };
});

export const StyledMemo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[600],
    fontSize: "12px",
    fontWeight: 400,
  };
});

export const StyledColorLabel = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background }) => {
  return {
    background,
    width: "12px",
    height: 8,
    borderRadius: 3,
    marginRight: 8,
  };
});
export const StyledTokenPopover = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    padding: 12,
    color: "#5",
    fontWeight: 500,
    width: 280,
    fontSize: 16,
  };
});
export const StyledSecondaryValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.purple[500],
    fontSize: "18px",
    fontWeight: 500,
    marginTop: 12,
  };
});

export const StyledSecondarySmallValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.purple[500],
    fontSize: "16px",
    fontWeight: 500,
    margin: "8px 0",
  };
});

export const StyledSubValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[800],
    fontSize: "14px",
    fontWeight: 500,
  };
});

export const StyledValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    fontSize: "36px",
    fontWeight: 500,
    marginBottom: "32px",
  };
});

export const StyledDropdown = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, open }) => {
  return {
    cursor: "pointer",
    background: "#FFFFFF",
    padding: 8,
    fontSize: 14,
    fontWeight: "500",
    paddingRight: 4,
    height: 32,
    borderRadius: 3,
    color: "#383538",
    display: "inline-flex",
    alignItems: "center",
    border: open ? "1px solid #7B61FF" : "1px solid #E6E6E6",
  };
});

export const StyledTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ tooltip: "tooltip-content", popper: className }}
    />
  ),
  {
    shouldForwardProp: isStyledPropsValid,
  }
)(({ theme, withoutMaxWidth }) => ({
  '&[data-popper-placement*="top"]': {
    "& .MuiTooltip-tooltip.tooltip-content": {
      marginBottom: "0px",
      marginLeft: "20px",
    },
  },
  "& .tooltip-content": {
    margin: theme.spacing(1.5, 0),
    padding: "10px 12px",
    fontSize: theme.fontSize.TINY,
    color: theme.customColors.grey[700],
    backgroundColor: theme.customColors.white,
    borderRadius: "3px",
    maxWidth: "280px",
    fontWeight: "regular",
    border: `1px solid ${theme.customColors.grey[500]}`,
    ...(withoutMaxWidth && {
      maxWidth: "initial",
    }),
  },
}));

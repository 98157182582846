import React, { useState, useEffect } from "react";
import _ from "lodash";
import { StyledDropdown } from "features/metadesk/legacy/webpush/Notification.styles";
import { DateRange } from "react-date-range";
import { Box, Popover } from "@mui/material";
import {
  Button,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import moment from "moment";

export default function DateRangeSelect({
  value,
  onChange,
  onClose,
  custom,
  dropdown,
  disabled,
  prefix,
  sx = {},
  ...props
}) {
  const [pickerValue, setPickerValue] = useState(value);
  const { formatMessage } = useIntl();
  const [openDateRange, setOpenDateRange] = useState(null);

  useEffect(() => {
    if (!openDateRange && !_.isEqual(value, pickerValue)) {
      setPickerValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!openDateRange && onClose) onClose();
  }, [openDateRange, onClose]);

  const renderDisplay = (defaultValue) => {
    return value[0]?.startDate && value[0]?.endDate ? (
      <Box sx={{ whiteSpace: "nowrap" }}>
        {moment(value[0]?.startDate).format("DD MMM,YYYY") +
          " - " +
          moment(value[0]?.endDate).format("DD MMM,YYYY")}
      </Box>
    ) : (
      defaultValue || formatMessage({ id: "global.filterCriteria.time" })
    );
  };

  return (
    <Box style={{ marginRight: 8 }} sx={{ ...sx }}>
      {custom ? (
        <button
          style={{
            padding: 0,
            border: "none",
            borderRadius: "20px",
            background: "transparent",
          }}
          open={Boolean(openDateRange)}
          onClick={(e) => {
            setOpenDateRange(e.currentTarget);
          }}
          disabled={disabled}
        >
          {custom}
        </button>
      ) : dropdown ? (
        <StyledDropdown
          noBorder
          open={Boolean(openDateRange)}
          onClick={(e) => {
            setOpenDateRange(e.currentTarget);
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            {prefix
              ? prefix
              : formatMessage({
                  id: "global.filterCriteria.trackingPeriod",
                })}{" "}
            {renderDisplay(" - ")}
            <i
              className="meta-crm-icon-ic_arrowdown font-size-18"
              style={{ marginLeft: 10, color: "rgba(0, 0, 0, 0.54)" }}
            />
          </Box>
        </StyledDropdown>
      ) : (
        <Button
          open={Boolean(openDateRange)}
          onClick={(e) => {
            setOpenDateRange(e.currentTarget);
          }}
          variant={VARIANT.TEXT}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            width: "fit-content",
          }}
          startIcon={<i className="meta-crm-icon-ic_calendar font-size-18" />}
        >
          {renderDisplay()}
        </Button>
      )}
      <Popover
        open={Boolean(openDateRange)}
        anchorEl={openDateRange}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setOpenDateRange(null)}
      >
        <DateRange
          rangeColors={["#7B61FF"]}
          onChange={(item) => {
            setPickerValue([item.selection]);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={pickerValue}
          direction="horizontal"
          {...props}
        />
        <div style={{ textAlign: "right", margin: 8 }}>
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.OUTLINED}
            style={{ width: 150, marginRight: 8 }}
            onClick={() => {
              setOpenDateRange(false);
              setPickerValue(value);
            }}
          >
            {formatMessage({ id: "global.btn.cancel" })}
          </Button>
          <Button
            color={COLOR.SECONDARY}
            style={{ width: 150 }}
            onClick={() => {
              setOpenDateRange(false);
              const adjustedPickerValue = [
                {
                  ...pickerValue[0],
                  endDate: moment(pickerValue[0].endDate).endOf("day").toDate(),
                },
              ];
              onChange(adjustedPickerValue);
            }}
          >
            {formatMessage({ id: "global.btn.apply" })}
          </Button>
        </div>
      </Popover>
    </Box>
  );
}

DateRangeSelect.defaultProps = {};

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useFetchTeamMember } from "../redux/fetchTeamMember";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { PopperMenu } from "@metacrm/metacrm-material-ui/dist/PopperMenu";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getUserAvatarIcon } from "../utils";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: 22,
      height: 22,
      marginRight: 4,
      borderRadius: 40,
    },
  };
});
const Collabrators = ({ selectedMembers, editable, entityName, ticketId }) => {
  const { memberList } = useFetchTeamMember();
  const [openCollabrator, setOpenCollabrator] = useState(false);
  const [members, setMembers] = useState(selectedMembers);
  const anchorRef = useRef(null);
  const classes = useStyles();
  useEffect(async () => {
    if (!_.isEqual(members, selectedMembers)) {
      setMembers(selectedMembers);
    }
  }, [selectedMembers]);

  const dispatchSnackbar = useDispatchSnackbar();

  async function addMember(data) {
    const newTagTeam = _.xorBy(members, [data], "_id");
    setMembers(newTagTeam);
    try {
      await axios.post(`${apiUrl}/api/ticket/update/collaborator`, {
        entityName,
        ticketId,
        tagTeam: newTagTeam,
      });
    } catch (err) {
      dispatchSnackbar({ err });
    }
  }

  return (
    <>
      <div className="startRow">
        {_.map(members, (value) => {
          return (
            <IconTooltip
              content={value.name}
              icon={
                <img
                  src={require(`assets/img/${getUserAvatarIcon(value._id)}`)}
                  className={classes.avatar}
                  style={{ border: "1px solid #222", marginBottom: 4 }}
                />
              }
            />
          );
        })}
        {editable && (
          <IconButton
            size={18}
            style={{ color: "#A5A5A5" }}
            ref={anchorRef}
            onClick={() => {
              setOpenCollabrator(true);
            }}
          >
            <i className="meta-crm-icon-ic_plusCircle font-size-22" />
          </IconButton>
        )}
      </div>
      <PopperMenu
        paperStyle={{ maxHeight: 200 }}
        itemList={_.map(memberList, (data, i) => {
          const selected = !!_.find(members, { _id: data._id });
          return {
            children: (
              <div key={i} className="startRow">
                <CustomCheckbox
                  style={{ padding: "0px 8px 0px 0" }}
                  checked={selected}
                />
                <img
                  src={require(`assets/img/${getUserAvatarIcon(data._id)}`)}
                  className={"smIcon"}
                />
                {data.name || "No Name"}
              </div>
            ),
            func: (e) => addMember(data),
          };
        })}
        open={openCollabrator}
        onClose={() => {
          setOpenCollabrator(false);
        }}
        anchorEl={anchorRef.current}
      />
    </>
  );
};

export default Collabrators;

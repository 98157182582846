import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  WIDGET_CONFIG_FETCH_BEGIN,
  WIDGET_CONFIG_FETCH_SUCCESS,
  WIDGET_CONFIG_FETCH_FAILURE,
  WIDGET_CONFIG_UPDATE_SUCCESS,
  WIDGET_CONFIG_UPDATE_BEGIN,
  WIDGET_CONFIG_UPDATE_FAILURE,
} from "../constants";
import axios from "axios";
import { apiUrl } from "features/configure";
import { alertServerError } from "features/helpers/utils";
export function fetchWidgetConfig({ entityName }) {
  return async (dispatch) => {
    dispatch({
      type: WIDGET_CONFIG_FETCH_BEGIN,
    });
    try {
      let types = await axios.get(
        `${apiUrl}/api/manage/widgetConfig/${entityName}`
      );
      dispatch({
        type: WIDGET_CONFIG_FETCH_SUCCESS,
        data: types.data,
      });
      return types.data;
    } catch (err) {
      dispatch({
        type: WIDGET_CONFIG_FETCH_FAILURE,
      });
      dispatch(alertServerError(err));
      console.log(err);
    }
  };
}
export function updateWidgetConfig({ entityName, config, type }) {
  return (dispatch, getState) => {
    dispatch({
      type: WIDGET_CONFIG_UPDATE_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        if (type === "integration") {
          let types = await axios.post(
            `${apiUrl}/api/manage/widgetConfig/${entityName}`,
            {
              config,
            }
          );
        } else if (type === "customization") {
          let types = await axios.post(
            `${apiUrl}/api/manage/widgetConfig/${entityName}/customization`,
            {
              config,
            }
          );
        }

        dispatch({
          type: WIDGET_CONFIG_UPDATE_SUCCESS,
        });
        resolve();
      } catch (err) {
        dispatch({
          type: WIDGET_CONFIG_UPDATE_FAILURE,
        });
        dispatch(alertServerError(err));
        console.log(err);
      }
    });

    return promise;
  };
}

export function useFetchWidgetConfig() {
  const dispatch = useDispatch();

  const { widgetConfig, fetchWidgetConfigPending, updateWidgetConfigPending } =
    useSelector(
      (state) => ({
        widgetConfig: state.metadesk.widgetConfig,
        fetchWidgetConfigPending: state.metadesk.fetchWidgetConfigPending,
        updateWidgetConfigPending: state.metadesk.updateWidgetConfigPending,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchWidgetConfig(data));
    },
    [dispatch]
  );
  const updateAction = useCallback(
    (data) => {
      return dispatch(updateWidgetConfig(data));
    },
    [dispatch]
  );
  return {
    widgetConfig,
    fetchWidgetConfigPending,
    updateWidgetConfigPending,
    updateWidgetConfig: updateAction,
    fetchWidgetConfig: boundAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case WIDGET_CONFIG_FETCH_BEGIN:
      return {
        ...state,
        fetchWidgetConfigPending: true,
      };
    case WIDGET_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        fetchWidgetConfigPending: false,
        widgetConfig: action.data,
      };

    case WIDGET_CONFIG_FETCH_FAILURE:
      return {
        ...state,
        fetchWidgetConfigPending: false,
      };
    case WIDGET_CONFIG_UPDATE_BEGIN:
      return {
        ...state,
        updateWidgetConfigPending: true,
      };
    case WIDGET_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        updateWidgetConfigPending: false,
        widgetConfig: state.widgetConfig,
      };
    case WIDGET_CONFIG_UPDATE_FAILURE:
      return {
        ...state,
        updateWidgetConfigPending: false,
      };

    default:
      return state;
  }
}

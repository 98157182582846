import React, { useState, useEffect } from "react";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { Loading } from "features/common";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box, useTheme } from "@mui/material";
import { map, upperFirst } from "lodash-es";
import { useIntl } from "react-intl";
import useMarketModule from "hooks/useMarketModule";
import {
  Button,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { StyledColumnTitle } from "./Referral.styles";
import Back from "features/metadesk/components/Back";
import Address from "features/metadesk/components/Address";
import _ from "lodash";
import {
  alertServerError,
  getShortAddress,
  parseCustomDomainUrl,
} from "features/helpers/utils";
import { useOutletContext } from "react-router";
import { useDispatch } from "react-redux";
import { apiUrl } from "features/configure";
import axios from "axios";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { exportCSV, formatCSV } from "features/helpers/utils";

export default function ReferralDetail() {
  const { referralConnected } = useMarketModule();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState({});
  const [order, setOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("transactionReferred");
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const PAGE_COUNT = 20;
  const rowData = referralData?.data || [];
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      let query = {
        page,
        sortBy,
        order: order == "desc" ? -1 : 1,
      };
      const { data, headers } = await axios.get(
        `${apiUrl}/api/referral/${entityName}/program/detail`,
        { params: query }
      );
      setTotalCount(_.get(headers, "x-total-count", 0));
      setReferralData(data);
      return data;
    } catch (err) {
      console.log("err: ", err);
      dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (referralConnected) {
      await getAPIData();
    }
  }, [page, sortBy, order, referralConnected]);

  const headerContent = (content) => (
    <Box height="30px" my="8px" display="flex" alignItems="center">
      <Box
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          color: "#383538",
        }}
      >
        {content || "-"}
      </Box>
    </Box>
  );

  const headCells = [
    {
      show: true,
      disabledSort: true,
      id: "linkCreated",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.linkCreated.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.linkCreated.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.linkCreated"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "linkClick",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.linkClick.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.linkClick.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.linkClick"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "walletConnected",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.walletConnected.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.walletConnected.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.walletConnected"))}
        </Box>
      ),
    },
    {
      show: true,
      id: "transactionReferred",
      label: (
        <Box display="flex" flexDirection="column">
          <Box className="startRow">
            <StyledColumnTitle>
              {formatMessage({
                id: "referralAnalysis.columnName.transactionReferred.title",
              })}
            </StyledColumnTitle>

            <IconTooltip
              customColor="#7B61FF"
              content={formatMessage({
                id: "referralAnalysis.columnName.transactionReferred.tooltip",
              })}
            />
          </Box>
          {headerContent(_.get(referralData, "total.transactionReferred"))}
        </Box>
      ),
    },
  ];

  const renderContents = (row) => {
    const contents = [
      <Box slot="linkCreated" className="startRow">
        <img
          src={require(`assets/img/${getUserAvatarIcon(row.address, theme)}`)}
          className="icon"
        />
        {getShortAddress(row.address)}
      </Box>,
      <Box slot="linkClick" sx={{ maxWidth: "260px" }} className="wordBreak">
        {row.linkClick || "--"}
      </Box>,
      <Box slot="walletConnected"> {row.walletConnected || "--"}</Box>,
      <Box slot="transactionReferred">{row.transactionReferred || "--"}</Box>,
    ];
    return contents;
  };

  const exportToCSV = async () => {
    const exportResult = await axios.get(
      `${apiUrl}/api/referral/${entityName}/program/detail?dump=1`
    );
    exportCSV(exportResult.data, "MetaCRM_Referrals");
  };

  return (
    <Box position={"relative"}>
      <Back
        to={parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/marketing/referral/"
        )}
      />
      <Loading open={isLoading} fullScreen={false} white />
      <Box className="betweenRow" sx={{ marginBottom: "20px" }}>
        <Box className="title">
          {formatMessage({
            id: "referralAnalysis.header.title",
          })}
        </Box>
        <Button
          variant={VARIANT.OUTLINED}
          disabled={_.isEmpty(rowData)}
          onClick={exportToCSV}
        >
          {formatMessage({
            id: "global.btn.download",
          })}
        </Button>
      </Box>
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 260px)",
          overflow: "auto",
        }}
      >
        <CRMTable
          rowData={rowData}
          headers={headCells}
          contents={map(rowData, (user, i) => renderContents(user))}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={sortBy}
        />
      </div>

      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={totalCount}
        page={page}
        setPage={(v) => setPage(v)}
      />
    </Box>
  );
}

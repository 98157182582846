import React, { memo, useContext, useEffect, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import OnlyTabs from "@metacrm/metacrm-material-ui/dist/OnlyTabs";
import { Box } from "@material-ui/core";
import { findIndex } from "lodash";

function Header() {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    promotype,
    setParams,
    setIsLoading,
    isLoading,
  } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();

  const promotypeTabs = [
    {
      id: "widget",
      label: formatMessage({ id: "campaignPerformance.tab.widget.title" }),
    },
    {
      id: "flyer",
      label: formatMessage({ id: "campaignPerformance.tab.flyer.title" }),
    },
    {
      id: "tracker",
      label: formatMessage({ id: "campaignPerformance.tab.tracker.title" }),
    },
  ];
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(findIndex(promotypeTabs, { id: promotype }) || 0);
  }, [promotype]);

  const handleChange = (event, newValue) => {
    setIsLoading({ ...isLoading, switchTab: true });
    setValue(newValue);
    setParams({
      promotype: promotypeTabs[newValue]?.id,
      channel: "all",
    });
    setIsLoading({ ...isLoading, switchTab: false });
  };

  return (
    <>
      <div
        className="betweenRow"
        style={{ alignItems: "flex-start", marginBottom: "28.5px" }}
      >
        <div className={"title"}>
          {/* {formatMessage({ id: "global.taskStatus.analysis" })} */}
          Campaign Performance
        </div>
      </div>
      <div
        className="betweenRow"
        style={{ alignItems: "center", marginBottom: "8px" }}
      >
        <OnlyTabs tabs={promotypeTabs} value={value} onChange={handleChange} />
        <Box sx={{ width: "4px" }} />
        {/* <DateRangeSelect
          sx={{ marginLeft: "16px" }}
          shownDate={new Date()}
          defaultInputRanges={null}
          value={[
            {
              startDate,
              endDate,
              key: "selection",
            },
          ]}
          onChange={(value) => {
            setStartDate(value[0].startDate);
            setEndDate(value[0].endDate);
          }}
        /> */}
      </div>
    </>
  );
}

export default memo(Header);

import React, { useEffect, useState } from "react";
import NoWorkspace from "./NoWorkspace";
import HasWorkspace from "./HasWorkspace";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useAccount, useDisconnect } from "wagmi";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useOnboardingState } from "../OnboardingProvider";
import { Box } from "@mui/material";

export default function WorkSpace() {
  const { prevStep } = useOnboardingState();
  const [workSpace, setWorkSpace] = useState(false);
  const [loading, setLoading] = useState(true);
  const { address: evmAddress, isConnected } = useAccount({});

  useEffect(() => {
    async function getEntityList() {
      const { data } = await axios.get(`${apiUrl}/api/workspace`, {
        params: { address: evmAddress },
      });
      setLoading(false);
      setWorkSpace(data.entities);
    }
    getEntityList();
  }, [evmAddress]);

  const { disconnect } = useDisconnect();

  async function handleDisconnect() {
    await disconnect(); // 確保 disconnect 完成後再觸發 prevStep
    prevStep();
  }

  useEffect(() => {
    if (!isConnected) {
      // 當錢包斷開連接時，返回上一頁
      prevStep();
    }
  }, [isConnected, prevStep]);
  return (
    <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Box
            onClick={handleDisconnect}
            sx={{
              position: "absolute",
              top: 24,
              left: 24,
              zIndex: 10,
              color: "#A5A5A5",
              cursor: "pointer",
            }}
          >
            <i className="meta-crm-icon-ic_arrowBackward font-size-36" />
          </Box>

          {workSpace.length > 0 ? (
            <HasWorkspace workSpace={workSpace} />
          ) : (
            <NoWorkspace prevStep={prevStep} />
          )}
        </>
      )}
    </Box>
  );
}

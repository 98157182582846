import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import styled from "@emotion/styled/macro";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, buttonColor, readonly }) => ({
    display: "flex",
    flexWrap: "wrap",
    cursor: readonly ? "default" : "pointer",
  })
);

export const StyledToggleButton = styled(ToggleButton)(
  ({ theme, buttonColor, readonly }) => ({
    marginRight: "8px",
    border: `0px solid ${theme.customColors.grey[400]} !important`,
    borderRadius: "4px !important",
    padding: "4px 8px",
    color: theme.customColors.grey[700],
    textTransform: "none",
    cursor: readonly ? "default" : "pointer",
    "&:hover": {
      backgroundColor: theme.customColors.purple[300],
    },
    "& .MuiToggleButton-label": {
      fontWeight: 500,
      fontSize: 12,
      color: theme.customColors.grey[500],
    },

    "&.Mui-selected": {
      "&:hover": {
        backgroundColor: buttonColor || theme.customColors.purple[500],
        "& .MuiToggleButton-label": {
          color: theme.palette.white,
        },
      },
      "& .MuiToggleButton-label": {
        color: `${theme.customColors.white} !important`,
      },

      backgroundColor: buttonColor || theme.customColors.purple[500],
      border: `1px solid ${
        buttonColor || theme.customColors.purple[500]
      } !important`,
    },
  })
);

export default function CustomToggle({ value, onChange, data, fullWidth }) {
  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={onChange}
      style={{ textAlign: "right" }}
    >
      {data.map((button, index) => (
        <StyledToggleButton key={index} value={button.value}>
          {button.text}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}

CustomToggle.defaultProps = {};

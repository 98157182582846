import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AgentAnalytics } from "./AgentAnalytics";
import { TicketAnalytics } from "./TicketAnalytics";
import { useIntl } from "react-intl";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import CustomToggle, {
  StyledToggleButton,
} from "components/CustomToggle/CustomToggle";

const ServiceReportPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [range, setRange] = useState("all");
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (range == "custom") return;
    if (range == "all") {
      setStartDate("");
      setEndDate("");
      return;
    }
    const now = new Date();
    const start = new Date();
    setStartDate(new Date(start.setDate(start.getDate() - parseInt(range))));
    setEndDate(now);
  }, [range]);

  return (
    <Box>
      <div className="title">Ticket Distribution</div>

      <Box className="card" mb={"20px"}>
        <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
          <CustomToggle
            data={[
              { text: "1 Day", value: 1 },
              { text: "7 Days", value: 7 },
              { text: "30 Days", value: 30 },
              { text: "All", value: "all" },
            ]}
            value={range}
            onChange={(e, newValue) => {
              setRange(newValue);
            }}
          />
          <DateRangeSelect
            value={[
              {
                startDate,
                endDate,
                key: "selection",
              },
            ]}
            custom={
              <StyledToggleButton
                className={range == "custom" ? "Mui-selected" : ""}
              >
                Custom
              </StyledToggleButton>
            }
            onChange={(value) => {
              setRange("custom");
              setStartDate(value[0]?.startDate || undefined);
              setEndDate(value[0]?.endDate || undefined);
            }}
          />
        </Box>

        <TicketAnalytics startDate={startDate} endDate={endDate} />
      </Box>

      <AgentAnalytics startDate={startDate} endDate={endDate} />
    </Box>
  );
};

export default ServiceReportPage;

import { useMediaQuery } from "@material-ui/core";
import { Box } from "@mui/material";
import { useConnectWallet } from "features/home/redux/connectWallet";
import React, { useState, useEffect } from "react";
import { useAccount, useConnect } from "wagmi";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { StyledLoginCard, StyledWarningLink } from "./Onboarding.styles";
import { useOnboardingState } from "./OnboardingProvider";

export default function Login() {
  const { nextStep } = useOnboardingState();
  const connectorsImg = {
    metaMask: require("assets/img/MetaMask.png"),
    injected: require("assets/img/BrowserWallet.png"),
    walletConnect: require("assets/img/WalletConnect.svg").default,
  };

  const {
    address: evmAddress,
    isConnected: evmConnected,
    isConnecting: evmConnecting,
  } = useAccount({});

  useEffect(() => {
    if (evmAddress && evmConnected) {
      nextStep();
    }
  }, [evmAddress, evmConnected]);

  const { connect, connectors } = useConnect();
  const theme = useTheme();

  const [currentConnectors, setCurrentConnectors] = useState([]);
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let filteredConnectors = connectors;

    if (!computer) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name !== "MetaMask";
      });
      //mobile version (only show injected metamask)
    }
    if (!window?.ethereum) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name == "WalletConnect";
      });
    }

    // 排序以確保順序為 MetaMask, Browser Wallet, WalletConnect
    const orderedConnectors = _.sortBy(filteredConnectors, (connector) => {
      return ["MetaMask", "Browser Wallet", "WalletConnect"].indexOf(
        connector.name
      );
    });

    setCurrentConnectors(orderedConnectors);
  }, [computer, connectors, window]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        pt: "125px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FAFAFD",
      }}
    >
      <img src={require("assets/img/onboardingLogo.png")} />
      <Box color="#383538" fontSize="24px" fontWeight={700}>
        Hello! Welcome to MetaCRM.
      </Box>
      <Box color="#777" fontSize="14px" mb="32px" mt={"4px"}>
        Log in or sign up by connecting your wallet.
      </Box>

      {currentConnectors.map((connector, i) => (
        <StyledLoginCard
          key={connector.id}
          onClick={async () => {
            connect({ connector });
          }}
        >
          <img
            src={connectorsImg[connector.id]}
            style={{ width: 36, height: 36 }}
          />
          <Box ml="12px">{connector.name}</Box>
        </StyledLoginCard>
      ))}
      <Box color={"#777"} fontSize="12px" display="flex" gap="2px">
        <span>By Connecting wallet, I agree to the</span>
        <StyledWarningLink
          href="https://metacrm.inc/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </StyledWarningLink>
        &{"  "}
        <StyledWarningLink
          href="https://metacrm.inc/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </StyledWarningLink>
      </Box>
    </Box>
  );
}

import React from "react";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "features/common/redux/actions";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
const CodePannel = ({ code }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        style={{ position: "absolute", right: "10px", top: "10px" }}
        onClick={() => copyToClipboard(code)}
      >
        <i className="meta-crm-icon-ic_copy font-size-24" />
      </IconButton>
      <SyntaxHighlighter
        language="javascript"
        customStyle={{ borderRadius: "4px" }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};
export default CodePannel;

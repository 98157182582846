import React, { useState, useRef, useContext, createContext } from "react";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";

// Create Context
const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

// options 範例
// options = {
//   noNeedConfirm:false,
//   title: "",
//   content: "",
//   confirmText: "",
//   cancelText: "",
//   color: "secondary"
// };

export const ConfirmDialogProvider = ({ children }) => {
  const [options, setOptions] = useState(null);
  const awaitingPromiseRef = useRef(null);

  const confirm = (options) => {
    if (options.noNeedConfirm) {
      return Promise.resolve();
    }
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOptions(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    if (options.onConfirm) {
      options.onConfirm();
    }
    setOptions(null);
  };

  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}

        <BasicDialog
          dialogRootStyle={{ maxWidth: "368px", textAlign: "center" }}
          open={Boolean(options)}
          dialogTitle={options && options.title ? options.title : ""}
          dialogContent={options && options.content ? options.content : ""}
          confirmText={
            options && options.confirmText ? options.confirmText : ""
          }
          cancelText={options && options.cancelText ? options.cancelText : ""}
          onClose={handleClose}
          onHandleConfirm={handleConfirm}
          color={options && (options.color || "error")}
        />
      </ConfirmContext.Provider>
    </>
  );
};

export default ConfirmDialogProvider;

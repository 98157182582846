import { getShortAddress } from "features/helpers/utils";
import EntityItem from "../components/EntityItem";
import {
  UserAddress,
  EntityListContainer,
  ListWrapper,
} from "./EntityList.styled";

export default function EntityList({ memberAddress, entityList }) {
  const walletAddress = getShortAddress(memberAddress);

  if (!entityList.length) return false;

  return (
    <EntityListContainer>
      <UserAddress>{walletAddress}'s Workspace</UserAddress>
      <ListWrapper>
        {entityList.map((entity, i) => (
          <EntityItem key={i} entity={entity} />
        ))}
      </ListWrapper>
    </EntityListContainer>
  );
}

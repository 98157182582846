import React, { useState } from "react";
import { useTheme } from "@mui/styles";

import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import Box from "@mui/material/Box";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";
import { get } from "lodash-es";

import { useDisconnect } from "wagmi";
import { useForm } from "react-hook-form";
import RegisterStep1 from "./RegisterStep1/RegisterStep1";
import RegisterStep2 from "./RegisterStep2/RegisterStep2";
import StepProvider from "contexts/stepContext/Step.context";

import {
  StyledRegisterContainer,
  StyledRegisterSwitchWalletRow,
  StyledAlreadyAUser,
  StyledRegisterMainContent,
  StyledMetaDeskTitle,
  StyledMetaDeskTitleImage,
  StyledRegisterPaperContainer,
  StyledRegisterStepTitle,
  StyledConnectWalletContainer,
  StyledRegisterConnectWallet,
  StyledHelpHint,
  StyledLink,
  StyledBackButton,
} from "./RegisterForm.styles";

const formDataInit = {
  name: "",
  email: "",
  companyName: "",
};

const RegisterForm = ({ module }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const nextFormStep = () => setStep((currentStep) => currentStep + 1);
  const prevFormStep = () => setStep((currentStep) => currentStep - 1);

  const { disconnect } = useDisconnect();

  return (
    <>
      <StyledRegisterContainer>
        <StyledRegisterSwitchWalletRow>
          <StyledAlreadyAUser>
            Already a user?
            <Button
              sx={{ marginLeft: "5px" }}
              variant={VARIANT.TEXT}
              onClick={() => {
                disconnect();
              }}
            >
              Switch wallet
            </Button>
          </StyledAlreadyAUser>
        </StyledRegisterSwitchWalletRow>
        <StyledRegisterMainContent>
          <Box>
            <StyledMetaDeskTitleImage>
              {step !== 0 && (
                <StyledBackButton onClick={prevFormStep}>
                  <Box mr="5px">Back</Box>{" "}
                  <i className="meta-crm-icon-ic_redo font-size-16" />
                </StyledBackButton>
              )}

              <IconIcMetaCRM width={150}></IconIcMetaCRM>
            </StyledMetaDeskTitleImage>

            <StyledRegisterPaperContainer step={step}>
              <StepProvider>
                {step === 0 && (
                  <RegisterStep1
                    module={module}
                    onNextStep={nextFormStep}
                    onPrevStep={prevFormStep}
                  />
                )}
                {step === 1 && (
                  <RegisterStep2
                    module={module}
                    onNextStep={nextFormStep}
                    onPrevStep={prevFormStep}
                  />
                )}
              </StepProvider>
            </StyledRegisterPaperContainer>
            <StyledHelpHint style={{ color: theme.customColors.grey[700] }}>
              If you need any help, please
              <StyledLink
                target="_blank"
                to="mailto: bd@metacrm.inc"
                style={{ textDecoration: "underline" }}
              >
                contact us.
              </StyledLink>
            </StyledHelpHint>
          </Box>
        </StyledRegisterMainContent>
      </StyledRegisterContainer>
    </>
  );
};

export default RegisterForm;

import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Drawer, Tabs, IconButton, Hidden } from "@material-ui/core";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";

const useStyles = (props) =>
  makeStyles((theme) => {
    return {
      drawer: {
        [theme.breakpoints.up("md")]: {
          width: props.drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [theme.breakpoints.up("lg")]: {
          width: `calc(100% - ${props.drawerWidth}px)`,
          marginLeft: props.drawerWidth,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("lg")]: {
          display: "none",
        },
      },
      drawerPaper: {
        width: props.drawerWidth,
        borderRight: "0.5px solid #D8D8D8",
        fontSize: 13,
      },
      toolbar: theme.mixins.toolbar,
    };
  });
export default function SideMenu({
  content,
  anchor = "left",
  drawerWidth = 200,
}) {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  const [leftOpen, setLeftOpen] = React.useState(false);

  const leftToggle = () => {
    setLeftOpen(!leftOpen);
  };
  const classes = useStyles({ drawerWidth, computer })();

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <IconButton
          onClick={leftToggle}
          style={{
            position: "fixed",
            zIndex: 1000,
            [anchor]: "0",
            top: "50%",
            color: "white",
            background: "#383538",
          }}
        >
          <MenuOpenIcon />
        </IconButton>
        <Drawer
          container={container}
          variant="temporary"
          anchor={anchor}
          open={leftOpen}
          onClose={leftToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={anchor}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {content}
        </Drawer>
      </Hidden>
    </nav>
  );
}

import { Box } from "@mui/material";
import React from "react";
import ReactECharts from "echarts-for-react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { rainbowColors, thirtyColors } from "utils/constants";

// 註解一下
// Bar Chart Component
// props: data - array - required - the data to be displayed in the circle chart.

export default function BehaviorBarChart({
  data,
  legendSx,
  singleColor,
  chartName,
  title,
  type = "number", // type 先分成純數字與百分比兩種，後續再增加更多種類，最底下再多改他要顯示的樣式
  filter,
  loading = false,
  onClick = null,
  labelRotate = 45,
}) {
  const colors = singleColor
    ? Array.from({ length: 10 }).fill(singleColor)
    : data.length > 10
    ? thirtyColors
    : rainbowColors;
  const option = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        const tooltipData = params[0];
        return `${tooltipData.name}: ${
          type === "number"
            ? parseInt(tooltipData.value).toLocaleString()
            : tooltipData.value + "%"
        }`;
      },
    },
    xAxis: {
      show: true,
      type: "category",
      data: data.map((item) => item.name),
      axisLabel: {
        rotate: labelRotate,
        formatter: (value) =>
          value.length > 9 ? value.slice(0, 6) + "..." : value,
        margin: 12,
      },
    },
    yAxis: {
      show: true,
      type: "value",
    },
    series: [
      {
        data: data.map((item, index) => ({
          value: item.value,
          itemStyle: { color: colors[index] },
        })),
        type: "bar",
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%", // Adjust the width as needed
        height: "250px", // Adjust the height as needed
        backgroundColor: "#FFF",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Box
            fontSize="14px"
            fontWeight="bold"
            mb="24px"
            sx={{
              position: "absolute",
              top: "22px",
              left: "16px",
              color: "#555555",
              fontSize: "16px",
              fontWeight: 500,
              ...legendSx,
            }}
          >
            {chartName}
          </Box>
          <Box
            sx={{
              width: "50%",
              height: "100%",
              flexShrink: 0,
            }}
          >
            <ReactECharts
              option={option}
              autoResize={true}
              style={{ height: "100%", width: "100%" }}
              notMerge={true}
            />
          </Box>
          <Box
            sx={{
              width: "50%",
              color: "#777",
              pl: "40px",
              borderLeft: "1px solid #E6E6E6",
            }}
          >
            {title && (
              <Box fontSize={12} mb="12px" color="#777">
                {title}
              </Box>
            )}
            <Grid container>
              {data?.slice(0, 10).map((item, index) => (
                <Grid
                  xs={6}
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "12px",
                  }}
                >
                  {!singleColor && (
                    <Box
                      sx={{
                        height: "8px",
                        width: "13px",
                        borderRadius: "2px",
                        backgroundColor: colors[index],
                        mb: "1px",
                        mr: "8px",
                      }}
                    />
                  )}
                  <Box
                    key={index}
                    sx={{
                      fontSize: "12px",
                      color: "#383538",
                      cursor: onClick ? "pointer" : "default",
                    }}
                    onClick={() => onClick(item.name, item.value)}
                  >
                    {item.name}：
                    {type === "number"
                      ? parseInt(item.value).toLocaleString()
                      : item.value + "%"}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

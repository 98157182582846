import React from "react";
import { Box } from "@material-ui/core";
import { useIntl } from "react-intl";
import { BasicDialog } from "@metacrm/metacrm-material-ui/dist/Dialog";
import { StyledModalTableRow } from "features/metadesk/campaign/campaignList/component/TrackingModal.styles";
import moment from "moment";
import { centerTruncate, getTransactionUrl } from "features/helpers/utils";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { objectiveMappingName } from "features/metadesk/campaign/createCampaign/component/Objective";

const CountModal = ({ open, onClose, data }) => {
  const { formatMessage } = useIntl();
  const ecosystemName = useSelector(selectEcosystemName);
  const renderDialogContent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #e6e6e6",
          padding: "12px 17px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%", overflowY: "auto" }}>
          <StyledModalTableRow>
            <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
              Objective
            </div>
            <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
              Value
            </div>
            <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
              Transaction hashs
            </div>
            <div style={{ width: "25%", textAlign: "left", fontWeight: 500 }}>
              Timestamp
            </div>
          </StyledModalTableRow>
          <Box sx={{ overflowY: "auto" }}>
            {data?.map((item, index) => {
              const { chainId, hash, objective, timestamp, value } = item;
              const valueStr = value === "Unknown" || !value ? "-" : value;
              return (
                <StyledModalTableRow key={hash + index}>
                  <Box sx={{ width: "25%" }}>
                    {objectiveMappingName[objective]}
                  </Box>
                  <Box sx={{ width: "25%" }}>{valueStr}</Box>
                  <Box sx={{ width: "25%" }}>
                    <a
                      href={getTransactionUrl(ecosystemName, chainId, hash)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#7B61FF", textDecoration: "underline" }}
                    >
                      {centerTruncate(hash)}
                    </a>
                  </Box>
                  <Box sx={{ width: "25%" }}>
                    {moment(timestamp).format("MMM DD,YYYY HH:mmA")}
                  </Box>
                </StyledModalTableRow>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      dialogRootStyle={{ maxWidth: "800px", overflow: "hidden" }}
    >
      <Box
        padding={"24px 40px 48px"}
        flexDirection={"column"}
        sx={{ overflow: "hidden", flex: 1, display: "flex", width: "800px" }}
        maxWidth={"800px"}
      >
        {renderDialogContent()}
      </Box>
    </BasicDialog>
  );
};

export default CountModal;

import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const useStyles = makeStyles((theme) => ({
  field: {
    color: "white",
    backgroundColor: "transparent",
    overflow: "visible",
    position: "relative",
    padding: 10,
  },
  inputRoot: {
    color: "white",
  },
  showDetail: {
    justifyContent: "space-around",

    width: "100%",
    color: "#383538",
    borderBottom: `1px solid #141314`,
    backgroundColor: "transparent",
    "& .MuiInputBase-root": {
      color: "#383538",
      fontSize: 14,
      FontFamily: "Inter",
      fontWeight: 500,
    },
    "& .MuiInputBase-input": {
      padding: "7px 0",
    },
    "& .MuiInputBase-input": {
      padding: "6px 0px",
    },
    "& fieldset": { color: "#383538" },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: "#383538",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  center: {
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  option: {
    marginTop: 8,
    "& .MuiInputBase-root": {
      color: "#383538",
    },
  },

  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
  number: {
    background: "#FFFFFF",
    borderRadius: 5,
    border: "1px solid #A5A5A5",
    padding: "5px 10px",
  },
  noLine: {
    border: "0px",
  },
  outline: {
    border: "1px solid #DADADA",
    borderRadius: 5,
    padding: "5px 10px",
    background: "#FFFFFF",
  },
  error: {
    background: "#FFDED7",
    border: "1px solid #FC5555",
  },
  sm: {
    padding: "0px 5px",
    "& .MuiInputBase-root": {
      fontSize: 13,
    },
  },
  bold: {
    "& .MuiInputBase-root": {
      fontSize: 15,
      fontWeight: 500,
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={true}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const {
    classes,
    disabled,
    outline,
    value,
    placeholder,
    center,
    option,
    onChange,
    startAdornment,
    sm,
    bold,
    endAdornment,
    onBlur,
    noLine,
    number,
    max,
    error,
    ...rest
  } = props;
  const commonStyle = useStyles();
  const commonClasses = {
    root: `${commonStyle.showDetail} ${center && commonStyle.center} ${
      sm && commonStyle.sm
    } ${bold && commonStyle.bold} ${option && commonStyle.option} ${
      number && commonStyle.number
    } ${noLine && commonStyle.noLine} ${outline && commonStyle.outline} ${
      error && commonStyle.error
    }`,
    disabled: commonStyle.disabled,
  };

  if (disabled) {
    return (
      <div style={{ textAlign: center ? "center" : "left" }}>
        <div
          style={{
            fontSize: sm ? 14 : 18,
            paddingLeft: 5,
            paddingTop: 5,
            fontWeight: 500,
            color: option ? "#383538" : "#383538",
          }}>
          {value}
        </div>
      </div>
    );
  }
  let inputProps = {
    startAdornment: props.startAdornment,
    disableUnderline: true,
  };
  if (startAdornment) inputProps["startAdornment"] = startAdornment;
  if (endAdornment)
    inputProps["endAdornment"] = (
      <InputAdornment position="end" className={commonStyle.inputAdornment}>
        {endAdornment}
      </InputAdornment>
    );
  if (number) inputProps["inputComponent"] = NumberFormatCustom;
  // if (multiline) inputProps["inputComponent"] = AutoSizeCustom;
  return (
    <div className={commonStyle.field}>
      <TextField
        {...props}
        // type={number ? "number" : ""}
        placeholder={placeholder || ""}
        classes={Object.assign({}, commonClasses, classes)}
        onBlur={(e) => {
          onChange({ target: { value: _.trim(e.target.value) } });
          onBlur && onBlur();
        }}
        color="secondary"
        InputProps={inputProps}
        {...rest}
      />
      {max && (
        <span style={{ position: "absolute", right: 0, color: "#383538" }}>
          {_.get(value, "length", 0)}/{max}
        </span>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};

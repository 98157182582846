import React, { memo } from "react";

import TableCell from "@material-ui/core/TableCell";

import _ from "lodash";
import { StyledTableCell } from "./CustomTable.styles";

const CustomTableCell = ({
  rowData,
  noBorderLeft,
  isSelected,
  style,
  valueClass,
  classes,
  headers,
  rows,
  rowIndex,
  lastFixedItem = null,
  calculateLeftDistance = () => {},
}) => {
  const renderChild = (headerItem, rowData) => {
    const Child = rowData.find(
      (cellItem) => cellItem.props.slot === headerItem.id
    );
    if (Child) {
      return Child;
    }
  };
  const firstFlowItemIndex = _.findIndex(headers, function (o) {
    return !o.fixed && o.show;
  });

  return (
    <>
      {_.map(headers, (headerItem, rIndex) => {
        if (!_.get(headerItem, "show", false)) {
          return null;
        }
        const cellWidth = headerItem.width;

        const leftDistance = calculateLeftDistance(rIndex, headers);
        return (
          <StyledTableCell
            isFixed={headerItem.fixed}
            lastFixedItem={lastFixedItem}
            leftDistance={leftDistance}
            key={rIndex}
            index={rIndex}
            rowIndex={rowIndex}
            rows={rows}
            cellWidth={cellWidth}
            headers={_.filter(headers, { show: true })}
            align={_.get(headerItem, "centerText", false) ? "center" : "left"}
            className={`${classes.value} ${valueClass} ${
              isSelected ? classes.selected : ""
            }`}
            style={{
              ...style,
            }}
          >
            {/* {rIndex !== 0 &&
              noBorderLeft[rIndex] !== true &&
              firstFlowItemIndex != rIndex && (
                <div
                  className="absoluteBorderLeft"
                  style={{ left: 2, height: "50%", top: "25%" }}
                />
              )} */}
            {renderChild(headerItem, rowData)}
          </StyledTableCell>
        );
      })}
    </>
  );
};

export default memo(CustomTableCell);

import React from "react";

import { Link, Navigate, useHistory } from "react-router-dom";
import _ from "lodash";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import { Outlet, useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { selectTrialEntityName } from "features/metadesk/redux/trial/trial.selector";
import { useDispatch, useSelector } from "react-redux";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { InterceptorContextProvider } from "features/home/InterceptorContext";

const Trial = () => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const { isConnected } = useAccount({});
  const entityName = useSelector(selectTrialEntityName);

  if (isCustomDomain) {
    return (
      <Navigate
        to={parseCustomDomainUrl(isCustomDomain, entityName, "/")}
        replace
      />
    );
  }

  return (
    <>
      <InterceptorContextProvider isTrial={true}>
        <ConnectWallet
          isCustomDomain={isCustomDomain}
          defaultOpen={true}
          entityName={entityName}
          isTrial={true}
        />
        {isConnected && <Outlet context={[entityName, isCustomDomain]} />}
      </InterceptorContextProvider>
    </>
  );
};

export default Trial;

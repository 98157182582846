import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import {
  useOutletContext,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import IconIcMailchimp from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMailchimp";
import IconIcSlackSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcSlackSquare";
import IconIcGitHubSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcGitHubSquare";
import IconIcTracking from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcTracking";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useSelector } from "react-redux";
import {
  selectAttributionVersionCanUpdate,
  selectTicketType,
  selectWidgetVersionCanUpdate,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import IconIcDiscordSquare from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscordSquare";
import BasicModal from "features/metadesk/chat/BasicModal";
import {
  parseCustomDomainUrl,
  isVersionLessThan,
} from "features/helpers/utils";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import {
  selectServiceModule,
  selectMarketingModule,
  selectWidgetModule,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import IconIcWidgetIcon from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcWidgetIcon";
import { apiUrl } from "features/configure";
import axios from "axios";
import { useTheme } from "@mui/styles";

import { useIntl } from "react-intl";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import AutoCompleteDropdown from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import IntegrationVersionInfo from "./integrations/IntegrationVersionInfo";
import { Grid } from "@mui/material";

const Integration = () => {
  const theme = useTheme();
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const navigate = useNavigate();
  const [run, setRun] = useState("");
  const [entityName, isCustomDomain] = useOutletContext();
  const ticketType = useSelector(selectTicketType);
  const isTicketTypeExist = !_.isEmpty(ticketType);
  const [ticketSetupModalOpen, setTicketSetupModalOpen] = useState(false);
  const hasService = _.get(entityConfig, "modules.service");
  const hasMarketing = _.get(entityConfig, "modules.marketing");
  const serviceModule = useSelector(selectServiceModule);
  const marketingModule = useSelector(selectMarketingModule);
  const widgetModule = useSelector(selectWidgetModule);
  const widgetVersion = useSelector(selectWidgetVersionCanUpdate);
  const trackingVersion = useSelector(selectAttributionVersionCanUpdate);
  const [type, setType] = useState("");
  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  // 目前安裝的widget版本
  const {
    version: connectedWidgetVersion,
    latestVersion: widgetLatestVersion,
    canUpdate: widgetCanUpdate,
  } = widgetVersion || {};
  const {
    version: connectedAttributionVersion,
    latestVersion: attributionLatestVersion,
    canUpdate: attributionCanUpdate,
  } = trackingVersion || {};

  const isMultiDomainWidget = _.get(widgetModule, "multiDomain", false);

  const { formatMessage } = useIntl();
  let [searchParams] = useSearchParams();
  const guide = searchParams.get("guide");

  useEffect(() => {
    if (guide) {
      setRun(guide);
    }
  }, [guide]);

  const tagColorMapping = {
    services: {
      backgroundColor: "#DDEFFF",
      color: "#148EFF",
      label: "Services",
    },
    marketing: {
      backgroundColor: "#FFDDE5",
      color: "#FF6A9F",
      label: "Marketing",
    },
    notification: {
      backgroundColor: "#F1E4FF",
      color: "#7B61FF",
      label: "Notification",
    },
    analytics: {
      backgroundColor: "#E4FFDD",
      color: "#24DF77",
      label: "Analytics",
    },
    "sideAdd-ons": {
      backgroundColor: "#FFF6C7",
      color: "#FFB84E",
      label: "Side Add-ons",
    },
    message: { backgroundColor: "#C7FFF5", color: "#36E8F3", label: "Message" },
  };

  const integrationWayArr = [
    {
      auth: hasService,
      icon: <IconIcDiscordSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.discordBot.name" }),
      description: formatMessage({ id: "integration.discordBot.description" }),
      link: "discord",
      tags: ["services"],
      own: true,
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/discord"
          )
        );
      },
      extraAction: () => {
        const url = _.get(entityConfig, "serviceModule.discord.url");
        openWindow(url, true);
      },
    },
    {
      // hasService 或 hasMarketing 都可以使用widget
      auth: hasService || hasMarketing,
      icon: <IconIcWidgetIcon width={40} height={40} />,
      title: formatMessage({ id: "integration.widget.name" }),
      description: formatMessage({ id: "integration.widget.description" }),
      link: "widgetSupport",
      own: true,
      tags: ["marketing", "services", "notification"],
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/widget"
          )
        );
      },
      otherLinkToGo: parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/general/integration/update/widget"
      ),
      currentVersion: connectedWidgetVersion,
      latestVersion: widgetLatestVersion,
      canUpdate: widgetCanUpdate,
      showTooltip:
        connectedWidgetVersion &&
        connectedWidgetVersion !== "0.0.0" &&
        isVersionLessThan(connectedWidgetVersion, "1.1.0"),
      tooltipText: formatMessage({ id: "integration.apikey.version.hint" }),
    },
    // Blockscan Chat 可能不做
    // {
    //   auth: hasService,
    //   icon: <IconIcSlackSquare width={40} height={40} />,
    //   title: "Blockscan Chat",
    //   description:
    //     "Instant wallet-to-wallet interaction via Blockscan Chat with any Ethereum-compatible address.",
    //   tags: ["marketing"],
    // },
    {
      auth: hasService,
      icon: <IconIcMailchimp width={40} height={40} />,
      title: formatMessage({ id: "integration.mailchimp.name" }),
      description: formatMessage({ id: "integration.mailchimp.description" }),
      link: "mailchimp",
      tags: ["marketing"],
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/mailchimp"
          )
        );
      },
    },
    // {
    //   auth: hasService,
    //   icon: (
    //     <img
    //       src={require("assets/img/debankHi.svg").default}
    //       style={{ width: 40, height: 40 }}
    //     />
    //   ),
    //   title: formatMessage({ id: "integration.debank.name" }),
    //   description: formatMessage({ id: "integration.debank.description" }),
    //   tags: ["marketing", "message"],
    // },
    {
      auth: hasMarketing,
      icon: (
        <img
          src={require("assets/img/xmtp.svg").default}
          style={{ width: 40, height: 40 }}
        />
      ),
      title: formatMessage({ id: "integration.xmtp.name" }),
      description: formatMessage({ id: "integration.xmtp.description" }),
      tags: ["marketing", "message"],
      link: "xmtp",
      otherLinkToGo: `/${entityName}/settings/general/integration/update/xmtp`,
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/xmtp"
          )
        );
      },
    },
    {
      auth: hasMarketing,
      icon: (
        <img
          src={require("assets/img/push.svg").default}
          style={{ width: 40, height: 40 }}
        />
      ),
      title: formatMessage({ id: "integration.push.title" }),
      description: formatMessage({ id: "integration.push.description" }),
      tags: ["marketing"],
      link: "push",
      otherLinkToGo: `/${entityName}/settings/general/integration/update/push`,
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/push"
          )
        );
      },
    },
    {
      auth: hasMarketing,
      icon: <IconIcTracking width={40} height={40} />,
      title: formatMessage({ id: "integration.trackingTag.name" }),
      description: formatMessage({ id: "integration.trackingTag.description" }),
      link: "attribution",
      tags: ["marketing", "analytics"],
      own: true,
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/attribution"
          )
        );
      },
      otherLinkToGo: parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/general/integration/update/attribution"
      ),
      currentVersion: connectedAttributionVersion,
      latestVersion: attributionLatestVersion,
      canUpdate: attributionCanUpdate,
      showTooltip:
        connectedAttributionVersion &&
        isVersionLessThan(connectedAttributionVersion, "1.1.0"),
      tooltipText: formatMessage({ id: "integration.apikey.version.hint" }),
    },
    {
      auth: hasService,
      icon: (
        <img
          src={require("assets/img/discordNotification.png")}
          style={{ width: 45, height: 46 }}
        />
      ),
      title: formatMessage({ id: "integration.discord.name" }),
      description: formatMessage({ id: "integration.discord.description" }),
      link: "discordWebhook",
      tags: ["notification"],
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/discordWebhook"
          )
        );
      },
    },
    {
      auth: hasService,
      icon: <IconIcSlackSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.slack.name" }),
      description: formatMessage({ id: "integration.slack.description" }),
      link: "slack",
      tags: ["notification"],
      extraAction: () => {
        const url = _.get(entityConfig, "serviceModule.slack.url");
        openWindow(url, true);
      },
      setting: () => {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration/update/slack"
          )
        );
      },
    },
    {
      auth: hasService,
      icon: <IconIcGitHubSquare width={40} height={40} />,
      title: formatMessage({ id: "integration.githubIssue.name" }),
      description: formatMessage({ id: "integration.githubIssue.description" }),
      link: "github",
      tags: ["sideAdd-ons"],
      setting: "",
      extraAction: () => {
        const url = _.get(entityConfig, "serviceModule.github.url");
        openWindow(url);
      },
    },
  ];

  const handleNavigate = ({ extraAction, link, title, otherLinkToGo }) => {
    if (title === "Discord" && !isTicketTypeExist) {
      setTicketSetupModalOpen(true);
      return;
    }
    extraAction && extraAction();
    if (link == "discord") return;
    if (otherLinkToGo) {
      navigate(otherLinkToGo);
      return;
    }
    navigate(link);
  };

  const handleToSetTicket = () => {
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        "/settings/service-module/ticket"
      )
    );
  };

  const renderConnectButton = ({ link, extraAction, title, otherLinkToGo }) => {
    if (link === "widgetSupport") {
      if (!_.get(widgetModule, `enabled`)) {
        return (
          <Button color={COLOR.SECONDARY} style={{ width: 140 }} disabled>
            Coming soon
          </Button>
        );
      }
      if (isMultiDomainWidget) {
        return (
          <Button
            style={{ width: 140 }}
            color={COLOR.SECONDARY}
            onClick={() =>
              handleNavigate({ extraAction, link, title, otherLinkToGo })
            }
          >
            {"Connect"}
          </Button>
        );
      }
    } else if (
      !_.get(serviceModule, `${link}.enabled`) &&
      !_.get(marketingModule, `${link}.enabled`) &&
      link !== "widgetSupport"
    ) {
      return (
        <Button color={COLOR.SECONDARY} style={{ width: 140 }} disabled>
          Coming soon
        </Button>
      );
    }
    if (link === "widgetSupport" && !_.get(widgetModule, `enabled`)) {
      return (
        <Button color={COLOR.SECONDARY} style={{ width: 140 }} disabled>
          Coming soon
        </Button>
      );
    }

    if (
      _.get(serviceModule, `${link}.connected`) ||
      _.get(marketingModule, `${link}.connected`) ||
      (link === "widgetSupport" && _.get(widgetModule, `connected`))
    ) {
      return (
        <Button
          startIcon={<i className="meta-crm-icon-ic_check font-size-16" />}
          style={{
            width: 140,
            color: "#7B61FF",
            background: "#F7F1FE",
            cursor: "auto",
          }}
          color={COLOR.SECONDARY}
          onClick={() => {}}
        >
          {"Connected"}
        </Button>
      );
    }
    if (
      _.get(serviceModule, `${link}.connecting`) ||
      _.get(marketingModule, `${link}.connecting`) ||
      (link === "widgetSupport" && _.get(widgetModule, `connecting`))
    ) {
      return (
        <Button
          startIcon={
            <i className="meta-crm-icon-ic_integration font-size-16" />
          }
          style={{
            width: 140,
            color: "#7B61FF",
            background: "#F7F1FE",
            cursor: "auto",
          }}
          color={COLOR.SECONDARY}
          onClick={() => {}}
        >
          {"Connecting"}
        </Button>
      );
    }
    return (
      <Button
        style={{ width: 140 }}
        color={COLOR.SECONDARY}
        onClick={() =>
          handleNavigate({ extraAction, link, title, otherLinkToGo })
        }
      >
        {"Connect"}
      </Button>
    );
  };

  const renderSettingButton = ({
    link,
    setting,
    extraAction,
    title,
    canUpdate,
    otherLinkToGo,
    showTooltip = false,
    tooltipText,
  }) => {
    if (
      (setting &&
        (_.get(serviceModule, `${link}.connected`) ||
          _.get(marketingModule, `${link}.connected`))) ||
      (link === "widgetSupport" && _.get(widgetModule, `connected`))
    ) {
      return (
        <Box position={"relative"}>
          <Button
            onClick={setting}
            style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
            color={COLOR.SECONDARY}
          >
            <i className="meta-crm-icon-ic_settingLine font-size-18" />
          </Button>
          {showTooltip && (
            <Box position={"absolute"} top={"-6px"} right={"-6px"}>
              <IconTooltip
                content={tooltipText}
                customColor={theme.customColors.pink[500]}
                icon={
                  <img
                    src={require("assets/img/ic_info_fill.png")}
                    style={{ width: "16px", height: "16px" }}
                  />
                }
              ></IconTooltip>
            </Box>
          )}
          {canUpdate && (
            <Box
              position={"absolute"}
              top={"-12px"}
              right={"-12px"}
              color="white"
              fontSize="12px"
              bgcolor="#FC5555"
              p="4px"
              borderRadius="30px"
            >
              {formatMessage({ id: "global.reddot.newNotification" })}
            </Box>
          )}
        </Box>
      );
    }
    if (
      _.get(serviceModule, `${link}.connecting`) ||
      _.get(marketingModule, `${link}.connecting`) ||
      (link === "widgetSupport" && _.get(widgetModule, `connecting`))
    ) {
      return (
        <Box position={"relative"}>
          <Button
            onClick={() =>
              handleNavigate({ extraAction, link, title, otherLinkToGo })
            }
            style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
            color={COLOR.SECONDARY}
          >
            <i className="meta-crm-icon-ic_settingLine font-size-18" />
          </Button>
        </Box>
      );
    }
    if (link === "widgetSupport" && isMultiDomainWidget) {
      return (
        <Box position={"relative"}>
          <Button
            onClick={setting}
            style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
            color={COLOR.SECONDARY}
          >
            <i className="meta-crm-icon-ic_settingLine font-size-18" />
          </Button>
          {showTooltip && (
            <Box position={"absolute"} top={"-6px"} right={"-6px"}>
              <IconTooltip
                content={tooltipText}
                customColor={theme.customColors.pink[500]}
                icon={
                  <img
                    src={require("assets/img/ic_info_fill.png")}
                    style={{ width: "16px", height: "16px" }}
                  />
                }
              ></IconTooltip>
            </Box>
          )}
          {canUpdate && (
            <Box
              position={"absolute"}
              top={"-12px"}
              right={"-12px"}
              color="white"
              fontSize="12px"
              bgcolor="#FC5555"
              p="4px"
              borderRadius="30px"
            >
              {formatMessage({ id: "global.reddot.newNotification" })}
            </Box>
          )}
        </Box>
      );
    }
    return (
      <Button
        disabled={true}
        style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
        color={COLOR.SECONDARY}
      >
        <i className="meta-crm-icon-ic_settingLine font-size-18" />
      </Button>
    );
  };

  const openWindow = (url, fullScreen = false) => {
    const width = 800;
    const height = 1200;
    const left = window.innerWidth - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    if (!url) return;
    if (fullScreen) {
      window.location.replace(url);
    } else {
      window.open(
        url,
        "popup",
        `width=${width},height=${height},top=${top},left=${left}`
      );
    }
  };

  const typeOptions = [
    { label: "All", value: "" },
    { label: "Services", value: "services" },
    { label: "Marketing", value: "marketing" },
    { label: "Analytics", value: "analytics" },
    { label: "Notification", value: "notification" },
    { label: "Side Add-ons", value: "sideAdd-ons" },
    // { label: "Message", value: "message" },
  ];

  const [filter, setFilter] = useState({
    search: "",
    type: "",
    updateAvailable: false,
  });

  const [input, setInput] = useState("");
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleEnter = () => {
    setFilter((prev) => ({ ...prev, search: input }));
  };

  const handleBlur = () => {
    setFilter((prev) => ({ ...prev, search: input }));
  };

  const handleChangeType = (event, target) => {
    setFilter((prev) => ({ ...prev, type: target }));
  };

  const clearSearch = () => {
    setFilter((prev) => ({ ...prev, search: "" }));
  };

  const toggleUpdateAvailable = () => {
    setFilter((prev) => ({ ...prev, updateAvailable: !prev.updateAvailable }));
  };

  const FilterArr = integrationWayArr.filter((item) => {
    if (filter.updateAvailable) return item.canUpdate;
    if (
      filter.search &&
      !item.title.toLowerCase().includes(filter.search.toLowerCase())
    ) {
      return false;
    }
    if (filter.type.value && !item?.tags?.includes(filter.type.value)) {
      return false;
    }
    return true;
  });

  return (
    <div>
      <div className="title">
        {formatMessage({ id: "integration.header.title" })}
      </div>
      <div className="description">
        {formatMessage({ id: "integration.header.description" })}
        {entityConfig.demoSite && (
          <Button
            color={COLOR.INFO}
            style={{ marginLeft: 10, minWidth: 0, padding: 10 }}
            onClick={async () => {
              try {
                await axios.post(apiUrl + "/api/manage/integration/reset", {
                  entityName: entityName,
                });
                fetchEntityConfig({ entityName });
              } catch (error) {
                console.log("error reset integration", error);
              }
            }}
          >
            Reset
          </Button>
        )}
      </div>
      <Box
        display="flex"
        alignItems="center"
        m={"40px 0 8px"}
        flexWrap={"wrap"}
      >
        <InputField
          placeholder={formatMessage({ id: "integration.search.placeholder" })}
          onEnter={handleEnter}
          onBlur={handleBlur}
          onChange={handleChange}
          name="search"
          style={{ minWidth: "230px" }}
          value={input}
          type="search"
          onClean={clearSearch}
        />
        <Box ml={"8px"} width={"200px"}>
          <AutoCompleteDropdown
            options={typeOptions}
            value={filter.type}
            onChange={handleChangeType}
            placeholder="All"
            prefix={
              <Box position="start">
                {formatMessage({ id: "global.header.type" })}
              </Box>
            }
          />
        </Box>
        {(widgetCanUpdate || attributionCanUpdate) && (
          <Box
            sx={{
              ml: "8px",
              color: "white",
              borderRadius: "4px",
              alignContent: "center",
              padding: "10px 12px",
              boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.10)",
              cursor: "pointer",
              bgcolor: "#FF9D2A",
            }}
            onClick={toggleUpdateAvailable}
          >
            {formatMessage({ id: "global.upgradeAvailable.button" })}
          </Box>
        )}
      </Box>

      {FilterArr.map((integrationWayItem, index) => {
        const {
          auth,
          icon,
          title,
          description,
          link,
          setting,
          extraAction,
          otherLinkToGo,
          currentVersion,
          latestVersion,
          canUpdate,
          showTooltip,
          tooltipText,
          tags,
          own,
        } = integrationWayItem;
        if (!auth) return null;
        return (
          <StyledWhiteContainer key={index} style={{ marginBottom: "9px" }}>
            <Grid
              container
              sx={{
                marginBottom:
                  index !== integrationWayArr.findLastIndex((item) => item.auth)
                    ? "12px"
                    : 0,
              }}
              id={`${link}Guide`}
            >
              <Grid item xs={12} md={9}>
                {" "}
                <div className="startRow" style={{ alignItems: "flex-start" }}>
                  {icon}
                  <div style={{ marginLeft: 16 }}>
                    <Box display={"flex"} alignItems={"center"}>
                      <div className="subTitle">{title}</div>
                      {tags &&
                        tags.map((tag) => (
                          <Box
                            key={tag}
                            sx={{
                              backgroundColor:
                                tagColorMapping[tag].backgroundColor,
                              color: tagColorMapping[tag].color,
                              borderRadius: "2.5px",
                              padding: "2.5px 4px",
                              fontSize: "12px",
                              marginLeft: "8px",
                            }}
                          >
                            {tagColorMapping[tag].label}
                          </Box>
                        ))}
                      {currentVersion && (
                        <Box
                          ml="8px"
                          display="flex"
                          color={"#777777"}
                          fontSize="12px"
                        >
                          {formatMessage({
                            id: "global.integration.currentVersion.description",
                          })}{" "}
                          :
                          <Box
                            ml="2px"
                            color={"#7B61FF"}
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setType(
                                link === "attribution" ? "tracking" : "widget"
                              );
                            }}
                          >
                            {currentVersion}
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <div
                      className="description"
                      style={{ maxWidth: 520, lineHeight: "20px" }}
                    >
                      {description}
                    </div>
                    {own && (
                      <Box color={"#A5A5A5"} fontSize={"12px"} fontWeight={400}>
                        {formatMessage({ id: "integration.appIntro.addOwns" })}
                      </Box>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3} textAlign={"right"}>
                <div className="endRow">
                  {renderConnectButton({
                    link,
                    extraAction,
                    title,
                    otherLinkToGo,
                  })}
                  {renderSettingButton({
                    link,
                    setting,
                    extraAction,
                    title,
                    otherLinkToGo,
                    showTooltip,
                    tooltipText,
                    canUpdate,
                  })}
                </div>
              </Grid>
            </Grid>
          </StyledWhiteContainer>
        );
      })}
      <BasicModal
        open={ticketSetupModalOpen}
        onClose={() => setTicketSetupModalOpen(false)}
        modalTitle="Ticket Type Settings Required"
        modalContent="Please complete the ticket type settings before connecting to the Discord ticket bot"
        confirmText="Go to Setting"
        cancelText="Close"
        onHandleConfirm={handleToSetTicket}
      />
      {type && (
        <IntegrationVersionInfo handleClose={() => setType("")} type={type} />
      )}
    </div>
  );
};

export default Integration;

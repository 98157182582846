import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { Grid, useMediaQuery } from "@material-ui/core";
import {
  IconButton,
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchGroups } from "../redux/hooks";
import CustomTable from "components/CustomTable/CustomTable";
import { formatDateAndTime, getShortAddress } from "features/helpers/utils";

import { useNavigate, useParams, useOutletContext } from "react-router-dom";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useSnackbar } from "features/common/redux/hooks";
import CustomTabs from "components/CustomTabs/Tabs";
import GroupCardNew from "./CreateGroup/GroupCardNew/GroupCardNew";
import CreateGroupModal from "./CreateGroupModal";
import { parseCustomDomainUrl } from "features/helpers/utils";
import ClearIcon from "@material-ui/icons/Clear";
import { formatValue } from "features/helpers/bignumber";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { getUserAvatarIcon } from "../utils";
import TableMenu from "components/CustomTable/TableMenu";
import Back from "../components/Back";
import ExportModal from "../components/ImportList/ExportModal";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import TokensWraper from "../components/Icons/TokensWraper";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch } from "react-redux";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import UserTag from "../components/UserTag";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import useGetPagePermission from "hooks/useGetPagePermission";
import { Loading } from "features/common";
import Address from "../components/Address";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 700,
    fontSize: 20,
  },
  ruleField: {
    color: "#383538",
    fontSize: 12,
    fontWeight: 500,
    padding: "0px 24px",
    paddingTop: 40,
  },
  ruleHeader: {
    fontSize: 14,
    lineHeight: "17px",
    padding: "0px 0px",
    paddingBottom: 11,
  },
  moneySymbol: { fontSize: 14, marginRight: 5 },
  tableValue: {
    fontWeight: 500,
    fontSize: 15,
  },
  numberAddress: (props) => ({
    marginLeft: 8,
    fontSize: 13,
    color: props.customColors.grey[600],
    display: "flex",
    alignItems: "center",
    "& i": {
      marginRight: "2px",
    },
  }),
}));
const kMapping = {
  "Wallet Create": "addressFirstTransactionDate",
  "Wallet Balance": "walletValue",
  Transaction: "addressNonce",
  "NFT Amount": "nftCount",
  Address: "address",
};

const GroupDetail = () => {
  const {
    editGroup,
    fetchGroupQueryUserList,
    fetchGroupsUserPending,
    getGroupsUsersPending,
    queryUsers,
    queryUsersCount,
    getGroupUsers,
    groupUsers,
    removeGroup,
    updateGroupUserFilter,
    groupUsersCount,
    updateGroupPending,
    selectedGroup,
    groupUserFilter,
  } = useFetchGroups();
  const { groupId } = useParams();
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openExport, setOpenExport] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const PAGE_COUNT = 20;
  const [search, setSearch] = useState(
    _.get(groupUserFilter, `[${groupId}].search`)
  );
  const [groupTabIndex, setGroupTabIndex] = useState(0);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState("");

  const page = _.get(groupUserFilter, `[${groupId}].page`, 1);
  const queryPage = _.get(groupUserFilter, `[${groupId}].queryPage`, 1);
  const orderData = _.get(groupUserFilter, `[${groupId}].orderData`, {
    sort: "_id",
    order: -1,
  });
  const removeConfirmRef = useRef();
  const dispatch = useDispatch();
  const prevState = useRef(groupUserFilter[groupId]);
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  const compareObjects = (obj1, obj2) => {
    return _.keys(obj1).filter(
      (key) => !_.isEqual(_.get(obj1, key), _.get(obj2, key))
    );
  };

  useEffect(() => {
    if (!groupId) return;
    const differentKeys = compareObjects(
      groupUserFilter[groupId],
      prevState.current
    );
    prevState.current = groupUserFilter[groupId];

    if (!(differentKeys.length == 1 && differentKeys[0] == "queryPage")) {
      getGroupUsers({
        entityName,
        groupId,
        filter: groupUserFilter[groupId],
      });
    }
    if (rules) {
      if (differentKeys.length == 1 && differentKeys[0] == "page") return;
      fetchGroupQueryUserList({
        rules: rules,
        entityName,
        groupId,
        filter: groupUserFilter[groupId],
      });
    }
  }, [groupId, groupUserFilter, rules]);

  useEffect(() => {
    if (!groupId) return;
    if (selectedGroup?._id !== groupId) return;
    if (!rules) {
      setRules(selectedGroup.rules);
    }
  }, [selectedGroup]);

  const allRefresh = () => {
    const newFilter = { ...groupUserFilter[groupId], page: 1, queryPage: 1 };
    getGroupUsers({
      entityName,
      groupId,
      filter: newFilter,
    });
    fetchGroupQueryUserList({
      rules: selectedGroup.rules,
      entityName,
      groupId,
      filter: newFilter,
    });
  };

  const handleSort = (header, direction) => {
    const by = header || orderData.sort;

    const dir =
      orderData.sort != by ? orderData.order : direction || orderData.order;

    updateGroupUserFilter(groupId, { orderData: { sort: by, order: dir } });
  };

  const selectedCount = selectedUserData.length;

  const handleOpenConfirmModal = (e) => {
    e.stopPropagation();
    removeConfirmRef.current.setOpen(true);
  };

  const handleRemoveGroup = async () => {
    await removeGroup({ groupId: groupId, entityName });
    navigate(
      parseCustomDomainUrl(isCustomDomain, entityName, "/customers/segment")
    );
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("segmentation");
  const customerPermission = pagePermission("customerList");

  const renderUserTable = ({
    data,
    pagination,
    clickRow,
    loading,
    tableStyle = { height: "40vh" },
  }) => {
    return (
      <div>
        <CustomTable
          sortMapping={kMapping}
          loading={loading}
          noDataTitle="No User"
          checkbox
          containerStyle={tableStyle}
          rowData={data}
          sortDataFunction={handleSort}
          sortBy={orderData.sort}
          order={orderData.order}
          disabledSort={{ 0: true, 7: true }}
          centerText={{}}
          headers={[
            "Address",
            "Tags",
            "Wallet Balance",
            "NFT Amount",
            "Transaction",
            "Current Holding",
            "Protocol",
            "Wallet Create",
          ]}
          selectedRowData={selectedUserData}
          setSelectedRowData={setSelectedUserData}
          rowSelected={(userId) => {
            if (clickRow && customerPermission.show) clickRow(userId);
          }}
          contents={_.reduce(
            data,
            (result, user, i) => {
              if (user) {
                result.push([
                  <Address user={user} />,
                  <div className={`${classes.tableValue}`}>
                    <UserTag
                      width={"100%"}
                      minWidth={250}
                      entityName={entityName}
                      initTags={_.map(_.get(user, "tags", []), "_id")}
                      disabled={true}
                    />
                  </div>,
                  <div className={`${classes.tableValue} startRow`}>
                    ${formatValue(user.walletValue, 0)}
                  </div>,
                  <div className={`${classes.tableValue} startRow`}>
                    <MetaCRMTag
                      option={{
                        color: theme.customColors.purple[300],
                        label: _.get(user, "nftCount", "0") || 0,
                      }}
                    />
                  </div>,
                  <div className={`${classes.tableValue} startRow`}>
                    <MetaCRMTag
                      option={{
                        color: theme.customColors.purple[300],
                        label: _.get(user, "addressNonce", "0") || 0,
                      }}
                    />
                  </div>,
                  <div style={{ minWidth: 100 }}>
                    <TokensWraper
                      size={"24px"}
                      marginLeft="-8px"
                      tokens={_.get(user, "assets.token", [])}
                    />
                  </div>,
                  <div style={{ minWidth: 100 }}>
                    <TokensWraper
                      size={"24px"}
                      showNumber={3}
                      marginLeft="-8px"
                      tokens={_.get(user, "protocols", [])}
                    />
                  </div>,
                  <div className={classes.tableValue}>
                    {formatDateAndTime(
                      _.get(user, "addressFirstTransactionDate", "-")
                    )}
                  </div>,
                ]);
              }
              return result;
            },
            []
          )}
        />
        {pagination}
      </div>
    );
  };

  const handleAllAdd = async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      let { data } = await axios.post(`${apiUrl}/api/manage/groups/addAll`, {
        entityName,
        groupId,
      });

      setGroupTabIndex(0);

      updateGroupUserFilter(groupId, { page: 1, queryPage: 1 });
      setSelectedUserData([]);

      enqueueSnackbar({
        message: `${data.updatedCount} members have been\n saved to this segment.`,
        options: {
          variant: "info",
        },
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(
            error,
            "response.data.error",
            _.get(error, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const tabMenu = [
    { label: `Choose : ${selectedCount}` },
    {
      label: (
        <div className="startRow">
          <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
          Export
        </div>
      ),
      onClick: () => {
        setOpenExport(true);
      },
    },
  ];
  if (canEdit) {
    tabMenu.push({
      disabled: selectedCount == 0,
      onClick: async () => {
        try {
          let userData = {
            ...selectedGroup,
            entityName,
            groupId,
          };
          const selectedUserIds = _.map(selectedUserData, "_id");
          let undoData = { ...userData };
          if (groupTabIndex == 0) {
            userData.removeUsers = selectedUserIds;
            undoData.addUsers = selectedUserIds;
          } else {
            userData.addUsers = selectedUserIds;
          }
          await editGroup(userData);
          allRefresh();
          setSelectedUserData([]);

          enqueueSnackbar({
            message: `${selectedCount} members have been\n ${
              groupTabIndex == 1 ? "saved to" : "removed from"
            } this segment.`,
            options: {
              variant: "info",
              // persist: groupTabIndex == 0,
              action:
                groupTabIndex == 0 ? (
                  <div className="endRow">
                    <Button
                      color="text"
                      onClick={async () => {
                        await editGroup(undoData);
                        allRefresh();
                        closeSnackbar();
                      }}
                    >
                      Undo
                    </Button>
                    <IconButton
                      onClick={() => {
                        closeSnackbar();
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    color="text"
                    onClick={() => {
                      setGroupTabIndex(0);
                      closeSnackbar();
                    }}
                  >
                    Check Now
                    <ArrowForwardIcon style={{ marginLeft: 5 }} />
                  </Button>
                ),
            },
          });
        } catch (err) {
          console.log(err);
        }
      },
      icon:
        groupTabIndex == 1 ? null : (
          <i className="meta-crm-icon-ic_trash font-size-14" />
        ),
      label: groupTabIndex == 1 ? "+Add to Segment" : "Delete",
    });
  }

  return (
    <main>
      <Loading open={loading} />
      <div>
        <Back
          text="Segment List"
          to={parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/customers/segment"
          )}
        />
        {canEdit && (
          <CreateGroupModal
            open={openCreate}
            onClose={() => setOpenCreate(false)}
            selectedGroup={selectedGroup}
            onSave={async (groupName, groupColor) => {
              await editGroup({
                entityName,
                name: groupName,
                color: groupColor,
                rules: selectedGroup.rules,
                groupId: _.get(selectedGroup, "_id"),
              });

              setOpenCreate(false);
            }}
          />
        )}
        <div>
          <div
            className={`${classes.header} startRow`}
            style={{ paddingBottom: "20px" }}
          >
            {selectedGroup.name}

            <div className={classes.numberAddress}>
              <i className="meta-crm-icon-ic_people font-size-12" />
              {groupUsersCount}
            </div>
            {canEdit && (
              <>
                <IconButton
                  onClick={() => {
                    setOpenCreate(true);
                  }}
                  style={{ padding: 1, marginLeft: 8 }}
                  color={theme.customColors.grey[500]}
                  size={16}
                >
                  <i className="meta-crm-icon-ic_edit-1 font-size-16" />
                </IconButton>

                <IconButton
                  onClick={(e) => handleOpenConfirmModal(e)}
                  style={{ padding: 1, marginLeft: 6 }}
                  color={theme.customColors.grey[500]}
                  size={16}
                >
                  <i className="meta-crm-icon-ic_trash font-size-16" />
                </IconButton>
              </>
            )}
          </div>
          <div>
            <GroupCardNew isRow={true} groupInfo={selectedGroup} />
            <InputField
              size={SIZE.MEDIUM}
              style={{ width: "280px" }}
              placeholder="Search list by address..."
              startAdornment={
                <i className="meta-crm-icon-ic_search font-size-16 mgr4" />
              }
              onEnter={() => {
                updateGroupUserFilter(groupId, {
                  search,
                  page: 1,
                  queryPage: 1,
                });
              }}
              onBlur={() => {
                updateGroupUserFilter(groupId, {
                  search,
                  page: 1,
                  queryPage: 1,
                });
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div style={{ position: "relative", marginTop: 24 }}>
          <div
            style={{
              width: "100%",
              borderBottom: "0.5px solid #A5A5A5",
              position: "absolute",
              top: 28,
            }}
          />
          <TableMenu
            tabs={[
              { label: `Choose : ${selectedCount}` },
              {
                label: (
                  <div className="startRow">
                    <i className="meta-crm-icon-ic-download-1 font-size-18 mgr4" />
                    Export
                  </div>
                ),
                onClick: () => {
                  setOpenExport(true);
                },
              },
              {
                disabled: selectedCount == 0,
                onClick: async () => {
                  try {
                    let userData = {
                      ...selectedGroup,
                      entityName,
                      groupId,
                    };
                    const selectedUserIds = _.map(selectedUserData, "_id");
                    let undoData = { ...userData };
                    if (groupTabIndex == 0) {
                      userData.removeUsers = selectedUserIds;
                      undoData.addUsers = selectedUserIds;
                    } else {
                      userData.addUsers = selectedUserIds;
                    }
                    await editGroup(userData);
                    allRefresh();
                    setSelectedUserData([]);

                    enqueueSnackbar({
                      message: `${selectedCount} members have been\n ${
                        groupTabIndex == 1 ? "saved to" : "removed from"
                      } this segment.`,
                      options: {
                        variant: "info",
                        // persist: groupTabIndex == 0,
                        action:
                          groupTabIndex == 0 ? (
                            <div className="endRow">
                              <Button
                                variant={VARIANT.TEXT}
                                onClick={async () => {
                                  await editGroup(undoData);
                                  allRefresh();
                                  closeSnackbar();
                                }}
                              >
                                Undo
                              </Button>
                              <IconButton
                                onClick={() => {
                                  closeSnackbar();
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <Button
                              variant={VARIANT.TEXT}
                              onClick={() => {
                                setGroupTabIndex(0);
                                closeSnackbar();
                              }}
                            >
                              Check Now
                              <ArrowForwardIcon style={{ marginLeft: 5 }} />
                            </Button>
                          ),
                      },
                    });
                  } catch (err) {
                    console.log(err);
                  }
                },
                icon:
                  groupTabIndex == 1 ? null : (
                    <i className="meta-crm-icon-ic_trash font-size-14" />
                  ),
                label: groupTabIndex == 1 ? "+Add to Segment" : "Delete",
              },
            ]}
            show={selectedCount > 0}
          />

          <Box position="relative">
            {!getGroupsUsersPending &&
              !updateGroupPending &&
              queryUsersCount > 0 &&
              canEdit && (
                <Button
                  variant={VARIANT.TEXT}
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "-10px",
                    zIndex: 150,
                  }}
                  onClick={(e) => handleAllAdd(e)}
                >
                  <i className="meta-crm-icon-ic_recycle font-size-20 mgr5" />
                  {formatMessage({ id: "segment.update.addAll" })}
                </Button>
              )}
            <CustomTabs
              sm="true"
              tabIndex={groupTabIndex}
              handleChange={(e, newValue) => {
                setSelectedUserData([]);
                setGroupTabIndex(newValue);
              }}
              tabs={[
                {
                  label: `Existing Users (${groupUsersCount || "-"})`,
                  content: renderUserTable({
                    data: groupUsers,
                    loading: getGroupsUsersPending || updateGroupPending,
                    clickRow: (userId) =>
                      navigate(
                        parseCustomDomainUrl(
                          isCustomDomain,
                          entityName,
                          `/customers/user/${userId}`
                        )
                      ),
                    pagination: (
                      <CustomPagination
                        PAGE_COUNT={PAGE_COUNT}
                        count={groupUsersCount}
                        page={page}
                        setPage={(v) =>
                          updateGroupUserFilter(groupId, { page: v })
                        }
                      />
                    ),
                  }),
                },

                {
                  label: (
                    <Box display="flex">
                      Newly Detected (
                      {fetchGroupsUserPending || !queryUsersCount
                        ? "-"
                        : queryUsersCount}
                      )
                    </Box>
                  ),

                  content: renderUserTable({
                    data: queryUsers,
                    loading: fetchGroupsUserPending || updateGroupPending,
                    clickRow: (userId) =>
                      navigate(
                        parseCustomDomainUrl(
                          isCustomDomain,
                          entityName,
                          `/customers/user/${userId}`
                        )
                      ),
                    pagination: (
                      <CustomPagination
                        PAGE_COUNT={PAGE_COUNT}
                        count={queryUsersCount}
                        page={queryPage}
                        setPage={(v) =>
                          updateGroupUserFilter(groupId, { queryPage: v })
                        }
                      />
                    ),
                  }),
                },
              ]}
            />
          </Box>
        </div>
      </div>
      <ExportModal
        open={openExport}
        onClose={() => setOpenExport(false)}
        group={selectedGroup}
        entityName={entityName}
        choose={_.map(selectedUserData, "_id")}
      />
      {canEdit && (
        <RemoveConfirm
          onRemove={handleRemoveGroup}
          title={"Delete your segment"}
          ref={removeConfirmRef}
          controlByParent
        />
      )}
    </main>
  );
};

export default GroupDetail;

import React from "react";
import _ from "lodash";
import config from "../../config";
import { useSelector } from "react-redux";

import UserInfo from "./UserInfo";
import MagpieUserInfo from "./magpie/MagpieUserInfo";
import CurveUserInfo from "./curve/CurveUserInfo";
import AzukiUserInfo from "./azuki/AzukiUserInfo";
import RadiantUserInfo from "./radiant/RadiantUserInfo";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const UserInfoContainer = ({
  selectedUserAddress,
  entityName,
  selectedUserId,
  ticketListData,
  isFetching,
  fetchUserObjs,
}) => {
  const isDemoSite = useSelector(selectDemoSite);
  const userInfoConfig = _.get(config, "allowedEntities.userInfo", {});

  const checkEntityName = (type) => {
    return (
      !_.isEmpty(userInfoConfig[type]) &&
      _.includes(_.map(userInfoConfig[type], _.toLower), _.toLower(entityName))
    );
  };
  if (checkEntityName("curve"))
    return (
      <CurveUserInfo
        selectedUserId={selectedUserId}
        ticketListData={ticketListData}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        isFetching={isFetching}
        fetchUserObjs={fetchUserObjs}
      />
    );
  if (checkEntityName("radiant") || isDemoSite)
    return (
      <RadiantUserInfo
        selectedUserId={selectedUserId}
        ticketListData={ticketListData}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        isFetching={isFetching}
        fetchUserObjs={fetchUserObjs}
      />
    );
  if (checkEntityName("magpie"))
    return (
      <MagpieUserInfo
        ticketListData={ticketListData}
        selectedUserId={selectedUserId}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        isFetching={isFetching}
        fetchUserObjs={fetchUserObjs}
      />
    );
  if (checkEntityName("azuki"))
    return (
      <AzukiUserInfo
        ticketListData={ticketListData}
        selectedUserId={selectedUserId}
        selectedUserAddress={selectedUserAddress}
        entityName={entityName}
        isFetching={isFetching}
        fetchUserObjs={fetchUserObjs}
      />
    );
  return (
    <UserInfo
      ticketListData={ticketListData}
      selectedUserId={selectedUserId}
      selectedUserAddress={selectedUserAddress}
      entityName={entityName}
      isFetching={isFetching}
      fetchUserObjs={fetchUserObjs}
    />
  );
};

export default UserInfoContainer;

import React, { useEffect, useState } from "react";
import { get } from "lodash";
import _ from "lodash";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import useNavigateEntity from "hooks/useNavigateEntity";
import useMarketModule from "hooks/useMarketModule";
import useGetPagePermission from "hooks/useGetPagePermission";
import {
  StyledContainerTitle,
  StyledContentContainer,
  StyledReferralValue,
  StyledSubTitle,
  StyledWhiteContainer,
} from "./Referral.styles";
import { useDispatch, useSelector } from "react-redux";
import ReferralRules from "features/metadesk/settings/integrations/tracking/ReferralRules";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { apiUrl, objectiveList } from "features/configure";
import axios from "axios";
import { Loading } from "features/common";
import { alertServerError } from "features/helpers/utils";
import { useOutletContext } from "react-router";
import moment from "moment";
export default function Referral() {
  const { formatMessage } = useIntl();
  const { navigateEntity } = useNavigateEntity();
  const [entityName, isCustomDomain] = useOutletContext();
  const dispatch = useDispatch();
  const ecosystemName = useSelector(selectEcosystemName);
  const handleToCreateReferralPage = () => {
    navigateEntity(`/marketing/referral/create`);
  };
  const { attributionConnect, referralEnabled } = useMarketModule();
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState();
  const objective = get(referralData, "objectives[0]");
  const pagePermission = useGetPagePermission();
  const { canEdit, show } = pagePermission("campaigns");

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${apiUrl}/api/referral/${entityName}/program`
      );
      const data = result?.data;
      setReferralData(data);
      return data;
    } catch (err) {
      console.log("err: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    getAPIData();
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Loading open={isLoading} fullScreen={false} white />

      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box className="title" mb={1}>
            {formatMessage({ id: "global.function.referral.title" })}
          </Box>
          <Box color={(theme) => theme.customColors.grey[600]} mb="13px">
            {formatMessage({ id: "global.function.referral.description" })}
          </Box>
        </Box>
        {canEdit && (
          <Button
            onClick={handleToCreateReferralPage}
            disabled={
              !referralEnabled ||
              !attributionConnect ||
              !_.isEmpty(referralData)
            }
          >
            {formatMessage({ id: "global.btn.createNew" })}{" "}
          </Button>
        )}
      </Box>
      <Box>
        {!_.isEmpty(referralData) ? (
          <Box
            sx={{ cursor: show ? "pointer" : "none" }}
            onClick={() => {
              if (show) {
                navigateEntity("/marketing/dashboard/referral");
              }
            }}
          >
            <StyledWhiteContainer>
              <StyledContainerTitle>
                {referralData?.referralUrl}
              </StyledContainerTitle>
              <StyledContentContainer>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={3}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.transactionRefferred",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue
                      style={{ color: "#7B61FF", fontWeight: 700 }}
                    >
                      {referralData?.referralCount || "--"}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.programDescription",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {referralData?.description}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {ecosystemName == "EVM" && (
                      <>
                        <StyledSubTitle>
                          {formatMessage({
                            id: "campaignBuild.thirdStep.field.chain.title",
                          })}
                        </StyledSubTitle>
                        <StyledReferralValue>
                          {objective?.chainId}
                        </StyledReferralValue>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <StyledSubTitle>
                      {formatMessage({
                        id: "referralsProgram.list.objective",
                      })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {_.find(objectiveList, { value: objective?.type })?.label}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSubTitle>
                      {ecosystemName == "Solana"
                        ? formatMessage({
                            id: "referralsProgram.list.programId",
                          })
                        : formatMessage({
                            id: "campaignBuild.thirdStep.field.contractAddress.title",
                          })}
                    </StyledSubTitle>
                    <StyledReferralValue>
                      {objective?.smartContractAddress}
                    </StyledReferralValue>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {objective?.type === "contract" && (
                      <>
                        <StyledSubTitle>
                          {formatMessage({
                            id: "referralsProgram.list.targetInstruction",
                          })}
                        </StyledSubTitle>
                        <StyledReferralValue>
                          {objective?.method}
                        </StyledReferralValue>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledSubTitle className="startRow">
                      <Box style={{ fontSize: 14, marginRight: 5 }}>
                        Created:{" "}
                      </Box>
                      {moment(referralData?.updated).format("YYYY/MM/DD")}
                    </StyledSubTitle>
                  </Grid>
                </Grid>
                <Box sx={{ background: "#F8F8F8", p: "20px", mt: "12px" }}>
                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "#7B61FF",
                      mb: "8px",
                      fontWeight: 500,
                    }}
                    className="startRow"
                  >
                    <i className="meta-crm-icon-ic_info font-size-16 mgr4" />
                    {formatMessage({
                      id: "referralsProgram.list.note.title",
                    })}
                  </Box>
                  <ReferralRules />
                </Box>
              </StyledContentContainer>
            </StyledWhiteContainer>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            mt="12%"
          >
            <img src={require("assets/img/campaignNoData.png")} />
            <p
              style={{ color: "#555555", width: "250px", textAlign: "center" }}
            >
              Start creating your referral program and observe the performance
              of different referrers.
            </p>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import _, { chain } from "lodash";
import {
  StyledIconImg,
  StyledChainIconImg,
  StyledWrap,
} from "./TokensWraper.styles";
import { tokensMap, chainIdMapping } from "features/configure/tokenList.js";
import {
  IconTooltip,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { getHexImg } from "features/helpers/utils";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import Token from "./Token";
import { useSelector } from "react-redux";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";

const TokensWraper = ({
  tokens = [],

  size = "16px",
  marginLeft = "-6px",
  showNumber = 5,
  prev,
}) => {
  const chainList = useSelector(selectEntityChainList);
  const renderIcon = ({ chainIcon, i, address, chainId, icon }) => {
    return (
      <StyledWrap key={i}>
        {!_.isEmpty(chainIcon) && (
          <StyledChainIconImg
            src={chainIcon}
            marginLeft={i == 0 ? 0 : marginLeft}
          />
        )}
        <Token
          data={{ address: address, chainId: chainId, icon }}
          width={size}
          height={size}
          margin={i == 0 ? 0 : `0 0 0 ${marginLeft}`}
        />
      </StyledWrap>
    );
  };

  if (tokens.length === 0) return "-";

  return (
    <div className="startRow" style={{ flexWrap: "nowrap" }}>
      <div style={{ display: "flex" }}>
        {_.map(tokens, (d, i) => {
          if (i > showNumber - 1) return;
          const token = prev ? _.get(d, prev, {}) : d;
          const chainId =
            token.chainId ||
            d.chainId ||
            chainIdMapping[token.chain] ||
            token.chain;
          const chainIcon = _.find(chainList, {
            id: chainId,
          })?.logo_url;
          const data = {
            ...token,
            chainIcon,
            i,
            chainId,
          };
          return (
            <IconTooltip
              icon={renderIcon(data)}
              content={
                <div
                  className="startRow"
                  style={{ paddingLeft: i == 0 ? 0 : 6 }}
                >
                  {renderIcon(data)}
                  <span style={{ marginLeft: 4 }}>
                    {token.preferredName || token.symbol || token.name}
                  </span>
                </div>
              }
            ></IconTooltip>
          );
        })}
      </div>
      <div style={{ marginLeft: "4px" }}>
        {tokens && tokens.length > showNumber && (
          <MetaCRMTag
            option={{
              label: `${
                tokens.length - showNumber > 0 ? tokens.length - showNumber : ""
              }`,
              color: "#F7F1FE",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TokensWraper;

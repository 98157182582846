import React, { useContext, useEffect, useState } from "react";
import PerformanceUtmLinksTable from "./PerformanceUtmLinksTable";
import { Box } from "@mui/material";
import PerformanceUtmLinksSearch from "./PerformanceUtmLinksSearch";
import { Loading } from "features/common";
import { useIntl } from "react-intl";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useOutletContext, useParams } from "react-router";
import { get, lowerCase } from "lodash-es";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import CustomPagination from "components/CustomPagination/CustomPagination";
import Back from "features/metadesk/components/Back";
import { useNavigate } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";

function sumByKey(data, key) {
  return data.reduce((accumulator, currentItem) => {
    return accumulator + (currentItem[key] || 0);
  }, 0);
}

function CampaignUtmLinksAnalysis() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("linkClicked");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({
    data: [],
    total: {
      linkClicked: 0,
      walletConnected: 0,
      objectiveAchieved: 0,
      conversionRate: 0,
    },
  });

  const [entityName, isCustomDomain] = useOutletContext();
  const dispatchSnackbar = useDispatchSnackbar();
  const [needUpdate, setNeedUpdate] = useState(false);

  const PAGE_COUNT = 20;

  const { id } = useParams();

  const { linkClicked, walletConnected, objectiveAchieved, conversionRate } =
    data?.total || {
      linkClicked: 0,
      walletConnected: 0,
      objectiveAchieved: 0,
      conversionRate: 0,
    };

  const getPerformanceData = async (e) => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios.get(
        `${apiUrl}/api/campaignPerformance/${entityName}/detail/${id}/utms?search=${search}&sort=${orderBy}&order=${
          order === "asc" ? 1 : -1
        }`
      );
      const total = {
        linkClicked: sumByKey(data, "linkClicked"),
        walletConnected: sumByKey(data, "walletConnected"),
        objectiveAchieved: sumByKey(data, "objectiveAchieved"),
        conversionRate:
          isNaN(
            sumByKey(data, "objectiveAchieved") / sumByKey(data, "linkClicked")
          ) || sumByKey(data, "linkClicked") === 0
            ? 0
            : sumByKey(data, "objectiveAchieved") /
              sumByKey(data, "linkClicked"),
      };

      const newData = {
        data,
        total,
      };

      setData(newData);
      setTotalCount(get(headers, "x-total-count", 0));
    } catch (error) {
      dispatchSnackbar({
        message: error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // 用 needUpdate 來判斷是否需要更新
  useEffect(() => {
    if (!needUpdate) return;
    getPerformanceData();
    setNeedUpdate(false);
  }, [needUpdate]);

  useEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      setNeedUpdate(true);
    }
  }, [search, order, orderBy]);

  // 當切換頁數時，重新取得資料
  useEffect(() => {
    setNeedUpdate(true);
  }, [page]);

  const handleSearch = (search) => {
    setSearch(search);
    // getPerformanceData();
  };

  const headerContent = ({ content, type }) => (
    <Box height="30px" my="8px" display="flex" alignItems="center">
      {type === "number" ? (
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            color: "#383538",
          }}
        >
          {content}
        </Box>
      ) : (
        content
      )}
    </Box>
  );

  const headCells = [
    {
      show: true,
      width: 200,
      disabledSort: true,
      id: "link",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Box display="flex" alignItems="center">
            <Box mr="5px">
              {formatMessage({
                id: "trackerTab.linkLayer.tableName.firstOriginalLink",
              })}
            </Box>
            /
            <Box color={(theme) => theme.customColors.purple[500]} ml="5px">
              {formatMessage({
                id: "trackerTab.linkLayer.tableName.firstShortLink",
              })}
            </Box>
          </Box>
        </div>
      ),
      title: formatMessage({ id: "filter.utmFiltering.title" }),
    },
    {
      show: true,
      disabledSort: false,
      width: 125,
      id: "linkClicked",
      label: (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box className="startRow">
            <Box className="mgr4">
              {formatMessage({ id: "analysis.columnName.linkClicked.title" })}
            </Box>
            <IconTooltip
              content={formatMessage({
                id: "analysis.columnName.clicks.tooltip",
              })}
            />
          </Box>
          {headerContent({ type: "number", content: linkClicked || 0 })}
        </Box>
      ),
      title: formatMessage({ id: "analysis.columnName.linkClicked.title" }),
    },
    {
      show: true,
      disabledSort: false,
      width: 185,
      id: "walletConnected",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "analysis.columnName.walletsConnected.title" })}
          {headerContent({ type: "number", content: walletConnected || 0 })}
        </div>
      ),
      title: formatMessage({
        id: "analysis.columnName.walletsConnected.title",
      }),
    },
    {
      show: true,
      disabledSort: false,
      id: "objectiveAchieved",
      width: 185,
      label: (
        <div>
          <Box>
            {formatMessage({
              id: "analysis.columnName.objectiveAchieved.title",
            })}
          </Box>
          {headerContent({ type: "number", content: objectiveAchieved || 0 })}
        </div>
      ),
      title: formatMessage({
        id: "analysis.columnName.objectiveAchieved.title",
      }),
    },
    {
      show: true,
      disabledSort: false,
      width: 160,
      id: "conversionRate",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "analysis.columnName.conversionRate.title" })}
          {headerContent({
            type: "number",
            content: conversionRate.toFixed(2) + "%",
          })}
        </div>
      ),
      title: formatMessage({ id: "analysis.columnName.conversionRate.title" }),
    },
  ].filter(Boolean);

  const handleRowClick = (targetId, row) => {
    console.log("targetId: ", targetId);
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/marketing/dashboard/campaign/${id}?utmLink=${targetId}`
      )
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Back />
      <Box sx={{ marginBottom: "24px" }}>
        <Box className={"title"} display={"flex"} alignItems={"center"}>
          {formatMessage({ id: "trackerTab.linkLayer.header.title" })}

          <Box display={"flex"} sx={{ fontSize: "16px", marginLeft: "8px" }}>
            (&nbsp;
            <Box color={(theme) => theme.customColors.purple[500]} mr="5px">
              {data.data.length}
            </Box>
            Links )
          </Box>
        </Box>
      </Box>
      <Box position={"relative"}>
        <Loading fullScreen={false} white open={isLoading} topPos={"30vh"} />
        <PerformanceUtmLinksSearch
          search={search}
          setSearch={setSearch}
          onSearch={handleSearch}
        />
        <PerformanceUtmLinksTable
          data={data}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default CampaignUtmLinksAnalysis;

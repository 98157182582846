import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

import {
  Button,
  VARIANT,
  COLOR,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";

import { parseCustomDomainUrl } from "features/helpers/utils";

import {
  EntityItemContainer,
  EntityLogo,
  EntityMembers,
  ImageContainer,
  NavigateAction,
} from "./EntityItem.styled";

const IC_CONFIG = {
  lg: {
    getImageContainerLen: (len) => len * 15 + 5,
    getLeftOffset: (idx) => idx * 15,
    height: 20,
    width: 20,
  },
  md: {
    getImageContainerLen: (len) => len * 12 + 3,
    getLeftOffset: (idx) => idx * 12,
    height: 15,
    width: 15,
  },
};

const DEFAULT_LOGO = require("assets/img/header.svg").default;

export default function EntityItem({ entity }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const totalMemberCount = entity.memberCount;
  const iterations = Array.from(
    { length: Math.min(totalMemberCount, 5) },
    (_, index) => index + 1
  );
  const config = computer ? IC_CONFIG.lg : IC_CONFIG.md;
  const imageContainerLen = config.getImageContainerLen(iterations.length);
  const entityLogoUrl = entity.company ? entity.company.logo : DEFAULT_LOGO;
  const isCustomDomain = Boolean(process.env.REACT_APP_ENTITY);

  const { formatMessage } = useIntl();

  function handleRedirect() {
    if (entity.name === "radiant") {
      window.location.href = "https://team.radiant.capital/";
    } else if (entity.name === "curve") {
      window.location.href = "https://admin.curve.repair/";
    } else {
      navigate(parseCustomDomainUrl(isCustomDomain, entity.name, "/"));
      window.location.reload();
    }
  }

  return (
    <EntityItemContainer>
      <EntityLogo>
        <img src={entityLogoUrl} alt="entity logo" width={143} height={46} />
      </EntityLogo>
      <EntityMembers>
        <ImageContainer width={imageContainerLen} height={config.height}>
          {iterations.map((n, idx) => {
            return (
              <img
                key={`image-${n}`}
                src={require(`assets/img/workspace_ic/ic_alpaca-${n}.png`)}
                width={config.width}
                height={config.height}
                style={{
                  position: "absolute",
                  left: `${config.getLeftOffset(idx)}px`,
                }}
              />
            );
          })}
        </ImageContainer>
        <p>{totalMemberCount} people</p>
      </EntityMembers>
      <NavigateAction>
        <Button
          style={computer ? { width: 142, height: 36 } : { height: 30 }}
          color={COLOR.SECONDARY}
          variant={VARIANT.CONTAINED}
          size={SIZE.XL}
          onClick={handleRedirect}
        >
          {formatMessage({ id: "global.btn.go" })}
        </Button>
      </NavigateAction>
    </EntityItemContainer>
  );
}

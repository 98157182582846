import React, { useState, createContext, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { setAdvancedSorting } from "features/metadesk/redux/globalState/globalState.action";
import { selectIsAdvancedSorting } from "features/metadesk/redux/globalState/globalState.selector";
import { useDispatch, useSelector } from "react-redux";
import { setStorage } from "features/helpers/storage";

export const PerformanceContext = createContext();

// 提供器组件
export const PerformanceProvider = ({ children }) => {
  // 从 URL 中获取参数
  const urlSearchParams = new URLSearchParams(window.location.search);
  const initialSearchParams = urlSearchParams.get("search") || "";
  const initialPromotypeParams = urlSearchParams.get("promotype") || "widget";
  const initialChannelParams = urlSearchParams.get("channel") || "all";
  const [searchParams, setSearchParams] = useSearchParams({
    search: initialSearchParams,
    promotype: initialPromotypeParams,
    channel: initialChannelParams,
  });
  const [dimension, setDimension] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();

  const isAdvanced = useSelector(selectIsAdvancedSorting);
  const setIsAdvanced = (value) => {
    dispatch(setAdvancedSorting(value));
    setStorage("isAdvancedSorting", value);
  };
  const [isLoading, setIsLoading] = useState({
    chart: false,
    table: false,
    switchTab: false,
  });

  useEffect(() => {
    const promotype = searchParams.get("promotype");

    if (promotype === "tracker") {
      setDimension("");
    }
  }, [searchParams.get("promotype"), isAdvanced]);

  function setParams(value) {
    const currentParams = Object.fromEntries(searchParams.entries());
    const promotype = value?.promotype;
    const newParams = { ...currentParams, ...value };
    if (promotype && promotype !== "flyer") {
      delete newParams.channel;
    }
    setSearchParams(newParams);
  }

  return (
    <Box position={"relative"}>
      <PerformanceContext.Provider
        value={{
          search: searchParams.get("search") || "",
          setSearch: (search) => setParams({ search }),
          promotype: searchParams.get("promotype") || "Flyer",
          setPromotype: (promotype) => setParams({ promotype }),
          channel: searchParams.get("channel") || "",
          setChannel: (channel) => setParams({ channel }),
          setParams,
          dimension,
          setParams,
          setDimension,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          isLoading,
          setIsLoading,
          isAdvanced,
          setIsAdvanced,
        }}
        style={{ height: "100%" }}
      >
        {children}
      </PerformanceContext.Provider>
    </Box>
  );
};

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { get, isEmpty } from "lodash-es";
import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useDispatch } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledCustomizationContainer,
  StyledLine,
} from "./OnChainDataSource.styles";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { alertServerError } from "features/helpers/utils";
import { isAddress } from "features/helpers/utils";
import { useTheme } from "@mui/styles";
import TokenDisplayPreference from "./TokenDisplayPreference";
import NFTDisplayPreference from "./NFTDisplayPreference";
import TxDisplayPreference from "./TxDisplayPreference";
import PortfolioPreference from "./PortfolioPreference";
import useGetPagePermission from "hooks/useGetPagePermission";
import OnChainDataSourceProvider from "./OnChainDataSource.context";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import { StyledWhiteContainer } from "../../common.styles";

const formDataInit = {
  txDisplayList: [{}],
  nftDisplayList: [{}],
};

const OnChainDataSource = () => {
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });

  const {
    entityConfig,
    updateEntityConfig,
    updateEntityConfigPending,
    fetchEntityConfig,
  } = useFetchEntityConfig();
  const dispatch = useDispatch();
  const ecosystemName = useSelector(selectEcosystemName);

  const [entityName] = useOutletContext();
  const theme = useTheme();
  const [isEqualData, setIsEqualData] = useState(true);

  const [tokenDisplayChains, setTokenDisplayChains] = useState(
    _.get(entityConfig, "chainIds", [])
  );
  const [nftDisplayList, setNftDisplayList] = useState(
    _.get(entityConfig, "nftInfo", [])
  );
  const [txDisplayList, setTxDisplayList] = useState(
    _.get(entityConfig, "smartContractInfo", [{}])
  );
  const [fetchGeneralTx, setFetchGeneralTx] = useState(
    _.get(entityConfig, "fetchGeneralTx", "false").toString()
  );
  const [portfolioTokensData, setPortfolioTokensData] = useState(
    _.cloneDeep(_.get(entityConfig, "tokenProfile", {}))
  );

  const isAnyEmptyInList = () => {
    return (
      txDisplayList.some((obj) => {
        if (!obj.address) {
          return true;
        }
        // 如果 ecosystemName 是 "EVM"，檢查地址是否有效
        if (ecosystemName === "EVM" && !isAddress(obj.address)) {
          return true;
        }

        return false;
      }) ||
      nftDisplayList.some((obj) => {
        if (!obj.address) {
          return true;
        }
        // 如果 ecosystemName 是 "EVM"，檢查地址是否有效
        if (ecosystemName === "EVM" && !isAddress(obj.address)) {
          return true;
        }

        return false;
      })
    );
  };

  useEffect(() => {
    let isEqual = true;
    if (
      !_.isEqual(
        fetchGeneralTx,
        _.get(entityConfig, "fetchGeneralTx", "false").toString()
      )
    ) {
      isEqual = false;
    }
    if (
      !_.isEqual(txDisplayList, _.get(entityConfig, "smartContractInfo", []))
    ) {
      isEqual = false;
    }
    if (!_.isEqual(nftDisplayList, _.get(entityConfig, "nftInfo", []))) {
      isEqual = false;
    }
    if (
      !_.isEqual(
        _.map(_.get(portfolioTokensData, "tokens", []), "address"),
        _.map(_.get(entityConfig, "tokenProfile.tokens", []), "address")
      )
    ) {
      isEqual = false;
    }

    if (
      !_.isEqual(
        _.get(portfolioTokensData, "name"),
        _.get(entityConfig, "tokenProfile.name")
      )
    ) {
      isEqual = false;
    }
    if (
      !_.isEqual(
        _.get(portfolioTokensData, "icon"),
        _.get(entityConfig, "tokenProfile.icon")
      )
    ) {
      isEqual = false;
    }

    if (
      !_.isEqual(_.sortBy(tokenDisplayChains), _.sortBy(entityConfig.chainIds))
    ) {
      isEqual = false;
    }

    setIsEqualData(isEqual);
  }, [
    txDisplayList,
    nftDisplayList,
    portfolioTokensData,
    tokenDisplayChains,
    entityConfig,
    fetchGeneralTx,
  ]);

  const isValid = !isEqualData && !isAnyEmptyInList();

  useUnload((e) => {
    if (!isEqualData) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const handleSave = async () => {
    try {
      await updateEntityConfig({
        entityName,
        chainIds: tokenDisplayChains,
        nftInfo: nftDisplayList,
        fetchGeneralTx,
        smartcontractInfo: txDisplayList,
        tokenProfile: portfolioTokensData,
      });
      fetchEntityConfig({ entityName });
      dispatch(
        enqueueSnackbar({
          message: (
            <div
              style={{
                color: theme.customColors.purple[500],
              }}
              className="formTitle startRow"
            >
              Configs has been updated!
            </div>
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "info",
          },
        })
      );
    } catch (error) {
      console.error("error: ", error);
      dispatch(alertServerError(error));
    }
  };

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");
  const renderNFTDisplay = () => {
    if (ecosystemName === "EVM") {
      return (
        <>
          <NFTDisplayPreference
            nftDisplayList={nftDisplayList}
            setNftDisplayList={setNftDisplayList}
          />
          <StyledLine></StyledLine>
        </>
      );
    } else {
      return null;
    }
  };

  const renderTxDisplay = () => {
    if (ecosystemName === "EVM") {
      return (
        <>
          <TxDisplayPreference
            fetchGeneralTx={fetchGeneralTx}
            setFetchGeneralTx={setFetchGeneralTx}
            txDisplayList={txDisplayList}
            setTxDisplayList={setTxDisplayList}
          />
          <StyledLine></StyledLine>
        </>
      );
    } else {
      return null;
    }
  };

  const renderPortfolioDisplay = () => {
    if (ecosystemName === "EVM") {
      return (
        <PortfolioPreference
          setPortfolioTokensData={setPortfolioTokensData}
          portfolioTokensData={portfolioTokensData}
          entityName={entityName}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <Box mb="20px">
        <Box className="title">On-chain Data Source</Box>
        <Box className="description">
          Create your ticket types and set up CSAT.
        </Box>
      </Box>
      <StyledWhiteContainer>
        <OnChainDataSourceProvider>
          {updateEntityConfigPending ? (
            <Box
              sx={{
                minHeight: "50vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingComponent />
            </Box>
          ) : (
            <StyledCustomizationContainer>
              <Box>
                <TokenDisplayPreference
                  tokenDisplayChains={tokenDisplayChains}
                  setTokenDisplayChains={setTokenDisplayChains}
                  entityName={entityName}
                />
                <StyledLine></StyledLine>
                <NFTDisplayPreference
                  nftDisplayList={nftDisplayList}
                  setNftDisplayList={setNftDisplayList}
                />
                <StyledLine></StyledLine>
                <TxDisplayPreference
                  fetchGeneralTx={fetchGeneralTx}
                  setFetchGeneralTx={setFetchGeneralTx}
                  txDisplayList={txDisplayList}
                  setTxDisplayList={setTxDisplayList}
                />
                <StyledLine></StyledLine>
                <PortfolioPreference
                  setPortfolioTokensData={setPortfolioTokensData}
                  portfolioTokensData={portfolioTokensData}
                  entityName={entityName}
                />
                {canEdit ? (
                  <>
                    <FixedButton>
                      <Button
                        sx={{ width: "120px", marginLeft: "8px" }}
                        onClick={handleSave}
                        disabled={!isValid}
                      >
                        Save
                      </Button>
                    </FixedButton>
                    <Prompt when={!isEqualData} />
                  </>
                ) : null}
              </Box>
            </StyledCustomizationContainer>
          )}
        </OnChainDataSourceProvider>
      </StyledWhiteContainer>
    </FormProvider>
  );
};

export default OnChainDataSource;

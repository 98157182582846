import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ListItem, ListItemSecondaryAction } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import _ from "lodash";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import {
  StatusLabel,
  SIZE,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { Box } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  listItems: {
    color: `#555555`,
    marginLeft: 0,
    fontSize: 12,
    fontWeight: 600,
    padding: "13px 13px",
    paddingRight: 0,
    marginTop: 0,
    minHeight: 36,

    marginBottom: "5px",
    "&.Mui-selected": {
      backgroundColor: `#F7F1FE`,
      color: "#5643CC",
      fontWeight: "bold",
    },
    "&:hover": {
      color: `#7B61FF`,
      background: "transparent",
      "&.Mui-selected": {
        backgroundColor: `#F7F1FE`,
        color: "#5643CC",
        fontWeight: "bold",
      },
    },
  },
  subItem: {
    padding: "8px 8px",
    minHeight: 28,
    paddingLeft: 15,
    paddingRight: 0,
    marginBottom: 4,
    borderRadius: 0,
  },
  subMenu: {
    marginBottom: 4,
    marginTop: 4,
  },
  subLabel: {
    fontWeight: 600,
    marginLeft: 10,
    width: "100%",
  },
  menu: {
    "&:hover": { backgroundColor: "transparent", color: `#7B61FF` },
    "&.Mui-selected": {
      backgroundColor: `#F7F1FE`,
      color: "#5643CC",
      fontWeight: "bold",
    },
  },
  listHeader: {
    fontSize: 12,
    fontWeight: 500,
    minHeight: 36,
    borderTop: "1px solid #E6E6E6",
    color: "#A5A5A5",
    "&:hover": { backgroundColor: `transparent` },
  },
  listContent: {
    fontSize: 12,
    padding: 0,
  },
  error: {
    border: "1px solid #FF3296",
  },
  formPage: {},
  selected: {
    color: "#5643CC",
    fontWeight: "bold",
    backgroundColor: `#F7F1FE`,
    borderRight: "3px solid #7B61FF",
  },
  menuSelected: {
    backgroundColor: `#F7F1FE`,
    color: "#5643CC",
    fontWeight: "bold",
  },
  subMenuSelected: {
    backgroundColor: `transparent`,
    color: "#7B61FF",
    fontWeight: "bold",
    borderRight: "0px",
  },

  disabled: {
    color: "#A5A5A5",
  },

  arrows: {
    width: 18,
    height: 18,
    marginRight: 5,
    color: (props) => (props.open ? "#5643CC" : ""),
  },
}));

export default function CustomListItem({
  label,
  icon,
  onClick,
  header,
  content,
  rightIcon,
  formPage,
  error,
  selected,
  disabled,
  right,
  enabled = true,
  isMenu = false,
  isSubMenu = false,
  subItems = [],
  isNew,
  handleSubItemClick = () => {},
  defaultOpen = true,
  disableSubMenuSelected,
  ...props
}) {
  const theme = useTheme();

  const [menuOpen, setMenuOpen] = useState(defaultOpen);

  const classes = useStyles({ open: menuOpen });

  const handleClick = (e) => {
    if (!menuOpen) setMenuOpen(true);
    if (subItems.length > 0) {
      handleItemClick(subItems[0]);
    }
    if (onClick) onClick();
  };

  const subMenuSelected =
    !disableSubMenuSelected && _.find(subItems, { selected: true });

  const renderNew = () => {
    return <StatusLabel color={COLOR.WARNING}>New</StatusLabel>;
  };

  function toggleArrow() {
    if (disabled) return;
    setMenuOpen((prev) => !prev);
  }

  const handleItemClick = (subItem) => {
    if (subItem.disabled) return;

    handleSubItemClick(subItem);
  };

  return (
    <Box position={"relative"}>
      <ListItem
        button={!header}
        onClick={() => handleClick()}
        disabled={disabled}
        classes={{
          root: `${classes.listItems} ${header && classes.listHeader} ${
            content && classes.listContent
          } ${formPage && classes.formPage} ${
            (selected || subMenuSelected) && classes.selected
          } ${error && classes.error} ${disabled && classes.disabled} ${
            isMenu && classes.menu
          } ${isSubMenu && subMenuSelected && classes.subMenuSelected}`,
        }}
        {...props}
      >
        <Box>{icon}</Box>
        <Box style={{ overflow: "hidden" }}>{label}</Box>

        {!isMenu && (
          <ListItemSecondaryAction className="row" style={{ right: 4 }}>
            {isNew && renderNew()}
            {rightIcon}
            {error && (
              <ErrorOutlineIcon
                style={{
                  color: "#FF3296",
                  verticalAlign: "middle",
                  margin: 5,
                  fontSize: 11,
                }}
              />
            )}
          </ListItemSecondaryAction>
        )}

        <ListItemSecondaryAction className="row" style={{ right: 4 }}>
          {isNew && renderNew()}
          {isMenu && (
            <div
              onClick={() => toggleArrow()}
              style={{ marginLeft: "auto", display: "flex", cursor: "pointer" }}
            >
              {menuOpen ? (
                <ExpandLessIcon className={classes.arrows} />
              ) : (
                <ExpandMoreIcon className={classes.arrows} />
              )}
            </div>
          )}
          {rightIcon && rightIcon}

          {error && (
            <ErrorOutlineIcon
              style={{
                color: "#FF3296",
                verticalAlign: "middle",
                margin: 5,
                fontSize: 11,
              }}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>{" "}
      {isMenu && !_.isEmpty(subItems) && (
        <div className={classes.subMenu}>
          <Collapse in={menuOpen} timeout="auto" unmountOnExit>
            {subItems.map((subItem, i) => {
              if (subItem.isMenu) {
                return (
                  <CustomListItem
                    isSubMenu={true}
                    key={subItem.id || i}
                    label={subItem.label}
                    disabled={subItem.disabled}
                    isMenu={true}
                    style={{
                      paddingLeft: 23,
                      minHeight: 28,
                    }}
                    handleSubItemClick={(sub) => handleItemClick(sub)}
                    subItems={_.map(subItem.subItems, (i) => {
                      return { ...i, style: { paddingLeft: 31 } };
                    })}
                    defaultOpen={defaultOpen}
                  />
                );
              }
              return (
                <ListItem
                  key={i}
                  button={!header && !content}
                  onClick={() => handleItemClick(subItem)}
                  classes={{
                    root: `${classes.listItems} ${classes.subItem} ${
                      header && classes.listHeader
                    } ${content && classes.listContent} ${
                      formPage && classes.formPage
                    } ${subItem.selected && classes.menuSelected} ${
                      error && classes.error
                    } ${disabled && classes.disabled} `,
                  }}
                  style={{
                    background: subItem.background,
                    ...subItem.style,
                  }}
                >
                  <div
                    style={{ overflow: "hidden" }}
                    className={classes.subLabel}
                  >
                    {subItem.label}
                  </div>
                  {subItem.rightIcon && (
                    <ListItemSecondaryAction className={`row `}>
                      <span
                        className={subItem.selected && classes.menuSelected}
                      >
                        {subItem.rightIcon}
                      </span>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
          </Collapse>
        </div>
      )}
    </Box>
  );
}

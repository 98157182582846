import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledMainContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: 1200,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  // Media queries for responsive styles
  [theme.breakpoints.down("lg")]: {
    width: 900,
  },
  [theme.breakpoints.down("md")]: {
    width: 600,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

import React, { useState } from "react";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import { Box } from "@mui/material";
import OTPInput from "react-otp-input";
import { StyledOtpInput } from "./Trial.styles";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";

export default function TrialStep2({ nextStep, stepData, handleStepData }) {
  const numInputs = 4;
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  async function handleVerifyEmail() {
    try {
      setError(false);
      setIsLoading(true);
      const { data } = await axios.post(`${apiUrl}/api/entity/newVerify`, {
        registerId: stepData.registerId,
        verifyCode: otp,
      });

      nextStep();
    } catch (err) {
      // dispatchSnackbar({ err });
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSendVerifyEmail() {
    try {
      setIsLoading(true);

      const { data } = await axios.post(
        `${apiUrl}/api/entity/newSendVerificationEmail`,
        {
          username: stepData.name,
          email: stepData.email,
        }
      );
      handleStepData("registerId", data.registerId);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Box
      maxWidth="400px"
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box color="#383538" fontSize="24px" fontWeight={700} mb="4px">
        Check Your email for the code
      </Box>
      <Box color="#777" fontSize="14px" mb="64px">
        Please enter the confirmation code sent to your email address{" "}
        <span style={{ color: "#7B61FF", fontWeight: 700 }}>
          {stepData.email}{" "}
        </span>
      </Box>
      <Box width={"290px"} textAlign="left">
        <Box mb="14px" fontSize="14px" color={error ? "#FC5555" : "#555"}>
          Confirmation code
        </Box>

        <StyledOtpInput error={error}>
          <OTPInput
            className="xxx"
            value={otp}
            onChange={setOtp}
            numInputs={numInputs}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              textAlign: "center",
              maxWidth: "23%",
              fontSize: "28px",
              fontWeight: 900,
            }}
            focusStyle={{
              outline: "none",
              border: "2px solid #05DEBA",
              borderRadius: "8px",
            }}
            skipDefaultStyles={true}
            inputType="number"
            shouldAutoFocus={true}
          />
        </StyledOtpInput>
        {error && (
          <Box color={"#FC5555"} mb={"12px"}>
            Invalid confirmation code. Please check the code in your email and
            try again.
          </Box>
        )}
        <Box color="#777" fontSize="12px" lineHeight={"normal"}>
          Can’t find the email? Check your email address or click{" "}
          <span
            style={{
              fontWeight: 700,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleSendVerifyEmail}
          >
            here
          </span>{" "}
          to resent.
        </Box>
      </Box>

      <Button
        disabled={otp.length < 4}
        onClick={handleVerifyEmail}
        sx={{ mt: "32px" }}
        isLoading={isLoading}
      >
        Verify
      </Button>
    </Box>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { InterceptorContextProvider } from "features/home/InterceptorContext";
import { selectTrialEntityName } from "features/metadesk/redux/trial/trial.selector";

import WorkspaceWrapper from "../components/WorkspaceWrapper";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";

const WorkspaceLogin = () => {
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const { address, isConnected } = useAccount({});
  const entityName = useSelector(selectTrialEntityName);
  const navigate = useNavigate();

  useEffect(() => {
    if (isConnected) {
      navigate(`/workspace`);
    }
  }, [address]);

  return (
    <>
      <WorkspaceWrapper isShowBrandIcon={false} isShowHelperMsg={false}>
        <InterceptorContextProvider isTrial={true}>
          <ConnectWallet
            isCustomDomain={isCustomDomain}
            defaultOpen={true}
            entityName={entityName}
            isTrial={true}
          />
        </InterceptorContextProvider>
      </WorkspaceWrapper>
    </>
  );
};

export default WorkspaceLogin;

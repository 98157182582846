import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { formatDateAndTime, getShortAddress } from "features/helpers/utils";
import _ from "lodash";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import moment from "moment";
import { useFetchTags } from "features/metadesk/redux/hooks";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import Impersonator from "components/Impersonator/Impersonator";
import { getWeb3Url } from "features/helpers/web3";
import axios from "axios";
import { apiUrl, entityConfig } from "features/configure";
import { useDispatch } from "react-redux";
import { useFetchUserWalletInfo } from "../redux/fetchUserWalletInfo";
import { getUserAvatarIcon } from "../utils";
import { useIntl } from "react-intl";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  messageDetail: {
    background: "#FFFFFF",
    padding: 10,
    height: "calc(100vh - 137px)",
    margin: 10,
  },
  userWalletInfoDetails: {
    padding: "8px 12px",

    position: "relative",
    background: "#ffffff",
    borderBottom: "1px solid #D6d6d6",
  },
  userWalletInfo: {
    background: "#FFFFFF ",
    borderRadius: "10px !important",
    height: "60px",
  },
  grey: { color: "#777777", paddingBottom: 2, fontWeight: 400, fontSize: 12 },
  time: { fontSize: 12, fontWeight: 500 },
}));

const UserTitle = ({
  selectedUserId,
  selectedUserAddress,
  extra,
  entityName,
  paddingLeft,
  fetchUserObjs,
}) => {
  const dispatch = useDispatch();
  const { fetchUserWalletInfo, userWalletInfos, refreshWalletInfo } =
    fetchUserObjs();
  const theme = useTheme();

  const userWalletInfo =
    _.get(userWalletInfos, `[${selectedUserAddress}]`, {}) || {};

  const classes = useStyles();
  const [systemTags, setSystemTags] = useState([]);
  const [openImpersonator, setOpenImpersonator] = useState(false);
  const [selectedMirrorChain, setSelectedMirrorChain] = useState(0);
  const { formatMessage } = useIntl();

  useEffect(() => {
    let newTags = [];
    let walletValue = _.get(userWalletInfo, "walletValue", 0);
    let onChainValue = _.get(userWalletInfo, "onChainValue", 0);
    let nftValue = _.get(userWalletInfo, "nftValue", 0);
    let nftCount = _.get(userWalletInfo, "nftCount", 0);

    if (walletValue >= 200000) {
      newTags.push({
        value: "Whale ",
        subLabel: "(Token)",
        color: "#FF95DD",
        tooltip: "whale.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.walletValue",
          },
          { value: ">$200k" }
        ),
      });
    } else if (walletValue >= 10000 && walletValue < 200000) {
      newTags.push({
        value: "Dolphin ",
        subLabel: "(Token)",
        color: "#FFD7F2",
        tooltip: "dolphin.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.walletValue",
          },
          { value: "between $10k~200k" }
        ),
      });
    } else if (walletValue > 0 && walletValue < 10000) {
      newTags.push({
        value: "Shrimp ",
        subLabel: "(Token)",
        color: "#FFF1FB",
        tooltip: "shrimp.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.walletValue" },
          { value: "<$10k" }
        ),
      });
    }
    // onchain value
    if (onChainValue >= 100000) {
      newTags.push({
        value: "Whale ",
        subLabel: "(Smart Contract)",
        color: "#FFCC7E",
        tooltip: "whale.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.onChainValue",
          },
          { value: ">$100k" }
        ),
      });
    } else if (onChainValue >= 10000 && onChainValue < 100000) {
      newTags.push({
        value: "Dolphin ",
        subLabel: "(Smart Contract)",
        color: "#FFF8BD",
        tooltip: "dolphin.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.onChainValue",
          },
          { value: "between $10k~100k" }
        ),
      });
    } else if (onChainValue > 0 && onChainValue < 10000) {
      newTags.push({
        value: "Shrimp ",
        subLabel: "(Smart Contract)",
        color: "#FFF8E0",
        tooltip: "shrimp.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.onChainValue",
          },
          { value: "<$10k" }
        ),
      });
    }
    // nft value
    if (nftValue >= 200000) {
      newTags.push({
        value: "Whale ",
        subLabel: "($NFT)",
        color: "#BDB4FE",
        tooltip: "whale.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nftValue" },
          { value: ">$200k" }
        ),
      });
    } else if (nftValue >= 10000 && nftValue < 200000) {
      newTags.push({
        value: "Dolphin ",
        subLabel: "($NFT)",
        color: "#E8D8FF",
        tooltip: "dolphin.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.nftValue",
          },
          { value: "between $10k~200k" }
        ),
      });
    } else if (nftValue > 0 && nftValue < 10000) {
      newTags.push({
        value: "Shrimp ",
        subLabel: "($NFT)",
        color: "#F3EAFF",
        tooltip: "shrimp.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nftValue" },
          { value: "<$10k" }
        ),
      });
    }

    //nft count
    if (nftCount >= 50) {
      newTags.push({
        value: "#NFT 50+",
        color: "#BCBCBC",
        tooltip: "NTFLevel3.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nft" },
          { value: ">50" }
        ),
      });
    } else if (nftCount >= 30 && nftCount < 50) {
      newTags.push({
        value: "#NFT 30~50",
        color: "#D1D1D1",
        tooltip: "NTFLevel2.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nft" },
          { value: "between 30~50" }
        ),
      });
    } else if (nftCount >= 10 && nftCount < 30) {
      newTags.push({
        value: "#NFT 10~30",
        color: "#E4E4E4",
        tooltip: "NTFLevel1.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nft" },
          { value: "between 10~30" }
        ),
      });
    } else if (nftCount >= 1 && nftCount < 10) {
      newTags.push({
        value: "#NFT < 10",
        color: "#F3F3F3",
        tooltip: "NTFLevel0.svg",
        desc: formatMessage(
          { id: "userInfoContent.userTitle.nft" },
          { value: "<10" }
        ),
      });
    }

    let addressAge = _.get(userWalletInfo, "updatedList")
      ? moment().diff(
          moment(
            _.min(_.map(userWalletInfo.updatedList, "firstTransactionDate"))
          ),
          "years",
          true
        )
      : "N/A";

    //address status
    if (addressAge >= 3) {
      newTags.push({
        value: "Crypto OG",
        color: "#2CF0E3",
        tooltip: "OG.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.addressCreation",
          },
          { value: "> 3 years" }
        ),
      });
    } else if (addressAge >= 1 && addressAge < 3) {
      newTags.push({
        value: "Crypto Regular",
        color: "#ADFFF5",
        tooltip: "normal.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.addressCreation",
          },
          { value: "between 1~3 years" }
        ),
      });
    } else if (addressAge != "N/A") {
      newTags.push({
        value: "Crypto Newbie",
        color: "#DAFFFD",
        tooltip: "newbie.svg",
        desc: formatMessage(
          {
            id: "userInfoContent.userTitle.addressCreation",
          },
          { value: "<1 year" }
        ),
      });
    }
  }, [selectedUserAddress, userWalletInfo]);

  const getAddress = (isShort) => {
    const walletInfoAddress = _.get(userWalletInfo, "address");
    if (paddingLeft) return selectedUserAddress;
    return isShort ? getShortAddress(walletInfoAddress) : walletInfoAddress;
  };
  const didName = _.get(userWalletInfo, "didNames[0].name");
  const icon = getUserAvatarIcon(selectedUserId, theme, didName);
  return (
    <div>
      <Impersonator
        address={selectedUserAddress}
        webUrl={
          _.get(entityConfig, `[${entityName}]`, entityConfig.default)[
            "mirrorUrl"
          ]
        }
        rpcURL={getWeb3Url(selectedMirrorChain)} // TODO: change to selected chain
        open={openImpersonator}
        closeAction={() => {
          setSelectedMirrorChain(null);

          setOpenImpersonator(false);
        }}
        chainId={selectedMirrorChain}
      />
      <div className={classes.userWalletInfoDetails}>
        <div
          style={{
            fontWeight: 500,
            fontSize: 18,
          }}
          className="betweenRow"
        >
          <div className="startRow" style={{ alignItems: "flex-start" }}>
            <div
              className="startRow"
              style={{
                color: "#777777",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <img
                src={require(`assets/img/${icon}`)}
                className="smIcon"
                style={{ marginRight: 10, borderRadius: 20 }}
              />
              <span style={{ fontSize: paddingLeft ? 20 : 14 }}>
                {getAddress(true)}
              </span>
              <IconButton
                size={18}
                style={{ marginLeft: 4 }}
                onClick={() => {
                  navigator.clipboard.writeText(getAddress(false));
                }}
              >
                <i className="meta-crm-icon-ic_copy2 font-size-18" />
              </IconButton>
              <span
                className="startRow"
                style={{
                  border: "1px solid #D6D6D6",
                  borderRadius: 2,
                  color: "#777777",
                  padding: "2px 5px",
                  borderRadius: 3,
                  transform: "scale(0.8)",
                }}
              >
                {formatMessage({ id: "global.header.lastUpdated" })}:
                <br /> {moment(userWalletInfo.updated).format("MM/DD HH:mm")}
                <IconButton
                  size={28}
                  style={{ marginLeft: 4 }}
                  onClick={async () => {
                    refreshWalletInfo({
                      address: selectedUserAddress,
                      entityName: entityName,
                    });
                  }}
                >
                  <i className="meta-crm-icon-ic_refresh font-size-32" />
                </IconButton>
              </span>
            </div>
          </div>
          {_.get(entityConfig, `[${entityName}]`, entityConfig.default)[
            "mirrorUrl"
          ] && (
            <span style={{ marginLeft: 10 }}>
              <CustomDropdown
                border
                sm
                placeholder={
                  <div className="startRow" style={{ flexWrap: "nowrap" }}>
                    <i
                      className="meta-crm-icon-ic_mirror font-size-28"
                      style={{ color: "#000000" }}
                    />
                    {formatMessage({ id: "userInfoContent.userTitle.mirror" })}
                  </div>
                }
                data={_.get(entityConfig, `[${entityName}].chains`, [])}
                value={selectedMirrorChain}
                onSelect={(e) => {
                  setSelectedMirrorChain(e.target.value);
                  setOpenImpersonator(true);
                }}
              />
            </span>
          )}
        </div>
      </div>
      <div
        className={classes.userWalletInfoDetails}
        style={{
          minHeight: 100,
          paddingLeft: paddingLeft ? "65px" : "0",
          padding: "5px 12px",
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            {extra && extra}
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                textAlign: "right",
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <div className={classes.grey}>
                {formatMessage({
                  id: "userInfoContent.userTitle.lastTransaction",
                })}
              </div>
              <div className={classes.grey}>
                {formatDateAndTime(
                  _.max(
                    _.map(userWalletInfo.updatedList, "latestTransactionDate")
                  ) || "-"
                )}
              </div>
              <div className={classes.grey} style={{ marginTop: 6 }}>
                {formatMessage({
                  id: "userInfoContent.userTitle.firstTransaction",
                })}
              </div>
              <div className={classes.grey}>
                {formatDateAndTime(
                  _.min(
                    _.map(userWalletInfo.updatedList, "firstTransactionDate")
                  ) || "-"
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserTitle;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { useDispatch } from "react-redux";

import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import ImageUpload from "features/metadesk/components/ImageUpload";
import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import { formatSizeUnits } from "features/helpers/format";
import { useForm, useFormState, useFieldArray } from "react-hook-form";
import { enqueueSnackbar } from "features/common/redux/actions";
import { parseCustomDomainUrl, uploadFiles } from "features/helpers/utils";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { Loading } from "features/common";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";

const formDataInit = {
  imgs: [],
};

const WidgetLogo = ({ widgetItemInfo, onSetDirty, onRefetch, goBack }) => {
  const dispatch = useDispatch();

  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();

  const {
    widgetConfig,
    fetchWidgetConfig,
    updateWidgetConfig,
    updateWidgetConfigPending,
  } = useFetchWidgetConfig();
  const [imgs, setImgs] = useState(formDataInit.imgs);
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });
  const { append, replace } = useFieldArray({
    control: methods.control,
    name: "imgs",
  });
  const { isDirty } = useFormState({ control: methods.control });
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    onSetDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const logo = _.get(widgetItemInfo, "logo");
    setImgs(logo ? [logo] : formDataInit.imgs);
  }, [widgetItemInfo]);

  const uploadLogo = _.get(imgs, "[0]");

  const saveConfig = async () => {
    const logos = await uploadFiles(imgs, entityName);
    await updateWidgetConfig({
      entityName,
      config: {
        logo: _.get(logos, "[0]"),
        widgetId: _.get(widgetItemInfo, "_id"),
      },
      type: "customization",
    });
    onRefetch();
    dispatch(
      enqueueSnackbar({
        message: formatMessage({
          id: "widgetSettings.configure.saveSuccess",
        }),
        options: {
          variant: "success",
        },
      })
    );
  };

  const getLogo = (width = 80, height = 80, logoStyle = {}) =>
    uploadLogo ? (
      <img
        src={_.get(uploadLogo, "data_url", uploadLogo)}
        style={{
          width: width,
          height: height,
          objectFit: "contain",
          ...logoStyle,
        }}
      />
    ) : (
      <IconLogoCRM width={width} height={height} style={{ ...logoStyle }} />
    );

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Box sx={{ margin: "0 auto 20px" }}>
            <div>
              <div style={{ marginBottom: 12 }} className="text">
                {formatMessage({
                  id: "widgetCustomization.widgetLogo.subtitle",
                })}{" "}
                <span className="required">*</span>
              </div>
              {readonly ? (
                <Box
                  className="column"
                  sx={{
                    borderRadius: "3px",
                    border: "1px solid #E6E6E6",
                    height: "250px",
                    maxWidth: { xs: "100%", lg: "90%" },
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  {getLogo()}
                </Box>
              ) : (
                <ImageUpload
                  maxFileSize={2097152}
                  acceptType={["png"]}
                  max={2}
                  replace={true}
                  uploadComponent={
                    <Box
                      className="column"
                      sx={{
                        borderRadius: "3px",
                        border: "1px solid #E6E6E6",
                        height: "250px",
                        maxWidth: { xs: "100%", lg: "90%" },
                        textAlign: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      {getLogo()}
                      <i
                        className="meta-crm-icon-ic_uploadImage"
                        style={{
                          color: "#A5A5A5",
                          fontSize: 20,
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                        }}
                      />
                    </Box>
                  }
                  value={imgs}
                  onChange={(list) => {
                    const logo = [list[list.length - 1]];

                    setImgs(logo);
                    replace(logo);
                  }}
                />
              )}
              <div
                style={{
                  marginTop: 8,
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#5C5C5C",
                }}
              >
                <div>
                  {formatMessage({ id: "uploader.restriction.imageType" })}
                  <span style={{ color: "#7B61FF" }}>
                    {formatMessage({
                      id: "uploader.restriction.imageType.png",
                    })}
                  </span>
                </div>
                <div>
                  {formatMessage({ id: "uploader.restriction.imageSize" })}
                </div>
                <div>
                  {formatMessage({ id: "uploader.restriction.resolution" })}
                  <span>
                    {formatMessage({ id: "restriction.resolution.200x200" })}
                  </span>
                </div>
              </div>
            </div>

            <div className="field">
              <Button
                variant={VARIANT.OUTLINED}
                color={COLOR.SECONDARY}
                size={SIZE.XL}
                style={{ width: 120, marginRight: 8 }}
                onClick={goBack}
              >
                {formatMessage({ id: "global.btn.back" })}
              </Button>
              {canEdit && (
                <Button
                  color={COLOR.SECONDARY}
                  disabled={!isDirty}
                  onClick={async () => {
                    try {
                      await saveConfig();
                    } catch (err) {}
                  }}
                  size={SIZE.XL}
                  style={{ width: 120 }}
                >
                  {formatMessage({ id: "global.btn.save" })}
                </Button>
              )}
            </div>
          </Box>
        </Grid>
        <Box
          component={Grid}
          item
          xs={12}
          lg={7}
          sx={{
            padding: "70px",
            paddingBottom: 0,
            background: "#F8F8F8",
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{
                marginBottom: 21,
                fontSize: 16,
                fontWeight: 700,
                color: "#777",
              }}
            >
              Preview your theme
            </div>

            <Box display={"flex"} justifyContent={"center"}>
              <div style={{ position: "relative" }}>
                <img
                  src={require("assets/img/widgetLogoBg.png")}
                  style={{ width: "320px" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    height: "36px",
                    top: "18px",
                    left: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getLogo("unset", "28px")}
                </Box>
              </div>
            </Box>
          </div>
        </Box>
      </Grid>
    </Box>
  );
};

export default WidgetLogo;

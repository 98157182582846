import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Button, IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import Box from "@mui/material/Box";
import {
  OptionItem,
  OptionItemGroup,
} from "./settings/Customization/TicketType/SurveyOptionItem.style";

export const StyledMacroButton = styled(Button, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[700],
    "& i": {
      marginRight: "10px",
    },
    "&:hover": {
      color: theme.customColors.purple[500],
    },
    "&:after": {
      content: '""',
      height: "17px",
      width: "1px",
      backgroundColor: theme.customColors.grey[400],
      marginLeft: "16px",
    },
  };
});

export const StyledAdminMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ sending }) => {
  return {
    borderRadius: 10,
    padding: "12px",
    opacity: sending ? 0.5 : 1,

    lineHeight: "1.2",
    maxWidth: 430,
    fontWeight: 600,
    position: "relative",
    fontSize: 15,
    display: "inline-block",
    wordBreak: "break-word",
    background: "#7B61FF",
    color: "#ffffff",
    textAlign: "left",
    borderRadius: "10px 0px 10px 10px",
  };
});

export const StyledMessageRow = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ status, isRecalled }) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    alignItems: status == "failed" ? "center" : "flex-end",
    ...(isRecalled && {
      opacity: 0.5,
    }),
  };
});

export const StyledAdminMessageTime = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    textAlign: "right",
    marginRight: 8,
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  };
});

export const StyledUserMessageTime = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    textAlign: "left",
    marginLeft: 8,
    fontSize: 10,
    fontWeight: 400,
    color: "#777777",
  };
});

export const StyledMsgField = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    background: "white",
    border: "1px solid #A5A5A5",
    borderRadius: 7,
    padding: "8px 12px",
    marginRight: 10,
    color: "#383538",
  };
});

export const StyledMsgValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 14,
    fontWeight: 500,
  };
});

export const StyledFileContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
  };
});

export const StyledFileBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    borderRadius: 5,
    width: "150px",
    padding: 5,
    height: 40,
    border: "1px solid #e6e6e6",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };
});

export const StyledMsgAddress = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 14,
    fontWeight: 500,
  };
});

export const StyledUserMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ index }) => {
  return {
    borderRadius: 10,
    lineHeight: "1.2",
    maxWidth: 430,
    fontWeight: 600,
    position: "relative",
    fontSize: 15,
    color: "#383538",
    display: "inline-block",
    wordBreak: "break-word",
    border: "1px solid #A5A5A5",
    padding: 0,
    background: index === 0 ? "#F8F8F8" : "#ffffff",
    borderRadius: "0px 10px 10px 10px",
  };
});

export const StyledMoreButton = styled(IconButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    "&:hover": {
      background: theme.customColors.purple[300],
    },
  };
});

export const StyledRecallContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    // position: "absolute",
    fontSize: "12px",
    color: theme.customColors.grey[600],
    fontWeight: 500,
    textAlign: "right",
    marginTop: "5px",
  };
});

export const StyledMagicWandButton = styled(StyledMacroButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[500],
    "& i": {
      margin: 0,
    },
  };
});

export const StyledRetryIcButton = styled(StyledMacroButton, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    "& i": {
      margin: 0,
    },
    "&:after": {
      content: '""',
      height: "17px",
      width: "1px",
      backgroundColor: "transparent",
      marginLeft: "16px",
    },
  };
});
export const SurveyScoreGroup = styled(OptionItemGroup)(({ theme }) => ({
  marginTop: 16,
  marginBottom: 0,

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    rowGap: 16,
  },
}));

export const SurveyScoreItem = styled(OptionItem)(({ theme }) => {});

export const AiReplyLoader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    display: "flex",
    height: 102,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 10,
  };
});

export const AiReplyPreference = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    cursor: "pointer",
    color: theme.customColors.grey[500],
    display: "flex",
    alignItems: "center",

    "&:hover": {
      color: theme.customColors.purple[500],
    },
  };
});

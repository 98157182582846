import React, { useState } from "react";
import _ from "lodash";
import SearchAddressInfo from "./SearchAddressInfo";
import { Badge } from "@mui/material";
import {
  StyledDrawer,
  StyledControlArea,
  StyledDrawerContainer,
  StyledTabs,
  StyledTab,
  StyledTabPanel,
} from "./TicketDrawer.styles";
import DIDInfo from "./DIDInfo";
import GithubIssue from "./GithubIssue";
import { useFetchGithub } from "features/metadesk/redux/fetchGithub";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import useGetPagePermission from "hooks/useGetPagePermission";
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TicketDrawer = ({
  computer,
  renderUserInfo,
  entityName,
  selectedTicket,
  selectedUserAddress,
  selectedUserId,
  userCanEdit,
}) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(true);
  const { githubIssues } = useFetchGithub();
  const { entityConfig } = useFetchEntityConfig();
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderArrow = () => {
    let arrow;
    if (open) {
      arrow = computer ? (
        <i className="meta-crm-icon-ic_arrowLineRight font-size-16" />
      ) : (
        <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
      );
    } else {
      arrow = computer ? (
        <i className="meta-crm-icon-ic_arrowLineLeft font-size-16" />
      ) : (
        <i className="meta-crm-icon-ic_arrowLineDown font-size-16" />
      );
    }
    return arrow;
  };

  const renderSearchAddressInfo = () => (
    <SearchAddressInfo entityName={entityName} />
  );

  const renderDIDInfo = () => {
    return (
      <div
        style={{
          background: "#F8F8F8",
          padding: 0,
          overflowX: "hidden",
          height: "100%",
          overflowY: "auto",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        {!selectedUserAddress && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.9)",
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("assets/img/boxOpen.svg").default}
              style={{ width: 40, height: 40 }}
            />
            <div
              style={{
                color: "#777777",
                fontWeight: 500,
                fontSize: 14,
                marginTop: 7,
              }}
            >
              {formatMessage({ id: "ticketDrawer.did.empty" })}
            </div>
          </div>
        )}
        <DIDInfo
          selectedUserAddress={selectedUserAddress}
          selectedUserId={selectedUserId}
          entityName={entityName}
        />
      </div>
    );
  };

  const renderGithubIssue = () => (
    <GithubIssue selectedTicket={selectedTicket} />
  );

  const tabsLabel = [
    <i className="meta-crm-icon-ic_appUserProfile font-size-40" />,
    <i className="meta-crm-icon-ic_search font-size-28" />,
    <i className="meta-crm-icon-ic_appDid font-size-40" />,
  ];

  const tabsContent = [
    renderUserInfo(),
    renderSearchAddressInfo(),
    renderDIDInfo(),
  ];

  if (_.get(entityConfig, "serviceModule.github.connected")) {
    tabsLabel.push(
      <Badge
        color="secondary"
        badgeContent={
          <span style={{ marginTop: 2 }}>{githubIssues.length}</span>
        }
      >
        <i className="meta-crm-icon-ic_github font-size-20" />
      </Badge>
    );
    tabsContent.push(renderGithubIssue());
  }

  const pagePermission = useGetPagePermission();

  return (
    <StyledDrawer
      anchor={computer ? "right" : "top"}
      variant="permanent"
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <StyledControlArea onClick={handleToggle}>
        {renderArrow()}
      </StyledControlArea>
      <StyledDrawerContainer open={open}>
        {userCanEdit && (
          <StyledTabs
            orientation={computer ? "vertical" : "horizontal"}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            indicatorColor={"transparent"}

            // sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {tabsLabel.map((tab, index) => (
              <StyledTab
                label={tab}
                {...a11yProps(index)}
                key={index}
                // disabled={!selectedUserAddress}
              />
            ))}
          </StyledTabs>
        )}

        {tabsContent.map((tabContent, index) => (
          <StyledTabPanel open={open} value={value} index={index} key={index}>
            {tabContent}
          </StyledTabPanel>
        ))}
      </StyledDrawerContainer>
    </StyledDrawer>
  );
};

export default TicketDrawer;

import React, { useContext, useEffect, useRef, useState } from "react";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useIntl } from "react-intl";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import { Box } from "@mui/material";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useOutletContext } from "react-router";
import { get, lowerCase } from "lodash-es";

export default function PerformanceUtmLinksSearch({ onSearch }) {
  const { formatMessage } = useIntl();
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    onSearch(input);
  };

  const handleClean = () => {
    setInput("");
    onSearch("");
  };

  return (
    <Box
      display={"flex"}
      sx={{
        backgroundColor: "white",
        padding: "8px 16px",
      }}
    >
      <Box sx={{ width: "50%" }}>
        <InputField
          sx={{
            width: "100%",
          }}
          placeholder={formatMessage({
            id: "trackerTab.linkLayer.searchBar.placeHolder",
          })}
          onChange={(e) => setInput(e.target.value)}
          onBlur={handleChange}
          onEnter={handleChange}
          onClean={handleClean}
          name="test"
          value={input}
          type={"search"}
          size={SIZE.MEDIUM}
        />
      </Box>
    </Box>
  );
}

export const rainbowColors = [
  "#EB6085",
  "#FF9BCD",
  "#FF935C",
  "#FFDD67",
  "#72DFD0",
  "#BCEB3C",
  "#50C4ED",
  "#AAD4F4",
  "#B485D8",
  "#D2B5D4",
];

export const thirtyColors = [
  "#D7C1E0",
  "#FF9BCD",
  "#72DFD0",
  "#8293FF",
  "#ACDBDF",
  "#FF935C",
  "#FFDD67",
  "#E79E85",
  "#C8BB84",
  "#D2B5D4",
  "#B1D056",
  "#FFD66E",
  "#F2B57F",
  "#D6C8FF",
  "#AAD4F4",
  "#FF847C",
  "#C0C5CD",
  "#0EB29A",
  "#D8CBBB",
  "#BCEB3C",
  "#93DEFF",
  "#C5EA8B",
  "#F7ABC7",
  "#66D37E",
  "#70A3C4",
  "#50C4ED",
  "#FFFB85",
  "#B485D8",
  "#EB6085",
  "#E66878",
];

import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { get, isEmpty } from "lodash-es";
import { isAddress } from "features/helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import {
  StyledSettingAreaTitle,
  StyledSettingAreaHint,
} from "features/home/pages/Trial/TrialService/AttributionLinkSetup/AttributionLinkSetup.styles";
import { StyledSettingSupportCenterContainer } from "features/metadesk/settings/Customization/SettingSupportCenter/SettingSupportCenter.styles";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import _ from "lodash";
import TokenDisplayPreference from "features/metadesk/settings/Customization/OnChainDataSource/TokenDisplayPreference";
import NFTDisplayPreference from "features/metadesk/settings/Customization/OnChainDataSource/NFTDisplayPreference";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { selectChainIds } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { alertServerError } from "features/helpers/utils";
import TrialSetStepButton from "features/home/pages/Trial/TrialSetStepButton/TrialSetStepButton";
import { useStepData } from "contexts/stepContext/Step.context";
import { useIntl } from "react-intl";

const formDataInit = {
  txDisplayList: [],
  nftDisplayList: [],
};

const TrialMarketingSetStep2 = ({
  step,
  onNextStep,
  onPrevStep,
  entityName,
}) => {
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });

  const { updateEntityConfig, entityConfig } = useFetchEntityConfig();
  const { formatMessage } = useIntl();
  const [isPostLoading, setIsPostLoading] = useState(false);
  const { setStepValues, stepData } = useStepData();
  const chainIds = useSelector(selectChainIds);

  const tokenDisplayChainsDefault = chainIds;
  const dispatch = useDispatch();

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  const [tokenDisplayChains, setTokenDisplayChains] = useState(
    _.get(entityConfig, "chainIds", [])
  );

  const [nftDisplayList, setNftDisplayList] = useState([]);

  const isAnyEmptyInList = () => {
    return nftDisplayList?.some(
      (obj) => !obj.address || !isAddress(obj.address)
    );
  };

  const isValid = !isAnyEmptyInList();

  useEffect(() => {
    if (!isEmpty(stepData)) {
      if (get(stepData, "nftDisplayList")) {
        setNftDisplayList(stepData.nftDisplayList);
      }

      if (get(stepData, "tokenDisplayChains")) {
        setTokenDisplayChains(stepData.tokenDisplayChains);
      } else {
        if (!isEmpty(tokenDisplayChainsDefault)) {
          setTokenDisplayChains(tokenDisplayChainsDefault);
        }
      }
    }
  }, [stepData]);

  const handleSubmitInfo = async (sendData) => {
    setStepValues({
      tokenDisplayChains: tokenDisplayChains,
      nftDisplayList: nftDisplayList,
    });
    try {
      await updateEntityConfig({
        entityName,
        chainIds: tokenDisplayChains,
        nftInfo: nftDisplayList,
      });
      onNextStep();
    } catch (error) {
      dispatch(alertServerError(error));
    }
  };
  const handlePrevStep = () => {
    setStepValues({
      tokenDisplayChains: tokenDisplayChains,
      nftDisplayList: nftDisplayList,
    });

    onPrevStep();
  };

  const renderButtonArea = () => {
    return (
      <TrialSetStepButton
        onSave={methods.handleSubmit(handleSubmitInfo)}
        onBack={handlePrevStep}
        saveText="Next"
        isValid={isValid}
        isLoading={isPostLoading}
        display="flex"
        justifyContent="center"
      />
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <StyledSettingSupportCenterContainer>
          <Box width="500px" mt={"60px"}>
            <StyledSettingAreaTitle sx={{ textAlign: "center" }}>
              {formatMessage({
                id: "serviceOnboard.dataSource.title",
              })}
            </StyledSettingAreaTitle>
            <StyledSettingAreaHint sx={{ textAlign: "center" }}>
              {formatMessage({
                id: "serviceOnboard.dataSource.description",
              })}
            </StyledSettingAreaHint>
            <TokenDisplayPreference
              tokenDisplayChains={tokenDisplayChains}
              setTokenDisplayChains={setTokenDisplayChains}
              entityName={entityName}
            />
            <NFTDisplayPreference
              nftDisplayList={nftDisplayList}
              setNftDisplayList={setNftDisplayList}
            />
            {renderButtonArea()}
          </Box>
        </StyledSettingSupportCenterContainer>
        <Prompt when />
      </FormProvider>
    </>
  );
};

export default TrialMarketingSetStep2;

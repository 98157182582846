import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { get, isEmpty, isEqual } from "lodash-es";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";

import SettingItem from "features/metadesk/settings/Customization/SettingSupportCenter/SettingItem/SettingItem";
import { parseCustomDomainUrl } from "features/helpers/utils";
import {
  StyledSettingListContainer,
  StyledSettingAreaButtonContainer,
} from "features/metadesk/settings/Customization/SettingSupportCenter/SettingSupportCenter.styles";
import { useForm, useFormState } from "react-hook-form";
import { enqueueSnackbar } from "features/common/redux/actions";
import useUnload from "common/useUnload";
import { useDispatch, useSelector } from "react-redux";
import WidgetDemoTemplate from "./WidgetDemoTemplate";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import useGetPagePermission from "hooks/useGetPagePermission";

const WidgetTheme = ({ widgetItemInfo, onSetDirty, onRefetch, goBack }) => {
  const { formatMessage } = useIntl();

  const [entityName, isCustomDomain] = useOutletContext();
  const defaultColor = [
    {
      name: "primaryColor",
      hint: "Header and body",
      title: "Primary Color",
    },

    {
      name: "backgroundColor",
      hint: "Home Background",
      title: "Background Color",
    },
    {
      name: "hoverColor",
      hint: "Text Color",
      title: "Text Color",
    },
    {
      name: "textHoverColor",
      hint: "Text Color As Hovering",
      title: "Text Hover Color",
    },
    {
      name: "cardColor",
      hint: "Card Background",
      title: "Card Color",
    },
    {
      name: "cardHoverColor",
      hint: "Card Color As Hovering",
      title: "Card Hover Color",
    },
  ];

  const [colorArray, setColorArray] = useState(defaultColor);
  const { updateWidgetConfig, updateWidgetConfigPending } =
    useFetchWidgetConfig();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const colorValueForForm = (dataArray) =>
    dataArray.reduce((acc, item) => {
      acc[item.name] = item.color;
      return acc;
    }, {});
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: colorValueForForm(colorArray),
  });
  const { isDirty } = useFormState({ control });

  useEffect(() => {
    onSetDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (isEmpty(widgetItemInfo)) return;

    updateColorArray(get(widgetItemInfo, "colorTheme")); //backend will assing default theme
    reset(get(widgetItemInfo, "colorTheme"));
  }, [widgetItemInfo]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newColorArray = colorArray.map((item) => {
      if (item.name === name) {
        return { ...item, color: value };
      }
      return item;
    });
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setColorArray(newColorArray);
  };

  const sendInfo = async (formData) => {
    try {
      await updateWidgetConfig({
        entityName,
        config: {
          colorTheme: {
            primaryColor: formData?.primaryColor,
            backgroundColor: formData?.backgroundColor,
            hoverColor: formData?.hoverColor,
            textHoverColor: formData?.textHoverColor,
            cardColor: formData?.cardColor,
            cardHoverColor: formData?.cardHoverColor,
          },
          widgetId: get(widgetItemInfo, "_id"),
        },
        type: "customization",
      });
      onRefetch();
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "widgetSettings.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "widgetSettings.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitInfo = () => {
    handleSubmit(sendInfo)();
  };

  const updateColorArray = (apiData) => {
    const updatedColors = colorArray.map((item) => {
      if (apiData[item?.name]) {
        return {
          ...item,
          color: apiData[item.name],
        };
      }
      return item;
    });
    setColorArray(updatedColors);
  };

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const pagePermission = useGetPagePermission();
  const { canEdit } = pagePermission("customization");

  return (
    <Box
      sx={{
        background: "white",
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={5}>
          <Box sx={{ margin: "0 auto" }}>
            <div>
              <div style={{ marginBottom: 12 }} className="text">
                Color Theme <span className="required">*</span>
              </div>
              <StyledSettingListContainer>
                {colorArray.map((colorSetting) => {
                  return (
                    <SettingItem
                      colorSetting={colorSetting}
                      key={colorSetting.name}
                      control={control}
                      onChange={handleChange}
                      errorsInfo={errorsInfo}
                    />
                  );
                })}
              </StyledSettingListContainer>
            </div>
            <StyledSettingAreaButtonContainer>
              <Button
                variant={VARIANT.OUTLINED}
                color={COLOR.SECONDARY}
                size={SIZE.XL}
                style={{ width: 120, marginRight: 8 }}
                onClick={goBack}
              >
                {formatMessage({ id: "global.btn.back" })}
              </Button>
              {canEdit && (
                <Button
                  disabled={!isDirty}
                  color={COLOR.SECONDARY}
                  onClick={handleSubmitInfo}
                  size={SIZE.XL}
                  style={{ width: 120 }}
                >
                  {formatMessage({ id: "global.btn.save" })}
                </Button>
              )}
            </StyledSettingAreaButtonContainer>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          style={{
            background: "#F8F8F8",
            paddingBottom: 50,
            paddingTop: 70,
            textAlign: "center",
          }}
        >
          <WidgetDemoTemplate colorArray={colorArray} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WidgetTheme;

import { Box } from "@mui/material";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import React, { useContext, useState } from "react";
import { CampaignListContext } from "./context/CampaignListProvider";
import { useIntl } from "react-intl";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { upperFirst } from "lodash";

export default function CampaignFilter() {
  const { setSearch, setPromotype } = useContext(CampaignListContext);
  const [input, setInput] = useState("");
  const { formatMessage } = useIntl();

  function clearSearch() {
    setSearch("");
  }

  const promotypes = ["all", "flyer", "tracker", "widget"];

  const itemList = promotypes.map((type) => ({
    children: upperFirst(type),
    value: type === "all" ? "" : type,
    func: ({ value, syntheticEvent: e }) => {
      setPromotype(value);
    },
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ marginRight: "10px" }}>
        <InputField
          size={SIZE.MEDIUM}
          placeholder={formatMessage({ id: "campaignList.search.placeholder" })}
          onBlur={() => setSearch(input)}
          onEnter={() => setSearch(input)}
          onChange={(e) => setInput(e.target.value)}
          value={input}
          type="search"
          onClean={clearSearch}
        />
      </Box>
      <Menu isFilter itemList={itemList} />
    </Box>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RegisterForm from "../RegisterForm/RegisterForm";
import TrialServiceSetStepContainer from "./TrialServiceSetStepContainer";
import { selectTrialHasConfirm } from "features/metadesk/redux/trial/trial.selector";

const TrialService = ({}) => {
  const hasConfirm = useSelector(selectTrialHasConfirm);

  return (
    <>
      {hasConfirm ? (
        <TrialServiceSetStepContainer />
      ) : (
        <RegisterForm module="service" />
      )}
    </>
  );
};

export default TrialService;

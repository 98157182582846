import { TitleText } from "./Title.styled";

const Title = ({ title }) => {
  return (
    <TitleText>
      <span>{title.text}</span> {title.emoji}
    </TitleText>
  );
};

export default Title;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RegisterForm from "../RegisterForm/RegisterForm";
import TrialMarketingSetStepContainer from "./TrialMarketingSetStepContainer";
import { selectTrialHasConfirm } from "features/metadesk/redux/trial/trial.selector";

const TrialMarketing = ({ }) => {
  const hasConfirm = useSelector(selectTrialHasConfirm);

  return (
    <>
      {hasConfirm ? (
        <TrialMarketingSetStepContainer />
      ) : (
        <RegisterForm module="marketing" />
      )}
    </>
  );
};

export default TrialMarketing;

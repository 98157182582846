import React, { useContext, useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { PerformanceContext } from "./context/PerformanceProvider";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { get } from "lodash-es";
export default function PerformanceLeftChart({ timeRangeClicked }) {
  const { startDate, endDate } = useContext(PerformanceContext);
  const [chartData, setChartData] = useState([]);
  const { formatMessage } = useIntl();

  const color = ["#29CC6A", "#98DAFF", "#D6BBFB", "#FFE284", "#FFBCF0"];
  const legendList = timeRangeClicked?.map((item) => item.name);
  const getMinAndMaxTimestamps = (data) => {
    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;
    if (startDate) {
      minTimestamp = moment(startDate).startOf("day").utc().valueOf();
    }
    if (endDate) {
      maxTimestamp = moment(endDate).endOf("day").utc().valueOf();
    }
    data.forEach((item) => {
      item.dataPoints.forEach((point) => {
        const timestamp = moment(parseInt(point.timestamp))
          .startOf("day")
          .utc()
          .valueOf();
        if (timestamp < minTimestamp) minTimestamp = timestamp;
        if (timestamp > maxTimestamp) maxTimestamp = timestamp;
      });
    });
    return { minTimestamp, maxTimestamp };
  };

  const fillData = (data, start, end, step) => {
    const timestampMap = new Map(data.map((item) => [item[0], item[1]]));
    const filledData = [];
    for (let ts = start; ts <= end; ts += step) {
      const value = timestampMap.get(ts) || 0;
      filledData.push([ts, value]);
    }
    return filledData;
  };

  const getOptions = (newSeries) => {
    return {
      grid: {
        left: "10%",
        // top: "100px",
      },
      legend: {
        data: legendList,
        // top: "30px",
        right: "60px",
        width: "90%",
        icon: "path://M3,1 h4 a2,2 0 0 1 2,2 v4 a2,2 0 0 1 -2,2 h-4 a2,2 0 0 1 -2,-2 v-4 a2,2 0 0 1 2,-2", // SVG路径，定义了一个有圆角的方形
        textStyle: {
          color: "#333",
          fontSize: 12,
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series: newSeries || chartData,
      tooltip: {
        trigger: "axis",
      },
      // grid: { containLabel: true },
    };
  };

  useEffect(() => {
    if (get(timeRangeClicked, "length", 0) === 0) {
      setChartData([]);
      return;
    }
    const { minTimestamp, maxTimestamp } =
      getMinAndMaxTimestamps(timeRangeClicked);

    const newSeries = timeRangeClicked.map((item, index) => {
      const sortedData = item.dataPoints.sort(
        (a, b) => parseInt(a.timestamp) - parseInt(b.timestamp)
      );
      const filledData = fillData(
        sortedData.map((point) => [
          moment(parseInt(point.timestamp)).startOf("day").utc().valueOf(),
          point.count,
        ]),
        minTimestamp,
        maxTimestamp,
        3600000 * 24 // 1 hour in milliseconds
      );
      return {
        ...item,
        type: "line",
        data: filledData,
        itemStyle: { color: color[index % color.length] },
      };
    });

    setChartData(newSeries);
  }, [timeRangeClicked, startDate, endDate]);

  const isEmpty = timeRangeClicked.length === 0;

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px 0 ",
        width: "60%",
        height: "380px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: "#555555",
          margin: "6px 16px",
        }}
      >
        Clicked Over Time
      </Box>
      {isEmpty ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("assets/img/chartNoData.svg").default} />
          <Box color="#A5A5A5" mt="15px">
            {formatMessage({ id: "global.status.chartNoData" })}
          </Box>
        </Box>
      ) : (
        <ReactECharts
          option={getOptions()}
          autoResize={true}
          style={{ height: "100%" }}
          notMerge={true}
        />
      )}
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";
import { alertServerError } from "features/helpers/utils";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import { useDispatch } from "react-redux";
import axios from "axios";
import _ from "lodash";
import { Loading } from "features/common";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { Box } from "@mui/material";
import { useFetchTeamMember } from "../redux/fetchTeamMember";
import { getUserAvatarIcon } from "../utils";
import { useTheme } from "@emotion/react";
import IconIcPin from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcPin";
import moment from "moment";

export function AgentAnalytics({ startDate, endDate }) {
  const [entityName, isCustomDomain] = useOutletContext();
  const [analytics, setAnalytics] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Done");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { memberList } = useFetchTeamMember();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rowData = useMemo(() => {
    const m = { unassigned: {} };
    if (analytics) {
      memberList
        ?.filter((member) => !member.internal)
        ?.forEach((member) => {
          m[member._id] = { ...member };
        });
      analytics.counts?.forEach(({ _id, count }) => {
        if (!m[_id.team]) return;
        m[_id.team] = { ...m[_id.team], [_id.status]: count };
      });
      analytics.responseTime?.forEach(({ _id, responseTime }) => {
        if (!m[_id]) return;
        m[_id] = { ...m[_id], responseTime };
      });
    }
    let unassigned = m.unassigned;
    delete m.unassigned;

    const result = _.orderBy(
      _.values(m),
      [(item) => !item[orderBy], orderBy],
      ["asc", order]
    );

    return [unassigned, ...result];
  }, [analytics, order, orderBy, memberList]);

  const msToTime = (ms) => {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days";
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const params = {};
      if (startDate) params.startDate = moment(startDate).valueOf();
      if (endDate) params.endDate = moment(endDate).valueOf();
      const _analytics = await axios.get(
        apiUrl + `/api/ticket/analytics/${entityName}/agent`,
        { params }
      );

      setAnalytics(_analytics.data);
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  const headers = [
    { show: true, label: "Agent", title: "Agent", id: "name" },
    { show: true, label: "New", title: "New", id: "New" },
    { show: true, label: "Ongoing", title: "Ongoing", id: "Ongoing" },
    { show: true, label: "Done", title: "Done", id: "Done" },
    {
      show: true,
      label: "Avg. First Response Time",
      title: "Avg. First Response Time",
      id: "responseTime",
    },
  ];

  const theme = useTheme();

  const renderContents = (agent) => {
    const icon = getUserAvatarIcon(agent._id, theme);

    const contents = [
      <Box slot="name">
        {agent.name ? (
          <>
            <img
              src={require(`assets/img/${icon}`)}
              className="icon"
              style={{ borderRadius: 20, margin: 5 }}
            />
            {agent.name}
          </>
        ) : (
          <>
            <IconIcPin
              className="icon"
              style={{ borderRadius: 20, margin: 5 }}
            />
            Unassigned
          </>
        )}
      </Box>,
      <Box slot="New">{agent.New || "-"}</Box>,
      <Box slot="Ongoing">{agent.Ongoing || "-"}</Box>,
      <Box slot="Done">{agent.Done || "-"}</Box>,
      <Box slot="responseTime">
        {agent.responseTime ? msToTime(agent.responseTime) : "-"}
      </Box>,
    ];
    return contents;
  };

  return (
    <div>
      <Loading open={loading} fullScreen={false} />
      <CRMTable
        headers={headers}
        rowData={rowData}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        contents={_.map(rowData, (agent) => renderContents(agent))}
      />
    </div>
  );
}

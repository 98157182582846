import { parseCustomDomainUrl } from "features/helpers/utils";
import { useNavigate, useOutletContext } from "react-router";

export default function useNavigateEntity() {
  const navigate = useNavigate();
  const [entityName, isCustomDomain] = useOutletContext();

  function navigateEntity(url) {
    navigate(parseCustomDomainUrl(isCustomDomain, entityName, url));
  }

  function linkToEntity(url) {
    return parseCustomDomainUrl(isCustomDomain, entityName, url);
  }

  return { navigateEntity, linkToEntity, entityName, isCustomDomain, navigate };
}

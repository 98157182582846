import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { get, isEmpty, isEqual } from "lodash-es";
import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

import { useOutletContext, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  InputField,
  SIZE as InputSize,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  StyledCustomizationContainer,
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
  StyledLine,
  StyledDiscordSettingContainer,
  StyledDiscordTicketContainer,
  StyledDiscordTicketTitle,
} from "./DiscordBot.styles";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Loading } from "features/common";
import AutoAssignTicketType from "../AutoAssignTicketType/AutoAssignTicketType";
import { useIntl } from "react-intl";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import useGetPagePermission from "hooks/useGetPagePermission";
import { useTheme } from "@mui/styles";
import { StyledWhiteContainer } from "../../common.styles";
import useNavigateEntity from "hooks/useNavigateEntity";

const discordButtonStyle = {
  Primary: 1,
  Secondary: 2,
  Success: 3,
  Danger: 4,
};

const hasDuplicateName = (arr) => {
  if (isEmpty(arr)) {
    return false;
  }
  const nameSet = new Set();

  for (const item of arr) {
    const itemName = get(item, "name");
    if (!itemName) {
      return false;
    }
    if (nameSet.has(itemName.toLowerCase())) {
      return true; // 如果發現重複，則返回 true
    }
    nameSet.add(item.name.toLowerCase());
  }

  return false; // 如果迴圈完成而沒有找到重複，則返回 false
};
const formDataInit = {
  ticketList: [],
  introTitle: "",
  introDescription: "",
  threadAutoArchiveDuration: 10080,
};

const mergeTicketTypesArray = (arr1, arr2) => {
  const map = new Map(arr2.map((item) => [item._id, item]));

  return arr1.map((item) => {
    return map.has(item._id) ? map.get(item._id) : item;
  });
};

const DiscordBot = () => {
  const methods = useForm({
    defaultValues: formDataInit,
    mode: "onChange",
  });
  const { append, replace } = useFieldArray({
    control: methods.control,
    name: "ticketList",
  });
  const theme = useTheme();
  let [searchParams] = useSearchParams();
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const [ticketList, setTicketList] = useState(formDataInit.ticketList);
  const [isLoading, setIsLoading] = useState(false);
  const [isEqualData, setIsEqualData] = useState(true);
  const [isEqualTicketType, setIsEqualTicketType] = useState(true);
  const [threadAutoArchiveDuration, setThreadAutoArchiveDuration] = useState(
    formDataInit.threadAutoArchiveDuration
  );
  const [introTitle, setIntroTitle] = useState(formDataInit.introTitle);
  const [chooseTicketType, setChooseTicketType] = useState([]);
  const { navigateEntity } = useNavigateEntity();
  const [initialType, setInitialType] = useState([]);

  const [ticketFormat, setTicketFormat] = useState("thread");
  const [autoAssign, setAutoAssign] = useState(false);
  const [introDescription, setIntroDescription] = useState(
    formDataInit.introDescription
  );
  const { formatMessage } = useIntl();
  const firstFetchData = useRef({});
  const guide = searchParams.get("guide");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const {
    fetchEntityConfig,
    entityConfig,
    ticketTypeList,
    fetchTicketTypeList,
    updateTicketTypeList,
  } = useFetchEntityConfig();

  const isConnected = _.get(entityConfig, "serviceModule.discord.connected");
  const isAnyEmptyInTicketList = () => {
    return ticketList.some((obj) => obj.name === "");
  };

  const isValid =
    !hasDuplicateName(ticketList) &&
    (!isEqualData || !isEqualTicketType) &&
    !isAnyEmptyInTicketList();

  useEffect(() => {
    const isEqual =
      _.isEqual(
        threadAutoArchiveDuration,
        _.get(firstFetchData, "current.threadAutoArchiveDuration")
      ) &&
      _.isEqual(introTitle, _.get(firstFetchData, "current.introTitle")) &&
      _.isEqual(
        introDescription,
        _.get(firstFetchData, "current.introDescription")
      );

    setIsEqualData(isEqual);
    setIsEqualTicketType(_.isEqual(chooseTicketType, initialType));
  }, [
    chooseTicketType,
    initialType,
    threadAutoArchiveDuration,
    introTitle,
    introDescription,
    firstFetchData.current,
  ]);

  useEffect(async () => {
    setIsLoading(true);
    await fetchDisocrdBotInfo();
    if (guide) {
      setRun(true);
    }
    if (!isEmpty(ticketTypeList)) {
      await getTicketTypeList(ticketTypeList);
    }
    setIsLoading(false);
  }, [ticketTypeList]);

  // 用來更新 discord bot ticketType 在設定列表的樣式 要是沒有顏色與地址 給定預設值
  const getTicketTypeList = async (data) => {
    // 首先拿到 discordBot 設定的ticketTypes
    const {
      data: { ticketTypes: ticketTypesFromDiscordBot, autoAssign },
    } = await axios.get(
      `${apiUrl}/api/manage/ticketTypes/${entityName}/discordBot/${null}`
    );

    // 拿到這個entity全部的ticketTypes , 並且要是沒有  addressRequired , discordButtonStyle 就給上預設值
    const dataWithDefaultDiscordInfo = data.map((item) => {
      if (
        item.addressRequired === undefined ||
        item.discordButtonStyle === undefined
      ) {
        return { ...item, discordButtonStyle: 1, addressRequired: false };
      }
      return item;
    });

    // 合併兩個陣列 找到 這個entity全部的ticketTypes的id 有在discordBot設定的ticketTypes的id的話, 就把這個 ticket替換成 discordBot的ticket
    // (這樣那些顏色 addressRequired 才會是正確的)
    const newTicketList = mergeTicketTypesArray(
      dataWithDefaultDiscordInfo,
      ticketTypesFromDiscordBot.ticketTypes
    );

    // 設定list為樣式正確的newTicketList
    setTicketList(newTicketList);

    const modifiedTicketTypes = _.map(
      ticketTypesFromDiscordBot.ticketTypes,
      (x) => ({ ...x, assignee: _.get(x.assignee, "_id", null) })
    );

    // 設定 Discord Ticket 選中的結果
    setChooseTicketType(modifiedTicketTypes);
    setInitialType(modifiedTicketTypes);
    setAutoAssign(autoAssign);

    // 用來比對頁面ticketType是否有變更用的
    firstFetchData.current = {
      ...firstFetchData.current,
      ticketList: newTicketList,
    };
    // 給 react hook form 使用
    replace(newTicketList);
  };

  useUnload((e) => {
    if (!isEqualData || !isEqualTicketType) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const updateDisocrdBotInfo = (data) => {
    const fetchIntroTitle = _.get(data, "introTitle", "");
    const fetchIntroDescription = _.get(data, "introDescription", "");
    const fetchThreadAutoArchiveDuration = _.get(
      data,
      "threadAutoArchiveDuration",
      10080
    );
    const fetchTicketFormat = _.get(data, "ticketFormat", "thread");
    setIntroTitle(fetchIntroTitle);
    setIntroDescription(fetchIntroDescription);
    setThreadAutoArchiveDuration(fetchThreadAutoArchiveDuration);
    setTicketFormat(fetchTicketFormat);

    firstFetchData.current = {
      ...firstFetchData.current,
      introTitle: fetchIntroTitle,
      threadAutoArchiveDuration: fetchThreadAutoArchiveDuration,
      introDescription: fetchIntroDescription,
    };
  };

  const fetchDisocrdBotInfo = async () => {
    try {
      const { data } = await axios.get(
        apiUrl + "/api/discord/ticketBot/" + entityName
      );
      updateDisocrdBotInfo(data);
    } catch (err) {}
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (!isEqualTicketType) {
        try {
          const payload = chooseTicketType.filter(
            (ticket) => ticket.name.trim() !== ""
          );

          await axios.post(
            `${apiUrl}/api/manage/ticketTypes/${entityName}/discordBot/${null}`,
            {
              ticketTypes: payload,
            }
          );

          await getTicketTypeList(ticketTypeList);

          // 成功打完api 後 把新資料設進firstFetchData  這樣比對資料才會更新 又變成equal狀態
          firstFetchData.current = {
            ...firstFetchData.current,
            ticketList: payload,
          };
        } catch (error) {
          dispatch(
            enqueueSnackbar({
              message: get(error, "response.data.error", "error"),
              options: {
                variant: "error",
              },
            })
          );
        } finally {
        }
      }
      if (!isEqualData) {
        const { data } = await axios.post(
          apiUrl + `/api/discord/ticketBot/${entityName}/configure`,
          {
            introTitle,
            introDescription,
            threadAutoArchiveDuration: threadAutoArchiveDuration,
          }
        );
        updateDisocrdBotInfo(data);
      }

      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "discordSetting.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );

      fetchEntityConfig({ entityName });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutoAssign = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/ticket/toggleAutoAssign/${entityName}/discordBot/${null}`,
        { isEnableAutoAssign: !autoAssign }
      );
      if (data.success) setAutoAssign(!autoAssign);
      dispatch(
        enqueueSnackbar({
          message: `Auto assign ${!autoAssign ? "Enabled" : "Disabled"}`,
          options: { variant: "success" },
        })
      );
    } catch (error) {}
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");
  const integrationPermission = pagePermission("integration");

  const renderInstallPrompt = () => (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={"432px"}
      margin={"0 auto"}
      flexDirection={"column"}
      marginTop={"5%"}
    >
      <Box
        component="img"
        width={"100%"}
        src={require(`assets/img/not_install_discord_bot.png`)}
        mb={3}
      ></Box>

      <Box
        fontSize={"24px"}
        fontWeight={"700"}
        color={(theme) => theme.customColors.grey[800]}
        textAlign={"center"}
        mb="5px"
      >
        {formatMessage({ id: "WidgetCustomization.emptyState.title" })}
      </Box>

      <Box
        color={(theme) => theme.customColors.grey[600]}
        textAlign={"center"}
        mb={3}
        fontWeight={400}
        lineHeight={"20px"}
      >
        {formatMessage({ id: "WidgetCustomization.emptyState.description" })}
      </Box>
      {integrationPermission.canEdit && (
        <Button
          onClick={() => {
            const url = _.get(entityConfig, "serviceModule.discord.url");
            window.location.replace(url);
          }}
        >
          {formatMessage({ id: "global.btn.getStarted" })}
        </Button>
      )}
    </Box>
  );

  if (!isConnected) {
    return renderInstallPrompt();
  }

  return (
    <FormProvider {...methods}>
      <Box mb="20px">
        <Box className="title">
          {formatMessage({ id: "integration.discordBot.name" })}
        </Box>
      </Box>
      <StyledWhiteContainer>
        <StyledCustomizationContainer>
          <Loading open={isLoading} fullScreen={false} />
          <StyledGeneralSettingsContainer>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <StyledSettingTitle>
                  {formatMessage({ id: "DiscordBotCustomization.intro.title" })}
                </StyledSettingTitle>
                <StyledDescription>
                  {formatMessage({
                    id: "DiscordBotCustomization.intro.description",
                  })}
                </StyledDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <InputField
                  readonly={readonly}
                  fullWidth
                  size={SIZE.LARGE}
                  variant={VARIANT.OUTLINED}
                  title={"Title"}
                  placeholder="Open a ticket"
                  value={introTitle || ""}
                  maxLength={30}
                  mb={"20px"}
                  onChange={(e) => {
                    setIntroTitle(e.target.value);
                  }}
                />
                <InputField
                  readonly={readonly}
                  fullWidth
                  size={SIZE.LARGE}
                  multiline
                  rows={5}
                  variant={VARIANT.OUTLINED}
                  title={"Description"}
                  maxLength={500}
                  placeholder="Please select the type of ticket you would like to open"
                  value={introDescription || ""}
                  onChange={(e) => {
                    setIntroDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </StyledGeneralSettingsContainer>
          <StyledLine />
          {ticketFormat === "thread" && integrationPermission.canEdit && (
            <>
              <StyledGeneralSettingsContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <StyledSettingTitle>
                      {formatMessage({
                        id: "DiscordBotCustomization.archivePeriod.title",
                      })}
                    </StyledSettingTitle>
                    <StyledDescription>
                      {formatMessage({
                        id: "DiscordBotCustomization.archivePeriod.description",
                      })}
                    </StyledDescription>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CustomDropdown
                      readonly={readonly}
                      border
                      fullWidth
                      size={SIZE.LARGE}
                      value={threadAutoArchiveDuration}
                      onSelect={(e) => {
                        setThreadAutoArchiveDuration(e.target.value);
                      }}
                      data={[
                        {
                          label: formatMessage({
                            id: "DiscordBotCustomization.archivePeriod.oneHour",
                          }),
                          value: 60,
                        },
                        {
                          label: formatMessage({
                            id: "DiscordBotCustomization.archivePeriod.oneDay",
                          }),
                          value: 1440,
                        },
                        {
                          label: formatMessage({
                            id: "DiscordBotCustomization.archivePeriod.threeDays",
                          }),
                          value: 4320,
                        },
                        {
                          label: formatMessage({
                            id: "DiscordBotCustomization.archivePeriod.oneWeek",
                          }),
                          value: 10080,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </StyledGeneralSettingsContainer>
              <StyledLine />
            </>
          )}
          <StyledGeneralSettingsContainer style={{ overflowX: "auto" }}>
            <StyledSettingTitle>
              <span className="mgr4">
                {formatMessage({
                  id: "DiscordBotCustomization.ticketType.title",
                })}
              </span>
              <IconTooltip
                customColor="#A5A5A5"
                content={formatMessage({
                  id: "DiscordBotCustomization.ticketType.tooltip",
                })}
                icon={<i className="meta-crm-icon-ic_info font-size-16" />}
              />
            </StyledSettingTitle>
            <StyledDescription>
              {formatMessage({
                id: "DiscordBotCustomization.ticketType.description",
              })}
            </StyledDescription>
            <StyledDiscordSettingContainer>
              <StyledDiscordTicketContainer>
                <StyledDiscordTicketTitle>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box pl={"50px"}>
                      {formatMessage({
                        id: "DiscordBotCustomization.ticketType.name",
                      })}
                    </Box>

                    <Box>
                      <Box pl={"28px"}>
                        {formatMessage({
                          id: "DiscordBotCustomization.ticketType.color",
                        })}
                      </Box>
                    </Box>

                    <Box>
                      {formatMessage({
                        id: "DiscordBotCustomization.ticketType.requireAddress",
                      })}
                    </Box>

                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          marginLeft: "auto",
                          position: "relative",
                          top: -4,
                        }}
                      >
                        <IconTooltip
                          customColor="#A5A5A5"
                          content={formatMessage({
                            id: "ticketCustomization.autoAssign.tooltip",
                          })}
                          icon={
                            <i className="meta-crm-icon-ic_info font-size-16" />
                          }
                        />
                        <p>
                          {formatMessage({
                            id: "customization.ticketType.autoAssign",
                          })}
                        </p>
                        <Switch
                          checked={autoAssign}
                          onChange={() => handleAutoAssign()}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledDiscordTicketTitle>

                <AutoAssignTicketType
                  serviceName={"discord"}
                  canEdit={canEdit}
                  ticketList={ticketList}
                  autoAssign={autoAssign}
                  chooseTicketType={chooseTicketType}
                  setChooseTicketType={setChooseTicketType}
                />
              </StyledDiscordTicketContainer>
            </StyledDiscordSettingContainer>
          </StyledGeneralSettingsContainer>
          <StyledLine />
          <StyledGeneralSettingsContainer>
            <StyledSettingTitle>
              {formatMessage({
                id: "DiscordBotCustomization.goToSetting.subtitle",
              })}
            </StyledSettingTitle>
            <StyledDescription>
              {formatMessage({
                id: "DiscordBotCustomization.goToSetting.description",
              })}
            </StyledDescription>
            <Button
              variant={VARIANT.TEXT}
              style={{ padding: 0 }}
              onClick={() =>
                navigateEntity("/settings/general/integration/update/discord")
              }
            >
              {formatMessage({
                id: "DiscordBotCustomization.goToSetting.hyperlink",
              })}
            </Button>
          </StyledGeneralSettingsContainer>
          {canEdit && (
            <FixedButton>
              <Button
                sx={{ width: "120px" }}
                onClick={handleSave}
                disabled={!isValid || isLoading}
              >
                Save
              </Button>
            </FixedButton>
          )}
          <Prompt when={!isEqualData || !isEqualTicketType} />
        </StyledCustomizationContainer>
      </StyledWhiteContainer>
    </FormProvider>
  );
};

export default DiscordBot;

import React, { forwardRef, useImperativeHandle, cloneElement } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "components/CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";

const RemoveConfirm = forwardRef(
  (
    {
      title,
      text,
      onRemove,
      close,
      iconSize = 16,
      removeComponent,
      controlByParent = false,
      zIndex = 14000,
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = (e) => {
      setOpen(false);
      close && close();
      e.stopPropagation();
    };
    const handleClick = (e) => {
      setOpen(true);
      e?.stopPropagation();
    };
    const { formatMessage } = useIntl();
    useImperativeHandle(ref, () => ({
      setOpen,
    }));

    return (
      <div>
        {!controlByParent &&
          (removeComponent ? (
            cloneElement(removeComponent, { onClick: handleClick })
          ) : (
            <IconButton onClick={handleClick} size={iconSize} color="#A5A5A5">
              <i className="meta-crm-icon-ic_trash" />
            </IconButton>
          ))}

        <Modal
          open={open}
          onClose={(e) => handleClose(e)}
          className="modal"
          style={{ zIndex }}
        >
          <div className="modalAlert">
            <IconButton
              onClick={(e) => handleClose(e)}
              className="modalClose"
              size={16}
            >
              <CloseIcon />
            </IconButton>
            <div className="modalHeader">
              {title || formatMessage({ id: "global.btn.remove" })}
            </div>
            <div className="modalDescription">
              {text || formatMessage({ id: "global.warning.remove" })}
            </div>

            <div style={{ margin: "10px auto", width: 120 }}>
              <Button
                onClick={(e) => {
                  onRemove(e);
                  handleClose(e);
                }}
                color="remove"
                style={{ marginTop: 4 }}
                fullWidth
              >
                {formatMessage({ id: "global.btn.delete" })}
              </Button>
              <div style={{ marginTop: 4 }}>
                <Button
                  onClick={(e) => handleClose(e)}
                  color="text"
                  sm
                  fullWidth
                  style={{ margin: 0 }}
                >
                  {formatMessage({ id: "global.btn.cancel" })}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default RemoveConfirm;

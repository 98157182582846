const blackListProps = {
  expanded: true,
  backgroundColor: true,
  selectAreaHeight: true,
  bgColor: true,
  isRequired: true,
  isFixed: true,
  cellWidth: true,
  leftDistance: true,
  lastFixedItem: true,
  textColor: true,
  borderColor: true,
  primaryColor: true,
  buttonColor: true,
  buttonTextColor: true,
  hoverColor: true,
  isError: true,
  isPreview: true,
  flexStart: true,
  isDemo: true,
  isAbsolute: true,
  blueTitle: true,
  imgErrorText: true,
  isComingSoon: true,
  marginLeft: true,
  rowIndex: true,
  isHeader: true,
  textAlign: true,
  isIsolateComponent: true,
};

export default blackListProps;

import { Box } from "@mui/material";
import React, { useState } from "react";
import Login from "./Login";
import {
  StyledOnboardingLeftContainer,
  StyledOnboardingWrapper,
} from "./Onboarding.styles";
import {
  OnboardingContextProvider,
  useOnboardingState,
} from "./OnboardingProvider";

import WorkSpace from "./WorkSpace/WorkSpace";
import Trial from "./Trial/Trial";

export default function Onboarding() {
  return (
    <StyledOnboardingWrapper>
      <StyledOnboardingLeftContainer>
        <Box
          component={"img"}
          src={require("assets/img/onboardingImg.png")}
          sx={{
            position: "absolute",
            bottom: "0",
            left: "50%",
            maxHeight: "calc(100vh - 100px)",
            transform: "translateX(-50%)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "79px",
            left: "50%",
            transform: "translateX(-50%)",
            fontWeight: 400,
          }}
        >
          <Box
            sx={{
              color: "#FFF",
              fontSize: "32px",
              fontFamily: "Saira",
              lineHeight: "50px",
              background: "linear-gradient(90deg, #FFF 50%, #999 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              mb: "8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            Your 360° Next-Gen CRM Hub
          </Box>
          <Box
            className="startRow"
            fontSize={"14px"}
            color="#d6d6d6"
            width="340px"
            m="0 auto"
          >
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Analytics
            </Box>
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Customer
            </Box>
            <Box pr="15px" mr="15px" borderRight={"1px solid #555555"}>
              Marketing
            </Box>
            <Box>Service</Box>
          </Box>
        </Box>
      </StyledOnboardingLeftContainer>
      {/* right container use provider to control */}
      <OnboardingContextProvider>
        <OnboardingRightContainer />
      </OnboardingContextProvider>
    </StyledOnboardingWrapper>
  );
}

const OnboardingRightContainer = () => {
  const { step } = useOnboardingState();
  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      {step === 0 && <Login />}
      {step === 1 && <WorkSpace />}
      {step === 2 && <Trial />}
    </Box>
  );
};

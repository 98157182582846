import { Box } from "@mui/material";
import ReactECharts from "echarts-for-react";

export default function DonutChart({ title, data, tooltip }) {
  const option = {
    tooltip: {
      trigger: "item",
      formatter: tooltip,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["30%", "50%"],
        avoidLabelOverlap: false,
        emphasis: { scale: false },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 2,
          normal: {
            color: function (params) {
              const colorList = [
                "#AAD4F4",
                "#F7ABC7",
                "#D2B5D4",
                "#71F2A4",
                "#72DFD0",
                "#FFE284",
              ];
              return colorList[params.dataIndex];
            },
          },
        },
        data: data,
      },
    ],
  };

  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        bgcolor: "#fff",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: (theme) => theme.customColors.grey[700],
        }}
      >
        {title}
      </Box>

      <ReactECharts option={option} style={{ flex: 1, height: "258px" }} />
    </Box>
  );
}

import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Swiper } from "swiper/react";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const StyledChainTabs = styled(Tabs, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  background: theme.customColors.grey[200],
  borderRadius: "4px",
  minHeight: "32px",
  padding: "2px",

  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const StyledChainTab = styled(Tab, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  padding: "6.5px",
  minHeight: "28px",
  color: theme.customColors.grey[700],

  "&.Mui-selected": {
    background: theme.customColors.white,
    borderRadius: "4px",
    border: `1px solid ${theme.customColors.purple[500]}`,
    color: theme.customColors.purple[500],
  },
}));

export const StyledTabLabel = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledTabLabelImg = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  width: "14px",
  height: "14px",
  marginRight: "6px",
}));

export const StyledBuildWith = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isPreview }) => ({
  fontsize: "12px",
  color: theme.customColors.grey[600],
  marginTop: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledFixButtonContainer } from "./common.styles";
import { useOpenState } from "contexts/stepContext/Open.context";
import { useMatches } from "react-router";
import {
  drawerWidth,
  moduleWidth,
} from "features/home/components/MainMenu.styles";

const FixedButton = ({
  isFixedBarShow = true,
  rightPlaceHolder = null,
  leftComponent,
  children,
  isShowGap = true,
  wholePage = false,
}) => {
  const theme = useTheme();
  const matches = useMatches();
  const isHideMenu = _.get(matches, `[${matches?.length - 1}].handle.hideMenu`);
  const { openState } = useOpenState();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getLeft = () => {
    if (isMobile) return 0;
    if (!openState.hideSideMenu) {
      let leftWidth = 0;
      if (openState.menu) leftWidth += drawerWidth + 5;
      if (openState.moduleOpen) leftWidth += moduleWidth + 8;
      return leftWidth;
    } else {
      return "0px";
    }
  };

  return (
    <>
      {isShowGap && <Box height={"60px"}></Box>}
      <StyledFixButtonContainer
        sx={{
          display: isFixedBarShow ? "block" : "none",
          position: "fixed",
          left: getLeft(),
          right: 0,
          bottom: 0,
          paddingTop: "12px",
          paddingBottom: "12px",

          background: "white",
          boxShadow: "0px -5px 20px 0px rgba(0, 0, 0, 0.05)",
          zIndex: 20,
        }}
      >
        <Box pr="15px" pl="15px" mr={isMobile ? 0 : "20px"}>
          <Box
            maxWidth={"1440px"}
            margin={"0 auto"}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Box mr="auto">{leftComponent}</Box>
            {children}
            {!isMobile && rightPlaceHolder && (
              <Box sx={{ width: rightPlaceHolder }}></Box>
            )}
          </Box>
        </Box>
      </StyledFixButtonContainer>
    </>
  );
};

export default FixedButton;

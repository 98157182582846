import React, { useState, useEffect, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import { get, isEmpty } from "lodash-es";
import Box from "@mui/material/Box";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

import TrialSetStepButton from "features/home/pages/Trial/TrialSetStepButton/TrialSetStepButton";

import useUnload from "common/useUnload";
import Prompt from "features/common/Prompt";

import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

import { useDispatch, useSelector } from "react-redux";
import { useStepData } from "contexts/stepContext/Step.context";
import IntegrationAttributionLink from "features/metadesk/settings/integrations/IntegrationAttributionLink";
import { useIntl } from "react-intl";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import useNavigateEntity from "hooks/useNavigateEntity";
import { redirectRoot } from "features/helpers/utils";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import useGetPagePermission from "hooks/useGetPagePermission";

const formDataInit = {
  domainName: "",
};

const TrialMarketingSetStep2 = ({
  step,
  onNextStep,
  onPrevStep,
  entityName,
  moduleName,
}) => {
  const { formatMessage } = useIntl();
  const [formData, setFormData] = useState(formDataInit);
  const dispatch = useDispatch();
  const pagePermission = useGetPagePermission();
  const { setStepValues, stepData } = useStepData();
  const { navigateEntity } = useNavigateEntity();
  const { entityConfig } = useFetchEntityConfig();
  const {
    handleSubmit,
    control,
    setError,
    formState,
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });

  const { isDirty, isValid } = formState;

  useEffect(() => {
    if (get(stepData, "domainName")) {
      setFormData({ domainName: stepData.domainName });
      setValue("domainName", stepData.domainName, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [stepData]);

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  const handleSkip = () => {
    let link = "";
    if (moduleName == "service") {
      link = `/${moduleName}/ticket/new`;
    }
    if (moduleName == "marketing") {
      link = `/${moduleName}/campaign/draft`;
    }
    navigateEntity(link);
    window.location.reload();
  };

  const sendInfo = async (data) => {
    try {
      await axios.post(`${apiUrl}/api/manage/attributionConfig/${entityName}`, {
        url: `https://${data.domainName}`,
      });

      await reset({ domainName: data.domainName });

      onNextStep();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(
            error,
            "response.data.error",
            get(error, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleSubmitInfo = () => {
    handleSubmit(sendInfo)();
  };

  return (
    <>
      <Box
        paddingTop={"80px"}
        backgroundColor={(theme) => theme.customColors.white}
      >
        <Box
          maxWidth={"1024px"}
          margin={"0 auto"}
          paddingRight={"20px"}
          paddingLeft={"20px"}
        >
          <IntegrationAttributionLink
            isTrial={true}
            controlByParent={control}
            formDataByParent={formData}
            setFormDataByParent={setFormData}
            formStateByParent={formState}
          />
          <FixedButton wholePage={true}>
            <Button
              size={SIZE.XL}
              style={{ width: 120 }}
              onClick={handleSkip}
              variant={VARIANT.OUTLINED}
              sx={{ marginRight: "8px" }}
            >
              Skip
            </Button>
            <Button
              disabled={!isValid}
              color={COLOR.SECONDARY}
              size={SIZE.XL}
              style={{ width: 120 }}
              onClick={handleSubmitInfo}
            >
              Next
            </Button>
          </FixedButton>
        </Box>
      </Box>
      <Prompt when={isDirty} />
    </>
  );
};

export default TrialMarketingSetStep2;

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import OtpInput from "react-otp-input";

export const StyledEmailHint = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginBottom: "12px",
}));

export const StyledYourEmail = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  fontWeight: 700,
  fontSize: "16px",
  marginBottom: "45px",
}));

export const StyledConfirmText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  color: theme.customColors.grey[700],
  fontSize: "12px",
  marginBottom: "20px",
}));

export const StyledOtpInput = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "100%",
  marginBottom: "30px",
  "& > div": {
    width: "100%",
    justifyContent: "space-between",
    "& input[type='number']::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type='number']::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type='number']": {
      MozAppearance: "textfield",
    },
    "& input": {
      border: 0,
      borderBottom: `1px solid ${theme.customColors.grey[300]}`,

      "&:focus": {
        outline: 0,
        borderBottom: `1px solid ${theme.customColors.grey[700]}`,
      },
    },
  },
}));

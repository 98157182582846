import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { get, isEmpty, has, isEqual } from "lodash-es";

import Box from "@mui/material/Box";

import { useDispatch } from "react-redux";

import { useOutletContext } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";

import axios from "axios";
import { apiUrl } from "features/configure";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import { useMediaQuery } from "@material-ui/core";

import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import {
  StyledInterfaceThemeContainer,
  StyledInterfaceThemeTitle,
  StlyledSelectThemeContainer,
  StyledThemeButtonGroup,
  StyledThemeButton,
  StyledThemeButtonTitle,
  StyledThemeButtonContentContainer,
  StyledThemeButtonHint,
  StyledThemeButtonImg,
  StyledLink,
  StyledTicketTypeTitle,
} from "./InterfaceTheme.styles";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import Prompt from "features/common/Prompt";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import AutoAssignTicketType from "../AutoAssignTicketType/AutoAssignTicketType";
import useUnload from "common/useUnload";
import useGetPagePermission from "hooks/useGetPagePermission";
import { StyledWhiteContainer } from "../../common.styles";

const mergeTicketTypesArray = (arr1, arr2) => {
  const map = new Map(arr2.map((item) => [item._id, item]));

  return arr1.map((item) => {
    return map.has(item._id) ? { ...item, assignee: map.get(item._id) } : item;
  });
};

const InterfaceTheme = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customization");

  const [entityName, isCustomDomain] = useOutletContext();
  const { ticketTypeList } = useFetchEntityConfig();
  const [ticketTypesArray, setTicketTypesArray] = useState([]);

  const [chooseTicketType, setChooseTicketType] = useState([]);
  const [initialType, setInitialType] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [autoAssign, setAutoAssign] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customTheme, setCustomTheme] = useState("light");
  const [customColor, setCustomColor] = useState({
    background: "#383538",
    border: "#D6D6D6",
    button: "#0096FF",
    buttonText: "#F8F8F8",
    hover: "#CBE6FF",
    primary: "#ffffff",
    text: "#383538",
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleGetTicketTypes = async (data) => {
    try {
      const { data: ticketTypesData } = await axios.get(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/supportCenter/${null}`
      );
      const cleanerTicketTypes = (ticketTypesData.ticketTypes || []).map(
        (tt) => ({
          _id: tt.ticketType,
          assignee: tt.assignee || null,
        })
      );

      const newTicketTypes = mergeTicketTypesArray(data, cleanerTicketTypes);

      setTicketTypesArray(newTicketTypes);

      setChooseTicketType(cleanerTicketTypes);
      setInitialType(cleanerTicketTypes);
      setAutoAssign(ticketTypesData.autoAssign);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
    }
  };

  const handleCustomTheme = async (event, newCustomTheme) => {
    if (newCustomTheme !== null) {
      setCustomTheme(newCustomTheme);

      try {
        await axios.post(`${apiUrl}/api/manage/theme/${entityName}`, {
          theme: newCustomTheme,
          ...(newCustomTheme === "custom" && { customColor: customColor }),
        });
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      }
    }
  };

  const fetchTheme = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/manage/theme/${entityName}`
      );
      const themeName = get(data, "name");
      const themeColor = get(data, "customColor");
      setCustomTheme(themeName ? themeName : "light");

      if (has(data, "customColor") && !isEmpty(data.customColor)) {
        setCustomColor(themeColor);
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveTicketType = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/supportCenter/${null}`,
        {
          ticketTypes: _.map(chooseTicketType, (obj) => ({
            ticketType: obj._id,
            assignee: obj.assignee,
          })),
        }
      );
      const cleanerTicketTypes = (data || []).map((tt) => ({
        _id: tt.ticketType,
        assignee: tt.assignee || null,
      }));

      const newTicketTypes = mergeTicketTypesArray(
        ticketTypeList,
        cleanerTicketTypes
      );

      setTicketTypesArray(newTicketTypes);

      setInitialType(cleanerTicketTypes);
      setChooseTicketType(cleanerTicketTypes);
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "supportSetting.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleAutoAssign = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/ticket/toggleAutoAssign/${entityName}/supportCenter/${null}`,
        { isEnableAutoAssign: !autoAssign }
      );
      if (data.success) setAutoAssign(!autoAssign);
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "supportSetting.configure.saveSuccess",
          }),
          options: { variant: "success" },
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    const sortedChooseTicketType = [...chooseTicketType].sort();
    const sortedInitialType = [...initialType].sort();

    if (!isEqual(sortedChooseTicketType, sortedInitialType)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [initialType, chooseTicketType]);

  useEffect(() => {
    if (!isEmpty(ticketTypeList)) {
      handleGetTicketTypes(ticketTypeList);
    }
  }, [ticketTypeList]);

  useEffect(() => {
    fetchTheme();
  }, []);

  useUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  return (
    <StyledInterfaceThemeContainer>
      <Box mb="20px">
        <Box className="title">Support Center</Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            minHeight: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      ) : (
        <StyledWhiteContainer>
          <Box>
            <StyledInterfaceThemeTitle>
              Support Center Webpage Theme
            </StyledInterfaceThemeTitle>

            <StlyledSelectThemeContainer>
              <StyledThemeButtonGroup
                value={customTheme}
                exclusive
                onChange={canEdit ? handleCustomTheme : null}
              >
                <StyledThemeButton value="light" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_sun font-size-24" />
                    </Box>
                    Light Theme
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Configure your support center to bright style.
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/light_theme.svg").default}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
                <StyledThemeButton value="dark" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_moon font-size-32" />
                    </Box>
                    Dark Theme
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Configure your support center to dark style.
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/dark_theme.jpg")}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
                <StyledThemeButton value="custom" readonly={readonly}>
                  <StyledThemeButtonTitle>
                    <Box mr={1} fontWeight={500}>
                      <i className="meta-crm-icon-ic_color font-size-32" />
                    </Box>
                    Custom Theme
                    <StyledLink
                      to={parseCustomDomainUrl(
                        isCustomDomain,
                        entityName,
                        "/settings/customization/support-center"
                      )}
                    >
                      Settings
                    </StyledLink>
                  </StyledThemeButtonTitle>
                  <StyledThemeButtonContentContainer>
                    <StyledThemeButtonHint>
                      Customize your own color theme for support center!
                    </StyledThemeButtonHint>
                    <Box textAlign="center">
                      <StyledThemeButtonImg
                        src={require("assets/img/light_theme.svg").default}
                        alt="discord-img"
                      ></StyledThemeButtonImg>
                    </Box>
                  </StyledThemeButtonContentContainer>
                </StyledThemeButton>
              </StyledThemeButtonGroup>
            </StlyledSelectThemeContainer>
          </Box>

          <Box
            sx={{
              width: "calc(100% + 40px)",
              background: (theme) => theme.customColors.grey[300],
              height: "1px",
              marginLeft: "-20px",
              marginRight: "-20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></Box>
          <div style={{ overflowX: "auto" }}>
            <Box width={"576px"}>
              <StyledTicketTypeTitle
                display={"flex"}
                alignItems={"center"}
                columnGap={"4px"}
              >
                {formatMessage({
                  id: "supportCenterCustomization.ticketType.title",
                })}{" "}
                <IconTooltip
                  customColor={theme.customColors.grey[500]}
                  content={formatMessage({
                    id: "supportCenterCustomization.ticketType.tooltip",
                  })}
                ></IconTooltip>
              </StyledTicketTypeTitle>

              <Box mb={3} color={(theme) => theme.customColors.grey[600]}>
                {formatMessage({
                  id: "supportCenterCustomization.ticketType.description",
                })}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                  color: (theme) => theme.customColors.grey[700],
                  pl: "50px",
                  pr: 1,
                  mb: 1.5,
                }}
              >
                <Box>Ticket Type</Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <IconTooltip
                    customColor="#A5A5A5"
                    content={formatMessage({
                      id: "ticketCustomization.autoAssign.tooltip",
                    })}
                    icon={<i className="meta-crm-icon-ic_info font-size-16" />}
                  />
                  <p>
                    {formatMessage({
                      id: "customization.ticketType.autoAssign",
                    })}
                  </p>
                  <Switch
                    checked={autoAssign}
                    onChange={() => handleAutoAssign()}
                  />
                </Box>
              </Box>
              <AutoAssignTicketType
                serviceName={"supportCenter"}
                canEdit={canEdit}
                ticketList={ticketTypesArray}
                autoAssign={autoAssign}
                chooseTicketType={chooseTicketType}
                setChooseTicketType={setChooseTicketType}
              />

              {canEdit && (
                <FixedButton isShowGap={false}>
                  <Button
                    color={COLOR.SECONDARY}
                    size={SIZE.XL}
                    sx={{
                      width: "120px",
                    }}
                    onClick={handleSaveTicketType}
                    disabled={!isDirty}
                  >
                    {formatMessage({ id: "global.btn.save" })}
                  </Button>
                </FixedButton>
              )}
            </Box>
          </div>
        </StyledWhiteContainer>
      )}
      <Prompt when={isDirty} />
    </StyledInterfaceThemeContainer>
  );
};

export default InterfaceTheme;

import { Box } from "@mui/material";
import Back from "features/metadesk/components/Back";
import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash-es";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
import FixedButton from "features/metadesk/settings/common/FixedButton";
import { Loading } from "features/common";
import {
  StyledWhiteContainer,
  StyledContainerTitle,
  StyledContentContainer,
} from "./Referral.styles";
import Objective from "../createCampaign/component/Objective";
import {
  alertServerError,
  parseCustomDomainUrl,
  validateURL,
} from "features/helpers/utils";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/snackbar";

const defaultValues = {
  url: "",
  description: "",
  objectives: [{}],
};

export default function CreateReferral() {
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState(defaultValues);
  const [latestObjectives, setLatestObjectives] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors: errorsInfo, isValid },
  } = methods;

  useEffect(() => {
    handleGetLatestObjective();
  }, []);

  const submitCreate = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${apiUrl}/api/referral/${entityName}/program`, {
        websiteURL: referralData?.url,
        description: referralData?.description,
        objective: get(referralData, "objectives[0]"),
      });
      navigate(
        parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/marketing/dashboard/referral"
        )
      );
    } catch (err) {
      dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetLatestObjective = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/objective/latest`
      );
      setLatestObjectives(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
  return (
    <div style={{ paddingBottom: "20px" }}>
      <FormProvider {...methods}>
        <Back />
        <Box className="title">
          {formatMessage({ id: "referralProgramBuild.firstStep.title" })}
        </Box>
        <Box className="description">
          {formatMessage({ id: "referralProgramBuild.firstStep.description" })}
        </Box>
        <Loading open={isLoading} fullScreen={true} />
        <StyledWhiteContainer>
          <StyledContainerTitle>
            {formatMessage({
              id: "referralProgramBuild.firstStep.information.title",
            })}
          </StyledContainerTitle>
          <StyledContentContainer>
            <Box sx={{ marginBottom: "20px" }}>
              <InputField
                title={
                  <Box className="startRow">
                    {formatMessage({
                      id: "referralProgramBuild.firstStep.websiteURL.title",
                    })}
                    <Box className="required">*</Box>
                  </Box>
                }
                placeholder={formatMessage({
                  id: "widgetSettings.domain.placeholder",
                })}
                onChange={(e) => {
                  setReferralData({ ...referralData, url: e.target.value });
                }}
                name="url"
                control={control}
                errors={get(errorsInfo, "url.message")}
                value={referralData.url}
                prefixWord={"https://"}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id: "global.inputCheck.empty",
                    }),
                  },
                  validate: (value) =>
                    validateURL(value) ||
                    formatMessage({
                      id: "global.inputCheck.invalidUrl",
                    }),
                }}
              ></InputField>
            </Box>
            {/* description input */}
            <Box>
              <InputField
                title={formatMessage({
                  id: "referralProgramBuild.firstStep.description.title",
                })}
                multiline={true}
                rows={5}
                maxLength={200}
                placeholder={formatMessage({
                  id: "global.column.description",
                })}
                name="description"
                value={referralData.description}
                onChange={(e) => {
                  setReferralData({
                    ...referralData,
                    description: e.target.value,
                  });
                }}
              />
            </Box>
          </StyledContentContainer>
        </StyledWhiteContainer>
        <StyledWhiteContainer>
          <StyledContainerTitle>
            {formatMessage({
              id: "referralProgramBuild.firstStep.objective.title",
            })}
          </StyledContainerTitle>
          <StyledContentContainer>
            <Objective
              objectiveIndex={0}
              latestObjectives={latestObjectives}
              formData={referralData}
              setFormData={(values) => {
                setReferralData({
                  ...referralData,
                  ...values,
                });
              }}
            ></Objective>
          </StyledContentContainer>
        </StyledWhiteContainer>
        <FixedButton
          leftComponent={
            <Button
              sx={{ width: "144px" }}
              variant={VARIANT.OUTLINED}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          }
        >
          <Button
            sx={{ width: "144px" }}
            disabled={!isValid}
            onClick={() => {
              handleSubmit(submitCreate());
            }}
          >
            Create
          </Button>
        </FixedButton>
      </FormProvider>
    </div>
  );
}

import styled from "@emotion/styled/macro";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const EntityItemContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  background: #ffffff80;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0px #1717170a;
`;

export const EntityLogo = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: 143,
  height: 46,
  marginRight: 152,

  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "left",
  },

  [theme.breakpoints.down("lg")]: {
    width: 120,
    height: 36,
    marginRight: 100,
  },

  [theme.breakpoints.down("md")]: {
    marginRight: 50,
  },

  [theme.breakpoints.down("sm")]: {
    width: 100,
    height: 30,
    marginRight: 15,
  },
}));

export const EntityMembers = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: 16,
  alignItems: "center",

  "& > p": {
    color: "#a5a5a5",
    fontWeight: "normal",
    marginRight: 15,
    textWrap: "nowrap",

    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

export const ImageContainer = styled.div`
  position: relative;
  width: ${(props) => props.width + `px`};
  height: ${(props) => props.height + `px`};
`;

export const NavigateAction = styled.div`
  margin-left: auto;
`;

import React, { useContext } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  SIZE,
  VARIANT,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { OpenTrialContext } from "features/common/TrialDialog/OpenTrialProvider";
import { CreateNewWorkspaceContainer } from "./CreateNewWorkspace.styled";

const CreateNewWorkspace = () => {
  const { openTrial } = useContext(OpenTrialContext);
  const { formatMessage } = useIntl();
  return (
    <CreateNewWorkspaceContainer>
      <Button
        style={{ width: 272, height: 36 }}
        color={COLOR.SECONDARY}
        variant={VARIANT.OUTLINED}
        size={SIZE.XL}
        onClick={openTrial}>
        {formatMessage({ id: "workspace.btn.create" })}
      </Button>
    </CreateNewWorkspaceContainer>
  );
};

export default CreateNewWorkspace;

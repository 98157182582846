import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { Grid, Modal } from "@mui/material";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";

export const StyledStepsContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: 100,
  paddingLeft: 138,
  overflow: "hidden",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    padding: "100px 100px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "60px 30px",
  },
}));

export const StyledSettingStepNumber = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  background: theme.customColors.purple[300],
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "5px",
}));

export const StyledStepSectionHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const StyledDiscordTicketTypePreviewImg = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("xl")]: {
    position: "absolute",
    top: 180,
    right: -50,
    width: 800,
  },

  [theme.breakpoints.between("lg", "xl")]: {
    position: "absolute",
    top: 180,
    right: -100,
    width: 650,
  },

  [theme.breakpoints.down("lg")]: {
    width: 500,
    position: "relative",
    left: -12,
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const StyledHintContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: 4,
  fontWeight: 400,
  marginTop: "4px",
  color: theme.customColors.purple[500],
}));

export const StyledActionButtonContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: 32,
  columnGap: 8,
  display: "flex",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PerformancePieChart from "./component/PerformancePieChart";
import {
  StyledCard,
  StyledMemo,
} from "features/metadesk/legacy/webpush/Notification.styles";
import moment from "moment";
import { useIntl } from "react-intl";
import { SinglePerformanceContext } from "./context/SinglePerformanceProvider";

export default function SinglePerformanceChart({ chartData, campaignInfo }) {
  const params = new URLSearchParams(window.location.search);
  const dimension = params.get("dimension");
  const dimensionValue = params.get("dimensionValue");

  const [isDataReady, setIsDataReady] = useState(false);

  const {
    newUser = 0,
    returningUser = 0,
    totalUser = 0,
    walletAgeOver1Year = 0,
    walletAgeUnder1Year = 0,
    walletBalanceOver10k = 0,
    walletBalanceUnder10k = 0,
  } = chartData || {};

  const userData = [
    { name: "New User", value: newUser },
    {
      name: "Returning User",
      value: returningUser,
    },
  ];

  const ageData = [
    {
      name: "Over 365 days",
      value: walletAgeOver1Year,
    },
    {
      name: "Under 365 days",
      value: walletAgeUnder1Year,
    },
  ];
  const balanceData = [
    {
      name: "Over $10k",
      value: walletBalanceOver10k,
    },
    {
      name: "Under $10k",
      value: walletBalanceUnder10k,
    },
  ];
  const { filterDetail } = useContext(SinglePerformanceContext);
  const { periodStart, periodEnd } = filterDetail || {};
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (chartData && campaignInfo) {
      setIsDataReady(true);
    }
  }, [chartData, campaignInfo]);

  return (
    <>
      <StyledCard style={{ padding: 0, marginTop: 5 }}>
        {dimension && (
          <Box className="startRow" p={"15px 20px"}>
            <>
              <StyledMemo className="startRow">
                <Box className="mgr4" fontWeight={400} display="flex">
                  <Box color="#555">{dimension}:</Box>
                  <Box color="#000" ml={"4px"}>
                    {dimensionValue}
                  </Box>
                </Box>
              </StyledMemo>
              {/* <div className="borderLeft" /> */}
            </>
          </Box>
        )}
        {dimension && <Box borderBottom={"1px solid #E6E6E6"} m={"0 15px"} />}
        {isDataReady && (
          <Grid container m={"10px 0"}>
            <PerformancePieChart
              pieData={userData}
              value={totalUser}
              title={
                <>
                  <i className="meta-crm-icon-ic_userProfile font-size-18 mgr4" />
                  {formatMessage({ id: "chart.totalUsers.title" })}
                </>
              }
            />
            <PerformancePieChart
              pieData={balanceData}
              title={
                <>
                  <i className="meta-crm-icon-ic_wallentBalance font-size-18 mgr4" />
                  {formatMessage({ id: "chart.walletBalance.title" })}
                </>
              }
            />
            <PerformancePieChart
              pieData={ageData}
              title={
                <>
                  <i className="meta-crm-icon-ic_walletAge font-size-18 mgr4" />
                  {formatMessage({ id: "chart.walletAge.title" })}
                </>
              }
              last={true}
            />
          </Grid>
        )}
      </StyledCard>
    </>
  );
}

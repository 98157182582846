import React from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem } from "@material-ui/core";
import { SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { StyledBadge } from "features/metadesk/importList/ImportUserList.styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    zIndex: 1,
    color: "#383538",
    fontWeight: 500,
    borderRadius: "3px",
    maxHeight: 300,
    "&& .Mui-selected": {
      backgroundColor: "#F7F1FE",
      color: "#383538",
      "&:hover": {
        backgroundColor: "#F7F1FE",
      },
    },
  },
  input: {
    fontWeight: 500,
    fontSize: 14,
    marginRight: 16,
    lineHeight: "28px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
  },
  select: {
    "&:focus": {
      borderRadius: 3,
      backgroundColor: "white",
    },
  },

  root: {
    background: "white",
    boxSizing: "border-box",
    padding: "0px 12px",
    minWidth: 90,
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: 500,
    fontFamily: "Inter",
    border: (props) => (props.border ? "1px solid #E6E6E6" : "1px solid white"),
    "&[aria-expanded=true]": {
      borderRadius: 3,
      border: (props) =>
        props.noExpandedBorder ? "1px solid #FFFFFF" : "1px solid #7B61FF",
    },
  },
  [SIZE.XL]: {
    height: 40,
  },
  [SIZE.LARGE]: {
    height: 36,
  },
  [SIZE.MEDIUM]: {
    height: 32,
    minWidth: 50,
    padding: "0 8px",
  },
  [SIZE.SMALL]: {
    height: 28,
    padding: "0 8px",
    minWidth: 50,
  },
  [SIZE.XS]: {
    height: 24,
    padding: "0 8px",
    minWidth: 30,
  },
  [SIZE.XSS]: {
    height: 16,
    padding: "0 4px",
    minWidth: 30,
  },

  error: {
    background: "#FFDED7",
    border: "1px solid #FC5555",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
  },
}))(MenuItem);

export const CustomDropdown = ({
  data,
  onSelect,
  value,
  style,
  sm,
  size = SIZE.XL,
  label,
  border,
  noExpandedBorder,
  renderInput,
  fullWidth,
  disabled,
  placeholder,
  render,
  error,
  multipleValues = false,
  limitCount,
  multiple,
  preText,
  readonly = false,
  ...props
}) => {
  const classes = useStyles({ border, noExpandedBorder, size });
  if (sm) size = SIZE.MEDIUM;

  const renderInputContent = (selected, match) => {
    if (multiple && limitCount) {
      return (
        <div className="startRow">
          {match.map((value, i) => {
            if (i > limitCount - 1) return;
            if (value)
              return renderInput
                ? renderInput(value)
                : _.get(value, "label", "");
          })}
          {match.length > limitCount && (
            <StyledBadge badgeContent={selected.length - 1} color="secondary" />
          )}
        </div>
      );
    }
    return renderInput ? renderInput(match) : _.get(match, "label", "");
  };

  if (readonly) {
    let match = _.find(data, ["value", value]);
    if (multiple) {
      match = _.filter(data, (o) => {
        return _.includes(value, o._id);
      });
    }
    if (multipleValues) {
      match = _.find(data, ["values", value]);
    }

    return (
      <div>
        {label && (
          <div style={{ marginBottom: 6.5, fontWeight: 500, fontSize: 14 }}>
            {label}
          </div>
        )}
        <div className={classes.input}>
          {preText && preText}
          {renderInputContent(value, match)}
        </div>
      </div>
    );
  }
  return (
    <div>
      {label && (
        <div style={{ marginBottom: 6.5, fontWeight: 500, fontSize: 14 }}>
          {label}
        </div>
      )}
      <Select
        disabled={disabled || data.length == 0}
        value={value}
        multiple={multiple}
        disableUnderline={true}
        fullWidth={fullWidth}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          MenuListProps: { disablePadding: true },
          PaperProps: {},
          classes: { paper: classes.menuPaper },
        }}
        style={{ marginRight: 8, ...style }}
        classes={{
          select: classes.select,
          root: `${classes.root} ${classes[size]} ${error && classes.error}`,
          outlined: classes.input,
        }}
        onChange={onSelect}
        displayEmpty={true}
        renderValue={(selected) => {
          if (!value || !selected) {
            return (
              <Box
                className={classes.input}
                color={(theme) => theme.customColors.grey[700]}
              >
                {placeholder ? placeholder : ""}
              </Box>
            );
          }
          let match = _.find(data, ["value", selected]);
          if (multiple) {
            match = _.filter(data, (o) => {
              return _.includes(selected, o._id);
            });
          }
          if (multipleValues) {
            match = _.find(data, ["values", selected]);
          }

          return (
            <div className={classes.input}>
              {preText && preText}
              {renderInputContent(selected, match)}
            </div>
          );
        }}
        {...props}
      >
        {_.map(data, (d) =>
          render ? (
            render(d)
          ) : (
            <StyledMenuItem
              key={multipleValues ? _.get(d, "label") : _.get(d, "value")}
              value={multipleValues ? _.get(d, "values") : _.get(d, "value")}
              disabled={_.get(d, "disabled")}
            >
              {_.get(d, "label")}
            </StyledMenuItem>
          )
        )}
      </Select>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default CustomDropdown;

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledTicketDetailContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {};
});

export const StyledMessageInfoContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    width: "240px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  };
});

export const StyledChatAreaContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  USER_INFO_FETCH_BEGIN,
  USER_INFO_FETCH_SUCCESS,
  USER_INFO_FETCH_FAILURE,
  USER_INFO_UPDATE_SUCCESS,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";

export function fetchUserInfo({ entityName, userId }) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_INFO_FETCH_BEGIN,
    });
    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${apiUrl}/api/user?userId=${userId}&entityName=` + entityName
        );
        dispatch({
          type: USER_INFO_FETCH_SUCCESS,
          data: result.data,
          userId,
        });
      } catch (err) {
        dispatch({
          type: USER_INFO_FETCH_FAILURE,
        });
        dispatch(
          enqueueSnackbar({
            message: _.get(
              err,
              "response.data.error",
              _.get(err, "response.data.message", "error")
            ),
            options: {
              variant: "error",
            },
          })
        );
      }
    });

    return promise;
  };
}

export function useFetchUserInfo() {
  const dispatch = useDispatch();

  const { userInfos, fetchUserInfoPending, fetchUserInfoDone } = useSelector(
    (state) => ({
      userInfos: state.metadesk.userInfos,
      fetchUserInfoPending: state.metadesk.fetchUserInfoPending,
      fetchUserInfoDone: state.metadesk.fetchUserInfoDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchUserInfo(data));
    },
    [dispatch]
  );

  return {
    userInfos,
    fetchUserInfo: boundAction,
    fetchUserInfoDone,
    fetchUserInfoPending,
  };
}
export function reducer(state, action) {
  switch (action.type) {
    case USER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          [action.userId]: action.data,
        },
      };
    case USER_INFO_FETCH_BEGIN:
      return {
        ...state,
        fetchUserInfoPending: true,
        fetchUserInfoDone: false,
      };

    case USER_INFO_FETCH_SUCCESS:
      return {
        ...state,
        userInfos: {
          ...state.userInfos,
          [action.userId]: action.data,
        },
        fetchUserInfoPending: false,
        fetchUserInfoDone: true,
      };
    case USER_INFO_FETCH_FAILURE:
      return {
        ...state,
        fetchUserInfoPending: false,
        fetchUserInfoDone: false,
      };

    default:
      return state;
  }
}

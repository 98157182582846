import React from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  collapseRow: {
    background: "white",
    padding: "10px",
    fontSize: 12,
    minHeight: 49,
    fontWeight: 500,
    borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
  },
  row: {
    background: "white",
    padding: "10px",
    fontSize: 12,
    minHeight: 49,
    fontWeight: 500,
    borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
    marginBottom: 5,
  },
  rowHeader: {
    color: "#7B61FF",
    fontSize: 10,
    fontWeight: 500,
  },
}));

export default function Rows({
  headers,
  contents,
  widths,
  loading,
  fetching,
  style,
  collapse = true,
}) {
  const classes = useStyles();

  return (
    <div>
      <div style={{ padding: "10px 10px 0 10px" }}>
        <Grid container alignItems="center" className={classes.rowHeader}>
          {_.map(headers, (header, i) => (
            <Grid item xs={12} key={i} sm={widths[i]}>
              {header}
            </Grid>
          ))}
        </Grid>
      </div>
      <div
        style={{
          paddingTop: 10,
        }}
      >
        {fetching ? (
          <div
            style={{
              background: "#FFFFFF",
              borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
              textAlign: "center",
              fontWeight: 600,
              padding: 10,
              marginTop: 5,
            }}
          >
            <div className="row" style={{ color: "#7B61FF" }}>
              <img src={require("assets/img/loading.gif")} className="icon" />
              Data Fetching...
            </div>
          </div>
        ) : loading ? (
          <div style={{ textAlign: "center" }}>
            <img src={require("assets/img/loading.gif")} className="icon" />
          </div>
        ) : contents && contents.length > 0 ? (
          contents.map((row, i) => {
            return (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={collapse ? classes.collapseRow : classes.row}
                key={i}
                style={{ ...style }}
              >
                {_.map(row, (content, c) => (
                  <Grid item xs={12} key={c} sm={widths[c]}>
                    {content}
                  </Grid>
                ))}
              </Grid>
            );
          })
        ) : (
          <div className="noData">
            <div className="row">
              <img
                src={require("assets/img/boxOpen.svg").default}
                className="icon"
              />
              No Data
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

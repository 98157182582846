import { Box } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";

export default function ImportDataHistoryBox({ history }) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const { type, addressCount } = history;
  const fileName =
    type === "csv"
      ? history.csvFilename.split("/").pop()
      : history.contractName;

  return (
    <Box>
      <Box
        sx={{
          pl: "16px",
          pb: "20px",
          borderLeft: "1px solid #E6E6E6",
          fontSize: 12,
          color: "#A5A5A5",
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: "-0.5px",
            width: "5px",
            height: "5px",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
            backgroundColor: "#A5A5A5",
          },
        }}
      >
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Box
            sx={{
              color: type === "csv" ? "#38C8C7" : "#698DEE", // CSV => #38C8C7 , Smart Contract => #698DEE
              fontWeight: 700,
              fontSize: 14,
              mb: "4px",
            }}
          >
            {type === "csv" ? "CSV" : "Smart Contract"}
          </Box>
          <Box>{moment(history.importTime).format("MMM DD,YYYY HH:mmA")}</Box>
        </Box>
        <Box display="flex" alignItems="center" mb="4px">
          Address count :
          <Box color="#777" pl="4px">
            {addressCount}
          </Box>
        </Box>
        <Box display="flex" alignItems="start" mb="4px">
          <Box flexShrink={0}>
            {type === "csv" ? "File" : "Contract"} Name :
          </Box>
          <Box color="#777" pl="4px">
            {fileName}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#7B61FF",
            gap: "2px",
            cursor: "pointer",
            mb: "4px",
          }}
          onClick={handleOpen}
        >
          Details
          {open ? (
            <i className="meta-crm-icon-ic_arrowLineUp font-size-14" />
          ) : (
            <i className="meta-crm-icon-ic_arrowLineDown font-size-14" />
          )}
        </Box>

        {open && (
          <>
            {type === "csv" ? (
              <Box display="flex" alignItems="center" mb="4px">
                Vaild fields :
                <Box color="#777" pl="4px">
                  {history.validFields || 0}
                </Box>
              </Box>
            ) : (
              <>
                <Box display="flex" alignItems="center" mb="4px" flexShrink={0}>
                  Address :
                  <Box color="#777" pl="4px">
                    {history.contractAddress}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb="4px">
                  Import type :
                  <Box color="#777" pl="4px">
                    {history.contractImportType}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" mb="4px">
                  Time range :
                  <Box color="#777" pl="4px">
                    {moment(history.contractInteractTimeRange?.from).format(
                      "DD/MM/YYYY"
                    )}
                    ~
                    {moment(history.contractInteractTimeRange?.to).format(
                      "DD/MM/YYYY"
                    )}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

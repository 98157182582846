import axios from "axios";
import { apiUrl } from "features/configure";
import { get, includes } from "lodash-es";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { CampaignListContext } from "../context/CampaignListProvider";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { useIntl } from "react-intl";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import { useFetchTicketList } from "features/metadesk/redux/fetchTicketList";
import useNavigateEntity from "hooks/useNavigateEntity";
import { useConnectWallet } from "features/home/redux/connectWallet";
import useGetPagePermission from "hooks/useGetPagePermission";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";

export default function useGetCampaignTable() {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { status, search, promotype } = useContext(CampaignListContext);
  const [entityName, isCustomDomain] = useOutletContext();
  const [update, setUpdate] = useState(false);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();
  const dispatchSnackbar = useDispatchSnackbar();
  const { navigateEntity } = useNavigateEntity();

  const headCells = [
    {
      show: true,
      disabledSort: true,
      id: "status",
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {formatMessage({ id: "campaign.header.status" })}
        </div>
      ),
      title: "Status",
    },
    {
      show: true,
      disabledSort: true,
      id: "campaignName",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "campaigns.header.campaignName" })}
        </div>
      ),
      title: "Campaign Name",
    },
    {
      show: true,
      disabledSort: true,
      id: "trackingLink",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "campaigns.header.trackingLink" })}
        </div>
      ),
      title: "Tracking Link",
    },
    {
      show: true,
      disabledSort: true,
      id: "promotype",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          Promotion Type
        </div>
      ),
      title: "Promotion Type",
    },
    {
      show: true,
      disabledSort: true,
      id: "clicks",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "campaigns.header.clicks" })}
        </div>
      ),
      title: "Clicks",
    },
    {
      show: true,
      disabledSort: true,
      id: "startDate",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {formatMessage({ id: "campaigns.header.startDate" })}
          <IconTooltip
            style={{ marginLeft: "4px" }}
            content={formatMessage({ id: "campaign.startDate.description" })}
          />
        </div>
      ),
      title: "Start Date",
    },
    {
      show: true,
      disabledSort: true,
      id: "lastUpdated",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "campaigns.header.lastUpdate" })}
        </div>
      ),
      title: "Last Updated",
    },
    {
      show: false,
      disabledSort: true,
      id: "assignee",
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {formatMessage({ id: "campaigns.header.assignee" })}
        </div>
      ),
      title: "Assignee",
    },
    {
      show: true,
      disabledSort: true,
      id: "action",
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {formatMessage({ id: "campaigns.header.action" })}
        </div>
      ),
      title: "Action",
    },
  ];

  async function getCampaignList() {
    try {
      setLoading(true);
      const { data, headers } = await axios(
        `${apiUrl}/api/campaign/${entityName}?page=${page}&sort=${orderBy}&order=${order}&status=${status}&search=${search}${
          promotype ? `&promotype=${promotype}` : ""
        }`
      );
      setTotalCount(get(headers, "x-total-count", 0));
      setData(data);
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setLoading(false);
    }
  }

  // 當切換狀態或搜尋時，將頁數回到第一頁，並重新取得資料
  useEffect(() => {
    setPage(1);
    setUpdate(true);
  }, [status, search, promotype]);

  // 當切換頁數時，重新取得資料
  useEffect(() => {
    setUpdate(true);
  }, [page]);

  // 用 update 來判斷是否需要更新
  useEffect(() => {
    if (!update) return;
    getCampaignList();
    setUpdate(false);
  }, [update]);

  const handleDeleteAPI = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/campaign/${entityName}/${id}`);
      await getCampaignList();
      dispatchSnackbar({
        message: "Delete campaign successfully",
        variant: "success",
      });
      getStatsCounts({ entityName });
    } catch (err) {
      dispatchSnackbar({ err });
    }
  };
  const { getStatsCounts } = useFetchTicketList();

  async function handleDelete(id, description) {
    confirm({
      title: formatMessage({
        id: "popup.delete.title",
      }),
      content:
        description ||
        formatMessage({
          id: "popup.delete.description",
        }),
      confirmText: formatMessage({
        id: "global.btn.delete",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(async () => {
        await handleDeleteAPI(id);
      })
      .catch((err) => {
        console.log("cancel", err);
      });
  }
  const { userData } = useConnectWallet();

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("campaigns");

  function handleRowClick(id) {
    const campaign = data.find((val) => val._id === id);
    const CampaignStatus = campaign?.status;
    const removeClick = ["draft", "scheduled"];
    if (CampaignStatus === "scheduled") {
      navigateEntity(`/marketing/campaign/edit/${id}`);
    } else if (CampaignStatus === "draft") {
      if (readonly) return;
      navigateEntity(`/marketing/campaign/create/${id}`);
    } else {
      if (pagePermission("campaignPerformance").show) {
        const CampaignName = campaign?.name;
        navigateEntity(
          `/marketing/dashboard/campaign?search=${CampaignName}&promotype=${campaign?.promotype}`
        );
      }
    }
  }

  function handleRequestSort() {}

  return {
    loading,
    page,
    setPage,
    rowData: data,
    headCells,
    totalCount,
    handleRowClick,
    handleRequestSort,
    handleDelete,
    order,
    orderBy,
  };
}

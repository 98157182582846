import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledAlreadyAUser = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .MuiButtonBase-root": {
    padding: 0,
  },
}));

export const StyledConnectWalletContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "10px 14px",
  borderRadius: "3px",
  background: theme.customColors.purple[300],
  marginBottom: "16px",
}));

export const StyledRegisterConnectWallet = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
}));

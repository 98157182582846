import styled from "styled-components";
import Button from "components/CustomButtons/Button";

export const StyledButton = styled(Button)`
  fill: ${(props) => (props.white ? "#fff" : "#141314")};

  & svg {
    margin: 0 2px;
  }
  &:hover {
    background: ${(props) => (props.white ? "#fff" : "#7b61ff")};
    fill: ${(props) => (props.white ? "#7b61ff" : "#fff")};
  }
`;

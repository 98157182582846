import React, { useState, useEffect } from "react";
import _ from "lodash";
import { getHexImg } from "features/helpers/utils";
import { getAddress } from "viem";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useSelector } from "react-redux";
import solanaTokenList from "./solanaToken";

const Token = ({ data, onClick, width = 20, height = 20, margin = 5 }) => {
  const [imgSrc, setImgSrc] = useState();
  const ecosystemName = useSelector(selectEcosystemName);

  const getTokenImg = () => {
    if (data.icon) {
      return data.icon;
    }

    let address = data.address;
    if (address === "0x0000000000000000000000000000000000000000") {
      address = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
    }

    if (ecosystemName === "Solana") {
      const targetObj = solanaTokenList.find(
        (item) => item.address === address
      );
      return targetObj.logoURI;
    }

    if (ecosystemName === "EVM") {
      address = getAddress(address);
    }

    return `https://storage.googleapis.com/metadesk-dev/token/${data.chainId}/${address}.png`;
  };
  useEffect(async () => {
    setImgSrc(getTokenImg());
  }, [data]);

  const handleError = () => {
    if (data.address == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      setImgSrc(
        `https://storage.googleapis.com/metadesk-dev/token/1/${getAddress(
          "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        )}.png`
      );
      return;
    }
    if (data.address) {
      return setImgSrc(getHexImg(_.get(data, "address", "")));
    } else {
      setImgSrc(require("assets/img/empty-token.png"));
    }
  };
  return (
    <div
      className="startRow"
      onClick={() => onClick && onClick(data)}
      style={{ cursor: onClick ? "pointer" : "" }}
    >
      <img
        className="smIcon"
        style={{ borderRadius: 30, width, height, margin }}
        src={imgSrc}
        onError={handleError}
      />
      {data.symbol || data.name}
    </div>
  );
};
export default Token;

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledSettingSupportCenterContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingTop: "35px",
  display: "flex",
  alignItems: "center",
  minHeight: `100vh`,
  backgroundColor: theme.customColors.white,
  minWidth: "1440px",
  justifyContent: "center",
  paddingBottom: "50px",
}));

export const StyledSettingAreaContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledSettingAreaTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "8px",
}));

export const StyledSettingAreaHint = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  marginBottom: "32px",
  lineHeight: "20px",
}));

export const StyledSettingListContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledSettingAreaButtonContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  margin: "50px 0",
}));

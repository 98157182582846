import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { get, isEmpty, isEqual } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "features/common/redux/actions";
import Box from "@mui/material/Box";
import { StyledWidgetTitle } from "./WidgetDemoTemplate.styles";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import AutoAssignTicketType from "../Customization/AutoAssignTicketType/AutoAssignTicketType";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import axios from "axios";
import { apiUrl } from "features/configure";
import useGetPagePermission from "hooks/useGetPagePermission";
import { Loading } from "features/common";

function sortArray(arr) {
  if (isEmpty(arr)) return arr;
  return arr;
}

const mergeTicketTypesArray = (arr1, arr2) => {
  const map = new Map(arr2.map((item) => [item._id, item]));

  return arr1.map((item) => {
    return map.has(item._id) ? { ...item, assignee: map.get(item._id) } : item;
  });
};

const WidgetTicketType = ({
  widgetItemInfo,
  onSetDirty,
  onRefetch,
  goBack,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [entityName, isCustomDomain] = useOutletContext();
  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("customization");

  const { ticketTypes, _id } = widgetItemInfo;

  const { ticketTypeList } = useFetchEntityConfig();
  const [ticketTypesArray, setTicketTypesArray] = useState([]);

  const [initialType, setInitialType] = useState([]);
  const [chooseTicketType, setChooseTicketType] = useState([]);
  const [autoAssign, setAutoAssign] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGetTicketTypes = async (data) => {
    setLoading(true);
    try {
      const { data: ticketTypesData } = await axios.get(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/widget/${_id}`
      );
      const cleanerTicketTypes = (ticketTypesData.ticketTypes || []).map(
        (tt) => ({
          _id: tt.ticketType,
          assignee: tt.assignee || null,
        })
      );

      const newTicketTypes = mergeTicketTypesArray(data, cleanerTicketTypes);

      setTicketTypesArray(newTicketTypes);

      setChooseTicketType(cleanerTicketTypes);
      setInitialType(cleanerTicketTypes);
      setAutoAssign(ticketTypesData.autoAssign);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(ticketTypes)) {
      handleGetTicketTypes(ticketTypeList);
    }
  }, [ticketTypes]);

  useEffect(() => {
    const sortedChooseTicketType = [...chooseTicketType].sort();
    const sortedInitialType = [...initialType].sort();

    if (!isEqual(sortedChooseTicketType, sortedInitialType)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [initialType, chooseTicketType]);

  const handleSaveTicketType = async () => {
    try {
      const { data: updatedTicketTypesData } = await axios.post(
        `${apiUrl}/api/manage/ticketTypes/${entityName}/widget/${_id}`,
        {
          ticketTypes: _.map(chooseTicketType, (obj) => ({
            ticketType: obj._id,
            assignee: obj.assignee,
          })),
        }
      );
      const cleanerTicketTypes = (updatedTicketTypesData || []).map((tt) => ({
        _id: tt.ticketType,
        assignee: tt.assignee || null,
      }));

      const newTicketTypes = mergeTicketTypesArray(
        ticketTypeList,
        cleanerTicketTypes
      );

      setTicketTypesArray(newTicketTypes);

      setInitialType(cleanerTicketTypes);
      setChooseTicketType(cleanerTicketTypes);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleAutoAssign = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/manage/ticket/toggleAutoAssign/${entityName}/widget/${_id}`,
        { isEnableAutoAssign: !autoAssign }
      );
      if (data.success) setAutoAssign(!autoAssign);
      dispatch(
        enqueueSnackbar({
          message: `Auto assign ${!autoAssign ? "Enabled" : "Disabled"}`,
          options: { variant: "success" },
        })
      );
    } catch (error) {}
  };

  const saveConfig = async () => {
    setLoading(true);
    try {
      await handleSaveTicketType();
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "widgetSettings.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: get(err, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ background: "white" }}>
      <StyledWidgetTitle display={"flex"} alignItems={"center"}>
        <Loading open={loading} fullScreen={false} />
        {formatMessage({ id: "widgetCustomization.ticketType.subtitle" })}{" "}
        <span className="required">*</span>
        <IconTooltip
          customColor={theme.customColors.grey[500]}
          content={formatMessage({
            id: "widgetCustomization.ticketType.tooltip",
          })}></IconTooltip>
      </StyledWidgetTitle>

      <Box
        sx={{ maxWidth: "465px" }}
        mb={2}
        color={(theme) => theme.customColors.grey[600]}>
        {formatMessage({ id: "widgetCustomization.ticketType.description" })}
      </Box>
      <div style={{ overflowX: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            color: (theme) => theme.customColors.grey[700],
            pl: "50px",
            pr: 1,
            mb: 1.5,
            width: 576,
          }}>
          <Box>Ticket Type</Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <IconTooltip
              customColor="#A5A5A5"
              content={formatMessage({
                id: "ticketCustomization.autoAssign.tooltip",
              })}
              icon={<i className="meta-crm-icon-ic_info font-size-16" />}
            />
            <p>
              {formatMessage({
                id: "customization.ticketType.autoAssign",
              })}
            </p>
            <Switch checked={autoAssign} onChange={() => handleAutoAssign()} />
          </Box>
        </Box>
        <div style={{ width: 576 }}>
          <AutoAssignTicketType
            serviceName={"widget"}
            canEdit={canEdit}
            ticketList={ticketTypesArray}
            autoAssign={autoAssign}
            chooseTicketType={chooseTicketType}
            setChooseTicketType={setChooseTicketType}
          />
        </div>
      </div>
      <Box marginTop={"32px"}>
        <Button
          variant={VARIANT.OUTLINED}
          color={COLOR.SECONDARY}
          size={SIZE.XL}
          style={{ width: 120, marginRight: 8 }}
          onClick={goBack}>
          {formatMessage({ id: "global.btn.back" })}
        </Button>
        {canEdit && (
          <Button
            disabled={!isDirty}
            color={COLOR.SECONDARY}
            onClick={saveConfig}
            size={SIZE.XL}
            style={{ width: 120 }}>
            {formatMessage({ id: "global.btn.save" })}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default WidgetTicketType;

import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Checkbox, COLOR } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import Box from "@mui/material/Box";
import { get, debounce } from "lodash-es";
import OtpInput from "react-otp-input";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAccount, useDisconnect } from "wagmi";

import { useStepData } from "contexts/stepContext/Step.context";
import { enqueueSnackbar } from "features/common/redux/actions";
import { postRegisterVerifyAsyncAction } from "features/metadesk/redux/trial/trial.action";
import { selectTrialIsLoading } from "features/metadesk/redux/trial/trial.selector";

import {
  StyledEmailHint,
  StyledYourEmail,
  StyledConfirmText,
  StyledOtpInput,
} from "./RegisterStep2.styles";
import { StyledRegisterStepTitle } from "../RegisterForm.styles";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";

const RegisterStep2 = ({ onNextStep, onPrevStep, module }) => {
  const numInputs = 4;
  const [otp, setOtp] = useState("");
  const { setStepValues, stepData } = useStepData();
  const isLoading = useSelector(selectTrialIsLoading);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { getAuthenticatedInfo } = useConnectWallet();
  const { entityConfig, fetchEntityConfig } = useFetchEntityConfig();
  const { address, isConnected } = useAccount({});

  const handleModuleType = () => {
    switch (pathname) {
      case "/trial/marketing":
        return "marketing";
      case "/trial/service":
        return "service";
      default:
        return null;
    }
  };

  const handleVerify = async () => {
    const { data } = await axios.post(`${apiUrl}/api/entity/verify`, {
      companyName: stepData.companyName,
      name: stepData.name,
      verifyCode: otp,
      address: address,
      moduleType: handleModuleType(),
    });
    dispatch(
      postRegisterVerifyAsyncAction(data, async () => {
        await getAuthenticatedInfo(stepData.companyName);
        fetchEntityConfig({ entityName: stepData.companyName });
      })
    );
  };

  const handleResend = async () => {
    try {
      const { data } = await axios.post(`${apiUrl}/api/entity/register`, {
        companyName: stepData.companyName,
        email: stepData.email,
        module,
      });
      setStepValues({ resendTimestamp: data.resendTimestamp });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(
            error,
            "response.data.error",
            get(error, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    }
  };
  const debouncedHandleResend = debounce(handleResend, 500);

  const handleResendClick = () => {
    debouncedHandleResend();
  };

  return (
    <>
      <StyledRegisterStepTitle>We just emailed you</StyledRegisterStepTitle>
      <StyledEmailHint>
        Please input the confirmation code we sent to you.
      </StyledEmailHint>
      <StyledYourEmail>{stepData.email}</StyledYourEmail>
      <StyledConfirmText>Confirmation code</StyledConfirmText>
      <StyledOtpInput>
        <OtpInput
          className="xxx"
          value={otp}
          onChange={setOtp}
          numInputs={numInputs}
          renderSeparator={<span> </span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            textAlign: "center",
            maxWidth: "23%",
            fontSize: "28px",
            fontWeight: 900,
          }}
          focusStyle={{
            outline: "none",
            border: "2px solid #05DEBA",
            borderRadius: "8px",
          }}
          skipDefaultStyles={true}
          inputType="number"
          shouldAutoFocus={true}
        />
      </StyledOtpInput>
      <Button
        sx={{ width: "100%", marginBottom: "5px" }}
        disabled={otp.length !== numInputs}
        onClick={handleVerify}
        isLoading={isLoading}
      >
        Verify
      </Button>

      <Button
        sx={{ width: "100%" }}
        variant={VARIANT.TEXT}
        onClick={handleResendClick}
        resendTimestamp={stepData.resendTimestamp}
      >
        Resend confirmation code
      </Button>
    </>
  );
};

export default RegisterStep2;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@material-ui/core";
import Box from "@mui/material/Box";

import { enqueueSnackbar } from "features/common/redux/actions";
import { useFetchAiSupportConfig } from "features/metadesk/redux/aiSupport/fetchAiSupport";

import {
  InputField,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/InputField";
import { Button, COLOR } from "@metacrm/metacrm-material-ui/dist/Button";

import {
  StyledSettingTitle,
  StyledDescription,
} from "../Customization/TicketType/TicketType.styles";
import {
  LastUpdatedTimeStamp,
  TrainingMsg,
  UrlInputWrapper,
} from "./GitbookUrlSource.styles";
import useGetPagePermission from "hooks/useGetPagePermission";

const TRAINING_CONDITIONS = ["inprogress", "ready"];

const formDataInit = {
  documentUrl: "",
};

export default function GitbookUrlSource({ aiConfig }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { formatMessage } = useIntl();
  const {
    handleSubmit,
    control,
    formState: { errors: errorsInfo, isValid },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...formDataInit },
  });
  const [formData, setFormData] = useState(formDataInit);
  const [intervalId, setIntervalId] = useState(null);
  const { fetchAiSupportConfig, updateAiConfig } = useFetchAiSupportConfig();
  const documentSyncStatus = _.get(aiConfig, "documentSyncStatus");
  const documentUrl = _.get(aiConfig, "documentUrl");
  const updatedAt = _.get(aiConfig, "updatedAt", null);

  const isTraining = () => {
    return _.includes(TRAINING_CONDITIONS, documentSyncStatus);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setFormData({ ...formData, documentUrl });
    reset({ ...formData, documentUrl });
    // Poll for latest documentSyncStatus
    if (_.includes(TRAINING_CONDITIONS, documentSyncStatus)) {
      if (!intervalId) {
        let intervalId = setInterval(async () => {
          await fetchAiSupportConfig({ entityName });
        }, 15000);
        setIntervalId(intervalId);
      }
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [aiConfig]);

  const handleSave = async () => {
    try {
      await updateAiConfig({
        entityName,
        documentUrl: formData.documentUrl,
      });
      dispatch(
        enqueueSnackbar({
          message: "Update success!",
          options: {
            variant: "info",
          },
        })
      );
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: _.get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const pagePermission = useGetPagePermission();
  const { canEdit, readonly } = pagePermission("aiSupport");

  const renderInputTitle = () => {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
          <img
            src={require("assets/img/ic_gitbookUrlIcon.svg").default}
            alt="gitbook-logo"
          />
          {formatMessage({
            id: "aiManagement.dataSource.addGitbook.subtitle",
          })}
          <span className="required">* </span>
        </div>
        <div
          style={{
            padding: "6px 0",
            color: theme.customColors.grey[600],
            fontSize: theme.fontSize.TINY,
          }}
        >
          {formatMessage({
            id: "aiManagement.dataSource.addGitbook.label",
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <StyledSettingTitle
        style={{ fontWeight: theme.fontWeight.BOLD, fontSize: 18 }}
      >
        {formatMessage({ id: "aiManagement.dataSource.addGitbook.title" })}
      </StyledSettingTitle>
      <StyledDescription>
        {formatMessage({
          id: "aiManagement.dataSource.addGitbook.description",
        })}
      </StyledDescription>
      <UrlInputWrapper>
        <Box style={{ width: !isMobile ? 480 : "100%" }}>
          <InputField
            control={control}
            readonly={readonly}
            size={SIZE.XL}
            name="documentUrl"
            variant={VARIANT.OUTLINED}
            rules={{
              required: {
                value: true,
                message: "Gitbook URL is required",
              },
            }}
            title={renderInputTitle()}
            errors={_.get(errorsInfo, "documentUrl.message")}
            errorTextAbsolute={true}
            placeholder={formatMessage({
              id: "aiManagement.dataSource.addGitbook.placeholder",
            })}
            value={formData.documentUrl}
            onChange={handleFieldChange}
          />
        </Box>
        {isTraining() ? (
          <TrainingMsg>
            <div>
              <img
                src={require("assets/img/loading.gif")}
                style={{ width: 25, height: 25 }}
              />
            </div>
            <div>
              {formatMessage({ id: "aiManagement.dataSource.status.training" })}
            </div>
          </TrainingMsg>
        ) : (
          <div
            style={{
              display: "flex",
              columnGap: 8,
              ...(isMobile && {
                flexDirection: "row-reverse",
                width: "100%",
                justifyContent: "space-between",
              }),
            }}
          >
            {canEdit && (
              <Button
                color={COLOR.INFO}
                size={SIZE.XL}
                variant={VARIANT.OUTLINED}
                disabled={!isValid}
                style={{
                  fontSize: theme.fontSize.TINY,
                  width: !isMobile ? 70 : 140,
                  border: `1px solid ${theme.customColors.grey[300]}`,
                }}
                onClick={handleSave}
              >
                <i className="meta-crm-icon-ic_refresh font-size-18" />
                {formatMessage({
                  id: "global.btn.sync",
                })}
              </Button>
            )}
            {updatedAt && (
              <LastUpdatedTimeStamp>
                <div>
                  {formatMessage({ id: "global.header.lastUpdated" })}
                  {":"}
                </div>
                <div>{moment(updatedAt).format("MMM D, YYYY h:mmA")}</div>
              </LastUpdatedTimeStamp>
            )}
          </div>
        )}
      </UrlInputWrapper>
    </>
  );
}

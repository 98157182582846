import React from "react";
import { useIntl } from "react-intl";
import { useDisconnect } from "wagmi";
import { useTheme } from "@mui/styles";

import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import Box from "@mui/material/Box";

import {
  StyledRegisterContainer,
  StyledRegisterSwitchWalletRow,
  StyledAlreadyAUser,
  StyledRegisterMainContent,
  StyledHelpHint,
  StyledLink,
  StyledMetaDeskTitleImage,
} from "features/home/pages/Trial/RegisterForm/RegisterForm.styles";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";
import NoAuth from "features/metadesk/components/NoAuth";

const WorkspaceWrapper = ({
  children,
  isShowHelperMsg = true,
  isShowBrandIcon = true,
}) => {
  const theme = useTheme();
  const { disconnect } = useDisconnect();
  const { formatMessage } = useIntl();

  const isShowNoAuth = !isShowBrandIcon && !isShowHelperMsg;

  return (
    <>
      <StyledRegisterContainer style={{ backgroundAttachment: "fixed" }}>
        <StyledRegisterSwitchWalletRow>
          <StyledAlreadyAUser>
            {formatMessage({ id: "workspace.begin.explanatory" })}
            <Button
              sx={{ marginLeft: "5px" }}
              variant={VARIANT.TEXT}
              onClick={() => {
                disconnect();
                window.location.href = "/workspace/login";
              }}>
              {formatMessage({ id: "explanatory.action.switchWallet" })}
            </Button>
          </StyledAlreadyAUser>
        </StyledRegisterSwitchWalletRow>
        <StyledRegisterMainContent>
          <Box>
            {isShowBrandIcon && (
              <StyledMetaDeskTitleImage>
                <IconIcMetaCRM width={150} />
              </StyledMetaDeskTitleImage>
            )}
            {isShowNoAuth && <NoAuth />}
            {children}
            {isShowHelperMsg && (
              <StyledHelpHint style={{ color: theme.customColors.grey[700] }}>
                {formatMessage({ id: "workspace.end.explanatory" })}
                <StyledLink
                  target="_blank"
                  to="mailto: bd@metacrm.inc"
                  style={{ textDecoration: "underline" }}>
                  {formatMessage({ id: "explanatory.action.contactUs" })}
                </StyledLink>
              </StyledHelpHint>
            )}
          </Box>
        </StyledRegisterMainContent>
      </StyledRegisterContainer>
    </>
  );
};

export default WorkspaceWrapper;

import React, { useState } from "react";
import _ from "lodash";

import {
  StyledGeneralSettingsContainer,
  StyledSettingTitle,
  StyledDescription,
} from "./OnChainDataSource.styles";
import PortfolioTokens from "./PortfolioTokens";
import BasicModal from "features/metadesk/chat/BasicModal";

const PortfolioPreference = ({
  portfolioTokensData,
  setPortfolioTokensData,
  entityName,
}) => {
  const [openEffectPreview, setOpenEffectPreview] = useState(false);

  return (
    <StyledGeneralSettingsContainer>
      <BasicModal
        open={openEffectPreview}
        textAlign="left"
        onClose={() => {
          setOpenEffectPreview(false);
        }}
        maxWidth="850px"
        modalTitle={"Effect Preview"}
        modalContent={"Query the contract you want from the blockchain."}
        maxWidth={"900px"}
        children={
          <img
            src={require("assets/img/portfolioEffectPreview.png")}
            style={{ height: 432 }}
          />
        }
      />
      <StyledSettingTitle>Portfolio Summary Preference</StyledSettingTitle>
      <StyledDescription>
        Configure the token balance display in the portfolio summary of user
        profiles -{" "}
        <span
          className="link"
          onClick={() => {
            setOpenEffectPreview(true);
          }}
        >
          Effect Preview
        </span>
      </StyledDescription>
      <StyledSettingTitle>
        Select the data of token you want to display
      </StyledSettingTitle>
      <PortfolioTokens
        entityName={entityName}
        portfolioTokensData={portfolioTokensData}
        setPortfolioTokensData={setPortfolioTokensData}
      />
    </StyledGeneralSettingsContainer>
  );
};

export default PortfolioPreference;

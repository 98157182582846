import React, { useEffect, useState, useMemo } from "react";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { hot, setConfig } from "react-hot-loader";
import store from "./common/store";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import { arbitrum, mainnet, polygon, optimism, bsc } from "viem/chains";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { walletConnectProvider } from "@web3modal/wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { MaterialDesignContent } from "notistack";
import styled from "@emotion/styled/macro";
import { StylesProvider } from "@material-ui/core/styles";
import CustomThemeProvider from "features/home/CustomThemeProvider";
import ConfirmDialogProvider from "features/home/ConfirmDialogProvider";
import ZoomInDialogProvider from "features/home/ZoomInDialogProvider";
import { OpenTrialProvider } from "features/common/TrialDialog/OpenTrialProvider";
import { Notifier } from "features/common";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as Sentry from "@sentry/react";
import { IntlProvider } from "react-intl";
import { enMessages, zhTWMessages } from "locales";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import {
  WalletProvider,
  MartianWalletAdapter,
  AptosWalletAdapter,
  RiseWalletAdapter,
  PontemWalletAdapter,
} from "@manahippo/aptos-wallet-adapter";
import {
  ConnectionProvider,
  WalletProvider as SolanaWalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import RouteComponent from "./RouteComponent";
import "swiper/css/navigation";
import StateOpenContextProvider from "contexts/stepContext/Open.context";

const messages = {
  en: enMessages,
  "zh-TW": zhTWMessages,
};

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: "https://1d2b6d6d84ef4aa8944e97f83bcba111@o4504931933093888.ingest.sentry.io/4504972480741376",
  integrations: [
    Sentry.browserTracingIntegration({
      routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const wallets = [
  new PontemWalletAdapter(),
  new MartianWalletAdapter(),
  new RiseWalletAdapter(),
  new AptosWalletAdapter(),
];

const solanaWallets = [new SolflareWalletAdapter(), new PhantomWalletAdapter()];

const projectId = "e8f69f6a94a0dc82705e2e74954640c7"; //walletConnet project ID

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-error": {
    backgroundColor: "#fc5555",
  },
}));

const { publicClient, chains } = configureChains(
  [mainnet, polygon, optimism, arbitrum, bsc],
  [
    alchemyProvider({
      apiKey: "eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm",
    }),
    walletConnectProvider({ projectId }),

    publicProvider(),
  ]
);
const metadata = {
  name: "MetaCRM",
  description: "MetaCRM Login",
  url: "https://metacrm.inc",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const connectors = [
  new InjectedConnector({
    chains,
    options: {
      name: "Browser Wallet",
      shimDisconnect: true,
    },
  }),
  new MetaMaskConnector({
    chains,
    options: {
      shimDisconnect: true,
      UNSTABLE_shimOnConnectSelectAccount: true,
    },
  }),
  new WalletConnectConnector({
    chains,
    options: {
      projectId,
      metadata,
    },
  }),
];

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

setConfig({
  logLevel: "debug",
});

const renderMetadata = (
  title = "MetaCRM Admin Portal",
  desc = "Made with ♥️ in MetaVerse",
  url,
  imageURL
) => {
  if (!url) {
    url = window.location.href;
  }
  if (!imageURL) {
    imageURL = window.location.origin + require("assets/img/defaultBanner.png");
  }
  if (process.env.REACT_APP_ENTITY == "curve") {
    imageURL =
      window.location.origin + require("assets/img/curveBanner.png").default;
    title = "Curve Admin Portal";
    desc = "Powered by MetaCRM";
  } else if (process.env.REACT_APP_ENTITY == "radiant") {
    imageURL =
      window.location.origin + require("assets/img/radiantBanner.png").default;
    title = "Radiant Admin Portal";
    desc = "Powered by MetaCRM";
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="fragment" content="!" />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={desc} />
      <meta itemprop="image" content={imageURL} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageURL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="description" content={desc} />
    </Helmet>
  );
};

function Root() {
  const [locale, setLocale] = useState("en");
  // const metaDeskWallet = useMetaDeskWalletAdapter();

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // initializePriceCache()
  return (
    <Provider store={store}>
      {renderMetadata(
        "MetaCRM Admin Portal",
        "Made with ♥️ in MetaVerse",
        window.location.href,
        null
      )}
      <StylesProvider>
        <CustomThemeProvider>
          {/* react-intl 所定義好的語系檔都在安裝好的套件內，路徑為 node_modules/react-intl/locale-data/ */}
          <IntlProvider
            locale={locale}
            defaultLocale="en"
            messages={messages[locale]}
          >
            <SnackbarProvider
              Components={{
                error: StyledMaterialDesignContent,
              }}
              classes={{ containerRoot: "z-alert" }}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              iconVariant={{
                info: "",
              }}
            >
              <StateOpenContextProvider>
                <ConfirmDialogProvider>
                  <ZoomInDialogProvider>
                    <WagmiConfig config={wagmiConfig}>
                      <WalletProvider
                        wallets={wallets}
                        onError={(error) => {
                          console.log("Handle Aptos Error Message", error);
                        }}
                      >
                        <ConnectionProvider endpoint={endpoint}>
                          <SolanaWalletProvider
                            wallets={solanaWallets}
                            autoConnect={true}
                            onError={(error) => {
                              console.log("Handle Solana Error Message", error);
                            }}
                          >
                            <OpenTrialProvider>
                              {/* <RouterProvider router={router} /> */}
                              <RouteComponent></RouteComponent>
                              <Notifier />
                            </OpenTrialProvider>
                          </SolanaWalletProvider>
                        </ConnectionProvider>
                      </WalletProvider>
                    </WagmiConfig>
                  </ZoomInDialogProvider>
                </ConfirmDialogProvider>
              </StateOpenContextProvider>
            </SnackbarProvider>
          </IntlProvider>
        </CustomThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default hot(module)(Root);

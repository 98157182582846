import React, { useState, useEffect } from "react";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import { useFetchUserWalletInfo } from "features/metadesk/redux/fetchUserWalletInfo";
import CustomTabs from "components/CustomTabs/Tabs";
import { Loading } from "features/common";
import _ from "lodash";
import { IconButton } from "@material-ui/core";
import { useTheme, Grid } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { enqueueSnackbar } from "features/common/redux/actions";
import { apiUrl } from "features/configure";
import { StyledSearchAddressInfo } from "./SearchAddressInfo.styles";
import {
  StyledDIDAddressInfo,
  StyledDidTabContentContainer,
  StyledCustomTabsContainer,
} from "./DIDInfo.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { useDispatch } from "react-redux";
import { formatDateAndTime, getShortAddress } from "features/helpers/utils";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { useIntl } from "react-intl";

const DIDInfo = ({ selectedUserAddress, selectedUserId, entityName }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [hoverId, setHoverId] = useState();
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    fetchDIDInfo,
    DIDInfo,
    DIDCount,
    fetchDIDPending,
    userWalletInfos,
    fetchUserWalletInfo,
    totalDID,
  } = useFetchUserWalletInfo();
  const userWalletInfo = _.get(userWalletInfos, `[${selectedUserAddress}]`, []);
  const getTabChainId = () => {
    if (selectedTab == 1) return 56;
    if (selectedTab == 2) return 42161;
    return "";
  };
  const getIsFetching = () => {
    return _.get(DIDInfo, "didDataFetching");
  };
  useEffect(() => {
    let id;
    if (getIsFetching()) {
      id = setInterval(() => {
        fetchDIDInfo({
          entityName,
          address: selectedUserAddress,
          page,
          chainId: getTabChainId(),
        });
      }, 10000);
    }
    return () => {
      if (id) clearInterval(id);
    };
  }, [DIDInfo.didDataFetching]);

  useEffect(() => {
    if (selectedUserAddress) {
      fetchDIDInfo({
        entityName,
        address: selectedUserAddress,
        page,
        chainId: getTabChainId(),
      });
    }
  }, [page, selectedUserAddress, selectedTab]);
  const renderDIDRow = (did, i) => {
    const isHover = did._id == hoverId;
    return (
      <div
        key={i}
        style={{
          padding: 10,
          background: isHover ? "#F7F1FE" : "#FFFFFF",
          borderBottom: "1px solid #d6d6d6",
          height: 120,
          position: "relative",
        }}
        onMouseEnter={() => setHoverId(did._id)}
        onMouseLeave={() => setHoverId("")}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={4}>
            <img
              src={_.get(did, "metadata.image")}
              style={{ width: 100, height: 100, borderRadius: 10 }}
            />
          </Grid>
          <Grid item xs={8}>
            <div style={{ color: theme.customColors.grey[700] }}>
              {formatMessage({ id: "ticketDrawer.did.expiresOn" })} :{" "}
              {formatDateAndTime(_.get(did, "expireDate"))}
            </div>
            <div
              style={{ fontWeight: 700, fontSize: 18, marginTop: 8 }}
              className="startRow"
            >
              <img
                src={require(`assets/img/${
                  did.chainId == 42161 ? "arbSpaceId" : "bnbSpaceId"
                }.png`)}
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                  marginRight: 5,
                }}
              />
              {_.get(did, "metadata.name")}
            </div>
          </Grid>
        </Grid>
        {isHover && (
          <div style={{ position: "absolute", right: 10, bottom: 10 }}>
            <a
              href={
                "https://space.id/search?query=" + _.get(did, "metadata.name")
              }
              target="_blank"
              style={{ color: "#7B61FF", fontWeight: 500, fontSize: 14 }}
              className="endRow"
              rel="noreferrer"
            >
              <img
                src={require(`assets/img/spaceId.svg`).default}
                className="smIcon"
              />
              {formatMessage({ id: "tickerDrawer.did.moreOnSpaceID" })}{" "}
              <ArrowForwardIcon style={{ height: 18 }} />
            </a>
          </div>
        )}
      </div>
    );
  };
  const renderDIDInfo = () => {
    const didList = _.get(DIDInfo, "didList", []);
    if (didList.length == 0) {
      return (
        <div className="column" style={{ height: "40vh", color: "#A5A5A5" }}>
          <i className="meta-crm-icon-ic_boxOpenLight font-size-64" />
          <div>{formatMessage({ id: "tickerDrawer.did.notFound" })}</div>
        </div>
      );
    }
    return (
      <div style={{ margin: "10px 0", overflow: "auto" }}>
        {_.map(didList, (did, i) => {
          return renderDIDRow(did, i);
        })}
        <CustomPagination
          shape="rounded"
          PAGE_COUNT={20}
          count={DIDCount}
          page={page}
          setPage={setPage}
        />
      </div>
    );
  };

  const tabs = [
    {
      label: "All",
    },
    {
      label: "BNB",
    },
    {
      label: "ARB",
    },
  ];
  const didName = _.get(userWalletInfo, "didNames[0].name");
  const icon = getUserAvatarIcon(selectedUserId, theme, didName);
  return (
    <StyledSearchAddressInfo>
      <StyledDIDAddressInfo>
        <div style={{ height: 132 }}>
          <div className="betweenRow">
            <div
              className="startRow"
              style={{
                color: "#777777",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              <img
                src={require(`assets/img/${icon}`)}
                className="smIcon"
                style={{ marginRight: 10, borderRadius: 20 }}
              />
              <span style={{ fontSize: 14 }}>
                {getShortAddress(selectedUserAddress)}
              </span>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => {
                  navigator.clipboard.writeText(selectedUserAddress);
                }}
              >
                <img
                  src={require("assets/img/addressCopy.svg").default}
                  style={{ width: 18, marginLeft: 5 }}
                />
              </IconButton>
            </div>

            <span
              className="startRow"
              style={{
                borderRadius: 2,
                color: "#777777",
                padding: "2px 5px",
                borderRadius: 3,
              }}
            >
              {formatMessage({ id: 'global.header.lastUpdated' })}:
              {moment(userWalletInfo.updated).format("MM/DD HH:mm")}
              <IconButton
                style={{ padding: 0 }}
                onClick={async () => {
                  try {
                    await axios.post(apiUrl + "/api/user/refresh", {
                      address: selectedUserAddress,
                      entityName: entityName,
                    });
                    fetchDIDInfo({
                      entityName,
                      address: selectedUserAddress,
                      page,
                      chainId: getTabChainId(),
                    });
                  } catch (error) {
                    dispatch(
                      enqueueSnackbar({
                        message: _.get(error, "response.data.error", "Error"),
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: "error",
                        },
                      })
                    );
                  }
                }}
              >
                <img
                  src={require("assets/img/refresh.png")}
                  style={{ width: 18, marginLeft: 5 }}
                />
              </IconButton>
            </span>
          </div>
          <div style={{ textAlign: "right", paddingTop: 30 }}>
            <span
              style={{
                background: theme.customColors.purple[100],
                padding: 8,
                borderRadius: 5,
              }}
            >
              {formatMessage({ id: 'ticketDrawer.did.#ofDID' })}:{" "}
              <span style={{ fontWeight: 700, fontSize: 18 }}>{totalDID}</span>
            </span>
          </div>
        </div>
      </StyledDIDAddressInfo>
      <StyledDidTabContentContainer>
        <Loading open={fetchDIDPending || getIsFetching()} fullScreen={false} />
        <StyledCustomTabsContainer hidden={_.isEmpty(selectedUserAddress)}>
          <CustomTabs
            borderBottom
            sm="true"
            tabIndex={selectedTab}
            handleChange={(e, newValue) => {
              setPage(1);
              setSelectedTab(newValue);
            }}
            tabs={tabs}
          />
          {renderDIDInfo()}
        </StyledCustomTabsContainer>
      </StyledDidTabContentContainer>
    </StyledSearchAddressInfo>
  );
};

export default DIDInfo;

import React, { useContext, useEffect, useRef, useState } from "react";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import DateRangeSelect from "components/DateRange/DateRangeSelect";
import { Box } from "@mui/material";
import CustomToggle, {
  StyledToggleButton,
} from "components/CustomToggle/CustomToggle";
import { useSelector } from "react-redux";
import { selectDemoSite } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import moment from "moment";

export default function PerformanceSearch() {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setSearch,
    search,
    isAdvanced,
  } = useContext(PerformanceContext);

  const { formatMessage } = useIntl();
  const [input, setInput] = useState("");
  const [range, setRange] = useState("all");
  const handleChange = (e) => {
    setSearch(input);
  };
  useEffect(() => {
    setInput(search);
  }, [search]);

  useEffect(() => {
    if (range == "custom") return;
    if (range == "all") {
      setStartDate("");
      setEndDate("");
      return;
    }
    const now = moment().endOf("day").toDate();
    setStartDate(
      moment().startOf("day").subtract(parseInt(range), "days").toDate()
    );
    setEndDate(now);
  }, [range]);

  return (
    <Box
      display={"flex"}
      sx={{
        backgroundColor: "white",
        padding: "8px 16px",
      }}
    >
      <Box sx={{ flex: "1", maxWidth: "500px" }}>
        <InputField
          sx={{
            width: "100%",
          }}
          placeholder={
            isAdvanced
              ? formatMessage({
                  id: "campaignPerformance.trackerTab.advancedSearchBar.placeHolder",
                })
              : formatMessage({
                  id: "campaignPerformance.trackerTab.normalSearchBar.placeHolder",
                })
          }
          onChange={(e) => setInput(e.target.value)}
          onBlur={handleChange}
          onEnter={handleChange}
          onClean={() => setSearch("")}
          name="test"
          value={input}
          type={"search"}
          size={SIZE.MEDIUM}
        />
      </Box>

      <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
        <CustomToggle
          data={[
            { text: "1 Day", value: 1 },
            { text: "7 Days", value: 7 },
            { text: "30 Days", value: 30 },
            { text: "All", value: "all" },
          ]}
          value={range}
          onChange={(e, newValue) => {
            if (!newValue) return;
            setRange(newValue);
          }}
        />
        <DateRangeSelect
          value={[
            {
              startDate,
              endDate,
              key: "selection",
            },
          ]}
          custom={
            <StyledToggleButton
              className={range == "custom" ? "Mui-selected" : ""}
            >
              Custom
            </StyledToggleButton>
          }
          onChange={(value) => {
            setRange("custom");
            setStartDate(value[0]?.startDate || undefined);
            setEndDate(value[0]?.endDate || undefined);
          }}
        />
      </Box>
    </Box>
  );
}

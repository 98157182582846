import React, { useState, useEffect, useRef } from "react";
import { useOutletContext, Navigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import Back from "features/metadesk/components/Back";
import CampaignStep1 from "./CampaignStep1";
import CampaignStep2 from "./CampaignStep2";
import CampaignStep3 from "./CampaignStep3";
import { CampaignCreationProvider } from "./context/CampaignCreationProvider";
import BottomFixedButton from "./BottomFixedButton";
import SelectCampaignModal from "./component/SelectCampaignModal";
import CampaignSideMenu from "features/metadesk/campaign/createCampaign/component/CampaignSideMenu";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";

import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { TrackingOptionsProvider } from "./component/SetOptions/OptionsProvider";

const CreateCampaign = () => {
  const { fetchEntityConfig } = useFetchEntityConfig();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const [entityName, isCustomDomain] = useOutletContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const initialTab = parseInt(urlSearchParams.get("tab")) || 1;
  const [step, setStep] = useState(initialTab || 1);
  const [isOpen, setIsOpen] = useState(false);
  // 進來時確保狀態都是最新的
  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, []);

  return (
    <CampaignCreationProvider>
      <TrackingOptionsProvider>
        <Box display="flex">
          <CampaignSideMenu />
          <Box
            width={"100%"}
            margin="0 auto"
            paddingLeft={computer ? "200px" : "0"}
          >
            <Back></Back>
            {step === 1 && <CampaignStep1 step={step} />}
            {step === 2 && (
              <CampaignStep2
                step={step}
                setStep={setStep}
                setIsOpen={setIsOpen}
              />
            )}
            {step === 3 && <CampaignStep3 step={step} />}
            <BottomFixedButton
              step={step}
              setStep={setStep}
              setIsOpen={setIsOpen}
            />
            <SelectCampaignModal
              step={step}
              setStep={setStep}
              open={isOpen}
              onClose={() => setIsOpen(false)}
            ></SelectCampaignModal>
          </Box>
        </Box>
      </TrackingOptionsProvider>
    </CampaignCreationProvider>
  );
};

export default CreateCampaign;

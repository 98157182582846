import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";

import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";

import { StyledTicketTypeRow } from "../../WidgetSetting/WidgetTicketType.styles";
import { StyledTicketTypeName } from "../InterfaceTheme/InterfaceTheme.styles";
import DiscordTicketSettingItem from "../DiscordTicketSettingItem/DiscordTicketSettingItem";
import Member from "./Member";
import AssignmentModal from "./AssigmentModal";
import { useFetchTeamMember } from "features/metadesk/redux/fetchTeamMember";

export default function AutoAssignTicketType({
  serviceName, // discord, widget, supportCenter
  canEdit,
  ticketList,
  autoAssign,
  chooseTicketType,
  setChooseTicketType,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { memberByTicketList: members } = useFetchTeamMember();
  const [assignment, setAssignment] = useState();

  const isDiscordService = serviceName === "discord";

  const selectedTickets = useMemo(
    () =>
      (chooseTicketType || []).reduce((acc, tt) => {
        return {
          ...acc,
          [tt._id]: {
            ticketType: ticketList?.find(
              (_ticketType) => _ticketType._id === tt._id
            ),
            assignee: members?.find((_m) => _m._id === tt.assignee),
          },
        };
      }, {}),
    [chooseTicketType, ticketList, members]
  );

  const isSelectedTicketType = (_id) => {
    return _.some(chooseTicketType, (v) => v._id === _id);
  };

  const handleChangeDiscordTicketColor = ({ newColor, ticketId }) => {
    const newChooseTicketType = _.map(chooseTicketType, (v) => {
      if (v._id === ticketId && newColor !== null) {
        return { ...v, discordButtonStyle: newColor };
      }
      return v;
    });
    setChooseTicketType(newChooseTicketType);
  };

  const handleChangeDiscordRequiresAddress = ({ event, ticketId }) => {
    const newChooseTicketType = _.map(chooseTicketType, (v) => {
      if (v._id === ticketId) {
        return { ...v, addressRequired: event.target.checked };
      }
      return v;
    });
    setChooseTicketType(newChooseTicketType);
  };

  const renderDiscordSettingItems = (tt) => {
    return (
      <DiscordTicketSettingItem
        ticketList={tt}
        chooseTicketType={chooseTicketType}
        key={tt._id}
        onChangeTicketColor={handleChangeDiscordTicketColor}
        onChangeRequiresAddress={handleChangeDiscordRequiresAddress}
      />
    );
  };

  const handleSelectCheckBox = (e) => {
    const { value, checked } = e.target;
    let tkt = { ..._.find(ticketList, (u) => u._id === value) };
    if (isDiscordService) {
      tkt.assignee = _.get(tkt.assignee, "_id", null);
    } else {
      tkt = {
        _id: tkt._id,
        assignee: _.get(tkt.assignee, "assignee", null),
      };
    }
    if (checked) {
      setChooseTicketType([...chooseTicketType, { ...tkt }]);
    } else {
      setChooseTicketType(_.filter(chooseTicketType, (o) => o._id !== value));
    }
  };
  return (
    <>
      {ticketList.map((tt) => {
        return (
          <StyledTicketTypeRow
            selected={isSelectedTicketType(tt._id)}
            key={tt._id}>
            {canEdit ? (
              <CustomCheckbox
                value={tt._id}
                style={{ marginRight: 12 }}
                checked={isSelectedTicketType(tt._id)}
                onChange={handleSelectCheckBox}
              />
            ) : null}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                ...(!isSelectedTicketType(tt._id) && {
                  opacity: 0.5,
                  pointerEvents: "none",
                  cursor: "default",
                }),
              }}>
              <div style={{ flex: 0.3 }}>
                <StyledTicketTypeName style={{ width: "fit-content" }}>
                  {tt.name}
                </StyledTicketTypeName>
              </div>
              {isDiscordService && renderDiscordSettingItems(tt)}
              <Box
                sx={isDiscordService ? { flex: 0.3, textAlign: "right" } : {}}
                visibility={autoAssign ? "visible" : "hidden"}>
                <Button
                  style={{
                    padding: 0,
                    paddingRight: 8,
                  }}
                  disabled={!canEdit}
                  variant={VARIANT.TEXT}
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkt = _.find(
                      chooseTicketType,
                      (o) => o._id === tt._id
                    );

                    if (!autoAssign || _.isEmpty(tkt)) return;

                    setAssignment({
                      ticketType: tt,
                      assignee: tkt?.assignee || null,
                    });
                  }}>
                  {selectedTickets[tt._id]?.assignee ? (
                    <div
                      style={{
                        color: theme.customColors.grey[600],
                        display: "flex",
                        fontSize: theme.fontSize.TINY,
                      }}>
                      <div className="mgr4">
                        {formatMessage({
                          id: "ticketCustomization.suggestionType.subtitle",
                        })}
                        :
                      </div>
                      <Member member={selectedTickets[tt._id].assignee} />
                    </div>
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        color: theme.customColors.purple[500],
                        opacity: isSelectedTicketType(tt._id) ? 1 : 0.5,
                        fontSize: theme.fontSize.TINY,
                      }}>
                      <img
                        className="mgr4"
                        src={require("assets/img/ic_assignment.svg").default}
                        alt=""
                      />
                      Assignment
                    </Box>
                  )}
                </Button>
              </Box>
            </div>
          </StyledTicketTypeRow>
        );
      })}

      <AssignmentModal
        members={members}
        assignment={assignment}
        setAssignment={setAssignment}
        onClose={() => setAssignment(undefined)}
        onConfirm={() =>
          setChooseTicketType((prv) => {
            const idx = prv.findIndex(
              (v) => v._id === assignment?.ticketType._id
            );

            if (idx === -1) return prv;

            const updatedItem = {
              ...prv[idx],
              assignee: assignment.assignee,
            };

            const _prv = [...prv];
            _prv.splice(idx, 1, updatedItem);
            return _prv;
          })
        }
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import _ from "lodash";

import UserInfoContainer from "./UserInfoContainer";
import { useFetchUserWalletInfo } from "./redux/fetchUserWalletInfo";

const UserInfoRoot = ({
  selectedUserId,
  selectedUserAddress,
  entityName,
  ticketListData,
  isSearch = false,
}) => {
  const {
    fetchUserWalletInfo,
    refreshWalletInfo,
    userWalletInfos: generalUserWalletInfos,
    fetchUserWalletInfoPending: generalFetchUserWalletInfoPending,
    searchUserWalletInfos,
    fetchSearchUserWalletInfoPending,
  } = useFetchUserWalletInfo();

  const userWalletInfos = isSearch
    ? searchUserWalletInfos
    : generalUserWalletInfos;

  const fetchUserWalletInfoPending = isSearch
    ? fetchSearchUserWalletInfoPending
    : generalFetchUserWalletInfoPending;

  const userWalletInfo =
    _.get(userWalletInfos, `[${selectedUserAddress}]`, {}) || {};

  useEffect(() => {
    if (selectedUserAddress) {
      fetchUserWalletInfo({
        userAddress: selectedUserAddress,
        entityName,
        isSearch,
      });
    }
  }, [selectedUserAddress]);

  const getIsFetching = () => {
    return (
      _.some(userWalletInfo.updatedList, { onChainDataFetching: true }) ||
      _.some(userWalletInfo.updatedList, { protocolDataFetching: true })
    );
  };
  useEffect(() => {
    if (getIsFetching()) {
      const id = setInterval(() => {
        fetchUserWalletInfo({
          userAddress: selectedUserAddress,
          entityName,
          isSearch,
        });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [userWalletInfo.updatedList]);

  const fetchUserObjs = () => ({
    fetchUserWalletInfo,
    refreshWalletInfo,
    userWalletInfos,
    fetchUserWalletInfoPending,
    isSearch,
  });

  return (
    <UserInfoContainer
      ticketListData={ticketListData}
      selectedUserId={selectedUserId}
      selectedUserAddress={selectedUserAddress}
      entityName={entityName}
      isFetching={getIsFetching()}
      fetchUserObjs={fetchUserObjs}
    />
  );
};

export default UserInfoRoot;

import React, { memo, useContext, useState } from "react";
import _ from "lodash";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PureModal from "../../campaignList/component/PureModal";
import { CampaignCreationContext } from "../context/CampaignCreationProvider";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Button from "@metacrm/metacrm-material-ui/dist/Button";
import moment from "moment";
import useCampaignSave from "../hook/useCampaignSave";
import {
  countCustomTrackingLink,
  countEngagementLink,
  countEngagementReach,
} from "../utils";
import { lowerCase } from "lodash";

const ConfirmPublishModal = ({ open, onClose }) => {
  const { formatMessage } = useIntl();
  const { stepData } = useContext(CampaignCreationContext);
  const { name, startFrom, campaignContentSets, objectives } = stepData;
  const { save, isLoading } = useCampaignSave();

  const type = _.get(campaignContentSets, `${0}.type`);
  const goal = _.get(campaignContentSets, `${0}.goal`);

  const objectiveName = {
    tokenIn: "Token Transfer In",
    tokenOut: "Token Transfer Out",
    mintNFT: "Mint NFT",
    contract: "Contract Interaction",
  };

  const TypeMapping = {
    engagement: formatMessage({
      id: "popup.selectPromotoType.engagement.title",
    }),
    custom: formatMessage({
      id: "popup.selectPromotoType.custom.title",
    }),
  };
  return (
    <PureModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      padding={"40px"}
      maxWidth="1000px">
      <Box sx={{ width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "8px", fontWeight: 700, fontSize: "18px" }}>
            {formatMessage({ id: "popup.confirmToPublish.title" })}
          </p>
          <p style={{ marginBottom: "24px", color: "#5C5C5C" }}>
            {formatMessage({ id: "popup.confirmToPublish.description" })}
          </p>
        </div>
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid #E6E6E6",
          }}>
          <Box
            style={{
              padding: "12px 23px 18px",
              borderBottom: "1px solid #E6E6E6",
              background: "#F8F8F8",
            }}>
            <p style={{ color: "#555555", fontSize: "12px" }}>
              {formatMessage({ id: "campaignBuild.info.campaignName" })}
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: 700, width: "100%" }}
              className="wordBreak">
              {name}
            </p>
          </Box>
          <Box sx={{ padding: "12px 23px 18px" }}>
            {(goal === "onchain" || type === "tracker") && (
              <>
                {objectives.map((data, index) => (
                  <Grid container key={index}>
                    <Grid item xs={6}>
                      <Box sx={{ textAlign: "left" }}>
                        <p style={{ color: "#555555", fontSize: "12px" }}>
                          {formatMessage({ id: "global.commonWord.objective" })}
                        </p>
                        <p style={{ fontSize: "16px", color: "#000" }}>
                          {objectiveName[data.type]}
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ textAlign: "left" }}>
                        <p style={{ color: "#555555", fontSize: "12px" }}>
                          {formatMessage({ id: "global.contract.address" })}
                        </p>
                        <p
                          style={{ fontSize: "16px", color: "#000" }}
                          className="wordBreak">
                          {data.smartContractAddress}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
                <div className="mhr" />
              </>
            )}
            {campaignContentSets.map((data, index) => {
              let trackingLink = 0;
              let estimated = 0;
              if (data.type === "tracker") {
                trackingLink = countCustomTrackingLink(data.customLinkSettings);
              }
              if (data.type === "flyer" || data.type === "widget") {
                const { engagementDistributionSettings } = data;
                trackingLink = countEngagementLink(
                  engagementDistributionSettings
                );

                estimated = countEngagementReach(
                  engagementDistributionSettings
                );
              }
              const channels = data?.engagementDistributionSettings?.map(
                (item) =>
                  formatMessage({ id: `integration.${item.channel}.name` })
              );
              const title =
                data.type === "tracker"
                  ? "Attribution Links"
                  : channels.join(",");
              return (
                <Grid
                  container
                  key={data.type + index}
                  style={{
                    margin:
                      index !== campaignContentSets.length - 1
                        ? "0 0 32px"
                        : "0",
                  }}>
                  <Grid item xs={6}>
                    <Box sx={{ textAlign: "left" }}>
                      <p style={{ color: "#555555", fontSize: "12px" }}>
                        {formatMessage({ id: "global.commonWord.promo" })}{" "}
                        {"Type"}: {TypeMapping[data.type]}
                      </p>
                      <p style={{ fontSize: "16px", color: "#000" }}>{title}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ textAlign: "left" }}>
                      <p style={{ color: "#555555", fontSize: "12px" }}>
                        {formatMessage({ id: "campaign.attributes.subtotal" })}
                      </p>
                      <p style={{ fontSize: "16px", color: "#000" }}>
                        {trackingLink ? (
                          <>
                            <span style={{ color: "#7B61FF", fontWeight: 700 }}>
                              {trackingLink}
                            </span>{" "}
                            {formatMessage({ id: "campaign.commonWord.links" })}
                          </>
                        ) : null}
                        {trackingLink && estimated ? "," : null}
                        {estimated ? (
                          <>
                            <span style={{ color: "#7B61FF", fontWeight: 700 }}>
                              {estimated}
                            </span>{" "}
                            {lowerCase(
                              formatMessage({
                                id: "campaign.type.estimatedReach",
                              })
                            )}
                          </>
                        ) : null}
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Box>

        <p
          style={{
            textAlign: "center",
            color: "#7B61FF",
            margin: "8px 0 32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <i className="meta-crm-icon-ic_info font-size-16" />
          {formatMessage({
            id: "popup.confirmToPublish.hint.trackDeadline",
          })}{" "}
          {startFrom
            ? moment(startFrom).add(7, "days").format("ll")
            : moment().add(7, "days").format("ll")}
          .
        </p>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button variant="text" onClick={onClose} style={{ color: "black" }}>
            {formatMessage({ id: "global.btn.cancel" })}
          </Button>
          <Button onClick={() => save(false)} disabled={isLoading}>
            {formatMessage({ id: "global.btn.publish" })}
          </Button>
        </Box>
      </Box>
    </PureModal>
  );
};

export default memo(ConfirmPublishModal);

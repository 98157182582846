import React, { useState, useEffect, useMemo } from "react";
import { get, isEmpty } from "lodash-es";
import { useOutletContext } from "react-router-dom";
import { Grid, Modal } from "@mui/material";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import { useForm, Controller, useFormState } from "react-hook-form";
import {
  Button,
  IconButton,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import axios from "axios";

import {
  selectMarketingModule,
  selectEntityId,
  selectTrackingObject,
  selectAttributionVersionCanUpdate,
} from "features/metadesk/redux/entityConfig/entityConfig.selector";
import {
  getBaseUrl,
  formatCalendarDateAndTime,
  validateURL,
  parseCustomDomainUrl,
} from "features/helpers/utils";
import { apiUrl } from "features/configure";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import SDKSettingSteps from "./tracking/SDKSettingSteps";
import {
  StyledHoverShowIcon,
  StyledFormTitle,
  StyledApiKeyCreatedTime,
  StyledPreviewImageContainer,
  StyledGreyBlock,
} from "./IntegrationWidgetSetting.styles";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useIntl } from "react-intl";
import { useZoomIn } from "features/home/ZoomInDialogProvider";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import BasicDialog from "@metacrm/metacrm-material-ui/dist/Dialog";
import TrackingDocument from "./tracking/TrackingDocument";
import ReferralAPI from "./tracking/ReferralAPI";
import IntegrationSettings from "./IntegrationSettings";
import useMarketModule from "hooks/useMarketModule";
import CodePannel from "components/CodePannel/CodePannel";
import { generateTrackingScript } from "features/configure/appCode";
import FixedButton from "../common/FixedButton";
import SriCodeTab from "features/metadesk/appUpdate/SriCodeTab";

const formDataInit = {
  domainName: "",
};

const IntegrationAttributionLink = ({
  isTrial,
  controlByParent,
  formDataByParent,
  setFormDataByParent,
  formStateByParent,
  buttons,
}) => {
  const { formatMessage } = useIntl();
  const [options, setOptions] = useState(null);
  const [entityName, isCustomDomain] = useOutletContext();
  const [formDataSelf, setFormDataSelf] = useState(formDataInit);
  const formData = useMemo(
    () => formDataByParent || formDataSelf,
    [formDataByParent, formDataSelf]
  );

  const setFormData = setFormDataByParent || setFormDataSelf;
  const [selectedTab, setSelectedTab] = useState(0);
  const marketingModule = useSelector(selectMarketingModule);
  const {
    attributionConnect,
    attributionConnecting,
    referralEnabled,
    attributionUrl,
  } = useMarketModule();
  const ecosystemName = useSelector(selectEcosystemName);
  const entityId = useSelector(selectEntityId);
  const [integrityInfo, setIntegrityInfo] = useState({});
  const [SRIEnable, setSRIEnable] = useState(true);
  const trackingObject = useSelector(selectTrackingObject);
  const { apiKey, created: apiKeyCreated } = trackingObject;
  const { fetchEntityConfig } = useFetchEntityConfig();
  const confirm = useConfirm();
  let [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const zoomIn = useZoomIn();

  const { latestVersion, canUpdate } = useSelector(
    selectAttributionVersionCanUpdate
  );

  // 'Undetected' 'Waiting' 'Enabled' 'Failed'
  const attributionIntegrationStatus = attributionConnect
    ? "Enabled"
    : attributionConnecting
    ? "Waiting"
    : "Undetected";

  const {
    handleSubmit,
    control: selfControl,
    formState: selfFormState,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });
  const formState = formStateByParent || selfFormState;
  const { errors: errorsInfo, isValid } = formState;
  const control = controlByParent ? controlByParent : selfControl;
  const { isDirty } = useFormState({ control });

  const generateScript = ({ integrityInfo }) => {
    return generateTrackingScript({
      integrityInfo,
      entityId,
      apiKey,
      ecosystemName,
    });
  };

  useEffect(() => {
    const domainName = get(marketingModule, "attribution.url");
    setFormData({
      ...formData,
      domainName: domainName?.replace("https://", ""),
    });
    reset({ ...formData, domainName: domainName?.replace("https://", "") });
  }, [attributionUrl]);

  const fetchIntegrity = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/integrity/tracking`);
      setIntegrityInfo(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchIntegrity();
  }, []);

  const trackingAddressCode = `import { useAccount } from "wagmi";
// if your app is using wagmi, you can use useAccount hook
// your app might not use wagmi, and you might need some other way to grab wallet address

// ...
const Component = () => {
  const { address } = useAccount();

  useEffect(() => {
    if (window?.MetaCRMTracking?.manualConnectWallet) {
      window.MetaCRMTracking.manualConnectWallet(address);
    }
  }, [address]);
  // ...
};
  `;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const renderAttributionIntegrationStatus = () => {
    if (attributionIntegrationStatus === "Undetected") {
      return (
        <StatusLabel color={COLOR.INFO}>
          {formatMessage({
            id: "global.status.undetected",
          })}
        </StatusLabel>
      );
    }
    if (attributionIntegrationStatus === "Waiting") {
      return (
        <StatusLabel color={COLOR.WARNING}>
          {formatMessage({
            id: "global.status.waiting",
          })}
        </StatusLabel>
      );
    }
    if (attributionIntegrationStatus === "Enabled") {
      return (
        <StatusLabel color={COLOR.SECONDARY}>
          {formatMessage({
            id: "global.status.enabled",
          })}
        </StatusLabel>
      );
    }
    if (attributionIntegrationStatus === "Failed") {
      return (
        <StatusLabel color={COLOR.ERROR}>
          {formatMessage({
            id: "global.status.failed",
          })}
        </StatusLabel>
      );
    }
  };

  const handleClose = (e) => {
    setOptions(null);
  };

  const handleCheckSuccess = () => {
    setOptions({
      title: (
        <Box textAlign={"center"}>
          {formatMessage({
            id: "marketingSettings.headCode.statusDetected",
          })}
        </Box>
      ),
      confirmText: "OK",
      handleConfirm: () => {
        handleClose();
      },
    });
  };

  const handleCheckWaiting = () => {
    setOptions({
      title: (
        <Box textAlign={"center"}>
          {" "}
          {formatMessage({
            id: "popup.statusReturn.title",
          })}
        </Box>
      ),
      content: (
        <Box textAlign={"center"}>
          {formatMessage({
            id: "marketingSettings.headCode.statusWaiting",
          })}
        </Box>
      ),
      confirmText: "OK",
      handleConfirm: () => {
        handleClose();
      },
    });
  };

  const handleCheckInstallAttribution = async () => {
    try {
      const { metadesk } = await fetchEntityConfig({ entityName });
      const isAttributionConnected =
        metadesk?.entityConfig?.marketingModule?.attribution?.connected;
      if (isAttributionConnected) {
        handleCheckSuccess();
      } else {
        handleCheckWaiting();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleRegenerateAPI = async () => {
    try {
      await axios.post(`${apiUrl}/api/manage/apiKey/${entityName}/tracking`);
      fetchEntityConfig({ entityName });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  // 要是沒有apiKey 並且以前都沒有安裝過 進來直接產生一個apiKey
  useEffect(() => {
    if (!apiKey && !attributionConnect && !attributionConnecting) {
      handleRegenerateAPI();
    }
  }, [apiKey, attributionConnect, attributionConnecting]);

  const handleRegenerate = () => {
    confirm({
      title: formatMessage({
        id: "popup.regenerateApiKey.title",
      }),
      content: formatMessage({
        id: "popup.regenerateApiKey.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        handleRegenerateAPI();
      })
      .catch(() => {});
  };

  const sendInfo = async (formData) => {
    try {
      await axios.post(`${apiUrl}/api/manage/attributionConfig/${entityName}`, {
        url: `https://${formData.domainName}`,
      });
      dispatch(
        enqueueSnackbar({
          message: "Domain set Success",
          options: {
            variant: "success",
          },
        })
      );

      if (from) {
        navigate(parseCustomDomainUrl(isCustomDomain, entityName, `/${from}`));
      } else {
        navigate(
          parseCustomDomainUrl(
            isCustomDomain,
            entityName,
            "/settings/general/integration"
          )
        );
      }

      fetchEntityConfig({ entityName });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    }
  };

  const handleSave = () => {
    handleSubmit(sendInfo)();
  };

  const SDKsteps = [
    {
      title: formatMessage({
        id: "widgetSettings.apiKey.title",
      }),
      describe: formatMessage({
        id: "marketingSettings.apiKey.description",
      }),
      content: (
        <Grid item xs={12}>
          <Grid container spacing={1} id={"guide1_2"}>
            <StyledFormTitle center item xs={12} md={4}>
              {formatMessage({
                id: "widgetSettings.apiKey.input",
              })}
            </StyledFormTitle>
            <Grid position={"relative"} item xs={12} md={6}>
              <InputField
                multiline={false}
                rows={1}
                disabled
                value={apiKey}
                endAdornment={
                  <IconButton onClick={() => copyToClipboard(apiKey)}>
                    <i className="meta-crm-icon-ic_copy font-size-24" />
                  </IconButton>
                }
              ></InputField>
              <StyledApiKeyCreatedTime>
                <Box
                  color={(theme) => theme.customColors.grey[600]}
                  size="12px"
                  mr="2.5px"
                >
                  {formatMessage({
                    id: "plans&billing.usage.recountTime",
                  })}{" "}
                  :
                </Box>
                <Box
                  color={(theme) => theme.customColors.purple[500]}
                  size="12px"
                >
                  {formatCalendarDateAndTime(apiKeyCreated)}
                </Box>
              </StyledApiKeyCreatedTime>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                size={SIZE.XL}
                sx={{ width: "100%" }}
                onClick={handleRegenerate}
              >
                {formatMessage({
                  id: "global.btn.regenerate",
                })}
              </Button>
            </Grid>
            <Grid item xs={12} mb={"16px"}></Grid>
          </Grid>{" "}
        </Grid>
      ),
    },
    {
      stepNumber: 1,
      title: formatMessage({
        id: "trackingSettings.domain.title",
      }),
      describe: formatMessage({
        id: "trackingSettings.domain.description",
      }),

      content: (
        <Grid item xs={12}>
          <Grid container spacing={1} id={"guide1_3"}>
            <StyledFormTitle center item xs={12} md={4}>
              {formatMessage({
                id: "widgetSettings.domain.input",
              })}
            </StyledFormTitle>
            <Grid position={"relative"} item xs={12} md={8}>
              <InputField
                placeholder={formatMessage({
                  id: "widgetSettings.domain.placeholder",
                })}
                onChange={handleFieldChange}
                name="domainName"
                control={control}
                errors={get(errorsInfo, "domainName.message")}
                value={formData.domainName}
                prefixWord={"https://"}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id: "global.inputCheck.empty",
                    }),
                  },
                  validate: (value) =>
                    validateURL(value) ||
                    formatMessage({
                      id: "global.inputCheck.invalidUrl",
                    }),
                }}
              ></InputField>
            </Grid>
          </Grid>{" "}
        </Grid>
      ),
    },
    {
      stepNumber: 2,
      title: formatMessage({
        id: "trackingSettings.tagInstall.title",
      }),
      status: renderAttributionIntegrationStatus,

      titleButton: (
        <Button
          variant={VARIANT.OUTLINED}
          sx={{ marginLeft: "auto" }}
          disabled={attributionIntegrationStatus === "Enabled"}
          onClick={handleCheckInstallAttribution}
        >
          {formatMessage({
            id: "global.btn.checkConnection",
          })}
        </Button>
      ),
      describe: formatMessage({
        id: "trackingSettings.tagInstall.description",
      }),

      content: (
        <Grid item xs={12}>
          <Grid container spacing={1} id={"guide1_4"}>
            <StyledFormTitle item xs={12} md={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                height={"24px"}
                color={canUpdate ? "#FF9D2A" : null}
              >
                {formatMessage({
                  id: "snippet.widget.integration",
                })}
                {canUpdate && (
                  <Box
                    bgcolor="#FF9D2A"
                    p="2px 6px"
                    fontSize="12px"
                    borderRadius="10px"
                    color="white"
                    ml="8px"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        parseCustomDomainUrl(
                          isCustomDomain,
                          entityName,
                          "/settings/general/app/update-list?updateType=tracking"
                        )
                      )
                    }
                  >
                    {formatMessage({ id: "global.btn.goToUpdate" })}
                    {": " + latestVersion}
                  </Box>
                )}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {/* <Box>
                  <CustomSwitch
                    checked={SRIEnable}
                    onClick={() => setSRIEnable(!SRIEnable)}
                  />
                </Box> */}
              </Box>
            </StyledFormTitle>
            <Grid position={"relative"} item xs={12} md={8} mb={2}>
              {!isEmpty(integrityInfo) && apiKey && (
                <SriCodeTab
                  sriCode={generateScript({ integrityInfo })}
                  nonSriCode={generateScript({})}
                />
              )}

              {!apiKey && (
                <Box position={"relative"}>
                  <Box
                    height="100%"
                    width="100%"
                    backgroundColor={(theme) => theme.customColors.grey[300]}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    color={(theme) => theme.customColors.purple[500]}
                    fontSize={"24px"}
                    fontWeight={"700"}
                    position={"absolute"}
                    zIndex={2}
                    sx={{ opacity: 0.8 }}
                  >
                    {formatMessage({
                      id: "widgetSettings.apiKey.generate.hint",
                    })}
                  </Box>
                  <InputField multiline={true} rows={9} disabled />
                </Box>
              )}
              <StyledGreyBlock mt={"12px"}>
                <Box marginRight="5px">
                  <i className="meta-crm-icon-ic_info font-size-16" />
                </Box>

                <Box component="span">
                  {formatMessage({
                    id: "widgetSettings.headCode.instruction",
                  })}
                </Box>
              </StyledGreyBlock>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="description">
                {formatMessage({
                  id: "marketingSettings.headCode.preview",
                })}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box style={{ textAlign: "center" }}>
                {SRIEnable ? (
                  <StyledPreviewImageContainer
                    onClick={() =>
                      zoomIn({
                        src: require("assets/img/attribution_tutorial.png"),
                      }).catch(() => {})
                    }
                  >
                    <Box
                      component="img"
                      src={require("assets/img/attribution_tutorial.png")}
                      sx={{ width: "100%" }}
                    />
                    <StyledHoverShowIcon>
                      <i className="meta-crm-icon-ic_bigger font-size-24" />
                    </StyledHoverShowIcon>
                  </StyledPreviewImageContainer>
                ) : (
                  <StyledPreviewImageContainer
                    onClick={() =>
                      zoomIn({
                        src: require("assets/img/attribution_tutorial_SRI_false.png"),
                      }).catch(() => {})
                    }
                  >
                    <Box
                      component="img"
                      src={require("assets/img/attribution_tutorial_SRI_false.png")}
                      sx={{ width: "100%" }}
                    />
                    <StyledHoverShowIcon>
                      <i className="meta-crm-icon-ic_bigger font-size-24" />
                    </StyledHoverShowIcon>
                  </StyledPreviewImageContainer>
                )}
              </Box>
            </Grid>{" "}
          </Grid>
        </Grid>
      ),
    },
    {
      stepNumber: 3,
      title: formatMessage({
        id: "trackingSettings.enhancedWallet.title",
      }),

      titleHint: `(${formatMessage({
        id: "global.annotation.recommended",
      })})`,

      describe: (
        <>
          {ecosystemName === "Solana"
            ? formatMessage({
                id: "walletConnects.snippet.description",
              })
            : formatMessage({
                id: "trackingSettings.enhancedWallet.description",
              })}

          <Box
            color={(theme) => theme.customColors.purple[500]}
            sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
          >
            <Box mr="4px" className="meta-crm-icon-ic_info font-size-16" />
            {formatMessage({
              id: "widgetSettings.addressIdentification.explanation",
            })}
          </Box>
        </>
      ),

      content: (
        <Grid item xs={12}>
          <Grid container spacing={1} id={"guide1_5"}>
            <StyledFormTitle item xs={12} md={4}>
              <Box display={"flex"} alignItems={"center"} height={"24px"}>
                {formatMessage({
                  id: "snippet.widget.addressIdentification",
                })}
              </Box>
              <Box className="description">
                {ecosystemName === "Solana" ? (
                  <Box>
                    To ensure that you can track{" "}
                    <Box
                      sx={{ display: "inline" }}
                      color={(theme) => theme.customColors.purple[500]}
                    >
                      Solflare wallet,
                    </Box>{" "}
                    you must install this method.
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </StyledFormTitle>
            <Grid position={"relative"} item xs={12} md={8}>
              {!isEmpty(integrityInfo) && (
                <CodePannel code={trackingAddressCode} />
              )}
              <StyledGreyBlock mt={"12px"}>
                <Box marginRight="5px">
                  <i className="meta-crm-icon-ic_info font-size-16" />
                </Box>

                <Box component="span">
                  {formatMessage({
                    id: "metaCRMSDK.walletConnects.addressIdentification.instruction",
                  })}
                </Box>
              </StyledGreyBlock>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  ];

  const tabs = [
    {
      label: formatMessage({
        id: "tab.content.introduction.title",
      }),
      content: (
        <TrackingDocument
          setSelectedTab={setSelectedTab}
          referralEnabled={referralEnabled}
        />
      ),
      _id: 0,
    },
    {
      label: formatMessage({
        id: "tab.content.embedTrackingTag.title",
      }),
      content: (
        <SDKSettingSteps
          SDKsteps={SDKsteps}
          isIsolateComponent={isTrial}
          extra={
            <FixedButton>
              <Button
                disabled={!isValid || !isDirty}
                color={COLOR.SECONDARY}
                sx={{ width: 140 }}
                size={SIZE.XL}
                onClick={handleSave}
              >
                {formatMessage({
                  id: "global.btn.save",
                })}
              </Button>
            </FixedButton>
          }
        />
      ),
      _id: 1,
    },
  ];

  if (referralEnabled) {
    tabs.push({
      label: formatMessage({
        id: "tab.content.referralProgramApi.title",
      }),
      content: (
        <ReferralAPI
          attributionConnect={attributionConnect}
          setSelectedTab={setSelectedTab}
        />
      ),
      _id: 2,
    });
  }

  return (
    <>
      <IntegrationSettings
        title={formatMessage({
          id: "marketingSettings.installation.title",
        })}
        description={formatMessage({
          id: "marketingSettings.installation.description",
        })}
        isFixedBarShow={false}
        isIsolateComponent={isTrial}
        content={
          isTrial ? (
            <SDKSettingSteps
              SDKsteps={SDKsteps}
              isIsolateComponent={isTrial}
              extra={buttons}
            />
          ) : (
            <Tabs
              tabs={tabs}
              color={COLOR.SECONDARY}
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
            />
          )
        }
      />
      <BasicDialog
        open={Boolean(options)}
        onClose={handleClose}
        onHandleConfirm={options && options.handleConfirm}
        dialogTitle={options && options.title ? options.title : ""}
        dialogContent={options && options.content ? options.content : ""}
        confirmText={options && options.confirmText ? options.confirmText : ""}
        cancelText={options && options.cancelText ? options.cancelText : ""}
      />
    </>
  );
};

export default IntegrationAttributionLink;

import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { StyledEngineProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";


const AntTabs = withStyles({
  root: { minHeight: 20, background: "transparent" },
  indicator: {
    background: "#7B61FF",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 70,
    fontWeight: 600,
    marginRight: 5,
    padding: 3,
    minHeight: 20,

    "&:hover": {
      color: "#555555",
      opacity: 1,
    },
    "&$selected": {
      "& .MuiTab-wrapper": {
        fontWeight: 600,
        color: "#555555",
      },
    },
    "&:focus": {
      color: "#555555",
    },
  },
  selected: {
    background: "#f7f1fe",
  },
}))((props) => <Tab disableRipple {...props} />);

const MetaTabs = withStyles({
  root: { minHeight: 20, marginBottom: 5 },
  indicator: {
    background: "transparent",
  },
})(Tabs);

const MetaTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 70,
    fontWeight: 600,
    minHeight: 20,
    opacity: 1,
    "& .MuiTab-wrapper": {
      padding: "5px 10px",
    },
    color: "#777777",
    "&:hover": {
      color: "#777777",
    },
    "&$selected": {
      fontWeight: 600,
      "& .MuiTab-wrapper": {
        padding: "5px 10px",
        background: "#ffffff",
        borderRadius: 3,
        color: "#5C5C5C",
      },
    },
    "&:focus": {
      color: "#5C5C5C",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, tabPanelStyles, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={tabPanelStyles}
      {...other}
    >
      {value === index && <div style={{ overflow: "visible" }}>{children}</div>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#BEB3F4",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabs: {
    color: "#383538",
    overflow: "hidden",
    position: "relative",
  },
  tab: {
    fontSize: 13,
    padding: "3px 20px",
    textTransform: "capitalize",
    border: "0px",
    "& .MuiTab-wrapper": {
      fontWeight: 600,
    },
  },
  layoutSpacer: {
    height: "60px",
    position: "relative",
  },
  metaTabs: {
    overflow: "hidden",
    padding: "8px 12px",
    width: "100%",
    position: "absolute",
  },
  metaTab: {
    fontSize: 20,
    fontWeight: 600,

    background: "#F7F1FE",
    textTransform: "capitalize",
    "& .MuiTab-wrapper": {
      fontWeight: 600,
    },
    "&:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "&:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
}));

export default function CustomTabs(props) {
  const {
    tabs,
    initIndex = 0,
    tabIndex,
    handleChange,
    type,
    sm,
    centered,
    tabStyle,
    borderBottom,
    extra,
    disabled,
    tabPanelStyles,
    ...other
  } = props;
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const renderTabs = () => {
    if (type == "metaform") {
      return (
        <div className={classes.layoutSpacer}>
          <div className={classes.metaTabs}>
            <MetaTabs
              {...other}
              value={typeof tabIndex === "number" ? tabIndex : value}
              onChange={handleChange}
              indicatorColor="secondary"
            >
              {tabs.map((tab, index) => {
                return (
                  <MetaTab
                    key={index}
                    style={{
                      fontSize: computer ? (sm ? 13 : 20) : 13,
                      minWidth: computer ? 90 : 0,
                      padding: 3,
                      ...tabStyle,
                    }}
                    className={`${classes.metaTab}`}
                    label={tab.label}
                    disabled={tab.disabled}
                    {...a11yProps(index)}
                  />
                );
              })}
            </MetaTabs>
          </div>
          {extra && (
            <div
              style={{
                position: "absolute",
                right: 5,
                top: computer ? 5 : "-50px",
              }}
            >
              {extra}
            </div>
          )}
        </div>
      );
    }
    return (
      <div position="static" className={classes.tabs}>
        {borderBottom && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              borderBottom: "1px solid #E6E6E6",
              top: 28,
            }}
          />
        )}
        <AntTabs
          // TabIndicatorProps={{ style: { background: "transparent" } }}
          value={typeof tabIndex === "number" ? tabIndex : value}
          onChange={handleChange}
          indicatorColor="primary"
        >
          {tabs.map((tab, index) => {
            // let color =
            //   tabIndex == index ? theme.palette.background.primary : "";
            return (
              <AntTab
                disableRipple
                key={index}
                style={{ ...tabStyle }}
                className={`${classes.tab}`}
                label={tab.label}
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            );
          })}
        </AntTabs>{" "}
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst={false}>
      <div>
        {renderTabs()}

        {tabs.map((tab, index) => {
          return (
            <TabPanel
              key={index}
              value={typeof tabIndex === "number" ? tabIndex : value}
              index={index}
              tabPanelStyles={tabPanelStyles}
            >
              {tab.content}
            </TabPanel>
          );
        })}
      </div>
    </StyledEngineProvider>
  );
}

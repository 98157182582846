import React from "react";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Button, {
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useIntl } from "react-intl";
import useNavigateEntity from "hooks/useNavigateEntity";

export default function IntergrationCover({ show, type, onClick, zIndex = 3 }) {
  const { navigateEntity } = useNavigateEntity();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const handleNavigation = (identifier) => {
    let url = "/settings/general/integration/update";
    if (identifier === "tracker") url += "/attribution";
    if (identifier === "widget") url += "/widget";
    if (identifier === "push") url += "/push";
    if (identifier === "xmtp") url += "/xmtp";
    navigateEntity(url);
  };

  if (!show) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: `${alpha(theme.customColors.grey[300], 0.5)}`,
        backdropFilter: "blur(5px)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        zIndex,
        color: "#7B61FF",
      }}
    >
      <Box mb="16px" width="237px" textAlign="center">
        {type === "tracker"
          ? formatMessage({
              id: "promotionType.tracker.block.description",
            })
          : type === "flyer"
          ? formatMessage({
              id: "promotionType.flyer.block.description",
            })
          : type === "widget"
          ? formatMessage({
              id: "promotionType.widget.block.description",
            })
          : ""}
      </Box>
      {type == "flyer" ? (
        <div style={{ display: "flex", columnGap: 8 }}>
          <Button
            style={{
              background: "#FFFFFF",
              color: theme.customColors.purple[500],
              fontWeight: theme.fontWeight.BOLD,
            }}
            color={COLOR.SECONDARY}
            variant={VARIANT.OUTLINED}
            onClick={() => handleNavigation("push")}
          >
            <img
              src={require("assets/img/img_push.svg").default}
              style={{ width: 20, height: 20, marginRight: 4 }}
            />
            {"Push Protocol"}
          </Button>
          <Button
            style={{
              background: "#FFFFFF",
              fontWeight: theme.fontWeight.BOLD,
              color: theme.customColors.purple[500],
            }}
            color={COLOR.SECONDARY}
            variant={VARIANT.OUTLINED}
            onClick={() => handleNavigation("xmtp")}
          >
            <img
              src={require("assets/img/img_xmtp.svg").default}
              style={{ width: 20, height: 20, marginRight: 4 }}
            />
            {"XMTP"}
          </Button>
        </div>
      ) : (
        <Box
          sx={{ fontWeight: 700, cursor: "pointer" }}
          onClick={() => {
            if (onClick) return onClick(type);
            handleNavigation(type);
          }}
        >
          {formatMessage({
            id: "selectPromoteType.explanatory.goToIntegration",
          })}
        </Box>
      )}
    </Box>
  );
}

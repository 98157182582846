import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef } from "react";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import EngagementCampaign from "./component/EngagementCampaign";
import CustomCampaign from "./component/CustomCampaign";
import { useIntl } from "react-intl";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { get, isEmpty } from "lodash-es";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import _ from "lodash";

export default function CampaignStep2({ step, setStep, setIsOpen }) {
  const { formatMessage } = useIntl();
  const { campaignId } = useParams();
  const isMounted = useRef(false);
  const ecosystemName = useSelector(selectEcosystemName);

  const { stepData, handleSetIsValid } = useContext(CampaignCreationContext);
  const { campaignContentSets } = stepData;
  const methods = useForm({
    defaultValues: {
      campaignContentSets: [],
    },
    mode: "onChange",
  });
  const { getValues } = methods;
  const { append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "campaignContentSets",
  });

  const {
    formState: { isValid: isFormValid },
  } = methods;

  const type = _.get(campaignContentSets, `${0}.type`);
  const goal = _.get(campaignContentSets, `${0}.goal`);

  const mapReactHookFormValidateItem = (targetData) => {
    return targetData.map((item) => {
      return {
        engagementDescription: item.engagementDescription,
        engagementTitle: item.engagementTitle,
        engagementDistributionSettings: item.engagementDistributionSettings,
      };
    });
  };

  function verifyCanNext() {
    const isValid = campaignContentSets.every((item) => {
      if (item.type === "flyer" || item.type === "widget") {
        return (
          isFormValid &&
          item.engagementDistributionSettings.every((setting) => {
            const isChannelAndAudienceValid =
              setting.channel && setting.audienceType;

            const isOnchainValid =
              item.goal !== "onchain" ||
              ((setting.useLinkFormatSetting
                ? !_.isEmpty(setting.websiteUrl)
                : true) &&
                (setting.useLinkFormatSetting
                  ? !_.isEmpty(setting.linkSetting)
                  : true));

            if (setting.audienceType === "all") {
              return isChannelAndAudienceValid && isOnchainValid;
            }

            const isAudienceTypeValid =
              (setting.customType === "adam" && setting.adamAudience) ||
              setting.userGroup ||
              setting.tag ||
              setting.aiEnhancedUserGroup;

            return (
              isChannelAndAudienceValid && isOnchainValid && isAudienceTypeValid
            );
          })
        );
      } else {
        return (
          item.customWebsiteUrl &&
          item.customLinkSettings.every((setting) => {
            return setting.source && setting.mediums.length > 0;
          })
        );
      }
    });
    handleSetIsValid(isValid);
  }

  useEffect(() => {
    if (campaignContentSets[0].type === "tracker") return;
    if (!isEmpty(campaignContentSets)) {
      const filterDataForReactHookForm =
        mapReactHookFormValidateItem(campaignContentSets);
      replace(filterDataForReactHookForm);
      if (campaignId) {
        if (!isMounted.current) {
          methods.trigger();
          isMounted.current = true;
        }
      }
    }
  }, [campaignContentSets]);

  useEffect(() => {
    verifyCanNext();
  }, [isFormValid, campaignContentSets]);

  return (
    <div style={{ paddingBottom: "60px" }}>
      <FormProvider {...methods}>
        <Box>
          <Box className="title" mb={1}>
            {formatMessage({ id: "campaignBuild.secondStep.title" })}
            <span style={{ color: "#7B61FF" }}>
              {" "}
              {step}/{goal === "onchain" || type === "tracker" ? 3 : 2}
            </span>
          </Box>
          <Box className="description" mb={"32px"}>
            {formatMessage({ id: "campaignBuild.secondStep.description" })}
          </Box>
        </Box>
        <div className="mhr" style={{ margin: 0 }}></div>
        {campaignContentSets.map((item, index) => {
          return item.type === "tracker" ? (
            <CustomCampaign key={index} tabIndex={index} step={step} />
          ) : (
            <EngagementCampaign key={index} tabIndex={index} step={step} />
          );
        })}
      </FormProvider>
    </div>
  );
}

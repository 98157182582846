import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import { CampaignCreationContext } from "./context/CampaignCreationProvider";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTheme } from "@mui/styles";

import {
  StyledContainerTitle,
  StyledGrid,
  StyledInputContainer,
  StyledWhiteCardContainer,
} from "./component/CampaignCreation.styles";

import { get, isEmpty, find, filter, isArray } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { StyledAutoComplete } from "features/metadesk/chat/MacroModal/MacroModal.styles";
import { isAddress } from "web3-validator";
import { v4 as uuidv4 } from "uuid";
import Objective from "./component/Objective";
import { AddButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";

export default function CampaignStep3({ step }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const { stepData, setStepValues, handleSetIsValid } = useContext(
    CampaignCreationContext
  );
  const { objectives } = stepData;
  const theme = useTheme();
  const [latestObjectives, setLatestObjectives] = useState([]);
  const [isLatestObjectiveLoading, setIsLatestObjectiveLoading] =
    useState(false);

  const methods = useForm({
    defaultValues: {
      objectiveList: [],
    },
    mode: "onChange",
  });
  const { append, replace, remove } = useFieldArray({
    control: methods.control,
    name: "objectiveList",
  });

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (isEmpty(objectives)) {
      setStepValues({
        objectives: [
          {
            type: "",
            chainId: "",
            smartContractAddress: "",
            smartContractABI: "",
            method: null,
            _id: uuidv4(),
          },
        ],
      });
    } else {
      const stepValuesWithoutId = objectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
        };
      });
      replace(stepValuesWithoutId);
    }
  }, []);

  useEffect(() => {
    const isNextValid = isValid;

    handleSetIsValid(isNextValid);
  }, [isValid, objectives]);

  const handleAddObjective = () => {
    setStepValues({
      objectives: [
        ...objectives,
        {
          type: "",
          chainId: "",
          smartContractAddress: "",
          smartContractABI: "",
          method: null,
          _id: uuidv4(),
        },
      ],
    });
  };

  const handleDeleteObjective = (targetId, objectiveIndex) => {
    const newArr = objectives.filter((item) => item._id !== targetId);
    setStepValues({ objectives: newArr });
    remove(objectiveIndex);
  };

  const handleGetLatestObjective = async () => {
    setIsLatestObjectiveLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/objective/latest`
      );
      setLatestObjectives(data);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLatestObjectiveLoading(false);
    }
  };

  useEffect(() => {
    handleGetLatestObjective();
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <Box className="title" mb={1}>
            {formatMessage(
              {
                id: "campaignBuild.thirdStep.title",
              },
              {
                step: <span style={{ color: "#7B61FF" }}> {step}/3</span>,
              }
            )}
          </Box>
          <Box className="description" mb={"22px"}>
            {formatMessage({ id: "campaignBuild.thirdStep.description" })}
          </Box>
        </Box>

        <StyledWhiteCardContainer>
          <StyledContainerTitle>
            {formatMessage({ id: "campaignBuild.thirdStep.subtitle" })}
          </StyledContainerTitle>
          <div className="mhr" style={{ margin: 0 }}></div>
          <StyledWhiteContainer>
            {objectives.map((objectiveItem, index) => (
              <Objective
                objectiveIndex={index}
                key={objectiveItem._id}
                onDelete={handleDeleteObjective}
                latestObjectives={latestObjectives}
                formData={stepData}
                setFormData={setStepValues}
              ></Objective>
            ))}

            <Box
              sx={{
                border: `1px dashed ${theme.customColors.grey[400]}`,
                mb: 3,
                mt: "14px",
              }}
            ></Box>
            <AddButton color="#E6E6E6" onClick={handleAddObjective}>
              {formatMessage({
                id: "campaignBuild.thirdStep.addObjective.btn",
              })}
            </AddButton>
          </StyledWhiteContainer>
        </StyledWhiteCardContainer>
      </FormProvider>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  StyledTrackingPaper,
  StyledTrackingTitle,
  StyledTrackingContainer,
  StyledDisabledOverlay,
  StyledTableHead,
  StyledTableCell,
  StyledHeadCell,
  StyledAPIURL,
} from "./TrackignSetting.styles";
import {
  StyledApiKeyCreatedTime,
  StyledFormTitle,
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepDescribe,
} from "../IntegrationWidgetSetting.styles";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "features/home/ConfirmDialogProvider";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import {
  Button,
  IconButton,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  alertServerError,
  formatCalendarDateAndTime,
} from "features/helpers/utils";
import { enqueueSnackbar } from "features/common/redux/actions";
import { Grid, Box, Table, TableBody, TableRow } from "@mui/material";
import { useOutletContext } from "react-router";
import { useTheme, styled } from "@mui/styles";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
const ReferralAPI = ({ attributionConnect, setSelectedTab }) => {
  const { formatMessage } = useIntl();
  const apiKeyCreated = new Date();
  const [entityName, isCustomDomain] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [apiKey, setApiKey] = useState("");
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const handleRegenerateAPI = async () => {
    try {
      const result = await axios.post(
        `${apiUrl}/api/referral/${entityName}/apiKey`
      );
      setApiKey(result.data.apiKey);
    } catch (err) {
      dispatch(alertServerError(err));
    }
  };

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${apiUrl}/api/referral/${entityName}/apiKey`
      );
      const data = result?.data.key;
      setApiKey(data);
      return data;
    } catch (err) {
      return null;
      // dispatch(alertServerError(err));
    } finally {
      setIsLoading(false);
    }
  };

  // 要是沒有apiKey 並且以前都沒有安裝過 進來直接產生一個apiKey
  useEffect(async () => {
    const data = await getAPIData();
    if (!data) {
      handleRegenerateAPI();
    }
  }, []);

  const handleRegenerate = () => {
    confirm({
      title: formatMessage({
        id: "popup.regenerateApiKey.title",
      }),
      content: formatMessage({
        id: "popup.regenerateApiKey.description",
      }),
      confirmText: formatMessage({
        id: "global.btn.confirm",
      }),
      cancelText: formatMessage({
        id: "Cancel",
      }),
    })
      .then(() => {
        handleRegenerateAPI();
      })
      .catch(() => {});
  };
  const renderTable = (rowData) => {
    return (
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledHeadCell style={{ width: "25%" }}>Name</StyledHeadCell>
            <StyledHeadCell style={{ width: "25%" }}>Type</StyledHeadCell>
            <StyledHeadCell style={{ width: "50%" }}>
              Description
            </StyledHeadCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {_.map(rowData, (row, i) => (
            <TableRow key={i}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>{row.description}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderApi = (data) => {
    return (
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle style={{ fontWeight: 700 }}>
            {data?.title}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {data?.description}
          </StyledSettingWidgetStepDescribe>
          <StyledAPIURL>{data?.url}</StyledAPIURL>
        </StyledTrackingContainer>
        <StyledTrackingTitle>
          {formatMessage({
            id: "global.request.parameters",
          })}
        </StyledTrackingTitle>
        {renderTable(data?.requests)}
        <StyledTrackingTitle style={{ marginTop: 20 }}>
          {formatMessage({
            id: "global.response.parameters",
          })}
        </StyledTrackingTitle>
        {renderTable(data?.responses)}
      </StyledSettingWidgetStepContainerOutSide>
    );
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      enqueueSnackbar({
        message: (
          <div
            style={{ color: theme.customColors.purple[500] }}
            className="formTitle startRow"
          >
            <i className="meta-crm-icon-ic_check font-size-18 mgr5" />
            Copied
          </div>
        ),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
        },
      })
    );
  };
  return (
    <StyledTrackingPaper>
      {!attributionConnect && (
        <StyledDisabledOverlay>
          <Box sx={{ maxWidth: 250, color: "#7B61FF", textAlign: "center" }}>
            {formatMessage({
              id: "marketingSettings.referralProgramApiTab.status.disable",
            })}
            <Button
              variant="text"
              sx={{ fontWeight: 700, mt: "10px" }}
              onClick={() => {
                setSelectedTab(1);
              }}
            >
              {formatMessage({
                id: "global.btn.goToEmbedTrackingTag",
              })}
            </Button>
          </Box>
        </StyledDisabledOverlay>
      )}
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle>
            {formatMessage({
              id: "marketingSettings.referralProgramApiTab.referralProgramApi.title",
            })}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {formatMessage({
              id: "marketingSettings.referralProgramApiTab.referralProgramApi.description",
            })}
          </StyledSettingWidgetStepDescribe>
        </StyledTrackingContainer>
      </StyledSettingWidgetStepContainerOutSide>
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle>
            {formatMessage({
              id: "marketingSettings.referralProgramApiTab.getYourAccessToken.title",
            })}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {formatMessage({
              id: "marketingSettings.referralProgramApiTab.getYourAccessToken.description",
            })}
          </StyledSettingWidgetStepDescribe>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1} id={"guide1_2"}>
                <StyledFormTitle center item xs={12} md={4}>
                  {formatMessage({
                    id: "widgetSettings.apiKey.input",
                  })}
                </StyledFormTitle>
                <Grid position={"relative"} item xs={12} md={6}>
                  <InputField
                    multiline={false}
                    rows={1}
                    disabled
                    value={apiKey}
                    endAdornment={
                      <IconButton onClick={() => copyToClipboard(apiKey)}>
                        <i className="meta-crm-icon-ic_copy font-size-24" />
                      </IconButton>
                    }
                  ></InputField>

                  <StyledApiKeyCreatedTime>
                    <Box
                      color={(theme) => theme.customColors.grey[600]}
                      size="12px"
                      mr="2.5px"
                    >
                      {formatMessage({
                        id: "plans&billing.usage.recountTime",
                      })}{" "}
                      :
                    </Box>
                    <Box
                      color={(theme) => theme.customColors.purple[500]}
                      size="12px"
                    >
                      {formatCalendarDateAndTime(apiKeyCreated)}
                    </Box>
                  </StyledApiKeyCreatedTime>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    disabled={isLoading}
                    size={SIZE.XL}
                    sx={{ width: "100%" }}
                    onClick={handleRegenerate}
                  >
                    {formatMessage({
                      id: "global.btn.regenerate",
                    })}
                  </Button>
                </Grid>
                <Grid item xs={12} mb={"16px"}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledTrackingContainer>
      </StyledSettingWidgetStepContainerOutSide>
      {renderApi({
        title: formatMessage({
          id: "marketingSettings.referralProgramApiTab.user.title",
        }),
        description: formatMessage({
          id: "marketingSettings.referralProgramApiTab.user.description",
        }),
        url: "https://client.metacrm.inc/user",
        requests: [
          { name: "apiKey", type: "String", description: "For authorization" },
          { name: "address", type: "String", description: "Referer address" },
        ],
        responses: [
          { name: "url", type: "String", description: "User referral link" },
          {
            name: "linkClick",
            type: "Number",
            description: "Link click count",
          },
          {
            name: "walletConnected",
            type: "Number",
            description: "Wallet connected count",
          },
          {
            name: "transactionReferred",
            type: "Number",
            description: "Referred transaction count",
          },
        ],
      })}
      {renderApi({
        title: formatMessage({
          id: "marketingSettings.referralProgramApiTab.getTopReferralData.title",
        }),
        description: formatMessage({
          id: "marketingSettings.referralProgramApiTab.getTopReferralData.description",
        }),
        url: "https://client.metacrm.inc/data",
        requests: [
          { name: "apiKey", type: "String", description: "For authorization" },
          {
            name: "top",
            type: "Number",
            description: "default = 5, max = 100",
          },
        ],
        responses: [
          {
            name: "data",
            type: "Array",
            description: "Top referral leader board",
          },
        ],
      })}
    </StyledTrackingPaper>
  );
};

export default ReferralAPI;

import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import Modal from "@mui/material/Modal";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import {
  StyledBasicModalContainer,
  StyledCloseBtn,
  StyledRadioLabel,
  StyledModalTitle,
  StyledModalContent,
  StyledButtonArea,
  StyledContentHeader,
  StyledPreview,
} from "./ExportModal.styles";
import { apiUrl } from "features/configure";
import axios from "axios";
import { alertServerError } from "features/helpers/utils";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import { exportCSV, formatCSV } from "features/helpers/utils";
import { useDispatch } from "react-redux";
import { Loading } from "features/common";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
const ExportModal = ({
  open,
  onClose,
  entityName,
  from = "all",
  group,
  choose,
  dataset,
}) => {
  const theme = useTheme();
  const [isExporting, setIsExporting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [preview, setPreview] = useState("");
  const [mailchimpList, setMailchimpList] = useState([]);
  const [selectedMailchimp, setSelectedMailchimp] = useState("");
  const { entityConfig } = useFetchEntityConfig();
  const [exportFrom, setExportFrom] = useState("choose");
  const [exportTo, setExportTo] = useState("csv");
  const dispatch = useDispatch();
  const enableMailchimp = _.get(
    entityConfig,
    `serviceModule.mailchimp.enabled`
  );

  const appendAddMemberData = (postData) => {
    if (exportFrom == "choose") {
      postData.userList = choose;
    } else if (exportFrom == "dataset") {
      postData.userSourceId = dataset._id;
    } else if (exportFrom == "group") {
      postData.groupId = group._id;
    } else if (exportFrom == "all") {
      postData.from = from;
    }
    return postData;
  };

  useEffect(async () => {
    if (exportTo == "mailchimp") {
      try {
        setIsFetching(true);
        const listResult = await axios.get(
          apiUrl + `/api/mailchimp/${entityName}/list`
        );
        setMailchimpList(
          _.map(_.get(listResult, "data.lists", []), (o) => {
            return { label: o.name, value: o.id };
          })
        );

        let postData = { preview: 1 };
        postData = appendAddMemberData(postData);
        const previewResult = await axios.post(
          apiUrl + `/api/mailchimp/${entityName}/addMember`,
          postData
        );
        setPreview(previewResult.data);
        setIsFetching(false);
      } catch (e) {
        console.log("e: ", e);
        dispatch(alertServerError(e));
      }
    }
  }, [exportTo, exportFrom]);

  const exportUsers = async () => {
    try {
      setIsExporting(true);

      if (exportTo == "csv") {
        let postData = appendAddMemberData({});
        const exportResult = await axios.post(
          `${apiUrl}/api/userSource/${entityName}/export`,
          postData
        );
        exportCSV(formatCSV(exportResult.data));
      } else if (exportTo == "mailchimp") {
        let postData = appendAddMemberData({ listId: selectedMailchimp });
        const result = await axios.post(
          apiUrl + `/api/mailchimp/${entityName}/addMember`,
          postData
        );
      }
      closeModal();
    } catch (e) {
      console.log("e: ", e);
      dispatch(alertServerError(e));
    } finally {
      setIsExporting(false);
    }
  };
  const closeModal = () => {
    onClose();
    setExportFrom("choose");
    setExportTo("csv");
    setPreview("");
    setSelectedMailchimp("");
  };
  const exportFromList = [
    {
      label: (
        <div className="startRow">
          Current Choose
          <StyledRadioLabel
            style={{ color: exportFrom == "choose" ? "#7B61FF" : "#A5A5A5" }}
          >
            {choose && `Choose : ${choose.length}`}
          </StyledRadioLabel>
        </div>
      ),
      value: "choose",
    },
  ];
  const exportToList = [{ label: "CSV", value: "csv" }];
  if (enableMailchimp)
    exportToList.push({
      label: "Mailchimp",
      value: "mailchimp",
      disabled: !_.get(entityConfig, "serviceModule.mailchimp.connected"),
    });

  if (group) {
    exportFromList.push({
      label: (
        <div className="startRow">
          Current Group
          <StyledRadioLabel
            style={{ color: exportFrom == "group" ? "#7B61FF" : "#A5A5A5" }}
          >
            {`${group.name}`}
          </StyledRadioLabel>
        </div>
      ),
      value: "group",
    });
  } else if (dataset && dataset.value !== "import") {
    exportFromList.push({
      label: (
        <div className="startRow">
          Current Dataset
          <StyledRadioLabel
            style={{ color: exportFrom == "dataset" ? "#7B61FF" : "#A5A5A5" }}
          >
            {`${dataset.tabName} : ${dataset.userCount}`}
          </StyledRadioLabel>
        </div>
      ),
      value: "dataset",
    });
  } else {
    exportFromList.push({
      label: "All",
      value: "all",
    });
  }
  const renderMailchimp = () => {
    if (!enableMailchimp) return <div />;
    return (
      <div>
        <div style={{ color: "#A5A5A5", marginLeft: 25, fontSize: 12 }}>
          * Only customers with email will be exported
        </div>
        {exportTo == "mailchimp" && (
          <div style={{ marginTop: 12 }}>
            <CustomDropdown
              border
              style={{ width: 200, marginLeft: 25 }}
              data={mailchimpList}
              value={selectedMailchimp}
              onSelect={(e) => {
                setSelectedMailchimp(e.target.value);
              }}
            />
            <StyledPreview>
              {isFetching ? (
                <div
                  className="row"
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  Fetching...
                  <img
                    src={require("assets/img/loading.gif")}
                    style={{ height: 20, width: 20, marginLeft: 4 }}
                  />
                </div>
              ) : (
                `Customer with email : ${_.get(
                  preview,
                  "withEmail"
                )}\nCustomer without email : ${_.get(preview, "withoutEmail")}`
              )}
            </StyledPreview>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      className={"modal"}
      onClose={closeModal}
      sx={{ zIndex: 10000 }}
    >
      <StyledBasicModalContainer>
        <Loading open={isExporting} fullScreen={false} />
        <StyledModalTitle>
          {"Export Customers"}
          <StyledCloseBtn
            size={24}
            color={theme.customColors.grey[700]}
            onClick={closeModal}
          >
            <i className="meta-crm-icon-ic_cancel" />
          </StyledCloseBtn>
        </StyledModalTitle>
        <StyledModalContent>
          <StyledContentHeader>Export from</StyledContentHeader>
          <CustomRadioGroup
            data={exportFromList}
            value={exportFrom}
            onChange={(e) => setExportFrom(e.target.value)}
          />
          <StyledContentHeader style={{ marginTop: 16 }}>
            Export to
          </StyledContentHeader>
          <CustomRadioGroup
            data={exportToList}
            value={exportTo}
            onChange={(e) => setExportTo(e.target.value)}
          />
          {renderMailchimp()}
        </StyledModalContent>
        <StyledButtonArea>
          <Button
            color={COLOR.SECONDARY}
            variant={VARIANT.TEXT}
            onClick={closeModal}
            style={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            color={COLOR.SECONDARY}
            disabled={
              exportTo == "mailchimp" &&
              (!selectedMailchimp || _.get(preview, "withEmail", 0) == 0)
            }
            onClick={exportUsers}
            style={{ width: 120 }}
          >
            Export
          </Button>
        </StyledButtonArea>
      </StyledBasicModalContainer>
    </Modal>
  );
};

export default ExportModal;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import {
  useFetchEntityConfig,
  useFetchTicketList,
} from "features/metadesk/redux/hooks";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useLocation, useOutletContext } from "react-router-dom";
import { Box, Collapse, Tab } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
// mock organization config
import {
  StyledDrawer,
  StyledDrawerContainer,
  StyledDrawerMenu,
  StyledLineTab,
  StyledNoneTab,
  StyledTab,
  StyledTabs,
  drawerWidth,
  moduleWidth,
} from "./MainMenu.styles";
import useNavigateEntity from "hooks/useNavigateEntity";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { defaultThemeConfig } from "features/configure";
import MenuList from "./MenuList";
import {
  containsAllProperties,
  getMenuList,
  getShortAddress,
  parseUrlPath,
  redirectRoot,
} from "features/helpers/utils";
import useGetPagePermission from "hooks/useGetPagePermission";
import { routeConfig } from "RouteComponent";
import { useOpenState } from "contexts/stepContext/Open.context";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { useDispatch, useSelector } from "react-redux";
import { useDisconnect } from "wagmi";
import CustomListItem from "components/CustomListItem/CustomListItem";
import { getUserAvatarIcon } from "features/metadesk/utils";
import { useIntl } from "react-intl";
import {
  selectBillingBarShow,
  selectRemindBarShow,
} from "features/metadesk/redux/globalState/globalState.selector";
import FeedbackButton from "./FeedbackButton";
import RemindBanner from "./Banner/RemindBanner";
import BillingBanner from "./Banner/BillingBanner";
import DemoBanner from "./Banner/DemoBanner";

function MainMenu({}) {
  const theme = useTheme();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { openState, setOpen } = useOpenState();
  const matches = useMatches();
  const dispatch = useDispatch();
  const isHideMenu = _.get(matches, `[${matches?.length - 1}].handle.hideMenu`);
  const remindBarShow = useSelector(selectRemindBarShow);
  const billingBarShow = useSelector(selectBillingBarShow);
  const navigate = useNavigate();
  const { navigateEntity } = useNavigateEntity();
  const [entityName, isCustomDomain] = useOutletContext();
  const { entityConfig } = useFetchEntityConfig();
  const [value, setValue] = useState(0);
  const [module, setModule] = useState("");
  const { auth, signout, userData } = useConnectWallet();
  const { getStatsCounts } = useFetchTicketList();
  const ecosystemName = useSelector(selectEcosystemName);
  const { disconnect } = useDisconnect();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const companyLogo = _.get(entityConfig, "company.logo");
  const [mobileLeftOpen, setMobileLeftOpen] = useState(true);
  const team = _.get(userData, "team", {});
  const isAdmin = userData?.team?.role?.isAdmin;
  const mobileToggle = () => {
    setMobileLeftOpen(true);
    setOpen({ menu: true });
  };
  const handleToggle = () => {
    localStorage.setItem("metacrm_menu_not_open", !!openState.menu);
    setOpen({ menu: !openState.menu });
  };
  const pagePermission = useGetPagePermission();

  useEffect(() => {
    if (auth) {
      getStatsCounts({ entityName });
      const intervalId = setInterval(() => {
        getStatsCounts({ entityName });
      }, 15000);

      return () => clearInterval(intervalId);
    }
  }, [auth, entityName]);

  useEffect(() => {
    setOpen({ hideSideMenu: isHideMenu });
  }, [isHideMenu]);

  const menuList = useMemo(() => {
    return getMenuList(entityConfig, pagePermission, ecosystemName, entityName);
  }, [entityConfig]);

  const handleMouseLeave = useCallback(() => {
    if (!openState.modulePinned) {
      setOpen({ moduleOpen: false });
    }
  }, [openState.modulePinned]);

  const togglePin = useCallback(() => {
    localStorage.setItem("metacrm_module_not_pinned", !!openState.modulePinned);
    setOpen({ modulePinned: !openState.modulePinned });
  }, [openState.modulePinned]);

  const handleMouseEnter = useCallback(() => {
    setOpen({ moduleOpen: true });
  }, []);

  const renderModules = ({ label, icon, upgrade }) => {
    let iconImg = icon;
    if (label == "Account")
      iconImg = (
        <img
          src={require(`assets/img/${getUserAvatarIcon(team?.role?._id)}`)}
          alt="roleImage"
          style={{ width: 20, height: 20 }}
        />
      );
    return (
      <Box position={"relative"} width="48px">
        {upgrade && (
          <i
            className="meta-crm-icon-ic_upgrade font-size-14"
            style={{
              position: "absolute",
              right: "2px",
              top: "-6px",
              fontWeight: 300,
              color: "#7B61FF",
            }}
          />
        )}
        <IconTooltip
          customColor="none"
          placement="right"
          icon={
            <Box>
              <Box fontWeight={400}> {iconImg}</Box>
              <Box
                fontSize={label?.length > 8 ? "9px" : "10px"}
                style={{ letterSpacing: "-0.5px" }}
              >
                {label}
              </Box>
            </Box>
          }
          content={
            <Box fontWeight={500} fontSize={"12px"}>
              {label}
            </Box>
          }
        ></IconTooltip>
      </Box>
    );
  };

  const tabs = useMemo(() => {
    return _.compact(
      _.map(_.values(routeConfig), (moduleData) => {
        const isNeedUpgrade =
          !_.isEmpty(moduleData.upgrade) &&
          !containsAllProperties(entityConfig, moduleData.upgrade);
        if (
          moduleData.path &&
          !isNeedUpgrade &&
          _.get(menuList, `[${moduleData.path}]`, []).length == 0
        )
          return;
        if (!isAdmin && isNeedUpgrade) return;
        const result = {
          label: renderModules({
            label: moduleData.title,
            icon: moduleData.icon,
            upgrade: isNeedUpgrade,
          }),
          module: moduleData.path,
          upgrade: isNeedUpgrade,
        };
        return result;
      })
    );
  }, [menuList]);

  useEffect(() => {
    setModule(tabs[value]?.module);
  }, [tabs, value]);

  useEffect(() => {
    const parsedUrl = parseUrlPath(location.pathname);
    if (parsedUrl) {
      const newValue = _.findIndex(tabs, { module: parsedUrl.module });
      if (newValue >= 0) setValue(newValue);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    handleTabClick(newValue);
  };

  const handleTabClick = (newValue) => {
    if (!tabs[newValue]) return;
    const upgrade = tabs[newValue].upgrade;
    if (upgrade) {
      setOpen({ upgrade: true });
      return;
    }
    setValue(newValue);
    setOpen({ menu: true });
    localStorage.setItem("metacrm_menu_not_open", false);
    const m = tabs[newValue].module;
    navigateEntity(redirectRoot(menuList, m));
  };

  const renderModuleMenu = () => {
    return (
      <StyledDrawerMenu
        style={{ width: moduleWidth, zIndex: 1, margin: "5px" }}
      >
        <Box
          sx={{
            p: "12px 10px",
            m: "0 5px",
            mb: "10px",
            borderBottom: `1px solid ${theme.customColors.grey[300]}`,
          }}
        >
          <IconButton
            onClick={togglePin}
            style={{ width: "16px" }}
            size={16}
            color={openState.modulePinned ? "#5643CC" : "#a5a5a5"}
          >
            <i
              className={`meta-crm-icon-ic_${
                openState.modulePinned ? "pin" : "pin1"
              }`}
            />
          </IconButton>
        </Box>

        <StyledTabs
          orientation={"vertical"}
          value={value}
          onChange={handleChange}
          indicatorColor={"transparent"}
        >
          {tabs.map((tab, index) => {
            if (!tab.module) {
              if (index == 0) return <StyledNoneTab />;
              return <StyledLineTab disable={true} />;
            }

            return (
              <StyledTab
                label={tab.label}
                key={index}
                onClick={() => handleTabClick(index)}
                upgrade={tab.upgrade}
                disabled={tab.disabled}
              />
            );
          })}
        </StyledTabs>
      </StyledDrawerMenu>
    );
  };
  const renderSubMenu = () => {
    return (
      <StyledDrawerMenu style={{ width: drawerWidth }}>
        {renderDrawerContent()}
      </StyledDrawerMenu>
    );
  };
  const renderMobileMenu = () => {
    if (!mobileLeftOpen) return;
    return (
      <StyledDrawer
        anchor={"left"}
        timeout={1000}
        variant={computer ? "permanent" : "temporary"}
        open={openState.moduleOpen || openState.menu}
        onClose={() => {
          if (!computer && mobileLeftOpen) setMobileLeftOpen(false);
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledDrawerContainer>
          {renderModuleMenu()}
          {openState.menu && renderSubMenu()}
        </StyledDrawerContainer>
      </StyledDrawer>
    );
  };

  const renderSideMenu = () => {
    if (isHideMenu) return;
    if (!computer) {
      return renderMobileMenu();
    }
    return (
      <StyledDrawerContainer>
        <Collapse
          in={openState.moduleOpen}
          orientation="horizontal"
          timeout={600}
          collapsedSize={openState.menu ? "10px" : moduleWidth}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {openState.moduleOpen ? (
            renderModuleMenu()
          ) : (
            <Box width={moduleWidth} height="100%" />
          )}
        </Collapse>
        <Collapse in={openState.menu} timeout={600} orientation="horizontal">
          {renderSubMenu()}
        </Collapse>
      </StyledDrawerContainer>
    );
  };

  const renderDrawerContent = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        height="100%"
        justifyContent={"space-between"}
      >
        <Box
          sx={{
            p: "8px 6px",
            m: "0 5px",
            mb: "10px",
            borderBottom: `1px solid ${theme.customColors.grey[300]}`,
          }}
          className="betweenRow"
        >
          <img
            src={companyLogo ? companyLogo : defaultThemeConfig.headerLogo}
            style={{
              height: "26px",
              maxWidth: "170px",
              marginRight: 20,
              objectFit: "contain",
              verticalAlign: "middle",
            }}
          />
          <IconButton size={"8px"} onClick={handleToggle} color="#a5a5a5">
            <i className="meta-crm-icon-ic_close" />
          </IconButton>
        </Box>
        <Box style={{ flex: 1, overflowY: "auto" }}>
          {module == "account" && (
            <Box className="startRow" sx={{ p: "10px", pt: "5px" }}>
              <img
                src={require(`assets/img/${getUserAvatarIcon(
                  team?.role?._id
                )}`)}
                alt="roleImage"
                className={"icon"}
              />
              <Box>
                <Box color={theme.customColors.grey[700]}>{team.name}</Box>
                <Box fontSize={"12px"} color={theme.customColors.grey[500]}>
                  {getShortAddress(team.address)}
                </Box>
              </Box>
            </Box>
          )}

          <MenuList
            data={menuList}
            module={module}
            entityName={entityName}
            isCustomDomain={isCustomDomain}
          />
          {module == "account" && (
            <CustomListItem
              label={
                <Box className="startRow" sx={{ fontWeight: 400 }}>
                  <i className="meta-crm-icon-ic_signOut font-size-16 mgr8" />
                  <Box fontSize={"12px"} fontWeight={700}>
                    {formatMessage({ id: "global.action.signOut" })}
                  </Box>
                </Box>
              }
              onClick={() => {
                signout(disconnect);
                navigate("/onboarding");
              }}
            />
          )}
        </Box>

        <Box
          style={{
            background: "white",
            height: 50,
            textAlign: "center",
            padding: "10px",
          }}
        >
          <FeedbackButton />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
        {!computer && !isHideMenu && (
          <IconButton
            color="white"
            hoverColor="white"
            onClick={() => mobileToggle()}
            style={{
              position: "fixed",
              zIndex: 1000,
              left: "0",
              top: "50%",
              background: "#383538",
              padding: "10px",
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        )}
        <Box>{renderSideMenu()}</Box>
        <Box
          component="main"
          style={{
            flex: 1,
            position: "relative",
            overflow: "auto",
            height: "100vh", // 確保主內容區域可以獨立滾動
          }}
        >
          {/* remind update banner  */}
          <RemindBanner />
          <DemoBanner />
          {/* remind billing banner */}
          {/* <BillingBanner /> */}

          <Box
            style={{
              margin: "0 auto",
              maxWidth: 1440,
              padding: "25px 20px",
              paddingTop: "54px",
            }}
          >
            <Outlet context={[entityName, isCustomDomain]} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MainMenu;

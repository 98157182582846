import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { Grid, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
  IconButton,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { useNavigate } from "react-router-dom";
import { get, isEmpty } from "lodash-es";

import { useFieldArray, useForm, useFormState } from "react-hook-form";
import CustomRadioGroup from "components/CustomRadioGroup/CustomRadioGroup";
import {
  alertServerError,
  parseCustomDomainUrl,
  uploadFiles,
} from "features/helpers/utils";
import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { useFetchWidgetConfig } from "features/metadesk/redux/hooks";
import { Loading } from "features/common";
import { StyledWidgetTitle } from "./WidgetDemoTemplate.styles";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import { enqueueSnackbar } from "features/common/redux/actions";
import useGetPagePermission from "hooks/useGetPagePermission";
import ImageUpload from "features/metadesk/components/ImageUpload";
import {
  ErrorContainer,
  FileUploadContainer,
  FileValidatorInfo,
  TinyText,
  UploadComponent,
} from "./WidgetLauncher.styles";

const widgetIcons = {
  0: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget01-1700205700.svg",
  1: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget02-1700205700.svg",
  2: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget03-1700205700.svg",
  3: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget04-1700205700.svg",
  4: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget05-1700205700.svg",
  5: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget06-1700205700.svg",
  6: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget07-1700205700.svg",
  7: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget08-1700205700.svg",
  8: "https://storage.googleapis.com/metadesk-dev/logo/ic_widget09-1700205700.svg",
  custom_ic_option: require(`assets/img/ic_editWidgetLauncherIcon.png`),
};

const getWidgetIcUrl = (iconUrl) => {
  const url = Object.values(widgetIcons).find((url) => url === iconUrl);
  return url ? url : widgetIcons[`custom_ic_option`];
};

const isCustomOption = (value) => {
  return value === widgetIcons["custom_ic_option"];
};

const positionValues = [
  { label: "The bottom right", value: "bottom_right" },
  { label: "The bottom left", value: "bottom_left" },
  { label: "The top right", value: "top_right" },
  { label: "The top left", value: "top_left" },
];

const displacementValues = [
  { label: "None", value: "none" },
  { label: "Vertically shift", value: "verticallyShift" },
  {
    label: "Horizontally shift",
    value: "horizontallyShift",
  },
];

const defaultValues = {
  icon: widgetIcons[0],
  displacement: displacementValues[0],
  position: positionValues[0],
  iconSize: "normal",
};

const WidgetLauncher = ({ widgetItemInfo, onSetDirty, onRefetch, goBack }) => {
  const { formatMessage } = useIntl();

  const [entityName, isCustomDomain] = useOutletContext();
  const [formData, setFormData] = useState(defaultValues);
  const [pastFormData, setPastFormData] = useState(defaultValues);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [uploadedImg, setUploadedImg] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { fetchWidgetConfig, updateWidgetConfig, updateWidgetConfigPending } =
    useFetchWidgetConfig();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control });

  const isCustomizationAllowed = _.get(
    widgetItemInfo,
    "isCustomizationAllowed"
  );

  useEffect(() => {
    onSetDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    /**
     * need to remove the custom icon from the option only when the
     * customization is not allowed i.e isCustomizationAllowed is false
     */
    if (isCustomizationAllowed === false) {
      delete widgetIcons.custom_ic_option;
    }
  }, [isCustomizationAllowed]);

  useEffect(() => {
    let data = {};
    data.icon = _.get(widgetItemInfo, "icon", defaultValues.icon);
    data.iconSize = _.get(widgetItemInfo, "iconSize", defaultValues.iconSize);
    const position = _.get(widgetItemInfo, "position");
    data.position = !isEmpty(position)
      ? _.find(positionValues, { value: position[0] + "_" + position[1] })
      : defaultValues.position;
    const displacement = _.get(widgetItemInfo, "displacement");
    data.displacement = displacement
      ? _.find(displacementValues, { value: displacement })
      : defaultValues.displacement;
    setFormData(data);
    setPastFormData(data);
    reset(data);
  }, [widgetItemInfo]);

  const saveConfig = async () => {
    try {
      const config = { ...formData };
      config.position = formData.position.value.split("_");
      config.displacement = formData.displacement.value;
      config.iconSize = formData.iconSize;
      config.widgetId = widgetItemInfo._id;
      config.icon =
        typeof formData.icon === "object" ? formData.icon[0] : formData.icon;
      console.log("config: ", config);
      await updateWidgetConfig({
        entityName,
        config,
        type: "customization",
      });
      onRefetch();
      dispatch(
        enqueueSnackbar({
          message: formatMessage({
            id: "widgetSettings.configure.saveSuccess",
          }),
          options: {
            variant: "success",
          },
        })
      );
      setUploadedImg([]);
    } catch (err) {
      console.log("err: ", err);
      dispatch(alertServerError(err));
    }
  };

  const getIconPositionStyle = () => {
    let style = {};
    switch (formData.position.value) {
      case "bottom_right":
        style = { bottom: "5%", right: "3%" };
        break;
      case "bottom_left":
        style = { bottom: "5%", left: "3%" };
        break;
      case "top_right":
        style = { top: "14%", right: "3%" };
        break;
      case "top_left":
        style = { top: "14%", left: "3%" };
        break;
      default:
        style = {};
    }
    if (formData.displacement.value === "verticallyShift") {
      style.top ? (style.top = "27%") : (style.bottom = "18%");
    } else if (formData.displacement.value === "horizontallyShift") {
      style.right ? (style.right = "12%") : (style.left = "12%");
    }

    return style;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData, [name]: value };

    /**
     * this block is supposed to be updated after implementation of Billing Plan
     */
    // if (name === "icon" && isCustomOption(value) && !isCustomizationAllowed) {
    //   window.location.href = "/workspace/login";
    //   return;
    // }

    if (name === "icon" && isCustomOption(value)) {
      newFormData[name] =
        getCustomPreviewIcon() ?? widgetIcons[`custom_ic_option`];
      setFormData(newFormData);
      setPastFormData({
        ...formData,
        [name]: getCustomPreviewIcon() ?? pastFormData.icon,
      });
      setIsSaveDisabled(uploadedImg.length ? false : true);
      if (uploadedImg.length) {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      return;
    }

    setFormData(newFormData);
    setPastFormData(newFormData);
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsSaveDisabled(false);
  };

  const handleUploadImageChange = async (list) => {
    const customLauncherIcon = [list[list.length - 1]];
    const iconUrl = await uploadFiles(customLauncherIcon, entityName);
    setUploadedImg([iconUrl]);
    setFormData({ ...formData, icon: iconUrl });
    setPastFormData({ ...formData, icon: iconUrl });
    setValue("icon", iconUrl, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsSaveDisabled(false);
  };

  const getCustomPreviewIcon = () => {
    let customIcon = _.get(uploadedImg, "[0]");

    if (customIcon) return customIcon;

    const icon = _.get(widgetItemInfo, "icon", defaultValues.icon);
    const ic_url = getWidgetIcUrl(icon);
    customIcon = ic_url === widgetIcons[`custom_ic_option`] ? icon : null;

    return customIcon;
  };

  const getLogo = (width = 80, height = 80, logoStyle = {}) => {
    const customIcon = getCustomPreviewIcon();

    return customIcon ? (
      <>
        <img
          src={customIcon}
          style={{
            width: width,
            height: height,
            objectFit: "contain",
            ...logoStyle,
          }}
        />
        <i
          className="meta-crm-icon-ic_uploadImage"
          style={{
            color: theme.customColors.grey[500],
            fontSize: 20,
            position: "absolute",
            bottom: 8,
            right: 8,
          }}
        />
      </>
    ) : (
      <>
        <i
          className="meta-crm-icon-ic_uploadImage"
          style={{
            color: theme.customColors.grey[300],
            fontSize: 90,
          }}
        />
        <TinyText
          style={{
            fontWeight: theme.fontWeight.BOLD,
            paddingTop: 4,
          }}
        >
          Click to upload or Drag & Drop
        </TinyText>
      </>
    );
  };

  const renderImageUploader = () => {
    const iconUrl = getWidgetIcUrl(formData.icon);

    if (!isCustomizationAllowed || iconUrl !== widgetIcons[`custom_ic_option`])
      return false;

    return (
      <FileUploadContainer>
        <StyledWidgetTitle>
          {formatMessage({ id: "launcherCustomization.upload.subtitle" })}
        </StyledWidgetTitle>
        <ImageUpload
          maxFileSize={2097152}
          max={1}
          acceptType={["png", "jpg", "jpeg", "svg", "heic"]}
          replace={true}
          uploadComponent={
            <UploadComponent className="column" error={!!fileUploadError}>
              {getLogo()}
            </UploadComponent>
          }
          value={uploadedImg}
          onChange={handleUploadImageChange}
        />
        <FileValidatorInfo style={{ color: "#5C5C5C" }}>
          <TinyText type="info">
            {formatMessage({ id: "uploader.restriction.imageType" })}{" "}
            <span style={{ color: theme.customColors.purple[500] }}>
              {`SVG, PNG, JPG, JPEG, HEIC`}
            </span>
          </TinyText>
          <TinyText type="info">
            {formatMessage({ id: "uploader.restriction.imageSize" })}
          </TinyText>
          <TinyText type="info">
            {formatMessage({ id: "uploader.restriction.resolution" })}
            <span>
              {formatMessage({ id: "restriction.resolution.120x120" })}
            </span>
          </TinyText>
        </FileValidatorInfo>
      </FileUploadContainer>
    );
  };

  const pagePermission = useGetPagePermission();
  const { readonly, canEdit } = pagePermission("customization");

  return (
    <div>
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={12} lg={5}>
          <Box sx={{ margin: "15px" }}>
            <div>
              <StyledWidgetTitle>
                {formatMessage({
                  id: "widgetCustomization.launcherIcon.subtitle",
                })}{" "}
                <span className="required">*</span>
              </StyledWidgetTitle>
              <CustomRadioGroup
                readonly={readonly}
                row
                control={control}
                data={_.map(Object.keys(widgetIcons), (key, index) => {
                  const url = widgetIcons[key];
                  return {
                    label: (
                      <img
                        src={url}
                        style={{
                          margin: "10px 0",
                          marginLeft: 8,
                          width: 46,
                          height: 46,
                          ...(url === widgetIcons[`custom_ic_option`] && {
                            position: "relative",
                            scale: "1.4",
                            top: 6,
                          }),
                        }}
                      />
                    ),
                    value: url,
                  };
                })}
                value={getWidgetIcUrl(formData.icon)}
                name="icon"
                onChange={handleChange}
              />
              {renderImageUploader()}
            </div>
            <div>
              <StyledWidgetTitle>
                {formatMessage({
                  id: "widgetCustomization.launcherPosition.subtitle",
                })}
                <span className="required">*</span>
              </StyledWidgetTitle>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <div className="formHeader">
                    {formatMessage({
                      id: "widgetCustomization.launcherPosition.cornerOption",
                    })}
                  </div>
                  <AutoCompleteDropdown
                    readonly={readonly}
                    fullWidth
                    control={control}
                    size={SIZE.MEDIUM}
                    options={[
                      { label: "The bottom right", value: "bottom_right" },
                      { label: "The bottom left", value: "bottom_left" },
                      { label: "The top right", value: "top_right" },
                      { label: "The top left", value: "top_left" },
                    ]}
                    value={formData.position}
                    name={"position"}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="formHeader">
                    {formatMessage({
                      id: "widgetCustomization.launcherPosition.displacement",
                    })}
                  </div>
                  <AutoCompleteDropdown
                    readonly={readonly}
                    fullWidth
                    control={control}
                    size={SIZE.MEDIUM}
                    options={[
                      { label: "None", value: "none" },
                      { label: "Vertically shift", value: "verticallyShift" },
                      {
                        label: "Horizontally shift",
                        value: "horizontallyShift",
                      },
                    ]}
                    value={_.get(formData, "displacement")}
                    name={"displacement"}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="field">
              <StyledWidgetTitle>
                {formatMessage({
                  id: "widgetCustomization.launcherSize.subtitle",
                })}
                <span className="required">*</span>
              </StyledWidgetTitle>
              <div>
                <CustomRadioGroup
                  readonly={readonly}
                  row
                  control={control}
                  value={_.get(formData, "iconSize")}
                  name={"iconSize"}
                  onChange={handleChange}
                  data={[
                    {
                      label: (
                        <div className="startRow">
                          {formatMessage({
                            id: "widgetCustomization.launcherSize.normal",
                          })}{" "}
                          <img
                            src={_.get(pastFormData, "icon", widgetIcons[0])}
                            style={{
                              width: 46,
                              marginLeft: 12,
                            }}
                          />
                        </div>
                      ),
                      value: "normal",
                    },
                    {
                      label: (
                        <div className="startRow">
                          {formatMessage({
                            id: "widgetCustomization.launcherSize.bigger",
                          })}
                          <img
                            src={_.get(pastFormData, "icon", widgetIcons[0])}
                            style={{
                              width: 60,
                              marginLeft: 12,
                            }}
                          />
                        </div>
                      ),
                      value: "bigger",
                    },
                  ]}
                />
              </div>
            </div>

            <div className="field">
              <Button
                variant={VARIANT.OUTLINED}
                color={COLOR.SECONDARY}
                size={SIZE.XL}
                style={{ width: 120, marginRight: 8 }}
                onClick={goBack}
              >
                {formatMessage({ id: "global.btn.back" })}
              </Button>
              {canEdit && (
                <Button
                  color={COLOR.SECONDARY}
                  disabled={!isDirty}
                  onClick={saveConfig}
                  size={SIZE.XL}
                  style={{ width: 120 }}
                >
                  {formatMessage({ id: "global.btn.save" })}
                </Button>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box
            sx={{
              marginLeft: { xs: 0, lg: "32px" },
              position: "relative",
            }}
          >
            <img
              src={require("assets/img/widgetPosition.svg").default}
              style={{ width: "100%" }}
            />
            <img
              src={_.get(pastFormData, "icon", widgetIcons[0])}
              style={{
                position: "absolute",
                width: _.get(formData, "iconSize") == "bigger" ? 60 : 46,
                ...getIconPositionStyle(),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default WidgetLauncher;

import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

export const StyledWidgetButtonContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  border: `1px solid ${theme.customColors.grey[300]}`,
  borderRadius: "3px",
  cursor: "pointer",
  padding: "12px",
  height: "110px",
  "&:hover": {
    border: `2px solid ${theme.customColors.purple[500]}`,
  },
}));

export const StyledAddNewWidgetButton = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  border: `1px dashed ${theme.customColors.grey[300]}`,
  borderRadius: "3px",
  cursor: "pointer",
  color: theme.customColors.grey[500],
  height: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 500,
}));

import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useFetchTeamMember, useFetchTicketList } from "./redux/hooks";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { get, isEmpty, find, filter, isArray } from "lodash-es";
import TicketTable from "./components/TicketTable";
import { apiUrl } from "features/configure";
import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Loading } from "features/common";
import { useParams, useOutletContext, Navigate } from "react-router-dom";
import CustomPagination from "components/CustomPagination/CustomPagination";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { TICKET_FILTER_INITIAL_STATE } from "./redux/fetchTicketList";
import { selectTicketTypeForDropdown } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { fetchTagsAsyncAction } from "features/metadesk/redux/macro/macro.action";
import { selectMacroTags } from "features/metadesk/redux/macro/macro.selector";
import { MenuItem, Box } from "@mui/material";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { Link } from "react-router-dom";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import { supportCenterBaseUrl } from "features/configure";
import { useIntl } from "react-intl";
import { useDebounceEffect } from "components/ConnectWallet/ConnectWallet";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";
import {
  AutoCompleteDropdown,
  EditTagOptionPopover,
} from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { Menu } from "@metacrm/metacrm-material-ui/dist/Menu";
import { selectRemindBarShow } from "./redux/globalState/globalState.selector";

const drawerWidth = "15rem";

const camelCaseToTitle = (camelCase) => {
  const result = camelCase.replace(/([A-Z])/g, " $1");

  return result
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const TicketList = ({ ticketStatus }) => {
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [channelOptions, setChannelOptions] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [getSourceLoading, setGetSourceLoading] = useState(false);
  const status = ticketStatus || "new";
  const { memberList } = useFetchTeamMember();
  const { socket } = useConnectWallet();
  // const [filterData, setFilterData] = useState({
  //   addressStatus: "",
  //   channel: [""],
  //   issue: "",
  //   assigneeOrCollaborator: "",
  //   searchTags: [""],
  // });

  const {
    ticketList,
    fetchTicketList,
    ticketCounts,
    fetchTicketListPending,
    updateTicketListFilter,
    ticketListFilter,
    statsCounts,
  } = useFetchTicketList();

  const { formatMessage } = useIntl();
  const page = _.get(ticketListFilter, `page`, 1);
  const orderData = _.get(ticketListFilter, `orderData`);
  const remindBarShow = useSelector(selectRemindBarShow);
  // console.log("searchTags: ", searchTags);
  const searchText = _.get(ticketListFilter, "searchText", "");
  const ticketTypeForDropdown = useSelector(selectTicketTypeForDropdown);
  const tagOptions = useSelector(selectMacroTags);
  const PAGE_COUNT = 20;

  const channelFilterNumber = useMemo(
    () =>
      isEmpty(ticketListFilter.channel) ? 0 : ticketListFilter.channel.length,
    [ticketListFilter.channel]
  );

  const searchTagsFilterNumber = useMemo(
    () =>
      isEmpty(ticketListFilter.searchTags)
        ? 0
        : ticketListFilter.searchTags.length,
    [ticketListFilter.searchTags]
  );

  const addressFilterNumber = useMemo(
    () => (ticketListFilter.addressStatus ? 1 : 0),
    [ticketListFilter.addressStatus]
  );

  const issueFilterNumber = useMemo(
    () => (isEmpty(ticketListFilter.issue) ? 0 : ticketListFilter.issue.length),
    [ticketListFilter.issue]
  );

  const assigneeFilterNumber = useMemo(
    () =>
      isEmpty(ticketListFilter.assigneeOrCollaborator)
        ? 0
        : ticketListFilter.assigneeOrCollaborator.length,
    [ticketListFilter.assigneeOrCollaborator]
  );

  const topFilterNumber = useMemo(
    () =>
      channelFilterNumber +
      searchTagsFilterNumber +
      addressFilterNumber +
      issueFilterNumber +
      assigneeFilterNumber,
    [
      channelFilterNumber,
      addressFilterNumber,
      issueFilterNumber,
      assigneeFilterNumber,
      searchTagsFilterNumber,
    ]
  );

  const handleChangeCheckbox = (e, filterKey) => {
    e.keepOpen = true;
    if (e.checked) {
      // 要是是選中
      if (e.value === "") {
        // 選全選的話(空字串) 就只要設定全選進入結果就好
        updateTicketListFilter({
          ...ticketListFilter,
          page: 1,
          [filterKey]: [],
        });
      } else {
        // 選全選以外的 就要把空字串過去料 並把舊的值 新的值都放進去
        const newFilter = ticketListFilter[filterKey].filter(
          (value) => value !== ""
        );

        updateTicketListFilter({
          ...ticketListFilter,
          page: 1,
          [filterKey]: [...newFilter, e.value],
        });
      }
    } else {
      // 要是是取消
      const newValue = ticketListFilter[filterKey].filter(
        (value) => value !== e.value
      );
      updateTicketListFilter({
        ...ticketListFilter,
        page: 1,
        [filterKey]: newValue,
      });
    }
  };

  const handleChangeRadioBtn = (e, filterKey) => {
    e.keepOpen = true;
    updateTicketListFilter({
      ...ticketListFilter,
      page: 1,
      [filterKey]: e.value,
    });
  };

  const handleGetSource = async () => {
    setGetSourceLoading(true);
    try {
      let { data } = await axios.get(
        `${apiUrl}/api/ticket/${entityName}/sources`
      );
      const transformName = ["supportCenter", "discord"];
      const formatData = data.map((item) => ({
        label: transformName.includes(item.name)
          ? camelCaseToTitle(item.name)
          : item.name,
        value: item._id,
      }));

      setChannelOptions([
        { label: "All", isAllOption: true, value: "" },
        ...formatData,
      ]);
      setChannelData(formatData);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setGetSourceLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchTagsAsyncAction({ entityName: entityName }));
    handleGetSource();
  }, []);

  useEffect(() => {
    if (_.get(ticketListFilter, "ticketStatus") === ticketStatus) return;
    if (page != 1) updateTicketListFilter({ page: 1, ticketStatus });
    else updateTicketListFilter({ ticketStatus });
  }, [ticketStatus]);

  useEffect(() => {
    setLoading(false);
  }, [ticketList]);

  useDebounceEffect(
    () => {
      fetchTicketList({
        entityName,
        filter: ticketListFilter,
      });
    },
    500,
    [ticketListFilter],
    () => setLoading(true)
  );

  const renderNoDataComponent = () => {
    return (
      <Box display="flex" justifyContent="center" mt="6%">
        <Box width="260px">
          <Box
            component="img"
            src={require(`assets/img/noTickets.svg`).default}
          ></Box>
          <Box>{formatMessage({ id: "ticketTable.data.empty" })}:</Box>
          <Link target="_blank" to={`${supportCenterBaseUrl}/${entityName}`}>
            {supportCenterBaseUrl}/{entityName}
          </Link>
        </Box>
      </Box>
    );
  };

  const itemList = useMemo(
    () => [
      {
        children: <>Channel</>,
        filterNumber: channelFilterNumber,
        subMenu: [
          {
            children: <>All</>,
            value: "",
            type: "checkbox",
            checked: isEmpty(ticketListFilter.channel),
            func: (e) => handleChangeCheckbox(e, "channel"),
          },
          ...channelData.map((item) => ({
            children: <>{item.label}</>,
            value: item.value,
            type: "checkbox",
            checked: ticketListFilter.channel.some(
              (channelValue) => channelValue === item.value
            ),
            func: (e) => handleChangeCheckbox(e, "channel"),
          })),
        ],
      },
      {
        children: <>Tag</>,
        filterNumber: searchTagsFilterNumber,
        subMenu: [
          {
            children: <>All</>,
            value: "",
            type: "checkbox",
            checked: isEmpty(ticketListFilter.searchTags),
            func: (e) => handleChangeCheckbox(e, "searchTags"),
          },
          ...tagOptions.map((item) => ({
            children: <>{item.value}</>,
            value: item._id,
            type: "checkbox",
            backgroundColor: item.color,
            checked: ticketListFilter.searchTags.some(
              (tagValue) => tagValue === item._id
            ),
            func: (e) => handleChangeCheckbox(e, "searchTags"),
          })),
        ],
      },
      {
        children: <>Address Status</>,
        type: "radioGroup",
        value: ticketListFilter.addressStatus,
        onRadioChange: (e) => handleChangeRadioBtn(e, "addressStatus"),
        filterNumber: addressFilterNumber,
        subMenu: [
          {
            children: <>All</>,
            value: "",
            type: "radio",
            checked: ticketListFilter.addressStatus === "",
          },
          {
            children: <>Has Address</>,
            value: "withAddress",
            type: "radio",
            checked: ticketListFilter.addressStatus === "withAddress",
          },
          {
            children: <>No Address</>,
            value: "withoutAddress",
            type: "radio",
            checked: ticketListFilter.addressStatus === "withoutAddress",
          },
        ],
      },
      {
        children: <>Issue</>,
        filterNumber: issueFilterNumber,
        subMenu: [
          {
            children: <>All</>,
            value: "",
            type: "checkbox",
            checked: isEmpty(ticketListFilter.issue),
            func: (e) => handleChangeCheckbox(e, "issue"),
          },
          ...ticketTypeForDropdown.map((item) => ({
            children: <>{item.label}</>,
            value: item.value,
            type: "checkbox",
            checked: ticketListFilter.issue.some(
              (issueValue) => issueValue === item.value
            ),
            func: (e) => handleChangeCheckbox(e, "issue"),
          })),
        ],
      },
      {
        children: <>Assignee or Collaborator</>,
        filterNumber: assigneeFilterNumber,
        subMenu: [
          {
            children: <>All</>,
            value: "",
            type: "checkbox",
            checked: isEmpty(ticketListFilter.assigneeOrCollaborator),
            func: (e) => handleChangeCheckbox(e, "assigneeOrCollaborator"),
          },
          ...memberList.map((item) => ({
            children: <>{item.name}</>,
            value: item._id,
            type: "checkbox",
            checked: ticketListFilter.assigneeOrCollaborator.some(
              (assigneeValue) => assigneeValue === item._id
            ),
            func: (e) => handleChangeCheckbox(e, "assigneeOrCollaborator"),
          })),
        ],
      },
    ],
    [
      addressFilterNumber,
      assigneeFilterNumber,
      channelData,
      channelFilterNumber,
      handleChangeCheckbox,
      handleChangeRadioBtn,
      issueFilterNumber,
      memberList,
      searchTagsFilterNumber,
      tagOptions,
      ticketListFilter.addressStatus,
      ticketListFilter.assigneeOrCollaborator,
      ticketListFilter.channel,
      ticketListFilter.issue,
      ticketListFilter.searchTags,
      ticketTypeForDropdown,
    ]
  );

  const renderFilter = () => {
    return (
      <div
        style={{
          paddingTop: 16,
        }}
      >
        <div className="startRow" style={{ flexWrap: "wrap" }}>
          <Box mr={1}>
            <InputField
              size={SIZE.LARGE}
              startAdornment={
                <i className="meta-crm-icon-ic_search font-size-14" />
              }
              placeholder="Search by Address / Discord Handle"
              value={searchText}
              onChange={(e) => {
                updateTicketListFilter({
                  page: 1,
                  searchText: e.target.value,
                });
              }}
            />
          </Box>

          <Box mr={1}>
            <Menu
              overflow="auto"
              position="initial"
              itemList={itemList}
              topFilterNumber={topFilterNumber}
              isFilter
            />
          </Box>

          <Button
            variant={VARIANT.TEXT}
            style={{ padding: 10 }}
            size={SIZE.SMALL}
            onClick={() => {
              updateTicketListFilter({
                ...TICKET_FILTER_INITIAL_STATE.ticketListFilter,
                ticketStatus: ticketListFilter.ticketStatus,
              });
            }}
            // onClick={() => {
            //   updateTicketListFilter({
            //     ...TICKET_FILTER_INITIAL_STATE.ticketListFilter,
            //     ticketStatus: ticketListFilter.ticketStatus,
            //   });
            // }}
          >
            {formatMessage({ id: "global.filter.clean" })}
          </Button>
        </div>
      </div>
    );
  };
  if (!ticketStatus)
    return (
      <Navigate
        to={parseCustomDomainUrl(
          isCustomDomain,
          entityName,
          "/service/ticket/new"
        )}
        replace
      />
    );

  return (
    <main>
      <div className="title">
        {formatMessage({ id: "global.header.tickets" })} -{" "}
        {_.upperFirst(status)}
      </div>
      {!getSourceLoading && renderFilter()}
      <div style={{ marginTop: 25, position: "relative" }}>
        <Loading
          open={fetchTicketListPending || loading}
          fullScreen={false}
          white
        />
        {ticketList && (
          <TicketTable
            orderData={orderData}
            noDataComponent={
              !_.get(statsCounts, `ticket.All`) && renderNoDataComponent()
            }
            containerStyle={{
              height: `calc(68vh - ${remindBarShow ? 60 : 0}px)`,
            }}
            setOrderData={(v) => updateTicketListFilter({ orderData: v })}
            isCustomDomain={isCustomDomain}
            ticketList={ticketList}
            entityName={entityName}
          />
        )}
      </div>
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={ticketCounts}
        page={page}
        setPage={(v) => updateTicketListFilter({ page: v })}
      />
    </main>
  );
};

export default TicketList;

import React, { memo, useContext, useEffect, useState } from "react";
import { PerformanceContext } from "./context/PerformanceProvider";
import { useIntl } from "react-intl";
import { Box, useTheme } from "@mui/material";
import DateRangeSelect from "components/DateRange/DateRangeSelect.js";
import {
  Button,
  VARIANT,
  SIZE,
} from "@metacrm/metacrm-material-ui/dist/Button";
import CustomToggle, {
  StyledToggleButton,
} from "components/CustomToggle/CustomToggle";
import useNavigateEntity from "hooks/useNavigateEntity";
import useMarketModule from "hooks/useMarketModule";
import _ from "lodash";
function PerformanceHeader({ objectiveData, isShowObjective }) {
  const { startDate, setStartDate, endDate, setEndDate, range, setRange } =
    useContext(PerformanceContext);

  const theme = useTheme();
  const { attributionConnect } = useMarketModule();
  const { navigateEntity } = useNavigateEntity();
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="betweenRow" style={{ alignItems: "flex-start" }}>
        <div className={"title"}>
          {formatMessage({ id: "global.taskStatus.analysis" })}
        </div>
      </div>
      <Box
        sx={{ alignItems: "center", bgcolor: "#fff", mt: 2, p: "6px 12px" }}
        className="betweenRow"
      >
        <Box></Box>
        <Box className="endRow">
          <CustomToggle
            data={[
              { text: "1 Day", value: 1 },
              { text: "7 Days", value: 7 },
              { text: "30 Days", value: 30 },
              { text: "All", value: "all" },
            ]}
            value={range}
            onChange={(e, newValue) => {
              setRange(newValue);
            }}
          />
          <DateRangeSelect
            value={[
              {
                startDate: range == "all" ? new Date() : startDate,
                endDate: range == "all" ? new Date() : endDate,
                key: "selection",
              },
            ]}
            custom={
              <StyledToggleButton
                className={range == "custom" ? "Mui-selected" : ""}
              >
                Custom
              </StyledToggleButton>
            }
            onChange={(value) => {
              setRange("custom");
              setStartDate(value[0]?.startDate || undefined);
              setEndDate(value[0]?.endDate || undefined);
            }}
          />
          {isShowObjective && (
            <Button
              variant={VARIANT.OUTLINED}
              size={SIZE.MEDIUM}
              disabled={!attributionConnect}
              onClick={() => {
                navigateEntity("/analytics/website/objective");
              }}
            >
              {formatMessage({
                id: `global.btn.${
                  !_.isEmpty(objectiveData)
                    ? "viewObjective"
                    : "createObjective"
                }`,
              })}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default memo(PerformanceHeader);

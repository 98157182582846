/* eslint-disable react/prop-types */
import React, { useState, forwardRef, useContext } from "react";

import { InputField } from "@metacrm/metacrm-material-ui/dist/InputField";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Linkify from "linkify-react";
import moment from "moment";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  Button,
  SIZE,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";

import { linkifyOptions } from "../utils";
import { StyledSendBtn } from "../AiChatDialog.styles";
import useNavigateEntity from "hooks/useNavigateEntity";
import { AdamTag } from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import { StyledDatePicker } from "features/metadesk/campaign/createCampaign/component/DateTimePicker.styles";
import AdamCharts from "../component/AdamCharts";
import { AdamContext } from "../Adam.context";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useOutletContext } from "react-router-dom";
import { getMinTime } from "features/helpers/utils";

const supportExtension = [".jpg", ".jpeg", ".png", ".svg"];
const fileSizeLimit = 10 * 1024 * 1024;

function MessageType({ msgInfo, active, handleSubmit }) {
  const [entityName] = useOutletContext();
  const { formatMessage } = useIntl();
  const { linkToEntity } = useNavigateEntity();
  const [msgLoading, setMsgLoading] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();

  const {
    form,
    handleInput,
    setStep,
    audienceNumber,
    handleCampaignDraft,
    campaignId,
  } = useContext(AdamContext);

  const theme = useTheme();
  // eslint-disable-next-line react/prop-types
  const { type, data, name, _id } = msgInfo;

  const validateFile = (file) => {
    if (!supportExtension.some((extension) => file.name.endsWith(extension))) {
      return false;
    }
    if (file.size > fileSizeLimit) {
      return false;
    }
    return true;
  };

  const handleChooseFile = async (event, url) => {
    const [file] = event.target.files;
    event.target.value = null;
    if (validateFile(file)) {
      let postFormData = new FormData();
      postFormData.append("campaign", file);
      postFormData.append("entityName", entityName);

      try {
        setMsgLoading((prev) => true);
        const responseData = await axios.post(`${apiUrl}/${url}`, postFormData);
        setStep((prevStep) => prevStep + 1);
        handleInput({
          target: {
            name: "campaignImageUrl",
            value: responseData.data.campaign[0],
          },
        });
      } catch (error) {
        console.log("error: ", error);
        dispatchSnackbar({
          error,
        });
      } finally {
        setMsgLoading(false);
      }
    }
  };

  function handleSend() {
    if (form[name].trim() === "") return;
    setStep((prevStep) => prevStep + 1);
  }

  if (msgLoading) {
    return (
      <Box>
        <LoadingComponent width={20} height={20} withText={false} />
      </Box>
    );
  }

  if (type === "content")
    return <Linkify options={linkifyOptions}>{data}</Linkify>;

  if (type === "image") {
    return (
      <>
        {form.campaignImageUrl && (
          <Box
            component="img"
            src={form.campaignImageUrl}
            alt="image"
            sx={{ maxWidth: "465px" }}
          />
        )}
      </>
    );
  }

  const renderButtons = (optionItem) => {
    if (optionItem.btnType === "file") {
      return (
        <>
          <Box
            component="input"
            id={`img-upload-${_id}`}
            type="file"
            accept=".jpg,.jpeg,.png,.svg"
            sx={{
              display: "none",
            }}
            onChange={(e) => {
              handleChooseFile(e, optionItem.apiUrl);
            }}
          ></Box>
          <Button
            key={optionItem.label}
            variant={VARIANT.OUTLINED}
            size={SIZE.SMALL}
            disabled={!active}
            sx={{
              marginRight: "8px",
              marginBottom: "8px",
              borderRadius: "8px",
            }}
            onClick={() =>
              document?.getElementById(`img-upload-${_id}`)?.click()
            }
          >
            {optionItem.label}
          </Button>
        </>
      );
    } else {
      return (
        <Button
          key={optionItem.label}
          variant={VARIANT.OUTLINED}
          size={SIZE.SMALL}
          disabled={!active}
          sx={{
            marginRight: "8px",
            marginBottom: "8px",
            borderRadius: "8px",
          }}
          onClick={
            optionItem.func
              ? optionItem.func
              : () => handleSubmit(optionItem.label)
          }
        >
          {optionItem.label}
        </Button>
      );
    }
  };

  if (type === "options") {
    return (
      <>
        {data.map((optionItem) => {
          return renderButtons(optionItem);
        })}
      </>
    );
  }

  if (type === "tags") {
    return (
      <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
        <Box mr={1} mb={1}></Box>
        {data.map((tagItem) => (
          <AdamTag
            key={tagItem}
            sx={{ marginRight: "8px", marginBottom: "8px" }}
          >
            {tagItem}
          </AdamTag>
        ))}
      </Box>
    );
  }

  if (type === "graphs") return <AdamCharts data={data} />;

  if (type === "input" || type === "multiInput")
    return (
      <Box minWidth={"40vw"} mt={1}>
        {!active ? (
          <Box color={"#777"}>{form[name]}</Box>
        ) : (
          <InputField
            borderRadius={20}
            placeholder={data}
            name={name}
            value={form[name]}
            multiline={type === "multiInput" ? true : false}
            rows={type === "multiInput" ? 3 : 1}
            onChange={handleInput}
            onEnter={handleSend}
            autoFocus={active}
            sx={{ "& .MuiInputBase-root": { paddingRight: "5.5px" } }}
            endAdornment={
              <StyledSendBtn onClick={handleSend}>
                <i className="meta-crm-icon-ic_send font-size-16" />
              </StyledSendBtn>
            }
          ></InputField>
        )}
      </Box>
    );

  if (type === "campaignResult")
    return (
      <>
        <Box
          sx={{
            border: `3px solid ${theme.customColors.purple[300]}`,
            borderRadius: "8px",
          }}
          backgroundColor={theme.customColors.white}
          maxWidth={"345px"}
        >
          <Box
            padding={"12px 23px 18px"}
            borderBottom={`3px solid ${theme.customColors.purple[300]}`}
          >
            <Box mb={1} fontSize={"12px"} color={theme.customColors.grey[500]}>
              Audience
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              fontWeight={"700"}
              fontSize={"16px"}
            >
              <Box color={theme.customColors.purple[500]} mr="5px">
                "{audienceNumber}"
              </Box>
              <Box>Address User</Box>
            </Box>
          </Box>
          <Box padding={"14px 23px 45px"}>
            <Box fontSize={"12px"} color={theme.customColors.grey[500]} mb={1}>
              Campaign Content
            </Box>
            <Box
              fontSize={"14px"}
              mb={1}
              color={theme.customColors.grey[800]}
              fontWeight={700}
            >
              {form.campaignName}
            </Box>
            <Box color={theme.customColors.grey[700]} mb={1} fontSize={"12px"}>
              {form.campaignDescription}
            </Box>
            <Box
              component={"img"}
              src={form.campaignImageUrl}
              width={"100%"}
              mb={1}
            ></Box>
            <Box color={theme.customColors.grey[500]} fontSize={"12px"}>
              {moment(form.campaignScheduledTime).format("DD MMM YYYY")}
            </Box>
          </Box>
        </Box>
        <Box
          component={Link}
          to={linkToEntity(`/marketing/campaign/create/${campaignId}?tab=2`)}
          color={theme.customColors.purple[500]}
          mt={"10px"}
          display={"block"}
          paddingLeft={"4px"}
        >
          View your campaign information
        </Box>
      </>
    );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      variant={VARIANT.OUTLINED}
      size={SIZE.SMALL}
      sx={{
        borderRadius: "8px",
      }}
    >
      Select a Date
    </Button>
  ));

  if (type === "time")
    return (
      <>
        {active ? (
          <StyledDatePicker
            selected={form[name]}
            onCalendarClose={handleCampaignDraft}
            onChange={(date) => handleInput({ target: { name, value: date } })}
            customInput={<ExampleCustomInput />}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            popperPlacement="right-end"
            dateFormat="MM/d/yyyy h:mm aa"
            minDate={new Date()}
            minTime={getMinTime(form[name])}
            maxTime={new Date(0, 0, 0, 23, 59, 59)}
          />
        ) : (
          <Box color={"#777"}>
            {moment(form[name]).format("MM/d/yyyy h:mm a")}
          </Box>
        )}
      </>
    );

  return null;
}

export default MessageType;

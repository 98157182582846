import React, { useState, useMemo } from "react";
import {
  StyledContainerTitle,
  StyledInputContainer,
  StyledWhiteAccordion,
  StyledWhiteAccordionSummary,
  StyledWhiteCardContainer,
} from "../createCampaign/component/CampaignCreation.styles";
import { AccordionDetails, Box } from "@mui/material";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import IconTooltip, {
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import ImageUpload from "features/metadesk/components/ImageUpload";
import ImagePreview from "features/metadesk/components/ImagePreview";
import InputField from "@metacrm/metacrm-material-ui/dist/InputField";
import { useIntl } from "react-intl";
import { Button, IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { get, upperFirst } from "lodash-es";
import EditNotificationPreviewContainer from "./EditNotificationPreviewContainer";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/snackbar";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";
import {
  countEngagementLink,
  countEngagementReach,
} from "../createCampaign/utils";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { StyledContentItemTitle } from "./EngagementEdit.styles";
import DateTimePicker from "features/metadesk/campaign/createCampaign/component/DateTimePicker";
import moment from "moment";
import useGetPagePermission from "hooks/useGetPagePermission";
import useNavigateEntity from "hooks/useNavigateEntity";
import { aiEnhancedUserHolderMap } from "../createCampaign/constants";

const formDataInit = {
  engagementTitle: "",
  engagementDescription: "",
  engagementImages: [],
  startFrom: "",
};

const channelMapping = {
  widget: "MetaCRM Widget",
  debankHi: "Debank Hi",
  xmtp: "XMTP",
  push: "Push Protocol",
};

export default function EngagementEdit({
  campaignContentItem,
  tabIndex,
  onFieldChange,
  startFrom,
  onChangeTime,
  status,
  name,
}) {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo },
    clearErrors,
    getValues,
    setValue,
    reset,
    getFieldState,
    trigger,
  } = useFormContext();
  const { append, replace } = useFieldArray({
    control: control,
    name: "campaignContentSets",
  });

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [entityName] = useOutletContext();
  const channels = useMemo(() => {
    return get(campaignContentItem, "engagementDistributionSettings").map(
      (item) => channelMapping[item.channel]
    );
  }, [campaignContentItem]);

  const handleFieldChange = ({ event, fieldName }) => {
    onFieldChange({
      tabIndex,
      name: fieldName,
      value: event.target.value,
    });
  };

  const setImgs = (value) => {
    onFieldChange({ tabIndex, name: "engagementImages", value });
  };

  const handleUploadImg = async (list) => {
    setImgs(list);
    await uploadFile(list[0].file);
  };

  const uploadFile = async (file) => {
    if (file) {
      let imgFormData = new FormData();

      imgFormData.append("campaign", file);
      imgFormData.append("entityName", entityName);
      try {
        const {
          data: { campaign },
        } = await axios.post(`${apiUrl}/api/gcp/uploadCampaign`, imgFormData);
        setImgs([campaign[0]]);

        onFieldChange({ tabIndex, name: "engagementImages", value: campaign });
      } catch (error) {
        setImgs([]);
        console.error(error);
        dispatch(
          enqueueSnackbar({
            message: get(error, "response.data.error", "error"),
            options: {
              variant: "error",
            },
          })
        );
      } finally {
      }
    }
  };
  const { engagementDistributionSettings } = campaignContentItem;

  const [engagementLinkAmount, engagementReachAmount] = useMemo(() => {
    return [
      countEngagementLink(engagementDistributionSettings),
      countEngagementReach(engagementDistributionSettings),
    ];
  }, [engagementDistributionSettings]);
  const { navigateEntity } = useNavigateEntity();

  const pagePermission = useGetPagePermission();
  const isReadOnly =
    status !== "scheduled" || pagePermission("campaigns").readonly;

  const renderImageArea = () => {
    if (!isReadOnly) {
      return (
        <div
          style={{
            borderRadius: 3,
            border: "1px dashed #E6E6E6",
          }}
        >
          <div style={{ margin: 35 }} className="column">
            <div style={{ margin: 35 }} className="column">
              {campaignContentItem.engagementImages.length > 0 ? (
                <ImagePreview
                  imgs={campaignContentItem.engagementImages}
                  setImgs={setImgs}
                  size={200}
                />
              ) : (
                <>
                  <i
                    className="meta-crm-icon-ic_uploadImage font-size-72"
                    style={{
                      color: "#E6E6E6",
                      marginBottom: 12,
                    }}
                  />
                  <ImageUpload
                    multiple={false}
                    acceptType={["png", "jpg", "heic", "jpeg"]}
                    uploadComponent={
                      <Button color={COLOR.SECONDARY}>
                        {formatMessage({ id: "global.btn.uploadImage" })}
                      </Button>
                    }
                    value={campaignContentItem.engagementImages}
                    onChange={handleUploadImg}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Box>
          <Box
            component="img"
            src={campaignContentItem.engagementImages[0]}
            width="285px"
            sx={{ objectFit: "cover" }}
          ></Box>
        </Box>
      );
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box mb="20px" />
          <StyledWhiteCardContainer>
            <Box>
              <Box
                p="20px"
                pb="8px"
                sx={{ color: "#555", fontSize: "14px", fontWeight: 700 }}
              >
                Promotion Type
              </Box>
              <div className="mhr" style={{ margin: "12px 0" }} />
              <Box
                p="20px"
                pt="8px"
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <Box sx={{ color: "#555", fontSize: "12px", mr: "24px" }}>
                  <Box mb="13px">Type</Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {campaignContentItem.type === "flyer" ? (
                      <>
                        <img
                          src={
                            require("assets/img/ic_promotype_flyer.svg").default
                          }
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                            borderRadius: "50%",
                          }}
                        />
                        Flyer
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            require("assets/img/ic_promotype_widget.svg")
                              .default
                          }
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                            borderRadius: "50%",
                          }}
                        />
                        Widget
                      </>
                    )}
                  </Box>
                </Box>
                <img
                  src={require("assets/img/arrowRight.svg").default}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "24px",
                    borderRadius: "50%",
                  }}
                />
                <Box sx={{ color: "#555", fontSize: "12px" }}>
                  <Box mb="13px">Goal</Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {campaignContentItem.goal === "onchain" ? (
                      <>
                        <img
                          src={
                            require("assets/img/ic_goal_onchain.svg").default
                          }
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                            borderRadius: "50%",
                          }}
                        />
                        Tracking on-chain behavior
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            require("assets/img/ic_goal_message.svg").default
                          }
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                            borderRadius: "50%",
                          }}
                        />
                        Delivery of messages only
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </StyledWhiteCardContainer>
          <StyledWhiteAccordion defaultExpanded disableGutters={true}>
            <StyledWhiteAccordionSummary
              expandIcon={
                <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: 0, paddingRight: "20px", margin: 0 }}
            >
              <StyledContainerTitle>Content</StyledContainerTitle>
            </StyledWhiteAccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <div className="mhr" style={{ margin: 0 }}></div>
              <StyledInputContainer>
                <div className="formField">
                  {!isReadOnly ? (
                    <InputField
                      maxLength={50}
                      name={`campaignContentSets[${tabIndex}].engagementTitle`}
                      control={control}
                      title={
                        <div className="formHeader">
                          {formatMessage({ id: "global.column.title" })}{" "}
                          <span className="required">*</span>
                        </div>
                      }
                      errors={get(
                        errorsInfo,
                        `campaignContentSets.[${[
                          tabIndex,
                        ]}].engagementTitle.message`
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: `Title ${formatMessage({
                            id: "global.error.required",
                          })}`,
                        },
                        minLength: {
                          value: 5,
                          message: formatMessage({
                            id: "macrosSetting.macrosCreate.addQuestionMinError",
                          }),
                        },
                        maxLength: {
                          value: 50,
                          message: formatMessage({
                            id: "macrosSetting.macrosCreate.addQuestionMinError",
                          }),
                        },
                      }}
                      onChange={(event) =>
                        handleFieldChange({
                          event,
                          fieldName: "engagementTitle",
                        })
                      }
                      value={campaignContentItem.engagementTitle}
                      placeholder={formatMessage({ id: "global.column.title" })}
                    />
                  ) : (
                    <>
                      <StyledContentItemTitle>
                        {formatMessage({ id: "global.column.title" })}
                      </StyledContentItemTitle>
                      <Box className="wordBreak">
                        {campaignContentItem.engagementTitle}
                      </Box>
                    </>
                  )}
                </div>
                <div className="formField">
                  {!isReadOnly ? (
                    <InputField
                      title={
                        <div className="formHeader">
                          {formatMessage({ id: "global.column.description" })}{" "}
                          <span className="required">*</span>
                        </div>
                      }
                      name={`campaignContentSets[${tabIndex}].engagementDescription`}
                      control={control}
                      maxLength={1500}
                      errors={get(
                        errorsInfo,
                        `campaignContentSets.[${[
                          tabIndex,
                        ]}].engagementDescription.message`
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: "Description is Required",
                        },
                      }}
                      minRows={8}
                      multiline={true}
                      onChange={(event) =>
                        handleFieldChange({
                          event,
                          fieldName: "engagementDescription",
                        })
                      }
                      value={campaignContentItem.engagementDescription}
                      placeholder={formatMessage({
                        id: "global.column.description",
                      })}
                    />
                  ) : (
                    <>
                      <StyledContentItemTitle>
                        {formatMessage({ id: "global.column.description" })}
                      </StyledContentItemTitle>
                      <Box className="wordBreak">
                        {campaignContentItem.engagementDescription}
                      </Box>
                    </>
                  )}
                </div>
                <div className="formField">
                  <StyledContentItemTitle>
                    {formatMessage({ id: "uploader.instruction.image" })}
                  </StyledContentItemTitle>
                  {renderImageArea()}
                </div>
              </StyledInputContainer>
            </AccordionDetails>
          </StyledWhiteAccordion>
          <StyledWhiteAccordion defaultExpanded disableGutters={true}>
            <StyledWhiteAccordionSummary
              expandIcon={
                <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: 0, paddingRight: "20px", margin: 0 }}
            >
              <StyledContainerTitle>Distribution Setting</StyledContainerTitle>
            </StyledWhiteAccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <div className="mhr" style={{ margin: 0 }}></div>
              {engagementDistributionSettings.map((item, index) => (
                <StyledInputContainer>
                  <Grid container style={{ marginBottom: "16px" }}>
                    <Grid item md={3}>
                      <StyledContentItemTitle>
                        {formatMessage({ id: "campaign.edit.marketChannel" })}
                      </StyledContentItemTitle>
                      <Box display={"flex"} alignItems="center">
                        {upperFirst(item.channel)}
                        <IconButton
                          sx={{ marginLeft: "4px" }}
                          size={18}
                          color={"#555"}
                          onClick={(e) => {
                            navigateEntity(
                              `/marketing/dashboard/campaign?search=${name}&promotype=${campaignContentItem.type}&channel=${item.channel}`
                            );

                            e.stopPropagation();
                          }}
                        >
                          <i className="meta-crm-icon-ic_faq font-size-18" />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <StyledContentItemTitle>
                        {formatMessage({
                          id: "engagementCampaign.distributionSetting.audience.all",
                        })}
                      </StyledContentItemTitle>
                      <Box style={{ color: "#000" }}>
                        {item.tag
                          ? "User Tag"
                          : item.userGroup
                          ? "Segment"
                          : item.aiEnhancedUserGroup
                          ? "AI-Enhanced User"
                          : "All"}
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <StyledContentItemTitle>
                        {formatMessage({ id: "campaign.attributes.subtotal" })}
                      </StyledContentItemTitle>
                      <Box style={{ color: "#000" }}>
                        <span
                          style={{
                            color: "#7B61FF",
                            marginRight: "4px",
                            fontWeight: 700,
                          }}
                        >
                          {item.estimatedAmount}
                        </span>
                        {formatMessage({ id: "campaign.type.estimatedReach" })}
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <StyledContentItemTitle>
                        {formatMessage({ id: "campaigns.header.trackingLink" })}
                      </StyledContentItemTitle>
                      <Box sx={{ color: "#7B61FF", fontWeight: 700 }}>
                        {item.trackingLink ? 1 : 0}
                      </Box>
                    </Grid>
                  </Grid>

                  {(item.tag || item.userGroup) && (
                    <Grid container style={{ marginBottom: "16px" }}>
                      <Grid item md={12}>
                        <StyledContentItemTitle>
                          {item.tag ? "User Tag" : "Segment"}
                        </StyledContentItemTitle>
                        <Box display={"flex"} alignItems="center">
                          {item?.tag?.value || item?.userGroup?.name}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {item.aiEnhancedUserGroup && (
                    <Grid container style={{ marginBottom: "16px" }}>
                      <Grid item md={3}>
                        <StyledContentItemTitle>
                          Enhanced User
                        </StyledContentItemTitle>
                        <Box display={"flex"} alignItems="center">
                          {item?.aiEnhancedUserGroupName}
                        </Box>
                      </Grid>
                      <Grid item md={9}>
                        <StyledContentItemTitle>
                          Hoders of
                        </StyledContentItemTitle>
                        <Box display={"flex"} alignItems="center">
                          {aiEnhancedUserHolderMap[
                            item?.aiEnhancedUserGroupName
                          ].map((holderImg) => {
                            return (
                              <img
                                src={holderImg}
                                alt="item.name"
                                style={{
                                  width: 18,
                                  height: 18,
                                  marginRight: "4px",
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </StyledInputContainer>
              ))}
            </AccordionDetails>
          </StyledWhiteAccordion>
          <StyledWhiteAccordion defaultExpanded disableGutters={true}>
            <StyledWhiteAccordionSummary
              expandIcon={
                <i className="meta-crm-icon-ic_arrowLineDown font-size-18" />
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ padding: 0, paddingRight: "20px", margin: 0 }}
            >
              <StyledContainerTitle>
                {formatMessage({ id: "global.taskStatus.scheduled" })}
              </StyledContainerTitle>
            </StyledWhiteAccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <div className="mhr" style={{ margin: 0 }}></div>
              <StyledInputContainer>
                <p style={{ color: "#555555" }}>
                  <span style={{ marginRight: "4px" }}>
                    {formatMessage({ id: "global.labelName.website" })}
                  </span>
                  <IconTooltip
                    customColor="#555555"
                    content={formatMessage({
                      id: "campaign.startDate.tooltip",
                    })}
                  />
                </p>
                {!isReadOnly ? (
                  <DateTimePicker
                    value={startFrom}
                    onChange={onChangeTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MM/d/yyyy h:mm aa"
                    showIcon
                    icon={
                      <Box
                        component="i"
                        color={(theme) => theme.customColors.grey[700]}
                        className="meta-crm-icon-ic_calendar font-size-18"
                      />
                    }
                    placeholderText={formatMessage({
                      id: "campaignBuild.secondStep.expirationDate",
                    })}
                  ></DateTimePicker>
                ) : (
                  <Box>{moment(startFrom).format("DD MMM YYYY , HH:mm")}</Box>
                )}
              </StyledInputContainer>
            </AccordionDetails>
          </StyledWhiteAccordion>
        </Grid>
        <Grid item xs={4}>
          <Box mb="20px" />
          <EditNotificationPreviewContainer
            data={campaignContentItem}
            imgs={campaignContentItem.engagementImages}
            startFrom={startFrom}
          />
        </Grid>
      </Grid>
    </div>
  );
}

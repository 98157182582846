import React from "react";

import {
  StyledTrackingPaper,
  StyledTrackingTitle,
  StyledTrackingContainer,
  StyledTrackingStepTitle,
} from "./TrackignSetting.styles";
import {
  StyledSettingWidgetStepContainerOutSide,
  StyledSettingWidgetStepDescribe,
} from "../IntegrationWidgetSetting.styles";
import { useIntl } from "react-intl";
import ReferralRules from "./ReferralRules";
import { StyledLink } from "../../Customization/InterfaceTheme/InterfaceTheme.styles";
const TrackingDocument = ({ setSelectedTab, referralEnabled }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledTrackingPaper>
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle>
            {formatMessage({
              id: "marketingSettings.introductionTab.introduction.title",
            })}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {formatMessage({
              id: "marketingSettings.introductionTab.introduction.description",
            })}
          </StyledSettingWidgetStepDescribe>
        </StyledTrackingContainer>
      </StyledSettingWidgetStepContainerOutSide>
      <StyledSettingWidgetStepContainerOutSide>
        <StyledTrackingContainer>
          <StyledTrackingTitle sx={{ fontWeight: 700 }}>
            {formatMessage({
              id: "marketingSettings.introductionTab.marketingCampaign.title",
            })}
          </StyledTrackingTitle>
          <StyledSettingWidgetStepDescribe>
            {formatMessage({
              id: "marketingSettings.introductionTab.marketingCampaign.description",
            })}
          </StyledSettingWidgetStepDescribe>
        </StyledTrackingContainer>
      </StyledSettingWidgetStepContainerOutSide>
      {referralEnabled && (
        <>
          {" "}
          <StyledSettingWidgetStepContainerOutSide>
            <StyledTrackingContainer>
              <StyledTrackingTitle sx={{ fontWeight: 700 }}>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgram.title",
                })}
              </StyledTrackingTitle>
              <StyledSettingWidgetStepDescribe>
                {formatMessage(
                  {
                    id: "marketingSettings.introductionTab.referralProgram.description",
                  },
                  { br: <br /> }
                )}
              </StyledSettingWidgetStepDescribe>

              <StyledTrackingStepTitle>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgramStep1.title",
                })}
              </StyledTrackingStepTitle>
              <StyledSettingWidgetStepDescribe>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgramStep1.description",
                })}
                <StyledLink
                  onClick={() => {
                    setSelectedTab(1);
                  }}
                >
                  {formatMessage({
                    id: "marketingSettings.introductionTab.referralProgramStep1.action",
                  })}
                </StyledLink>
              </StyledSettingWidgetStepDescribe>
              <img
                src={require("assets/img/trackingStatus.png")}
                style={{ maxWidth: "100%" }}
              />
              <StyledTrackingStepTitle>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgramStep2.title",
                })}
              </StyledTrackingStepTitle>
              <ReferralRules />
              <StyledTrackingStepTitle>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgramStep3.title",
                })}
              </StyledTrackingStepTitle>
              <StyledSettingWidgetStepDescribe>
                {formatMessage({
                  id: "marketingSettings.introductionTab.referralProgramStep3.description",
                })}
                <br />
                <StyledLink
                  onClick={() => {
                    setSelectedTab(2);
                  }}
                >
                  {formatMessage({
                    id: "tab.content.referralProgramApi.title",
                  })}
                </StyledLink>
              </StyledSettingWidgetStepDescribe>
            </StyledTrackingContainer>
          </StyledSettingWidgetStepContainerOutSide>
        </>
      )}
    </StyledTrackingPaper>
  );
};

export default TrackingDocument;

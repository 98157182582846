import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  formatDateAndTime,
  getShortAddress,
  parseCustomDomainUrl,
} from "features/helpers/utils";
import { Modal, Grid } from "@mui/material";
import CustomPagination from "components/CustomPagination/CustomPagination";
import moment from "moment";
import { useFetchNotifications } from "../../redux/fetchNotifications";
import CustomTable from "components/CustomTable/CustomTable";
import ReactECharts from "echarts-for-react";
import BigNumber from "bignumber.js";
import Back from "../../components/Back";
import { Button, SIZE } from "@metacrm/metacrm-material-ui/dist/Button";
import NotificationPreview from "./NotificationPreview";
import {
  StyledTitle,
  StyledSubtitle,
  StyledSubValue,
  StyledCard,
  StyledValue,
} from "./Notification.styles";
import CustomTabs from "components/CustomTabs/Tabs";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NotificationChannelIcons from "./components/NotificationChannelIcons";
import NotificationCategoryLabel from "./components/NotificationCategoryLabel";
import { Loading } from "features/common";
import CustomPopover from "components/CustomPopover/CustomPopover";
import Address from "../../components/Address";

const PerformanceDetail = () => {
  const navigate = useNavigate();
  const { type, notificationId } = useParams();
  const [entityName, isCustomDomain] = useOutletContext();
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState(0);
  const [orderData, setOrderData] = useState({ sort: "_id", order: 1 });
  const [openPreview, setOpenPreview] = useState(false);
  const {
    performanceData,
    getPerformanceById,
    getSinglePerformancePending,
    getPerformanceUserListPending,
    performanceUserList,
    usersCount,
    getPerformanceUserList,
  } = useFetchNotifications();
  const data = _.get(performanceData, `[${notificationId}]`, {});
  const notification = _.get(data, "notification", {});

  const theme = useTheme();
  const isBroadcast = type == "broadcast";
  const PAGE_COUNT = 20;

  const echartsRef = useRef(null);

  useEffect(() => {
    getPerformanceById({ entityName, notificationId });
    setUserType(0);
  }, [notificationId]);

  useEffect(() => {
    if (echartsRef.current) {
      echartsRef.current.getEchartsInstance().setOption(getChartOption());
    }
  }, [data]);

  const kMapping = {
    Transaction: "transactionCount",
    TVL: "TVL",
    "NFT Amount": "nftAmount",
    "Wallet Create": "addressFirstTransactionDate",
    "Open time": "readAt",
  };

  const getUserTypeMapping = () => {
    if (userType == 0) return "read";
    if (userType == 1) return "unread";
    return "all";
  };

  const handleSort = (header, direction) => {
    const dir = direction || orderData.order;
    const by = header || orderData.sort;
    setOrderData({ sort: by, order: dir });
  };

  useEffect(() => {
    getPerformanceUserList({
      entityName,
      notificationId,
      type: getUserTypeMapping(),
      page,
      orderData,
    });
  }, [page, userType, orderData]);

  const labelOption = {
    show: true,
    offset: [0, -20],
    position: "insideTopLeft",
    width: "100%",
    verticalAlign: "center",
    formatter: (params) => {
      return `{a|${params.seriesName}}\n{c|$${new BigNumber(
        params.value
      ).toFormat(0)}}`;
    },
    rich: {
      a: {
        lineHeight: 10,
        fontSize: 12,
        fontWeight: 400,
      },
      c: {
        color: "#555555",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 25,
      },
    },
    color: "#777777",
    fontSize: 16,
  };

  const renderTrackingDays = (noti) => {
    if (!noti) return;
    return (
      <>
        {noti.trackingPeriodDay} Days Until{" "}
        {moment(noti.scheduledSendingTime)
          .add(noti.trackingPeriodDay, "days")
          .format("YYYY/MM/DD HH:mm")}
      </>
    );
  };

  const renderNotiTable = () => {
    return (
      <div>
        <CustomTable
          headers={[
            "Address / Widget ID",
            "Discrod ID",
            "Click Link",
            "NFT Amount",
            "Transaction",
            "Wallet Create",
            "Open time",
          ]}
          sortMapping={kMapping}
          sortDataFunction={handleSort}
          sortBy={orderData.sort}
          order={orderData.order}
          loading={getPerformanceUserListPending}
          containerStyle={{ height: "25vh" }}
          rowSelected={(index) => {
            const user = _.get(
              performanceUserList,
              `[${notificationId}][${index}]`
            );
            if (user._id) {
              navigate(
                parseCustomDomainUrl(
                  isCustomDomain,
                  entityName,
                  `/customers/user/${user._id}`
                )
              );
            }
          }}
          contents={_.map(
            _.get(performanceUserList, `[${notificationId}]`, []),
            (user, i) => {
              return [
                <Address user={user} />,
                _.get(user, "mostRecentDiscordID.handle", ""),
                <span
                  style={{
                    color: user.clickUrl ? "#7B61FF" : "#383538",
                  }}
                >
                  {user.clickUrl
                    ? "Yes"
                    : _.get(notification, "urls", []).length > 0
                    ? "No"
                    : "N/A"}
                </span>,
                user.nftAmount,
                user.transactionCount,
                formatDateAndTime(user.addressFirstTransactionDate),
                formatDateAndTime(user.readAt),
              ];
            }
          )}
        />
      </div>
    );
  };

  const getChartOption = () => {
    const after = _.get(notification, "tvlStats.after", 0);
    const before = _.get(notification, "tvlStats.before", 0);
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: { show: false },
      yAxis: {
        show: false,
        data: ["TVL"],
      },
      grid: {
        left: "-25px",
        bottom: "3%",
        containLabel: true,
      },
      series: [
        {
          name: "TVL after",
          type: "bar",
          barGap: "140%",
          data: [after],
          color: "#FFC670",
          barWidth: "30%",
          label: labelOption,
        },
        {
          name: "TVL before",
          type: "bar",
          data: [before],
          color: "#CFA9FF",
          barWidth: "30%",
          label: labelOption,
        },
      ],
    };
  };

  const renderTVLGrowth = () => {
    const after = _.get(notification, "tvlStats.after", 0);
    const before = _.get(notification, "tvlStats.before", 0);
    const isGrowth = after - before >= 0;
    return (
      <div>
        <StyledTitle color={"#FC5555"} className="startRow">
          TVL Growth Rate:{" "}
          <CustomPopover
            hoverComponenet={
              <i
                className="meta-crm-icon-ic_info font-size-20"
                style={{
                  marginLeft: 5,
                }}
              />
            }
            content={
              <div style={{ color: "#777" }}>
                Caculate from sent date to tracking period.
              </div>
            }
          />
        </StyledTitle>
        <StyledValue>
          $ {new BigNumber(after - before).toFormat(0)}{" "}
          <span
            style={{
              color: isGrowth ? "#14C864" : "#FC5555",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {before
              ? parseFloat(((after - before) * 100) / before).toFixed(1)
              : "-"}
            %
            {isGrowth ? (
              <ArrowUpwardIcon style={{ fontSize: 32 }} />
            ) : (
              <ArrowDownwardIcon style={{ fontSize: 32 }} />
            )}
          </span>
        </StyledValue>

        <ReactECharts
          ref={echartsRef}
          option={getChartOption()}
          style={{ height: "193px" }}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>
    );
  };
  const userTabs = [
    {
      label: `Opens (${_.get(data, "count.read", 0)})`,
    },
  ];

  if (!isBroadcast) {
    userTabs.push({
      label: `Unreads (${_.get(data, "count.unread", 0)})`,
    });
    userTabs.push({
      label: `All (${_.get(data, "count.all", 0)})`,
    });
  }

  const renderPieChart = () => {
    if (isBroadcast) return;

    const read = _.get(data, "count.read", 0);
    const unread = _.get(data, "count.unread", 0);
    const all = _.get(data, "count.all", 0);
    const hasZero = read == 0 || unread == 0;
    let pieData = [
      {
        value: read,
        name: "Opens",
      },
      {
        value: unread,
        name: "Unreads",
      },
    ];
    if (all == 0) {
      pieData = [];
    }

    return (
      <Grid item xs={12} md={3}>
        <StyledCard className="card">
          <ReactECharts
            option={{
              tooltip: {
                trigger: "item",
              },
              legend: {
                icon: "circle",
                left: "left",
                textStyle: {
                  color: "#777777",
                },
                formatter: function (name) {
                  return (
                    name +
                    "\n" +
                    _.get(
                      data,
                      `count.[${name == "Opens" ? "read" : "unread"}]`,
                      0
                    )
                  );
                },
              },
              color: ["#64BFFF", "#FF7A7A"],

              series: [
                {
                  name: "Total Sent",
                  startAngle: hasZero ? 90 : 180,
                  minShowLabelAngle: 10,
                  type: "pie",
                  radius: "80%",
                  labelLayout: (params) => {
                    return {
                      align: "center",
                      verticalAlign: "bottom",
                      dy: -1,
                    };
                  },
                  label: {
                    formatter: `{d}%`,
                    width: 40,
                  },
                  labelLine: { legend: 25, length2: 35 },
                  data: pieData,

                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }}
            style={{ height: "310px" }}
            notMerge={true}
            lazyUpdate={true}
          />
        </StyledCard>
      </Grid>
    );
  };

  return (
    <div>
      <Modal
        open={openPreview}
        className="modal"
        onClose={() => setOpenPreview(false)}
      >
        <div className={"modalPaper"}>
          <NotificationPreview data={notification} imgs={notification.images} />
        </div>
      </Modal>
      <div className="betweenRow" style={{ marginTop: "-20px" }}>
        <Back />

        <Button
          color="secondary"
          size={SIZE.LARGE}
          onClick={() => {
            setOpenPreview(true);
          }}
        >
          View published
        </Button>
      </div>
      <div style={{ position: "relative", marginTop: 12 }}>
        <Loading open={getSinglePerformancePending} fullScreen={false} white />

        <div className={"title startRow"}>
          <div style={{ marginRight: 10 }}>{notification.title} - </div>
          <NotificationCategoryLabel category={type} />
        </div>

        <div className="startRow" style={{ marginTop: 12 }}>
          <StyledSubtitle>Channel : </StyledSubtitle>
          <div style={{ margin: "0 10px" }}>
            <NotificationChannelIcons channels={notification.channels} icon />
          </div>
          <StyledSubtitle>Sent date : </StyledSubtitle>
          <StyledSubValue>
            {formatDateAndTime(notification.scheduledSendingTime)}
          </StyledSubValue>
          <StyledSubtitle style={{ margin: "0 5px" }}>
            Tracking period :
          </StyledSubtitle>
          <StyledSubValue>{renderTrackingDays(notification)}</StyledSubValue>
        </div>
        <Grid
          container
          spacing={1}
          style={{ position: "relative", marginTop: 6 }}
        >
          <Grid item xs={12} md={3}>
            <StyledCard className="card" style={{ minHeight: 375 }}>
              <StyledTitle
                color={theme.customColors.purple[500]}
                className="startRow"
              >
                <i
                  className="meta-crm-icon-ic_mailOpen font-size-20"
                  style={{ marginRight: 5 }}
                />
                Opens
              </StyledTitle>
              <StyledValue>{_.get(notification, "readCount", "-")}</StyledValue>
              {!isBroadcast && (
                <>
                  <StyledTitle color={theme.customColors.purple[500]}>
                    Total sent
                  </StyledTitle>
                  <StyledValue>
                    {_.get(notification, "sentCount", "-")}
                  </StyledValue>
                  <StyledTitle
                    color={theme.customColors.purple[500]}
                    className="startRow"
                  >
                    <CustomPopover
                      hoverComponenet={
                        <i
                          className="meta-crm-icon-ic_info font-size-20"
                          style={{
                            marginRight: 5,
                          }}
                        />
                      }
                      content={
                        <div style={{ color: "#777" }}>
                          (Opens / Total sent) * 100%
                        </div>
                      }
                    />{" "}
                    Conversion Rate (%)
                  </StyledTitle>
                  <StyledValue style={{ margin: 0 }}>
                    {_.get(notification, "sentCount")
                      ? parseFloat(
                          (notification.readCount * 100) /
                            notification.sentCount
                        ).toFixed(1)
                      : "-"}
                    %
                  </StyledValue>
                </>
              )}
            </StyledCard>
          </Grid>
          {renderPieChart()}
          <Grid item xs={12} md={6}>
            <StyledCard className="card">{renderTVLGrowth()}</StyledCard>
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: "10px 0px" }}>
        <CustomTabs
          borderBottom={true}
          sm="true"
          tabIndex={userType}
          handleChange={(e, newValue) => setUserType(newValue)}
          tabs={userTabs}
        />
        {renderNotiTable()}
        <CustomPagination
          shape="rounded"
          PAGE_COUNT={PAGE_COUNT}
          count={usersCount}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default PerformanceDetail;

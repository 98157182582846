import { useContext, useEffect, useState } from "react";
import { PerformanceContext } from "../context/PerformanceProvider";
import { useOutletContext, useParams } from "react-router";
import axios from "axios";
import { apiUrl } from "features/configure";
import useDispatchSnackbar from "hooks/useDispatchSnackbar";
import _ from "lodash";
import moment from "moment";

export default function useGetPerformanceChart() {
  const [entityName] = useOutletContext();
  const {
    startDate,
    endDate,
    page,
    setPage,
    setIsLoading,
    range,
    setStartDate,
    setEndDate,
  } = useContext(PerformanceContext);
  const [data, setData] = useState({
    timeRangeClicked: [],
    referrers: { total: 0, data: [] },
    performance: [],
    os: [],
    browser: [],
    device: [],
    country: [],
  });
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatchSnackbar = useDispatchSnackbar();
  const params = { entityName };
  if (startDate) params.startDate = startDate;
  if (endDate) params.endDate = endDate;

  async function getReferrerData() {
    return axios.get(`${apiUrl}/api/dashboard/referer`, {
      params: { ...params, pageSize: 5, page },
    });
  }

  async function getLineChart() {
    return axios.get(`${apiUrl}/api/dashboard/lineChart`, { params });
  }

  async function getAllCharts() {
    return axios.get(`${apiUrl}/api/dashboard/allCharts`, { params });
  }

  async function getPerformanceData() {
    try {
      setIsLoading((prev) => ({ ...prev, chart: true }));
      const r = [getLineChart(), getAllCharts()];
      if (page == 1) {
        r.push(getReferrerData());
      } else {
        setPage(1);
      }
      const result = await Promise.all(r);
      const lineChart = _.get(result, "[0].data");
      const allCharts = _.get(result, "[1].data");
      const referrer = _.get(result, "[2].data");
      setData((prv) => ({
        ...prv,
        referrers: referrer
          ? {
              total: referrer.total,
              data: referrer.refererData,
            }
          : { ...prv.referrers },
        performance: lineChart.campaignPerformance,
        os: allCharts.osData,
        browser: allCharts.browserData,
        device: allCharts.deviceData,
        country: allCharts.countryData.sort(
          (a, b) => b.totalUser - a.totalUser
        ),
      }));
      setHasLoaded(true); // Mark data as loaded
    } catch (err) {
      console.log("err: ", err);
      dispatchSnackbar({ err });
    } finally {
      setIsLoading((prev) => ({ ...prev, chart: false }));
    }
  }

  async function getRefersTableData() {
    try {
      setIsLoading((prev) => ({ ...prev, table: true }));
      const [{ data: referrer }] = await Promise.all([getReferrerData()]);

      setData((prv) => ({
        ...prv,
        referrers: {
          total: referrer.total,
          data: referrer.refererData,
          totalUsers: referrer.totalUsers,
          totalWalletsConnected: referrer.totalWalletsConnected,
        },
      }));
    } catch (err) {
      dispatchSnackbar({ err });
    } finally {
      setIsLoading((prev) => ({ ...prev, table: false }));
    }
  }

  useEffect(() => {
    if (range == "custom") return;
    if (range == "all") {
      setStartDate("all");
      setEndDate("all");
      return;
    }
    const now = moment().endOf("day").toDate();
    setStartDate(
      moment().startOf("day").subtract(parseInt(range), "days").toDate()
    );
    setEndDate(now);
  }, [range]);

  useEffect(() => {
    getPerformanceData();
  }, []);

  useEffect(() => {
    if (hasLoaded) {
      getPerformanceData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (hasLoaded) {
      getRefersTableData();
    }
  }, [page]);

  return data;
}

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";

import {
  useFetchAiSupportConfig,
  useFetchEntityConfig,
} from "features/metadesk/redux/hooks";
import { selectAiSupportConfig } from "features/metadesk/redux/aiSupport/aiSupport.selector";
import { selectServiceModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";

import { Loading } from "features/common";
import CustomTabs from "components/CustomTabs/Tabs";
import Setup from "./Setup";
import MagicWand from "./MagicWand";

import { Tab } from "./AiManagement.styles";
import { StyledCustomTabsContainer } from "../common.styles";
import { StyledCustomTabsContentContainer } from "features/metadesk/settings/common.styles";

const AiManagement = () => {
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [entityName, isCustomDomain] = useOutletContext();

  const { entityConfig } = useFetchEntityConfig();
  const { fetchAiSupportConfig } = useFetchAiSupportConfig();

  const selectedAiSupport = useSelector(selectAiSupportConfig);
  const selectedServiceModule = useSelector(selectServiceModule);

  const isShowMagicWand = _.get(
    selectedServiceModule,
    "aiSupport.magicWand.enabled",
    false
  );

  const isEnabledMagicWand = _.get(
    selectedServiceModule,
    "aiSupport.magicWand.connected",
    false
  );

  useEffect(() => {
    getAiSupportConfig();
  }, []);

  const getAiSupportConfig = async () => {
    try {
      setIsLoading(true);
      await fetchAiSupportConfig({ entityName });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  let tabs = [
    {
      label: (
        <Tab>
          <Box
            component="i"
            mr="5px"
            fontWeight={400}
            className="meta-crm-icon-ic_settingOutline font-size-20 font-weight-100"
          />
          Set up
        </Tab>
      ),
      content: (
        <Setup
          entityConfig={entityConfig}
          aiSupport={_.get(selectedAiSupport, "config", {})}
        />
      ),
    },
  ];

  if (isShowMagicWand && isEnabledMagicWand) {
    tabs.push({
      label: (
        <Tab>
          <Box
            component="i"
            mr="5px"
            fontWeight={400}
            className="meta-crm-icon-ic_magicWandTab font-size-24 font-weight-100"
          />
          {formatMessage({ id: "aiManagement.tab.magicWand" })}
        </Tab>
      ),
      content: <MagicWand />,
    });
  }

  const handleChangeTab = (newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Loading open={isLoading} fullScreen={false} />
      <div style={{ maxWidth: 780 }}>
        <div className="title">
          {formatMessage({ id: "settingPage.aiManagement.title" })}
        </div>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div className="description">
            {formatMessage({ id: "settingPage.aiManagement.description" })}
          </div>
        </Box>
      </div>
      <Box mb={5}></Box>
      <Box>
        <StyledCustomTabsContainer>
          <CustomTabs
            sm
            tabIndex={selectedTab}
            handleChange={(e, newValue) => handleChangeTab(newValue)}
            tabs={tabs}
          />
        </StyledCustomTabsContainer>
      </Box>
    </div>
  );
};

export default AiManagement;

import React from "react";
import _ from "lodash";
import { IconButton } from "@material-ui/core";

const ImagePreview = ({
  imgs,
  setImgs,
  size = 70,
  editable = true,
  fullWidth,
  style,
}) => {
  const imgCss = {
    verticalAlign: "middle",
    width: fullWidth ? "100%" : size,
    height: fullWidth ? "100%" : size,
    objectFit: "contain",
  };
  return (
    <div className="startRow" style={{ padding: 10 }}>
      {imgs &&
        imgs.map((image, index) => {
          const isUrl = typeof image == "string";
          const fileType = isUrl
            ? image.split(".").pop()
            : image.file.name.split(".").pop();
          return (
            <span
              key={index}
              style={{
                position: "relative",
                background: "#F1EFFA",
                margin: 10,
                marginLeft: 0,
                borderRadius: 10,
                ...style,
              }}
            >
              {fileType == "pdf" ? (
                <span className="startRow" style={{ padding: 10 }}>
                  <img src={require("assets/img/pdf.svg").default} alt="" />
                  <div style={{ padding: 9 }}>
                    <div style={{ fontWeight: 700 }}>
                      {_.truncate(image.file.name, { length: 10 })}
                    </div>
                    PDF
                  </div>
                </span>
              ) : (
                <img
                  src={_.get(image, "data_url", image)}
                  alt=""
                  style={imgCss}
                />
              )}

              {editable && (
                <IconButton
                  onClick={() => {
                    const newImgs = [...imgs];
                    newImgs.splice(index, 1);
                    setImgs(newImgs);
                  }}
                  style={{
                    padding: 0,
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                  }}
                >
                  <img
                    src={require("assets/img/ic_x.svg").default}
                    className="smIcon"
                    style={{ marginRight: "0" }}
                  />
                </IconButton>
              )}
            </span>
          );
        })}
    </div>
  );
};

export default ImagePreview;

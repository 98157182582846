import styled from "@emotion/styled/macro";
import { alpha } from "@mui/material/styles";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";

export const StyledWhiteContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sx }) => ({
  background: theme.customColors.white,
  padding: "20px",
  marginBottom: "60px",
  borderRadius: 8,
  boxShadow: "0px 1.2824229001998901px 6.412113666534424px 0px #0000001A",
  ...sx,
}));

export const StyledCustomTabsContentContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  minHeight: "55vh",
  background: "white",
  padding: "20px",
  marginTop: "20px",
  boxShadow: `0px 1.2824229001998901px 6.412113666534424px 0px #0000001A`,
}));

export const StyledCustomTabsContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      height: "1px",
      width: "100%",
      background: theme.customColors.grey[300],
      top: "32px",
    },

    "& .MuiTab-root": {
      textTransform: "none",
    },

    "& .MuiTabs-root": {
      "& .MuiButtonBase-root": {
        maxWidth: "unset",
        minWidth: "unset",
        flexGrow: "unset",
        flexBasis: "auto",
        fontSize: "14px",
        lineHeight: 1,
        padding: `9.5px 15px`,
      },
    },
  };
});

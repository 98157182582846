import React, { useState, useEffect } from "react";
import _, { chain } from "lodash";
import {
  StyledIconImg,
  StyledChainIconImg,
  StyledWrap,
  NFTIconImg,
} from "./TokensWraper.styles";
import { tokensMap, chainIdMapping } from "features/configure/tokenList.js";
import {
  IconTooltip,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import { getHexImg } from "features/helpers/utils";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
import Token from "./Token";
import { useSelector } from "react-redux";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { Box } from "@mui/material";

const NFTsWrapper = ({
  nfts = [],
  marginLeft = "-6px",
  showNumber = 5,
  nftAmounts = 0,
}) => {
  const chainList = useSelector(selectEntityChainList);

  const renderIcon = ({ chainIcon, i, img }) => {
    return (
      <StyledWrap key={i}>
        {!_.isEmpty(chainIcon) && (
          <StyledChainIconImg
            src={chainIcon}
            marginLeft={i == 0 ? 0 : marginLeft}
          />
        )}
        <NFTIconImg src={img} marginLeft={i == 0 ? 0 : marginLeft} />
      </StyledWrap>
    );
  };

  // 這邊nft有時候資料比較多，用for迴圈會比reduce可能還好一點，因為for迴圈可以直接用break跳出迴圈
  function formatNftsData(nfts) {
    const nftsData = [];
    for (let i = 0; i < nfts.length; i++) {
      const { chainId, nftList } = nfts[i];
      for (let j = 0; j < nftList.length; j++) {
        if (nftsData.length >= showNumber) break;
        const nft = nftList[j];
        nftsData.push({ ...nft, chainId });
      }
      if (nftsData.length >= showNumber) break;
    }
    return nftsData;
  }
  // const formatData = nfts.reduce((acc, curr) => {
  //   if (acc.length >= showNumber) return acc;

  //   const { chainId, nftList } = curr;
  //   nftList.forEach((nft) => {
  //     if (acc.length < showNumber) {
  //       acc.push({ ...nft, chainId });
  //     }
  //   });
  //   return acc;
  // }, []);

  const formatData = formatNftsData(nfts);

  if (nfts.length === 0) return "-";

  return (
    <div className="startRow" style={{ flexWrap: "nowrap" }}>
      <div style={{ display: "flex" }}>
        {_.map(formatData, (d, i) => {
          if (i > showNumber - 1) return;
          const { chainId, name, content } = d;
          const chainIcon = _.find(chainList, {
            id: parseInt(chainId),
          })?.logo_url;
          const data = {
            chainIcon,
            i,
            chainId,
            img: content,
          };
          return (
            <IconTooltip
              key={content}
              icon={renderIcon(data)}
              content={
                <Box display="flex" alignItems={"center"}>
                  <img
                    src={content}
                    style={{
                      borderRadius: "50%",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  <Box>{name}</Box>
                </Box>
              }
            ></IconTooltip>
          );
        })}
      </div>
      <div style={{ marginLeft: "4px" }}>
        {nfts && nftAmounts > showNumber && (
          <MetaCRMTag
            option={{
              label: `${
                nftAmounts - showNumber > 0 ? nftAmounts - showNumber : ""
              }`,
              color: "#F7F1FE",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NFTsWrapper;

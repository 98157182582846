import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import ImagePreview from "features/metadesk/components/ImagePreview";
import { StyledWhiteContainer } from "features/metadesk/settings/common.styles";
import Box from "@mui/material/Box";
import {
  formatWidgetDateAndTime,
  getShortAddress,
} from "features/helpers/utils";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Navigation, Controller } from "swiper/modules";
import {
  StyledSwiper,
  StyledDescription,
} from "./EditNotificationPreview.styles";
import Linkify from "linkify-react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectMarketingModule } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { makeCustomTrackingLink } from "../createCampaign/utils";

const options = {
  target: (href) => {
    const { hostname } = window.location;
    if (hostname && href.includes(hostname)) {
      return "";
    }
    return "_blank";
  },
  attributes: {
    rel: "noreferrer noopener",
  },
};

const EditNotificationPreview = ({
  data,
  startFrom,
  imgs = [],
  minHeight = null,
  name,
}) => {
  const swiperRefAsset = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const channels = useSelector(selectMarketingModule);

  const subtitle = {
    widget: "global.serviceWidget.subtitle",
    xmtp: "integration.xmtp.name",
    push: "integration.push.name",
  };

  const renderLink = (index) => {
    const { linkSetting, shortLink, linkTitle, trackingLink } = data
      .engagementDistributionSettings[index] || {
      linkSetting: {},
      shortLink: "",
      linkTitle: "",
      trackingLink: "",
    };

    if (linkSetting && Object.keys(linkSetting).length > 0) {
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {linkSetting?.type === "namingLink"
            ? linkSetting?.linkTitle
            : "https://bit.ly/" + linkSetting?.shortLink}
        </Box>
      );
    }
    if (linkTitle)
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {linkTitle}
        </Box>
      );
    if (shortLink) {
      return (
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={shortLink}
          target="_blank"
          rel="noreferrer"
        >
          {shortLink}
        </Box>
      );
    }
    return (
      <>
        {trackingLink ? (
          <Box
            sx={{
              color: theme.customColors.blue[500],
              fontSize: "12px",
              textDecoration: "underline",
              marginTop: "8px",
            }}
            component={"a"}
            href={trackingLink}
            target="_blank"
            rel="noreferrer"
          >
            {trackingLink}
          </Box>
        ) : (
          data.engagementDistributionSettings[index].websiteUrl &&
          "https://" +
            data.engagementDistributionSettings[index].websiteUrl +
            makeCustomTrackingLink(
              data.engagementDistributionSettings,
              name,
              index
            )
        )}
      </>
    );
  };

  const renderWidget = (index) => (
    <Box
      className="card"
      sx={{
        height: "600px",
        maxHeight: "600px",
        padding: 0,
        overflowY: "auto",
        marginBottom: "40px",
      }}
    >
      <Box
        sx={{
          background: theme.customColors.blue[600],
          height: 48,
          width: "100%",
          borderRadius: "5px 5px 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: theme.customColors.white,
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {formatMessage({ id: "global.serviceWidget.subtitle" })}
      </Box>
      <div style={{ padding: 13, overflowWrap: "break-word" }}>
        <Box
          fontSize={"12px"}
          color={(theme) => theme.customColors.grey[500]}
          mb="5px"
        >
          {formatWidgetDateAndTime(startFrom)}
        </Box>
        <div className="tableValue">{data.engagementTitle}</div>

        <StyledDescription sx={{ marginTop: "20px" }}>
          <Linkify options={options}>{data.engagementDescription}</Linkify>
        </StyledDescription>
        {imgs.length > 0 && (
          <div style={{ marginTop: 5 }}>
            <ImagePreview
              imgs={imgs}
              fullWidth={true}
              editable={false}
              style={{ border: "0px" }}
            />
          </div>
        )}
        {_.get(data, "urls", []).length > 0 && (
          <div style={{ marginTop: 5 }}>
            <a
              href={_.get(data, "urls[0].link")}
              target="_blank"
              rel="noreferrer"
            >
              {_.get(data, "urls[0].title")}
            </a>
          </div>
        )}
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
          component={"a"}
          href={data.engagementDistributionSettings[index]?.trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {renderLink(index)}
        </Box>
      </div>
    </Box>
  );

  const renderXmtp = (index) => (
    <Box
      className="card"
      sx={{
        height: "600px",
        maxHeight: "600px",
        padding: 0,
        overflowY: "auto",
        marginBottom: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          borderRadius: "5px 5px 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: theme.customColors.grey[600],
          fontSize: "15px",
          fontWeight: "600",
          py: 2,
          position: "sticky",
          top: 0,
        }}
      >
        {getShortAddress(channels.xmtp?.address)}
      </Box>
      <div
        style={{
          padding: 13,
          overflowWrap: "break-word",
          backgroundColor: theme.customColors.grey[200],
          flex: 1,
        }}
      >
        <Box
          fontSize={"12px"}
          fontWeight={500}
          color={(theme) => theme.customColors.grey[500]}
          bgcolor={(theme) => theme.customColors.white}
          p={2.5}
          mb="5px"
        >
          <Box>{data.engagementTitle || "Give a title"}</Box>

          <Box sx={{ marginTop: "20px" }}>
            <Linkify options={options}>
              {data.engagementDescription || "Give a description"}
            </Linkify>
          </Box>
          {imgs.length > 0 && (
            <div style={{ marginTop: 5 }}>
              <ImagePreview
                imgs={imgs}
                fullWidth={true}
                editable={false}
                style={{ border: "0px" }}
              />
            </div>
          )}
          {_.get(data, "urls", []).length > 0 && (
            <div style={{ marginTop: 5 }}>
              <a
                href={_.get(data, "urls[0].link")}
                target="_blank"
                rel="noreferrer"
              >
                {_.get(data, "urls[0].title")}
              </a>
            </div>
          )}
          <Box fontSize={"10px"} mt={2.5}>
            {formatWidgetDateAndTime(startFrom || new Date())}
          </Box>
        </Box>
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
        >
          {renderLink(index)}
        </Box>
      </div>
      <Box
        py={1.25}
        pl={2}
        pr={1.25}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        color={(theme) => theme.customColors.grey[500]}
        bgcolor={(theme) => theme.customColors.grey[200]}
        borderTop={"1px solid"}
        borderColor={(theme) => theme.customColors.grey[300]}
        position={"sticky"}
        bottom={0}
      >
        <Box flex={1} mr={1}>
          Send a message...
        </Box>
        <Box
          color={(theme) => theme.customColors.grey[500]}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={24}
          width={24}
        >
          <i className="meta-crm-icon-ic_img font-size-16 mgr5" />
        </Box>
        <Box
          color={(theme) => theme.customColors.blue[600]}
          bgcolor={(theme) => theme.customColors.blue[200]}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={24}
          width={24}
          borderRadius={24}
        >
          <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
        </Box>
      </Box>
    </Box>
  );

  const renderPush = (index) => (
    <Box
      className="card"
      sx={{
        height: "600px",
        maxHeight: "600px",
        padding: 0,
        overflowY: "auto",
        marginBottom: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          borderRadius: "5px 5px 0 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          color: theme.customColors.grey[600],
          fontSize: "15px",
          fontWeight: "600",
          py: 2,
          position: "sticky",
          top: 0,
        }}
      >
        <Box>{getShortAddress(channels.push?.address)}</Box>
      </Box>
      <div
        style={{
          padding: 13,
          overflowWrap: "break-word",
          backgroundColor: theme.customColors.grey[200],
          flex: 1,
        }}
      >
        <Box
          fontSize={"12px"}
          fontWeight={500}
          color={(theme) => theme.customColors.grey[500]}
          bgcolor={(theme) => theme.customColors.white}
          p={2.5}
          mb="5px"
        >
          <Box>{data.engagementTitle || "Give a title"}</Box>

          <Box sx={{ marginTop: "20px" }}>
            <Linkify options={options}>
              {data.engagementDescription || "Give a description"}
            </Linkify>
          </Box>
          {imgs.length > 0 && (
            <div style={{ marginTop: 5 }}>
              <ImagePreview
                imgs={imgs}
                fullWidth={true}
                editable={false}
                style={{ border: "0px" }}
              />
            </div>
          )}
          {_.get(data, "urls", []).length > 0 && (
            <div style={{ marginTop: 5 }}>
              <a
                href={_.get(data, "urls[0].link")}
                target="_blank"
                rel="noreferrer"
              >
                {_.get(data, "urls[0].title")}
              </a>
            </div>
          )}
          <Box fontSize={"10px"} mt={2.5}>
            {formatWidgetDateAndTime(startFrom || new Date())}
          </Box>
        </Box>
        <Box
          sx={{
            color: theme.customColors.blue[500],
            fontSize: "12px",
            textDecoration: "underline",
            marginTop: "8px",
          }}
        >
          {renderLink(index)}
        </Box>
      </div>
      <Box
        py={1.25}
        pl={2}
        pr={1.25}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        color={(theme) => theme.customColors.grey[500]}
        bgcolor={(theme) => theme.customColors.grey[200]}
        borderTop={"1px solid"}
        borderColor={(theme) => theme.customColors.grey[300]}
        position={"sticky"}
        bottom={0}
      >
        <Box flex={1} mr={1}>
          Send a message...
        </Box>
        <Box
          color={(theme) => theme.customColors.grey[500]}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={24}
          width={24}
        >
          <i className="meta-crm-icon-ic_img font-size-16 mgr5" />
        </Box>
        <Box
          color={(theme) => theme.customColors.blue[600]}
          bgcolor={(theme) => theme.customColors.blue[200]}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={24}
          width={24}
          borderRadius={24}
        >
          <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
        </Box>
      </Box>
    </Box>
  );

  return (
    <StyledWhiteContainer minHeight={minHeight ? minHeight : "80vh"}>
      <Box className="column" padding={"20px 32px"} width="100%">
        <Box
          display="flex"
          alignItems={"center"}
          fontSize="12px"
          color={theme.customColors.grey[600]}
        >
          <Box mr="5px">
            {formatMessage({ id: "profileSetting.companyProfile.preview" })}:
          </Box>
          <Box
            sx={{
              border: `1px solid ${theme.customColors.grey[300]}`,
              borderRadius: "10px",
              padding: "2px 5px",
            }}
          >
            {data?.engagementDistributionSettings[swiperIndex]?.channel &&
              formatMessage({
                id: subtitle[
                  data?.engagementDistributionSettings[swiperIndex].channel
                ],
              })}
          </Box>
        </Box>

        <Box width="100%" maxWidth={"calc(100vw - 150px)"}>
          <StyledSwiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation, Controller]}
            className="mySwiper"
            ref={swiperRefAsset}
            navigation={true}
            onSlideChange={(swiperInfo) => {
              setSwiperIndex(swiperInfo.activeIndex);
            }}
          >
            {data?.engagementDistributionSettings.map(
              (distributionItem, index) => (
                <SwiperSlide key={index}>
                  {distributionItem.channel === "widget" && renderWidget(index)}
                  {distributionItem.channel === "xmtp" && renderXmtp(index)}
                  {distributionItem.channel === "push" && renderPush(index)}
                  {distributionItem.channel === "debankHi" && (
                    <Box>DebankHi TODO</Box>
                  )}
                </SwiperSlide>
              )
            )}
          </StyledSwiper>
        </Box>
      </Box>
    </StyledWhiteContainer>
  );
};

export default EditNotificationPreview;

import React, { useState } from "react";
import { Tabs } from "@metacrm/metacrm-material-ui/dist/Tabs";
import { Box } from "@mui/material";
import CodePannel from "components/CodePannel/CodePannel";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
const SriCodeTab = ({ nonSriCode, sriCode }) => {
  const [sriTabValues, setSriTabValues] = useState(0);
  const { formatMessage } = useIntl();
  return (
    <Box sx={{ position: "relative" }}>
      <Tabs
        value={sriTabValues}
        onChange={(e, newValue) => setSriTabValues(newValue)}
        tabs={[
          {
            _id: "default",
            label: formatMessage({
              id: "global.default.tab",
            }),
            content: (
              <Box mt="10px">
                <CodePannel code={nonSriCode} />
              </Box>
            ),
          },
          {
            _id: "sriSecured",
            label: formatMessage({
              id: "global.sriSecured.tab",
            }),
            icon: <i className="meta-crm-icon-ic_safeSdk font-size-16" />,
            content: (
              <Box mt="10px">
                <CodePannel code={sriCode} />
              </Box>
            ),
          },
        ]}
        iconPosition="end"
      ></Tabs>
      <Box
        sx={{
          position: "absolute",
          left: "240px",
          top: "10px",
          textDecoration: "underline",
        }}
        color={(theme) => theme.customColors.purple[500]}
        component={Link}
        to="https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity"
        target="_blank"
      >
        {formatMessage({
          id: "global.btn.sri",
        })}
      </Box>
    </Box>
  );
};

export default SriCodeTab;

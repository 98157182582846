import styled from "@emotion/styled/macro";
import { Accordion, AccordionSummary, Box } from "@mui/material";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledMainCategoryTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  color: theme.customColors.grey[500],
  padding: "12px 12px",
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${theme.customColors.grey[300]}`,
}));

export const StyledNavItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginBottom: "25px",
}));

export const StyledNavItemTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: theme.customColors.grey[600],
  marginBottom: "10px",
}));

export const StyledNavItemInfo = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  wordBreak: "break-all",
}));

import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MetaCRMTag from "@metacrm/metacrm-material-ui/dist/MetaCRMTag";
const useStyles = makeStyles((theme) => ({
  icon: {
    width: 16,
    height: 16,
    borderRadius: 20,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "8px 12px",
    paddingBottom: 5,
    background: "#ffffff",
    borderRadius: 2.5,
    border: "1px solid #a5a5a5",
    minWidth: 110,
    boxShadow: "3px 3px 1px 0px rgba(0, 0, 0, 0.20)",
  },
}));
const GroupLabel = ({ groups, entityName, all }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoveredItem, setHoveredItem] = useState("");
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const renderPopover = () => {
    if (!anchorEl) return;
    return (
      <div
        style={{
          position: "absolute",
          zIndex: 2000,
          top: 20,
          left: 0,
        }}
      >
        <div className={classes.paper}>
          {_.map(groups, (group, index) => {
            return (
              <div
                className="startRow"
                key={index}
                onClick={(e) => {
                  navigate(
                    "/" + entityName + `/customers/segment/${group._id}`
                  );
                  e.stopPropagation();
                }}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem("")}
                style={{
                  marginBottom: 6,
                  opacity: hoveredItem === index ? 0.6 : 1,
                  cursor: "pointer",
                }}
              >
                <div style={{ fontSize: 11, fontWeight: 400 }}>
                  {_.truncate(_.get(group, "name", ""), { length: 15 })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (!groups || groups.length == 0) return <div></div>;

  return (
    <span
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      style={{ position: "relative" }}
    >
      <MetaCRMTag option={{ color: "#F7F1FE", label: groups.length }} />
      {renderPopover()}
    </span>
  );
};

export default GroupLabel;

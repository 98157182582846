import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

const buttonMixin = ({
  theme,
  width,
  height,
  background,
  textHeight,
  textWidth,
  textColor,
  border,
}) => ({
  ...(background && {
    background: background,
  }),
  width: width,
  height: height,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2px",
  ...(border && {
    border: `1px solid ${border}`,
  }),

  "&:after": {
    content: '""',
    width: textWidth,
    height: textHeight,
    borderRadius: "5px",
    background: textColor,
  },
});

const borderMixin = (color) => {
  return `1px solid ${color}`;
};

const fakeTextMixin = ({
  height = "4px",
  width = "32px",
  color,
  textHoverColor,
  borderRadius = "5px",
}) => ({
  background: color,
  width,
  height,
  borderRadius,
  ...(textHoverColor && {
    background: textHoverColor,
  }),
});

const msgItemAfterMixin = (color) => ({
  position: "relative",

  "&:after": {
    content: '""',
    width: "1px",
    height: "12px",
    background: color,
    position: "absolute",
    right: "-12.5px",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

export const StyledDemoTemplateContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledDemoTemplate = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  boxShadow: `0px 2.0105817317962646px 5.026454925537109px 0px #0000001A`,
  width: "760px",
}));
export const StyledDemoTemplateHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, primaryColor, borderColor }) => ({
  height: "30px",
  padding: "7px 10px",
  borderBottom: borderMixin(borderColor),
  background: primaryColor,
}));
export const StyledCard = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color, cardHoverColor, textHoverColor }) => ({
  padding: "12px 14px",
  boxShadow: `0px 2px 10px 0px rgba(0, 0, 0, 0.05)`,
  background: color,
  borderRadius: "8px",
  position: "relative",
  ...(cardHoverColor && {
    background: cardHoverColor,
  }),
  ...(textHoverColor && {
    textHoverColor: textHoverColor,
  }),
}));
export const StyledSettingIcon = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  height: "32px",
  borderRadius: "20px",
  background: "#FFFFFF",
  color: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "7px 9px",
}));

export const StyledMenuButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  marginLeft: "auto",
  "& i": {
    height: "100%",
    width: "100%",
    display: "block",
    color: textColor,
    transform: `translateY(-1px)`,
  },
}));
export const StyledDemoTemplateBody = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background }) => ({
  display: "flex",
  background: background,
}));

export const StyledWidgetTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.customColors.grey[700],
  marginBottom: 12,
}));
export const StyledDemoTemplateInfoArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background }) => ({
  flex: 1,
  marginRight: "11px",
  borderRadius: "5px 5px 0 0",
  background: `linear-gradient(197deg, ${background} 33%, #FFF 71.41%);`,
  minHeight: "400px",
}));

export const StyledDemoTemplateSearchContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  padding: "7.5px 7px",
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledDemoTemplateSearchInput = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  width: "200px",
  height: "18px",
  border: borderMixin(borderColor),
  borderRadius: "2px",
}));
export const StyledDemoTemplateCreateButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, buttonTextColor }) => ({
  ...buttonMixin({
    background: buttonColor,
    width: "80px",
    height: "18px",
    textHeight: "4px",
    textWidth: "64px",
    textColor: buttonTextColor,
  }),
}));
export const StyledFiltersContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  marginTop: "2.5px",
  display: "flex",
  paddingLeft: "7px",
}));
export const StyledFilterButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, buttonTextColor, borderColor }) => ({
  "&:nth-of-type(1)": {
    marginRight: "4px",
    ...buttonMixin({
      width: "30px",
      height: "14px",
      textHeight: "4px",
      textWidth: "20px",
      background: buttonColor,
      textColor: buttonTextColor,
      border: borderColor,
    }),
  },
  "&:nth-of-type(2)": {
    ...buttonMixin({
      width: "30px",
      height: "14px",
      textHeight: "4px",
      textWidth: "20px",
      textColor: buttonTextColor,
      border: borderColor,
    }),
  },
}));
export const StyledCategoryDropDown = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledTextHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, color }) => ({
  color: color,
  fontSize: "24px",
  fontWeight: 700,
  marginTop: "14px",
  marginBottom: 52,
}));
export const StyledTicketListTitleArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledStatusTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledTicketTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledLastMessageTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledCreateTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));
export const StyledTicketList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({}));
export const StyledTicketItem = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, hoverColor }) => ({
  padding: "18px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: hoverColor,
  position: "relative",
}));
export const StyledTicketItemStatus = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background = "#F1F6FF" }) => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  background,
}));
export const StyledTicketItemTicketTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor, borderColor }) => ({
  ...fakeTextMixin({ width: "80px", color: textColor }),
  ...msgItemAfterMixin(borderColor),
}));
export const StyledTicketItemLastMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor, borderColor }) => ({
  ...fakeTextMixin({ width: "120px", color: textColor }),
  ...msgItemAfterMixin(borderColor),
}));
export const StyledTicketItemCreate = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({ width: "35px", color: textColor }),
}));
export const StyledDemoTemplateChatArea = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor, primaryColor }) => ({
  border: borderMixin(borderColor),
  flex: 1,
  background: "#FFFFFF",
  borderRadius: "5px 5px 0 0",
  position: "relative",
}));
export const StyledDemoTemplateChatAreaHeaderContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, primaryColor }) => ({
  padding: "10px 15px",
  background: primaryColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px 5px 0 0",
  height: "48px",
}));
export const StyledDemoTemplateChatCreateInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
}));
export const StyledDemoTemplateChatCreateInfoTime = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({ width: "42px", color: textColor }),
  marginBottom: "8px",
}));
export const StyledDemoTemplateChatCreateInfoCategory = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({ width: "75px", color: textColor }),
}));
export const StyledDemoTemplateChatTicketTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  flex: 1,
  ...fakeTextMixin({
    width: "60px",
    height: "7px",
    color: textColor,
  }),
}));
export const StyledDemoTemplateChatTicketStatusInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));
export const StyledDemoTemplateChatTicketStatusText = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  marginBottom: "8px",
  ...fakeTextMixin({ width: "30px", color: textColor }),
}));
export const StyledDemoTemplateChatTicketStatusTag = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({ width: "30px", color: textColor }),
}));
export const StyledDemoTemplateChatContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingTop: "45px",
  paddingBottom: "30px",
}));
export const StyledUserMessageContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

export const StyledUserInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledImgAvatar = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
}));

export const StyledUserMessageName = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "12px",
}));

export const StyledUserMessageItemBlock = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  border: borderMixin(borderColor),
  height: "105px",
  width: "215px",
  padding: "8px",
  borderRadius: "5px",
  marginBottom: "8px",
}));

export const StyledUserMessageItemFrom = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({
    width: "168px",
    height: "7px",
    color: textColor,
  }),
  marginBottom: "8px",
}));

export const StyledUserMessageItemInfoBox = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  border: borderMixin(borderColor),
  height: "17px",
  width: "82px",
  borderRadius: "3px",
  "&:nth-of-type(1)": {
    marginRight: "4px",
  },
}));

export const StyledUserMessageItemMsg = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor }) => ({
  ...fakeTextMixin({
    width: "196px",
    height: "7px",
    color: textColor,
  }),
  marginBottom: "6px",
  "&:last-child": {
    marginBottom: 0,
  },
}));

export const StyledAdminMessageContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({}));

export const StyledAdminInfo = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
}));

export const StyledAdminMessageAvatar = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  width: "13px",
  marginBottom: "3px",
}));

export const StyledAdminMessageItemBlock = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  border: borderMixin(borderColor),
  width: "32px",
  height: "22px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledAdminMessageItemMsgTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(
  ({
    theme,
    textColor,
    width,
    height,
    mb = "8px",
    mt = "0",
    textHoverColor,
    sx,
  }) => ({
    ...fakeTextMixin({
      width,
      height,
      color: textColor,
      textHoverColor: textHoverColor,
    }),
    marginBottom: mb,
    marginTop: mt,
    ...sx,
  })
);

export const StyledAdminMessageItemMsg = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, textColor, width, height, mb = "8px", mt = "0" }) => ({
  ...fakeTextMixin({
    width,
    height,
    color: textColor,
  }),
  marginBottom: mb,
  marginTop: mt,
  opacity: 0.5,
}));

export const StyledDemoTemplateReplyContainer = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  height: "120px",
  borderTop: borderMixin(borderColor),
  padding: "11px 13px",
}));

export const StyledDemoTemplateReplyInput = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  border: borderMixin(borderColor),
  width: "100%",
  height: "75px",
  marginBottom: "5px",
}));

export const StyledDemoTemplateReplyFunctionList = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
}));

export const StyledDemoTemplateReplyUploadImg = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  width: "72px",
  height: "18px",
  border: borderMixin(borderColor),
  borderRadius: "3px",
}));

export const StyledDemoTemplateReplySendButton = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, buttonColor, buttonTextColor }) => ({
  marginLeft: "auto",
  ...buttonMixin({
    background: buttonColor,
    width: "60px",
    height: "18px",
    textHeight: "4px",
    textWidth: "49px",
    textColor: buttonTextColor,
  }),
}));

export const StyledBottomNavigation = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor }) => ({
  boxShadow: `0px -5px 20px 0px #0000000D`,
  height: "75px",
  borderRadius: `0 0 5px 5px`,
  display: "flex",
}));

export const StyledBottomNavigationContentContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, borderColor, color, unRead }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: color,
}));

export const StyledBottomNavigationIcon = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, unRead }) => ({
  position: "relative",
  ...(unRead && {
    "&:after": {
      content: '""',
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      background: "red",
      position: "absolute",
      top: "0px",
      right: "2px",
    },
  }),
}));

export const StyledFixBottomContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, primaryColor, textColor }) => ({
  position: "absolute",
  background: primaryColor,
  bottom: "17px",
  left: "50%",
  width: "142px",
  height: "36px",
  borderRadius: "20px",
  transform: "translateX(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledFixBottomText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, primaryColor, textColor }) => ({
  ...fakeTextMixin({
    width: "88px",
    height: "12px",
    color: textColor,
    borderRadius: "10px",
  }),
}));

import { styled } from "@mui/material/styles";
import TableCell from "@material-ui/core/TableCell";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";

const getZIndex = ({ isHeader, isFixed, rows = 40, rowIndex, isLoading }) => {
  const initZIndex = isHeader ? rows : rows - rowIndex;
  let zIndex = initZIndex;
  if (isFixed) {
    if (isHeader) {
      zIndex = initZIndex + 8;
    } else {
      zIndex = initZIndex + 4;
    }
  } else {
    if (isHeader) {
      zIndex = initZIndex + 6;
    } else {
      zIndex = initZIndex + 2;
    }
  }

  return zIndex;
};

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: isStyledPropsValid,
})(
  ({
    isFixed,
    index,
    cellWidth,
    lastFixedItem,
    isHeader,
    leftDistance,
    rowIndex,
    headers,
    rows = 40,
  }) => {
    const zIndex = getZIndex({ isHeader, isFixed, rows, rowIndex });
    return {
      position: isHeader || isFixed ? "sticky" : "relative",
      minWidth: cellWidth ? `${cellWidth}px` : "unset",
      width: "max-content",
      zIndex,
      // 要是是fixed的欄位需要計算左邊的距離
      ...(isFixed && { left: `${leftDistance}px` }),
      "&:hover": {
        zIndex: zIndex + 4,
      },
      // 要是是欄位fix的table 並且是最後一行fix欄位
      "&:after": {
        ...(isFixed &&
          typeof index === "number" &&
          !isNaN(index) &&
          index === lastFixedItem && {
            content: '""',
            height: "100%",
            width: "45px",
            borderLeft: headers?.length > 8 ? "1px solid #E6E6E6" : "0px",
            background:
              headers?.length > 8
                ? "linear-gradient(85.43deg, rgba(226, 226, 226, 0.3) 3.19%, rgba(248, 248, 248, 0.01) 108.81%)"
                : "transparent",
            position: "absolute",
            display: "block",
            right: "-43px",
            top: 0,
            bottom: 0,
            marginRight: 10,
          }),
      },
    };
  }
);

export const StyledRowLoading = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, rowIndex, rows }) => {
  const zIndex = getZIndex({
    isHeader: false,
    isFixed: false,
    rows: rows,
    rowIndex,
    isLoading: true,
  });
  return {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    background: `${alpha(theme.customColors.white, 0.7)}`,
    zIndex: zIndex + 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.customColors.purple[500],
    fontWeight: 700,
  };
});

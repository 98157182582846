import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { Grid, useMediaQuery, IconButton } from "@material-ui/core";
import moment from "moment";
import { convertToInternationalCurrencySystem } from "features/helpers/bignumber";

import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    minHeight: "calc(100vh - 50px)",
    marginLeft: 20,
    width: "calc(100% - 40px)",
  },
  header: {
    fontWeight: 700,
    fontSize: 20,
  },
  groupHeader: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "22px",

    color: "#383538",
  },
  rules: { color: "#7B61FF", fontWeight: 500, fontSize: 12 },
  ruleTitle: { fontWeight: 500, fontSize: 13, marginTop: 5 },
  ruleValue: { fontWeight: 500, fontSize: 20 },

  rowTitle: {
    fontWeight: 500,
    fontSize: 13,
    color: "#A5A5A5",
    paddingBottom: 12,
  },
  borderLeft: {
    borderLeft: "0.5px solid #D6D6D6",
    paddingLeft: 24,
  },
}));

const GroupCard = ({ group, edit, remove, enter, select, isRow }) => {
  const largeStyle = {
    fontSize: isRow ? 20 : 16,
    fontWeight: isRow ? 500 : 500,
    color: isRow ? "#7B61FF" : "#555555",
  };
  const classes = useStyles();
  const theme = useTheme();

  const renderSettingValue = (settings, key, symbol = "#") => {
    const from = _.get(settings, key + ".from");
    const to = _.get(settings, key + ".to");
    const small = {
      fontSize: isRow ? 10 : 16,
      fontWeight: 500,
      margin: "0 2px",
    };

    if (from && to) {
      return (
        <span style={largeStyle}>
          {symbol}
          {convertToInternationalCurrencySystem(from, 0)}
          {isRow ? " - " : "~"}
          {symbol}
          {convertToInternationalCurrencySystem(to, 0)}
        </span>
      );
    } else if (from) {
      return (
        <span
          style={largeStyle}
        >{`More Than ${symbol}${convertToInternationalCurrencySystem(
          from,
          0
        )}`}</span>
      );
    } else if (to) {
      return (
        <span
          style={largeStyle}
        >{`Under ${symbol}${convertToInternationalCurrencySystem(
          to,
          0
        )}`}</span>
      );
    }

    return <span style={largeStyle}>-</span>;
  };

  const renderDateValue = (settings) => {
    const from = _.get(settings, "timeInterval.from");
    const to = _.get(settings, "timeInterval.to");
    let value = "";
    if (from && to) {
      value = `${moment(from).format("YYYY MMM DD")} ~ ${moment(to).format(
        "YYYY MMM DD"
      )} `;
    } else if (from) {
      value = `${moment(from).format("YYYY MMM DD")} ~ Until Today`;
    } else if (to) {
      value = `Deadline ${moment(to).format("YYYY MMM DD")}`;
    }
    if (value) {
      return <span style={largeStyle}>{value}</span>;
    }

    return <span style={largeStyle}>-</span>;
  };
  if (isRow) {
    return (
      <div
        style={{
          background: "white",
          borderRadius: 5,
          padding: "19px 24px",
          marginTop: 8,
          marginBottom: 24,
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={6} md={3}>
            <div className={classes.rowTitle}>Wallet Balance</div>
            <div className={classes.ruleValue}>
              {renderSettingValue(group.rules, "walletBalance", "$")}
            </div>
          </Grid>
          <Grid item xs={6} md={3} className={classes.borderLeft}>
            <div className={classes.rowTitle}>NFT Amount</div>
            <div className={classes.ruleValue}>
              {renderSettingValue(group.rules, "nftAmount", "#")}
            </div>
          </Grid>
          <Grid item xs={6} md={3} className={classes.borderLeft}>
            <div className={classes.rowTitle}>Transaction</div>
            <div className={classes.ruleValue}>
              {renderSettingValue(group.rules, "txnAmount", "#")}
            </div>
          </Grid>
          <Grid item xs={6} md={3} className={classes.borderLeft}>
            <div className={classes.rowTitle}>Time Interval</div>
            <div>{renderDateValue(group.rules)}</div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: 15,
        color: "#555555",
        fontSize: 13,
        marginRight: 10,
        marginBottom: 10,
        border: "1px solid #A5A5A5",
        borderRadius: 5,
        background: "#FFFFFF",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => {
        if (enter) {
          enter(group);
        }
      }}
    >
      <div
        className="betweenRow"
        style={{
          borderBottom: "0.5px solid #383538",
          paddingBottom: 15,
        }}
      >
        <div className={`${classes.groupHeader} startRow`}>
          <div
            style={{
              background: group.color || "#FAA7E0",
              width: 18,
              height: 18,
              borderRadius: 20,
              marginRight: 6,
            }}
          />
          {group.name}{" "}
          <span
            style={{
              color: "#A5A5A5",
              fontSize: 15,
              fontWeight: 500,
              marginLeft: 5,
            }}
          >
            {_.get(group, "userWallets") && group.userWallets.length}
          </span>
          {edit && (
            <IconButton
              onClick={(e) => {
                edit(group);
                e.stopPropagation();
              }}
              style={{ padding: 5, marginLeft: 5 }}
            >
              <img src={require("assets/img/editButton.svg").default} />
            </IconButton>
          )}
        </div>
        <div>
          {remove && (
            <RemoveConfirm
              onRemove={() => {
                remove(group);
              }}
              title={"Delete your segment"}
            />
          )}
        </div>
      </div>
      <div style={{ marginTop: 13 }}>
        <div className={classes.rules}>Rules:</div>
        <div className={classes.ruleTitle}>
          Wallet Balance:{" "}
          <span className={classes.ruleValue}>
            {renderSettingValue(group.rules, "walletBalance", "$")}
          </span>
        </div>{" "}
        <div className={classes.ruleTitle}>
          NFT Amount:{" "}
          <span className={classes.ruleValue}>
            {renderSettingValue(group.rules, "nftAmount", "#")}
          </span>
        </div>
        <div className={classes.ruleTitle}>
          Transaction:{" "}
          <span className={classes.ruleValue}>
            {renderSettingValue(group.rules, "txnAmount", "#")}
          </span>
        </div>
        <div className={classes.ruleTitle}>
          Time Interval:{" "}
          <span className={classes.ruleValue}>
            {renderDateValue(group.rules)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { useFetchEntityConfig } from "../redux/hooks";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import {
  IconTooltip,
  COLOR,
} from "@metacrm/metacrm-material-ui/dist/IconTooltip";
const useStyles = makeStyles((theme) => ({
  inboxTicketType: {
    textTransform: "uppercase",
    background: "#57AFE0",
    color: "#383538",
    padding: "2px 9px",
    margin: 5,
    marginLeft: 0,
  },
}));

const TicketTypeLabel = ({ ticketType, edit, callback }) => {
  const classes = useStyles();
  const [selectedTicketType, setSelectedTicketType] = useState(
    _.get(ticketType, "_id")
  );
  const { ticketTypeList } = useFetchEntityConfig();
  useEffect(async () => {
    setSelectedTicketType(_.get(ticketType, "_id"));
  }, [ticketType]);
  let background = "#F7F1FE";

  let style = {
    fontSize: 14,
    fontWeight: 500,
    border: "1px solid #E6E6E6",
    borderRadius: 20,
    display: "inline-block",
    minWidth: 162,
    lineHeight: "17px",
    background: background,
    whiteSpace: "nowrap",
    textAlign: "center",
  };
  const truncateLength = 15;
  const renderTicketType = (v) => {
    const isTruncate = v.length > truncateLength;
    if (isTruncate) {
      return (
        <IconTooltip
          content={v}
          icon={
            <span
              className={classes.inboxTicketType}
              style={{ ...style, cursor: "pointer" }}
            >
              {_.truncate(v, { length: truncateLength })}
            </span>
          }
        />
      );
    }
    return (
      <span className={classes.inboxTicketType} style={style}>
        {v}
      </span>
    );
  };
  const typeName = _.get(ticketType, "name");

  if (edit) {
    return (
      <CustomDropdown
        border
        data={_.map(ticketTypeList, (o) => {
          return {
            label: renderTicketType(o.name),
            value: o._id,
          };
        })}
        value={selectedTicketType}
        onSelect={(e) => {
          setSelectedTicketType(e.target.value);
          callback && callback(e.target.value);
        }}
      />
    );
  }
  if (!typeName) return <span />;
  return renderTicketType(typeName);
};

export default TicketTypeLabel;

import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";

import { Button, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { Checkbox, COLOR } from "@metacrm/metacrm-material-ui/dist/Checkbox";
import Box from "@mui/material/Box";
import { get, isEmpty } from "lodash-es";
import axios from "axios";
import { apiUrl } from "features/configure";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAccount, useDisconnect } from "wagmi";
import { getShortAddress, parseCustomDomainUrl } from "features/helpers/utils";
import { useForm } from "react-hook-form";
import { useStepData } from "contexts/stepContext/Step.context";
import { enqueueSnackbar } from "features/common/redux/actions";
import { useIntl } from "react-intl";

import {
  StyledAlreadyAUser,
  StyledConnectWalletContainer,
  StyledRegisterConnectWallet,
} from "./RegisterStep1.styles";

import { StyledRegisterStepTitle } from "../RegisterForm.styles";
import isEmail from "validator/lib/isEmail";

const formDataInit = {
  name: "",
  email: "",
  companyName: "",
};

const RegisterStep1 = ({ onNextStep, onPrevStep, module }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors: errorsInfo, isValid },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: formDataInit,
  });
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { setStepValues, stepData } = useStepData();

  const [formData, setFormData] = useState(formDataInit);

  const [isChecked, setCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { address, isConnected } = useAccount({});
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(stepData)) {
      setFormData(stepData);
      setCheck(true);
      reset(stepData);
    }
  }, [stepData]);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeCheckbox = () => {
    setCheck((prev) => !prev);
  };

  const handleSubmitInfo = async (values) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${apiUrl}/api/entity/register`, {
        companyName: values.companyName,
        email: values.email,
        module,
      });

      setStepValues({
        ...values,
        entityName: data.entityName,
        resendTimestamp: data.resendTimestamp,
      });
      onNextStep();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(
            error,
            "response.data.error",
            get(error, "response.data.message", "error")
          ),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledRegisterStepTitle>Tell us more about you</StyledRegisterStepTitle>
      <StyledConnectWalletContainer width="100%">
        <Box fontSize="12px" mb="3px">
          Connected Wallet Address
        </Box>
        <StyledRegisterConnectWallet>
          {getShortAddress(address)}
        </StyledRegisterConnectWallet>
      </StyledConnectWalletContainer>

      <Box mb={2} width="100%">
        <InputField
          title="Name"
          name="name"
          value={formData.name}
          isRequired
          placeholder="Enter your full name"
          control={control}
          onChange={handleFieldChange}
          errors={get(errorsInfo, "name.message")}
          errorTextAbsolute={false}
          rules={{
            required: {
              value: true,
              message: "Name is required",
            },
          }}
        ></InputField>
      </Box>

      <Box mb={2} width="100%">
        <InputField
          title="Email"
          name="email"
          value={formData.email}
          isRequired
          placeholder="Enter your email"
          control={control}
          onChange={handleFieldChange}
          errors={get(errorsInfo, "email.message")}
          errorTextAbsolute={false}
          rules={{
            required: {
              value: true,
              message: "Email is required",
            },
            validate: (value) =>
              isEmail(value) ||
              formatMessage({
                id: "global.error.emailValid",
              }),
          }}
        ></InputField>
      </Box>

      <Box mb={4} width="100%">
        <InputField
          title="Company Name"
          name="companyName"
          value={formData.companyName}
          isRequired
          placeholder="Enter your company name"
          onChange={handleFieldChange}
          control={control}
          errorTextAbsolute={false}
          errors={get(errorsInfo, "companyName.message")}
          rules={{
            required: {
              value: true,
              message: "Company Name required",
            },
            pattern: {
              value: /^[A-Za-z0-9]+$/,
              message: "Alphanumeric characters only.",
            },
          }}
        ></InputField>
      </Box>

      <Box mb={4} width="100%">
        <Checkbox
          checked={isChecked}
          onChange={handleChangeCheckbox}
          label={
            <>
              I agree to the{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                style={{ color: theme.customColors.purple[500] }}
              >
                terms and privacy
              </Link>{" "}
              statements.
            </>
          }
        />
      </Box>

      <Button
        sx={{ width: "100%" }}
        disabled={!isValid || !isChecked}
        onClick={handleSubmit(handleSubmitInfo)}
        isLoading={isLoading}
      >
        Get Started
      </Button>
      <Box mt="10px" display="flex" justifyContent="center">
        <StyledAlreadyAUser>
          Already a user?
          <Button
            sx={{ marginLeft: "5px" }}
            variant={VARIANT.TEXT}
            onClick={() => disconnect()}
          >
            Switch wallet
          </Button>
        </StyledAlreadyAUser>
      </Box>
    </>
  );
};

export default RegisterStep1;

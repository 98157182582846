import { React, useState } from "react";
import { SafeInjectProvider } from "./SafeInjectContext.js";
import AppLoader from "./AppLoader";
import { Modal } from "@material-ui/core";

function Impersonator({ address, webUrl, rpcURL, open, closeAction, chainId }) {
  return (
    <SafeInjectProvider>
      <Modal open={open} className={"modal"}>
        <div className={"loadingPaper"}>
          <div className="App">
            <AppLoader
              appUrl={webUrl}
              address={address}
              rpcURL={rpcURL}
              closeAction={closeAction}
              chainId={chainId}
            />
          </div>
        </div>
      </Modal>
    </SafeInjectProvider>
  );
}

export default Impersonator;

import { Box } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { useIntl } from "react-intl";
import CRMTable from "@metacrm/metacrm-material-ui/dist/Table";
import { map, upperFirst } from "lodash-es";
import useGetPerformanceTable from "./hook/useGetPerformanceTable";
import CustomPagination from "components/CustomPagination/CustomPagination";
import IconTooltip from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Switch from "@metacrm/metacrm-material-ui/dist/Switch";
import { PerformanceContext } from "features/metadesk/campaign/campaignPerformance/context/PerformanceProvider";
import moment from "moment";

export default function PerformanceTable() {
  const {
    headCells,
    data,
    loading,
    page,
    setPage,
    totalCount,
    handleRowClick,
    handleRequestSort,
    order,
    orderBy,
    promotype,
  } = useGetPerformanceTable();
  const { isAdvanced, setIsAdvanced } = useContext(PerformanceContext);
  const { formatMessage } = useIntl();

  const PAGE_COUNT = 20;
  const rowData = data.data;

  const channelMapping = {
    "": "",
    push: "Push",
    xmtp: "XMTP",
  };

  const renderContents = (report) => {
    const isMessageGoal = (value) => {
      if (report?.goal !== "message") return value;
      return (
        <IconTooltip
          icon="-"
          content={formatMessage({
            id: "global.tooltip.uninstalledTrackingTag.notification",
          })}
        />
      );
    };

    const contents = [
      <Box slot={promotype === "tracker" ? "mainDimensions" : "Campaign"}>
        {report.name}
      </Box>,
      <Box slot="channel">{channelMapping[report.channel] || "-"}</Box>,
      <Box slot="dimensionValue">{report.dimensionValue || "-"}</Box>,
      <Box slot="openCount">{report.openCount}</Box>,
      <Box slot="linkClicked">{isMessageGoal(report.linkClicked)}</Box>,
      <Box slot="ctr">
        {isMessageGoal((report.ctr || 0)?.toFixed(2) + "%")}
      </Box>,
      <Box slot="walletConnected">{isMessageGoal(report.walletConnected)}</Box>,
      <Box slot="objectiveAchieved">
        {isMessageGoal(report.objectiveAchieved)}
      </Box>,
      <Box slot="conversionRate">
        {isMessageGoal(report.conversionRate?.toFixed(2) + "%")}
      </Box>,
      <Box slot="startFrom">
        {report.startFrom
          ? moment(report.startFrom).format("YYYY/MM/DD HH:mm")
          : ""}
      </Box>,
    ];

    return contents;
  };

  return (
    <>
      {/* <Loading open={loading} fullScreen={false} white /> */}
      <CRMTable
        isDraggable={true}
        containerStyle={{ minHeight: 300 }}
        rowData={rowData}
        headers={headCells}
        noKey={true}
        contents={map(rowData, (user, i) => renderContents(user))}
        onRowClick={handleRowClick}
        onRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        customTableTop={
          promotype === "tracker" && (
            <Switch
              label={
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ marginRight: "5px" }}
                >
                  <Box mr="5px">
                    {formatMessage({
                      id: "campaignPerformance.trackerTab.advanced.sorting",
                    })}
                  </Box>
                  <IconTooltip
                    content={formatMessage({
                      id: "campaignPerformance.tab.tracker.advancedToolTip",
                    })}
                  />
                </Box>
              }
              labelPlacement="start"
              onChange={() => {
                setIsAdvanced(!isAdvanced);
              }}
              checked={isAdvanced}
            />
          )
        }
      />
      <CustomPagination
        shape="rounded"
        PAGE_COUNT={PAGE_COUNT}
        count={totalCount}
        page={page}
        setPage={(v) => setPage(v)}
      />
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import DraggableListItem from "./DraggableListItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Menu, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  StyledMenu,
  StyledMenuItem,
  StyledDragHandlerBarArea,
  StyledMenuTitle,
  StyledShownArea,
} from "./DraggableList.styles";
import {
  Button,
  IconButton,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { alertServerError } from "features/helpers/utils";
import { useFetchUserList } from "features/metadesk/redux/fetchUserList";
import RemoveConfirm from "components/RemoveConfirm/RemoveConfirm";
import { Box } from "@mui/material";
import { Checkbox } from "@metacrm/metacrm-material-ui/dist/Checkbox";

const DraggableList = ({
  items,
  setHeader,
  onDragEnd,
  onShowOrHide,
  onReset,
  onSave,
  edit,
  type,
}) => {
  const [entityName] = useOutletContext();
  const { setSavedHeaderItems, savedHeaderItems } = useFetchUserList();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleShow = (event, item) => {
    event.stopPropagation();
    onShowOrHide({ ...item, show: true });
  };

  const handleOpenCustomize = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCustomize = () => {
    setAnchorEl(null);
  };

  const handleRemoveHeader = async (event, item) => {
    try {
      event.stopPropagation();
      if (!item._id) return;
      const newHeaders = [...items];
      _.remove(newHeaders, (i) => i._id === item._id);
      const res = await axios.delete(
        apiUrl + `/api/userSource/${entityName}/csv/header/${item._id}`
      );
      setHeader(newHeaders);
    } catch (err) {
      console.log(err);
      dispatch(alertServerError(err));
    }
  };

  return (
    <Box position="absolute" top={"-50px"} right={0} zIndex={9999}>
      <Box
        onClick={handleOpenCustomize}
        width="fit-content"
        p="10px"
        borderRadius="4px"
        display="flex"
        sx={{
          cursor: "pointer",
          border: anchorEl ? "1px solid #7B61FF" : "1px solid white",
          color: anchorEl ? "#7B61FF" : "#555",
          bgcolor: anchorEl ? "#FAF6FF" : "white",
        }}
      >
        <i className="meta-crm-icon-ic_customize font-size-16" />
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseCustomize}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          className="betweenRow"
          p="12px 20px"
          position={"sticky"}
          sx={{ background: "white", top: 0, zIndex: 1 }}
        >
          <StyledMenuTitle disableRipple>Customize Columns</StyledMenuTitle>
          <Box display="flex" alignItems="center">
            <Box
              onClick={onReset}
              color={edit.canReset ? "#7B61FF" : "#A5A5A5"}
              sx={{ cursor: "pointer", fontSize: "12px" }}
            >
              Reset
            </Box>
            <Box
              onClick={onSave}
              color={edit.canSave ? "#7B61FF" : "#A5A5A5"}
              sx={{ cursor: "pointer", fontSize: "12px", ml: "16px" }}
            >
              Save
            </Box>
          </Box>
        </Box>

        <StyledShownArea>
          <MenuItem disabled className="sub-title">
            Shown
          </MenuItem>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {_.map(items, (item, index) => {
                    if (!item.show) {
                      return null;
                    }
                    return (
                      <DraggableListItem
                        item={item}
                        index={index}
                        key={item.id}
                        onShowOrHide={onShowOrHide}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </StyledShownArea>
        <MenuItem disabled className="sub-title">
          Hidden
        </MenuItem>
        {_.map(items, (item, index) => {
          if (item.show) {
            return null;
          }
          return (
            <StyledMenuItem key={item.id}>
              <Checkbox
                value={item}
                checked={false}
                onChange={(event) => handleShow(event, item)}
                label={
                  <Box ml="4px">{_.truncate(item.title, { length: 20 })}</Box>
                }
                controlStyle={{ width: "100%" }}
              />
              <div style={{ paddingRight: 10 }}>
                {item._id && (
                  <RemoveConfirm
                    title={"Delete"}
                    text={`Are you sure you want to delete this column?`}
                    onRemove={(event) => {
                      handleRemoveHeader(event, item);
                    }}
                  />
                )}
              </div>
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </Box>
  );
};

export default React.memo(DraggableList);

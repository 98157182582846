import React, { useState, useEffect } from "react";
import _ from "lodash";
import { get, isEmpty, has } from "lodash-es";

import Box from "@mui/material/Box";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";

import { useDispatch, useSelector } from "react-redux";
import {
  useFetchEntityConfig,
  useFetchWidgetConfig,
} from "features/metadesk/redux/hooks";

import { useOutletContext, useNavigate, Link } from "react-router-dom";
import { parseCustomDomainUrl } from "features/helpers/utils";

import axios from "axios";
import { apiUrl } from "features/configure";

import { StyledWidgetButtonContainer } from "./CustomServiceWidget.styles";
import { StyledSettingTitle } from "../SettingCommon.styles";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import {
  Button,
  SIZE,
  COLOR,
  VARIANT,
} from "@metacrm/metacrm-material-ui/dist/Button";
import { StatusLabel } from "@metacrm/metacrm-material-ui/dist/StatusLabel";
import { useIntl } from "react-intl";

const WidgetButton = ({ widgetItemInfo }) => {
  const {
    apiKey,
    created: apiKeyCreated,
    _id,
    name,
    isNewAdd,
    connected,
  } = widgetItemInfo;
  const [entityName, isCustomDomain] = useOutletContext();

  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const handleGoToSettingWidget = (e) => {
    e.preventDefault();
    navigate(
      parseCustomDomainUrl(
        isCustomDomain,
        entityName,
        `/settings/customization/widgetSetting/${_id}`
      )
    );
  };

  // 'Undetected' 'Waiting' 'Enabled' 'Failed'
  const widgetIntegrationStatus = connected ? "Enabled" : "Waiting";
  const renderWidgetIntegrationStatus = () => {
    if (widgetIntegrationStatus === "Waiting") {
      return (
        <StatusLabel color={COLOR.WARNING}>
          {formatMessage({
            id: "global.status.waiting",
          })}
        </StatusLabel>
      );
    }
    if (widgetIntegrationStatus === "Enabled") {
      return (
        <StatusLabel color={COLOR.SECONDARY}>
          {formatMessage({
            id: "global.status.enabled",
          })}
        </StatusLabel>
      );
    }
  };
  return (
    <>
      <StyledWidgetButtonContainer onClick={handleGoToSettingWidget}>
        <Box>{renderWidgetIntegrationStatus()}</Box>
        <Box
          fontWeight={700}
          fontSize={"16px"}
          color={(theme) => theme.customColors.grey[700]}
          mt="5px"
        >
          {name}
        </Box>
      </StyledWidgetButtonContainer>
    </>
  );
};

export default WidgetButton;

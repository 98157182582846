import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AutoCompleteDropdown } from "@metacrm/metacrm-material-ui/dist/AutoCompleteDropdown";
import { selectEcosystemName } from "features/metadesk/redux/entityConfig/entityConfig.selector";

import {
  StyledRadioButton,
  StyledFormControlLabel,
  StyledRadioIcon,
  StyledRadioCheckedIcon,
  StyledRadioLabel,
  StyledCollapseContent,
  StyledFieldTitle,
  StyledErrorHint,
  StyledFetchABIButton,
  StyledCustomPaper,
  StyledAutoCompleteTextField,
} from "./CampaignStep3.styles";
import {
  StyledRecentObjectiveContainer,
  StyledRecentObjectiveButtonArea,
  StyledObjectiveListContainer,
  StyledRecentObjectiveItem,
  StyledRecentObjectiveItemTitle,
  StyledRecentObjectiveItemName,
  StyledRecentObjectiveItemContent,
  StyledToggleButtonGroup,
} from "./Objective.styles";
import Collapse from "@mui/material/Collapse";
import RadioGroup from "@mui/material/RadioGroup";
import { IconTooltip } from "@metacrm/metacrm-material-ui/dist/IconTooltip";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { InputField, SIZE } from "@metacrm/metacrm-material-ui/dist/InputField";
import { get, isEmpty } from "lodash-es";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
import { enqueueSnackbar } from "features/common/redux/actions";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { StyledAutoComplete } from "features/metadesk/chat/MacroModal/MacroModal.styles";
import { isAddress } from "features/helpers/utils";
import { IconButton } from "@metacrm/metacrm-material-ui/dist/Button";
import { useTheme } from "@mui/styles";
import ToggleButton from "@mui/material/ToggleButton";
import { useIntl } from "react-intl";
import { selectEntityChainList } from "features/metadesk/redux/entityConfig/entityConfig.selector";
import { convertIfNumeric } from "features/helpers/utils";

export const objectiveMappingName = {
  "": "All",
  tokenIn: "Token Transfer In",
  tokenOut: "Token Transfer Out",
  mintNFT: "Mint NFT",
  contract: "Contract Interaction",
};

export default function Objective({
  onDelete,
  objectiveIndex,
  latestObjectives = [],
  formData = { objectives: [] },
  setFormData = () => {},
  readonly,
  isTargetEvent,
  indexNumber,
}) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [entityName, isCustomDomain] = useOutletContext();
  const stepData = formData;
  const setStepValues = setFormData;
  const { objectives } = stepData;
  const [isFetchingLoading, setIsFetchingLoading] = useState(false);
  const objectiveItem = _.get(formData, `objectives[${objectiveIndex}]`);
  const [usableABI, setUsableABI] = useState([]);
  const [fetchABICount, setFetchABICount] = useState(0);
  const [changeTypeManualCount, setChangeTypeManualCount] = useState(0);
  const [recentUsedExpand, setRecentUsedExpand] = useState(false);
  const [recentObjective, setRecentObjective] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const ecosystemName = useSelector(selectEcosystemName);
  const chainList = useSelector(selectEntityChainList);

  const chainListForDropdown = _.map(chainList, (chain) => {
    return {
      label: chain.name,
      value: chain.value,
      name: chain.name,
      imgURL: chain.logo_url,
    };
  });

  const theme = useTheme();

  const {
    control,
    formState: { errors: errorsInfo },
    clearErrors,
    getFieldState,
    trigger,
  } = useFormContext();

  const { append, replace } = useFieldArray({
    control: control,
    name: "objectiveList",
  });

  useEffect(() => {
    const newItem = {
      chainId: "",
      smartContractAddress: "",
      smartContractABI: "",
      method: null,
      type: objectiveItem.type,
      _id: objectiveItem._id,
    };
    const newObjectives = replaceObjectById(
      objectives,
      objectiveItem._id,
      newItem
    );

    setStepValues({ objectives: newObjectives });
    setRecentObjective(null);

    const stepValuesForReactHookForm = newObjectives.map((item) => {
      return {
        type: item.type,
        chainId: item.chainId,
        smartContractAddress: item.smartContractAddress,
        smartContractABI: item.smartContractABI,
        method: item.method,
      };
    });
    replace(stepValuesForReactHookForm);
    clearErrors([
      `objectiveList[${objectiveIndex}].chainId`,
      `objectiveList[${objectiveIndex}].smartContractAddress`,
      `objectiveList[${objectiveIndex}].smartContractABI`,
      `objectiveList[${objectiveIndex}].method`,
    ]);
  }, [changeTypeManualCount]);

  useEffect(() => {
    const setDataFromRecentObjective = async () => {
      if (recentObjective) {
        const targetObj = latestObjectives.find(
          (item) => item._id === recentObjective
        );

        const newItem = {
          ...objectiveItem,
          smartContractAddress: targetObj.smartContractAddress,
          chainId: targetObj.chainId,
          type: targetObj.type,
          smartContractABI: targetObj.smartContractABI,
        };
        const newObjectives = replaceObjectById(
          objectives,
          objectiveItem._id,
          newItem
        );
        if (targetObj.smartContractABI) {
          // 要是有ABI 先把ABI filter出來 拿到裡面所有的method
          // 然後將targetObj.method設回newItem
          const result = filterDataABI(targetObj.smartContractABI);
          setUsableABI(result);
          newItem.method = targetObj.method;
        } else {
          newItem.method = null;
        }
        setStepValues({ objectives: newObjectives });

        const stepValuesForReactHookForm = newObjectives.map((item) => {
          return {
            type: item.type,
            chainId: item.chainId,
            smartContractAddress: item.smartContractAddress,
            smartContractABI: item.smartContractABI,
            method: item.method,
          };
        });
        replace(stepValuesForReactHookForm);
      }
    };

    setDataFromRecentObjective();
  }, [recentObjective]);

  const isFetchABICanClick = () => {
    // chain address都要有值 並且符合驗證規則 才能點擊fetchABI
    if (readonly) return false;
    const chain = get(objectiveItem, "chainId");
    const smartContractAddress = get(objectiveItem, "smartContractAddress");

    if (
      chain &&
      !getFieldState(`objectiveList[${objectiveIndex}].chainId`).invalid &&
      smartContractAddress &&
      !getFieldState(`objectiveList[${objectiveIndex}].smartContractAddress`)
        .invalid
    ) {
      return true;
    }

    return false;
  };

  const handleDeleteObjective = () => {
    onDelete(objectiveItem._id, objectiveIndex);
  };

  // fieldName是用來control在step的value用的,而name是用來給react-hook-form用的
  const handleChange = ({ event, fieldName }) => {
    const { value } = event.target;
    const newItem = { ...objectiveItem, [fieldName]: value };
    const newObjectives = replaceObjectById(
      objectives,
      objectiveItem._id,
      newItem
    );
    setStepValues({ objectives: newObjectives });
  };

  const replaceObjectById = (array, targetId, newObject) => {
    // 淺拷貝
    const newArray = array.slice();
    const index = newArray.findIndex((obj) => obj._id === targetId);
    if (index !== -1) {
      newArray[index] = newObject;
    }
    return newArray;
  };

  const handleTypesChange = (event) => {
    const newItem = { ...objectiveItem, type: event.target.value };
    const newObjectives = replaceObjectById(
      objectives,
      objectiveItem._id,
      newItem
    );
    setStepValues({ objectives: newObjectives });
    setChangeTypeManualCount((prev) => prev + 1);
  };

  const handleChainSelect = (event, onFormChange) => {
    handleChange({ event: event, fieldName: "chainId" });
    onFormChange(event.target.value);
  };

  const handleChangeDropDown = (event, value, name) => {
    const e = { target: { name: name, value: value } };
    handleChange({ event: e, fieldName: name });
  };

  const parseABIToOptions = (ABI) => {
    const options = ABI.map((ABIItem, key) => {
      return {
        label: ABIItem.name,
        value: ABIItem.name,
        id: ABIItem.id,
      };
    });

    return options;
  };

  const targetFunctionOptions = parseABIToOptions(usableABI);

  const handleFetchABI = async () => {
    try {
      setIsFetchingLoading(true);
      let result = await axios.get(
        `${apiUrl}/api/campaign/${entityName}/contract/fetchABI`,
        {
          params: {
            chainId: get(objectiveItem, "chainId"),
            address: get(objectiveItem, "smartContractAddress"),
          },
        }
      );
      const ABIText = JSON.stringify(result.data);
      // setFormData({ ...formData, contractABI: ABIText });
      const newItem = { ...objectiveItem, smartContractABI: ABIText };
      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );

      setStepValues({ objectives: newObjectives });

      const stepValuesForReactHookForm = newObjectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
        };
      });
      replace(stepValuesForReactHookForm);
      await trigger();
      // 為了讓useEffect執行 handleFilterABIData,因為要是在這裡直接執行handleFilterABIData,那麽裡面的finally會比上面的setStepValues更早執行
      setFetchABICount((prev) => prev + 1);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: get(error, "response.data.error", "error"),
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      setIsFetchingLoading(false);
    }
  };

  const filterDataABI = (ABI) => {
    let ABIArray;
    let ABINotViewAndTypeFunction;

    if (ecosystemName === "Solana") {
      ABIArray = JSON.parse(ABI).instructions;
      ABINotViewAndTypeFunction = ABIArray;
    } else {
      ABIArray = JSON.parse(ABI);
      ABINotViewAndTypeFunction = ABIArray.filter(
        (ABIItem) =>
          ABIItem.type === (isTargetEvent ? "event" : "function") &&
          ABIItem.stateMutability !== "view"
      );
    }

    const ABINotViewAndTypeFunctionWidthId = ABINotViewAndTypeFunction.map(
      (ABIItem, index) => {
        return { ...ABIItem, id: index };
      }
    );

    return ABINotViewAndTypeFunctionWidthId;
  };

  useEffect(() => {
    // 第一次進來處理ABI資料時 不用把targetFunctionValue清空
    if (!isMounted) {
      handleFilterABIData(objectiveItem.smartContractABI, false);
    } else {
      handleFilterABIData(objectiveItem.smartContractABI);
    }
    setIsMounted(true);
  }, [fetchABICount]);

  const handleFilterABIData = async (ABI, clearTargetFunctionValue = true) => {
    if (!ABI?.trim()) {
      setUsableABI([]);

      // 元件第一次掛載 倒入資料時 不用把targetFunctionValue清空
      const newItem = clearTargetFunctionValue
        ? { ...objectiveItem, method: null }
        : objectiveItem;

      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );
      setStepValues({ objectives: newObjectives });
      return;
    }
    let tempUsableABI = [];
    try {
      tempUsableABI = filterDataABI(ABI);
      setUsableABI(tempUsableABI);
    } catch (error) {
      setUsableABI([]);
      tempUsableABI = [];
      dispatch(
        enqueueSnackbar({
          message: "Please confirm ABI is correct",
          options: {
            variant: "error",
          },
        })
      );
    } finally {
      // 元件第一次掛載 倒入資料時 不用把targetFunctionValue清空
      const newItem = clearTargetFunctionValue
        ? { ...objectiveItem, method: null }
        : { ...objectiveItem, method: objectiveItem.method };

      const newObjectives = replaceObjectById(
        objectives,
        objectiveItem._id,
        newItem
      );
      setStepValues({ objectives: newObjectives });
      const stepValuesForReactHookForm = newObjectives.map((item) => {
        return {
          type: item.type,
          chainId: item.chainId,
          smartContractAddress: item.smartContractAddress,
          smartContractABI: item.smartContractABI,
          method: item.method,
        };
      });
      await replace(stepValuesForReactHookForm);
      trigger();
    }
  };

  const renderChainAndAddress = () => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box position="relative">
          <StyledFieldTitle isRequired>
            {formatMessage({ id: "campaignBuild.thirdStep.field.chain.title" })}
          </StyledFieldTitle>

          <AutoCompleteDropdown
            disabled={readonly}
            value={chainListForDropdown.find(
              (option) => option.value == objectiveItem.chainId
            )}
            options={chainListForDropdown}
            onChange={(event, v) => {
              handleChange({
                event: { target: { value: v?.value } },
                fieldName: "chainId",
              });
            }}
            control={control}
            name={`objectiveList[${objectiveIndex}].chainId`}
            size={SIZE.LARGE}
            rules={{
              required: {
                value: true,
                message: formatMessage({
                  id: "campaignBuild.thirdStep.field.chain.error.required",
                }),
              },
            }}
            withIcon
          />
          <StyledErrorHint>
            {get(
              errorsInfo,
              `objectiveList.[${[objectiveIndex]}].chainId.message`
            )}
          </StyledErrorHint>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <InputField
          disabled={readonly}
          control={control}
          title={formatMessage({
            id:
              ecosystemName !== "Solana"
                ? "campaignBuild.thirdStep.field.contractAddress.title"
                : "campaignBuild.thirdStep.field.programID.title",
          })}
          isRequired
          size={SIZE.LARGE}
          onChange={(event) =>
            handleChange({ event, fieldName: "smartContractAddress" })
          }
          name={`objectiveList[${objectiveIndex}].smartContractAddress`}
          value={objectiveItem.smartContractAddress}
          errorTextAbsolute
          errors={get(
            errorsInfo,
            `objectiveList.[${[objectiveIndex]}].smartContractAddress.message`
          )}
          placeholder={formatMessage({
            id:
              ecosystemName !== "Solana"
                ? "campaignBuild.thirdStep.field.contractAddress.placeholder"
                : "campaignBuild.thirdStep.field.programID.placeholder",
          })}
          rules={{
            required: {
              value: true,
              message: formatMessage({
                id: "campaignBuild.thirdStep.field.contractAddress.error.required",
              }),
            },
            validate: (value) =>
              isAddress(value) ||
              formatMessage({
                id: "campaignBuild.thirdStep.field.contractAddress.error.notValid",
              }),
          }}
        />
      </Grid>
    </Grid>
  );

  const handleExpandClick = () => {
    setRecentUsedExpand(!recentUsedExpand);
  };

  const handleRecentObjective = (event, newRecentObjective) => {
    setRecentObjective(newRecentObjective);
    setRecentUsedExpand(false);
  };

  const renderCampaignContractObjective = () => {
    return (
      <>
        {renderChainAndAddress()}
        <Box position="relative">
          {isFetchingLoading && <LoadingComponent isAbsolute />}

          <Box mt={3} mb={3}>
            <InputField
              disabled={readonly}
              control={control}
              title={formatMessage({
                id:
                  ecosystemName !== "Solana"
                    ? "campaignBuild.thirdStep.field.ContractABI.title"
                    : "campaignBuild.thirdStep.field.programIDL.title",
              })}
              isRequired
              size={SIZE.LARGE}
              onChange={(event) =>
                handleChange({ event, fieldName: "smartContractABI" })
              }
              name={`objectiveList[${objectiveIndex}].smartContractABI`}
              multiline={true}
              rows={3}
              value={objectiveItem.smartContractABI}
              errorTextAbsolute
              onBlur={() => handleFilterABIData(objectiveItem.smartContractABI)}
              errors={get(
                errorsInfo,
                `objectiveList.[${[objectiveIndex]}].smartContractABI.message`
              )}
              rules={{
                required: {
                  value: true,
                  message: formatMessage({
                    id:
                      ecosystemName !== "Solana"
                        ? "campaignBuild.thirdStep.field.ContractABI.error.required"
                        : "campaignBuild.thirdStep.field.programIDL.error.required",
                  }),
                },
              }}
            />
          </Box>
          {ecosystemName !== "Solana" && !readonly && (
            <StyledFetchABIButton
              readonly={!isFetchABICanClick()}
              onClick={handleFetchABI}
              component="button"
            >
              {formatMessage({
                id: "campaignBuild.thirdStep.fetchABI.btn",
              })}
            </StyledFetchABIButton>
          )}
        </Box>
        <Box position="relative" mb={3}>
          <StyledFieldTitle isRequired>
            {isTargetEvent
              ? formatMessage({
                  id: "campaignBuild.thirdStep.field.targetEvent.title",
                })
              : formatMessage({
                  id: "campaignBuild.thirdStep.field.targetFunction.title",
                })}
          </StyledFieldTitle>
          {readonly ? (
            <h3>{objectiveItem.method}</h3>
          ) : (
            <Controller
              name={`objectiveList[${objectiveIndex}].method`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "target function is required",
                },
              }}
              render={({ field: { onChange: onFormChange, ...field } }) => (
                <StyledAutoComplete
                  readonly={readonly}
                  sx={{ width: "100%" }}
                  disableClearable
                  size={SIZE.LARGE}
                  options={targetFunctionOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, v) => {
                    handleChangeDropDown(event, v.value, "method");
                    onFormChange(v);
                  }}
                  // 或是為null是為了避免targetFunctionOptions.find 返回為undefined 進而導致 AutoComplete 被轉為 uncontrolled
                  value={
                    targetFunctionOptions.find(
                      (option) => objectiveItem.method === option.value
                    ) || null
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <StyledAutoCompleteTextField
                      {...params}
                      placeholder={
                        isTargetEvent
                          ? formatMessage({
                              id: "campaignBuild.thirdStep.field.targetEvent.placeholder",
                            })
                          : formatMessage({
                              id: "campaignBuild.thirdStep.field.targetFunction.placeholder",
                            })
                      }
                    />
                  )}
                  PaperComponent={StyledCustomPaper}
                />
              )}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box mb={3}>
        <Box
          fontWeight={700}
          color={(theme) => theme.customColors.grey[700]}
          display={"flex"}
          alignItems={"center"}
          mb={1}
        >
          {formatMessage({ id: "campaignBuild.thirdStep.objective.title" })}{" "}
          {indexNumber || objectiveIndex + 1}
          {onDelete && (
            <IconButton
              className="list-item-btn"
              size={20}
              onClick={handleDeleteObjective}
              color={theme.customColors.grey[500]}
              sx={{ marginLeft: "5px" }}
            >
              <i className="meta-crm-icon-ic_trash font-size-20" />
            </IconButton>
          )}
        </Box>
        <Box mb={2}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={_.get(objectiveItem, "type", "")}
            onChange={(e) => handleTypesChange(e)}
          >
            <Grid container>
              {ecosystemName !== "Solana" && !isTargetEvent && (
                <>
                  <Box component={Grid} item xs={12} sm={3}>
                    <StyledFormControlLabel
                      value="tokenIn"
                      control={
                        <StyledRadioButton
                          icon={<StyledRadioIcon />}
                          checkedIcon={<StyledRadioCheckedIcon />}
                        />
                      }
                      label={
                        <StyledRadioLabel>
                          {formatMessage({
                            id: "global.objective.tokenTransferIn",
                          })}{" "}
                          <Box ml="5.5px" display="flex">
                            <IconTooltip
                              content={formatMessage({
                                id: "tooltip.objective.tokenTransferIn",
                              })}
                            />
                          </Box>
                        </StyledRadioLabel>
                      }
                    />
                  </Box>

                  <Box component={Grid} item xs={12} sm={3}>
                    <StyledFormControlLabel
                      value="tokenOut"
                      control={
                        <StyledRadioButton
                          icon={<StyledRadioIcon />}
                          checkedIcon={<StyledRadioCheckedIcon />}
                        />
                      }
                      label={
                        <StyledRadioLabel>
                          {formatMessage({
                            id: "global.objective.tokenTransferOut",
                          })}
                          <Box ml="5.5px" display="flex">
                            <IconTooltip
                              content={formatMessage({
                                id: "tooltip.objective.tokenTransferOut",
                              })}
                            />
                          </Box>
                        </StyledRadioLabel>
                      }
                    />
                  </Box>

                  <Box component={Grid} item xs={12} sm={3}>
                    <StyledFormControlLabel
                      value="mintNFT"
                      control={
                        <StyledRadioButton
                          icon={<StyledRadioIcon />}
                          checkedIcon={<StyledRadioCheckedIcon />}
                        />
                      }
                      label={
                        <StyledRadioLabel>
                          {formatMessage({
                            id: "global.objective.mintNFT",
                          })}
                          <Box ml="5.5px" display="flex">
                            <IconTooltip
                              content={formatMessage({
                                id: "tooltip.objective.mintNFT",
                              })}
                            />
                          </Box>
                        </StyledRadioLabel>
                      }
                    />
                  </Box>
                </>
              )}
              {!isTargetEvent && (
                <Box component={Grid} item xs={12} sm={3}>
                  <StyledFormControlLabel
                    value="contract"
                    control={
                      <StyledRadioButton
                        icon={<StyledRadioIcon />}
                        checkedIcon={<StyledRadioCheckedIcon />}
                      />
                    }
                    label={
                      <StyledRadioLabel>
                        {formatMessage({
                          id: "global.objective.contractInteraction",
                        })}
                        <Box ml="5.5px" display="flex">
                          <IconTooltip
                            content={formatMessage({
                              id: "tooltip.objective.contractInteraction",
                            })}
                          />
                        </Box>
                      </StyledRadioLabel>
                    }
                  />
                </Box>
              )}
            </Grid>
          </RadioGroup>

          {objectiveItem.type === "tokenIn" && (
            <StyledCollapseContent>
              {renderChainAndAddress()}
            </StyledCollapseContent>
          )}

          {objectiveItem.type === "tokenOut" && (
            <StyledCollapseContent>
              {renderChainAndAddress()}
            </StyledCollapseContent>
          )}

          {objectiveItem.type === "mintNFT" && (
            <StyledCollapseContent>
              {renderChainAndAddress()}
            </StyledCollapseContent>
          )}

          {objectiveItem.type === "contract" && (
            <StyledCollapseContent>
              {renderCampaignContractObjective()}
            </StyledCollapseContent>
          )}
        </Box>
        {!isEmpty(latestObjectives) && (
          <Box>
            <StyledRecentObjectiveContainer>
              <StyledRecentObjectiveButtonArea
                expanded={recentUsedExpand}
                onClick={handleExpandClick}
              >
                <Box>
                  {formatMessage({
                    id: "campaignBuild.thirdStep.recentUsedObjectives",
                  })}
                </Box>
                <i className="meta-crm-icon-ic_arrowLineDown font-size-20" />
              </StyledRecentObjectiveButtonArea>

              <Collapse in={recentUsedExpand} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    background: theme.customColors.grey[200],
                    padding: "16px",
                    borderTop: `1px dashed ${theme.customColors.grey[300]}`,
                  }}
                >
                  <StyledToggleButtonGroup
                    value={recentObjective}
                    exclusive
                    onChange={handleRecentObjective}
                  >
                    <StyledObjectiveListContainer
                      component={Grid}
                      container
                      spacing={1}
                    >
                      {latestObjectives.map((latestObjectiveItem, index) => (
                        <Grid item xs={12} sm={3} key={latestObjectiveItem._id}>
                          <StyledRecentObjectiveItem
                            component={ToggleButton}
                            value={latestObjectiveItem._id}
                          >
                            <Box mb={2}>
                              <StyledRecentObjectiveItemTitle>
                                {formatMessage({
                                  id: "campaignBuild.thirdStep.recentObjectiveBlock.objective.title",
                                })}
                              </StyledRecentObjectiveItemTitle>
                              <StyledRecentObjectiveItemName>
                                {objectiveMappingName[latestObjectiveItem.type]}
                              </StyledRecentObjectiveItemName>
                            </Box>

                            <Box mb={2}>
                              <StyledRecentObjectiveItemTitle>
                                {formatMessage({
                                  id: "campaignBuild.thirdStep.recentObjectiveBlock.chain.title",
                                })}
                              </StyledRecentObjectiveItemTitle>
                              <StyledRecentObjectiveItemContent>
                                <Box display={"flex"} alignItems={"center"}>
                                  <Box
                                    component="img"
                                    width={"18px"}
                                    height={"18px"}
                                    marginRight={"4px"}
                                    src={
                                      _.find(chainList, {
                                        value: convertIfNumeric(
                                          latestObjectiveItem.chainId
                                        ),
                                      })?.logo_url
                                    }
                                    alt="latestObjectiveItemLogo"
                                  />

                                  <Box>
                                    {
                                      _.find(chainList, {
                                        value: convertIfNumeric(
                                          latestObjectiveItem.chainId
                                        ),
                                      })?.name
                                    }
                                  </Box>
                                </Box>
                              </StyledRecentObjectiveItemContent>
                            </Box>

                            <Box mb={2}>
                              <StyledRecentObjectiveItemTitle>
                                {formatMessage({
                                  id: "campaignBuild.thirdStep.recentObjectiveBlock.contractAddress.title",
                                })}
                              </StyledRecentObjectiveItemTitle>
                              <StyledRecentObjectiveItemContent>
                                {latestObjectiveItem.smartContractAddress}
                              </StyledRecentObjectiveItemContent>
                            </Box>
                            {latestObjectiveItem?.method ? (
                              <Box>
                                <StyledRecentObjectiveItemTitle>
                                  {formatMessage({
                                    id: "campaignBuild.thirdStep.recentObjectiveBlock.Target Function.title",
                                  })}
                                </StyledRecentObjectiveItemTitle>
                                <StyledRecentObjectiveItemContent>
                                  {latestObjectiveItem.method}
                                </StyledRecentObjectiveItemContent>
                              </Box>
                            ) : (
                              <Box height={"30px"}></Box>
                            )}
                          </StyledRecentObjectiveItem>
                        </Grid>
                      ))}
                    </StyledObjectiveListContainer>
                  </StyledToggleButtonGroup>
                </Box>
              </Collapse>
            </StyledRecentObjectiveContainer>
          </Box>
        )}
      </Box>
    </>
  );
}

import ReactECharts from "echarts-for-react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { alertServerError } from "features/helpers/utils";
import { Loading } from "features/common";
import { useOutletContext } from "react-router";
import { apiUrl } from "features/configure";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import { StyledSubtitle } from "./ServiceReport.styles";
import { thirtyColors } from "utils/constants";
import moment from "moment";

export function TicketAnalytics({ startDate, endDate }) {
  const [entityName, isCustomDomain] = useOutletContext();
  const [analytics, setAnalytics] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const renderPieChart = ({ pieData, title, colors }) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {title && (
            <StyledSubtitle className="startRow">{title}</StyledSubtitle>
          )}
        </Grid>
        <Grid item xs={12}>
          <ReactECharts
            option={{
              tooltip: {
                trigger: "item",
                formatter: "{a} {b} : {c} ({d}%)",
              },
              legend: {
                bottom: "5%",
                left: "center",
              },
              series: [
                {
                  name: "",
                  type: "pie",
                  radius: ["35%", "55%"],
                  center: ["50%", "40%"],
                  data: pieData,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }}
            style={{ height: "359px" }}
            notMerge={true}
            lazyUpdate={true}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const params = {};
      if (startDate) params.startDate = moment(startDate).valueOf();

      if (endDate) params.endDate = moment(endDate).valueOf();
      const _analytics = await axios.get(
        apiUrl + `/api/ticket/analytics/${entityName}/ticket`,
        { params }
      );
      setAnalytics(_analytics.data);
    } catch (error) {
      dispatch(alertServerError(error));
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  const statusColors = ["#F7ABC7", "#D2B5D4", "#AAD4F4"];
  const channelColors = ["#EEC9E8", "#86E1E0", "#B5E4FE"];
  const ticketTypeColors = thirtyColors;

  return (
    <div>
      <Loading open={loading} fullScreen={false} />
      <Box className="xshr" />
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          style={{ borderRight: "1px solid #E6E6E6", padding: 16 }}
        >
          {renderPieChart({
            pieData: _.sortBy(_.get(analytics, "counts.status", []), [
              (item) => _.indexOf(["New", "Ongoing", "Done"], item._id),
            ]).map(({ _id, count }, index) => ({
              value: count,
              name: _id,
              itemStyle: {
                color: statusColors[index],
              },
            })),
            title: <>Ticket Status</>,
          })}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ padding: 16, borderRight: "1px solid #E6E6E6" }}
        >
          {renderPieChart({
            pieData: _.sortBy(_.get(analytics, "counts.channel", []), [
              (item) =>
                _.indexOf(["discord", "widget", "supportCenter"], item._id),
            ]).map(({ _id, count }, index) => ({
              value: count,
              name: _.upperFirst(_id),
              itemStyle: {
                color: channelColors[index],
              },
            })),
            title: <>Ticket Channel</>,
          })}
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 16 }}>
          {renderPieChart({
            pieData: _.reverse(
              _.sortBy(_.get(analytics, "counts.type", []), ["count"])
            ).map(({ count, name }, index) => ({
              value: count,
              name: name,
              itemStyle: {
                color: ticketTypeColors[index % 30],
              },
            })),
            title: <>Ticket Type</>,
          })}
        </Grid>

        {/* <Grid item xs={12} md={3} style={{ padding: 16 }}>
          {renderPieChart({
            pieData: _.get(analytics, "counts.tag", []).map(
              ({ _id, count }) => ({
                value: count,
                name: _id,
              })
            ),
            title: (
              <>
                <i className="meta-crm-icon-ic_userProfile font-size-18 mgr4" />
                Tag
              </>
            ),
          })}
        </Grid> */}
      </Grid>
    </div>
  );
}
